/* eslint-disable max-lines */
// @ts-nocheck
import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  mutedLabel: {
    color: theme.palette.primary.main,
  },
  applicantName: {
    fontWeight: 300,
  },
  paddingDrag: {
    paddingBottom: theme.spacing(2),
  },
  btnSecondaryAction: {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.secondary,
    },
  },
  btnPrimaryAction: {
    color: theme.palette.common.white,
  },
  fabs: {
    maxWidth: 48,
    transition: theme.transitions.create('max-width'),
    '& .hidden': {
      maxWidth: 0,
      overflow: 'hidden',
      transition: theme.transitions.create(['max-width', 'margin']),
      textDecoration: 'none',
    },
    '&:hover, &.extended': {
      maxWidth: 200,
    },
    '&:hover .hidden, &.extended .hidden': {
      maxWidth: 200,
      textDecoration: 'none',
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, -1),
    padding: theme.spacing(0, 1),
    lineHeight: 1.3,
  },
  floatingButtons: {
    position: 'absolute',
    top: '50%',
    height: theme.spacing(5),
    width: theme.spacing(5),
    marginTop: theme.spacing(-2.5),
    padding: theme.spacing(1),
  },
  alertButton: {
    background: theme.palette.alertGradient.main,
    color: theme.palette.grey[900],
    '&:hover': {
      background: theme.palette.alertGradient.hover,
    },
  },
  innerBody: {
    padding: theme.spacing(2, 1, 2, 2),
    borderBottom: `2px solid ${theme.palette.grey[200]}`,
    '&.isDragging': {
      border: `2px dashed ${theme.palette.grey[300]}`,
      margin: '-2px -2px 0 -2px',
      backgroundColor: theme.palette.grey[50],
    },
    '&.noBorder': {
      border: 'none',
    },
  },
  floatingLeft: {
    left: theme.spacing(-5),
    '&.extremeLeft': {
      left: theme.spacing(-10),
    },
  },
  floatingRight: {
    right: theme.spacing(-5),
    '&.rootChild': {
      right: theme.spacing(1),
    },
  },
  pointer: {
    position: 'relative',
    cursor: 'pointer',
  },
  expandIcon: {
    transform: 'rotate(-90deg)',
    transition: theme.transitions.create(['all']),
    marginLeft: 2,
    '&.expanded': {
      transform: 'rotate(0deg)',
      marginLeft: 0,
    },
  },
  noResults: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& svg': {
      minWidth: 180,
      height: 'auto',
      opacity: 0.7,
      marginRight: theme.spacing(2),
    },
  },
  minWidthHolder: {
    overflowX: 'auto',
    paddingBottom: theme.spacing(2),
  },
  minWidthContainer: {
    minWidth: theme.spacing(160),
  },
  pageHeader: {
    marginBottom: theme.spacing(5),
    '&.smallMargin': {
      marginBottom: theme.spacing(3),
    },
  },
  btnholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  pageTitle: {
    lineHeight: `${theme.spacing(5)}px`,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 1),
    '&:hover': {
      backgroundColor: fade('#000000', 0.08),
    },
    marginRight: theme.spacing(2),
    width: 'auto',
    display: 'inline-flex',
    boxShadow: theme.shadows[3],
    height: theme.spacing(5),
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: 90,
    '&:focus, &:hover': {
      width: 280,
    },
  },
  button: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(5),
    '&:last-child': {
      marginLeft: theme.spacing(2),
    },
    border: 'none',
    boxShadow: theme.shadows[3],
  },
  buttonGroup: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(5),
    border: 'none',
    boxShadow: theme.shadows[3],
    marginLeft: '1px !important',
  },
  iconMargin: {
    marginLeft: `${theme.spacing(1)}px !important`,
  },
  iconMarginRight: {
    marginRight: theme.spacing(1),
  },
  paperAlert: {
    backgroundColor: theme.palette.grey[100],
    border: `2px dashed ${theme.palette.grey[200]}`,
  },
  header: {
    padding: theme.spacing(2, 6, 2, 4),
    marginBottom: theme.spacing(1),
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'relative',
    '&:not(.envioProcess) .MuiGrid-item': {
      overflow: 'hidden',
    },
    '&.sameRightPadding': {
      padding: theme.spacing(2, 4),
    },
    /*
    '&.apostilamento': {
      padding: theme.spacing(2, 8, 2, 12),
    },
    */
    '&.envioProcess': {
      padding: theme.spacing(1),
      minHeight: theme.spacing(7),
      display: 'flex',
      '&:not(.noFlex)': {
        alignItems: 'center',
      },
    },
    '&.documentacao, &.apostilamento': {
      padding: theme.spacing(2, 8),
    },
    '&.selected': {
      paddingBottom: 0,
    },
  },
  dateofarrival: {
    paddingLeft: theme.spacing(1),
  },
  list: {
    width: '100%',
    '& button': {
      padding: theme.spacing(1),
      marginRight: theme.spacing(0.5),
    },
  },
  checked: {
    color: `${theme.palette.primary.main} !important`,
  },
  listIndent: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  listChecked: {
    backgroundColor: theme.palette.grey[200],
  },
  checkboxDisabled: {
    opacity: 0,
  },
  body: {
    padding: theme.spacing(2, 6, 2, 4),
    margin: theme.spacing(1.5, 0, 2, 0),
    position: 'relative',
    minHeight: theme.spacing(8),
    transition: theme.transitions.create(['box-shadow', 'min-height'], { duration: '100ms' }),
    boxShadow: theme.shadows[6],
    /*
    '&.apostilamento': {
      userSelect: 'none',
      padding: theme.spacing(2, 8, 2, 12),
    },
    */
    '&.sameRightPadding': {
      padding: theme.spacing(2, 4),
    },
    '&.documentacao, &.apostilamento': {
      userSelect: 'none',
      padding: theme.spacing(2, 8),
    },
    '&.clickable': {
      userSelect: 'none',
      cursor: 'pointer',
    },
    '&.clickable:hover': {
      boxShadow: theme.shadows[0],
    },
    '&.selected': {
      boxShadow: theme.insetShadows[0],
      backgroundColor: theme.palette.grey[400],
    },
  },
  innerHeader: {
    padding: theme.spacing(2, 1, 2, 2),
    borderTop: `1px solid ${theme.palette.grey[600]}`,
    backgroundColor: theme.palette.grey[200],
  },
  lotExpandButtonHolder: {
    minWidth: theme.spacing(5),
    marginRight: theme.spacing(0.5),
  },
  expandButtonGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  bodyEnvio: {
    userSelect: 'none',
    padding: theme.spacing(2, 1, 2, 1),
    margin: theme.spacing(0, 0, 2, 0),
    position: 'relative',
    minHeight: theme.spacing(8),
    transition: theme.transitions.create(['box-shadow', 'min-height', 'border-width'], { duration: '100ms' }),
    boxShadow: theme.shadows[6],
    '&.clickable': {
      cursor: 'pointer',
    },
    '&.lot': {
      margin: '0 !important',
    },
    '&:not(.paddingNone):hover': {
      boxShadow: theme.shadows[0],
    },
    '&.selected': {
      boxShadow: theme.insetShadows[0],
      backgroundColor: theme.palette.grey[400],
    },
    '&.isActive': {
      border: `2px dashed ${theme.palette.grey[200]}`,
      margin: `calc(${theme.spacing(1.5)}px - 2px) -2px calc(${theme.spacing(2)}px - 2px) -2px`,
      boxShadow: theme.shadows[1],
    },
    '&.paddingNone': {
      padding: '0 !important',
      cursor: 'default',
    },
    '&.inner': {
      margin: '0 !important',
      boxShadow: 'none!important',
    },
    '&.dragging': {
      opacity: 0.7,
      cursor: 'move',
      border: `2px dashed ${theme.palette.grey[200]}`,
      margin: `calc(${theme.spacing(1.5)}px - 2px) -2px calc(${theme.spacing(2)}px - 2px) -2px`,
    },
    '&.noMarginTop': {
      marginTop: '0 !important',
    },
  },
  flexButton: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    padding: 0,
    transform: 'rotate(0deg)',
    transition: theme.transitions.create(['transform']),
    '&.expanded': {
      transform: 'rotate(180deg)',
    },
  },
  actions: {
    transition: theme.transitions.create(['opacity']),
    opacity: 0,
    '&.shown': {
      opacity: 1,
    },
  },
  subMenuHolder: {
    margin: theme.spacing(0, 0, 3, 0),
    width: '100%',
    paddingBottom: 0,
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
  },
  tabsGrid: {
    paddingBottom: '0 !important',
    paddingLeft: '0 !important',
  },
  moreverticon: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    marginTop: '-18px',
    padding: theme.spacing(1),
  },
  centeredGrid: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  hoverText: {
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: fade(theme.palette.text.primary, 0.7),
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  hiddenText: {
    height: '1px',
    position: 'absolute',
    top: '2px',
    zIndex: '-1',
    padding: 0,
    left: 0,
    margin: 0,
    border: 'none',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  pLine: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    whiteSpace: 'nowrap',
    lineHeight: 1.8,
  },
  dialogMoreInfo: {
    padding: theme.spacing(6, 3, 3, 3),
  },
  smallPaper: {
    width: theme.spacing(35),
    display: 'inline-block',
    padding: theme.spacing(2),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    boxShadow: theme.shadows[4],
    '&:hover': {
      boxShadow: theme.shadows[1],
    },
  },
  link: {
    marginRight: theme.spacing(1),
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    fontWeight: theme.typography.body1.fontWeight,
    padding: 0,
    background: 'none',
    backgroundColor: 'transparent',
    appearance: 'none',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    textAlign: 'left',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.main,
    },
  },
  closeBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  gridContainer: {
    paddingBottom: theme.spacing(1),
    position: 'relative',
  },
  moreverticonDialog: {
    top: theme.spacing(3),
    right: 0,
    position: 'absolute',
  },
  fromMdPadding: {
    [theme.breakpoints.up('md')]: {
      paddingRight: `${theme.spacing(5.5)}px !important`,
    },
  },
  beforeMdPadding: {
    [theme.breakpoints.down('md')]: {
      paddingRight: `${theme.spacing(5.5)}px !important`,
    },
  },
  closeBtnTiny: {
    marginRight: theme.spacing(0.5),
  },
  closeBtnTinyIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  buttonGroupSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
}));
