import React, { useContext, useRef } from 'react';
import uniqid from 'uniqid';
import { ServiceContext } from 'contexts/service';
import PropTypes from 'prop-types';
import {
  Paper, IconButton, Grid, Typography, Button,
} from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
} from '@material-ui/icons';
import { stopPropagation, semanticDate } from 'global/functions';
import clsx from 'clsx';
import Contact from 'global/modules/Contact';
import { VERIFICACAO_ROW } from 'constants/service/menus';
import { SCOPE } from 'constants/service';
import GridDocs from 'service/modules/GridDocs';
import { useStyles } from '../style';

function VerificacaoRow({ item, push }) {
  const classes = useStyles();
  const menuRef = useRef(null);
  const serviceContext = useContext(ServiceContext);
  const processes = {};
  if (item.processes) {
    item.processes.forEach((process) => {
      if (process.products[0] && process.products[0].title) {
        const value = processes[process.products[0].title];
        processes[process.products[0].title] = value ? value + 1 : 1;
      }
    });
  }

  const {
    setDocsShown,
    handleShowMoreInfo,
    openMenu,
  } = serviceContext;

  return (
    <>
      <Paper
        className={clsx(classes.body, 'clickable')}
        onClick={(e) => handleShowMoreInfo(true, item, e, SCOPE)}
      >
        <Grid container spacing={2}>
          <Grid item xs={4} className={classes.centeredGrid}>
            <Contact item={item} customer={item.customer} editable />
          </Grid>
          <Grid item xs={5} className={classes.centeredGrid}>
            <Grid container spacing={2}>
              <Grid item xs={4} className={classes.centeredGrid}>
                <Typography variant="body1">
                  {semanticDate(item.comfirmed_date, true)}
                </Typography>
              </Grid>
              <GridDocs
                currentClient={item}
                xs={3}
                scopeId={item.id}
                clientName={item.customer.name}
                setDocsShown={setDocsShown}
                docType={SCOPE}
              />
              <Grid item xs={5} className={classes.centeredGrid}>
                {Object.keys(processes).length > 0 && Object.keys(processes).map((key) => (
                  <Typography key={uniqid()} variant="body1">
                    {`${processes[key]}x ${key}`}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} className={classes.centeredGrid}>
            <Button
              component="a"
              href={`/verificacao/${item.id}`}
              onClick={(e) => { push(`/verificacao/${item.id}`); stopPropagation(e); }}
              variant="contained"
              color="primary"
              fullWidth
            >
              {'Verificar venda'}
            </Button>
          </Grid>
        </Grid>
        <IconButton
          ref={menuRef}
          aria-label="Mais ações"
          aria-controls="long-menu"
          aria-haspopup="true"
          className={classes.moreverticon}
          onClick={(e) => {
            stopPropagation(e);
            openMenu(true, VERIFICACAO_ROW, menuRef.current, item);
          }}
        >
          <MoreVertIcon />
        </IconButton>
      </Paper>
    </>
  );
}

VerificacaoRow.propTypes = {
  item: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
};

export default VerificacaoRow;
