/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import uniqid from 'uniqid';
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
} from '@material-ui/core';
import { deleteDocument, sendDocument } from 'store/actions/documents';
import { showNotification } from 'store/actions/notifications';
import { changeStepProcessSendDocs, blockProcess } from 'store/actions/processes';
import { FULFILLED } from 'constants/session';
import { showConfirmation } from 'store/actions/confirmations';
import DialogChecklist from './DialogChecklist';

function Dialogs({
  classes, context, setUpdate, update, actionStatus, settingsStatus, ...props
}) {
  const {
    currentClient,
    currentCheck,
    confirmEndStepDialog,
    handleConfirmEndStepDialog,
    handleBlockDialog,
    confirmBlockDialog,
    checkDialog,
    handleCheckDialog,
    block,
  } = context;
  const [reason, setReason] = useState('');

  const handleBlockProcess = () => {
    props.blockProcess(currentClient.id, reason, block);
  };

  const handleCloseBlockDialog = () => {
    handleBlockDialog();
    setReason('');
  };

  const handleChangeReason = (e) => setReason(e.target.value);

  useEffect(() => {
    if (confirmEndStepDialog && actionStatus === FULFILLED) handleConfirmEndStepDialog();
    if (confirmBlockDialog && actionStatus === FULFILLED) {
      handleCloseBlockDialog();
      setUpdate(uniqid());
    }
    if (checkDialog && actionStatus === FULFILLED) {
      handleCheckDialog();
      setUpdate(uniqid());
    }
    // eslint-disable-next-line
  }, [actionStatus]);

  return (
    <>
      {Object.keys(currentCheck).length > 0 && (
        <DialogChecklist
          currentCheck={currentCheck}
          checkDialog={checkDialog}
          handleCheckDialog={handleCheckDialog}
          setUpdate={setUpdate}
        />
      )}
      {Object.keys(currentClient).length > 0 && (
        <Dialog
          PaperProps={{
            square: true,
          }}
          open={confirmBlockDialog}
          onClose={() => handleCloseBlockDialog()}
          scroll="body"
          aria-labelledby="dialog-endstep-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="dialog-endstep-title">
            {block ? 'Travar processo' : 'Destravar processo'}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {block
                    ? `Se encontrou alguma pendência de documentação, indique o travamento do processo de ${currentClient.applicant.name}`
                    : `Deseja destravar o processo de ${currentClient.applicant.name}?`}
                </Typography>
                {block && (
                  <TextField
                    id="outlined-multiline-static"
                    label="Motivo para a trava do processo"
                    fullWidth
                    multiline
                    value={reason}
                    rows="4"
                    onChange={handleChangeReason}
                    margin="normal"
                    variant="outlined"
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleCloseBlockDialog()}
              variant="outlined"
              color="secondary"
            >
              {'Cancelar'}
            </Button>
            <Button
              onClick={() => handleBlockProcess()}
              disabled={reason === '' && block}
              variant="outlined"
              color="primary"
            >
              {'Confirmar'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

const actions = {
  deleteDocument,
  sendDocument,
  showNotification,
  changeStepProcessSendDocs,
  showConfirmation,
  blockProcess,
};

const mapStateToProps = (state) => ({
  steps: state.steps.collection,
  actionStatus: state.processes.actionStatus,
  settingsStatus: state.processes.settingsStatus,
});

export default connect(mapStateToProps, actions)(Dialogs);
