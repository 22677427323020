import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DIALOG_CUSTOMER, DIALOG_EDIT_CUSTOMER } from 'constants/dialogs';
import { updateDialog } from 'store/actions/dialogs';
import DialogCustomer from './DialogCustomer';
import DialogEditCustomer from './DialogEditCustomer';

function Dialogs({
  mode, open, currentCustomer, ...props
}) {
  const closeDialog = () => {
    props.updateDialog({ mode: '', open: false, currentCustomer: {} });
  };

  switch (mode) {
    case DIALOG_EDIT_CUSTOMER:
      return (
        <DialogEditCustomer
          open={open}
          currentCustomer={currentCustomer}
          closeDialog={closeDialog}
        />
      );
    case DIALOG_CUSTOMER:
    default:
      return (
        <DialogCustomer
          open={open}
          currentCustomer={currentCustomer}
          closeDialog={closeDialog}
        />
      );
  }
}

Dialogs.propTypes = {
  mode: PropTypes.oneOf([
    DIALOG_CUSTOMER,
    DIALOG_EDIT_CUSTOMER,
    '',
  ]),
  open: PropTypes.bool,
  currentCustomer: PropTypes.object,
  updateDialog: PropTypes.func.isRequired,
};

Dialogs.defaultProps = {
  mode: DIALOG_CUSTOMER,
  open: false,
  currentCustomer: {},
};

const mapStateToProps = (state) => ({
  mode: state.dialogs.mode,
  open: state.dialogs.open,
  currentCustomer: state.dialogs.currentCustomer,
});

export default connect(mapStateToProps, { updateDialog })(Dialogs);
