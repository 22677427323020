import React from 'react';
import JoditEditor from 'jodit-react';
import { string, bool, func } from 'prop-types';

const EditContent = ({ content, setSaveContent, readonly = false, unstyled = false, ...props }) => {
  const updateContent = (value) => setSaveContent(value);
  const config = {
    readonly,
    language: 'pt_br',
    preset: unstyled ? 'inline' : 'none',
  };
  const editor = React.useMemo(() => (
    <JoditEditor
      value={content}
      config={config}
      onChange={(value) => updateContent(value)}
    />
    //eslint-disable-next-line
  ), [content]);
  return editor;
};

EditContent.propTypes = {
  content: string,
  readonly: bool,
  unstyled: bool,
  setContent: func,
};

EditContent.defaultProps = {
  content: '',
  readonly: false,
  unstyled: false,
  setContent: () => { },
};

export default EditContent;
