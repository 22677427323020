import React from 'react';
import { Dialog, DialogContent, Typography, Link, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: `calc(100vh - ${theme.spacing(12)}px)`,
  },
  content: {
    height: `calc(100vh - ${theme.spacing(12)}px)`,
    padding: '0px !important',
  },
}));

export const Visualization = ({ doc, docUrl, minHeight = false }) => (
  <>
    {doc.type.includes('pdf')
      ? (
        <object
          style={{
            width: '100%',
            height: minHeight ? '220px' : '100%',
            marginBottom: -4,
          }}
          type="application/pdf"
          data={docUrl}
        >
          <Typography>
            {'Não foi possível visualizar o documento, você pode baixa-lo '}
            <Link href={docUrl} target="_blank">
              {'aqui'}
            </Link>
            {'.'}
          </Typography>
        </object>
      ) : (
        <img src={docUrl} alt={doc.title} width="100%" height="auto" style={{ marginBottom: -4 }} />
      )}
  </>
);

function Expanded({ doc, expanded, setExpanded, ...props }) {
  const classes = useStyles(props);
  const handleClose = () => setExpanded(false);

  return (
    <Dialog
      open={expanded}
      scroll="body"
      maxWidth="xl"
      fullWidth
      classes={{
        paper: classes.paper,
      }}
      onClose={() => handleClose()}
    >
      <DialogContent
        classes={{
          root: classes.content,
        }}
      >
        <Visualization doc={doc} docUrl={doc.src} />
      </DialogContent>
    </Dialog>
  )
}

export default Expanded;