import React from 'react';

export const UserContext = React.createContext({
  user: null,
  setUser: (user) => { },
  isMaster: false,
  isInternal: false,
  isCustomer: false,
  userInternal: false,
  userSales: false,
  userCustomer: false,
  userService: false,
  isManager: false,
  userCoordinator: false,
});

export const LoaderContext = React.createContext({
  isLoading: (loading) => { },
});
