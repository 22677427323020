import React, { useState, useEffect } from 'react';
import {
  array, func, object, string,
} from 'prop-types';
import uniqid from 'uniqid';
import { MenuItem, TextField, Paper, Typography } from '@material-ui/core';
import Autosuggest from 'react-autosuggest';
import _ from 'lodash';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { deburrify } from 'global/functions';

function renderInputComponent(inputProps) {
  const {
    classes, inputRef = () => { }, ref, errortext, errorTextValue, ...other
  } = inputProps;
  return (
    <TextField
      autoComplete="off"
      helperText={errortext ? <Typography variant="caption" color="error">{errorTextValue}</Typography> : ''}
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes ? classes.input : 'default',
        },
      }}
      {...other}
    />
  );
}

function AutosuggestField({ errorTextValue, ...props }) {
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState('');
  const [errortext, seterrortext] = useState(false);

  const getSuggestions = (value) => {
    if (typeof value === 'undefined' || value.lenght === 0) return [];
    const searchValue = value ? value.trim().toLowerCase() : '';
    return _.filter(
      props.suggestList,
      (item) => _.includes(_.toLower(item.label), searchValue),
    );
  };

  const loadDefaultValue = () => {
    if (typeof props.value !== 'undefined' && props.value !== '' && props.loadKey !== '') {
      const defaultItem = _.first(
        _.filter(
          props.suggestList,
          (item) => _.toLower(item[props.loadKey]) === _.toLower(props.value),
        ),
      );
      if (typeof defaultItem !== 'undefined') setSelectedSuggestion(defaultItem.label);
    }
  };

  const getSuggestValue = (suggest) => suggest.value;

  const handleFetchSuggestions = ({ value }) => setSuggestions(getSuggestions(value));

  const handleClearSuggestions = () => setSuggestions([]);

  const handleChange = (event, { newValue }) => {
    setSelectedSuggestion(newValue);
    seterrortext(false);
  };

  const renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map((part) => (
            <span key={uniqid()} style={{ fontWeight: part.highlight ? 600 : 400 }}>
              {part.text}
            </span>
          ))}
        </div>
      </MenuItem>
    );
  };

  const handleSelect = (event, { suggestion }) => {
    setSelectedSuggestion(suggestion.label);
    props.onSelect(suggestion);
  };

  const handleGetClosestSuggestion = (e, b) => {

    var labelsuggestList;

    console.log(selectedSuggestion)

    if(props.notTranslate != null && props.notTranslate) {
      labelsuggestList = props.suggestList
      .find((a) => deburrify(a.label) === deburrify(selectedSuggestion));
      console.log('aqui 1')
    } else {
      labelsuggestList = props.suggestList
      .find((a) => a.translations
      && deburrify(a.translations.br) === deburrify(selectedSuggestion));
      console.log('aqui 2')
    }

    if (suggestions.length > 0) {
      handleSelect(e, { suggestion: suggestions[0] });
      seterrortext(false);
    } else if (labelsuggestList === undefined) {
      setSelectedSuggestion('');
      props.onSelect('');
      seterrortext(true);
    }
  };

  useEffect(() => {
    loadDefaultValue();
    // eslint-disable-next-line
  }, []);

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={handleFetchSuggestions}
      onSuggestionsClearRequested={handleClearSuggestions}
      onSuggestionSelected={handleSelect}
      getSuggestionValue={getSuggestValue}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent}
      inputProps={{
        value: selectedSuggestion,
        onChange: handleChange,
        onBlur: handleGetClosestSuggestion,
        label: props.label,
        errortext: errortext ? 'true' : '',
        errorTextValue,
        ...props.inputProps,
        autoComplete: 'off',
      }}
      renderSuggestionsContainer={(options) => (
        <Paper elevation={2} {...options.containerProps} style={{ zIndex: 100 }}>
          {options.children}
        </Paper>
      )}
      theme={{ ...props.theme }}
    />
  );
}

AutosuggestField.propTypes = {
  suggestList: array.isRequired,
  onSelect: func.isRequired,
  label: string.isRequired,
  inputProps: object,
  theme: object,
  loadKey: string,
  value: string,
  errorTextValue: string,
};

AutosuggestField.defaultProps = {
  theme: {},
  loadKey: '',
  value: '',
  errorTextValue: 'Por favor, selecione o país',
};

export default AutosuggestField;
