export const editPersonObject = (person) => ({
  ...person,
  name: person.name || '',
  document_number: person.document_number || '',
  birthplace: person.birthplace || '',
  consulate: person.consulate || '',
  concelho: person.concelho || '',
  freguesia: person.freguesia || '',
  mother_name: person.mother_name || '',
  father_name: person.father_name || '',
  crc: person.crc || '',
  assento_year: person.assento_year || '',
  certificate_type: person.certificate_type || 1,
  certificate_id: person.certificate_id || 1,
  birthdate: person.birthdate,
});
