import React from 'react';
import { Typography, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CopyElement from 'global/CopyElement';
import { convertCurrency } from 'helpers';
import { displayTime, createMarkup } from 'global/functions';

const Row = ({ item, classes, expanded, handleExpand, tags, handleAddTag, ...props }) => {
  const totalCost = item.prices ? item.prices.reduce((a, b) => a + (b.price || 0), 0) : 0;
  let estimates = item.minimum_days_to_completion ? `de ${displayTime(item.minimum_days_to_completion)} ` : '';
  if (item.maximum_days_to_completion) {
    estimates = `${estimates}até ${displayTime(item.maximum_days_to_completion)}`;
  }
  return (
    <ExpansionPanel
      expanded={expanded === item.id}
      onChange={() => handleExpand(item.id)}
      className={classes.expansionPanel}
      square
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${item.title}`}
        id={`product ${item.id} header`}
      >
        <Typography variant="h6" color="secondary" className={classes.question}>
          {item.title}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.panelContent}>
        <Grid container spacing={2} className={classes.panelInfos}>
          <Grid item xs={12}>
            {!item.description
              ? null
              : (
                <div className={classes.richText}>
                  <Typography dangerouslySetInnerHTML={createMarkup(item.description)} component="div" paragraph />
                </div>
              )
            }
            <Typography variant="h6" paragraph>
              {'Informações gerais'}
            </Typography>
            {estimates && (
              <Typography>
                <b>{'Estimativa de tempo para completar: '}</b>
                <CopyElement>
                  {estimates}
                </CopyElement>
              </Typography>
            )}
            {!item.initials ? null : (
              <Typography>
                <b>{'Código no sistema: '}</b>
                <CopyElement>
                  {item.initials}
                </CopyElement>
              </Typography>
            )}
            {!item.postal_value ? null : (
              <Typography>
                <b>{'Custo postal: '}</b>
                {convertCurrency(item.postal_value, true, 'EUR')}
              </Typography>
            )}
          </Grid>
          {item.requirements && item.requirements.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="h6" paragraph>
                {'Documentação'}
              </Typography>
              {item.requirements.map((requirement) => (
                <Typography key={requirement.id} component="div" paragraph>
                  <Typography variant="h6" color="textPrimary">
                    <CopyElement>
                      {requirement.name}
                    </CopyElement>
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {requirement.hague ? 'Precisa ser apostilado' : 'Não precisa ser apostilado'}
                  </Typography>
                  <Typography variant="subtitle1" color={requirement.company_responsible ? 'primary' : 'textSecondary'}>
                    {requirement.company_responsible ? 'A empresa é responsável por emitir este documento' : 'O cliente é responsável por emitir este documento'}
                  </Typography>
                  {!requirement.variation ? null : (
                    <CopyElement value={`Variação aceita: ${requirement.variation}`}>
                      <Typography>
                        <b>{'Variação aceita: '}</b>
                        {requirement.variation}
                      </Typography>
                    </CopyElement>
                  )}
                  {!requirement.format ? null : (
                    <CopyElement value={`Formato: ${requirement.format}`}>
                      <Typography>
                        <b>{'Formato: '}</b>
                        {requirement.format}
                      </Typography>
                    </CopyElement>
                  )}
                  {!requirement.exception ? null : (
                    <CopyElement value={`Exceção: ${requirement.exception}`}>
                      <Typography>
                        <b>{'Exceção: '}</b>
                        {requirement.exception}
                      </Typography>
                    </CopyElement>
                  )}
                  {!requirement.specification ? null : (
                    <CopyElement value={`Especificação: ${requirement.specification}`}>
                      <Typography>
                        <b>{'Especificação: '}</b>
                        {requirement.specification}
                      </Typography>
                    </CopyElement>
                  )}
                  {!requirement.obs ? null : (
                    <CopyElement value={`Observação: ${requirement.obs}`}>
                      <Typography>
                        <b>{'Observação: '}</b>
                        {requirement.obs}
                      </Typography>
                    </CopyElement>
                  )}
                </Typography>
              ))}
            </Grid>
          )}
          {item.prices && item.prices.length && (
            <Grid item xs={12}>
              <Typography variant="h6" paragraph>
                {'Valores'}
              </Typography>
              {item.prices.map((price) => (
                <CopyElement key={price.id} value={`${price.name}: ${convertCurrency(price.price)}`}>
                  <Typography>
                    <b>{`${price.name}:`}</b>
                    {` ${convertCurrency(price.price)}`}
                  </Typography>
                </CopyElement>
              ))}
              {totalCost && (

                <CopyElement value={`Valor de venda: ${convertCurrency(totalCost)}`}>
                  <Typography>
                    <b>{'Valor de venda:'}</b>
                    {` ${convertCurrency(totalCost)}`}
                  </Typography>
                </CopyElement>
              )}
            </Grid>
          )}
        </Grid>
        <Button
          size="small"
          color="primary"
          className={classes.tagButton}
          onClick={() => handleAddTag(item.category)}
        >
          # {tags.find(a => a.id === item.category).label}
        </Button>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

export default Row;
