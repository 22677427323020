import React from 'react';
import { connect } from 'react-redux';
import EditContent from 'global/modules/EditContent';
import { includes } from 'lodash';
import { SaleDashboardContext } from 'contexts/saleDashboard';
import { Dialog, DialogContent, Slide, AppBar, Toolbar, IconButton, Typography, Button, makeStyles } from '@material-ui/core';
import { getContractContent, changeContractContent } from 'store/actions/contract';
import { REQUESTING, DEFAULT, FULFILLED } from 'constants/common';
import { LoadingComponent } from 'global/modules/Loading';
import { Close } from '@material-ui/icons';
import { showConfirmation } from 'store/actions/confirmations';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    color: theme.palette.common.white,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CoordinatorDialogs = ({ content, actionStatus, confirmation, status, fetch, update, ...props }) => {
  const classes = useStyles({});
  const [saveContent, setSaveContent] = React.useState(content);
  const [requesting, setRequesting] = React.useState(false);
  const { handleDialogEditContract, dialogEditContract, currentClient } = React.useContext(SaleDashboardContext);
  React.useEffect(() => {
    if (currentClient.id && dialogEditContract) {
      fetch(currentClient.id);
    }
  }, [currentClient, dialogEditContract, fetch]);
  React.useEffect(() => {
    if (actionStatus === FULFILLED && requesting && dialogEditContract) {
      setRequesting(false);
      handleDialogEditContract();
    }
    //eslint-disable-next-line
  }, [actionStatus]);
  const handleSave = () => {
    if (currentClient.id) {
      const save = () => {
        setRequesting(true);
        update(currentClient.id, saveContent);
      };
      confirmation(
        {
          title: 'Salvar alterações',
          message: `Salvar alterações no contrato da venda${currentClient.customer && currentClient.customer.name ? ` de ${currentClient.customer.name}` : ''}`,
        },
        {
          confirm: 'Salvar',
          cancel: 'Cancelar',
        },
        {
          func: save,
        },
      );
    }
  };
  return (
    <Dialog
      open={dialogEditContract}
      fullScreen
      onClose={handleDialogEditContract}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleDialogEditContract} aria-label="close">
            <Close />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {`Editar contrato da venda${currentClient.customer && currentClient.customer.name ? ` de ${currentClient.customer.name}` : ''}`}
          </Typography>
          <Button color="inherit" onClick={handleSave}>
            {'Salvar alterações'}
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {includes([REQUESTING, DEFAULT], status)
          ? (
            <LoadingComponent />
          ) : null}
        <div id="editor">
          <EditContent key="richEditor" content={content} setSaveContent={setSaveContent} unstyled={includes([REQUESTING, DEFAULT], status)} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  content: state.contract.content,
  actionStatus: state.contract.actionStatus,
  status: state.contract.status,
});

const actions = {
  fetch: getContractContent,
  update: changeContractContent,
  confirmation: showConfirmation,
};

export default connect(mapStateToProps, actions)(CoordinatorDialogs);
