/* SEARCH */
export const PRODUCT_SEARCH = 'PRODUCT_SEARCH';
export const PRODUCT_SEARCH_RESULT = 'PRODUCT_SEARCH_RESULT';

/* PRODUCT */
export const FETCH_ALL_PRODUCTS = 'FETCH_ALL_PRODUCTS';
export const FETCH_ALL_PRODUCTS_FULFILLED = 'FETCH_ALL_PRODUCTS_FULFILLED';
export const FETCH_ALL_PRODUCTS_REJECTED = 'FETCH_ALL_PRODUCTS_REJECTED';

export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const CREATE_PRODUCT_FULFILLED = 'CREATE_PRODUCT_FULFILLED';
export const CREATE_PRODUCT_REJECTED = 'CREATE_PRODUCT_REJECTED';

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_FULFILLED = 'UPDATE_PRODUCT_FULFILLED';
export const UPDATE_PRODUCT_REJECTED = 'UPDATE_PRODUCT_REJECTED';

export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const REMOVE_PRODUCT_FULFILLED = 'REMOVE_PRODUCT_FULFILLED';
export const REMOVE_PRODUCT_REJECTED = 'REMOVE_PRODUCT_REJECTED';

/* PRICE */

export const ADD_PRICE_TO_PRODUCT = 'ADD_PRICE_TO_PRODUCT';
export const ADD_PRICE_TO_PRODUCT_FULFILLED = 'ADD_PRICE_TO_PRODUCT_FULFILLED';
export const ADD_PRICE_TO_PRODUCT_REJECTED = 'ADD_PRICE_TO_PRODUCT_REJECTED';

export const BATCH_UPDATE_PRICE = 'BATCH_UPDATE_PRICE';
export const BATCH_UPDATE_PRICE_FULFILLED = 'BATCH_UPDATE_PRICE_FULFILLED';
export const BATCH_UPDATE_PRICE_REJECTED = 'BATCH_UPDATE_PRICE_REJECTED';

export const UPDATE_PRICE = 'UPDATE_PRICE';
export const UPDATE_PRICE_FULFILLED = 'UPDATE_PRICE_FULFILLED';
export const UPDATE_PRICE_REJECTED = 'UPDATE_PRICE_REJECTED';

export const REMOVE_PRICE = 'REMOVE_PRICE';
export const REMOVE_PRICE_FULFILLED = 'REMOVE_PRICE_FULFILLED';
export const REMOVE_PRICE_REJECTED = 'REMOVE_PRICE_REJECTED';

/* REQUIREMENT */

export const ADD_REQUIREMENT_TO_PRODUCT = 'ADD_REQUIREMENT_TO_PRODUCT';
export const ADD_REQUIREMENT_TO_PRODUCT_FULFILLED = 'ADD_REQUIREMENT_TO_PRODUCT_FULFILLED';
export const ADD_REQUIREMENT_TO_PRODUCT_REJECTED = 'ADD_REQUIREMENT_TO_PRODUCT_REJECTED';

export const UPDATE_REQUIREMENT = 'UPDATE_REQUIREMENT';
export const UPDATE_REQUIREMENT_FULFILLED = 'UPDATE_REQUIREMENT_FULFILLED';
export const UPDATE_REQUIREMENT_REJECTED = 'UPDATE_REQUIREMENT_REJECTED';

export const REMOVE_REQUIREMENT = 'REMOVE_REQUIREMENT';
export const REMOVE_REQUIREMENT_FULFILLED = 'REMOVE_REQUIREMENT_FULFILLED';
export const REMOVE_REQUIREMENT_REJECTED = 'REMOVE_REQUIREMENT_REJECTED';
