import React from 'react';
import * as PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { connect } from 'react-redux';
import { Typography, Grid, Button, TextField, FormControl, Checkbox, InputLabel, Select, MenuItem } from '@material-ui/core';
import { processCategories } from 'constants/sales';
import { isEqual, toUpper, trim, includes } from 'lodash';
import { getFormInfoObj, setFormInfoObj, postFormObj, valid } from '../functions';
import EditDescriptionModal from './EditDescription';
import { Delete, Edit } from '@material-ui/icons';
import { updateProduct, removeProduct } from 'store/actions/product';
import { showConfirmation } from 'store/actions/confirmations';
import { FULFILLED, REJECTED } from 'constants/common';
import { ADD_MODE, EDIT_MODE } from 'constants/user';
import { defaultCasing } from 'helpers';
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import { createMarkup } from 'global/functions';

const ProductInfo = ({
  formInfo,
  setFormInfo,
  product,
  classes,
  status,
  update,
  confirmation,
  setUpdate,
  remove,
  dialogErrors,
  mode,
  newProduct,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [descriptionModal, setDescriptionModal] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const hasChanged = !isEqual(formInfo, getFormInfoObj(product));
  const contentHTML = convertFromHTML(formInfo.description);
  const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap);
  const richContent = JSON.stringify(convertToRaw(state));
  const handleSubmit = (e) => {
    try {
      valid(formInfo);
      confirmation(
        {
          title: 'Salvar informações de processo',
          message: `Deseja salvar alterações nas informações do processo ${product.title}?`,
        },
        {
          confirm: 'Salvar',
          cancel: 'Cancelar',
        },
        {
          func: () => {
            update(product.id, postFormObj(formInfo));
            setLoading(true);
          },
        },
      );
      setErrors([]);
    } catch (error) {
      setErrors(error);
    }
    e.preventDefault();
  };
  const handleRemove = (e) => {
    confirmation(
      {
        title: 'Remover processo do sistema',
        message: (
          <>
            <Typography variant="inherit">{`Deseja REMOVER o processo ${product.title} do sistema?`}</Typography>
            <Typography variant="inherit" color="error">{' Atenção: esta ação não poderá ser desfeita!'}</Typography>
          </>
        ),
      },
      {
        confirm: 'Remover',
        cancel: 'Cancelar',
      },
      {
        func: () => {
          remove(product.id);
          setLoading(true);
        },
      },
    );
    e.preventDefault();
  };
  const handleChangeToCompletion = (minmax, monyea, value) => {
    const newFormInfo = setFormInfoObj({
      ...formInfo,
      [minmax]: {
        ...formInfo[minmax],
        [monyea]: value !== '' ? parseInt(value, 10) : '',
      },
    });
    setFormInfo({ ...newFormInfo });
  };
  const checkFormatting = (name) => {
    setFormInfo((o) => {
      let value = o[name];
      if (name === 'title') {
        value = defaultCasing(value);
      }
      return ({
        ...o,
        [name]: value,
      });
    });
  };
  React.useEffect(() => {
    setLoading((o) => {
      if ((status === FULFILLED || status === REJECTED) && o) {
        setUpdate(uniqid());
        return false;
      }
      return o;
    });
  }, [status, setUpdate]);
  const handleDescription = () => setDescriptionModal((m) => !m);
  const handleChange = (name, value, limit = 0) => setFormInfo((o) => ({ ...o, [name]: limit ? trim(toUpper(value.substring(0, limit))) : value }));
  return (
    <form onSubmit={handleSubmit} style={{ marginTop: 16 }}>
      <EditDescriptionModal
        open={descriptionModal}
        handleDescription={handleDescription}
        formInfo={formInfo}
        setFormInfo={setFormInfo}
        richContent={richContent}
        classes={classes}
        setLoading={setLoading}
      />
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            variant="outlined"
            required
            fullWidth
            value={formInfo.title}
            label="Título"
            error={dialogErrors && dialogErrors.indexOf('title') >= 0}
            onBlur={() => checkFormatting('title')}
            onChange={(e) => handleChange('title', e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            variant="outlined"
            fullWidth
            required
            error={dialogErrors && dialogErrors.indexOf('initials') >= 0}
            value={formInfo.initials}
            label="Iniciais"
            onChange={(e) => handleChange('initials', e.target.value, 3)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl
            variant="outlined"
            required
            fullWidth
          >
            <InputLabel shrink>
              {'Categoria'}
            </InputLabel>
            <Select
              value={formInfo.category}
              onChange={(e) => handleChange('category', e.target.value)}
            >
              {Object.keys(processCategories).map((key) => (
                <MenuItem
                  key={`processCategory${key}`}
                  value={key}
                >
                  <Typography variant="inherit">
                    {processCategories[key]}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            value={formInfo.link_procuracao}
            label="Link para procuração"
            error={includes(errors, 'link_procuracao')}
            helperText={includes(errors, 'link_procuracao') ? 'URL inválida' : ''}
            onChange={(e) => handleChange('link_procuracao', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography paragraph>
            {'Tempo mínimo estimado para completar'}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} className={classes.center}>
              <Typography align="right" style={{ marginRight: 16 }}>
                {'De'}
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                value={formInfo.minimum.years}
                label={formInfo.minimum.years ? `Ano${formInfo.minimum.years > 1 ? 's' : ''}` : ''}
                onChange={(e) => handleChangeToCompletion('minimum', 'years', e.target.value)}
              />
            </Grid>
            <Grid item xs={6} className={classes.center}>
              <TextField
                variant="outlined"
                fullWidth
                value={formInfo.minimum.months}
                label={formInfo.minimum.months ? `M${formInfo.minimum.months > 1 ? 'eses' : 'ês'}` : ''}
                onChange={(e) => handleChangeToCompletion('minimum', 'months', e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography paragraph>
            {'Tempo máximo estimado para completar'}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6} className={classes.center}>
              <Typography align="right" style={{ marginRight: 16 }}>
                {'até'}
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                value={formInfo.maximum.years}
                label={formInfo.maximum.years ? `Ano${formInfo.maximum.years > 1 ? 's' : ''}` : ''}
                onChange={(e) => handleChangeToCompletion('maximum', 'years', e.target.value)}
              />
            </Grid>
            <Grid item xs={6} className={classes.center}>
              <TextField
                variant="outlined"
                fullWidth
                value={formInfo.maximum.months}
                label={formInfo.maximum.months ? `M${formInfo.maximum.months > 1 ? 'eses' : 'ês'}` : ''}
                onChange={(e) => handleChangeToCompletion('maximum', 'months', e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography>Descrição:</Typography>
          <div className={classes.editor}>
            <Typography className="fadeout" dangerouslySetInnerHTML={createMarkup(formInfo.description)} component="div" paragraph />
            <Button onClick={() => handleDescription()} className="fadein">
              <Edit />
            </Button>
          </div>
        </Grid>
        <Grid container alignItems="center" xs={12}>
          <Checkbox
            color={formInfo.is_courtesy ? 'primary' : 'secondary'}
            checked={formInfo.is_courtesy}
            onChange={(e) => handleChange('is_courtesy', e.target.checked)}
          />
          <Typography>
            {'Incluir como cortesia?'}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ height: 40 }} />
      </Grid>
      {!newProduct && (
        <>
          <Typography style={{ position: 'absolute', left: 16, bottom: 16 }}>
            <Button color="secondary" onClick={handleRemove} className={classes.removeButton} variant="outlined">
              <Delete />
            </Button>
          </Typography>
          <Typography style={{ position: 'absolute', right: 16, bottom: 16 }}>
            <Button
              variant={hasChanged ? 'contained' : 'outlined'}
              color={hasChanged ? 'primary' : 'secondary'}
              disabled={!hasChanged || loading}
              type="submit"
            >
              {'Salvar alterações'}
            </Button>
          </Typography>
        </>
      )}
    </form>
  )
}

ProductInfo.propTyoes = {
  formInfo: PropTypes.shape({}).isRequired,
  setFormInfo: PropTypes.func.isRequired,
  product: PropTypes.shape({}).isRequired,
  classes: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  update: PropTypes.string.isRequired,
  confirmation: PropTypes.func.isRequired,
  setUpdate: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  newProduct: PropTypes.bool,
  mode: PropTypes.oneOf([ADD_MODE, EDIT_MODE]),
};

ProductInfo.defaultProps = {
  mode: EDIT_MODE,
  newProduct: false,
};

const mapStateToProps = (state) => ({
  status: state.product.status,
});

const actions = {
  update: updateProduct,
  confirmation: showConfirmation,
  remove: removeProduct,
};

export default connect(mapStateToProps, actions)(ProductInfo);
