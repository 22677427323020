import { createSelector } from 'reselect';
import * as $_ from 'global/functions';
import { ALL } from 'constants/user';

const allUsers = (state) => state.listUsers.allUsers;

const basicSearchValues = ['name', 'email', 'phone'];

export const filterUsers = createSelector(
  allUsers,
  (userList) => (searchValue, filter, sortBy, sortOrder) => {
    let returnValue = userList;
    if (!returnValue || returnValue.length === 0) return [];
    if (filter && filter !== ALL) {
      returnValue = returnValue.filter((a) => (
        a.user_type === filter
      ));
    }

    if (searchValue) {
      returnValue = returnValue.filter((user) => (
        $_.matchable(
          user,
          basicSearchValues,
          searchValue,
        )
      ));
    }
    if (sortBy) {
      returnValue = returnValue.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return sortOrder ? -1 : 1;
        }
        if (a[sortBy] > b[sortBy]) {
          return sortOrder ? 1 : -1;
        }
        return 0;
      });
    }

    return Array.from(returnValue);
  },
);
