import {
  PAYMENT_CREDIT_CARD, PAYMENT_BANK_SLIP, PAYMENT_BARTE, PAYMENT_MAXI_PAGO, PAYMENT_ELECTRONIC_TERMINAL, PAYMENT_CREDIT_CARD_RECURRENT, PAYMENT_ITAU_BANK_SLIP,
} from 'constants/sales';
import { returnValidDate, stripValue } from 'helpers';

const chargable_interest = [
  PAYMENT_MAXI_PAGO,
  PAYMENT_ELECTRONIC_TERMINAL,
  PAYMENT_BARTE,
];

export const chargable = (value) => chargable_interest.indexOf(value) >= 0;

export const prepareToSendPaymentRow = (form) => {
  const months = form.payment_method === PAYMENT_CREDIT_CARD
    || form.payment_method === PAYMENT_MAXI_PAGO
    || form.payment_method === PAYMENT_BARTE
    || form.payment_method === PAYMENT_BANK_SLIP
    || form.payment_method === PAYMENT_CREDIT_CARD_RECURRENT
    || form.payment_method === PAYMENT_ELECTRONIC_TERMINAL
    || form.payment_method === PAYMENT_ITAU_BANK_SLIP
    ? form.months : 1;
  const interest_percentage = chargable(form.payment_method) ? form.interest_percentage : null;
  const interest_total = chargable(form.payment_method) ? form.interest_total : null;
  return ({
    value: stripValue(`${form.value}`),
    payment_method: form.payment_method,
    due_date: returnValidDate(form.due_date),
    months,
    customer_interest: form.customer_interest,
    interest_percentage,
    interest_total,
  });
};
