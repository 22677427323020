/* eslint-disable react/prop-types */
// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useRef } from 'react';
import { ServiceContext } from 'contexts/service';
import { connect } from 'react-redux';
import {
  Paper, IconButton, Button, Link, Grid, Typography,
} from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
} from '@material-ui/icons';
import * as $_ from 'global/functions';
import clsx from 'clsx';
import Contact from 'global/modules/Contact';
import { showConfirmation } from 'store/actions/confirmations';
import { ALINHAMENTO_ROW } from 'constants/service/menus';
import { confirmAligment } from 'store/actions/sales';
import { SCOPE } from 'constants/service';
import GridDocs from 'service/modules/GridDocs';
import { useStyles } from '../style';

function Row({ scope, ...props }) {
  const classes = useStyles();
  const menuRef = useRef(null);
  const serviceContext = useContext(ServiceContext);
  const {
    multipleSelect,
    setDocsShown,
    clearMultipleSelect,
    setMultipleSelect,
    handleShowMoreInfo,
    setOpenComments,
    openMenu,
  } = serviceContext;
  const thisselected = multipleSelect.indexOf(scope.id) >= 0;
  const handleOpenComments = (newScope, e) => {
    $_.stopPropagation(e);
    setOpenComments(newScope);
  };
  const handleEndStep = (e) => {
    $_.stopPropagation(e);

    const confirmAlign = () => {
      props.confirmAligment(scope.id);
    };

    props.showConfirmation(
      {
        title: 'Confirmar alinhamento',
        message: `Confirmar alinhamento de ${scope.customer.name}?`,
      },
      {
        confirm: 'Confirmar',
        cancel: 'Cancelar',
      },
      {
        func: confirmAlign,
        payload: {},
      },
    );
  };

  return (
    <>
      <Paper
        className={
          clsx(
            classes.body,
            'clickable',
            thisselected
              ? 'selected'
              : 'regular',
          )
        }
        onClick={(e) => {
          if (e.shiftKey || e.ctrlKey) {
            setMultipleSelect(scope.id, e);
          } else {
            handleShowMoreInfo(true, scope, e, SCOPE);
          }
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4} className={classes.centeredGrid}>
            <Contact item={scope} customer={scope.customer} editable />
          </Grid>
          <Grid item xs={2} className={classes.centeredGrid}>
            <Typography variant="body1">
              {$_.semanticDate(scope.created_at, true)}
            </Typography>
          </Grid>
          <GridDocs
            currentClient={scope}
            xs={2}
            scopeId={scope.id}
            clientName={scope.customer.name}
            setDocsShown={setDocsShown}
            docType={SCOPE}
          />
          <Grid item xs={2} className={classes.centeredGrid}>
            <Typography variant="body1">
              <Link
                component="button"
                onClick={(e) => handleOpenComments(scope, e)}
              >
                {'Observações sobre documentação'}
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.centeredGrid}>
            {!thisselected
              && (
                <Button
                  onClick={(e) => {
                    handleEndStep(e);
                    clearMultipleSelect();
                  }}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  {'Alinhamento feito'}
                </Button>
              )}
          </Grid>
        </Grid>
        <IconButton
          ref={menuRef}
          aria-label="Mais ações"
          aria-controls="long-menu"
          aria-haspopup="true"
          className={classes.moreverticon}
          onClick={(e) => {
            $_.stopPropagation(e);
            openMenu(true, ALINHAMENTO_ROW, menuRef.current, scope);
          }}
        >
          <MoreVertIcon />
        </IconButton>
      </Paper>
    </>
  );
}

export default connect(null, { showConfirmation, confirmAligment })(Row);
