import * as definitions from 'store/definitions/sales';
import { DEFAULT, REJECTED, REQUESTING, FULFILLED } from 'constants/common';

function Sales(state = {
  collection: [],
  allSales: [],
  allScopes: [],
  actionStatus: DEFAULT,
  batchStatus: DEFAULT,
  status: DEFAULT,
  fetchMoreStatus: DEFAULT,
  scopeStatus: DEFAULT,
  confirm: DEFAULT,
  currentPage: 1,
  totalPages: 1,
  modular: [],
  modularStatus: DEFAULT,
  scope: {},
  conselhos: [],
  freguesias: []
}, action) {
  switch (action.type) {
    case definitions.DELETE_SCOPE:
    case definitions.SHARE_SALE:
    case definitions.DELETE_SHARE_SALE:
      return {
        ...state,
        actionStatus: REQUESTING,
      };
    case definitions.DELETE_SCOPE_REJECTED:
    case definitions.SHARE_SALE_REJECTED:
    case definitions.DELETE_SHARE_SALE_REJECTED:
      return {
        ...state,
        actionStatus: REJECTED,
      };
    case definitions.DELETE_SCOPE_FULFILLED:
    case definitions.SHARE_SALE_FULFILLED:
    case definitions.DELETE_SHARE_SALE_FULFILLED:
      return {
        ...state,
        actionStatus: FULFILLED,
      };
    case definitions.BATCH_STATUS_FULFILLED:
      return {
        ...state,
        batchStatus: FULFILLED,
      };
    case definitions.BATCH_STATUS_REJECTED:
      return {
        ...state,
        batchStatus: REJECTED,
      };
    case definitions.BATCH_STATUS:
      return {
        ...state,
        batchStatus: REQUESTING,
      };
    case definitions.UPDATE_PERSON:
      return {
        ...state,
        status: REQUESTING,
      };
    case definitions.UPDATE_PERSON_REJECTED:
      return {
        ...state,
        status: REJECTED,
      };
    case definitions.UPDATE_PERSON_FULFILLED:
      return {
        ...state,
        status: FULFILLED,
      };
    case definitions.FETCH_ALL_LEADS_FULFILLED:
    case definitions.FETCH_ALL_ACCOMPANIMENT_FULFILLED:
    case definitions.FETCH_ALL_HISTORIC_FULFILLED:
      return {
        ...state,
        status: FULFILLED,
        totalPages: action.payload.last_page,
        currentPage: action.payload.current_page,
        allSales: [],
        collection: action.add ? [...state.collection, ...action.payload.data] : action.payload.data,
        fetchMoreStatus: action.add ? FULFILLED : state.fetchMoreStatus,
      };
    case definitions.FETCH_LEADS_BY_SALESMAN_FULFILLED:
    case definitions.FETCH_ACCOMPANIMENT_BY_SALESMAN_FULFILLED:
    case definitions.FETCH_HISTORIC_BY_SALESMAN_FULFILLED:
    case definitions.FETCH_SALES_BY_CLIENT_FULFILLED: 
      return {
        ...state,
        status: FULFILLED,
        totalPages: action.payload.last_page,
        currentPage: action.payload.current_page,
        allSales: action.payload.data,
        collection: action.payload.data,
      };
    case definitions.FETCH_TO_ALIGN_FULFILLED:
    case definitions.FETCH_TO_DOCUMENTATION_FULFILLED:
    case definitions.FETCH_CONFIRMATION_SALES_FULFILLED:
      return {
        ...state,
        status: FULFILLED,
        allSales: action.payload.data,
        collection: action.payload.data,
        totalPages: action.payload.last_page,
        currentPage: action.payload.current_page,
      };
    case definitions.FETCH_MODULAR_SALES_BY_CLIENT_FULFILLED:
      return {
        ...state,
        modularStatus: FULFILLED,
        modular: action.payload,
      };
    case definitions.FETCH_ALL_OPEN_SCOPES_FULFILLED:
      return {
        ...state,
        status: FULFILLED,
        allScopes: action.payload,
      };
    case definitions.FETCH_ALL_LEADS_REJECTED:
    case definitions.FETCH_ALL_ACCOMPANIMENT_REJECTED:
    case definitions.FETCH_ALL_HISTORIC_REJECTED:
    case definitions.FETCH_LEADS_BY_SALESMAN_REJECTED:
    case definitions.FETCH_ACCOMPANIMENT_BY_SALESMAN_REJECTED:
    case definitions.FETCH_HISTORIC_BY_SALESMAN_REJECTED:
    case definitions.FETCH_SALES_BY_CLIENT_REJECTED:
    case definitions.FETCH_ALL_OPEN_SCOPES_REJECTED:
    case definitions.FETCH_CONFIRMATION_SALES_REJECTED:
      return {
        ...state,
        status: REJECTED,
        fetchMoreStatus: action.add ? REJECTED : state.fetchMoreStatus,
      };
    case definitions.FETCH_MODULAR_SALES_BY_CLIENT_REJECTED:
      return {
        ...state,
        modularStatus: REJECTED,
      };
    case definitions.FETCH_SALE_SCOPE_BY_SCOPE_ID_REJECTED:
      return {
        ...state,
        scopeStatus: REJECTED,
      };
    case definitions.FETCH_ALL_LEADS:
    case definitions.FETCH_ALL_ACCOMPANIMENT:
    case definitions.FETCH_ALL_HISTORIC:
    case definitions.FETCH_LEADS_BY_SALESMAN:
    case definitions.FETCH_ACCOMPANIMENT_BY_SALESMAN:
    case definitions.FETCH_HISTORIC_BY_SALESMAN:
    case definitions.FETCH_SALES_BY_CLIENT:
    case definitions.FETCH_ALL_OPEN_SCOPES:
    case definitions.FETCH_CONFIRMATION_SALES:
      return {
        ...state,
        status: REQUESTING,
        fetchMoreStatus: action.payload && action.payload.add ? REQUESTING : state.fetchMoreStatus,
      };
    case definitions.FETCH_MODULAR_SALES_BY_CLIENT:
      return {
        ...state,
        modularStatus: REQUESTING,
      };
    case definitions.FETCH_SALE_SCOPE_BY_SCOPE_ID:
      return {
        ...state,
        scopeStatus: REQUESTING,
      };
    case definitions.SALES_SEARCH_RESULT:
      return {
        ...state,
        collection: action.payload,
      };
    case definitions.FETCH_SALE_SCOPE_BY_SCOPE_ID_FULFILLED:
      return {
        ...state,
        scope: action.payload,
        scopeStatus: FULFILLED,
        confirm: DEFAULT,
      };
    case definitions.CONFIRM_SCOPE:
    case definitions.INITIATE_PROCESSES_SALE:
      return {
        ...state,
        confirm: REQUESTING,
      };
    case definitions.CONFIRM_SCOPE_FULFILLED:
    case definitions.INITIATE_PROCESSES_SALE_FULFILLED:
      return {
        ...state,
        confirm: FULFILLED,
      };
    case definitions.CONFIRM_SCOPE_REJECTED:
    case definitions.INITIATE_PROCESSES_SALE_REJECTED:
      return {
        ...state,
        confirm: REJECTED,
      };
    case definitions.RESULTS_CLEAR_DONE:
      return {
        ...state,
        allSales: [],
        collection: [],
        status: DEFAULT,
      };
      case definitions.FETCH_PERSON_CONSELHOS_FULFILLED:
        return {
          ...state,
          conselhos: action.payload,
        };
      case definitions.FETCH_PERSON_FREGUESIAS_FULFILLED:
        return {
          ...state,
          freguesias: action.payload,
        };
    default:
      return {
        ...state,
      };
  }
}

export default Sales;
