import React from 'react';
import { connect } from 'react-redux';
import { Container, Grid, IconButton, Button, InputBase, Typography, MenuItem, ListItemIcon, ListItemText, Menu } from '@material-ui/core';
import { Close as CloseIcon, Search as SearchIcon, CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import { useStyles } from './styles';
import { productSearch } from 'store/actions/product';
import { processCategoriesList as tags } from 'constants/sales';
import AddProduct from './AddProduct';

const Header = ({ products, search, allProducts, status, setUpdate, ...props }) => {
  const classes = useStyles(props);
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [categoriesMenu, setCategoriesMenu] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState('');
  const handleSearchValue = (value) => setSearchValue(value);
  const handleAddTag = (id) => {
    const newTag = selectedTags[0] !== id ? [id] : [];
    setSelectedTags([...newTag]);
  }
  const noTags = () => setSelectedTags([]);
  const clearSearch = () => setSearchValue('');
  const handleCategoriesMenu = (e) => setCategoriesMenu((c) => !Boolean(c) && e.currentTarget ? e.currentTarget : null);

  React.useEffect(() => search(searchValue, selectedTags), [selectedTags, searchValue, search]);

  return (
    <Container maxWidth="xl">
      <Menu
        id="categories-menu"
        anchorEl={categoriesMenu}
        keepMounted
        open={Boolean(categoriesMenu)}
        onClose={(e) => handleCategoriesMenu(e)}
      >
        <MenuItem
          dense
          key="categories-all"
          onClick={() => noTags()}
        >
          <ListItemIcon>
            {selectedTags.length === 0 ? <CheckBox /> : <CheckBoxOutlineBlank />}
          </ListItemIcon>
          <ListItemText primary="Todas" />
        </MenuItem>
        {tags.map((tag) => {
          const selected = selectedTags.indexOf(tag.id) >= 0;
          return (
            <MenuItem
              dense
              key={`categories-menu${tag.label}`}
              onClick={() => handleAddTag(tag.id, 10)}
            >
              <ListItemIcon>
                {selected ? <CheckBox /> : <CheckBoxOutlineBlank />}
              </ListItemIcon>
              <ListItemText primary={tag.label} />
            </MenuItem>
          );
        })}
      </Menu>
      <Grid container spacing={2} className={classes.subMenuHolder}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" color="textSecondary">
            {`Processo${allProducts.length > 1 ? 's' : ''} `}
            {`(${products.length !== allProducts.length
              ? `${products.length} de ${allProducts.length}`
              : `${products.length}`})`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.btnholder}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Buscar..."
              onChange={(e) => handleSearchValue(e.target.value)}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              value={searchValue}
              endAdornment={(
                <IconButton onClick={clearSearch} classes={{ root: classes.closeBtnTiny }} style={{ opacity: Boolean(searchValue) ? 1 : 0 }} size="small">
                  <CloseIcon fontSize="small" classes={{ root: classes.closeBtnTinyIcon }} />
                </IconButton>
              )}
            />
          </div>
          <Button
            variant="contained"
            onClick={(e) => handleCategoriesMenu(e)}
            className={classes.buttonGroup}
            aria-label="Categorias"
          >
            {selectedTags.length === 0 ? 'Categorias' : tags.find((t) => t.id === selectedTags[0]).label}
          </Button>
          <AddProduct setUpdate={setUpdate} />
        </Grid>
      </Grid>
    </Container>
  )
};

const mapStateToProps = (state) => ({
  products: state.product.collection,
  allProducts: state.product.allProducts,
  status: state.product.status,
});

export default connect(mapStateToProps, { search: productSearch })(Header);
