/* eslint-disable react/jsx-props-no-spreading */
// @ts-nocheck
import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import * as Icons from '../sources/Icons';

function NoResults({
  message, opacity, marginRight, style, noPadding, icon, ...props
}) {
  const styles = [
    {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: noPadding ? 0 : '16px 24px',
    },
    {
      minWidth: 180,
      height: 'auto',
      opacity,
      marginRight,
    },
  ];
  let iconElement = [];
  switch (icon) {
    case 'process':
    default:
      iconElement = <Icons.NoProcesses style={{ ...styles[1] }} />;
      break;
    case 'lot':
      iconElement = <Icons.EmptyLot style={{ ...styles[1] }} />;
      break;
  }

  return (
    <Typography
      color="textSecondary"
      style={{ ...styles[0], ...style }}
      {...props}
    >
      {iconElement}
      {message}
    </Typography>
  );
}

NoResults.propTypes = {
  message: PropTypes.string,
  opacity: PropTypes.number,
  marginRight: PropTypes.number,
  style: PropTypes.object,
  noPadding: PropTypes.bool,
  icon: PropTypes.string,
};

NoResults.defaultProps = {
  message: 'Nehum resultado encontrado',
  opacity: 0.7,
  marginRight: 16,
  style: {},
  noPadding: false,
  icon: 'default',
};

export default NoResults;
