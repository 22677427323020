import React from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import { convertCurrency } from 'helpers';
import uniqid from 'uniqid';
import PaymentRow from './PaymentRow';
import { useStyles } from '../style';
import { CANCELLED } from 'constants/common/payments';

function Payments({ hiredServices, item, step }) {
  const classes = useStyles();
  return (
    <div>
      <Grid item xs={12}>
        {hiredServices && Object.keys(hiredServices).length > 0 && (
          <Grid container spacing={2} className={classes.headerContentExpand}>
            <Grid item xs={4}>
              <Typography variant="body1">Serviços contratados</Typography>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="body1" align="right">Valor</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body1">Total</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="right">Pagamentos</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {hiredServices && Object.keys(hiredServices).length > 0 ? (
          <Grid container spacing={2} className={classes.bodyContentExpand}>
            <Grid item xs={3}>
              {Object.keys(hiredServices).map((a) => (
                <Typography key={`servico${hiredServices[a].title}`} variant="body1" color="textSecondary">{`${hiredServices[a].times}x ${hiredServices[a].title}`}</Typography>
              ))}
              <Typography variant="body1" color="textSecondary">Subtotal</Typography>
              <Typography variant="body1" color="textSecondary">Honorários</Typography>
              {item.discount > 0 ? <Typography variant="body1" color="textSecondary">Desconto</Typography> : <Typography variant="body1" color="textSecondary">Sem desconto</Typography>}
              <Typography variant="body1">Total</Typography>
            </Grid>
            <Grid item xs={3}>
              {Object.keys(hiredServices).map((a) => (
                <Typography key={uniqid()} align="right" variant="body1" color="textSecondary">{convertCurrency(hiredServices[a].price)}</Typography>
              ))}
              <Typography variant="body1" color="textSecondary" align="right">{convertCurrency(item.subtotal)}</Typography>
              <Typography variant="body1" color="textSecondary" align="right">{convertCurrency(item.total_honorary)}</Typography>
              {item.discount > 0 && <Typography variant="body1" color="textSecondary" align="right">{`${item.discount}%`}</Typography>}
              <Typography variant="body1" align="right">{convertCurrency(item.total)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                {item.payments.filter(a => a.status !== CANCELLED && a.month_number === 1).map((payment) => (
                  <PaymentRow key={uniqid()} payment={payment} />
                ))}
              </Grid>
            </Grid>
          </Grid>
        ) : (
            <Grid item xs={12}>
              <Typography>Não foram encontrados serviços contratados</Typography>
            </Grid>
        )}
      </Grid>
    </div>
  );
}

export default Payments;