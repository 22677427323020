import React, { useContext } from 'react';
import { DOCSOK_ROW, DOCSOK_BLOCK_ROW } from 'constants/service/menus';
import { ServiceContext } from 'contexts/service';
import {
  MenuItem, Menu,
} from '@material-ui/core';
import { stopPropagation } from 'global/functions';

export default function Menus() {
  const serviceContext = useContext(ServiceContext);
  const { openMenu, anchorEl, currentClient, handleBlockDialog } = serviceContext;
  const handleBlock = (e, block) => {
    openMenu(false, block ? DOCSOK_ROW : DOCSOK_BLOCK_ROW);
    handleBlockDialog(currentClient, block);
    stopPropagation(e);
  }
  return (
    <>
      <Menu
        anchorEl={anchorEl[DOCSOK_ROW]}
        open={Boolean(anchorEl[DOCSOK_ROW])}
        onClose={() => openMenu(false, DOCSOK_ROW)}
      >
        <MenuItem onClick={(e) => handleBlock(e, true)}>
          {'Enviar para travados'}
        </MenuItem>
        <MenuItem>Solicitar cancelamento</MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorEl[DOCSOK_BLOCK_ROW]}
        open={Boolean(anchorEl[DOCSOK_BLOCK_ROW])}
        onClose={() => openMenu(false, DOCSOK_BLOCK_ROW)}
      >
        <MenuItem onClick={(e) => handleBlock(e, false)}>
          {'Retirar de travados'}
        </MenuItem>
        <MenuItem>Solicitar cancelamento</MenuItem>
      </Menu>
    </>
  )
}