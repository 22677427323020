// @ts-nocheck
import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import {
  Typography,
  Paper,
  Grid,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import * as Icons from 'global/sources/Icons';
import { ServiceContext } from 'contexts/service';
import { useStyles } from 'service/style';
import { stopPropagation } from 'global/functions';
import { func, array, object } from 'prop-types';
import { Archive } from '@material-ui/icons';

function LotHeader({ handleDropProcess, lots, lotNames }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const serviceContext = useContext(ServiceContext);
  const { multipleSelect } = serviceContext;
  const selecting = multipleSelect.length > 0;

  const handleMenu = (e) => setAnchorEl((o) => (!o && e.currentTarget ? e.currentTarget : null));

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={handleMenu}
      >
        {Object.keys(lotNames).map((k) => (
          <MenuItem
            key={`lotMenu${k}`}
            onClick={() => { handleMenu(); handleDropProcess(null, k); }}
          >
            {lotNames[k]}
          </MenuItem>
        ))}
      </Menu>
      <Typography
        className={clsx(classes.pageHeader, 'smallMargin')}
        variant="h5"
        color="textSecondary"
      >
        {`Pacotes (${lots.length})`}
      </Typography>
      <Paper className={clsx(classes.header, 'envioProcess', 'noFlex')}>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ padding: 0, textAlign: 'right' }}>
            <Button
              className={classes.buttonGroup}
              variant="contained"
              color="primary"
              disabled={!selecting}
              onClick={(e) => { handleDropProcess(); stopPropagation(e); }}
              classes={{
                root: clsx(
                  classes.actions,
                  selecting
                    ? 'shown'
                    : 'hidden',
                ),
                containedPrimary: classes.alertButton,
              }}
            >
              <Icons.AddLot className={classes.iconMarginRight} />
              {'Criar pacote'}
            </Button>
            {Object.keys(lotNames).length > 0 && (
              <Button
                className={clsx(classes.buttonGroup, classes.iconMargin)}
                variant="contained"
                color="primary"
                disabled={!selecting}
                onClick={(e) => { handleMenu(e); stopPropagation(e); }}
                classes={{
                  root: clsx(
                    classes.actions,
                    selecting
                      ? 'shown'
                      : 'hidden',
                  ),
                  containedPrimary: classes.alertButton,
                }}
              >
                <Archive className={classes.iconMarginRight} />
                {'Adicionar a um pacote'}
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

LotHeader.propTypes = {
  handleDropProcess: func.isRequired,
  lots: array.isRequired,
  lotNames: object.isRequired,
};

export default LotHeader;
