// @ts-nocheck
import { makeStyles } from '@material-ui/core';

export const useStylesMenu = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'transparent',
  },
  margin: {
    marginBottom: theme.spacing(4),
  },
  tab: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    minWidth: (props) => props.minMenuItemWidth,
  },
  tabRippleBg: {
    '& .MuiTouchRipple-root': {
      zIndex: 0,
      backgroundColor: 'transparent',
    },
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: `${theme.palette.common.white} !important`,
    height: '100% !important',
    zIndex: 0,
  },
  tabWrapper: {
    zIndex: '1',
  },
  container: {
    padding: 0,
    marginTop: 0,
  },
}));
