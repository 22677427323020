import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from 'service/style';

function EnvioHistoricHeader({ lotsSize }) {
  const classes = useStyles();
  return (
    <Typography
      className={classes.pageHeader}
      variant="h5"
      color="textSecondary">
      {`Pacote${lotsSize > 1 ? 's' : ''} (${lotsSize})`}
    </Typography>
  );
}

export default EnvioHistoricHeader;
