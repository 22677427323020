import moment from "moment";

// UPLOAD DOCUMENTS
export const MAXIMUM_FILES_ALLOWED = 10;
export const UPLOAD_TEXT = "Anexar documento";
export const MAXIMUM_TEXT = "Limite de documentos anexados atingido.";

/* SORTING */

export const DESC = 'desc';
export const ASC = 'asc';

/* * */

export const IUGU_MAX_VALUE = 700000;

export const salesMenu = [{
        name: 'Vendas',
        title: 'Vendas em aberto',
    },
    {
        name: 'Acompanhamento',
        title: 'Vendas em curso',
    },
    {
        name: 'Histórico',
        title: 'Vendas passadas',
    },
];

export const salesMasterMenu = [{
        name: 'Vendas',
        title: 'Todas as vendas em aberto',
    },
    {
        name: 'Acompanhamento',
        title: 'Todas as vendas em curso',
    },
    {
        name: 'Histórico',
        title: 'Todas as vendas passadas',
    },
];

export const salesMasterTabs = [{
        name: 'Todas as vendas',
        title: 'Todas as vendas',
    },
    {
        name: 'Vendas',
        title: 'Vendas',
    },
];

export const processCategories = {
    0: 'Processo de cidadania',
    1: 'Mudança de status',
    2: 'Busca',
    3: 'Verificação',
    4: 'Visto',
    5: 'Inativos',
};

export const processCategoriesList = Object
    .keys(processCategories)
    .map((id) => ({
        id: parseInt(id, 10),
        label: processCategories[id],
    }));

export const relations = [
    ['Filho', 'Filha'],
    ['Cônjuge', 'Cônjuge'],
    ['Neto', 'Neta'],
]

export const sameRelation = 'PROPRIO';

export const dashboardItemsMap = [{
        title: 'Venda',
        items: [
            { title: 'Em andamento', value: 1 },
            { title: 'Concluída', value: 2 },
        ],
    },
    {
        title: 'Contrato',
        items: [
            { title: 'Pendente', value: 2 },
            { title: 'Assinado', value: 3 },
        ],
    },
    {
        title: 'Pagamento',
        items: [
            { title: 'Pendente', value: 3 },
            { title: 'Pago', value: 4 },
        ],
    },
    {
        title: 'Serviços',
        items: [
            { title: 'Não iniciado', value: 4 },
            { title: 'Iniciado', value: 5 },
        ],
    },
];

export const itemsSort = [
    { title: 'Nome', obj: 'name' },
    { title: 'Etapa', obj: 'step' },
];


export const salesForm = {
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    certificate: '',
    city: '',
    clientName: '',
    country: '',
    cpf: '',
    discount: '',
    email: '',
    honorary: '',
    job: '',
    name: '',
    clientId: '',
    nationality: '',
    payment: '',
    phase: '',
    phone: '',
    portugueseInfo: [],
    priceFees: '',
    priceHonorary: '',
    process: '',
    quota: '',
    rg: '',
    rgEmittedBy: '',
    rgEmittedDate: '',
    sellerPhone: '',
    state: '',
    status: '',
    type: '',
    zip_code: '',
};

export const portugueseInfoForm = {
    birthdate: '',
    birthplace: '',
    certificate_id: 3,
    certificate_type: '',
    assento_year: '',
    crc: '',
    concelho_id: null,
    freguesia_id: null,
    father_name: '',
    freguesia: null,
    concelho: null,
    mother_name: '',
    name: '',
    consulate: '',
    document_number: '',
    docs: [],
    documents: [],
    fulfilled: false,
    second_sale: false
}

export const processForm = {
    applicantId: 'new',
    apostilate: false,
    use_old_price: false,
    name: '',
    gender: '',
    birthdate: '',
    hosts: '',
    is_courtesy: false,
    parent_type: '',
    clientId: '',
    products: [],
    product: [],
    extraProducts: [],
    extraProductsSelection: [],
    premium_service: false,
    documents: [],
}

export const FILL_LATER_TEXT = 'A confirmar';
export const UNDEFINED_FORM_CERTIFICATE_TYPE = 0;
export const FREGUESIA_FORM_CERTIFICATE_TYPE = 2;
export const CONSULATE_FORM_CERTIFICATE_TYPE = 3;

export const PEOPLE_FORM_MODE_NEW = 'PEOPLE_FORM_MODE_NEW';
export const PEOPLE_FORM_MODE_EDIT = 'PEOPLE_FORM_MODE_EDIT';

export const PROCESS_FORM_MODE_NEW = 'PROCESS_FORM_MODE_NEW';
export const PROCESS_FORM_MODE_EDIT = 'PROCESS_FORM_MODE_EDIT';

export const PAYMENT_CASH = 'CASH';
export const PAYMENT_BANK_TRANSFER = 'BANK_TRANSFER';
export const PAYMENT_BANK_SLIP = 'BANK_SLIP';
export const PAYMENT_ITAU_BANK_SLIP = 'ITAU_BANK_SLIP';
export const PAYMENT_CREDIT_CARD = 'CREDIT_CARD';
export const PAYMENT_MAXI_PAGO = 'MAXI_PAGO';
export const PAYMENT_BARTE = 'BARTE';
export const PAYMENT_ELECTRONIC_TERMINAL = 'CREDIT_CARD_MACHINE';
export const PAYMENT_CREDIT_CARD_RECURRENT = 'CREDIT_CARD_RECURRENT';

export const paymentMethodTitles = {
    [PAYMENT_CASH]: 'Dinheiro',
    // [PAYMENT_BANK_SLIP]: 'Boleto',
    [PAYMENT_ITAU_BANK_SLIP]: 'Boleto Itaú',
    [PAYMENT_BANK_TRANSFER]: 'Transferência',
    [PAYMENT_ELECTRONIC_TERMINAL]: 'Cartão (maquininha)',
    // [PAYMENT_CREDIT_CARD]: 'Cartão de crédito (iugu)',
    [PAYMENT_MAXI_PAGO]: 'Cartão de crédito (maxiPago)',
    [PAYMENT_BARTE]: 'Barte',
    [PAYMENT_CREDIT_CARD_RECURRENT]: 'Pagamento recorrente cartão de crédito',
};

export const PAYMENT_STATUS_NEW = 'NEW';
export const PAYMENT_STATUS_OPEN = 'OPEN';
export const PAYMENT_STATUS_PAID = 'PAID';
export const PAYMENT_STATUS_OVERDUE = 'OVERDUE';
export const PAYMENT_STATUS_CANCELLED = 'CANCELED';
export const PAYMENT_STATUS_ERROR = 'TRANSACTION_ERROR';

export const paymentStatusTitles = (payment) => {
    switch (payment) {
        case PAYMENT_STATUS_NEW:
            return 'Pagamento cadastrado';
        case PAYMENT_STATUS_OPEN:
            return 'Em aberto';
        case PAYMENT_STATUS_OVERDUE:
            return 'Atrasado';
        case PAYMENT_STATUS_CANCELLED:
            return 'Cancelado';
        case PAYMENT_STATUS_ERROR:
            return 'Erro na transação';
        case PAYMENT_STATUS_PAID:
            return 'Pago';
        default:
            return 'Desconhecido';
    }
};

export const paymentRow = {
    due_date: moment().add(2, 'days'),
    months: 1,
    value: '',
    payment_method: PAYMENT_CREDIT_CARD,
    interest_percentage: 0,
    customer_interest: false,
    interest_total: 0,
    chargeInterest: false,
};

export const filterItems = [{
        title: 'Venda',
        items: [{
                title: 'Em andamento',
                value: 0,
            },
            {
                title: 'Concluída',
                value: 1,
            },
        ],
    },
    {
        title: 'Contrato',
        items: [{
                title: 'Pendente',
                value: 1,
            },
            {
                title: 'Assinado',
                value: 2,
            },
        ],
    },
    {
        title: 'Pagamento',
        items: [{
                title: 'Pendente',
                value: 2,
            },
            {
                title: 'Pago',
                value: 3,
            },
        ],
    },
    {
        title: 'Serviços',
        items: [{
                title: 'Não iniciado',
                value: 3,
            },
            {
                title: 'Iniciado',
                value: 4,
            },
            {
                title: 'Alinhado',
                value: 5,
            },
        ],
    },
];

export const interestSuccessions = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 8.42, 9.84, 10.4, 11.1, 11.7, 12.4, 13.07, 13.72, 14.37, 15.02, 15.67, 16.32, 16.97, 17.62, 18.27]; // orginaal
export const interestGenerations = [0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 11.7, 12.4, 13.07, 13.72, 14.37, 15.02, 15.67, 16.32, 16.97, 17.62, 18.27]; // orginaal
// export const interest = [1.92, 3.52, 4.09, 4.67, 5.26, 5.85, 6.92, 7.53, 8.15, 8.77, 9.40, 10.04]; // itau
export const interestSalesManSuccessions = [0.0, 3.26, 3.76, 4.24, 4.72, 7.60, 7.77, 8.96, 9.49, 10.01, 10.53, 11.05, 11.57, 12.08, 12.60, 13.12, 13.63, 14.15, 14.67, 15.19, 15.70]; // original
export const interestSalesManGenerations = [0.0, 3.26, 3.76, 4.24, 4.72, 7.60, 7.77, 8.96, 9.49, 10.01, 10.53, 11.05, 11.57, 12.08, 12.60, 13.12, 13.63, 14.15, 14.67, 15.19, 15.70]; // original
// export const interestSalesMan = [1.88, 3.40, 3.93, 4.46, 4.99, 5.52, 6.48, 7.01, 7.54, 8.07, 8.60, 9.13]; // itau

export const paymentInstallments = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
export const paymentSpecialInstallments = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
export const interestSalesMan = [0.0, 3.26, 3.76, 4.24, 4.72, 7.60, 7.77, 8.96, 9.49, 10.01, 10.53, 11.05, 11.57, 12.08, 12.60, 13.12, 13.63, 14.15, 14.67, 15.19, 15.70]; // juros do vendendor atual
export const interest = [0.0, 3.38, 3.91, 4.44, 4.97, 8.23, 8.42, 9.84, 10.4, 11.12, 11.77, 12.42, 13.07, 13.72, 14.37, 15.02, 15.67, 16.32, 16.97, 17.62, 18.27]; // juros do cliente atual
export const installmentsInterest = interest.map((val, index) => ({ monthly: (val / (index + 1)), total: val }));

export const getInstallmentInterestsListByPlan = (toFamily) => (toFamily === "BIG_PORTUGUESE_FAMILY" || toFamily === "SMALL_PORTUGUESE_FAMILY") ? paymentSpecialInstallments : paymentInstallments;
export const getInstallmentInterestsList = (toClient, toFamily) => {
  if(toClient) {
    if(toFamily === "SMALL_PORTUGUESE_FAMILY") {
      return interestSuccessions
    }
    if(toFamily === "BIG_PORTUGUESE_FAMILY") {
      return interestGenerations
    }
    return interest
  } else {
    if(toFamily === "SMALL_PORTUGUESE_FAMILY") {
      return interestSalesManSuccessions
    }
    if(toFamily === "BIG_PORTUGUESE_FAMILY") {
      return interestSalesManGenerations
    }
    return interestSalesMan
  }
};

export const getInstallmentsInterests = (toClient, toFamily) => {
  if(toClient) {
    if(toFamily === "SMALL_PORTUGUESE_FAMILY") {
      return interestSuccessions.map((val, index) => ({ monthly: (val / (index + 1)), total: val }));
    }
    if(toFamily === "BIG_PORTUGUESE_FAMILY") {
      return interestGenerations.map((val, index) => ({ monthly: (val / (index + 1)), total: val }));
    }
    return interest.map((val, index) => ({ monthly: (val / (index + 1)), total: val }));
  } else {
    if(toFamily === "SMALL_PORTUGUESE_FAMILY") {
      return interestSalesManSuccessions.map((val, index) => ({ monthly: (val / (index + 1)), total: val }));
    }
    if(toFamily === "BIG_PORTUGUESE_FAMILY") {
      return interestSalesManGenerations.map((val, index) => ({ monthly: (val / (index + 1)), total: val }));
    }
    return interestSalesMan.map((val, index) => ({ monthly: (val / (index + 1)), total: val }));
  }
}

export const CLIENT_FORM_NAME_CUSTOMER_NEED_ALIGNMENT_QUESTION = "customer_need_alignment";
export const CLIENT_FORM_LABEL_CUSTOMER_NEED_ALIGNMENT_QUESTION = "O cliente precisa de alinhamento?";
export const CLIENT_FORM_OPTIONS_CUSTOMER_NEED_ALIGNMENT = [
    { label: 'Sim, com urgência!', value: 'YES_URGENT' },
    { label: 'Sim, delicado! Só mandar e-mail de documentação após alinhamento!', value: 'YES_DELICATE' },
    { label: 'Sim, normal', value: 'YES_NORMAL' },
    { label: 'Não precisa de alinhamento', value: 'DONT_NEED' },
]

export const CLIENT_FORM_NAME_CUSTOMER_INTERESTED_SHARING_FAMILIY = "customer_interested_sharing_family";
export const CLIENT_FORM_LABEL_CUSTOMER_INTERESTED_SHARING_FAMILIY = "O cliente manifesta interesse em passar para outros familiares?";
export const CLIENT_FORM_OPTIONS_CUSTOMER_INTERESTED_SHARING_FAMILIY = [
    { label: 'Sim, está testando primeiro', value: 'YES_TESTING_FIRST' },
    { label: 'Outras pessoas podem ter interesse', value: 'OTHER_PEOPLE_CAN_BE_INTERESTED' },
    { label: 'Não', value: 'NO' },
]

export const CLIENT_FORM_NAME_BEST_WAY_COMUNICATE = "best_way_communicate";
export const CLIENT_FORM_LABEL_BEST_WAY_COMUNICATE = "Qual é o melhor meio para se comunicar com o cliente?";
export const CLIENT_FORM_OPTIONS_BEST_WAY_COMUNICATE = [
    { label: 'Telefone', value: 'TELEPHONE' },
    { label: 'Whatsapp', value: 'WHATSAPP' },
    { label: 'E-mail', value: 'EMAIL' },
];

export const CLIENT_FORM_NAME_WHAT_CLIENT_WANT_CITIZENSHIP = "what_client_want_citizenship";
export const CLIENT_FORM_LABEL_WHAT_CLIENT_WANT_CITIZENSHIP = "Por que o cliente quer tirar a cidadania?";
export const CLIENT_FORM_OPTIONS_WHAT_CLIENT_WANT_CITIZENSHIP = [
    { label: 'Estudar na Europa', value: 'TO_STUDY' },
    { label: 'Trabalhar na Europa', value: 'TO_WORK' },
    { label: 'Morar na Europa', value: 'TO_LIVE' },
    { label: 'Presentear a família', value: 'GIFT_FAMILY' },
    { label: 'Futuro dos filhos', value: 'CHILDREN_FUTURE' },
    { label: 'Facilidades da cidadania', value: 'FACILITIES' },
    { label: 'Status', value: 'STATUS' },
    { label: 'Garantir o direito', value: 'GUARANTEE_RIGHT' },
];

export const CLIENT_FORM_NAME_CLIENT_URGENCY = "client_urgency";
export const CLIENT_FORM_LABEL_CLIENT_URGENCY = "Qual é a urgência do cliente?";
export const CLIENT_FORM_OPTIONS_CLIENT_URGENCY = [
    { label: 'Só falta isso para se mudar para outro país', value: 'NEED_IT_ONLY' },
    { label: 'Tem urgência, mas não depende disso', value: 'HAVE_URGENCY' },
    { label: 'Não tem urgência', value: 'HAVE_NOT_URGENCY' },
];

export const CLIENT_FORM_NAME_CLINET_RELATION_WITH_TECHNOLOGY = "client_relation_with_technology";
export const CLIENT_FORM_LABEL_CLINET_RELATION_WITH_TECHNOLOGY = "Como é a relação do seu cliente com tecnologia?";
export const CLIENT_FORM_OPTIONS_CLINET_RELATION_WITH_TECHNOLOGY = [
    { label: 'Boa, consegue fazer tudo sem dificuldades', value: 'GOOD' },
    { label: 'Tem dificuldades, precisa pedir ajuda, mas consegue fazer.', value: 'HAVE_LITTLE_DIFFICULT' },
    { label: 'É difícil até pra abrir um e-mail', value: 'HAVE_DIFFICULT' },
];

export const CLIENT_FORM_NAME_CLIENT_TYPE = "client_type";
export const CLIENT_FORM_LABEL_CLIENT_TYPE = "O seu cliente é…";
export const CLIENT_FORM_OPTIONS_CLIENT_TYPE = [
    { label: 'De idade, tem dificuldade com meios online.', value: 'OLD_PERSON' },
    { label: 'Adulto objetivo e sem tempo pra nada.', value: 'ADULT_NOT_HAVE_TIME' },
    { label: 'Cri cri, questiona, reclama e pontua todo detalhe.', value: 'EXIGENT_CLIENT' },
    { label: 'De boas, animado com a cidadania!', value: 'FRIENDLY' },
    { label: 'Arrogante, acha que sabe mais do que a gente.', value: 'ARROGANT' },
    { label: 'Fofinho, acha que somos os netinhos dele.', value: 'CUTE' },
    { label: 'Indiferente, pra ele é só uma contratação de um serviço como outro qualquer', value: 'INDIFFERENT' },
];

export const CLIENT_FORM_NAME_WANT_WHATSAPP_GROUP = "need_whatsapp_group";
export const CLIENT_FORM_LABEL_WANT_WHATSAPP_GROUP = "Quer grupo no whats?";

export const CLIENT_FORM_NAME_FUP_INTENSITY = "fup_intensity";
export const CLIENT_FORM_LABEL_FUP_INTENSITY = "Intensidade do FUP?";
export const CLIENT_FORM_OPTIONS_FUP_INTENSITY = [
    { label: 'Padrão normal', value: 'NORMAL' },
    { label: 'Quer contato toda hora, independente de ter atualização', value: 'ALL_TIME' },
    { label: 'Comunicar as atualizações', value: 'ALL_UPDATES' },
    { label: 'Só atualizações importantes', value: 'IMPORTANT_UPDATES_ONLY' },
];
