import { SHOW, HIDE } from 'store/definitions/notifications';

export function showNotification(variant, message) {
  return {
    type: SHOW,
    payload: { variant, message }
  }
}

export function hideNotification() {
  return {
    type: HIDE
  }
}
