import * as definitions from 'store/definitions/comments';

export function commentAll(id, text, isPublic, stepId) {
  return {
    type: definitions.COMMENT_ALL_PROCESS_BY_SALE,
    payload: { id, text, isPublic, stepId },
  };
}

export function processComment(id, text, isPublic, stepId) {
  return {
    type: definitions.POST_COMMENT_PROCESS,
    payload: { id, text, isPublic, stepId },
  };
}

export function eraseComment(id) {
  return {
    type: definitions.ERASE_COMMENT,
    payload: { id },
  };
}
