/* eslint-disable max-lines */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import uniqid from 'uniqid';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Axios from 'axios';
import { apiBaseUrl } from 'api';
import { PROCESS_PATH } from 'api/paths';
import { COMMENT_TYPE_ALL, COMMENT_TYPE_PROCESS } from 'constants/common/comments';
import {
  Close as CloseIcon,
  //  MoreVert as MoreVertIcon,
  NavigateBefore,
} from '@material-ui/icons';
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import Comments from 'global/Comments';
import { fetchModularClientSales } from 'store/actions/sales';
import { STEP_ALIGNMENT } from 'constants/process';
import { PROCESS } from 'constants/service';
import { useStyles } from './style';
import Imports from './Imports';
import HeaderGrid from './HeaderGrid';
import InfosGrid from './InfosGrid';
import DetailsGrid from './DetailsGrid';
import { returnCustomerObj } from './functions';
import { LoadingComponent } from '../Loading';

function MoreInfo({
  open,
  client,
  handleFunc,
  viewType,
  sales,
  clientSales,
  steps,
  update,
  setUpdate,
  modularStatus,
  ...props
}) {
  const [currentCustomer, setCurrentCustomer] = useState({ ...client });
  const [type, setType] = useState(viewType);
  const [loadingProcess, setLoadingProcess] = useState(false);
  const [setThis, setSethis] = useState(uniqid());
  const fetchClientSales = clientSales;
  const [isProcess, setIsProcess] = useState(true);
  const classes = useStyles(props);
  const infoObj = {
    scopeId: '',
    processId: '',
    clientName: '',
    customer: {},
    hosts: [],
    products: [{ title: '' }],
    processes: [],
    comments: [],
    salesman: {},
  };
  const [docsShown, setDocsShown] = useState(false);
  const [infos, setInfos] = useState(infoObj);
  const handleDocsShown = () => setDocsShown(!docsShown);

  const [subset, setSubset] = useState(false);
  const handleCloseDialog = () => {
    handleFunc(false);
  };
  const getStepId = (name) => {
    const stepObj = steps.find(a => a.name === name);
    return stepObj ? stepObj.id : null;
  };
  const handleSubset = (newSubset) => {
    const undoSubset = () => {
      setSubset(false);
      setCurrentCustomer(client);
      setType(viewType);
      setIsProcess(viewType === PROCESS);
      setSethis(uniqid());
      setLoadingProcess(false);
    };
    if (newSubset && newSubset.id) {
      setIsProcess(true);
      setLoadingProcess(true);
      Axios
        .get(`${apiBaseUrl}/${PROCESS_PATH}/${newSubset.id}`)
        .then((res) => {
          const { process } = res.data;
          setSubset(true);
          setCurrentCustomer(process);
          setType(PROCESS);
          setSethis(uniqid());
        })
        .catch(() => undoSubset());
    } else undoSubset();
  };

  useEffect(() => {
    if (!subset) {
      setType(viewType);
      setIsProcess(viewType === PROCESS);
    }
    // eslint-disable-next-line
  }, [viewType, subset]);

  useEffect(() => {
    if (client) setCurrentCustomer(client);
    // eslint-disable-next-line
  }, [client]);

  useEffect(() => {
    const processes = [];
    sales.forEach((sale) => {
      processes.push(...sale.processes);
    });
    setInfos({
      ...infos,
      processes,
    });
    // eslint-disable-next-line
  }, [sales, update]);

  useEffect(() => {
    if (Object.keys(currentCustomer).length > 0) {
      setInfos({
        ...returnCustomerObj(
          currentCustomer,
          fetchClientSales,
          isProcess,
          infoObj,
        ),
      });
      setLoadingProcess(false);
    }
    // eslint-disable-next-line
  }, [currentCustomer, client, type, fetchClientSales, update, isProcess]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        fullWidth={!isMobile}
        fullScreen={isMobile}
        maxWidth={isMobile ? false : 'md'}
        scroll="body"
      >
        <DialogContent className={clsx(classes.dialogMoreInfo)}>
          <IconButton onClick={() => handleCloseDialog()} className={classes.closeBtn}>
            <CloseIcon />
          </IconButton>
          {/* }
          <IconButton
            aria-label="More"
            aria-controls="long-menu"
            aria-haspopup="true"
            className={classes.moreverticonDialog}
            onClick={() => handleCloseDialog()}
          >
            <MoreVertIcon />
          </IconButton>
  { */}
          <div className={subset ? classes.subset : 'default'}>
            {subset && (
              <Button
                variant="contained"
                color="primary"
                className={classes.previousButton}
                onClick={() => handleSubset()}
              >
                <NavigateBefore />
                {'Voltar'}
              </Button>
            )}
            {currentCustomer && Object.keys(currentCustomer).length > 0 && (
              <>
                <HeaderGrid
                  isProcess={isProcess}
                  infos={infos}
                  type={type}
                  handleCloseDialog={handleCloseDialog}
                  handleDocsShown={handleDocsShown}
                />
                <InfosGrid
                  client={currentCustomer}
                  isProcess={isProcess}
                  infos={infos}
                  classes={classes}
                />
                <DetailsGrid
                  currentCustomer={currentCustomer}
                  infos={infos}
                  setUpdate={setUpdate}
                  isProcess={isProcess}
                  classes={classes}
                  handleSubset={handleSubset}
                  modularStatus={modularStatus}
                />
              </>
            )}
            {loadingProcess && (
              <LoadingComponent height={170} />
            )}
            <>
              <Comments
                allowed
                whenThereAreResults={<Typography variant="h5" gutterBottom>{`Comunicação interna sobre ${isProcess ? 'o processo' : 'a venda'}`}</Typography>}
                label="Escreva sua observação"
                header="Fazer observação"
                commentList={infos.comments}
                setUpdate={setUpdate}
                update={update}
                master
                salesId={currentCustomer.id}
                stepId={isProcess ? currentCustomer.step_id : getStepId(STEP_ALIGNMENT)}
                commentType={isProcess ? COMMENT_TYPE_PROCESS : COMMENT_TYPE_ALL}
              />
            </>
          </div>
        </DialogContent>
      </Dialog>
      <Imports
        update={update}
        currentClient={currentCustomer}
        setUpdate={setUpdate}
        objType={type}
        setThis={setThis}
        docsShown={docsShown}
        setDocsShown={handleDocsShown}
      />
    </>
  );
}

MoreInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  client: PropTypes.object.isRequired,
  handleFunc: PropTypes.func.isRequired,
  viewType: PropTypes.string,
  sales: PropTypes.array,
};

MoreInfo.defaultProps = {
  viewType: PROCESS,
  sales: [],
};

const mapStateToProps = (state) => ({
  sales: state.sales.modular,
  steps: state.steps.collection,
  modularStatus: state.sales.modularStatus,
});

export default connect(mapStateToProps, { clientSales: fetchModularClientSales })(MoreInfo);
