import * as definitions from 'store/definitions/users';
import { DEFAULT, REQUESTING, FULFILLED, REJECTED } from 'constants/common';

function Users(state = {
  users: {
    data: [],
    last_page: 0,
    page: 1
  },
  allUsers: {
    data: []
  },
  fetchStatus: DEFAULT,
  status: DEFAULT,
}, action) {
  switch (action.type) {
    case definitions.FETCH_ALL_USERS_FULFILLED:
      return {
        ...state,
        fetchStatus: FULFILLED,
        allUsers: action.payload,
        users: action.payload,
      };
    case definitions.FETCH_ALL_USERS_REJECTED:
      return {
        ...state,
        fetchStatus: REJECTED,
      };
    case definitions.FETCH_ALL_USERS:
      return {
        ...state,
        fetchStatus: REQUESTING,
      };
    case definitions.USERS_SEARCH_RESULT:
      return {
        ...state,
        users: action.payload,
      };
    case definitions.UPDATE_USER:
      return {
        ...state,
        status: REQUESTING,
      };
    case definitions.UPDATE_USER_FULFILLED:
      return {
        ...state,
        status: FULFILLED,
      };
    case definitions.UPDATE_USER_REJECTED:
      return {
        ...state,
        status: REJECTED,
      };
    default:
      return state;
  }
}

export default Users;
