import React from 'react';
import uniqid from 'uniqid';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Grid, Typography, Checkbox, Tooltip, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Link,
} from '@material-ui/core';
import { semanticDate } from 'global/functions';
import { nonApplicableDocument } from 'store/actions/documents';
import { DEFAULT, REQUESTING } from 'constants/common';

function DocRow({
  document,
  classes,
  cascade,
  toConfirm,
  setToConfirm,
  allArrived,
  moduleStatus,
  setRefetch,
  nonApplicables,
  processId,
  updateNonApplicable,
}) {
  const [requesting, setRequesting] = React.useState(false);
  const [notApplicable, setNonApplicable] = React.useState(false);
  React.useEffect(() => {
    if (nonApplicables) setNonApplicable(nonApplicables.indexOf(document.id) >= 0);
  }, [nonApplicables, document.id, cascade]);
  const handleApplicable = () => {
    if (!requesting) {
      const newNonApplicables = notApplicable
        ? nonApplicables.filter((a) => a !== document.id)
        : [...nonApplicables, document.id];
      setRequesting(true);
      updateNonApplicable(processId, newNonApplicables);
    }
  };
  const handleCheck = () => {
    const newToConfirm = toConfirm;
    if (toConfirm.indexOf(document.id) >= 0) {
      _.remove(newToConfirm, (n) => n === document.id);
    } else {
      newToConfirm.push(document.id);
    }
    setToConfirm([...newToConfirm]);
  };

  React.useEffect(() => {
    if (moduleStatus !== DEFAULT && moduleStatus !== REQUESTING && requesting) {
      setRequesting(false);
      setRefetch(uniqid());
    }
  }, [moduleStatus, setRefetch, requesting]);

  return (
    <Grid key={document.id} container spacing={0}>
      <Grid item xs={12}>
        <ListItem
          key={`action${document.id}`}
          role={undefined}
          dense
          disabled={document.delivered || notApplicable}
          button
          onClick={() => handleCheck()}
        >
          <ListItemIcon>
            <Tooltip title="Documento chegou">
              <Checkbox
                edge="start"
                tabIndex={-1}
                disableRipple
                name={`doc${document.id}`}
                checked={toConfirm.indexOf(document.id) >= 0 || document.delivered}
                classes={{ root: classes.listIndent, checked: classes.checked, disabled: classes.checkboxDisabled }}
                inputProps={{
                  'aria-labelledby': `label${document.id}`
                }}
              />
            </Tooltip>
          </ListItemIcon>
          <ListItemText
            id={`label${document.id}`}
            primary={(
              <Typography variant="subtitle2">
                {document.name}
                {document.delivered
                  && (
                    <Typography
                      component="span"
                      color="textPrimary"
                      className={classes.dateofarrival}
                      variant="body2"
                    >
                      {`- Chegou em ${semanticDate(document.delivered_at)}`}
                    </Typography>
                  )}
                {!document.delivered && notApplicable && (
                  <Typography
                    component="span"
                    color="textPrimary"
                    className={classes.dateofarrival}
                    variant="body2"
                  >
                    {'- Documento não se aplica ao processo'}
                  </Typography>
                )}
              </Typography>
            )}
          />
          <ListItemSecondaryAction>
            <Link component="button" variant="subtitle2" style={{ textDecoration: 'underline' }} onClick={() => handleApplicable()}>
              <Typography variant="subtitle1">
                {notApplicable ? 'Se aplica' : 'Não se aplica'}
              </Typography>
            </Link>
          </ListItemSecondaryAction>
        </ListItem>
      </Grid>
    </Grid>
  );
}

DocRow.propTypes = {
  document: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  toConfirm: PropTypes.array.isRequired,
  setToConfirm: PropTypes.func.isRequired,
  allArrived: PropTypes.bool.isRequired,
};

const actions = {
  updateNonApplicable: nonApplicableDocument,
}

const mapStateToProps = (state) => ({
  moduleStatus: state.documents.moduleStatus,
});

export default connect(mapStateToProps, actions)(DocRow);
