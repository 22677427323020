import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: 250,
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  logoImg: {
    width: 340,
    height: 'auto',
    display: 'block',
    margin: `${theme.spacing(3)}px auto ${theme.spacing(11)}px`,
    maxWidth: '80%',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: 0,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
  startButton: {
    display: 'block',
    margin: `${theme.spacing(4)}px auto`,
    padding: theme.spacing(2, 2),
  },
  button: {
    marginTop: theme.spacing(4),
  },
  sendButton: {
    marginTop: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 0,
    width: 240,
    maxWidth: '100%',
    display: 'block',
  },
}));
