import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import uniqid from 'uniqid';
import ActionsContext from 'contexts/actions';
import { Grid, Paper, Typography } from '@material-ui/core';
import TabHeader from 'sales/dashboard/components/TabHeader';
import TabRowSales from 'sales/dashboard/components/TabRowSales';
import { fetchLeadsBySalesman } from 'store/actions/sales';
import { LoadingComponent } from 'global/modules/Loading';
import { REQUESTING, FULFILLED } from 'constants/common';
import NoResults from 'global/modules/NoResults';
import { useStyles } from './style';
import { getCancelToken, cancel } from 'api';
import { Pagination } from '@material-ui/lab';

function TabSales({
  tab, tabKey, leadsReq, update, status, fetch, actionStatus, ...props
}) {
  const classes = useStyles(props);
  const { reload } = useContext(ActionsContext);
  const [leads, setLeads] = useState([...leadsReq]);
  const [requesting, setRequesting] = useState(false);
  const cancelSource = React.useRef(null);
  const [page, setPage] = useState(1)

  const handleCurrentPage = (e, page) => {
    setPage(page)
  }

  useEffect(() => {
    if (status === FULFILLED && requesting) {
      setRequesting(false);
    }
    setLeads([...leadsReq]);
  }, [status, leadsReq, requesting]);

  useEffect(() => {
    cancelSource.current = getCancelToken().source();
    if (!requesting) {
      setRequesting(true);
      fetch(cancelSource.current.token, page);
    }
    return () => cancel(cancelSource.current);
    // eslint-disable-next-line
  }, [update, reload]);

  useEffect(() => {
    fetch(cancelSource.current.token, page);
  }, [page])

  return (
    <Grid>
      <TabHeader
        title={tab.title}
        tabKey={tabKey}
        defaultSort="updated_at"
        itemsSort={[
          {
            title: 'Entrada',
            value: 'updated_at',
          },
          {
            title: 'Nome',
            value: 'name',
          },
        ]}
      />
      {leads
        && status === FULFILLED
        && leads.length === 0
        ? <NoResults message="Nenhuma venda encontrada" />
        : (
          <Paper className={clsx(classes.header, 'selectable')}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  Cliente
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  Informações
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  Escopo Venda
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        )}
      {leads
        && status === FULFILLED
        && leads.length > 0
        && leads.map((lead) => (
          <TabRowSales
            key={uniqid()}
            item={lead}
          />
        ))}
      {status === REQUESTING
        && <LoadingComponent />}
        <Pagination count={props.totalPages} page={props.currentPage} onChange={handleCurrentPage} />
    </Grid>
  );
}

TabSales.propTypes = {
  tab: PropTypes.object.isRequired,
  tabKey: PropTypes.number.isRequired,
  leadsReq: PropTypes.array.isRequired,
  update: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  fetch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  leadsReq: state.sales.collection,
  status: state.sales.status,
  actionStatus: state.sales.actionStatus,
  totalPages: state.sales.totalPages,
  currentPage: state.sales.currentPage
});

export default connect(mapStateToProps, { fetch: fetchLeadsBySalesman })(TabSales);
