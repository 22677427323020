import React from 'react';
import { connect } from 'react-redux';
import { Typography, Grid } from '@material-ui/core';
import * as imports from 'objects/imports';
import { sameRelation } from 'constants/sales';
import { convertCurrency } from 'helpers';
import { getTitlesAndTotalValue } from './functions';


function PeopleHeader({ process, people, expanded }) {
  const infoObj = getTitlesAndTotalValue(process);
  const processesTitles = infoObj.titles.join(', ');
  const findGender = () => {
    const find = imports.genders.find(a => a.value === people.gender);
    if (find) return find.label;
    return '';
  };
  if (expanded) return (<Typography variant="h5">{`Editar dados de ${people.name}`}</Typography>);
  if (!expanded) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" color="textSecondary">
            {people.name}
          </Typography>
          {process.host_relations !== sameRelation
            && (
              <Typography variant="subtitle2">
                {`${process.host_relations} de `}
                <strong>{process.hosts[0].name}</strong>
              </Typography>
            )}
        </Grid>
        {people.birthdate && (
          <Grid item xs={12} md="auto">
            <Typography variant="subtitle1">
              {'Data de nascimento: '}
              <strong>{people.birthdate}</strong>
            </Typography>
          </Grid>
        )}
        {people.gender && (
          <Grid item xs={12} md="auto">
            <Typography variant="subtitle1">
              {'Gênero: '}
              <strong>{findGender()}</strong>
            </Typography>
          </Grid>
        )}
        {process.products && process.products.length > 0 && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography>
                  {`Processo${process.products.length > 1 ? 's' : ''} cadastrado${process.products.length > 1 ? 's' : ''}: `}
                  <strong>{processesTitles}</strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {infoObj.totalCost
          ? (
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="primary">
                {`Valor total: ${convertCurrency(infoObj.totalCost)}`}
              </Typography>
            </Grid>
          )
          : null}
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  certificates: state.certificates.collection,
  scope: state.sales.scope,
  products: state.product.allProducts,
});

export default connect(mapStateToProps)(PeopleHeader);
