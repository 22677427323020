import React from 'react';
import DocumentsList from 'global/modules/Documents';

function Imports({ docsShown, setDocsShown, setThis, currentClient, update, setUpdate, objType }){
  return(
    <DocumentsList
      show={docsShown}
      currentClient={currentClient || {}}
      setUpdate={setUpdate}
      setThis={setThis}
      update={update}
      objType={objType}
      onClose={() => setDocsShown()}
    />
  )
}

export default Imports;