import * as definitions from 'store/definitions/payment';
import { DEFAULT, REJECTED, REQUESTING, FULFILLED } from 'constants/common';

function Payment(state = {
  status: DEFAULT,
  payments: {
    data:[],
    total: 0,
    per_page: 20
  },
}, action) {
  switch (action.type) {
    case definitions.CREATE_PAYMENT_FULFILLED:
    case definitions.REMOVE_PAYMENT_FULFILLED:
    case definitions.UPDATE_PAID_PAYMENT_FULFILLED:
    case definitions.CONFIRM_SALE_FULFILLED:
    case definitions.LOAD_PAYMENTS_FULFILLED:
      return {
        ...state,
        payments: action.payload,
        status: FULFILLED,
      };
    case definitions.CREATE_PAYMENT_REJECTED:
    case definitions.REMOVE_PAYMENT_REJECTED:
    case definitions.UPDATE_PAID_PAYMENT_REJECTED:
    case definitions.CONFIRM_SALE_REJECTED:
    case definitions.LOAD_PAYMENTS_REJECTED:
      return {
        ...state,
        status: REJECTED,
      };
    case definitions.CREATE_PAYMENT:
    case definitions.REMOVE_PAYMENT:
    case definitions.UPDATE_PAID_PAYMENT:
    case definitions.CONFIRM_SALE:
    case definitions.LOAD_PAYMENTS:
      return {
        ...state,
        status: REQUESTING,
      };
    default:
      return {
        ...state,
      };
  }
}

export default Payment;
