import * as definitions from 'store/definitions/documents';
import { DEFAULT, REJECTED, REQUESTING, FULFILLED, EMPTY } from 'constants/common';

function Documents(state = {
  status: DEFAULT,
  moduleStatus: DEFAULT,
  lastAction: EMPTY,
  collection: [],
}, action) {
  switch (action.type) {
    case definitions.DELETE_DOCUMENT:
    case definitions.SEND_DOCUMENT:
    case definitions.FETCH_DOCUMENTATION_DOCUMENTS:
    case definitions.CONFIRM_DOCUMENTS_DOCUMENTATION:
      return {
        ...state,
        collection: [],
        status: REQUESTING,
        lastAction: action.type,
      };
    case definitions.NON_APPLICABLE_DOCUMENT:
      return {
        moduleStatus: REQUESTING,
      };
    case definitions.DELETE_DOCUMENT_FULFILLED:
    case definitions.SEND_DOCUMENT_FULFILLED:
    case definitions.CONFIRM_DOCUMENTS_DOCUMENTATION_FULFILLED:
      return {
        ...state,
        status: FULFILLED,
      };
    case definitions.NON_APPLICABLE_DOCUMENT_FULFILLED:
      return {
        moduleStatus: FULFILLED,
      };
    case definitions.FETCH_DOCUMENTATION_DOCUMENTS_FULFILLED:
      return {
        ...state,
        status: FULFILLED,
        collection: action.payload.documents,
      }
    case definitions.CLEAR_COLLECTION:
      return {
        ...state,
        status: DEFAULT,
        collection: [],
      };
    case definitions.DELETE_DOCUMENT_REJECTED:
    case definitions.CONFIRM_DOCUMENTS_DOCUMENTATION_REJECTED:
    case definitions.SEND_DOCUMENT_REJECTED:
    case definitions.FETCH_DOCUMENTATION_DOCUMENTS_REJECTED:
      return {
        ...state,
        status: REJECTED,
      };
    case definitions.NON_APPLICABLE_DOCUMENT_REJECTED:
      return {
        moduleStatus: REJECTED,
      };
    default:
      return {
        ...state,
      };
  }
}

export default Documents;
