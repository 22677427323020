import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { SaleDashboardContext } from 'contexts/saleDashboard';
import { fetchAccompanimentBySalesman } from 'store/actions/sales';
import { Grid, Paper, Typography } from '@material-ui/core';
import ActionsContext from 'contexts/actions';
import TabHeader from 'sales/dashboard/components/TabHeader';
import TabRowAttendance from 'sales/dashboard/components/TabRowAttendance';
import { LoadingComponent } from 'global/modules/Loading';
import NoResults from 'global/modules/NoResults';
import { REQUESTING, FULFILLED } from 'constants/common';
import { useStyles } from './style';
import { setSalesStep } from '../functions';
import { getCancelToken, cancel } from 'api';
import { Pagination } from '@material-ui/lab';

function TabAttendance({
  tab, tabKey, attendancesReq, update, allSalesReq, status, fetchAll, ...props
}) {
  const classes = useStyles(false);
  const { reload } = useContext(ActionsContext);
  const { setCurrentClient, currentClient } = useContext(SaleDashboardContext);
  const [attendances, setAttendances] = useState([...attendancesReq]);
  const [allSales, setAllSales] = useState([...allSalesReq]);
  const [requesting, setRequesting] = useState(false);
  const cancelSource = React.useRef(null);
  const [page, setPage] = useState(1)

  const handleCurrentPage = (e, page) => {
    setPage(page)
  }

  useEffect(() => {
    if (status === FULFILLED && requesting) {
      setRequesting(false);
    }
    setAttendances([...attendancesReq]);
    setAllSales([...allSalesReq]);
  }, [status, allSalesReq, attendancesReq, requesting]);

  const attendancesWithSteps = attendances.map((a) => setSalesStep(a));

  useEffect(() => {
    cancelSource.current = getCancelToken().source();
    if (!requesting) {
      setRequesting(true);
      props.fetchAccompanimentBySalesman(cancelSource.current.token);
    }
    return () => cancel(cancelSource.current);
    // eslint-disable-next-line
  }, [update, reload]);

  useEffect(() => {
    if (status === FULFILLED) {
      const maintainClient = allSales.find((a) => a.id === currentClient.id);
      setCurrentClient(maintainClient || currentClient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, allSales]);

  useEffect(() => {
    if (!requesting) {
      setRequesting(true);
      props.fetchAccompanimentBySalesman(cancelSource.current.token, page);
    }
  }, [page])

  return (
    <Grid>
      <TabHeader
        title={tab.title}
        tabKey={tabKey}
      />
      {attendancesWithSteps
        && status === FULFILLED
        && attendancesWithSteps.length === 0
        ? <NoResults message="Nenhuma venda encontrada" />
        : (
          <Paper className={clsx(classes.header, 'selectable')}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Cliente</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Informações</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} className={classes.middleGridPadding}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2">Venda</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" component="p" align="center">Contrato</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" component="p" align="right">Pagamento</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} className={classes.lastChildPadding}>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Typography variant="subtitle2">Serviços</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        )}
      {attendancesWithSteps
        && attendancesWithSteps.map((item, i) => (
          <TabRowAttendance
            key={`attendance${item.id}`}
            index={i}
            item={item}
          />
        ))}
      {status === REQUESTING
        && <LoadingComponent />}
        <Pagination 
          count={props.totalPages} 
          page={props.currentPage} 
          onChange={handleCurrentPage} 
        />
    </Grid>
  );
}

TabAttendance.propTypes = {
  tab: PropTypes.object.isRequired,
  tabKey: PropTypes.number.isRequired,
  attendancesReq: PropTypes.array.isRequired,
  update: PropTypes.string.isRequired,
  allSalesReq: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  allSalesReq: state.sales.allSales,
  attendancesReq: state.sales.collection,
  status: state.sales.status,
  totalPages: state.sales.totalPages,
  currentPage: state.sales.currentPage
});

export default connect(mapStateToProps, { fetchAccompanimentBySalesman })(TabAttendance);
