import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import uniqid from 'uniqid';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { MESSAGE_ALERT_CANT_REMOVE_PERSON } from 'constants/common/messages';
import {
  Grid, TextField, Typography, MenuItem, FormControlLabel, Checkbox,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { showNotification } from 'store/actions/notifications';
import { showConfirmation } from 'store/actions/confirmations';
import { createPerson, removeDocument, updatePerson, removePerson } from 'store/actions/sales';
import NewPeopleDefaultForm from 'sales/form/StepOne/NewPeople/DefaultForm';
import {
  portugueseInfoForm,
  FREGUESIA_FORM_CERTIFICATE_TYPE,
  CONSULATE_FORM_CERTIFICATE_TYPE,
  FILL_LATER_TEXT,
} from 'constants/sales';
import FillLaterField from 'sales/form/StepOne/FillLaterField';
import NewPeopleFeguesiaForm from 'sales/form/StepOne/NewPeople/FreguesiaForm';
import NewPeopleConsulateForm from 'sales/form/StepOne/NewPeople/ConsulateForm';
import NewPeopleFooterForm from 'sales/form/StepOne/NewPeople/FooterForm';
import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_WARNING } from 'constants/notification';
import FilesPreview from 'global/modules/FilesPreview';
import { validateForm, prepareToSubmit } from './functions';
import moment from 'moment';
import Row from 'service/Envio/Historic/Row';

function SaleFormNewPeople({
  people, mode, ...props
}) {
  const formObj = { ...people, docs: [] };
  const [form, setForm] = useState(people ? formObj : portugueseInfoForm);
  const [hasError, setHasError] = useState(false);
  const [docsToAdd, setDocsToAdd] = useState([]);

  const handleUpdateForm = (key, value) => setForm({ ...form, [key]: value });

  const handleInitForm = (people) => {
    var objForm = portugueseInfoForm;
    if (people) {
      objForm = people;
      objForm.concelho = people.city ? people.city.concelho : null;
    }

    return objForm;
  }

  const handleSelectCertificateType = (certificate) => {
    setForm({
      ...form,
      certificate_id:
        certificate.id,
      certificate_type: certificate,
    });
  };

  const handleShowForm = () => {
    const type = form.certificate_type === null || form.certificate_type === undefined ? '' : form.certificate_type.type;
    switch (type) {
      case FREGUESIA_FORM_CERTIFICATE_TYPE:
        return (
          <NewPeopleFeguesiaForm
            form={form}
            onUpdateField={handleUpdateForm}
            hasError={hasError}
          />
        );

      case CONSULATE_FORM_CERTIFICATE_TYPE:
        return (
          <NewPeopleConsulateForm
            form={form}
            onUpdateField={handleUpdateForm}
            hasError={hasError}
          />
        );

      default:
        return (<div></div>);
    }
  };

  const handleRemovePerson = () => {
    const checkBlock = props.scope.processes.filter((a) => a.hosts[0].id === people.id);
    if (checkBlock && checkBlock.length > 0) {
      props.showNotification(NOTIFICATION_TYPE_WARNING, MESSAGE_ALERT_CANT_REMOVE_PERSON);
    } else {
      props.showConfirmation(
        {
          title: 'Remover português',
          message: `Remover informações sobre o/a cidadã(o) português ${form.name}?`,
        },
        {
          confirm: 'Remover',
          cancel: 'Cancelar',
        },
        {
          func: props.removePerson,
          payload: {
            personId: form.id,
            clientId: props.scope.client_id,
            scopeId: props.scope.id,
          },
        },
      );
    }
  };

  const handleUpdatePerson = () => {
    if (!validateForm(form)) {
      setHasError(true);
      props.showNotification(
        NOTIFICATION_TYPE_ERROR,
        'Por favor, preencha o formulário',
      );
      return;
    }
    props.showConfirmation(
      {
        title: 'Editar informações',
        message: `Editar as informações de ${form.name}?`,
      },
      {
        confirm: 'Editar',
        cancel: 'Cancelar',
      },
      {
        func: props.updatePerson,
        payload: {
          personId: form.id,
          data: prepareToSubmit(form),
          clientId: props.scope.client_id,
          scopeId: props.scope.id,
        },
      },
    );
  };

  const handleSubmit = (e) => {
    setHasError(false);
    e.preventDefault();
    if (!validateForm(form)) {
      setHasError(true);
      props.showNotification(
        NOTIFICATION_TYPE_ERROR,
        'Por favor, preencha o formulário',
      );
      return;
    }

    props.createPerson({ clientId: props.scope.client_id, scopeId: props.scope.id, data: prepareToSubmit(form) });
    setForm(people);
  };

  const handleOnClear = () => setForm(portugueseInfoForm);

  const handleAddDocs = () => {
    setForm({ ...form, docs: [...form.docs, ...docsToAdd] });
    setDocsToAdd([]);
  };

  const handleSecondSale = (event) => {
    setForm({ ...form, second_sale: event.target.checked });
  }

  const handleRemoveDoc = (doc, inScope) => {
    if (!inScope) {
      const docs = form.docs.filter((a) => !_.isEqual(a, doc));
      setForm({ ...form, docs });
    } else {
      props.removeDocument({ docId: doc.id, scopeId: props.scope.id, clientId: props.scope.client_id });
    }
  };

  const handleUpdatedYear = (value) => {
    var date = moment(value);

    if (value.length == 4) {
      date.add('years', 1)
    }

    var year = parseInt(date.year());

    if (year > 1911) {
      var certificate = _.first(_.filter(props.certificates, ['id', 4]))
      setForm({ ...form, certificate_id: 4, certificate_type: certificate, });
    }

    if (year <= 1911) {
      var certificate = _.first(_.filter(props.certificates, ['id', 2]))
      setForm({ ...form, certificate_id: 2, certificate_type: certificate, });
    }
  }

  useEffect(() => {
    handleInitForm(people);
  }, [people]);

  useEffect(() => {
    if (props.certificates.length > 0) {
      handleSelectCertificateType(
        _.first(
          _.filter(props.certificates, [form.certificate_id ? 'id' : 'type', form.certificate_id || 3]),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.certificates]);
  return (
    <form onSubmit={handleSubmit} onReset={handleOnClear} style={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            fullWidth
            variant="outlined"
            name="name"
            value={form.name}
            label="Nome do português"
            onChange={(e) => handleUpdateForm('name', e.target.value)}
            error={hasError && form.name === ''}
          />
        </Grid>
        <Grid item xs={4}>
          {props.certificates.length > 0
            ? (
              <TextField
                fullWidth
                select
                inputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                name="certificateType"
                label="Tipo de certificado"
                value={form.certificate_id}
                onChange={(e) => handleSelectCertificateType(_.first(_.filter(props.certificates, ['id', e.target.value])))}
                error={hasError && form.certificate_id === ''}
              >
                {props.certificates.map((certificate) => (
                  <MenuItem key={uniqid()} value={certificate.id}>{certificate.name}</MenuItem>
                ))}
              </TextField>
            ) : (
              <Skeleton width="100%" height="100%" style={{ margin: 0 }} />
            )}
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            name="birthdate"
            label="Data ou ano de nascimento"
            value={form.birthdate}
            onChange={(e) => handleUpdateForm('birthdate', e.target.value)}
            onBlur={(e) => handleUpdatedYear(e.target.value)}
            error={hasError && form.birthdate === ''}
          />
          <FillLaterField
            checked={form.birthdate === FILL_LATER_TEXT}
            onCheck={(e) => handleUpdateForm('birthdate', e ? FILL_LATER_TEXT : '')}
          />
        </Grid>

        <NewPeopleDefaultForm
          form={form}
          onUpdateField={handleUpdateForm}
          hasError={hasError}
        />

        {handleShowForm()}

        <Grid item xs={4}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={form.second_sale}
                onChange={handleSecondSale}
              />
            )}
            label="Segunda venda?"
          />
        </Grid>
        {((form.docs && form.docs.length > 0) || (form.documents && form.documents.length > 0)) && (
          <>
            <Grid item xs={12}>
              <Typography variant="h5">Documentos anexados</Typography>
            </Grid>
            {form.documents && form.documents.map((doc) => (
              <FilesPreview key={uniqid()} doc={doc} removeDoc={handleRemoveDoc} inScope />
            ))}
            {form.docs && form.docs.map((doc) => (
              <FilesPreview key={uniqid()} doc={doc} removeDoc={handleRemoveDoc} />
            ))}
          </>
        )}
      </Grid>
      <NewPeopleFooterForm
        mode={mode}
        file={docsToAdd}
        onAddDocs={handleAddDocs}
        setFile={setDocsToAdd}
        onRemove={handleRemovePerson}
        onUpdate={handleUpdatePerson}
        form={form}
        upload
      />
    </form>
  );
}

SaleFormNewPeople.propTypes = {
  people: PropTypes.object,
};

SaleFormNewPeople.defaultProps = {
  people: {},
};

const mapStateToProps = (state) => ({
  certificates: state.certificates.collection,
  scope: state.sales.scope,
});

const actions = {
  showNotification,
  createPerson,
  removeDocument,
  showConfirmation,
  updatePerson,
  removePerson,
};

export default connect(mapStateToProps, actions)(SaleFormNewPeople);
