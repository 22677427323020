import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { object, func } from 'prop-types';

function FormDefault({ formfields, handleUpdateForm }) {
  return (
    <>
      <Grid item xs={4}>
        <TextField
          fullWidth
          variant="outlined"
          name="birthdate"
          label="Data de nascimento"
          value={formfields.birthdate}
          onChange={(e) => handleUpdateForm('birthdate', e.target.value)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          name="concelho"
          value={formfields.concelho}
          label="Concelho"
          variant="outlined"
          onChange={(e) => handleUpdateForm('concelho', e.target.value)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          variant="outlined"
          name="freguesia"
          label="Freguesia de nascimento"
          value={formfields.freguesia}
          onChange={(e) => handleUpdateForm('freguesia', e.target.value)}
        />
      </Grid>
    </>
  );
}

FormDefault.propTypes = {
  formfields: object.isRequired,
  handleUpdateForm: func.isRequired,
};

export default FormDefault;
