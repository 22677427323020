import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  btnAddUser: {
    position: 'absolute',
    right: theme.spacing(2),
    margin: 0,
    padding: theme.spacing(1),
  },
  btnLabel: {
    margin: 0,
    height: '30px',
    lineHeight: '100%',
    width: '30px',
  },
  btnDisabled: {
    '&:hover': {
      background: 'transparent',
    }
  }
}));

function AddUser({ user, index }) {
  const classes = useStyles();
  const [dialogState, setdialogState] = useState({ open: false })

  const stoppropagation = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  const handleClickOpen = (e) => {
    setdialogState({ open: true })
    stoppropagation(e)
  };

  const handleClose = (e) => {
    setdialogState({ open: false })
    stoppropagation(e)
  };
  const inviteButton = () => {
    let exportClasses;
    let tooltipinfo;
    let showdialog;
    let dialogText;
    if (user.emailConfirmed) {
      exportClasses = 'circle'
      tooltipinfo = 'Usuário registrado'
      showdialog = false
    }
    else if (user.invited) {
      exportClasses = 'times'
      tooltipinfo = 'Usuário convidado'
      showdialog = true
      dialogText = 'O usuário foi convidado, reenvie o convite'
    }
    else {
      exportClasses = 'plus'
      tooltipinfo = 'Convidar usuário'
      showdialog = true
      dialogText = 'Convide o usuário para visualizar suas informações na plataforma'
    }
    return [exportClasses, tooltipinfo, showdialog, dialogText];
  }
  const userIcon =
    <IconButton
      onClick={
        (e) => {
          inviteButton()[2]
            && handleClickOpen(e)
        }}
      classes={{
        root: inviteButton()[2]
          ? classes.btnAddUser
          : clsx(classes.btnDisabled, classes.btnAddUser),
        label: classes.btnLabel,
      }}
      disableRipple={!inviteButton()[2]}
    >
      <i
        style={inviteButton()[2] ? {} : { color: '#76ba32', fontSize: '2rem', lineHeight: '2rem' }}
        className={`fas fa-user-${inviteButton()[0]}`}
      />
    </IconButton>
  return (
    <div>
      {inviteButton()[0] === '' ? <>{userIcon}</> :
        <Tooltip disableTouchListener disableFocusListener title={isMobile ? '' : inviteButton()[1]} placement="left">
          {userIcon}
        </Tooltip>
      }
      {inviteButton()[2] ?
        <Dialog
          onClick={stoppropagation}
          classes={{ root: 'dialog', paper: 'dialogPaper' }}
          open={dialogState.open}
          onClose={handleClose}
          aria-labelledby={`inviteform${index}`}
        >
          <DialogTitle id={`inviteform${index}`}>Convidar</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {inviteButton()[3]}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id={`name${index}`}
              label="Email"
              type="email"
              value={user.email}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="secondary" classes={{ root: 'dialog-button' }}>
              Cancelar</Button>
            <Button onClick={handleClose} variant="outlined" color="primary" classes={{ root: 'dialog-button' }}>
              Enviar</Button>
          </DialogActions>
        </Dialog> : null}
    </div>
  )
}

export default AddUser;