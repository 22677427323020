/* eslint-disable react/prop-types */
// @ts-nocheck
import React, { useEffect, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { ServiceContext } from 'contexts/service';
import { Container, Grid } from '@material-ui/core';
import NoResults from 'global/modules/NoResults';
import { sendDocumentationEmail } from 'store/actions/sales';
import { fetchProcessesBySteps, processSearch, clearResponses } from 'store/actions/processes';
import { STEP_DOCSOK } from 'constants/process';
import { FULFILLED } from 'constants/session';
import { LoadingComponent } from 'global/modules/Loading';
import {
  array, string, oneOfType, number, func,
} from 'prop-types';
import { DEFAULT } from 'constants/common';
import Row from './Row';
import BlockRow from './BlockRow';
import Header from './Header';
import { useStyles } from '../style';
import Dialogs from './modules/Dialogs';
import Menus from './modules/Menus';
import HeaderColumn from './HeaderColumn';

function Documentacao({
  docsokReq,
  status,
  update,
  setUpdate,
  allProcessesReq,
  settingsStatus,
  ...props
}) {
  const classes = useStyles();
  const serviceContext = useContext(ServiceContext);
  const { currentCheck, setCurrentCheck } = serviceContext;
  const [docsok, setDocsok] = useState(docsokReq);
  const [allProcesses, setAllProcesses] = useState(allProcessesReq);
  const [requesting, setRequesting] = useState(false);

  useEffect(() => {
    if (status === FULFILLED && requesting) {
      setRequesting(false);
    }
    setDocsok([...docsokReq]);
    setAllProcesses([...allProcessesReq]);
  }, [status, allProcessesReq, docsokReq, requesting]);

  useEffect(() => {
    if (!requesting) {
      setRequesting(true);
      props.fetchProcessesBySteps([STEP_DOCSOK]);
      serviceContext.setSearchValue('');
      serviceContext.setSortItems(false);
    }
    // eslint-disable-next-line
  }, [update]);

  useEffect(() => {
    if (settingsStatus === FULFILLED && status === FULFILLED) {
      setCurrentCheck(Object.keys(currentCheck).length > 0
        ? allProcesses.find((a) => a.id === currentCheck.id)
        : {});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsStatus, allProcesses]);

  const blocked = docsok && docsok.length > 0
    ? docsok.filter((a) => a.blocked) : [];
  const allBlocked = allProcesses && allProcesses.length > 0
    ? allProcesses.filter((a) => a.blocked) : [];
  const listed = docsok && docsok.length > 0
    ? docsok.filter((a) => !a.blocked) : [];
  const allListed = allProcesses && allProcesses.length > 0
    ? allProcesses.filter((a) => !a.blocked) : [];

  return (
    <>
      <Header />
      <Container maxWidth="xl">
        <Menus />
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <HeaderColumn filteredSize={blocked.length} responseSize={allBlocked.length} blocked />
            {status === FULFILLED
              && blocked.length === 0
              ? (
                <NoResults
                  message="Nenhum processo travado encontrado"
                />
              ) : (
                <>
                  {blocked.map((process) => (
                    <BlockRow key={`process${process.id}`} item={process} />
                  ))}
                </>
              )}
          </Grid>
          <Grid item xs={6}>
            <HeaderColumn filteredSize={listed.length} responseSize={allListed.length} />
            {status === FULFILLED
              && listed.length === 0
              ? (
                <NoResults
                  message="Nenhum processo encontrado"
                />
              ) : (
                <>
                  {listed.map((process) => (
                    <Row key={`process${process.id}`} item={process} />
                  ))}
                </>
              )}
          </Grid>
        </Grid>
        {status !== FULFILLED && docsokReq.length === 0 && (
          <LoadingComponent />
        )}
        <Dialogs
          context={serviceContext}
          classes={classes}
          setUpdate={setUpdate}
          update={update}
        />
      </Container>
    </>
  );
}

Documentacao.propTypes = {
  docsokReq: array.isRequired,
  status: string,
  update: oneOfType([string, number]),
  setUpdate: func,
  allProcessesReq: array.isRequired,
};

Documentacao.defaultProps = {
  status: DEFAULT,
  update: '',
  setUpdate: () => { },
};

const mapToStateToProps = (state) => ({
  docsokReq: state.processes.collection,
  allProcessesReq: state.processes.allProcesses,
  status: state.processes.status,
  settingsStatus: state.processes.settingsStatus,
});

const actions = {
  fetchProcessesBySteps,
  sendDocumentationEmail,
  processSearch,
  clearResponses,
};

export default connect(mapToStateToProps, actions)(Documentacao);
