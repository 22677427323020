import React from 'react';
import { createMuiTheme, MuiThemeProvider, fade } from '@material-ui/core/styles';
import transitions from '@material-ui/core/styles/transitions';
import shadows from '@material-ui/core/styles/shadows';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import {
  grey,
  deepOrange,
} from '@material-ui/core/colors';

export const Palette = {
  cyanish: {
    50: '#64de9d',
    100: '#5ed194',
    200: '#58c48b',
    300: '#53b882',
    400: '#4dab79',
    500: '#61c391',
    600: '#419167',
    700: '#3c855e',
    800: '#367855',
    900: '#306b4c',
  },
  green: {
    50: '#c0f788',
    100: '#b3f075',
    200: '#abed68',
    300: '#9eeb52',
    400: '#86d439',
    500: '#76ba32',
    600: '#6ead2f',
    700: '#66a12b',
    800: '#5c9425',
    900: '#4c7d1b',
  },
}

const breakpoints = createBreakpoints({});

const spacing = (i, j) => {
  let a = j ? `${i * 8}px ${j * 8}px` : i * 8
  return a;
}

const primaryButtonGradient = {
  main: 'linear-gradient(45deg, #61c391 0%, #60b3ab 100%)',
  hover: 'linear-gradient(45deg, #41aa74 0%, #3f837c 100%)',
};

const containedPrimaryButtonGradient = {
  main: 'linear-gradient(-45deg, #79b495 0%, #97b4b0 100%)',
  hover: 'linear-gradient(-45deg, #5fa580 0%, #779c97 100%)',
}

const alertButton = {
  //  main: `linear-gradient(45deg, #fdd25e 0%, #fffc99 100%)`,
  //  hover: `linear-gradient(45deg, #edbb31 0%, #fdcd5d 100%)`,
  main: `linear-gradient(45deg, #fff1cc -10%, #ffde66 120%)`,
  hover: `linear-gradient(45deg, #f9d571 -10%, #fcd755 120%)`,
};

const theme = createMuiTheme({
  breakpoints,
  overrides: {
    MuiButtonGroup: {
      groupedContainedPrimary: {
        '&.secondary': {
          background: containedPrimaryButtonGradient.main,
          borderRight: '1px solid #6fa58d',
          '&:hover': {
            background: containedPrimaryButtonGradient.hover,
          }
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        userSelect: 'none',
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: fade(Palette.cyanish[500], 0.05),
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: 'rgba(0,0,0,.7)',
      }
    },
    MuiDivider: {
      root: {
        margin: '.4em 0',
        backgroundColor: 'transparent',
      },
      inset: {
        margin: '0',
        marginLeft: '45px',
      },
    },
    MuiCard: {
      root: {
        position: 'relative'
      },
    },
    MuiContainer: {
      root: {
      }
    },
    MuiFormHelperText: {
      root: {
        textAlign: 'right',
      }
    },
    MuiTableCell: {
      root: {
        padding: '4px 24px'
      }
    },
    MuiOutlinedInput: {
      root: {
        '& .MuiSelect-icon': {
          width: spacing(4),
        }
      }
    },
    MuiPaper: {
      elevation3: {
        padding: spacing(3, 4),
        position: 'relative',
        minHeight: spacing(8),
        marginBottom: spacing(4),
      },
    },
    MuiButton: {
      root: {
        whiteSpace: 'nowrap',
        minHeight: spacing(5),
      },
      containedPrimary: {
        background: primaryButtonGradient.main,
        boxShadow: shadows[2],
        color: 'white',
        '&:hover': {
          background: primaryButtonGradient.hover,
          boxShadow: shadows[6],
        },
        '&$disabled': {
          background: 'inherit',
          backgroundColor: grey[300],
        },
      },
    },
    MuiFab: {
      primary: {
        background: primaryButtonGradient.main,
        '&:hover': {
          background: primaryButtonGradient.hover,
        }
      }
    },
    MuiLink: {
      root: {
        color: grey[600],
      },
      button: {
        '&:hover': {
          color: Palette.cyanish[500],
        }
      }
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
      },
    },
    MuiSnackbar: {
      root: {
        zIndex: 2000000,
      }
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 0,
        fontSize: '1 rem',
        backgroundColor: grey[800],
      },
      touch: {
        display: 'none',
      },
    },
    MuiInputLabel: {
      outlined: {
        padding: '0',
      },
      shrink: {
        padding: `0 ${spacing(1)}px !important`,
        backgroundColor: '#FFFFFF',
      },
      animated: {
        transition: transitions.create('all'),
      },
    },
    MuiStepLabel: {
      iconContainer: {
        paddingRight: 0,
      }
    },
    MuiTypography: {
      root: {
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
      },
      subtitle2: {
        lineHeight: '1.5',
      },
    },
  },
  typography: {
    useNextVariants: true,
    body2: {
      fontSize: '.92rem',
      color: 'rgba(0,0,0,0.7)',
      lineHeight: '100%',
    },
    body1: {
    },
    subtitle2: {
      fontSize: '1.1rem',
      color: 'rgba(0, 0, 0, 0.7)',
      lineHeight: '100%',
    },
    subtitle1: {
    },
    fontFamily: [
      '"Lato"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: Palette.cyanish,
    secondary: {
      main: '#7a7a79',
    },
    error: deepOrange,
    icons: {
      primary: grey[800],
      secondary: grey[600],
      white: '#FFFFFF',
    },
    background: {
      default: grey[100],
      paper: '#FFFFFF'
    },
    gradient: {
      main: primaryButtonGradient.main,
      hover: primaryButtonGradient.hover,
    },
    alertGradient: {
      main: alertButton.main,
      hover: alertButton.hover,
    },
  },
  card: {
  },
  insetShadows: [
    `inset 0px 2px 2px -1px rgba(0,0,0,0.2),
      inset 0px 0px 8px 0px rgba(0,0,0,0.14),
      inset 0px 1px 18px 0px rgba(0,0,0,0.12)`,
  ],
});

const Theme = (props) => <MuiThemeProvider theme={theme} {...props} />;
export default Theme;

Theme.displayName = 'Theme';
