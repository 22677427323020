import * as definitions from 'store/definitions/emails';

export function emailDocumentation(id) {
  return {
    type: definitions.SEND_EMAIL_DOCUMENTATION,
    payload: { id },
  };
}

export function emailWelcome(id) {
  return {
    type: definitions.SEND_EMAIL_WELCOME,
    payload: { id },
  };
}
