// @ts-nocheck
import React, { useContext } from 'react';
import clsx from 'clsx';
import {
  Paper,
  Grid,
  Collapse,
  Tooltip,
  IconButton,
  Badge,
} from '@material-ui/core';
import Contact from 'global/modules/Contact';
import {
  UnfoldLess as UnfoldLessIcon,
  UnfoldMore as UnfoldMoreIcon,
  Share,
} from '@material-ui/icons/';
import { SaleDashboardContext } from 'contexts/saleDashboard';
import PropTypes from 'prop-types';
import Progress from '../modules/Progress';
import { useStyles } from '../style';
import Payments from '../modules/Payments';
import ServiceColumns from '../modules/Service';
import { calcPrices } from '../functions';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import { UserContext } from 'contexts/user';

function TabRowAttendance({
  item, index,
}) {
  const dashContext = useContext(SaleDashboardContext);
  const {
    handleSelectMultiple,
    checkMultiSelected,
  } = dashContext;
  const currentExpanded = dashContext.expanded;
  const expanded = currentExpanded === index;
  const classes = useStyles(false);
  const step = parseInt(item.salesStep, 10);
  const hiredServices = item.processes.length > 0 ? calcPrices(item.processes) : {};
  const selected = checkMultiSelected(item.id);
  const userContext = React.useContext(UserContext);
  return (
    <Paper
      className={
        clsx(
          classes.body,
          'selectable',
          step < 3 && selected ? 'selected' : 'regular',
        )
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          {step < 3 && (
            <IconButton
              onClick={() => handleSelectMultiple(item.id)}
              className={classes.checkButton}
            >
              {selected
                ? <CheckBox />
                : <CheckBoxOutlineBlank />
              }
            </IconButton>
          )}
          <Contact item={item} customer={item.customer} editable />
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'center' }}>
          <Progress step={step} client={item} />
        </Grid>
        <Grid item xs={4} className={classes.lastChildPadding}>
          <Grid container spacing={2}>
            <ServiceColumns step={step} item={item} />
            <Grid item xs={4} md={3} className={classes.tableButtonGrid}>
            { item.shared.length > 0 && item.salesman_id === userContext.user.id ? (
                <Tooltip placement="left" title="Esta venda possui compartilhamentos">
                <IconButton
                  className={classes.flexButton}
                  onClick={() => dashContext.handleDialogShareSale([item.id], item.shared)}
                >
                  <Badge badgeContent={item.shared.length} color="error">
                    <Share />
                  </Badge>
                </IconButton>
              </Tooltip>
              ) : (
                <Tooltip placement="left" title="Compartilhar venda">
                  <IconButton
                    className={classes.flexButton}
                    onClick={() => dashContext.handleDialogShareSale([item.id])}
                  >
                    <Share />
                  </IconButton>
                </Tooltip>
              )}
              {step >= 1
                ? (
                  <Tooltip placement="left" title="Ver informações de pagamento">
                    <IconButton
                      className={classes.flexButton}
                      onClick={() => dashContext.setExpanded(expanded ? null : index)}
                    >
                      {expanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                    </IconButton>
                  </Tooltip>
                ) : (
                  <IconButton
                    className={classes.flexButton}
                    disabled
                  >
                    {expanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                  </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={expanded}>
        <Payments hiredServices={hiredServices} item={item} step={step} />
      </Collapse>
    </Paper>
  );
}

TabRowAttendance.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
};

export default TabRowAttendance;
