export const FETCH_CONTRACT = 'FETCH_CONTRACT';
export const FETCH_CONTRACT_FULFILLED = 'FETCH_CONTRACT_FULFILLED';
export const FETCH_CONTRACT_REJECTED = 'FETCH_CONTRACT_REJECTED';

export const SIGN_CONTRACT = 'SIGN_CONTRACT';
export const SIGN_CONTRACT_FULFILLED = 'SIGN_CONTRACT_FULFILLED';
export const SIGN_CONTRACT_REJECTED = 'SIGN_CONTRACT_REJECTED';

export const FETCH_CONTRACT_CONTENT = 'FETCH_CONTRACT_CONTENT';
export const FETCH_CONTRACT_CONTENT_FULFILLED = 'FETCH_CONTRACT_CONTENT_FULFILLED';
export const FETCH_CONTRACT_CONTENT_REJECTED = 'FETCH_CONTRACT_CONTENT_REJECTED';

export const UPDATE_CONTRACT_CONTENT = 'UPDATE_CONTRACT_CONTENT';
export const UPDATE_CONTRACT_CONTENT_FULFILLED = 'UPDATE_CONTRACT_CONTENT_FULFILLED';
export const UPDATE_CONTRACT_CONTENT_REJECTED = 'UPDATE_CONTRACT_CONTENT_REJECTED';
