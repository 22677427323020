export const FETCH_PROCESSES_BY_STEP = 'FETCH_PROCESSES_BY_STEP';
export const FETCH_PROCESSES_BY_STEP_REJECTED = 'FETCH_PROCESSES_BY_STEP_REJECTED';
export const FETCH_PROCESSES_BY_STEP_FULFILLED = 'FETCH_PROCESSES_BY_STEP_FULFILLED';

export const PROCESS_SEARCH = 'PROCESS_SEARCH';
export const PROCESS_SEARCH_RESULT = 'PROCESS_SEARCH_RESULT';

export const PROCESS_RESULTS_CLEAR = 'PROCESS_RESULTS_CLEAR';
export const PROCESS_RESULTS_CLEAR_DONE = 'PROCESS_RESULTS_CLEAR_DONE';

export const PROCESS_CHANGE_STEP = 'PROCESS_CHANGE_STEP';
export const PROCESS_CHANGE_STEP_FULFILLED = 'PROCESS_CHANGE_STEP_FULFILLED';
export const PROCESS_CHANGE_STEP_REJECTED = 'PROCESS_CHANGE_STEP_REJECTED';

export const PROCESS_CHANGE_STEP_SEND_DOC = 'PROCESS_CHANGE_STEP_SEND_DOC';
export const PROCESS_CHANGE_STEP_SEND_DOC_FULFILLED = 'PROCESS_CHANGE_STEP_SEND_DOC_FULFILLED';
export const PROCESS_CHANGE_STEP_SEND_DOC_REJECTED = 'PROCESS_CHANGE_STEP_SEND_DOC_REJECTED';

export const UPDATE_PROCESS = 'UPDATE_SERVICE_PROCESS';
export const UPDATE_PROCESS_FULFILLED = 'UPDATE_SERVICE_PROCESS_FULFILLED';
export const UPDATE_PROCESS_REJECTED = 'UPDATE_SERVICE_PROCESS_REJECTED';

export const BLOCK_PROCESS = 'BLOCK_SERVICE_PROCESS';
export const BLOCK_PROCESS_FULFILLED = 'BLOCK_SERVICE_PROCESS_FULFILLED';
export const BLOCK_PROCESS_REJECTED = 'BLOCK_SERVICE_PROCESS_REJECTED';

export const CREATE_PROCESS_SETTINGS = 'CREATE_PROCESS_SETTINGS';
export const CREATE_PROCESS_SETTINGS_FULFILLED = 'CREATE_PROCESS_SETTINGS_FULFILLED';
export const CREATE_PROCESS_SETTINGS_REJECTED = 'CREATE_PROCESS_SETTINGS_REJECTED';

export const UPDATE_PROCESS_SETTINGS = 'UPDATE_PROCESS_SETTINGS';
export const UPDATE_PROCESS_SETTINGS_FULFILLED = 'UPDATE_PROCESS_SETTINGS_FULFILLED';
export const UPDATE_PROCESS_SETTINGS_REJECTED = 'UPDATE_PROCESS_SETTINGS_REJECTED';

export const DELETE_PROCESS_SETTINGS = 'DELETE_PROCESS_SETTINGS';
export const DELETE_PROCESS_SETTINGS_FULFILLED = 'DELETE_PROCESS_SETTINGS_FULFILLED';
export const DELETE_PROCESS_SETTINGS_REJECTED = 'DELETE_PROCESS_SETTINGS_REJECTED';
