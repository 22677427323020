import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, Typography, Fab, Tooltip,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '50px',
    height: '50px',
    margin: '0 auto',
    borderRadius: '100%',
    backgroundColor: theme.palette.primary.main,
    display: 'block',
  },
  number: {
    lineHeight: '50px',
    fontSize: '1.25rem',
    textAlign: 'center',
    color: theme.palette.common.white,
  },
}));

function Countdown({ timer, onEnd, pushFunction }) {
  const classes = useStyles();
  const [seconds, setSeconds] = useState(timer);

  const goLink = () => {
    pushFunction(onEnd);
  };

  const endThis = () => {
    if (typeof onEnd !== 'string') onEnd();
    else goLink();
  };

  useEffect(() => {
    setTimeout(() => {
      if (seconds > 0) setSeconds(seconds - 1);
      if (seconds === 0) {
        endThis();
      }
    }, 1000);
    // eslint-disable-next-line
  }, [seconds, timer]);

  return (
    <Tooltip title={`Redirecionará em ${seconds} segundos. Clique para redirecionar.`}>
      <Fab className={classes.root} onClick={() => endThis()}>
        <Typography className={classes.number}>{seconds}</Typography>
      </Fab>
    </Tooltip>
  );
}

Countdown.propTypes = {
  timer: PropTypes.number,
  onEnd: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
  pushFunction: PropTypes.func.isRequired,
};

Countdown.defaultProps = {
  timer: 15,
};

export default Countdown;
