import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { object, string } from 'prop-types';

const defaultForAll = {
  classes: {},
  className: 'icon',
};

const propsForAll = {
  classes: object,
  className: string,
};

export function Loading({ classes, ...props }) {
  return (
    <SvgIcon viewBox="0 0 104 47" classes={classes} {...props}>
      <linearGradient id="gradientthis" gradientUnits="userSpaceOnUse" x1="24.6716" y1="53.2675" x2="79.2404" y2="-1.3014">
        <stop offset="0" style={{ stopColor: '#61C391' }} />
        <stop offset="0.5" style={{ stopColor: '#60BAA0' }} />
        <stop offset="1" style={{ stopColor: '#60B3AB' }} />
      </linearGradient>
      <path
        className="bgd1"
        d="M51.9,34.1c-3.4,4.9-8.2,7.1-13.6,8.2c-7.3,1.5-14.7,1.9-21.9-0.5C6,38.4,2.5,29.5,3.1,21
        c-0.1-1,0.7-1.8,1.7-1.9c0.1,0,0.2,0,0.2,0c0.9-0.1,1.8,0.7,1.8,1.6c0,0.1,0,0.1,0,0.2c0,1.9,1.3,3.5,3.1,3.9c2.1,0.7,4,0.8,5.7-0.9
        c2.1-2.3,3.9-4.9,5.5-7.6c1.5-2.5,3.2-4.9,5-7.1c5.1-6,9.8-7.5,18.2-5c2.6,0.8,4.9,2.3,6.7,4.4c0.8,1,1.1,0.7,1.8-0.1
        c4.7-5.2,13.7-7,19.4-4c3.4,1.8,5.7,4.7,7.8,7.8c1.9,2.8,3.7,5.6,5.5,8.4c0.7,1,1.4,1.9,2.2,2.7c2.1,2.4,4.3,2.4,7.3,1
        c1.2-0.5,1.9-1.7,2-3c0.1-1.3,0.6-2.2,2-2.2s1.8,1.2,1.9,2.5c0.6,10.3-5.3,18.5-15.4,20.9c-7.9,1.8-16.2,1.4-23.9-1.3
        C57.6,39.9,54.2,37.5,51.9,34.1z"
      />
    </SvgIcon>
  );
}

Loading.defaultProps = defaultForAll;
Loading.propTypes = propsForAll;

export function NoProcesses({ classes, ...props }) {
  return (
    <>
      <style type="text/css">
        {'.st3{fill:none;stroke:#7A7A7A;stroke-width:4;stroke-miterlimit:10;}'}
      </style>
      <SvgIcon viewBox="0 0 136 132" classes={classes} {...props}>
        <g>
          <g>
            <path
              fill="#BCBCBC"
              d="M17.8,123.8C9.6,91.7,4.6,58.7,3.2,25.5l-0.1-2.2l63-9.4L85.8,31l0,1.1c1.5,32.1,5,48.1,13.2,79.8l0.7,2.7
                        l-81.3,12.2L17.8,123.8z"
            />
            <path
              fill="#FFFFFF"
              d="M65.3,16.5l18.1,15.6c1.5,32.5,5.1,48.8,13.3,80.3l-76.3,11.4l-0.2-0.7C12,91.2,7.1,58.5,5.7,25.4v0
                        L65.3,16.5 M66.8,11.2l-2.3,0.3L4.9,20.5l-4.5,0.7l0.2,4.5c1.5,33.3,6.4,66.5,14.7,98.8l0.2,0.7l1.1,4.4l4.5-0.7l76.3-11.4
                        l5.5-0.8l-1.4-5.4c-8.2-31.5-11.7-47.4-13.2-79.3l-0.1-2.1l-1.6-1.4L68.6,12.7L66.8,11.2L66.8,11.2z"
            />
          </g>
          <g>
            <path
              fill="#BCBCBC"
              d="M23.7,114.6c-5.7-32.6-8.1-66-7-99.1l0.1-2.3l63.5-4.5l18.4,18.6l0,1.1c-1,32.1,1.3,48.4,7,80.6l0.5,2.7
                        l-82,5.8L23.7,114.6z"
            />
            <path
              fill="#FFFFFF"
              d="M79.3,11.3l16.8,17c-1,32.5,1.3,49,7.1,81.1v0l-76.9,5.5l-0.1-0.7c-5.7-32.6-8-65.6-6.9-98.6v0L79.3,11.3
                        M81.2,6.1L79,6.3l-60.1,4.3l-4.5,0.3l-0.2,4.5c-1.1,33.3,1.2,66.8,7,99.6l0.1,0.7l0.8,4.4l4.5-0.3l76.9-5.5l5.5-0.4l-1-5.5
                        c-5.7-32-8-48.2-7-80.1l0.1-2.1l-1.5-1.5l-16.8-17L81.2,6.1L81.2,6.1z"
            />
          </g>
          <g>
            <path
              fill="#BCBCBC"
              d="M31.1,108.9l-0.3-2.9c-3.4-33-3.4-66.4,0.1-99.4l0.2-2.2h63.7l17,19.8l-0.1,1.1c-3.3,31.9-2.2,48.4,1.3,80.9
                        l0.3,2.8H31.1z"
            />
            <path
              fill="#FFFFFF"
              d="M93.6,6.8L109.2,25c-3.3,32.4-2.2,49,1.3,81.4v0H33.4l-0.1-0.7c-3.4-32.9-3.4-66,0.1-98.9v0H93.6 M95.9,1.8
                        h-2.3H33.4h-4.5l-0.5,4.5c-3.5,33.1-3.5,66.7-0.1,99.9l0.1,0.7l0.5,4.5h4.5h77.1h5.6l-0.6-5.5c-3.4-32.3-4.5-48.6-1.3-80.3
                        l0.2-2.1l-1.4-1.6L97.4,3.6L95.9,1.8L95.9,1.8z"
            />
          </g>
          <polygon
            fill="#7A7A7A"
            points="93.7,6.8 91.7,25.6 109,24.9 "
          />
          <g>
            <path className="st3" d="M65.9,54.1c0,4.2-3.4,7.7-7.7,7.7s-7.7-3.4-7.7-7.7" />
            <path className="st3" d="M90.7,54.1c0,4.2-3.4,7.7-7.7,7.7c-4.2,0-7.7-3.4-7.7-7.7" />
            <path className="st3" d="M81.1,40.5c2.1-1.6,4.9-2.2,7.6-1.1c2.6,1,4.4,3.4,4.8,6" />
            <path className="st3" d="M59.8,40.5c-2.1-1.6-4.9-2.2-7.6-1.1c-2.6,1-4.4,3.4-4.8,6" />
            <circle className="st3" cx="70.9" cy="77.1" r="1.5"/>
          </g>
          <g>
            <g>
              <g>
                <polygon fill="#BCBCBC" points="110.9,71.2 111.2,66.1 115.9,60.6 112,60.4 112.5,53 127.7,53.9 127.4,58.9 122.7,64.5 127,64.7 
                                126.6,72.2 				" />
                            <path fill="#FFFFFF" d="M114.8,55.6l10.2,0.6l-0.1,1.7l-7.2,8.8l6.7,0.4l-0.1,2.4l-10.7-0.7l0.1-1.7l7.4-8.7l-6.3-0.4L114.8,55.6
                                M110.1,50.3l-0.3,5l-0.1,2.4l-0.3,5l1.4,0.1l-0.9,1.1l-1.1,1.3l-0.1,1.7l-0.1,1.7l-0.3,5l5,0.3l10.7,0.7l5,0.3l0.3-5l0.1-2.4
                                l0.3-5l-1.9-0.1l1-1.2l1-1.3l0.1-1.6l0.1-1.7l0.3-5l-5-0.3l-10.2-0.6L110.1,50.3L110.1,50.3z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <polygon fill="#BCBCBC" points="110.4,50.8 110.7,45.6 116,39.4 111.7,39.2 112.1,31.5 128.1,32.5 127.8,37.6 122.6,43.9 
                                127.4,44.2 127,51.8 				"/>
                            <path fill="#FFFFFF" d="M114.5,34.2l11,0.7l-0.1,1.8l-7.8,9.5l7.2,0.4l-0.2,2.6l-11.5-0.7l0.1-1.9l7.9-9.4l-6.8-0.4L114.5,34.2
                                M109.8,28.9l-0.3,5l-0.2,2.6l-0.3,5l1.9,0.1l-1.5,1.8l-1.1,1.3l-0.1,1.7l-0.1,1.9l-0.3,5l5,0.3l11.5,0.7l5,0.3l0.3-5l0.2-2.6
                                l0.3-5l-2.4-0.1l1.6-1.9l1-1.3l0.1-1.6l0.1-1.8l0.3-5l-5-0.3l-11-0.7L109.8,28.9L109.8,28.9z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <polygon fill="#BCBCBC" points="113.2,26.8 113.5,21.4 119.6,14.2 114.6,13.9 115,6 132.2,7 131.9,12.4 125.9,19.7 131.4,20 
                                131,27.9 				"/>
                            <path fill="#FFFFFF" d="M117.4,8.6l12.2,0.7l-0.1,2l-8.6,10.5l8,0.5l-0.2,2.9l-12.8-0.8l0.1-2.1l8.8-10.4l-7.5-0.5L117.4,8.6
                                M112.7,3.3l-0.3,5l-0.2,2.9l-0.3,5l2.6,0.2l-2.4,2.8l-1.1,1.3l-0.1,1.7l-0.1,2.1l-0.3,5l5,0.3l12.8,0.8l5,0.3l0.3-5l0.2-2.9
                                l0.3-5l-3.2-0.2l2.4-2.9l1-1.3l0.1-1.6l0.1-2l0.3-5l-5-0.3l-12.2-0.7L112.7,3.3L112.7,3.3z"/>
                        </g>
                    </g>
                </g>
            </g>
            </SvgIcon>
        </>
  );
}

NoProcesses.defaultProps = defaultForAll;
NoProcesses.propTypes = propsForAll;

export function EmptyLot({ classes, ...props }) {
    return (
        <>
            <style type="text/css">{`
                .st0{fill:#FFFFFF;}
                .st1{fill:#BCBCBC;}
                .st2{fill:#7A7A7A;}
            `}
            </style>
            <SvgIcon viewBox="0 0 158 138" classes={classes} {...props}>
            <g>
                <g>
                    <path className="st0" d="M110.2,43l40,37.9V106l0,1.1v0.8L47.7,129.6L8.2,90L8.1,64.5L110.2,43 M137.4,84.7l2.5-0.5
                        c0.2-0.4,0.3-0.9,0.4-1.5v0c0.3-2.4-0.6-5.2-1.8-6.4L123,61.8c-0.4-0.3-0.7-0.5-1.1-0.5c-0.8,0-1.5,1-1.7,2.6v0
                        c-0.3,2.4,0.6,5.2,1.8,6.4L137.4,84.7 M97.6,71.2l1.7-0.4L97.6,71.2C97.6,71.2,97.6,71.2,97.6,71.2 M29.2,85.8l66.3-14.1
                        c0,0,0,0,0,0L29,85.6L29.2,85.8 M17.3,88.3l5.7-1.2l-0.2-0.2l-5.5,1.2C17.3,88.2,17.3,88.3,17.3,88.3 M111.8,37.6l-2.6,0.5
                        L7.1,59.6l-4,0.8l0,4.1l0.1,25.6l0,2.1l1.4,1.5l39.5,39.6l1.9,1.9l2.7-0.6l102.5-21.7l4-0.8v-4.1v-0.7l0-1l0,0v0V80.9v-2.2
                        l-1.6-1.5l-40-37.9L111.8,37.6L111.8,37.6z"/>
                    <path className="st0" d="M84.1,18.6l-0.5-1.9l-1.4-4.8l-4.8,1.4c-1.1,0.3-2.2,0.7-3.3,1.1l-0.3,0.1c-1.9,0.7-3.8,1.5-5.5,2.5
                        c-0.3,0.1-0.5,0.3-0.7,0.4L67,17.7c-0.3,0.2-0.5,0.3-0.8,0.5c-3.1,1.8-5.9,4.2-8,7.2l-0.2,0.3c-0.5,0.7-0.9,1.3-1.2,1.9
                        c-1.1,2.3-1.6,4.9-1.6,7.4c0,0.3,0,0.7,0,0.9c0,1.6,0.2,3.3,0.7,4.9l0.2,0.5c0.9,2.8,2.4,5.3,4.4,7.5l0,0c1.8,2,3.9,3.7,6.5,5.3
                        l4.3,2.6l2.6-4.3l1-1.7l2.6-4.3l-4.3-2.6c-0.6-0.4-1.2-0.8-1.7-1.2c-1.4-1-2.5-2.4-3.4-3.9c-0.3-0.5-0.5-1-0.7-1.4l0,0
                        c-0.2-0.5-0.2-1.1-0.2-1.6c0-0.5,0.1-1.1,0.2-1.6l0,0c0.3-1.1,0.9-2.2,1.7-3l0.3-0.3c0.8-0.9,1.8-1.7,2.9-2.5
                        c0.6-0.4,1.3-0.8,2-1.2c0.9-0.5,2-1.1,2.6-1.3l1.2-0.4c0.8-0.3,1.6-0.6,2.5-0.8l4.8-1.4L84.1,18.6z M62.7,38.9l-1.9,0.5
                        c-0.3-1.2-0.5-2.3-0.5-3.5c0-1.2,0.2-2.4,0.5-3.6l1.9,0.6c-0.3,1-0.4,2-0.4,3C62.3,36.9,62.4,37.9,62.7,38.9z M70.7,48.2l-1,1.7
                        c-2.1-1.3-3.9-2.7-5.4-4.3l1.5-1.3C67.1,45.7,68.7,47,70.7,48.2z M65.8,27.6l-1.5-1.3c1.4-1.6,3.2-3.1,5.4-4.3l1,1.7
                        C68.7,24.8,67.1,26.2,65.8,27.6z M76.5,20.9L75.8,19c0.9-0.3,1.9-0.7,2.9-0.9l0.5,1.9C78.3,20.3,77.4,20.6,76.5,20.9z"/>
                    <path className="st0" d="M86.3,14c1.4,0,2.7,1,2.9,2.5c0.3,1.6-0.8,3.2-2.4,3.5c-0.2,0-0.4,0-0.5,0c-1.4,0-2.7-1-2.9-2.5
                        c-0.3-1.6,0.8-3.2,2.4-3.5C85.9,14.1,86.1,14,86.3,14 M86.3,9c-0.5,0-1,0-1.4,0.1c-4.3,0.8-7.2,5-6.4,9.3c0.7,3.8,4,6.6,7.9,6.6
                        c0.5,0,1,0,1.4-0.1c4.3-0.8,7.2-5,6.4-9.3C93.4,11.8,90.1,9,86.3,9L86.3,9z"/>
                    <path className="st0" d="M76.1,7.1c1.1,0,2.5,0.6,3.6,1.8c1.7,1.7,3.2,6.3,3.2,6.3s-5.2-0.8-7-2.5c-1.7-1.7-2.3-4-1.3-5
                        C75,7.3,75.5,7.1,76.1,7.1 M76.1,2.1c-1.9,0-3.7,0.7-5,2c-1.8,1.8-2.4,4.3-1.7,7c0.5,1.8,1.5,3.6,3,5.1c2.6,2.6,7.7,3.6,9.7,3.9
                        l7.9,1.2l-2.4-7.6c-0.4-1.3-2-5.9-4.4-8.4C81.2,3.3,78.6,2.1,76.1,2.1L76.1,2.1z"/>
                    <path className="st0" d="M97.2,12.3c1.4,0,2.6,0.5,2.9,1.5c0.4,1.4-1.1,3-3.3,3.7c-0.4,0.1-0.9,0.2-1.4,0.2c-2.4,0-5.7-1.1-5.7-1.1
                        s3.2-3.3,5.5-4C95.9,12.4,96.6,12.3,97.2,12.3 M97.2,7.3c-1.1,0-2.3,0.2-3.5,0.5c-3.2,1-6.6,4.2-7.6,5.2l-5.6,5.6l7.5,2.6
                        c0.7,0.2,4.2,1.4,7.3,1.4c1.1,0,2-0.1,2.9-0.4c5.1-1.6,7.9-5.9,6.6-10C103.9,9.2,100.9,7.3,97.2,7.3L97.2,7.3z"/>
                </g>
                <g>
                    <g>
                        <path className="st1" d="M110.2,43L8.1,64.5V90l102.1-21.4l40,37.9V80.9L110.2,43z M140.2,82.7L140.2,82.7c-0.3,2.4-1.5,3.3-2.7,2.1
                            l-15.4-14.5c-1.3-1.2-2.1-4.1-1.8-6.4v0c0.3-2.4,1.5-3.3,2.7-2.1l15.4,14.5C139.6,77.5,140.5,80.3,140.2,82.7z"/>
                    </g>
                    <g>
                        <polygon className="st0" points="151.2,106.9 47.7,128.9 6.9,90.6 110.3,68.5 			"/>
                    </g>
                    <path className="st2" d="M47.7,103.7L8.1,65.3v25.9l39.6,38.4l102.5-21.7V82L47.7,103.7z M37.5,105.5L37.5,105.5
                        c-0.3,2.4-1.5,3.3-2.8,2.1L19.1,93c-1.3-1.2-2.1-4.1-1.9-6.5v0c0.3-2.4,1.5-3.3,2.8-2.1L35.6,99C36.9,100.2,37.8,103.1,37.5,105.5
                        z"/>
                    <g>
                        <g>
                            <g>
                                <path className="st2" d="M81.5,54.7c-0.9-0.3-1.8-0.6-2.8-0.9l0.5-1.9c1,0.3,2,0.6,2.9,0.9L81.5,54.7z"/>
                            </g>
                            <g>
                                <path className="st2" d="M88.4,83.6l-1-1.7c1.9-1.2,3.6-2.5,4.9-4l1.5,1.3C92.3,80.9,90.5,82.4,88.4,83.6z M97.2,73.2l-1.9-0.5
                                    c0.3-1,0.4-2,0.4-3c0-1-0.1-2-0.4-3l1.9-0.6c0.3,1.2,0.5,2.4,0.5,3.6C97.7,70.9,97.5,72.1,97.2,73.2z M92.2,61.4
                                    c-1.3-1.5-3-2.8-4.9-4l1-1.7c2.1,1.3,3.9,2.7,5.4,4.3L92.2,61.4z"/>
                            </g>
                            <g>
                                <path className="st2" d="M79.3,87.5l-0.5-1.9c1-0.3,1.9-0.6,2.8-0.9l0.7,1.9C81.3,86.9,80.3,87.2,79.3,87.5z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path className="st2" d="M76.5,20.9L75.8,19c0.9-0.3,1.9-0.7,2.9-0.9l0.5,1.9C78.3,20.3,77.4,20.6,76.5,20.9z"/>
                            </g>
                            <g>
                                <path className="st2" d="M69.6,49.9c-2.1-1.3-3.9-2.7-5.4-4.3l1.5-1.3c1.3,1.5,3,2.8,4.9,4L69.6,49.9z M60.8,39.5
                                    c-0.3-1.2-0.5-2.3-0.5-3.5c0-1.2,0.2-2.4,0.5-3.6l1.9,0.6c-0.3,1-0.4,2-0.4,3c0,1,0.1,2,0.4,3L60.8,39.5z M65.8,27.6l-1.5-1.3
                                    c1.4-1.6,3.2-3.1,5.4-4.3l1,1.7C68.7,24.8,67.1,26.2,65.8,27.6z"/>
                            </g>
                            <g>
                                <path className="st2" d="M78.7,53.8c-1-0.3-2-0.6-2.9-0.9l0.7-1.9c0.9,0.3,1.8,0.6,2.8,0.9L78.7,53.8z"/>
                            </g>
                        </g>
                    </g>
                    <circle className="st2" cx="86.3" cy="17" r="3"/>
                    <g>
                        <path className="st1" d="M75.9,12.7c1.7,1.7,7,2.5,7,2.5s-1.4-4.6-3.2-6.3s-4-2.3-5.1-1.2S74.2,10.9,75.9,12.7z"/>
                    </g>
                    <g>
                        <path className="st1" d="M96.8,17.5c-2.3,0.7-7-1-7-1s3.2-3.3,5.5-4s4.4-0.2,4.9,1.2C100.5,15.1,99,16.8,96.8,17.5z"/>
                    </g>
                </g>
            </g>
        </SvgIcon>
    </>
    )
}

EmptyLot.defaultProps = defaultForAll;
EmptyLot.propTypes = propsForAll;

export function AddLot({ classes, ...props }) {
  return (
    <SvgIcon viewBox="0 0 24 24" classes={classes} {...props}>
      <polygon points="5.9,4 5.1,5 18.9,5 17.9,4 " />
      <path
        d="M19.2,3.5C18.9,3.2,18.5,3,18,3H6C5.5,3,5.1,3.2,4.8,3.5L3.5,5.2C3.2,5.6,3,6,3,6.5V19c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2
        V6.5c0-0.5-0.2-0.9-0.5-1.3L19.2,3.5z M17,14.4h-4v4.1h-2v-4.1H7v-2h4V8.2h2v4.1h4V14.4z M5.1,5l0.8-1h12l0.9,1H5.1z"/>
    </SvgIcon>
  );
}

AddLot.defaultProps = defaultForAll;
AddLot.propTypes = propsForAll;

export function CircleLogo({ classes, ...props }) {
  return (
    <SvgIcon viewBox="0 0 361 361" classes={classes} {...props}>
      <circle fill="#6B9900" cx="180.4" cy="180.6" r="180.5" />
      <path
        fill="#333333"
        d="M281.4,216.8c-9.5,10.8-24.4,16.3-44.2,16.3c-36,0-50.8-11.9-56.8-21.7c-6.1,9.9-20.8,21.7-56.8,21.7
c-19.8,0-34.7-5.5-44.2-16.3c-13.2-15.1-10.8-34.8-10.7-35.7c0.3-2.2,2.3-3.8,4.5-3.6c2.2,0.2,3.9,2.1,3.9,4.3c0,1.5,0.6,9.6,14,9.9
l0.1,0c6,0,12.3-10,18.5-19.7c9.2-14.6,19.7-31.1,36.9-31.1c19,0,29,8.6,33.8,15.1c4.8-6.5,14.8-15.1,33.8-15.1
c17.3,0,27.7,16.5,36.9,31.1c6.1,9.7,12.5,19.7,18.5,19.7l0.1,0c13.4-0.3,14-8.3,14-9.9c-0.1-2.2,1.6-4.1,3.9-4.3
c2.2-0.2,4.2,1.4,4.5,3.7C292.2,181.9,294.7,201.6,281.4,216.8z"
      />
    </SvgIcon>
  );
}

CircleLogo.defaultProps = defaultForAll;
CircleLogo.propTypes = propsForAll;

export function Logo({ classes, ...props }) {
  return (
    <SvgIcon viewBox="0 0 720 184" classes={classes} {...props}>
      <g>
        <g>
          <rect x="0.9" y="155.8" fill="#333333" width="276.1" height="1"/>
        </g>
        <g>
          <rect x="476.4" y="155.4" fill="#333333" width="233.5" height="1"/>
        </g>
      </g>
      <path
        fill="#333333"
        d="M437.2,173.7c-5.7,6.5-14.6,9.8-26.4,9.8c-21.5,0-30.4-7.1-34-13c-3.6,5.9-12.5,13-34,13
        c-11.9,0-20.8-3.3-26.4-9.8c-7.9-9-6.4-20.8-6.4-21.3c0.2-1.3,1.4-2.3,2.7-2.2c1.3,0.1,2.3,1.3,2.3,2.6c0,0.9,0.4,5.7,8.4,5.9l0.1,0
        c3.6,0,7.4-6,11-11.8c5.5-8.7,11.8-18.6,22.1-18.6c11.4,0,17.3,5.1,20.2,9c2.9-3.9,8.9-9,20.2-9c10.3,0,16.6,9.9,22.1,18.6
        c3.7,5.8,7.5,11.8,11,11.8l0.1,0c8-0.2,8.4-5,8.4-5.9c0-1.3,1-2.5,2.3-2.6c1.3-0.1,2.5,0.9,2.7,2.2
        C443.6,152.9,445.1,164.7,437.2,173.7z"
      />
      <g id="Cidadania_Já">
        <g>
          <path
            fill="#6B9900"
            d="M102.8,28.9h-8.3V14.1h8.3V28.9z M102.8,99.6h-8.3V36.4h8.3V99.6z"
          />
          <path
            fill="#6B9900"
            d="M178.3,68.3c0.2,9.1-3,17-9.8,23.6c-6.8,6.5-14.6,9.8-23.7,9.4c-8.8-0.2-16.3-3.5-22.4-10s-9.1-14.1-9.1-22.9
            c0-9.2,3.3-17.2,9.9-23.7c6.5-6.6,14.4-9.9,23.5-9.5c9.3,0.3,17.1,4.1,23.3,11.2V14.6h8.3V68.3z M144.9,93c7,0.3,12.9-2,17.7-7.2
            c5-5.2,7.3-11.2,7.1-18.2c-0.1-6.6-2.5-12.3-7.1-17.1c-4.5-4.8-10-7.1-16.5-7.1c-6.6-0.1-12.4,2.3-17.2,7.1
            c-4.8,4.8-7.1,10.4-7.2,17.2c-0.1,6.6,2.1,12.5,6.6,17.5C133,90.3,138.4,92.8,144.9,93z"
          />
          <path
            fill="#6B9900"
            d="M252.1,99.6h-8.2v-9.9c-6.9,7.9-15.3,11.6-25.3,11.3c-8.8-0.3-16.2-3.7-22.3-10.1c-6.1-6.4-9.2-14.1-9.2-22.9
            c0-9.2,3.3-17.2,9.9-23.7c6.5-6.6,14.4-9.8,23.6-9.4c8.9,0.3,16.4,3.7,22.6,10.2c6.2,6.4,9.2,14.1,8.9,22.8v0.4V99.6z M195.7,67.3
            c-0.1,6.6,2,12.5,6.5,17.5c4.5,5,10.1,7.5,16.5,7.9c7,0.3,12.9-2.1,17.7-7.2c5-5.2,7.3-11.3,7.2-18.3c-0.1-6.6-2.5-12.3-7.1-17.1
            c-4.5-4.8-10.1-7.1-16.5-7.1c-6.8,0-12.4,2.3-17.2,7.1C198.1,54.9,195.8,60.6,195.7,67.3z"
          />
          <path
            fill="#6B9900"
            d="M327.1,68.3c0.2,9.1-3,17-9.8,23.6c-6.8,6.5-14.6,9.8-23.7,9.4c-8.8-0.2-16.3-3.5-22.4-10s-9.1-14.1-9.1-22.9
            c0-9.2,3.3-17.2,9.9-23.7c6.5-6.6,14.4-9.9,23.5-9.5c9.3,0.3,17.1,4.1,23.3,11.2V14.6h8.3V68.3z M293.8,93c7,0.3,12.9-2,17.7-7.2
            c5-5.2,7.3-11.2,7.1-18.2c-0.1-6.6-2.5-12.3-7.1-17.1c-4.5-4.8-10-7.1-16.5-7.1c-6.6-0.1-12.4,2.3-17.2,7.1
            c-4.8,4.8-7.1,10.4-7.2,17.2c-0.1,6.6,2.1,12.5,6.6,17.5C281.8,90.3,287.2,92.8,293.8,93z"
          />
          <path
            fill="#6B9900"
            d="M400.9,99.6h-8.2v-9.9c-6.9,7.9-15.3,11.6-25.3,11.3c-8.8-0.3-16.2-3.7-22.3-10.1c-6.1-6.4-9.2-14.1-9.2-22.9
            c0-9.2,3.3-17.2,9.9-23.7c6.5-6.6,14.4-9.8,23.6-9.4c8.9,0.3,16.4,3.7,22.6,10.2c6.2,6.4,9.2,14.1,8.9,22.8v0.4V99.6z M344.5,67.3
            c-0.1,6.6,2,12.5,6.5,17.5c4.5,5,10.1,7.5,16.5,7.9c7,0.3,12.9-2.1,17.7-7.2c5-5.2,7.3-11.3,7.2-18.3c-0.1-6.6-2.5-12.3-7.1-17.1
            c-4.5-4.8-10.1-7.1-16.5-7.1c-6.8,0-12.4,2.3-17.2,7.1C346.9,54.9,344.6,60.6,344.5,67.3z"
          />
          <path
            fill="#6B9900"
            d="M465.5,99.6H457V62.7c0-5.3-2-9.9-5.9-13.7s-8.3-5.8-13.5-5.8c-5.2,0-9.9,1.9-13.7,5.8
            c-3.9,3.9-5.8,8.4-5.8,13.7v36.9h-8.5v-36c0-8.2,2.7-15,8-20.4c5.3-5.4,12-8.2,20.1-8.2c8,0,14.6,2.8,19.9,8.2
            c5.3,5.4,8,12.2,8,20.4V99.6z"/>
          <path
            fill="#6B9900"
            d="M482.7,28.9h-8.3V14.1h8.3V28.9z M482.7,99.6h-8.3V36.4h8.3V99.6z"/>
          <path
            fill="#6B9900"
            d="M556.8,99.6h-8.2v-9.9c-6.9,7.9-15.3,11.6-25.3,11.3c-8.8-0.3-16.2-3.7-22.3-10.1c-6.1-6.4-9.2-14.1-9.2-22.9
            c0-9.2,3.3-17.2,9.9-23.7c6.5-6.6,14.4-9.8,23.6-9.4c8.9,0.3,16.4,3.7,22.6,10.2c6.2,6.4,9.2,14.1,8.9,22.8v0.4V99.6z M500.4,67.3
            c-0.1,6.6,2,12.5,6.5,17.5c4.5,5,10.1,7.5,16.5,7.9c7,0.3,12.9-2.1,17.7-7.2c5-5.2,7.3-11.3,7.2-18.3c-0.1-6.6-2.5-12.3-7.1-17.1
            c-4.5-4.8-10.1-7.1-16.5-7.1c-6.8,0-12.4,2.3-17.2,7.1C502.9,54.9,500.5,60.6,500.4,67.3z"/>
        </g>
        <g>
          <path
            fill="#6B9900"
            d="M83.5,83.1c-7.9,14.7-21.7,23.3-38.7,23.6c-12.1,0.1-22.4-4.2-31-13C5.3,85,0.9,74.5,0.9,62.3
            c0-12.5,4.3-23.1,13-31.4c8.4-8.3,18.7-12.4,30.9-12.3c8.5,0.1,16.2,2.2,23.2,6.5c6.6,4.2,11.9,9.9,15.5,17h-9.6
            c-7.1-10.3-17-15.3-29.7-15.2c-9.4,0-17.6,3.4-24.5,10.3C12.9,44,9.4,52.2,9.1,61.9c-0.2,10,3.2,18.6,10.2,25.9
            C26.4,95,35,98.7,44.8,98.7c5.8,0,11.4-1.6,16.8-4.5c5.2-2.9,9.3-6.5,12.3-11H83.5z"/>
        </g>
        <g>
          <path
            fill="#6B9900"
            d="M632.8,80.3c0,5.9-2.2,10.9-6.8,15c-4.5,4-9.9,6-16.1,6c-6,0-11.2-2.1-15.6-6.3c-4.3-4.3-6.6-9.4-6.9-15.4
            h8.4c0.1,3.7,1.6,6.8,4.3,9.3c2.8,2.4,6.1,3.8,10,4c3.7,0.2,6.9-0.9,9.6-3.4c2.9-2.5,4.3-5.5,4.3-9.2V14.2h8.6V80.3z"/>
          <path
            fill="#6B9900"
            d="M707,99.6h-8.2v-9.9c-6.9,7.9-15.3,11.6-25.3,11.3c-8.8-0.3-16.2-3.7-22.3-10.1c-6.1-6.4-9.2-14.1-9.2-22.9
            c0-9.2,3.3-17.2,9.9-23.7c6.5-6.6,14.4-9.8,23.6-9.4c8.9,0.3,16.4,3.7,22.6,10.2c6.2,6.4,9.2,14.1,8.9,22.8v0.4V99.6z M650.6,67.3
            c-0.1,6.6,2,12.5,6.5,17.5c4.5,5,10.1,7.5,16.5,7.9c7,0.3,12.9-2.1,17.7-7.2c5-5.2,7.3-11.3,7.2-18.3c-0.1-6.6-2.5-12.3-7.1-17.1
            c-4.5-4.8-10.1-7.1-16.5-7.1c-6.8,0-12.4,2.3-17.2,7.1C653,54.9,650.7,60.6,650.6,67.3z"/>
        </g>
        <g>
          <path
            fill="#6B9900"
            d="M719.4,0.7C717,2.4,715,4.4,713,6.4c-2,2.1-3.4,4.5-5.1,6.8c-1.7,2.3-3.4,4.6-5,6.9c-1.6,2.3-3.3,4.6-4.9,7
            l0.2,0.2c2-2.1,3.9-4.2,5.8-6.2c1.9-2.1,3.8-4.2,5.7-6.3c1.9-2.1,4-4.1,5.6-6.4c1.6-2.3,3.2-4.7,4.3-7.5L719.4,0.7z"/>
        </g>
      </g>
    </SvgIcon>
  );
}

Logo.defaultProps = defaultForAll;
Logo.propTypes = propsForAll;