import React, { useContext } from 'react';
import {
  Stepper,
  StepConnector,
  Step,
  StepButton,
  Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { SaleDashboardContext } from 'contexts/saleDashboard';
import { salesDashboardSteps } from 'objects/imports';
import clsx from 'clsx';
import { PAYMENT_STATUS_PAID } from 'constants/sales';
import { CANCELLED } from 'constants/common/payments';
import { useStyles } from '../style';

function ModalStep({ title, index, step, classes, salesOK, context, client, thisCurrentStep, icon, ...props }) {
  const confirmedPayments = client.payments.filter(a => a.status === PAYMENT_STATUS_PAID);
  const listedPayments = client.payments.filter(a => a.status !== CANCELLED).length;
  const allPaymentsConfirmed = confirmedPayments.length >= 1;
  const confirmPayment = salesOK && allPaymentsConfirmed;
  const StepThis = (
    <Step
      key={title}
      {...props}
      className={
        index === step ? classes.stepActive : 'step'
      }
      classes={{
        root: clsx(classes.step, !confirmPayment && index === salesDashboardSteps.length - 1 ? 'pendent' : 'common'),
        completed: salesOK
          ? classes.stepFilled
          : classes.stepCompleted,
      }}
    >
      <StepButton
        icon={icon}
        active={thisCurrentStep}
        completed={index < step}
        onClick={(e) => context.openMenu(true, index, e, client, step)}
        classes={{ root: classes.stepButton }}
      />
    </Step>
  );
  if (!confirmPayment && index === salesDashboardSteps.length -1) {
    return (
      <Tooltip title={`Por favor, confirme o${listedPayments > 1 ? 's' : ''} pagamento${listedPayments > 1 ? 's' : ''}`}>
        {StepThis}
      </Tooltip>
    );
  }

  return StepThis;
}

function Progress({ client, step }) {
  const classes = useStyles(false);
  const salesOK = step > 2;
  const dashContext = useContext(SaleDashboardContext);

  return (
    <Stepper
      activeStep={step}
      className={classes.stepper}
      connector={(
        <StepConnector
          classes={{
            active: classes.connectorActive,
            completed: classes.connectorCompleted,
            disabled: classes.connectorDisabled,
            line: salesOK ? classes.connectorFilled : classes.connectorLine,
          }}
        />
      )}
    >
      {salesDashboardSteps.map((a, index) => {
        let icon = a.outlinedIcon;
        const thisCurrentStep = index === step;
        if (index < step && !salesOK) {
          icon = a.icon;
        } else if (thisCurrentStep) {
          icon = a.twotoneIcon;
        }

        const spreadable = {
          title: a.title,
          classes,
          step,
          index,
          context: dashContext,
          icon,
          thisCurrentStep,
          client,
          salesOK,
        };

        return <ModalStep key={spreadable.title} {...spreadable} />;
      })}
    </Stepper>
  );
}

Progress.propTypes = {
  client: PropTypes.object.isRequired,
  step: PropTypes.number.isRequired,
};

export default Progress;
