import React from 'react';

export const a11yProps = (index) => ({ id: `tab-${index}`, 'aria-controls': `tabpanel-${index}` });

export default function TabPanel({ children, value, index }) {
  if (value === index) {
    return (
      <div aria-labelledby={`tab-${index}`}>
        {children}
      </div>
    )
  }
  return null;
}
