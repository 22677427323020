import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, TextField, Typography, Checkbox, MenuItem, FormControlLabel } from '@material-ui/core';
import _ from 'lodash';
import { updateSaleScopeById } from 'store/actions/sales';
import {
    CLIENT_FORM_LABEL_CUSTOMER_NEED_ALIGNMENT_QUESTION,
    CLIENT_FORM_LABEL_CUSTOMER_INTERESTED_SHARING_FAMILIY,
    CLIENT_FORM_LABEL_BEST_WAY_COMUNICATE,
    CLIENT_FORM_LABEL_WHAT_CLIENT_WANT_CITIZENSHIP,
    CLIENT_FORM_LABEL_CLINET_RELATION_WITH_TECHNOLOGY,
    CLIENT_FORM_LABEL_CLIENT_URGENCY,
    CLIENT_FORM_LABEL_CLIENT_TYPE,
    CLIENT_FORM_OPTIONS_CUSTOMER_NEED_ALIGNMENT,
    CLIENT_FORM_OPTIONS_CUSTOMER_INTERESTED_SHARING_FAMILIY,
    CLIENT_FORM_OPTIONS_BEST_WAY_COMUNICATE,
    CLIENT_FORM_OPTIONS_CLIENT_URGENCY,
    CLIENT_FORM_OPTIONS_CLINET_RELATION_WITH_TECHNOLOGY,
    CLIENT_FORM_OPTIONS_WHAT_CLIENT_WANT_CITIZENSHIP,
    CLIENT_FORM_OPTIONS_CLIENT_TYPE,
    CLIENT_FORM_NAME_CUSTOMER_NEED_ALIGNMENT_QUESTION,
    CLIENT_FORM_NAME_CUSTOMER_INTERESTED_SHARING_FAMILIY,
    CLIENT_FORM_NAME_BEST_WAY_COMUNICATE,
    CLIENT_FORM_NAME_CLIENT_URGENCY,
    CLIENT_FORM_NAME_CLINET_RELATION_WITH_TECHNOLOGY,
    CLIENT_FORM_NAME_WHAT_CLIENT_WANT_CITIZENSHIP,
    CLIENT_FORM_NAME_CLIENT_TYPE,
    CLIENT_FORM_LABEL_WANT_WHATSAPP_GROUP,
    CLIENT_FORM_NAME_WANT_WHATSAPP_GROUP,
    CLIENT_FORM_NAME_FUP_INTENSITY,
    CLIENT_FORM_LABEL_FUP_INTENSITY,
    CLIENT_FORM_OPTIONS_FUP_INTENSITY
} from 'constants/sales';

function ClientInfoForm(props) {
    const [needAlignment, setNeedAlignment] = useState(props.scope.customer_need_alignment);
    const [sharingFamily, setSharingFamiliy] = useState(props.scope.customer_interested_sharing_family);
    const [bestWay, setBestway] = useState(props.scope.best_way_communicate);
    const [clientUrgency, setClientUrgency] = useState(props.scope.client_urgency);
    const [technologyExperience, setTechnologyExperience] = useState(props.scope.client_relation_with_technology);
    const [clientWantCitizen, setClientWantCitizen] = useState([]);
    const [clientTypes, setClientTypes] = useState([]);
    const [wantWhatsappGroup, setWantWhatsappGroup] = useState(props.scope.need_whatsapp_group);
    const [fupIntensity, setFupIntensity] = useState(props.scope.fup_intensity);

    const handleChangeNeedAlignment = (e) => {
        setNeedAlignment(e.target.value);
        var obj = {};
        obj[CLIENT_FORM_NAME_CUSTOMER_NEED_ALIGNMENT_QUESTION] = e.target.value;
        props.updateSaleScopeById(props.scope.id, props.scope.client_id, obj);
    }

    const handleChangeSharingFamliy = (e) => {
        setSharingFamiliy(e.target.value);
        var obj = {};
        obj[CLIENT_FORM_NAME_CUSTOMER_INTERESTED_SHARING_FAMILIY] = e.target.value;
        props.updateSaleScopeById(props.scope.id, props.scope.client_id, obj);
    }

    const handleChangeBestWay = (e) => {
        setBestway(e.target.value);
        var obj = {};
        obj[CLIENT_FORM_NAME_BEST_WAY_COMUNICATE] = e.target.value;
        props.updateSaleScopeById(props.scope.id, props.scope.client_id, obj);
    }

    const handleChangeClientUrgency = (e) => {
        setClientUrgency(e.target.value);
        var obj = {};
        obj[CLIENT_FORM_NAME_CLIENT_URGENCY] = e.target.value;
        props.updateSaleScopeById(props.scope.id, props.scope.client_id, obj);
    }

    const handleChangeTechnologyExperience = (e) => {
        setTechnologyExperience(e.target.value);
        var obj = {};
        obj[CLIENT_FORM_NAME_CLINET_RELATION_WITH_TECHNOLOGY] = e.target.value;
        props.updateSaleScopeById(props.scope.id, props.scope.client_id, obj);
    }

    const handleWantWhatsappGroup = (e) => {
        setWantWhatsappGroup(e.target.value);
        var obj = {};
        obj[CLIENT_FORM_NAME_WANT_WHATSAPP_GROUP] = e.target.value;
        props.updateSaleScopeById(props.scope.id, props.scope.client_id, obj);
    }

    const handleFUPIntensity = (e) => {
        setFupIntensity(e.target.value);
        var obj = {};
        obj[CLIENT_FORM_NAME_FUP_INTENSITY] = e.target.value;
        props.updateSaleScopeById(props.scope.id, props.scope.client_id, obj);
    }

    const handleChangeClientWantCitizen = (e) => {
        var values = clientWantCitizen;
        if (e.target.checked) {
            if (!values.includes(e.target.value)) values.push(e.target.value);
        } else {
            if (values.includes(e.target.value)) _.remove(values, (n) => n == e.target.value);
        }
        setClientWantCitizen(values);

        setTechnologyExperience(e.target.value);
        var obj = {};
        obj[CLIENT_FORM_NAME_WHAT_CLIENT_WANT_CITIZENSHIP] = values;
        props.updateSaleScopeById(props.scope.id, props.scope.client_id, obj);
    }

    const handleChangeClientType = (e) => {
        var values = clientTypes;
        if (e.target.checked) {
            if (!values.includes(e.target.value)) values.push(e.target.value);
        } else {
            if (values.includes(e.target.value)) _.remove(values, (n) => n == e.target.value);
        }
        setClientTypes(values);

        var obj = {};
        obj[CLIENT_FORM_NAME_CLIENT_TYPE] = values;
        props.updateSaleScopeById(props.scope.id, props.scope.client_id, obj);
    }

    useEffect(() => {
        var ClientWantCitizenValue = [];
        var clientTypeValue = [];

        if (props.scope.what_client_want_citizenship != null) {
            ClientWantCitizenValue = props.scope.what_client_want_citizenship;
        }

        if (props.scope.client_type != null) {
            clientTypeValue = props.scope.client_type;
        }

        setClientWantCitizen(ClientWantCitizenValue);
        setClientTypes(clientTypeValue);
    }, []);

    return(
        <>
            <Typography variant="h5" paragraph>Perfil do Cliente</Typography>
            <Grid container spacing={2} style={{marginBottom:20}}>
                <Grid item xs={12}>
                    <TextField
                        label={CLIENT_FORM_LABEL_CUSTOMER_NEED_ALIGNMENT_QUESTION}
                        variant="outlined"
                        fullWidth
                        select
                        value={needAlignment}
                        onChange={handleChangeNeedAlignment}
                    >
                        {CLIENT_FORM_OPTIONS_CUSTOMER_NEED_ALIGNMENT.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={CLIENT_FORM_LABEL_CUSTOMER_INTERESTED_SHARING_FAMILIY}
                        variant="outlined"
                        fullWidth
                        select
                        value={sharingFamily}
                        onChange={handleChangeSharingFamliy}
                    >
                        {CLIENT_FORM_OPTIONS_CUSTOMER_INTERESTED_SHARING_FAMILIY.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={CLIENT_FORM_LABEL_BEST_WAY_COMUNICATE}
                        variant="outlined"
                        fullWidth
                        select
                        value={bestWay}
                        onChange={handleChangeBestWay}
                    >
                        {CLIENT_FORM_OPTIONS_BEST_WAY_COMUNICATE.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <Typography>{CLIENT_FORM_LABEL_WHAT_CLIENT_WANT_CITIZENSHIP}</Typography>
                    <Grid container spacing={2}>
                        {CLIENT_FORM_OPTIONS_WHAT_CLIENT_WANT_CITIZENSHIP.map((option) => (
                            <Grid item xs={6} key={option.value}>
                                <FormControlLabel
                                    control={<Checkbox/>}
                                    value={option.value}
                                    checked={clientWantCitizen.includes(option.value)}
                                    onChange={handleChangeClientWantCitizen}
                                    label={option.label}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={CLIENT_FORM_LABEL_CLIENT_URGENCY}
                        variant="outlined"
                        fullWidth
                        select
                        value={clientUrgency}
                        onChange={handleChangeClientUrgency}
                    >
                        {CLIENT_FORM_OPTIONS_CLIENT_URGENCY.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <Typography>{CLIENT_FORM_LABEL_CLIENT_TYPE}</Typography>
                    <Grid container spacing={2}>
                        {CLIENT_FORM_OPTIONS_CLIENT_TYPE.map((option) => (
                            <Grid item xs={6} key={option.value}>
                                <FormControlLabel
                                    control={<Checkbox/>}
                                    value={option.value}
                                    checked={clientTypes.includes(option.value)}
                                    onChange={handleChangeClientType}
                                    label={option.label}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={CLIENT_FORM_LABEL_CLINET_RELATION_WITH_TECHNOLOGY}
                        variant="outlined"
                        fullWidth
                        select
                        value={technologyExperience}
                        onChange={handleChangeTechnologyExperience}
                    >
                        {CLIENT_FORM_OPTIONS_CLINET_RELATION_WITH_TECHNOLOGY.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                {props.scope.family_plan === 'NONE' || props.scope.family_plan === null ? <></> :
                <Grid item xs={12}>
                    <TextField
                        label={CLIENT_FORM_LABEL_WANT_WHATSAPP_GROUP}
                        variant="outlined"
                        fullWidth
                        select
                        value={wantWhatsappGroup}
                        onChange={handleWantWhatsappGroup}
                    >
                        <MenuItem key="want_whatsapp_no" value={false}>Não</MenuItem>
                        <MenuItem key="want_whatsapp_yes" value={true}>Sim</MenuItem>
                    </TextField>
                </Grid>
                }
                {props.scope.family_plan === 'NONE' || props.scope.family_plan === null ? <></> :
                <Grid item xs={12}>
                    <TextField
                        label={CLIENT_FORM_LABEL_FUP_INTENSITY}
                        variant="outlined"
                        fullWidth
                        select
                        value={fupIntensity}
                        onChange={handleFUPIntensity}
                    >
                        {CLIENT_FORM_OPTIONS_FUP_INTENSITY.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                }
            </Grid>
        </>
    );
}

const mapStateToProps = state => ({
    scope: state.sales.scope,
})

export default connect(mapStateToProps, {updateSaleScopeById})(ClientInfoForm);
