import { put, takeLatest, all, call } from 'redux-saga/effects';
import * as definitions from 'store/definitions/exchange';
import * as API from 'api';
import { EUR_EXCHANGE_URL } from 'api/paths';

function* fetchEurBrl() {
  try {
    const payload = yield call(API.fetchUrl, EUR_EXCHANGE_URL);
    yield put({ type: definitions.FETCH_EURO_EXCHANGE_FULFILLED, payload: payload.rates });
  } catch (error) {
    yield put({ type: definitions.FETCH_EURO_EXCHANGE_REJECTED, payload: { error } });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.FETCH_EURO_EXCHANGE, fetchEurBrl),
  ]);
}
