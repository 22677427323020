import React, { useContext } from 'react';
import TimeAgo from 'react-timeago';
import uniqid from 'uniqid';
import { createMarkup } from 'global/functions';
import { MASTER, CUSTOMER } from 'constants/user';
import { useStyles } from './styles';
import clsx from 'clsx';
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Divider,
  Link,
  Tooltip,
} from '@material-ui/core/';
import ptStrings from 'react-timeago/lib/language-strings/pt';
import { UserContext } from 'contexts/user';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import { steps } from 'constants/process';

function CommentRow({ comment, tiny, handleDeleteComment, renderComments, allSteps, index, ...props }) {
  const classes = useStyles(props);
  const { user } = useContext(UserContext);
  const formatter = buildFormatter(ptStrings);
  const commentContent = createMarkup(comment.text);
  const thisStep = allSteps.find((a) => a.id === comment.step_id);
  return (
    <div key={`commentsListWrapper${comment.id}`}>
      <List key={`commentsList${comment.id}`} className={classes.list}>
        <ListItem disableGutters className={clsx(classes.listItem, Boolean(tiny) ? 'tiny' : 'regular')}>
          {!Boolean(tiny)
            ? (
              <ListItemAvatar>
                <Tooltip title={steps(thisStep ? thisStep.name : '').title}>
                  <Avatar className={classes.avatar}>
                    <i key={uniqid()} className={steps(thisStep ? thisStep.name : '').icon} />
                  </Avatar>
                </Tooltip>
              </ListItemAvatar>
            ) : null}
          <ListItemText
            secondary={(
              <>
                <Typography color="textPrimary" component="span" dangerouslySetInnerHTML={commentContent} />
                {(user.id === comment.user_id && !comment.is_automatic) || user.userType === MASTER
                  ? (
                    <Link
                      component="button"
                      className={classes.eraseComment}
                      onClick={() => handleDeleteComment(comment.id)}
                    >
                      {'Apagar comentário'}
                    </Link>
                  ) : null}
              </>
            )}
            primary={(
              <Typography variant="subtitle2" color="textSecondary">
                {comment.author && (<Typography component="span" color={comment.is_automatic ? 'primary' : 'textPrimary'}>{`${comment.author.name} `}</Typography>)}
                <Typography component="span" variant="subtitle1">
                  {comment.author ? '(' : ''}
                  <TimeAgo date={comment.created_at} formatter={formatter} />
                  {comment.author ? ')' : ''}
                  {comment.public && user.userType !== CUSTOMER
                    ? (
                      <Typography component="span">
                        {' - '}
                        <Typography color="primary" component="span">
                          {'Comentário público'}
                        </Typography>
                      </Typography>
                    ) : null}
                </Typography>
              </Typography>
            )}
          />
        </ListItem>
      </List>
      {index !== renderComments.length - 1 ? <Divider variant="inset" /> : null}
    </div>
  );
}

export default CommentRow;
