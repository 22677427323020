import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import * as definitions from 'store/definitions/users';
import * as selectors from 'store/selectors/users';
import * as notificationsDefinitions from 'store/definitions/notifications';
import * as API from 'api';
import { USER_PATH } from 'api/paths';
import { NOTIFICATION_TYPE_SUCCESS, NOTIFICATION_TYPE_ERROR } from 'constants/notification';
import { MESSAGE_SUCCESS_COMMON_CREATE, MESSAGE_ERROR_COMMON_ERROR, MESSAGE_SUCCESS_COMMON_UPDATE } from 'constants/common/messages';

const sortByDate = (arr) => arr.sort((a, b) => {
  if (a.created_at > b.created_at) return -1;
  if (a.created_at < b.created_at) return 1;
  return 0;
});


export function* usersSearch(action) {
  const {
    searchValue, filter, sortBy, sortOrder,
  } = action.payload;
  const payload = yield select(
    (state) => selectors.filterUsers(state)(searchValue, filter, sortBy, sortOrder),
  );
  yield put({ type: definitions.USERS_SEARCH_RESULT, payload });
}

function* fetchAll(action) {
  try {
    const { view, search, type, limit, page } = action.payload;
    const payload = yield call(API.fetch, USER_PATH, {s: search, type, limit, page});
    yield put({
      type: definitions.FETCH_ALL_USERS_FULFILLED,
      payload: payload.user,
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_ALL_USERS_REJECTED,
      payload: { error },
    });
  }
}

/*
export function* createUser(action) {
  try {
    const { data } = action.payload;
    const payload = yield call(API.create, USER_PATH, { ...data });
    yield put({
      type: definitions.CREATE_USER_FULFILLED,
      payload: payload.customer,
    });
  } catch (error) {
    yield put({
      type: definitions.CREATE_USER_REJECTED,
      payload: { error },
    });
  }
}
*/

export function* createUser(action) {
  try {
    const { view, data } = action.payload;
    const payload = yield call(API.create, USER_PATH, { ...data });
    yield put({
      type: definitions.CREATE_USER_FULFILLED,
      payload: payload.customer,
    });
    yield put({
      type: definitions.FETCH_ALL_USERS,
      payload: { view },
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: MESSAGE_SUCCESS_COMMON_CREATE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.CREATE_USER_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export function* patchUser(action) {
  try {
    const { userId, data } = action.payload;
    const payload = yield call(API.update, USER_PATH, userId, { ...data });
    yield put({
      type: definitions.UPDATE_USER_FULFILLED,
      payload: payload.customer,
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: MESSAGE_SUCCESS_COMMON_UPDATE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.UPDATE_USER_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.FETCH_ALL_USERS, fetchAll),
    takeLatest(definitions.USERS_SEARCH, usersSearch),
    takeLatest(definitions.UPDATE_USER, patchUser),
    takeLatest(definitions.CREATE_USER, createUser),
  ]);
}
