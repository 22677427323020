import {
  put, takeLatest, all, call,
} from 'redux-saga/effects';
import * as definitions from 'store/definitions/emails';
import * as notificationDefinitions from 'store/definitions/notifications';
import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from 'constants/notification';
import * as messages from 'constants/common/messages';
import { SCOPE_PATH } from 'api/paths';
import * as API from 'api';

export function* requestDocumentation(action) {
  try {
    const { id } = action.payload;
    const fullPath = `${SCOPE_PATH}/${id}/send-documents-email`;
    const payload = yield call(API.fetch, fullPath);
    yield put({
      type: definitions.SEND_EMAIL_DOCUMENTATION_FULFILLED,
      payload,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_EMAIL,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.SEND_EMAIL_DOCUMENTATION_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_EMAIL },
    });
  }
}

export function* requestWelcome(action) {
  try {
    const { id } = action.payload;
    const fullPath = `${SCOPE_PATH}/${id}/send-welcome-email`;
    const payload = yield call(API.fetch, fullPath);
    yield put({
      type: definitions.SEND_EMAIL_WELCOME_FULFILLED,
      payload,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_EMAIL,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.SEND_EMAIL_WELCOME_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_EMAIL },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.SEND_EMAIL_DOCUMENTATION, requestDocumentation),
    takeLatest(definitions.SEND_EMAIL_WELCOME, requestWelcome),
  ]);
}
