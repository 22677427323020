import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { connect } from 'react-redux';
import { VERIFICACAO_ROW } from 'constants/service/menus';
import { ServiceContext } from 'contexts/service';
import {
  MenuItem, Menu,
} from '@material-ui/core';
import { showConfirmation } from 'store/actions/confirmations';
import { showNotification } from 'store/actions/notifications';
import { setAlignment as setThis } from 'store/actions/sales';
import { NOTIFICATION_TYPE_ERROR } from 'constants/notification';
import { MESSAGE_ERROR_CLIENT_NOT_FOUND } from 'constants/common/messages';
import { FULFILLED } from 'constants/session';

function Menus({
  notification, confirmation, setAlignment, confirm, setUpdate,
}) {
  const [trigger, setTrigger] = useState(true);
  const serviceContext = useContext(ServiceContext);
  const { openMenu, anchorEl, currentClient } = serviceContext;

  useEffect(() => {
    if (confirm === FULFILLED && trigger) {
      setUpdate(uniqid());
      setTrigger(false);
    }
  }, [confirm, trigger, setUpdate]);

  const handleConfirm = () => {
    if (currentClient.customer) {
      const confirmed = () => {
        setAlignment(currentClient.id);
      };
      openMenu(false, VERIFICACAO_ROW);
      confirmation(
        {
          title: 'Confirmar venda',
          message: `Deseja confirmar a venda do cliente ${currentClient.customer.name} (${currentClient.client_id})?`,
        },
        {
          confirm: 'Confirmar',
          cancel: 'Cancelar',
        },
        {
          func: confirmed,
        },
      );
    } else notification(NOTIFICATION_TYPE_ERROR, MESSAGE_ERROR_CLIENT_NOT_FOUND);
  };

  return (
    <Menu
      anchorEl={anchorEl[VERIFICACAO_ROW]}
      open={Boolean(anchorEl[VERIFICACAO_ROW])}
      onClose={() => openMenu(false, VERIFICACAO_ROW)}
    >
      <MenuItem onClick={() => handleConfirm()}>
        {'Confirmar verificação da venda'}
      </MenuItem>
    </Menu>
  );
}

Menus.propTypes = {
  setAlignment: PropTypes.func.isRequired,
  confirmation: PropTypes.func.isRequired,
  notification: PropTypes.func.isRequired,
  setUpdate: PropTypes.func.isRequired,
  confirm: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  status: state.sales.scopeStatus,
  confirm: state.sales.confirm,
});

const actions = {
  setAlignment: setThis,
  confirmation: showConfirmation,
  notification: showNotification,
};

export default connect(mapStateToProps, actions)(Menus);
