import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Paper, Typography, Grid, Checkbox, FormControlLabel, FormHelperText, FormControl, InputLabel, Select, MenuItem, InputAdornment } from '@material-ui/core';
import CurrencyField from 'global/fields/CurrencyField';
import { updateSaleScopeById } from 'store/actions/sales';
import { convertCurrency, stripValue } from 'helpers';


function Emission(props) {
    const [scope, setScope] = React.useState(props.scope);
    const  [delivery, setDelivery] = React.useState(scope.customer_delivery);
    const  [emission, setEmission] = React.useState(scope.emission);
    const  [emissionValue, setEmissionValue] = React.useState(scope.emission_value == null ? '0,00' : scope.emission_value);
    
    const handleCustomerDelivery = () => {
      setDelivery(!delivery);
      scope.customer_delivery = delivery;
      props.updateSaleScopeById(scope.id, scope.client_id, { customer_delivery: !delivery});
    }
    const handleChangeEmission = (event) => {
      setEmission(event.target.value);
      props.updateSaleScopeById(scope.id, scope.client_id, { emission: event.target.value});
    }

    const handleFocus = (w) => {
      if (emissionValue === '0,00') setEmissionValue('');
    };
  
    const handleChange = (event) => setEmissionValue(event.target.value);

    const handleCents = (value) => {
      let newValue = `${emissionValue}`;
      if (newValue !== '' && !newValue.includes(',')) {
        newValue = `${newValue},00`;
      }
      setEmissionValue(newValue);
      let saveValue = stripValue(emissionValue).toFixed(2).replace('.', '')
      props.updateSaleScopeById(scope.id, scope.client_id, { emission_value: saveValue});
    };
  
    return (
        <Paper elevation={3}>
          <Typography variant="h5" color="textPrimary" paragraph>Orçamento do Cartório</Typography>
          <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
              <Select
                    labelId="emission_select_id"
                    id="emission_select"
                    value={emission}
                    fullWidth
                    variant="outlined"
                    onChange={handleChangeEmission}
                  >
                    <MenuItem value={`NO_DOCS_EMISSION`}>
                      Sem Orçamento
                      </MenuItem>
                    <MenuItem value={`DOCS_EMISSION_WITH_CERTIFICATES`}>
                      Com orçamento
                      </MenuItem>
                  </Select>
              </Grid>
          </Grid>
        </Paper>
      )
}

export default connect(null, { updateSaleScopeById })(Emission);