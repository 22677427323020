import * as definitions from 'store/definitions/product';

export function fetchAll() {
  return {
    type: definitions.FETCH_ALL_PRODUCTS,
  };
}

export function productSearch(searchValue, categories) {
  return {
    type: definitions.PRODUCT_SEARCH,
    payload: { searchValue, categories },
  };
}

export function createProduct(data) {
  return {
    type: definitions.CREATE_PRODUCT,
    payload: { data },
  };
}

export function updateProduct(productId, data) {
  return {
    type: definitions.UPDATE_PRODUCT,
    payload: { productId, data },
  };
}

export function removeProduct(productId) {
  return {
    type: definitions.REMOVE_PRODUCT,
    payload: { productId },
  };
}

export function addPriceToProduct(productId, data) {
  return {
    type: definitions.ADD_PRICE_TO_PRODUCT,
    payload: { productId, data },
  };
}

export function batchUpdatePrice(priceId, data, last = false) {
  return {
    type: definitions.BATCH_UPDATE_PRICE,
    payload: { priceId, data, last },
  };
}

export function updatePrice(priceId, data) {
  return {
    type: definitions.UPDATE_PRICE,
    payload: { priceId, data },
  };
}

export function removePrice(priceId) {
  return {
    type: definitions.REMOVE_PRICE,
    payload: { priceId },
  };
}

export function addRequirementToProduct(productId, data) {
  return {
    type: definitions.ADD_REQUIREMENT_TO_PRODUCT,
    payload: { productId, data },
  };
}

export function updateRequirement(requirementId, data) {
  return {
    type: definitions.UPDATE_REQUIREMENT,
    payload: { requirementId, data },
  };
}

export function removeRequirement(requirementId) {
  return {
    type: definitions.REMOVE_REQUIREMENT,
    payload: { requirementId },
  };
}


