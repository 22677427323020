import React from 'react';
import uniqid from 'uniqid';
import { Typography } from '@material-ui/core';
import { displayTime, ordinalInPortuguese } from 'global/functions';
import { useStyles } from './style';

function Divider() {
  return <div style={{ paddingBottom: 10 }} />
}

function Checklist(props) {
  const classes = useStyles();

  const buildPhases = {};
  const { processes } = props.scope;
  processes.forEach((process) => {
    if (buildPhases[process.phase]) {
      buildPhases[process.phase] = [...buildPhases[process.phase], process];
    } else buildPhases[process.phase] = [process];
  });

  const pendingDocumentation = [];

  const checkApostilate = () => {
    processes.forEach((process) => {
      if (process.products[0].pivot.apostilate) return true;
    });
    return false;
  };

  const apostilate = checkApostilate();

  const responsabilidades = [
    'Seleção e direcionamento de documentação necessária para cada fase;',
    'Análise da documentação antes do despacho;',
    'Preparação dos documentos para despacho;',
    'Pagamento do vale-postal e despacho para conservatórias em Portugal em nome do cliente;',
    'Acompanhar atualizações e auxiliar na obtenção de documentos adicionais, caso demandado por Portugal, até a finalização do procedimento.',
  ];

  if (!apostilate) responsabilidades.push('Apostilamento da documentação;');

  return (
    <>
      <Typography
        gutterBottom
        variant="h4"
        align="center"
        color="textSecondary"
        className={classes.header}
      >
        {'Informações do contrato'}
      </Typography>
      <Typography
        paragraph
        align="center"
        className={classes.description}
      >
        {'Você está prestes a fechar:'}
      </Typography>
      <Typography variant="h6">
        {'Procedimentos e tempo esperado'}
      </Typography>
      {processes.map(process => {
        let estimates = process.products[0].minimum_days_to_completion ? `de ${displayTime(process.products[0].minimum_days_to_completion)} ` : '';
        if (process.products[0].maximum_days_to_completion) {
          estimates = `${estimates}até ${displayTime(process.products[0].maximum_days_to_completion)} após o envio`;
        }
        let secondTitle = '';
        if (process.products && process.products.length) {
          process.products.filter((p) => p.can_attached).forEach((product) => {
            const size = process.products.filter((b) => product.title === b.title).length;
            secondTitle = `${size > 1 ? `${size} processos de ` : 'um processo de'}${product.title}`;
          });
        }
        return (
          <Typography key={uniqid()}>
            {`${process.applicant.name} - ${process.products[0].title}${estimates ? ` - ${estimates}` : ''}`}
            {secondTitle
              ? ` (mais ${secondTitle})`
              : null}
          </Typography>
        )
      })}
      <Divider />

      <Typography variant="h6">
        {'Fases'}
      </Typography>
      {Object.keys(buildPhases).map((phase, index) => {
        const processesInPhase = buildPhases[phase].map((process) => `${process.applicant.name} - ${process.products[0].title}`);
        return (
          <Typography key={uniqid()} variant="subtitle2">
            <strong>{`${ordinalInPortuguese(index + 1)} fase:`}</strong><br />
            <ul style={{ marginTop: 8 }}>
              {processesInPhase.map((process) => (
                <li key={uniqid()} style={{ marginLeft: 16 }}><Typography component="span">{process}</Typography></li>
              ))}
            </ul>
          </Typography>
        )
      })}
      <Divider />

      <Typography variant="h6">
        {'Documentos necessários'}
      </Typography>
      {processes.map((process, index) => (
        <div key={uniqid()}>
          <Typography key={uniqid()} variant="subtitle2">
            {`${process.applicant.name}`} - {`${process.products[0].title}`}
          </Typography>
          <ul style={{ marginTop: 8 }}>
            {process.products[0].requirements.map(requirement => (
              <li key={uniqid()} style={{ marginLeft: 16 }}>
                <Typography>
                  {`${requirement.name} `}
                  {requirement.from_who !== null ?
                    requirement.from_who.replaceAll('applicant', process.applicant.name)
                      .replaceAll('product', process.products[0].title) :
                    ''
                  }
                  {requirement.exception && (
                    <Typography component="span" style={{ fontWeight: 'bolder' }}>
                      {` - Documento extraordinário: ${requirement.exception}`}
                    </Typography>
                  )}
                </Typography>
              </li>
            ))}
          </ul>
          {index + 1 !== processes.length && (
            <Divider />
          )}
        </div>
      ))}

      <Divider />

      <Typography variant="h6">
        {'Sua responsabilidade'}
      </Typography>
      <ul style={{ marginTop: 8 }}>
        <li style={{ marginLeft: 16 }}>
          {'Fornecer documentos e informações necessárias para preparação da documentação.'}
        </li>
        {pendingDocumentation.length > 0 && (
          <li style={{ marginLeft: 16 }}>
            {'Disponibilizar o(s) seguinte(s) documento(s) e/ou informação(ões)'}
          </li>
        )}
      </ul>
      <Divider />

      <Typography variant="h6">
        {'Nossa responsabilidade'}
      </Typography>
      <ul style={{ marginTop: 8 }}>
        {responsabilidades.map(responsabilidade => <li key={uniqid()} style={{ marginLeft: 16 }}>{responsabilidade}</li>)}
      </ul>


    </>
  );
}

export default Checklist;
