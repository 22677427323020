import * as definitions from '../definitions/documents';

export function deleteDocument(id) {
  return {
    type: definitions.DELETE_DOCUMENT,
    payload: { id },
  };
}

export function sendDocument(personId, documents) {
  return {
    type: definitions.SEND_DOCUMENT,
    payload: { personId, documents },
  };
}

export function fetchDocumentsDocumentation(processId) {
  return {
    type: definitions.FETCH_DOCUMENTATION_DOCUMENTS,
    payload: { processId },
  };
}

export function confirmDocumentsDocumentation(processId, documents) {
  return {
    type: definitions.CONFIRM_DOCUMENTS_DOCUMENTATION,
    payload: { processId, documents },
  };
}

export function clearDocuments() {
  return {
    type: definitions.CLEAR_COLLECTION,
  };
}

export const nonApplicableDocument = (processId = 0, documents = []) => ({
  type: definitions.NON_APPLICABLE_DOCUMENT,
  payload: { processId, documents },
});
