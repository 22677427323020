import {
  put, takeLatest, all, call,
} from 'redux-saga/effects';
import * as definitions from 'store/definitions/comments';
import { SCOPE_PATH, PROCESS_PATH, COMMENT_PATH } from 'api/paths';
import * as API from 'api';
import * as notificationDefinitions from 'store/definitions/notifications';
import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from 'constants/notification';
import * as messages from 'constants/common/messages';

export function* commentInAllProcessesBySale(action) {
  try {
    const { id, text, isPublic, stepId } = action.payload
    const fullPath = `${SCOPE_PATH}/${id}/processes/comment-all`;
    const payload = yield call(API.update, fullPath, '', { text, public: isPublic, step_id: stepId });
    yield put({
      type: definitions.COMMENT_ALL_PROCESS_BY_SALE_FULFILLED,
      payload,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_SUCCESS, message: messages.POST_COMMENT_SUCCESS },
    });
  } catch (error) {
    yield put({
      type: definitions.COMMENT_ALL_PROCESS_BY_SALE_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export function* commentInProcess(action) {
  try {
    const { id, text, isPublic, stepId } = action.payload
    const fullPath = `${PROCESS_PATH}/${id}/comment`;
    const data = {
      text,
      public: isPublic,
      step_id: stepId,
    };
    const payload = yield call(API.create, fullPath, data);
    yield put({
      type: definitions.POST_COMMENT_PROCESS_FULFILLED,
      payload,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_SUCCESS, message: messages.POST_COMMENT_SUCCESS },
    });
  } catch (error) {
    yield put({
      type: definitions.POST_COMMENT_PROCESS_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export function* removeComment(action) {
  try {
    const { id } = action.payload
    const payload = yield call(API.remove, COMMENT_PATH, id);
    yield put({
      type: definitions.ERASE_COMMENT_FULFILLED,
      payload,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_SUCCESS, message: messages.ERASE_COMMENT_SUCCESS },
    });
  } catch (error) {
    yield put({
      type: definitions.ERASE_COMMENT_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.COMMENT_ALL_PROCESS_BY_SALE, commentInAllProcessesBySale),
    takeLatest(definitions.POST_COMMENT_PROCESS, commentInProcess),
    takeLatest(definitions.ERASE_COMMENT, removeComment),
  ]);
}