import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Collapse,
} from '@material-ui/core';
import { updateSaleScopeById } from 'store/actions/sales';
import { createMarkup } from 'global/functions';
import { linebreakToHtml, htmlToLineBreak } from 'helpers';
import { string, number } from 'prop-types';
import Axios from 'axios';
import { apiBaseUrl } from 'api';
import { USER_PATH } from 'api/paths';
import ClientInfoForm from './ClientInfoForm';

const useStyles = makeStyles((theme) => ({
  list: {
    marginTop: -8,
  },
  listItem: {
    alignItems: 'end',
  },
  avatar: {
    margin: theme.spacing(0.5, 0, 0, 0),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  textField: {
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  buttonHolder: {
    textAlign: 'right',
  },
  name: {
    lineHeight: 1.2,
  },
  time: {
    lineHeight: 1,
  },
}));

function Comment({ comment, authorID }) {
  const classes = useStyles({});
  const [user, setUser] = useState({ name: false });
  useEffect(() => {
    if (authorID) {
      Axios
        .get(`${apiBaseUrl}/${USER_PATH}/${authorID}`)
        .then((res) => {
          if (res.data && res.data.user && res.data.user.name) setUser(res.data.user);
        });
    }
  }, [authorID]);
  return (
    <List className={classes.list}>
      <ListItem disableGutters className={classes.listItem}>
        <ListItemAvatar>
          <Avatar className={classes.avatar}>
            <i className="fas fa-shopping-cart" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          secondary={<Typography dangerouslySetInnerHTML={createMarkup(comment)} />}
          primary={user.name
            ? (
              <Typography variant="subtitle2" className={classes.name}>
                {user.name}
              </Typography>
            ) : <></>}
        />
      </ListItem>
    </List>
  );
}

Comment.propTypes = {
  comment: string,
  authorID: number,
};

Comment.defaultProps = {
  comment: '',
  authorID: 0,
};

function Comments({ scope, mode, ...props }) {
  const classes = useStyles({});
  const [comment, setComment] = useState('');
  const [edit, setEdit] = useState(!Boolean(scope.comment));
  const handleChange = (e) => setComment(e.target.value);

  const handleAddComment = () => {
    props.updateSaleScopeById(scope.id, scope.client_id, { comment: linebreakToHtml(comment) });
  };

  const handleEdit = () => {
    setComment(htmlToLineBreak(scope.comment));
    setEdit(true);
  };

  useEffect(() => {
    setEdit(!Boolean(scope.comment));
  }, [scope]);

  return (
    <Paper elevation={3}>
       <ClientInfoForm />
      <Typography variant="h5" paragraph>Comentário sobre a venda</Typography>
      <Collapse in={edit && mode !== 'CLOSED'}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="comment-text"
              label="Escreva seu comentário"
              multiline
              fullWidth
              rows="4"
              onChange={handleChange}
              value={comment}
              className={classes.textField}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} className={classes.buttonHolder}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddComment}
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
      </Collapse>
      <Collapse in={!edit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Comment comment={scope.comment} authorID={scope.salesman_id} />
          </Grid>
          {mode !== 'CLOSED' && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleEdit}
              >
                {'Editar'}
              </Button>
            </Grid>
          )}
        </Grid>
      </Collapse>
    </Paper>
  );
}

Comments.propTypes = {
  comment: string,
};

Comments.defaultProps = {
  comment: '',
};

const mapStateToProps = (state) => ({
  scope: state.sales.scope,
});

export default connect(mapStateToProps, { updateSaleScopeById })(Comments);