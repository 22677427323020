import React, { useContext } from 'react';
import * as $_ from 'global/functions';
import { connect } from 'react-redux';
import { itemTypes } from 'constants/batchpost';
import {
  useDrop,
  useDrag,
} from 'react-dnd';
import { useStyles } from 'service/style';
import { removeBatchPost } from 'store/actions/batchpost';
import { ServiceContext } from 'contexts/service';
import { object, number, func } from 'prop-types';
import LotRowUi from './LotRowUi';

function LotRow({ lot, index, removeLot, lotNames }) {
  const serviceContext = useContext(ServiceContext);
  const {
    setExpanded,
    expanded,
    handleLotDialog,
    handleDatePickDialog,
  } = serviceContext;
  const classes = useStyles();
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: itemTypes.process,
    drop: () => ({
      name: !lot ? 'Novo pacote' : `Pacote ${lot.id}`,
      id: lot.id,
      newLot: !(lot),
      lot,
    }),
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });
  const [{ isDragging }, drag] = useDrag({
    item: {
      name: !lot ? 'Novo pacote' : `Pacote ${lot.id}`,
      id: lot.id,
      type: itemTypes.lot,
      lot,
    },
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        return;
      }
      removeLot(lot.id);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const style = {
    minHeight: !lot ? 150 : 40,
  };

  const isActive = canDrop && isOver;

  const handleDialogDatePick = (open, client, dateItem, e, newDate) => {
    handleDatePickDialog(open, client, dateItem, true, newDate);
    $_.stopPropagation(e);
  };

  const handleThisExpanded = (e) => {
    if (expanded === lot.id) {
      setExpanded('');
    } else {
      setExpanded(lot.id);
    }
    if (e) $_.stopPropagation(e);
  };

  const totalCost = { cost: 0, currency: 'EUR' };
  if (lot.processes) {
    lot.processes.forEach((process) => {
      const postalCost = process.products
        && process.products[0]
        && process.products[0].postal_value / 100;
      totalCost.cost += postalCost || 0;
    });
  }
  const styles = { transform: 'translate3d(0, 0, 0)' };
  const masterExpanded = expanded === lot.id;
  const obj = {
    classes,
    isDragging,
    index,
    isActive,
    masterExpanded,
    handleThisExpanded,
    lot,
    handleLotDialog,
    removeLot,
    totalCost,
    lotNames,
    handleDialogDatePick,
  };

  return (
    <div ref={drop} className={classes.paddingDrag} style={{ ...style, ...styles }}>
      <div ref={drag} style={{ ...styles }}>
        {lot
          && (
            <LotRowUi {...obj} />
          )}
      </div>
    </div>
  );
}

LotRow.propTypes = {
  lot: object.isRequired,
  index: number,
  removeLot: func.isRequired,
  lotNames: object.isRequired,
};

LotRow.defaultProps = {
  index: 0,
};

const mapStateToProps = (state) => ({
  state,
});

const actions = {
  removeLot: removeBatchPost,
};

export default connect(mapStateToProps, actions)(LotRow);
