import * as definitions from 'store/definitions/processes';
import { DEFAULT, REJECTED, REQUESTING, FULFILLED } from 'constants/common';

function Processes(state = {
  collection: [],
  allProcesses: [],
  status: DEFAULT,
  blockSearch: false,
  actionStatus: DEFAULT,
  settingsStatus: DEFAULT,
  totalPages: 0,
}, action) {
  switch (action.type) {
    case definitions.PROCESS_CHANGE_STEP:
    case definitions.PROCESS_CHANGE_STEP_SEND_DOC:
    case definitions.UPDATE_PROCESS:
    case definitions.BLOCK_PROCESS:
      return {
        ...state,
        blockSearch: true,
        actionStatus: REQUESTING,
      };
    case definitions.PROCESS_CHANGE_STEP_FULFILLED:
    case definitions.PROCESS_CHANGE_STEP_SEND_DOC_FULFILLED:
    case definitions.UPDATE_PROCESS_FULFILLED:
    case definitions.BLOCK_PROCESS_FULFILLED:
      return {
        ...state,
        blockSearch: false,
        actionStatus: FULFILLED,
      };
    case definitions.PROCESS_CHANGE_STEP_REJECTED:
    case definitions.PROCESS_CHANGE_STEP_SEND_DOC_REJECTED:
    case definitions.UPDATE_PROCESS_REJECTED:
    case definitions.BLOCK_PROCESS_REJECTED:
      return {
        ...state,
        blockSearch: false,
        actionStatus: REJECTED,
      };
    case definitions.UPDATE_PROCESS_SETTINGS:
    case definitions.CREATE_PROCESS_SETTINGS:
      return {
        ...state,
        settingsStatus: REQUESTING,
      };
    case definitions.UPDATE_PROCESS_SETTINGS_FULFILLED:
    case definitions.CREATE_PROCESS_SETTINGS_FULFILLED:
      return {
        ...state,
        settingsStatus: FULFILLED,
      };
    case definitions.UPDATE_PROCESS_SETTINGS_REJECTED:
    case definitions.CREATE_PROCESS_SETTINGS_REJECTED:
      return {
        ...state,
        settingsStatus: REJECTED,
      };
    case definitions.FETCH_PROCESSES_BY_STEP:
      return {
        ...state,
        blockSearch: true,
        status: REQUESTING,
      };
    case definitions.FETCH_PROCESSES_BY_STEP_REJECTED:
      return {
        ...state,
        blockSearch: false,
        status: REJECTED,
      };
    case definitions.FETCH_PROCESSES_BY_STEP_FULFILLED:
      return {
        ...state,
        status: FULFILLED,
        blockSearch: false,
        collection: action.payload.data,
        allProcesses: action.payload.data,
        totalPages: action.payload.total,
      };
    case definitions.PROCESS_SEARCH_RESULT:
      return {
        ...state,
        collection: action.payload,
      };
    case definitions.PROCESS_RESULTS_CLEAR_DONE:
      return {
        ...state,
        allProcesses: [],
        collection: [],
        status: DEFAULT,
        actionStatus: DEFAULT,
      };
    default:
      return state;
  }
}

export default Processes;
