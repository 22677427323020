import * as definitions from 'store/definitions/processes';

export function fetchProcessesBySteps(step, page) {
  return {
    type: definitions.FETCH_PROCESSES_BY_STEP,
    payload: { step, page },
  };
}

export function processSearch(searchValue, filter, sortBy, sortOrder) {
  return {
    type: definitions.PROCESS_SEARCH,
    payload: {
      filter, sortBy, sortOrder, searchValue,
    },
  };
}

export function changeStepProcess(processId, stepName, notify = false) {
  return {
    type: definitions.PROCESS_CHANGE_STEP,
    payload: {
      processId,
      stepName,
      notify,
    },
  };
}

export function clearResponses() {
  return {
    type: definitions.PROCESS_RESULTS_CLEAR,
  };
}

export function changeProcess(id, data) {
  return {
    type: definitions.UPDATE_PROCESS,
    payload: { id, data },
  }
}

export function changeStepProcessSendDocs(
  processId,
  stepName,
  notify = false,
  personId,
  documents,
) {
  return {
    type: definitions.PROCESS_CHANGE_STEP_SEND_DOC,
    payload: {
      processId,
      stepName,
      notify,
      personId,
      documents,
    },
  };
}

export function blockProcess(processId, reason, block) {
  return {
    type: definitions.BLOCK_PROCESS,
    payload: { reason, block, processId },
  };
}

export function createSettings(processId, key, data) {
  return {
    type: definitions.CREATE_PROCESS_SETTINGS,
    payload: { processId, key, data },
  };
}

export function updateSettings(processId, key, data) {
  return {
    type: definitions.UPDATE_PROCESS_SETTINGS,
    payload: { processId, key, data },
  };
}

export function removeSetting(processId, key) {
  return {
    type: definitions.DELETE_PROCESS_SETTINGS,
    payload: { processId, key },
  };
}
