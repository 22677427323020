import React, { useState } from 'react';
import {
  Typography,
  Grid,
  TextField,
} from '@material-ui/core';
import CPFField from 'global/fields/CPFField';
import DatePickerField from 'global/fields/DatePickerField';
import * as imports from 'objects/imports';
import CPF from 'gerador-validador-cpf';

function Personal({
  handleChangeForm,
  form,
  ...props}) {
  const [cpfError, setCpfError] = useState(false);
  const handleValidate = (value) => setCpfError(!CPF.validate(value));
  return (
    <>
      <Typography variant="h5" paragraph>Dados pessoais</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            name="name"
            label="Nome"
            value={form.name}
            required
            onChange={e => handleChangeForm('name', e.target.value)}
            InputLabelProps={{
              shrink: Boolean(form.name),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DatePickerField
            fullWidth
            required
            value={form.birthDate != null ? form.birthDate : ''}
            label="Data de nascimento"
            legalAge
            disableFuture
            InputLabelProps={{
              shrink: Boolean(form.birthDate),
            }}
            onChanged={date => handleChangeForm('birthDate', date)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            select
            fullWidth
            required
            variant="outlined"
            name="gender"
            label="Gênero"
            SelectProps={{
              native: true,
            }}
            value={form.gender}
            onChange={e => handleChangeForm('gender', e.target.value)}
          >
            <option value=""> </option>
            {imports.genders.map((gender) => (
              <option key={`gender${gender.value}`} value={gender.value}>
                {gender.label}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <CPFField
            fullWidth
            required
            variant="outlined"
            name="cpf"
            label="CPF"
            value={form.cpf != null ? form.cpf : ''}
            onBlur={(e) => handleValidate(e.target.value)}
            error={cpfError}
            helperText={cpfError ? 'CPF inválido' : ''}
            onChange={e => handleChangeForm('cpf', e.target.value)}
            InputLabelProps={{
              shrink: Boolean(form.cpf),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            name="rg"
            label="RG"
            variant="outlined"
            fullWidth
            required
            value={form.rg != null ? form.rg : ''}
            onChange={e => handleChangeForm('rg', e.target.value)}
            InputLabelProps={{
              shrink: Boolean(form.rg),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="emission"
            label="Órgão emissor"
            variant="outlined"
            fullWidth
            required
            value={form.emission != null ? form.emission : ''}
            onChange={e => handleChangeForm('emission', e.target.value)}
            InputLabelProps={{
              shrink: Boolean(form.emission),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DatePickerField
            fullWidth
            required
            disableFuture
            value={form.emissionDate}
            name="emissionDate"
            label="Data de Emissão"
            InputLabelProps={{
              shrink: Boolean(form.emissionDate),
            }}
            onChanged={(date) => handleChangeForm('emissionDate', date)}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Personal;
