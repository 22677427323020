import { put, takeLatest, all, call } from 'redux-saga/effects';
import * as definitions from 'store/definitions/documents';
import * as API from 'api';
import { DOCUMENT_PATH, PERSON_PATH, PROCESS_PATH } from 'api/paths';
import * as notificationsDefinitions from 'store/definitions/notifications';
import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from 'constants/notification';
import { MESSAGE_SUCCESS_REMOVE_FILE, MESSAGE_ERROR_COMMON_ERROR, MESSAGE_SUCCESS_COMMON_UPDATE, MESSAGE_CONFIRM_DOCUMENTS, MESSAGE_ERROR_SMTP } from 'constants/common/messages';

function* deleteDocument(action) {
  try {
    const { id } = action.payload;
    const payload = yield call(API.remove, DOCUMENT_PATH, id);
    yield put({ type: definitions.DELETE_DOCUMENT_FULFILLED, payload });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_SUCCESS, message: MESSAGE_SUCCESS_REMOVE_FILE }
    });
  } catch (error) {
    yield put({ type: definitions.DELETE_DOCUMENT_REJECTED, payload: { error } });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: MESSAGE_ERROR_COMMON_ERROR }
    });
  }
}

export function* fetchDocumentsDocumentation(action) {
  try {
    const { processId } = action.payload;
    const fullPath = `${PROCESS_PATH}/${processId}/documents`;
    const payload = yield call(API.fetch, fullPath, {});
    yield put({
      type: definitions.FETCH_DOCUMENTATION_DOCUMENTS_FULFILLED,
      payload,
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_DOCUMENTATION_DOCUMENTS_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export function* confirmDocumentsDocumentation(action) {
  try {
    const { processId, documents } = action.payload;
    const fullPath = `${PROCESS_PATH}`;
    const action2 = `document/delivered`;
    const data = { documents };
    const payload = yield call(API.updateAction, fullPath, processId, action2, data);
    yield put({
      type: definitions.CONFIRM_DOCUMENTS_DOCUMENTATION_FULFILLED,
      payload,
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_SUCCESS, message: MESSAGE_CONFIRM_DOCUMENTS },
    });
  } catch (error) {
    let message = MESSAGE_ERROR_COMMON_ERROR;
    if (
      Object.prototype.hasOwnProperty.call(error, 'response')
      && Object.prototype.hasOwnProperty.call(error.response, 'data')
      && JSON.stringify(error.response.data).toLowerCase().includes('smtp')
    ) {
      message = MESSAGE_ERROR_SMTP;
    }
    yield put({
      type: definitions.CONFIRM_DOCUMENTS_DOCUMENTATION_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message },
    });
  }
}

export function* updateApplicable(action) {
  try {
    const { processId, documents } = action.payload;
    const payload = yield call(API.updateAction, PROCESS_PATH, processId, 'not-applicable-documents', { documents });
    yield put({
      type: definitions.NON_APPLICABLE_DOCUMENT_FULFILLED,
      payload,
    });
  } catch (error) {
    yield put({
      type: definitions.NON_APPLICABLE_DOCUMENT_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export function* sendDocument(action) {
  try {
    const { personId, documents, noNotification } = action.payload;
    const fullPath = `${PERSON_PATH}`;
    const payload = yield call(API.update, fullPath, personId, { docs: [...documents] });
    yield put({
      type: definitions.SEND_DOCUMENT_FULFILLED,
      payload,
    });
    if (!noNotification) {
      yield put({
        type: notificationsDefinitions.SHOW,
        payload: {
          variant: NOTIFICATION_TYPE_SUCCESS,
          message: MESSAGE_SUCCESS_COMMON_UPDATE,
        },
      });
    }
  } catch (error) {
    yield put({
      type: definitions.SEND_DOCUMENT_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.DELETE_DOCUMENT, deleteDocument),
    takeLatest(definitions.SEND_DOCUMENT, sendDocument),
    takeLatest(definitions.CONFIRM_DOCUMENTS_DOCUMENTATION, confirmDocumentsDocumentation),
    takeLatest(definitions.FETCH_DOCUMENTATION_DOCUMENTS, fetchDocumentsDocumentation),
    takeLatest(definitions.NON_APPLICABLE_DOCUMENT, updateApplicable),
  ]);
}
