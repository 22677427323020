import React from 'react';
import {
  Grid,
  Link,
  Typography,
  Fab,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { ArrowBack, Check } from '@material-ui/icons';
import { useStyles } from 'service/style';
import { LoadingComponent } from 'global/modules/Loading';
import { semanticDate } from 'global/functions';
import clsx from 'clsx';

function SingleHeader({ push, scope, handleConfirm, done }) {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Link href="/verificacao" style={{ textDecoration: 'none' }} onClick={(e) => { e.preventDefault(); push('/verificacao'); }}>
            <Fab className={clsx(classes.btnSecondaryAction, classes.fabs)} variant="extended">
              <ArrowBack />
              <Typography className="hidden" component="span">
                {' Listagem'}
              </Typography>
            </Fab>
          </Link>
        </Grid>
        {scope.client_id
          ? (
            <Grid item xs={8} className={classes.centeredGrid}>
              <Typography variant="h5" color="textSecondary" align="center">
                {`Venda ${scope.client_id}`}
              </Typography>
              {!done && (
                <Typography variant="subtitle2" align="center">
                  {`Entrada em: ${semanticDate(scope.comfirmed_date)}`}
                </Typography>
              )}
            </Grid>
          ) : (
            <Grid item xs={8}>
              <LoadingComponent margin={0} height={50} />
            </Grid>
          )}
        <Grid item xs={2}>
          <Typography align="right">
            {!done && scope.client_id && (
              <Fab className={clsx(classes.btnPrimaryAction, classes.fabs)} color="primary" variant="extended" onClick={() => handleConfirm()}>
                <Typography className="hidden" component="span">
                  {'Confirmar '}
                </Typography>
                <Check />
              </Fab>
            )}
            {done && (
              <Fab className="extended" variant="extended" disabled>
                <Typography className="hidden" component="span">
                  {'Confirmado '}
                </Typography>
                <Check />
              </Fab>
            )}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

SingleHeader.propTypes = {
  scope: PropTypes.shape({
    client_id: PropTypes.string,
    comfirmed_date: PropTypes.string,
  }).isRequired,
  push: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  done: PropTypes.bool.isRequired,
};

export default SingleHeader;
