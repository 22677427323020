export const INTERNAL = 'INTERNAL';
export const MASTER = 'MASTER';
export const CUSTOMER = 'CUSTOMER';
export const PERSON = 'PERSON';
export const MANAGER = 'MANAGER';
export const ALL = 'ALL';

export const INTERNAL_SALES = 'SALES';
export const INTERNAL_SERVICE = 'SERVICE';
export const INTERNAL_COORDINATOR = 'SALES_COORDINATOR';

export const EDIT_MODE = 'EDIT_MODE';
export const ADD_MODE = 'ADD_MODE';

export const userForm = {
  id: 'VmnRXWMM',
  name: '',
  phone: '',
  email: '',
  user_type: CUSTOMER,
  internal_type: INTERNAL_SERVICE,
  pipedrive_id: '',
};

export const usersTitles = {
  [INTERNAL]: 'Equipe',
  [PERSON]: 'Requerente',
  [CUSTOMER]: 'Cliente',
  [MANAGER]: 'Gerente',
  [MASTER]: 'Master',
};

export const internalsTitles = {
  [INTERNAL_SALES]: 'Venda',
  [INTERNAL_SERVICE]: 'Serviço',
  [INTERNAL_COORDINATOR]: 'Coordenação',
};

export const userLocations = (user) => {
  const userType = `${user.userType}${user.internalType ? `-${user.internalType}` : ''}`;

  switch (userType) {
    case `${INTERNAL}-${INTERNAL_SALES}`:
    case INTERNAL:
      return '/venda';
    case MASTER:
    case MANAGER:
    case `${MANAGER}-${INTERNAL_SERVICE}`:
    case `${MANAGER}-${INTERNAL_SALES}`:
    case `${MANAGER}-${INTERNAL_COORDINATOR}`:
    case `${INTERNAL}-${INTERNAL_SERVICE}`:
      return '/servico';
    case CUSTOMER:
    case PERSON:
      return '/contrato';
    default:
      return '/login';
  }
};
