import React from 'react';
import DocRow from './DocRow';
import {
  Grid, Typography, Collapse,
} from '@material-ui/core';

function ExpandContent({ classes, process, thisexpanded, ...props }) {
  return (
    <Collapse in={thisexpanded}>
      <Grid className={classes.bodyExpand} style={{ paddingTop: 24 }} container spacing={2}>
        <Grid item xs={12}>
          {process.documentationDocs && process.documentationDocs.length > 1 && (
            <Typography
              variant="subtitle2"
            >
              <strong>{'Documentos'}</strong>
            </Typography>
          )}
          {process.documentationDocs && process.documentationDocs.map((document) => (
            <DocRow key={document.id} document={document} classes={classes} />
          ))}
        </Grid>
      </Grid>
    </Collapse>
  )
}

export default ExpandContent;