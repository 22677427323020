import React from 'react';
import clsx from 'clsx';
import {
  Grid,
  Typography,
  Paper,
} from '@material-ui/core';
import { useStyles } from '../style';

function Header({ filteredSize, singleResult, ...props }) {
  const classes = useStyles(props);
  if (filteredSize > 0) {
    return (
      <Paper className={clsx(classes.header, singleResult ? 'default' : 'documentacao')}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="body2">
              {'Requerente'}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {'Tipo de processo'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {'Data de entrada'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {'Alinhamento'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">
              {'Última observação'}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body2">
              {'Documentação'}
            </Typography>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </Paper>
    );
  }
  return null;
}

export default Header;
