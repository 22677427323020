import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { ALINHAMENTO_ROW } from 'constants/service/menus';
import { ServiceContext } from 'contexts/service';
import {
  MenuItem, Menu, Typography,
} from '@material-ui/core';
import { emailDocumentation, emailWelcome } from 'store/actions/emails';
import { showConfirmation } from 'store/actions/confirmations';

const Menus = ({ documentation, welcome, confirmation }) => {
  const serviceContext = useContext(ServiceContext);
  const { openMenu, anchorEl, currentClient } = serviceContext;
  const WELCOME = 'welcome';
  const DOCUMENTATION = 'documentation';

  const handleEmail = (variant) => {

    const confirm = () => {
      if (variant === WELCOME) {
        welcome(currentClient.id);
      } else if (variant === DOCUMENTATION) {
        documentation(currentClient.id);
      }
      openMenu(false, ALINHAMENTO_ROW);
    };

    confirmation(
      {
        title: 'Reenviar email',
        message: <>
          {`Reenviar email de ${variant === WELCOME ? 'boas-vindas' : ''}${variant === DOCUMENTATION ? 'documentção' : ''} de ${currentClient.customer && currentClient.customer.name} para o e-mail `}
          <Typography variant="inherit" component="span" color="error">{`${currentClient.customer && currentClient.customer.email}`}</Typography>
          {'?'}
        </>,
      },
      {
        confirm: 'Confirmar',
        cancel: 'Cancelar',
      },
      {
        func: confirm,
        payload: {},
      },
    );
  };
  return (
    <Menu
      anchorEl={anchorEl[ALINHAMENTO_ROW]}
      open={Boolean(anchorEl[ALINHAMENTO_ROW])}
      onClose={() => openMenu(false, ALINHAMENTO_ROW)}
    >
      <MenuItem onClick={() => handleEmail(WELCOME)}>
        {'Reenviar link de boas-vindas'}
      </MenuItem>
      <MenuItem onClick={() => handleEmail(DOCUMENTATION)}>
        {'Reenviar e-mail de documentação'}
      </MenuItem>
      {/* <MenuItem>Reenviar procuração</MenuItem> */}
    </Menu>
  )
}

const actions = {
  documentation: emailDocumentation,
  welcome: emailWelcome,
  confirmation: showConfirmation,
};

export default connect(null, actions)(Menus);
