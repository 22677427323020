import React from 'react';
import {
  FolderOpen,
  Check,
  ShoppingBasket,
  HelpOutline,
} from '@material-ui/icons';
import { INTERNAL, MASTER, CUSTOMER, MANAGER } from 'constants/user';

export const menuLinks = [
  {
    title: 'Editar informações da conta',
    link: '/conta',
    userType: [INTERNAL, CUSTOMER, MASTER],
  },
  {
    title: 'Editar informações de processos',
    link: '/editar-processos',
    userType: [MASTER, MANAGER],
  },
  {
    title: 'Editar usuários',
    link: '/usuarios',
    userType: [MASTER, MANAGER],
  },
  {
    title: 'Pagamentos',
    link: '/pagamentos',
    userType: [MASTER, MANAGER],
  },
  {
    title: 'Portugueses',
    link: '/portugueses',
    userType: [MASTER, MANAGER],
  },
  {
    title: 'Novos Portugueses',
    link: '/portugueses/check',
    userType: [MASTER, MANAGER],
  },
  {
    title: 'Perguntas frequentes',
    link: '/faq',
    userType: [MASTER],
  },
];

export const menuGlobalInternal = [
  {
    title: 'Informações de processos',
    link: '/processos',
    icon: <HelpOutline />,
  }
];

export const serviceMenu = [
  {
    title: 'Central de serviços',
    link: '/servico',
    icon: <FolderOpen />,
  },
  {
    title: 'Verificação de vendas',
    link: '/verificacao',
    icon: <Check />,
  },
];

export const masterMenu = [
  ...serviceMenu,
  {
    title: 'Vendas',
    link: '/venda',
    icon: <ShoppingBasket />,
  },
];
