import * as definitions from 'store/definitions/customer';

function Customer(state = {
  action: [],
}, action) {
  switch (action.type) {
    case definitions.CREATE_CUSTOMER_FULFILLED:
      return {
        ...state,
        action: action.type,
        error: null
      };
    case definitions.CREATE_CUSTOMER_REJECTED:
      return {
        ...state,
        action: action.type,
        error: action.payload.data.errors
      };
    default:
      return {
        ...state,
        action: definitions.CREATE_CUSTOMER,
        error: null
      };
  }
}

export default Customer;
