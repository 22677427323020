import { put, takeLatest, all, call } from 'redux-saga/effects';
import * as definitions from 'store/definitions/externalAuth';
import * as API from 'api';
import { AUTH_PATH } from 'api/paths';
import {
  storeSessionToken,
  storeSessionTokenValidTime,
} from 'helpers';

function* externalAuth() {
  try {
    const payload = yield call(API.externalLogin, AUTH_PATH);
    const { access_token, expires_in } = payload;
    storeSessionToken(access_token);
    storeSessionTokenValidTime(expires_in);

    yield put({ type: definitions.EXTERNAL_AUTH_FULFILLED, payload: { access_token, expires_in } });
  } catch (error) {
    yield put({ type: definitions.EXTERNAL_AUTH_REJECTED, payload: { error } });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.EXTERNAL_AUTH, externalAuth),
  ]);
}
