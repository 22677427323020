/* eslint-disable react/jsx-props-no-spreading */
// @ts-nocheck
import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { ENVIO } from 'constants/common/pageTitles';
import { kebabCase } from 'lodash';
import { ServiceContext } from 'contexts/service';
import { Container, Collapse } from '@material-ui/core';
// import NoResults from 'global/modules/NoResults';
import {
  string, oneOfType, number, func,
} from 'prop-types';
import { clearResponses as clear } from 'store/actions/processes';
import { clearResponses as clearL } from 'store/actions/batchpost';
import Header from './Header';
import Dialogs from './modules/Dialogs';
import Menus from './modules/Menus';
import { useStyles } from '../style';
import Post from './Post';
import Historic from './Historic';

function Envio({
  update,
  setUpdate,
  subtab,
  clearResponses,
  clearLots,
}) {
  const classes = useStyles();
  const serviceContext = useContext(ServiceContext);
  const pageTitle = ENVIO;
  const tabs = ['Em montagem', 'Histórico'];
  const tabsKebab = tabs.map((a) => kebabCase(a));
  const index = subtab ? tabsKebab.indexOf(subtab) : 0;
  const [menuValue, setMenuValue] = useState(index >= 0 ? index : 0);
  const updates = { update, setUpdate };

  const handleMenu = (e, newValue) => {
    clearResponses();
    clearLots();
    setMenuValue(newValue);
  };

  return (
    <>
      <Header
        menuValue={menuValue}
        pageTitle={pageTitle}
        handleMenu={handleMenu}
        tabsKebab={tabsKebab}
        tabs={tabs}
      />
      <Container maxWidth="xl">
        <Menus />
        <Collapse in={menuValue !== 1}>
          <Post isIn={menuValue !== 1} {...updates} />
        </Collapse>
        <Collapse in={menuValue === 1}>
          <Historic isIn={menuValue === 1} {...updates} />
        </Collapse>
        <Dialogs
          context={serviceContext}
          classes={classes}
          setUpdate={setUpdate}
          update={update}
        />
      </Container>
    </>
  );
}

Envio.propTypes = {
  update: oneOfType([string, number]),
  subtab: string,
  setUpdate: func,
  clearLots: func.isRequired,
  clearResponses: func.isRequired,
};

Envio.defaultProps = {
  update: '',
  subtab: '',
  setUpdate: () => { },
};

export default connect(null, { clearResponses: clear, clearLots: clearL })(Envio);
