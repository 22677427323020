/* eslint-disable max-lines */
/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect } from 'react';
import { object, func } from 'prop-types';
import uniqid from 'uniqid';
import { ServiceContext } from 'contexts/service';
import { connect } from 'react-redux';
import {
  Paper, IconButton, Grid, Button, ButtonGroup, Tooltip, Typography,
} from '@material-ui/core';
import {
  MoreVert as MoreVertIcon, Edit, Send, CheckBoxOutlineBlank, CheckBox,
} from '@material-ui/icons';
import Contact from 'global/modules/Contact';
import * as $_ from 'global/functions';
import clsx from 'clsx';
import GridDocs from 'service/modules/GridDocs';
import { PROCESS } from 'constants/service';
import { showConfirmation } from 'store/actions/confirmations';
import { DOCSOK_ROW } from 'constants/service/menus';
import { confirmDocumentsDocumentation } from 'store/actions/documents';
import { STEP_ANALYSIS, STEP_POST } from 'constants/process';
import { createSettings as cSettings, updateSettings as uSettings, changeStepProcess } from 'store/actions/processes';
import { FULFILLED } from 'constants/session';
import { useStyles } from '../style';

function Row({
  item, updateSettings, createSettings, setUpdate, actionStatus, settingsStatus, ...props
}) {
  const classes = useStyles(props);
  const process = item;
  const client = process;
  const checkItems = [
    { item: 'doc', label: 'Documentação' },
    { item: 'hague', label: 'Apostilamento' },
    { item: 'assento', label: 'Registro / Assento' },
    { item: 'procuracao', label: 'Procuração' },
  ];
  const settingsObj = item.settings.find((a) => a.key === STEP_ANALYSIS);
  const checkSettings = settingsObj
    ? JSON.parse(settingsObj.value)
    : {};
  const settingsPost = {};
  const clearPost = {};
  checkItems.forEach((itemCheck) => {
    settingsPost[itemCheck.item] = (item.settings.find((a) => a.key === STEP_ANALYSIS)
      && item.settings
        .find((a) => a.key === STEP_ANALYSIS)[itemCheck.item])
      || false;
    clearPost[itemCheck.item] = false;
  });
  const [settings, setSettings] = useState({});
  const [allChecked, setAllChecked] = useState(false);
  const [requested, setRequested] = useState(false);
  const [fulfilled, setFulfilled] = useState(false);

  const serviceContext = useContext(ServiceContext);

  const {
    setDocsShown,
    handleShowMoreInfo,
    openMenu,
  } = serviceContext;

  useEffect(() => {
    setSettings(checkSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    const checkAllChecked = Object.values(settings).length > 0 && Object.values(settings)
      .filter((a) => !a).length === 0;
    setAllChecked(checkAllChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    if (actionStatus === FULFILLED) {
      setUpdate(uniqid());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionStatus]);

  useEffect(() => {
    if (settingsStatus === FULFILLED && requested) {
      setRequested(false);
      setFulfilled(true);
    }
  }, [settingsStatus, requested]);

  const handleClearChecking = (e) => {
    $_.stopPropagation(e);
    updateSettings(item.id, STEP_ANALYSIS, clearPost);
    setSettings(clearPost);
  };
  const handleCheck = (thisItem, e) => {
    $_.stopPropagation(e);
    let postable = {};
    if (item.settings.find((a) => a.key === STEP_ANALYSIS) || fulfilled) {
      postable = { ...settings, [thisItem]: !settings[thisItem] };
      updateSettings(item.id, STEP_ANALYSIS, postable);
    } else {
      postable = { ...settingsPost, [thisItem]: !settingsPost[thisItem] };
      createSettings(item.id, STEP_ANALYSIS, postable);
      setRequested(true);
    }
    setSettings(postable);
  };
  const handleEndStep = (e) => {
    $_.stopPropagation(e);
    const confirmThis = () => {
      props.changeStepProcess(process.id, STEP_POST, true);
    };

    props.showConfirmation(
      {
        title: 'Confirmar',
        message: `Confirmar que os documentos do processo de ${process.applicant.name} estão prontos para envio?`,
      },
      {
        confirm: 'Confirmar',
        cancel: 'Cancelar',
      },
      {
        func: confirmThis,
        payload: {},
      },
    );
  };

  return (
    <>
      <Paper
        key={`${process.id}`}
        className={clsx(classes.body, classes.pointer)}
        onClick={(e) => handleShowMoreInfo(true, process, e, PROCESS)}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={4} className={classes.centeredGrid}>
            <Contact
              item={{ client_id: process.identifier }}
              customer={{ name: process.applicant.name }}
              short
            />
          </Grid>
          <Grid item xs={3} className={classes.centeredGrid}>
            {process.apostilate_date && $_.semanticDate(process.apostilate_date, true)}
          </Grid>
          <GridDocs
            currentClient={process}
            xs={2}
            scopeId={process.sales_scope.id}
            clientName={process.applicant.name}
            setDocsShown={setDocsShown}
            docType={PROCESS}
          />
          <Grid item xs={3} className={clsx(classes.centeredGrid)} style={allChecked ? { flexDirection: 'row', alignItems: 'center' } : {}}>
            {allChecked
              ? (
                <ButtonGroup variant="contained" color="primary" style={{}}>
                  <Tooltip title="Editar confirmação" placement="left">
                    <Button onClick={(e) => handleClearChecking(e)} className="secondary" style={{ paddingLeft: 8, paddingRight: 8, minWidth: 'unset', minHeight: 'unset', maxwidth: 32 }}>
                      <Edit fontSize="small" />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Encaminhar para envio" placement="left">
                    <Button onClick={(e) => handleEndStep(e)}>
                      <Send fontSize="small" />
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              ) : checkItems.map((a) => (
                <Typography
                  key={`${client.id}${a.item}`}
                  className={classes.checkboxLabel}
                  onClick={(e) => handleCheck(a.item, e)}
                  color={Object.keys(settings).length > 0 && settings[a.item]
                    ? 'primary'
                    : 'textSecondary'}
                  variant="body2"
                >
                  {Object.keys(settings).length > 0 && settings[a.item]
                    ? (
                      <CheckBox
                        fontSize="small"
                        color="inherit"
                        className={classes.smallCheckbox}
                        style={{ marginRight: 4 }}
                      />
                    ) : (
                      <CheckBoxOutlineBlank
                        fontSize="small"
                        color="inherit"
                        className={classes.smallCheckbox}
                        style={{ marginRight: 4 }}
                      />
                    )}
                  {a.label}
                </Typography>
              ))}
          </Grid>
        </Grid>
        <IconButton
          aria-label={`Ações de menu relacionadas ao processo de ${process.applicant.name}`}
          aria-controls="long-menu"
          aria-haspopup="true"
          className={clsx(classes.floatingButtons, classes.floatingRight, 'rootChild')}
          onClick={(e) => {
            $_.stopPropagation(e);
            openMenu(true, DOCSOK_ROW, e.currentTarget, process);
          }}
        >
          <MoreVertIcon />
        </IconButton>
      </Paper>
    </>
  );
}

Row.defaultProps = {
  item: {},
};

Row.propTypes = {
  item: object,
  setUpdate: func.isRequired,
};

const actions = {
  showConfirmation,
  confirmDocumentsDocumentation,
  createSettings: cSettings,
  updateSettings: uSettings,
  changeStepProcess,
};

const mapStateToProps = (state) => ({
  actionStatus: state.processes.actionStatus,
  settingsStatus: state.processes.settingsStatus,
});

export default connect(mapStateToProps, actions)(Row);
