import { createSelector } from 'reselect';
import { regBuilder } from 'global/functions';
import { deburr, lowerCase, includes } from 'lodash';

const allProducts = (state) => state.product.allProducts;

const clearBurr = (value = '') => lowerCase(deburr(value));

export const filterProducts = createSelector(
  allProducts,
  (productList) => (searchValue = '', categories = []) => {
    let returnValues = productList || [];
    let newSearch = searchValue !== ''
      ? new RegExp(regBuilder(clearBurr(searchValue)), 'i')
      : /[\s\S]*/;
    if (categories.length) {
      returnValues = returnValues.filter(
        (product) => includes(categories, product.category)
      );
    }
    if (searchValue) {
      returnValues = returnValues.filter(
        (product) => Boolean(clearBurr(product.title).match(newSearch))
          || product.requirements.filter((requirement) => clearBurr(requirement.name).match(newSearch)).length
        );
    }
    return Array.from(returnValues);
  },
);
