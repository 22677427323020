export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const DELETE_DOCUMENT_FULFILLED = 'DELETE_DOCUMENT_FULFILLED';
export const DELETE_DOCUMENT_REJECTED = 'DELETE_DOCUMENT_REJECTED';

export const SEND_DOCUMENT = 'SEND_DOCUMENT';
export const SEND_DOCUMENT_FULFILLED = 'SEND_DOCUMENT_FULFILLED';
export const SEND_DOCUMENT_REJECTED = 'SEND_DOCUMENT_REJECTED';

export const FETCH_DOCUMENTATION_DOCUMENTS = 'FETCH_DOCUMENTATION_DOCUMENTS';
export const FETCH_DOCUMENTATION_DOCUMENTS_FULFILLED = 'FETCH_DOCUMENTATION_DOCUMENTS_FULFILLED';
export const FETCH_DOCUMENTATION_DOCUMENTS_REJECTED = 'FETCH_DOCUMENTATION_DOCUMENTS_REJECTED';

export const CLEAR_COLLECTION = 'DOCUMENTS_CLEAR_COLLECTION';

export const CONFIRM_DOCUMENTS_DOCUMENTATION = 'CONFIRM_DOCUMENTS_DOCUMENTATION';
export const CONFIRM_DOCUMENTS_DOCUMENTATION_FULFILLED = 'CONFIRM_DOCUMENTS_DOCUMENTATION_FULFILLED';
export const CONFIRM_DOCUMENTS_DOCUMENTATION_REJECTED = 'CONFIRM_DOCUMENTS_DOCUMENTATION_REJECTED';

export const NON_APPLICABLE_DOCUMENT = 'NON_APPLICABLE_DOCUMENT';
export const NON_APPLICABLE_DOCUMENT_FULFILLED = 'NON_APPLICABLE_DOCUMENT_FULFILLED';
export const NON_APPLICABLE_DOCUMENT_REJECTED = 'NON_APPLICABLE_DOCUMENT_REJECTED';
