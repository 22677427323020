import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { portugueseInfoForm, PEOPLE_FORM_MODE_NEW } from 'constants/sales';
import SaleFormNewPeople from './SaleFormNewPeople';

const NewPeopleForm = () => (
  <>
    <Typography variant="h5" paragraph>
      Cadastrar português
    </Typography>
    <Paper elevation={3}>
      <SaleFormNewPeople
        people={portugueseInfoForm}
        mode={PEOPLE_FORM_MODE_NEW}
      />
    </Paper>
  </>
);

export default NewPeopleForm;
