import { SHOW_CONFIRMATION, HIDE_CONFIRMATION } from 'store/definitions/confirmations';

export function showConfirmation(text, buttons, onConfirm) {
  return {
    type: SHOW_CONFIRMATION,
    payload: { text, buttons, onConfirm },
  };
}

export function hideConfirmation() {
  return {
    type: HIDE_CONFIRMATION,
  };
}
