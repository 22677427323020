// import CryptoJS from 'crypto-js';
// import _ from 'lodash';
import querystring from 'querystring';

// const SECRET = 'k8VvJOCtrm';
/*
export const encrypt = (object = {}) => {
  if (_.isEmpty(object) || !_.isObject(object)) return false;
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(object), SECRET).toString();
  const e64 = CryptoJS.enc.Base64.parse(encrypted);
  const eHex = e64.toString(CryptoJS.enc.Hex);
  return eHex;
};

export const decrypt = (value = '') => {
  try {
    const reb64 = CryptoJS.enc.Hex.parse(value);
    const bytes = reb64.toString(CryptoJS.enc.Base64);
    const decrypted = CryptoJS.AES.decrypt(bytes, SECRET);
    const plain = decrypted.toString(CryptoJS.enc.Utf8);
    return JSON.parse(plain);
  } catch (error) {
    return new Error(error);
  }
};
*/

export const parse = (string = '') => querystring.parse(string);

export const stringify = (object = {}) => querystring.stringify(object);

export const decode = (string = '', rParse = false) => {
  const decoded = decodeURIComponent(Buffer.from(string, 'base64').toString('ascii'));
  return rParse ? parse(decoded) : decoded;
};

export const encode = (string = '', rStringify = false) => {
  const encoded = encodeURIComponent(Buffer.from(string).toString('base64'));
  return rStringify ? stringify(encoded) : encoded;
};
