// @ts-nocheck
import React from 'react';
import cep from 'cep-promise';
import { isEqual } from 'lodash';
import { getLabel } from 'constants/newClient';
import {
  Dialog,
  Typography,
  Divider,
  DialogContent,
  DialogActions,
  Button,
  //  Paper,
} from '@material-ui/core';
import { useStyles } from './style';
import Contact from 'client/NewClientForm/Contact';
import Address from 'client/NewClientForm/Address';
import Personal from 'client/NewClientForm/Personal';
import { formatThis, formatToPost } from './functions';
import { updateUser } from 'store/actions/users';
import { connect } from 'react-redux';
import { FULFILLED, REJECTED } from 'constants/common';
import { ActionsContext } from 'contexts/actions';

const DialogEditCustomer = ({ open, closeDialog, currentCustomer, status, update }) => {
  const classes = useStyles({ open });
  const { reloadAll } = React.useContext(ActionsContext);
  const [requesting, setRequesting] = React.useState(false);
  const customer = currentCustomer ? { ...formatThis(currentCustomer) } : {};
  const [form, setForm] = React.useState(customer);
  const checkEqual = isEqual(form, customer);
  const handleSelectCountry = (selectedCountry) => setForm({
    ...form, country: selectedCountry.alpha2Code,
  });
  const loadAddressByZipCode = () => {
    const { zipCode } = form;
    if (zipCode && zipCode.length === 9) {
      cep(zipCode)
        .then((res) => {
          setForm({
            ...form,
            zipCode: res.cep,
            address1: res.street,
            address4: res.neighborhood,
            city: res.city,
            state: res.state,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleChangeForm = (field, value) => {
    setForm({ ...form, [field]: value });
  };

  React.useEffect(() => {
    //* AUTOCOMPLETE WORKAROUND
    const countryEl = document.getElementById('client-country');
    if (countryEl) countryEl.setAttribute('autocomplete', 'nope');
  }, []);
  const necessaryProps = { form, handleChangeForm };

  const addressProps = {
    ...necessaryProps,
    loadAddressByZipCode,
    getLabel,
    classes,
    handleSelectCountry,
  };
  const personalProps = {
    ...necessaryProps,
  };
  const contactProps = {
    ...necessaryProps,
  };
  const handleSend = (e) => {
    if (currentCustomer.id) {
      setRequesting(true);
      const formattedForm = formatToPost(form, currentCustomer);
      update(currentCustomer.id, formattedForm);
    }
    e.preventDefault();
  };

  React.useEffect(() => {
    if (requesting && (status === FULFILLED || status === REJECTED)) {
      setRequesting(false);
      reloadAll();
      closeDialog();
    }
    // eslint-disable-next-line
  }, [status, reloadAll, closeDialog]);

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      maxWidth="md"
      scroll="body"
    >
      <form onSubmit={handleSend}>
        <DialogContent>
          {currentCustomer && Object.keys(currentCustomer).length > 0 && (
            <>
              <Typography variant="h4" paragraph>
                {`Editar as informações de ${currentCustomer.name}`}
              </Typography>
              <Contact {...contactProps} />
              <Divider />
              <Personal {...personalProps} />
              <Divider />
              <Address {...addressProps} />
              <Divider />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => closeDialog()}
            color="secondary">
            {'Fechar'}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            disabled={checkEqual || requesting}>
            {checkEqual ? 'Editar' : 'Confirmar edição'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  status: state.listUsers.status,
});

export default connect(mapStateToProps, { update: updateUser })(DialogEditCustomer);
