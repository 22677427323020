/* eslint-disable max-lines */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import uniqid from 'uniqid';
import { stopPropagation } from 'global/functions';
import { updatePerson } from 'store/actions/sales';
import _ from 'lodash';
import { genders } from 'objects/imports';
import { REQUESTING, FULFILLED } from 'constants/common';
import { showConfirmation } from 'store/actions/confirmations';
import {
  array, object, func, string, bool,
} from 'prop-types';
import {
  Button,
  Grid,
  TextField,
  MenuItem,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { FREGUESIA_FORM_CERTIFICATE_TYPE, CONSULATE_FORM_CERTIFICATE_TYPE } from 'constants/sales';
import { editPersonObject } from 'constants/common/citizeninfo';
import { prepareToSubmit } from './functions';
import FormFreguesia from './FormFreguesia';
import FormConsulate from './FormConsulate';
import FormDefault from './FormDefault';
import FormApplicant from './FormApplicant';

function Edit({
  certificates,
  citizen,
  classes,
  confirmation,
  update,
  updateStatus,
  editing,
  setEditing,
  setUpdate,
  applicant,
  applicantSelf,
}) {
  const [formfields, setFormfields] = useState(editPersonObject(citizen));
  const [requested, setRequested] = useState(false);
  const changed = !_.isEqual(formfields, editPersonObject(citizen));
  const handleUpdateForm = (name, value) => {
    setFormfields((o) => ({
      ...o,
      [name]: value,
    }));
  };
  const handleSelectCertificateType = (certificate) => {
    setFormfields((o) => ({
      ...o,
      certificate_id:
        certificate.id,
      certificate_type: certificate,
    }));
  };
  const handleShowForm = () => {
    if (!applicant || applicantSelf) {
      const type = formfields.certificate_type === null || formfields.certificate_type === undefined ? '' : formfields.certificate_type.type;
      switch (type) {
        case FREGUESIA_FORM_CERTIFICATE_TYPE:
          return (
            <FormFreguesia formfields={formfields} handleUpdateForm={handleUpdateForm} />
          );
        case CONSULATE_FORM_CERTIFICATE_TYPE:
          return (
            <FormConsulate formfields={formfields} handleUpdateForm={handleUpdateForm} />
          );
        default:
          return (
            <FormDefault formfields={formfields} handleUpdateForm={handleUpdateForm} />
          );
      }
    }
    return <FormApplicant form={formfields} handleChangeForm={handleUpdateForm} />;
  };

  useEffect(() => {
    if (certificates.length > 0) {
      handleSelectCertificateType(
        _.first(
          _.filter(certificates, [formfields.certificate_id ? 'id' : 'type', formfields.certificate_id || 1]),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificates]);

  useEffect(() => {
    if (updateStatus === FULFILLED && editing && requested) {
      setEditing(false);
      setUpdate(uniqid());
      setRequested(false);
    }
  }, [updateStatus, setEditing, setUpdate, editing, requested]);

  const handleSubmit = (e) => {
    stopPropagation(e);
    const handleUpdate = () => {
      update({
        personId: formfields.id,
        data: prepareToSubmit(formfields),
      });
      setRequested(true);
    };
    confirmation(
      {
        title: 'Salvar alterações',
        message: `Salvar alterações no cadastro de de ${citizen.name}?`,
      },
      {
        confirm: 'Salvar',
        cancel: 'Cancelar',
      },
      {
        func: handleUpdate,
      },
    );
  };

  return (
    <Grid item xs={12}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={applicantSelf || applicant ? 6 : 8}>
            <TextField
              required
              fullWidth
              variant="outlined"
              name="name"
              value={formfields.name}
              label="Nome"
              onChange={(e) => handleUpdateForm('name', e.target.value)}
            />
          </Grid>
          {applicant && (
            <Grid item xs={2}>
              <TextField
                fullWidth
                select
                variant="outlined"
                name="gender"
                label="Gênero"
                value={formfields.gender}
                onChange={(e) => handleUpdateForm('gender', e.target.value)}
              >
                {genders.map((gender) => (
                  <MenuItem
                    key={`gender${gender.label}`}
                    value={gender.value}
                  >
                    {gender.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          {(!applicant || applicantSelf) && (
            <Grid item xs={4}>
              {certificates.length > 0
                ? (
                  <TextField
                    required
                    fullWidth
                    select
                    variant="outlined"
                    name="certificateType"
                    label="Tipo de certificado"
                    value={formfields.certificate_id}
                    onChange={(e) => handleSelectCertificateType(_.first(_.filter(certificates, ['id', e.target.value])))}
                  >
                    {certificates.map((certificate) => (
                      <MenuItem
                        key={certificate.id}
                        value={certificate.id}
                      >
                        {certificate.name}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <Skeleton width="100%" height="100%" style={{ margin: 0 }} />
                )}
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              name="mother_name"
              label="Nome da mãe"
              value={formfields.mother_name}
              onChange={(e) => handleUpdateForm('mother_name', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              name="father_name"
              label="Nome do pai"
              value={formfields.father_name}
              onChange={(e) => handleUpdateForm('father_name', e.target.value)}
            />
          </Grid>
          {handleShowForm()}
          <Grid item xs={12}>
            <Typography align="right" component="div">
              <div style={{ position: 'relative', display: 'inline-block' }}>
                <Button
                  disabled={!changed || updateStatus === REQUESTING}
                  className={classes.btn}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {'Salvar alterações'}
                </Button>
                {updateStatus === REQUESTING && (
                  <CircularProgress size={24} className={classes.buttonProgress} />
                )}
              </div>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  certificates: state.certificates.collection,
  updateStatus: state.sales.status,
});
const actions = {
  confirmation: showConfirmation,
  update: updatePerson,
};
Edit.propTypes = {
  updateStatus: string.isRequired,
  certificates: array.isRequired,
  citizen: object.isRequired,
  classes: object.isRequired,
  confirmation: func.isRequired,
  update: func.isRequired,
  setEditing: func.isRequired,
  setUpdate: func.isRequired,
  editing: bool.isRequired,
  applicant: bool,
  applicantSelf: bool,
};

Edit.defaultProps = {
  applicant: false,
  applicantSelf: false,
};

export default connect(mapStateToProps, actions)(Edit);
