import React, { useEffect } from 'react';
import { 
  Paper, 
  Container, 
  makeStyles, 
  Table, 
  TableContainer, 
  TableRow, 
  TableCell, 
  TableSortLabel, 
  TableHead, 
  TableBody, 
  Typography, 
  TablePagination, 
  Tooltip, 
  Button, 
  Modal, 
  Fade,
  Link as ULink
} from '@material-ui/core/';
import Root from 'global/modules/Root';
import PaymentPageHeader from 'global/PaymentPageHeader';
import { connect } from 'react-redux';
import moment from 'moment';
import CopyElement from 'global/CopyElement';
import { loadPayments, checkPayment, uncheckPayment } from 'store/actions/payment';
import { toCurrency } from 'global/functions';
import { Link } from 'react-router-dom';
import { paymentStatusTitles, paymentMethodTitles, PAYMENT_STATUS_CANCELLED } from 'constants/sales';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CircularProgress from '@material-ui/core/CircularProgress';
import DescriptionIcon from '@material-ui/icons/Description';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import { url } from 'api';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(0, 4),
    marginBottom: theme.spacing(1),
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'relative',
  },
  body: {
    padding: theme.spacing(1.5, 4),
    margin: theme.spacing(1.5, 0),
    position: 'relative',
    minHeight: theme.spacing(8),
    transition: theme.transitions.create('min-height'),
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'start',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: 400,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

function Payments({ loadPayments, payments, checkPayment, uncheckPayment, ...props }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [month, setMonth] = React.useState(6);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [search, setSearch] = React.useState('');
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [editablePayment, setEditablePayment] = React.useState({});
  const [isEditPayment, setIsEditPayment] = React.useState(false);
  const [paymentDate, setPaymentDate] = React.useState(null);
  const [processing, setProcessing] = React.useState(false);
  const [status, setStatus] = React.useState([]);
  const [paymentMethod, setPaymentMethod] = React.useState(null);
  const [checked, setChecked] = React.useState(null);
  const [byConfirmedSalesOnly, setByConfirmedSalesOnly] = React.useState(false);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    { id: 'id', numeric: false, disablePadding: true, label: '#', orderable: true },
    { id: 'sales_scopes.client_id', numeric: false, disablePadding: true, label: 'PT', orderable: true },
    { id: 'sales_scopes.internal_id', numeric: true, disablePadding: false, label: 'Pipedrive deal', orderable: true },
    { id: 'contract', numeric: true, disablePadding: false, label: '', orderable: false },
    { id: 'users.name', numeric: false, disablePadding: false, label: 'Cliente', orderable: true },
    { id: 'payments.payment_method', numeric: false, disablePadding: false, label: 'Meio de pagamento', orderable: true },
    { id: 'payments.status', numeric: false, disablePadding: false, label: 'Status', orderable: true },
    { id: 'payments.months', numeric: true, disablePadding: false, label: 'Total de parcelas', orderable: true },
    { id: 'payments.month', numeric: true, disablePadding: false, label: 'Nº parcela', orderable: true },
    { id: 'payments.due_date', numeric: false, disablePadding: false, label: 'Data de Vencimento', orderable: true },
    { id: 'payments.payment_date', numeric: false, disablePadding: false, label: 'Data de Pagamento', orderable: true },
    { id: 'payments.value', numeric: false, disablePadding: false, label: 'Valor', orderable: true },
    { id: 'actions', numeric: false, disablePadding: false, label: '', orderable: false },
  ];

  const handleSearchPayment = (value) => {
    setSearch(value);
    setPage(0);
  };

  const checkRow = (id) => console.log(id);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event, newRowsPage) => setPage(newRowsPage);

  const handleChangeStartDate = (date) => setStartDate(date);
  
  const handleChangeEndDate = (date) => setEndDate(date);

  const handleEditPayment = (payment) => {
    setEditablePayment(payment);
    setPaymentDate(payment.payment_date)
    setIsEditPayment(true);
  }

  const handleConfirmed = (confirmed) => setByConfirmedSalesOnly(confirmed.target.value);

  const handleCloseEditPayment = () => {
    setEditablePayment({});
    setPaymentDate(null);
    setIsEditPayment(false);
    setProcessing(false);
    setPage(page +1)
    setTimeout(() => setPage(page), 200);
  }

  const handleProcessing = async (type) => {
    setProcessing(true);
    if (type == 'check') await checkPayment(editablePayment.id,  moment(paymentDate).format('YYYY-MM-DD'));
    if (type == 'uncheck') await uncheckPayment(editablePayment.id);
    handleCloseEditPayment();
  }

  const handleChangeStatus = (event) => setStatus(event.target.value)

  const handlePaymentMethod = (event) => setPaymentMethod(event.target.value);
  
  const handleChecked = (event) => setChecked(event.target.value);
  
  useEffect(() => {
    loadPayments(
      page, 
      orderBy,
      order,
      search, 
      startDate != null ? moment(startDate).format('YYYY-MM-DD') : null, 
      endDate != null ? moment(endDate).format('YYYY-MM-DD') : null,
      status.join(),
      byConfirmedSalesOnly,
      paymentMethod,
      checked
    );
  }, [page, search, startDate, endDate, orderBy, order, status, paymentMethod, checked, byConfirmedSalesOnly])

  return (
    <Root header footer paddingTop>
      <PaymentPageHeader
        title="Pagamentos"
        searchValue={search}
        onSearch={handleSearchPayment}
        startDate={startDate}
        onChangeStartDate={handleChangeStartDate}
        endDate={endDate}
        onChangeEndDate={handleChangeEndDate}
        status={status}
        checked={checked}
        paymentMethod={paymentMethod}
        onChangePaymentMethod={handlePaymentMethod}
        onChangeChecked={handleChecked}
        onChangeStatus={handleChangeStatus}
        confirmed={byConfirmedSalesOnly}
        onChangeConfirmed={handleConfirmed}
      />
      <Container maxWidth="xl">
        <Paper>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align="center"
                      padding="default"
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={() => headCell.orderable ? handleRequestSort(headCell.id) : null}
                      >
                      {headCell.label}
                        {orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.data.map(row => (
                  <TableRow id={row.id}>
                    <TableCell padding="default">
                      <Typography variant="body1" color="textSecondary">{row.id}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <Tooltip title="Visualizar venda">
                        <Link 
                          to={`/nova-venda/${row.sales_scope_id || ''}`} 
                          target="_blank"
                        >
                          {row.clientId}
                        </Link>
                      </Tooltip>
                    </TableCell>
                    <TableCell padding="default">
                      <Typography variant="body1" color="textSecondary">{row.deal}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      {row.contract_path != null && (
                        <Tooltip title="Contrato">
                          <ULink 
                            href={`${url}/contracts/${row.contract_path}`}
                            target="_blank"
                          >
                            <DescriptionIcon />
                          </ULink>
                        </Tooltip>
                      )}
                        
                      {row.payment_doc != null && (
                        <Tooltip title="Comprovante">
                          <ULink 
                            href={`${url}/documents/${row.payment_doc}`} 
                            target="_blank"
                          >
                            <ConfirmationNumberIcon />
                          </ULink>
                        </Tooltip>
                      )}
                      
                    </TableCell>
                    <TableCell padding="default">
                      <Typography variant="body1" color="textSecondary">{row.customerName}</Typography>
                      <Typography variant="body1" color="textSecondary">
                        <CopyElement>{row.customerEmail}</CopyElement>
                      </Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <Typography color="textSecondary">{paymentMethodTitles[row.payment_method]}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <Typography color="textSecondary">{paymentStatusTitles(row.status)}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <Typography color="textSecondary">{row.months}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <Typography color="textSecondary">{row.month_number}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <Typography color="textSecondary">{row.due_date != null ? moment(row.due_date).format('DD/MM/YYYY') : ''}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <Typography color="textSecondary">{row.payment_date != null ? moment(row.payment_date).format('DD/MM/YYYY'): ''}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <Typography color="textSecondary">{toCurrency(row.value / 100)}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <Tooltip title={row.checked == 0 ? `Ainda não conferido` : `Conferido`}>
                        <Button 
                          variant="outlined" 
                          color={row.checked == 0 ? `secondary` : `primary`}
                          onClick={() => row.status !== PAYMENT_STATUS_CANCELLED ? handleEditPayment(row) : null}
                        >
                          {row.checked == 0 ? (<CheckCircleOutlineIcon />) : (<CheckCircleIcon />)}
                        </Button>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={payments.total}
            rowsPerPage={payments.per_page}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Container>
      <Modal 
        open={isEditPayment}
        onClose={handleCloseEditPayment}
        className={classes.modal}
      >
        <Fade in={isEditPayment}>
          <div className={classes.paper}>
            {editablePayment.checked == 0 ? (
              <div>
              <Typography>
                Pagamento {editablePayment.month_number}/{editablePayment.months} da venda {editablePayment.clientId}. O status desse pagamento será definido como PAGO.
              </Typography>
              <br />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  format="dd/MM/yyyy"
                  id="date-picker-inline"
                  placeholder="Data do pagamento"
                  value={paymentDate}
                  inputVariant="outlined"
                  maxDate={moment()}
                  fullWidth
                  mask="__/__/____"
                  onChange={(value) => setPaymentDate(value)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
              <Button 
                fullWidth 
                color="primary" 
                variant="contained" 
                style={{marginTop: 20}}
                onClick={() => handleProcessing('check')}
              >
                {!processing ? (<Typography>Conferir pagamento</Typography>) : (<CircularProgress />)}
              </Button>
              </div>
            ) : (
              <div>
              <Typography>
                Você tem certeza que deseja definir este pagamento como <b>NÃO CONFERIDO?</b>
              </Typography>
              <Button 
                fullWidth 
                color="primary" 
                variant="contained" 
                style={{marginTop: 20}}
                onClick={() => handleProcessing('uncheck')}
              >
                {!processing ? (<Typography>Alterar confirmação</Typography>) : (<CircularProgress />)}
              </Button>
              </div>
            )}
            <Button 
                fullWidth 
                color="secondary" 
                variant="outlined" 
                style={{marginTop: 5}}
                onClick={handleCloseEditPayment}
              >
                <Typography>Cancelar</Typography>
              </Button>
          </div>
        </Fade>
          
      </Modal>
    </Root>
  )
}

const mapStateToProps = (state) => ({
  payments: state.payment.payments
});

const actions = {
  loadPayments: loadPayments,
  checkPayment: checkPayment,
  uncheckPayment: uncheckPayment,
}

export default connect(mapStateToProps, { ...actions })(Payments);