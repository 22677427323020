import { put, takeLatest, all, call } from 'redux-saga/effects';
import * as definitions from 'store/definitions/steps';
import * as API from 'api';
import { STEP_PATH } from 'api/paths';

function* fetchAll() {
  try {
    const payload = yield call(API.fetch, STEP_PATH, {});
    yield put({ type: definitions.FETCH_ALL_STEPS_FULFILLED, payload: payload.data });
  } catch (error) {
    yield put({ type: definitions.FETCH_ALL_STEPS_REJECTED, payload: { error } });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.FETCH_ALL_STEPS, fetchAll),
  ]);
}
