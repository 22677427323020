import React from 'react';
import * as PropTypes from 'prop-types';
import { Add } from '@material-ui/icons';
import { Tooltip, Button, makeStyles } from '@material-ui/core';
import AddDialog from './AddDialog';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0,
      marginLeft: theme.spacing(2),
    },
  },
}));

const AddProduct = ({ tooltip, setUpdate }) => {
  const classes = useStyles({});
  const [open, setOpen] = React.useState(false);
  const handleToggle = () => setOpen((o) => !o);
  return (
    <div>
      <AddDialog open={open} setUpdate={setUpdate} setOpen={setOpen} handleToggle={handleToggle} />
      <Tooltip title={tooltip}>
        <Button
          className={classes.button}
          variant="contained"
          onClick={handleToggle}
        >
          <Add />
        </Button>
      </Tooltip>
    </div>
  );
};

AddProduct.propTypes = {
  tooltip: PropTypes.string,
};

AddProduct.defaultProps = {
  tooltip: 'Adicionar um tipo de processo',
};

export default AddProduct;
