import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { ServiceContext } from 'contexts/service';
import clsx from 'clsx';
import {
  Close as CloseIcon,
} from '@material-ui/icons';
import {
  Grid,
  Typography,
  Tooltip,
  Button,
  Paper,
  ButtonGroup,
} from '@material-ui/core';
import * as $_ from 'global/functions';
import { confirmAligmentMultiple } from 'store/actions/sales';
import { showConfirmation } from 'store/actions/confirmations';
import { useStyles } from '../style';

function Header({ filteredSize, ...props }) {
  const classes = useStyles();
  const serviceContext = useContext(ServiceContext);
  const {
    clearMultipleSelect,
  } = serviceContext;

  const handleConfirmEndMultipleStep = (e) => {
    $_.stopPropagation(e);
    const confirmAlignMultiple = () => {
      serviceContext.multipleSelect.forEach((scope, index) => {
        const findScope = props.scopes.find((a) => a.id === scope);
        if (findScope) {
          props.confirmAligmentMultiple(
            scope,
            index === serviceContext.multipleSelect.length - 1,
          );
        }
      });
    };

    if (serviceContext.multipleSelect.length > 0) {
      props.showConfirmation(
        {
          title: 'Confirmar alinhamento',
          message: `Confirmar alinhamento de ${serviceContext.multipleSelect.length} clientes?`,
        },
        {
          confirm: 'Confirmar',
          cancel: 'Cancelar',
        },
        {
          func: confirmAlignMultiple,
          payload: {},
        },
      );
    }
  };

  const selected = serviceContext.multipleSelect.length > 0;
  if (filteredSize > 0) {
    return (
      <Paper className={clsx(classes.header, selected ? 'selected' : 'regular')}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="body2">Cliente</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">Contato</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">Data de entrada</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">
              {'Documentos'}
            </Typography>
          </Grid>
          <Grid item style={{ textAlign: 'right' }} xs={4}>
            {selected
              && (
                <ButtonGroup variant="contained" color="primary">
                  <Tooltip title="Limpar seleção" placement="left">
                    <Button onClick={() => clearMultipleSelect()} className="secondary">
                      <CloseIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                  <Button onClick={(e) => handleConfirmEndMultipleStep(e)}>
                    {'Alinhamento feito'}
                  </Button>
                </ButtonGroup>
              )}
          </Grid>
        </Grid>
      </Paper>
    );
  }
  return null;
}

const mapStateToProps = (state) => ({
  scopes: state.sales.allSales,
});

export default connect(mapStateToProps, { confirmAligmentMultiple, showConfirmation })(Header);
