import React, { useState } from 'react';
import {
  ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Edit as EditIcon,
} from '@material-ui/icons/';
import { useStyles } from './style';
import RowHeader from './RowHeader';
import RowForm from './RowForm';

function Row({
  user, view, internalType,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <ExpansionPanel
      square
      elevation={3}
      expanded={expanded}
      onChange={handleExpand}
      classes={{ root: classes.bodyWrapper }}
    >
      <ExpansionPanelSummary
        classes={{
          root: classes.bodyRoot,
          expandIcon: classes.buttonEdit,
          content: classes.noMargin,
        }}
        expandIcon={
          expanded
            ? <CloseIcon />
            : <EditIcon />
        }
      >
        <RowHeader user={user} expanded={expanded} />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        className={expanded ? classes.contentWrapper : 'notExpanded'}
      >
        <RowForm user={user} expanded={expanded} internalType={internalType} view={view} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

export default Row;
