/* eslint-disable max-lines */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { showNotification } from 'store/actions/notifications';
import { NOTIFICATION_TYPE_ERROR } from 'constants/notification';
import {
  paymentRow,
  installmentsInterest,
  PAYMENT_CREDIT_CARD,
  PAYMENT_BANK_SLIP,
  IUGU_MAX_VALUE,
  getInstallmentInterestsList,
  getInstallmentsInterests,
  PAYMENT_MAXI_PAGO,
  PAYMENT_BARTE,
} from 'constants/sales';
import {
  Paper,
  makeStyles,
} from '@material-ui/core';
import { convertCurrency, stripValue } from 'helpers';
import { createPayment, removePayment } from 'store/actions/payment';
import { calculateSale } from 'store/actions/sales';
import { prepareToSendPaymentRow, chargable } from './functions';
import PaymentRowAdd from './PaymentRowAdd';
import PaymentRowShow from './PaymentRowShow';

const ADD = 'ADD';
const useStyles = makeStyles((theme) => ({
  paper: (props) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(props.mode === ADD ? 3 : 2),
  }),
  select: {
    display: 'flex',
    alignItems: 'center',
  },
  selectPadding: {
    paddingRight: theme.spacing(4),
  },
  hiddenOverflow: {
    overflow: 'hidden',
  },
}));

function PaymentRow({
  row, mode, scope, amountLeft, notification, ...props
}) {
  const classes = useStyles({ mode });
  const formObj = row
    ? { ...paymentRow, ...row, value: parseInt(row.value, 10) / 100 }
    : paymentRow;
  const [form, setForm] = useState(formObj);
  const { total } = scope;
  const checkInterest = (method, months, charge, value) => {
    let chargeInterest = false;
    let interest_total = 0;
    let interest_percentage = 0;
    const payment_method = method;
    const formValue = value && value.includes(',') ? stripValue(value) : value;
    let customer_interest = charge;

    if (chargable(method)) {
      chargeInterest = charge;
      const installment = getInstallmentsInterests(charge, scope.family_plan);
      interest_percentage = installment[parseInt(months, 10) - 1].total;
      // @ts-ignore
      interest_total = parseInt(stripValue(formValue) / 100 * interest_percentage, 10);

      if (method === PAYMENT_MAXI_PAGO || method === PAYMENT_BARTE) {
        if (parseInt(months) > 12) {
          props.calculateSale(scope.id, true);
        } else {
          props.calculateSale(scope.id, false);
        }
      }
    } else {
      props.calculateSale(scope.id, false);
    }
    setForm({
      ...form,
      payment_method,
      months,
      interest_percentage,
      customer_interest,
      interest_total,
      chargeInterest,
      value,
    });
  };
  const handleChange = (name, value) => {
    setForm({ ...form, [name]: value });
    if (name === 'months') checkInterest(form.payment_method, value, form.chargeInterest, form.value);
    else if (name === 'chargeInterest') checkInterest(form.payment_method, form.months, value, form.value);
    else if (name === 'value') checkInterest(form.payment_method, form.months, form.chargeInterest, value);
  };
  const handleChangeMethod = (value) => {
    handleChange('payment_method', value);
    setForm({
      ...form,
      payment_method: value,
    });
    checkInterest(value, form.months, form.chargeInterest, form.value);
  };
  const handleCents = () => {
    let newValue = `${form.value}`;
    if (newValue !== '' && !newValue.includes(',')) {
      newValue = `${newValue},00`;
    }
    if (stripValue(newValue) > amountLeft) newValue = convertCurrency(amountLeft, false);
    checkInterest(form.payment_method, form.months, form.chargeInterest, newValue);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (mode === ADD) {
      if (
        (form.payment_method === PAYMENT_CREDIT_CARD || form.payment_method === PAYMENT_BANK_SLIP)
        && stripValue(form.value) > IUGU_MAX_VALUE
      ) {
        notification(NOTIFICATION_TYPE_ERROR, `Limite de ${convertCurrency(IUGU_MAX_VALUE)} para este tipo de pagamento.`);
      } else {
        props.createPayment(scope.id, prepareToSendPaymentRow(form), scope.id);
        setForm(formObj);
      }
    } else props.removePayment(row.blockchain, 'Editado no escopo de venda', scope.id);
  };
  const setValueAsTotal = () => {
    const value = stripValue(form.value) === amountLeft ? '' : convertCurrency(amountLeft, false);
    checkInterest(form.payment_method, form.months, form.chargeInterest, value);
  };
  return (
    <Paper elevation={3} className={classes.paper}>
      <form onSubmit={handleSubmit}>
        {mode === ADD
          ? (
            <PaymentRowAdd
              amountLeft={amountLeft}
              form={form}
              total={total}
              classes={classes}
              setValueAsTotal={setValueAsTotal}
              familyPlan={scope.family_plan}
              handleCents={handleCents}
              handleChange={handleChange}
              handleChangeMethod={handleChangeMethod}
            />
          ) : (
            <PaymentRowShow
              row={row}
              form={form}
            />
          )}
      </form>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  paymentStatus: state.payment.status,
});

export default connect(mapStateToProps, { createPayment, removePayment, notification: showNotification, calculateSale })(PaymentRow);
