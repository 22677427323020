// @ts-nocheck
import React from 'react';
import { object } from 'prop-types';
import Theme from 'global/Theme';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import ActionsProvider from 'providers/actions';
import moment from 'moment';
import 'moment/locale/pt-br';
import NewClient from 'client/NewClientForm';
import Login from 'global/Login';
import PrivateRoute from 'middlewares/PrivateRoute';
import Body from 'global/Body';
import { insertToken } from 'api';
import ChatBot from 'global/ChatBot';

moment.locale('pt-br');
moment.defaultFormat = 'YYYY-MM-DD';

insertToken();

const App = ({ history }) => (
  <Theme>
    <ActionsProvider>
      <ConnectedRouter history={history}>
        <Switch>
          <Route
            exact
            path={['/novo-cliente', '/novo-cliente/:encrypted']}
            render={(s) => <NewClient {...s} />}
          />
          <Route exact path={['/login', '/login/:previous']} render={(s) => <Login {...s} />} />
          <PrivateRoute path="/" history={history} component={Body} />
        </Switch>
      </ConnectedRouter>
    </ActionsProvider>
  </Theme>
);

App.propTypes = {
  history: object.isRequired,
};

export default App;
