import React, { useEffect, useState } from 'react';
import uniqid from 'uniqid';
import { connect } from 'react-redux';
import Root from 'global/modules/Root';
import { SalesContext } from 'contexts/sales';
import SaleFormStepOne from 'sales/form/StepOne';
import SalesProvider from 'providers/sales';
import SaleFormHeader from 'sales/form/Header';
import FormProgress from 'sales/form/FormProgress';
import SaleFormStepTwo from 'sales/form/StepTwo';
import SaleFormStepThree from 'sales/form/StepThree';
import SaleFormStepFour from 'sales/form/StepFour';
import SaleFormStepFive from 'sales/form/StepFive';
import ClosedView from 'sales/form/ClosedView';
import ClientNotFound from 'sales/form/ClientNotFound';
import { fetchSaleScopeByClientd } from 'store/actions/sales';
import { SALE_FORM } from 'constants/common/pageTitles';
import { FULFILLED, REJECTED, REQUESTING, DEFAULT } from 'constants/common';
import { Container, Typography } from '@material-ui/core';
import Loading from 'global/modules/Loading';
import Countdown from 'global/modules/Countdown';
import ActionsContext from 'contexts/actions';
import { getCancelToken, cancel } from 'api';
import ChatBot from 'global/ChatBot';

function SalesForm({ confirm, status, scopeById, ...props }) {
  const refresh = React.useContext(ActionsContext).reload;
  const [show, setShow] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [reload, setReload] = useState(uniqid());
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [controlLoading, setControlLoading] = useState(DEFAULT);
  const clientId = props.match.params.client;
  const cancelSource = React.useRef(null);

  const handleLoadEscope = (client) => {
    cancelSource.current = getCancelToken().source();
    if (client || clientId) {
      scopeById(client || clientId);
      setShow(true);
    } else setShow(false);
  };

  useEffect(() => { return () => cancel(cancelSource.current) }, []);

  useEffect(() => {
    if (clientId) {
      scopeById(clientId);
      setShow(true);
    }
  }, [refresh, clientId, scopeById]);

  const handleReload = (val, client) => {
    setReload(val);
    handleLoadEscope(client);
  };

  const handleRefreshError = () => {
    setReload(uniqid());
    setSent(false);
    handleLoadEscope();
  };

  useEffect(() => {
    handleLoadEscope();
    // eslint-disable-next-line
  }, [reload, props.match.params, show]);

  useEffect(() => {
    if (confirm === FULFILLED || confirm === REJECTED) {
      setLoading(false);
      setSent(true);
    }
    if (confirm === REQUESTING) setLoading(true);
  }, [confirm, props.scope]);

  useEffect(() => {
    if (controlLoading === DEFAULT && (status === FULFILLED || status === REJECTED)) {
      setLoading(false);
      setControlLoading(status);
    }
    if (status === REJECTED) {
      setNotFound(true);
    } else setNotFound(false);
    console.log(show, reload, notFound);
    // eslint-disable-next-line
  }, [status]);

  if (loading && Boolean(clientId)) return <Loading />;

  if (sent) {
    return (
      <Root centered setTitle={`${confirm === FULFILLED ? 'Sucesso!' : 'Erro :('} - ${SALE_FORM}`} paddingTop>
        <Container
          maxWidth="md"
          style={{
            paddingBottom: 1,
          }}
        >
          <Typography
            variant="h5"
            color="textSecondary"
            align="center"
            paragraph
          >
            {confirm === FULFILLED ? 'As informações foram enviadas com sucesso!' : 'Erro, por favor tente novamente'}
          </Typography>
          <Countdown timer={10} pushFunction={props.history.push} onEnd={confirm === FULFILLED ? '/venda' : () => { handleRefreshError(); }} />
        </Container>
      </Root>
    );
  }

  return (
    <Root setTitle={SALE_FORM} header paddingTop minWidth={960}>
      <SalesProvider>
        <SalesContext.Consumer>
          {({ currentStep }) => (
            <>
              {show && reload && !notFound ? (
                <>
                  <SaleFormHeader scope={props.scope} />
                  {currentStep === 0 && <SaleFormStepOne />}
                  {currentStep === 1 && <SaleFormStepTwo />}
                  {currentStep === 2 && <SaleFormStepThree />}
                  {currentStep === 3 && <SaleFormStepFour />}
                  {currentStep === 4 && <SaleFormStepFive />}
                  <FormProgress />
                </>
              ) : (
                  <ClientNotFound
                    setReload={handleReload}
                    currentStep={currentStep}
                    {...props}
                  />
                )}
            </>
          )}
        </SalesContext.Consumer>
      </SalesProvider>
      <div style={{ paddingBottom: 80 }} />
    </Root>
  );
}

const mapStateToProps = (state) => ({
  scope: state.sales.scope,
  status: state.sales.scopeStatus,
  confirm: state.sales.confirm,
});

export default connect(mapStateToProps, { scopeById: fetchSaleScopeByClientd })(SalesForm);
