import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  UPSIDE_DOWN_EMOJI,
  THUMBS_EMOJI,
  SAD_EMOJI,
  CONFUSED_EMOJI,
  DOWNCAST_EMOJI,
  THINKING_EMOJI,
} from './emojis';

const TYPE_ERROR_API = 'TYPE_ERROR_API';
const TYPE_ERROR_USER = 'TYPE_ERROR_USER';
const TYPE_SUCCESS = 'TYPE_SUCCESS';
const TYPE_REMOVE = 'TYPE_REMOVE';
const TYPE_CANT = 'TYPE_CANT';
const TYPE_WARNING = 'TYPE_WARNING';

function EmojiIncluded({ emojiType, children }) {
  const getEmoji = () => {
    switch (emojiType) {
      case TYPE_ERROR_USER:
        return SAD_EMOJI;
      case TYPE_SUCCESS:
        return THUMBS_EMOJI;
      case TYPE_REMOVE:
        return UPSIDE_DOWN_EMOJI;
      case TYPE_CANT:
        return DOWNCAST_EMOJI;
      case TYPE_WARNING:
        return THINKING_EMOJI;
      default:
        return CONFUSED_EMOJI;
    }
  };

  return (
    <>
      <Typography
        variant="h6"
        component="span"
        style={{ marginRight: 8 }}
      >
        {getEmoji()}
      </Typography>
      <Typography
        component="span"
      >
        {children}
      </Typography>
    </>
  );
}

EmojiIncluded.proTypes = {
  emojiType: PropTypes.string,
  children: PropTypes.string.isRequired,
};

EmojiIncluded.defaultProps = {
  emojiType: TYPE_ERROR_API,
};

export const MESSAGE_COPIED = (
  <EmojiIncluded emojiType={TYPE_SUCCESS}>
    {'Copiado para a área de transferência'}
  </EmojiIncluded>
);
export const MESSAGE_NO_SALES_FOUND = (name) => (
  <EmojiIncluded emojiType={TYPE_WARNING}>
    {`Não foram encontradas vendas relacionadas a ${name}`}
  </EmojiIncluded>
);
export const MESSAGE_ERROR_SESSION_EXPIRED = (
  <EmojiIncluded emojiType={TYPE_ERROR_API}>
    {'Sua sessão expirou. Por favor, faça login novamente'}
  </EmojiIncluded>
);
export const MESSAGE_ERROR_COMMON_ERROR = (
  <EmojiIncluded emojiType={TYPE_ERROR_API}>
    {'Ocorreu um erro nessa ação. Por favor tente novamente'}
  </EmojiIncluded>
);
export const MESSAGE_SUCCESS_COMMON_UPDATE = (
  <EmojiIncluded emojiType={TYPE_SUCCESS}>
    {'Informações atualizadas com sucesso'}
  </EmojiIncluded>
);
export const MESSAGE_SUCCESS_COMMON_CREATE = (
  <EmojiIncluded emojiType={TYPE_SUCCESS}>
    {'Informações enviadas com sucesso'}
  </EmojiIncluded>
);
export const MESSAGE_SUCCESS_SENT_FILE = (
  <EmojiIncluded emojiType={TYPE_SUCCESS}>
    {'Arquivo enviado com sucesso'}
  </EmojiIncluded>
);
export const MESSAGE_SUCCESS_REMOVE_FILE = (
  <EmojiIncluded emojiType={TYPE_REMOVE}>
    {'Arquivo removido com sucesso'}
  </EmojiIncluded>
);
export const MESSAGE_ERROR_CLIENT_NOT_FOUND = (
  <EmojiIncluded emojiType={TYPE_ERROR_API}>
    {'Cliente não foi encontrado. Por favor tente novamente'}
  </EmojiIncluded>
);
export const MESSAGE_FILL_ALL_FIELDS = (
  <EmojiIncluded emojiType={TYPE_ERROR_USER}>
    {'Preencha todos os campos'}
  </EmojiIncluded>
);
export const MESSAGE_NO_PROCESSES = (
  <EmojiIncluded emojiType={TYPE_ERROR_USER}>
    {'O pacote de envio está vazio'}
  </EmojiIncluded>
);

// PRODUCTS

export const MESSAGE_SUCCESS_REMOVE_PRODUCT = (
  <EmojiIncluded emojiType={TYPE_REMOVE}>
    {'Produto removido com sucesso'}
  </EmojiIncluded>
);

// REQUIREMENTS

export const MESSAGE_SUCCESS_REMOVE_REQUIREMENT = (
  <EmojiIncluded emojiType={TYPE_REMOVE}>
    {'Documentação removida com sucesso'}
  </EmojiIncluded>
);

// PRICES

export const MESSAGE_SUCCESS_REMOVE_PRICE = (
  <EmojiIncluded emojiType={TYPE_REMOVE}>
    {'Valor removido com sucesso'}
  </EmojiIncluded>
);

// SPECIFIC ERRORS
export const MESSAGE_ERROR_SMTP = (
  <EmojiIncluded emojiType={TYPE_ERROR_API}>
    {'Houve um erro na comunicação com o servidor de e-mail'}
  </EmojiIncluded>
);

// COMMENTS
export const POST_COMMENT_SUCCESS = (
  <EmojiIncluded emojiType={TYPE_SUCCESS}>
    {'Comentário adicionado com sucesso'}
  </EmojiIncluded>
);
export const ERASE_COMMENT_SUCCESS = (
  <EmojiIncluded emojiType={TYPE_REMOVE}>
    {'Comentário removido com sucesso'}
  </EmojiIncluded>
);

// SESSION
export const MESSAGE_SUCCESS_ACCOUNT_UPDATED = (
  <EmojiIncluded emojiType={TYPE_SUCCESS}>
    {'Conta atualizada com sucesso'}
  </EmojiIncluded>
);
export const MESSAGE_WRONG_PASSWORD = (
  <EmojiIncluded emojiType={TYPE_ERROR_USER}>
    {'E-mail e/ou senha estão incorretos'}
  </EmojiIncluded>
);
export const MESSAGE_AUTH_ERROR = (
  <EmojiIncluded emojiType={TYPE_ERROR_USER}>
    {'Erro de autenticação'}
  </EmojiIncluded>
);

// SERVICE
export const MESSAGE_ALIGN_SUCCESS = (
  <EmojiIncluded emojiType={TYPE_SUCCESS}>
    {'Alinhamento confirmado com sucesso'}
  </EmojiIncluded>
);
export const MESSAGE_ALIGN_MULTIPLE_SUCCESS = (
  <EmojiIncluded emojiType={TYPE_SUCCESS}>
    {'Alinhamentos confirmados com sucesso'}
  </EmojiIncluded>
);
export const MESSAGE_CONFIRM_DOCUMENTS = (
  <EmojiIncluded emojiType={TYPE_SUCCESS}>
    {'Documento(s) confirmado(s) com sucesso'}
  </EmojiIncluded>
);

// SALES
export const MESSAGE_SUCCESS_CREATE_PROCESS = (
  <EmojiIncluded emojiType={TYPE_SUCCESS}>
    {'Processo criado com sucesso'}
  </EmojiIncluded>
);
export const MESSAGE_SUCCESS_CONFIRM_SCOPE = (
  <EmojiIncluded emojiType={TYPE_SUCCESS}>
    {'Contrato criado com sucesso'}
  </EmojiIncluded>
);
export const MESSAGE_SUCCESS_REMOVE_PROCESS = (
  <EmojiIncluded emojiType={TYPE_REMOVE}>
    {'Processo removido com sucesso'}
  </EmojiIncluded>
);
export const MESSAGE_SUCCESS_REMOVE_PERSON = (
  <EmojiIncluded emojiType={TYPE_REMOVE}>
    {'Pessoa removida do escopo com sucesso'}
  </EmojiIncluded>
);
export const MESSAGE_SUCCESS_ADDED_PAYMENT = (
  <EmojiIncluded emojiType={TYPE_SUCCESS}>
    {'Pagamento adicionado com sucesso'}
  </EmojiIncluded>
);
export const MESSAGE_SUCCESS_REMOVE_PAYMENT = (
  <EmojiIncluded emojiType={TYPE_REMOVE}>
    {'Pagamento removido com sucesso'}
  </EmojiIncluded>
);
export const MESSAGE_SUCCESS_CONFIRM_SALE = (
  <EmojiIncluded emojiType={TYPE_SUCCESS}>
    {'Venda confirmada com sucesso!'}
  </EmojiIncluded>
);
export const MESSAGE_ERROR_ADD_PAYMENT = (
  <EmojiIncluded emojiType={TYPE_ERROR_USER}>
    {'Por favor, selecione o comprovante de pagamento'}
  </EmojiIncluded>
);
export const MESSAGE_ALERT_CANT_REMOVE_PROCESS = (
  <EmojiIncluded emojiType={TYPE_CANT}>
    {'Existem outros processos vinculados a esse, não é possivel remover'}
  </EmojiIncluded>
);
export const MESSAGE_ALERT_CANT_REMOVE_PERSON = (
  <EmojiIncluded emojiType={TYPE_CANT}>
    {'Existem processos vinculados ao português, não é possivel remover'}
  </EmojiIncluded>
);
export const MESSAGE_SUCCESS_PAYMENT_CONFIRMED = (
  <EmojiIncluded emojiType={TYPE_SUCCESS}>
    {'Pagamento confirmado com sucesso'}
  </EmojiIncluded>
);

/* EMAILS */

export const MESSAGE_SUCCESS_EMAIL = (
  <EmojiIncluded emojiType={TYPE_SUCCESS}>
    {'E-mail enviado com sucesso!'}
  </EmojiIncluded>
);

export const MESSAGE_ERROR_EMAIL = (
  <EmojiIncluded emojiType={TYPE_ERROR_API}>
    {'Não foi possível enviar o e-mail'}
  </EmojiIncluded>
);

/* CONTRACT */

export const MESSAGE_SUCCESS_CONTRACT_UPDATE = (
  <EmojiIncluded emojiType={TYPE_SUCCESS}>
    {'Contrato alterado com sucesso!'}
  </EmojiIncluded>
);

export const MESSAGE_ERROR_CONTRACT_UPDATE = (
  <EmojiIncluded emojiType={TYPE_ERROR_API}>
    {'Não foi possível alterar o conteúdo do contrato'}
  </EmojiIncluded>
);
