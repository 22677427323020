import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { ServiceContext } from 'contexts/service';
import clsx from 'clsx';
import {
  Close as CloseIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import {
  Grid,
  Typography,
  Tooltip,
  Button,
  IconButton,
  Icon,
  InputBase,
  Paper,
  Container,
} from '@material-ui/core';
import { confirmAligmentMultiple } from 'store/actions/sales';
import { processSearch } from 'store/actions/processes';
import { showConfirmation } from 'store/actions/confirmations';
import { useStyles } from '../style';

function Header({ responseSize, filteredSize, pageTitle, blockSearch, singleResult, ...props }) {
  const classes = useStyles(props);
  const serviceContext = useContext(ServiceContext);
  const {
    setSearchValue, searchValue, setSortItems, sortItems,
  } = serviceContext;

  const handleChange = (e, variant) => {
    let newValue = searchValue;
    let newSort = sortItems; 
    switch (variant) {
      case 'search':
        newValue = e.target.value;
        setSearchValue(e.target.value);
        break;
  
      default:
        newSort = !newSort;
        setSortItems();
        break;
    }

    props.processSearch(newValue, false, 'align_date', newSort);
  }

  const clearSearch = () => setSearchValue('');
  const filtered = filteredSize !== responseSize;
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={2}
        className={classes.pageHeader}
      >
        <Grid item xs={6}>
          <Typography
            variant="h5"
            color="textSecondary"
            className={classes.pageTitle}
          >
            {`${pageTitle} (${filtered ? `${filteredSize} de ` : ''}${responseSize})`}
          </Typography>

        </Grid>
        <Grid item xs={6} className={classes.btnholder}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Buscar..."
              onChange={(e) => handleChange(e, 'search')}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              value={searchValue}
              endAdornment={(
                <IconButton onClick={() => clearSearch()} classes={{ root: classes.closeBtnTiny }} style={{ opacity: Boolean(searchValue) ? 1 : 0 }} size="small">
                  <CloseIcon fontSize="small" classes={{ root: classes.closeBtnTinyIcon }} />
                </IconButton>
              )}
            />
          </div>
          <Tooltip title="Ordenar por data de alinhamento">
            <Button
              variant="contained"
              onClick={(e) => handleChange(e, 'sort')}
              className={classes.buttonGroup}
              aria-label="Ascendente ou decrescente"
            >
              {'Ordenar'}
              <Icon style={{ fontSize: '1.2rem' }} className={clsx('fas', sortItems ? 'fa-sort-numeric-up' : 'fa-sort-numeric-down', classes.iconMargin)} />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      {filteredSize > 0 && (
        <Paper className={clsx(classes.header, singleResult ? 'default' : 'apostilamento')}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="body2">
                {'Requerente'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography variant="body2">
                    {'Tipo de processo'}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" align="center">
                    {'Data de envio'}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" align="center">
                    {'Data de chegada'}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">
                    {'Documentação'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} />
          </Grid>
        </Paper>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  blockSearch: state.processes.blockSearch,
})

export default connect(mapStateToProps, { processSearch, confirmAligmentMultiple, showConfirmation })(Header);
