import * as definitions from 'store/definitions/product';
import { DEFAULT, FULFILLED, REQUESTING, REJECTED } from 'constants/common';

function Product(state = {
  collection: [],
  allProducts: [],
  status: DEFAULT,
  priceStatus: DEFAULT,
  requirementStatus: DEFAULT,
  searchStatus: DEFAULT,
  fetchStatus: DEFAULT,
}, action) {
  switch (action.type) {
    case definitions.ADD_PRICE_TO_PRODUCT:
    case definitions.UPDATE_PRICE:
    case definitions.BATCH_UPDATE_PRICE:
    case definitions.REMOVE_PRICE:
      return {
        ...state,
        priceStatus: REQUESTING,
      };
    case definitions.ADD_PRICE_TO_PRODUCT_REJECTED:
    case definitions.UPDATE_PRICE_REJECTED:
    case definitions.BATCH_UPDATE_PRICE_REJECTED:
    case definitions.REMOVE_PRICE_REJECTED:
      return {
        ...state,
        priceStatus: REJECTED,
      };
    case definitions.ADD_PRICE_TO_PRODUCT_FULFILLED:
    case definitions.UPDATE_PRICE_FULFILLED:
    case definitions.REMOVE_PRICE_FULFILLED:
    case definitions.BATCH_UPDATE_PRICE_FULFILLED:
      return {
        ...state,
        priceStatus: FULFILLED,
      };
    case definitions.ADD_REQUIREMENT_TO_PRODUCT:
    case definitions.UPDATE_REQUIREMENT:
    case definitions.REMOVE_REQUIREMENT:
      return {
        ...state,
        requirementStatus: REQUESTING,
      };
    case definitions.ADD_REQUIREMENT_TO_PRODUCT_REJECTED:
    case definitions.UPDATE_REQUIREMENT_REJECTED:
    case definitions.REMOVE_REQUIREMENT_REJECTED:
      return {
        ...state,
        requirementStatus: REJECTED,
      };
    case definitions.ADD_REQUIREMENT_TO_PRODUCT_FULFILLED:
    case definitions.UPDATE_REQUIREMENT_FULFILLED:
    case definitions.REMOVE_REQUIREMENT_FULFILLED:
      return {
        ...state,
        requirementStatus: FULFILLED,
      };
    case definitions.FETCH_ALL_PRODUCTS:
      return {
        ...state,
        fetchStatus: REQUESTING,
      };
    case definitions.CREATE_PRODUCT:
    case definitions.UPDATE_PRODUCT:
    case definitions.REMOVE_PRODUCT:
      return {
        ...state,
        status: REQUESTING,
      };
    case definitions.FETCH_ALL_PRODUCTS_REJECTED:
      return {
        ...state,
        fetchStatus: REJECTED,
      };
    case definitions.CREATE_PRODUCT_REJECTED:
    case definitions.UPDATE_PRODUCT_REJECTED:
    case definitions.REMOVE_PRODUCT_REJECTED:
      return {
        ...state,
        status: REJECTED,
      };
    case definitions.CREATE_PRODUCT_FULFILLED:
    case definitions.UPDATE_PRODUCT_FULFILLED:
    case definitions.REMOVE_PRODUCT_FULFILLED:
      return {
        ...state,
        status: FULFILLED,
      };
    case definitions.FETCH_ALL_PRODUCTS_FULFILLED:
      return {
        ...state,
        fetchStatus: FULFILLED,
        collection: action.payload,
        allProducts: action.payload,
      };
    case definitions.PRODUCT_SEARCH:
      return {
        ...state,
        searchStatus: REQUESTING,
      };
    case definitions.PRODUCT_SEARCH_RESULT:
      return {
        ...state,
        searchStatus: FULFILLED,
        collection: action.payload,
      };
    default:
      return state;
  }
}

export default Product;
