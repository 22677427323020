import * as definitions from 'store/definitions/contract';

export const fetchContract = (id) => ({
  type: definitions.FETCH_CONTRACT,
  payload: { id },
});

export const signOnlineContract = (id) => ({
  type: definitions.SIGN_CONTRACT,
  payload: { id },
});

export const getContractContent = (id) => ({
  type: definitions.FETCH_CONTRACT_CONTENT,
  payload: { id },
});

export const changeContractContent = (id, content) => ({
  type: definitions.UPDATE_CONTRACT_CONTENT,
  payload: { id, content },
});
