// @ts-nocheck
import React, { useEffect, useContext, useState } from 'react';
import { ServiceContext } from 'contexts/service';
import uniqid from 'uniqid';
import PropTypes from 'prop-types';
import {
  DndProvider,
} from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { sendDocumentationEmail } from 'store/actions/sales';
import { fetchProcessesBySteps, processSearch, clearResponses } from 'store/actions/processes';
import { STEP_POST } from 'constants/process';
import { FULFILLED } from 'constants/session';
import { LoadingComponent } from 'global/modules/Loading';
import { fetchOpenBatchPost, createBatchPost, updateBatchPost } from 'store/actions/batchpost';
import { showNotification } from 'store/actions/notifications';
import { MESSAGE_ERROR_COMMON_ERROR } from 'constants/common/messages';
import { NOTIFICATION_TYPE_ERROR } from 'constants/notification';
import ProcessRow from './ProcessRow';
import ColumnProcess from './ColumnProcess';
import ColumnLot from './ColumnLot';
import ProcessHeader from './ProcessHeader';
import LotHeader from './LotHeader';
import LotRow from './LotRow';

function Post({
  envioReq,
  status,
  allProcessesReq,
  settingsStatus,
  update,
  setUpdate,
  openBatchpostsReq,
  batchpostStatus,
  batchpostActionStatus,
  notification,
  isIn,
  ...props
}) {
  const serviceContext = useContext(ServiceContext);
  const {
    multipleSelect,
    clearMultipleSelect,
  } = serviceContext;
  const [envio, setEnvio] = useState(envioReq.length ? [...envioReq] : []);
  const [openBatchposts, setOpenBatchposts] = useState(openBatchpostsReq.length
    ? [...openBatchpostsReq]
    : []);
  const [requesting, setRequesting] = useState(false);
  const [requestingLot, setRequestingLot] = useState(false);
  let processesInLots = [];
  openBatchposts.forEach((l) => {
    const ids = l.processes.map((p) => p.id);
    processesInLots = [...processesInLots, ...ids];
  });
  const filteredEnvio = envio.filter((p) => processesInLots.indexOf(p.id) === -1 && !p.post_date);

  const showError = () => {
    notification(NOTIFICATION_TYPE_ERROR, MESSAGE_ERROR_COMMON_ERROR);
  };

  const handleDropProcess = (process, lotId) => {
    const isMultiple = multipleSelect.length > 0;
    if (!isMultiple && (!process || !process.id)) {
      showError();
      return;
    }
    const lotsToAdd = isMultiple
      ? multipleSelect
      : [process.id];
    if (lotId) {
      const findOpenLot = openBatchposts.find((l) => l.id === parseInt(lotId, 10));
      if (findOpenLot) {
        const allProcessesI = [...findOpenLot.processes.map((a) => a.id), ...lotsToAdd];
        props.updateBatchPost(lotId, { processes: allProcessesI });
      } else {
        showError();
        return;
      }
    } else {
      props.createBatchPost({ processes: lotsToAdd });
    }
    if (isMultiple) clearMultipleSelect();
  };

  const openLotNames = {};
  openBatchposts.forEach((lot) => {
    openLotNames[lot.id] = lot.tracking_code || `Pacote ${lot.id}`;
  });

  useEffect(() => {
    if (batchpostActionStatus === FULFILLED) setUpdate(uniqid());
  }, [batchpostActionStatus, setUpdate]);

  useEffect(() => {
    if (status === FULFILLED && requesting) {
      setRequesting(false);
    }
    setEnvio([...envioReq]);
  }, [status, envioReq, requesting]);

  useEffect(() => {
    if (batchpostStatus === FULFILLED && requestingLot) {
      setRequestingLot(false);
    }
    setOpenBatchposts([...openBatchpostsReq]);
  }, [batchpostStatus, openBatchpostsReq, requestingLot]);

  useEffect(() => {
    if (!requesting && isIn) {
      setRequesting(true);
      setRequestingLot(true);
      props.fetchProcessesBySteps([STEP_POST]);
      props.fetchOpenBatchPost();
    }
    // eslint-disable-next-line
  }, [update, isIn]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ColumnProcess
            showResults={filteredEnvio.length > 0}
            fulfilled={status === FULFILLED}
            head={<ProcessHeader items={filteredEnvio} />}
          >
            {filteredEnvio.map((process, index) => (
              <ProcessRow
                index={index}
                key={`process${process.id}`}
                handleDropProcess={handleDropProcess}
                process={process}
              />
            ))}
          </ColumnProcess>
          {status !== FULFILLED && filteredEnvio.length === 0 && (
            <LoadingComponent />
          )}
        </Grid>
        <Grid item xs={6}>
          <ColumnLot
            side="lots"
            fulfilled={batchpostStatus === FULFILLED}
            showResults={openBatchposts.length > 0}
            head={(
              <LotHeader
                handleDropProcess={handleDropProcess}
                lots={openBatchposts}
                lotNames={openLotNames}
              />
            )}
          >
            {openBatchposts.map((batchpost, index) => (
              <LotRow
                key={`batchpost${batchpost.id}`}
                lotNames={openLotNames}
                lot={batchpost}
                index={index}
              />
            ))}
          </ColumnLot>
          {batchpostStatus !== FULFILLED && openBatchposts.length === 0 && (
            <LoadingComponent />
          )}
        </Grid>
      </Grid>
    </DndProvider>
  );
}

Post.propTypes = {
  envioReq: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  allProcessesReq: PropTypes.array.isRequired,
  settingsStatus: PropTypes.string.isRequired,
  update: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]).isRequired,
  setUpdate: PropTypes.func.isRequired,
  openBatchpostsReq: PropTypes.array.isRequired,
  batchpostStatus: PropTypes.string.isRequired,
  batchpostActionStatus: PropTypes.string.isRequired,
  notification: PropTypes.func.isRequired,
  isIn: PropTypes.bool.isRequired,
};

const mapToStateToProps = (state) => ({
  envioReq: state.processes.collection,
  openBatchpostsReq: state.batchpost.openBatchPosts,
  allProcessesReq: state.processes.allProcesses,
  status: state.processes.status,
  batchpostStatus: state.batchpost.status,
  batchpostActionStatus: state.batchpost.actionStatus,
  settingsStatus: state.processes.settingsStatus,
});

const actions = {
  fetchProcessesBySteps,
  sendDocumentationEmail,
  processSearch,
  clearResponses,
  fetchOpenBatchPost,
  createBatchPost,
  updateBatchPost,
  notification: showNotification,
};

export default connect(mapToStateToProps, actions)(Post);
