/* eslint-disable quote-props */
export const STEP_CONCLUSION = 'conclusion';
export const STEP_DOCSOK = 'docs_ok';
export const STEP_CHECKING = 'checking';
export const STEP_POST = 'post';
export const STEP_HAGUE = 'hague';
export const STEP_ANALYSIS = 'analysis';
export const STEP_ALIGNMENT = 'alignment';
export const STEP_DOCUMENTATION = 'documentation';
export const STEP_MONITORING = 'monitoring';
export const STEP_SALES = 'new';

export const steps = (step) => {
  switch (step) {
    case STEP_SALES:
      return {
        title: 'Venda',
        icon: 'fas fa-shopping-cart',
        index: -1,
      };
    case STEP_CHECKING:
      return {
        title: 'Serviço - Verificação',
        icon: 'fas fa-book',
        index: 0,
      };
    case STEP_ALIGNMENT:
      return {
        title: 'Serviço - Alinhamento',
        icon: 'fas fa-phone',
        index: 1,
      };
    case STEP_DOCUMENTATION:
      return {
        title: 'Serviço - Documentação',
        icon: 'fas fa-id-card',
        index: 2,
      };
    case STEP_DOCSOK:
      return {
        title: 'Serviço - Docs ok',
        icon: 'fas fa-clipboard-check',
        index: 3,
      };
    case STEP_HAGUE:
      return {
        title: 'Serviço - Apostilamento',
        icon: 'fas fa-passport',
        index: 4,
      };
    case STEP_ANALYSIS:
      return {
        title: 'Serviço - Análise',
        icon: 'fas fa-folder',
        index: 5,
      };
    case STEP_POST:
      return {
        title: 'Serviço - Envio',
        icon: 'fas fa-paper-plane',
        index: 6,
      };
    case STEP_MONITORING:
      return {
        title: 'Serviço - Acompanhamento',
        icon: 'fas fa-folder-open',
        index: 7,
      };
    case STEP_CONCLUSION:
      return {
        title: 'Conclusão',
        icon: 'fas fa-award',
        index: 8,
      };
    default:
      return {
        title: '',
        icon: 'fas fa-comment',
        index: null,
      };
  }
};

export const optionsFunc = {
  'Quem é o português?': {
    options: ['Pai', 'Mãe', 'Ambos'],
    required: true,
  },
  'Quem declarou o nascimento do requerente?': {
    options: ['Pai', 'Mãe', 'Terceiro'],
    required: true,
  },
  'Os pais do requerente eram casados quando ele nasceu?': {
    options: ['Sim', 'Não', 'Não posso afirmar'],
    required: true,
  },
  'Em Portugal?': {
    options: ['Sim', 'Não'],
    required: false,
    dependant: true,
  },
  'Tem transcrição de casamento incluída?': {
    options: ['Sim', 'Não'],
    required: true,
  },
  'PT': {
    options: '',
    required: false,
    dependant: true,
  },
  '** Pai reconheceu a paternidade antes dos 18 anos do requerente?': {
    options: ['Sim', 'Não'],
    required: true,
  },
  '** Nasceu após 1978 e foi declarado até 1 ano de idade?': {
    options: ['Sim', 'Não'],
    required: true,
  },
  'Existe alguma divergência entre as certidões?': {
    options: ['Sim', 'Não', 'Não posso afirmar'],
    required: true,
  },
  'Quais divergências?': {
    options: '',
    required: false,
    dependant: true,
  },
  'Observações': {
    options: '',
    required: false,
  },
};
