import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  pLine: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  pageTitle: {
    lineHeight: `${theme.spacing(5)}px`,
  },
  btnholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 1),
    '&:hover': {
      backgroundColor: fade('#000000', 0.08),
    },
    width: 'auto',
    display: 'inline-flex',
    boxShadow: theme.shadows[3],
    height: theme.spacing(5),
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: 90,
    '&:focus': {
      width: 280,
    },
  },
  buttonGroup: {
    '&:not(.toggle)': {
      padding: theme.spacing(1, 2),
    },
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(5),
    border: 'none',
    boxShadow: theme.shadows[3],
    marginLeft: `${theme.spacing(2)}px !important`,
    minWidth: 110,
  },
  toggleButton: {
    padding: theme.spacing(0, 2),
    height: 'auto',
    borderWidth: 0,
    overflow: 'hidden',
  },
  toggleButtonSelected: {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  closeBtnTiny: {
    marginRight: theme.spacing(0.5),
  },
  closeBtnTinyIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  iconMargin: {
    marginLeft: theme.spacing(1),
  },
  gridMiddleAlign: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  buttonEdit: {
    position: 'absolute',
    right: theme.spacing(1.5),
    top: '50%',
    height: 45,
    width: 45,
    transform: 'translate(0%, -50%)',
    margin: theme.spacing(0, -3, 0),
    '&.Mui-expanded': {
      transform: 'translate(0%, -50%) rotate(180deg)',
    },
  },
  header: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'relative',
    '&.editable': {
      padding: theme.spacing(2, 8, 2, 2),
    },
  },
  bodyRoot: {
    padding: '0px !important',
    minHeight: '40px !important',
  },
  bodyContainer: {
    padding: theme.spacing(2),
    position: 'relative',
    minHeight: theme.spacing(4),
    '&.editable': {
      padding: 0,
      width: '100%',
    },
  },
  bodyWrapper: {
    margin: `${theme.spacing(2)}px 0 0 auto !important`,
    padding: theme.spacing(2, 3),
    transition: theme.transitions.create('all'),
    backgroundColor: 'rgba(255, 255, 255, 1)',
    '&.header': {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      boxShadow: 'none !important',
      overflow: 'hidden',
      marginTop: '0 !important',
    },
  },
  contentWrapper: {
    padding: theme.spacing(2, 0, 0, 0),
  },
  noMargin: {
    margin: '0 !important',
  },
  actionButton: {
    margin: 0,
    marginTop: theme.spacing(1),
    '&.submit': {
      marginLeft: theme.spacing(2),
    },
  },
  addUserButton: {
    cursor: 'pointer',
    backgroundColor: `${theme.palette.primary.main} !important`,
    width: 40,
    height: 40,
    minWidth: 40,
    minHeight: 40,
  },
  addUserIcon: {
    color: theme.palette.common.white,
  },
}));
