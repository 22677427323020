import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  bool, object, string, number, oneOfType, node,
} from 'prop-types';
import * as pageTitles from 'constants/common/pageTitles';
import Header from 'global/Header';
import Footer from 'global/Footer';
import { UserContext } from 'contexts/user';
import { CUSTOMER } from 'constants/user';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: (props) => (props.footer ? 260 : 0),
    paddingTop: (props) => (
      theme.spacing(props.offsetTop) + (props.paddingTop ? theme.spacing(4) : 0)
    ),
    backgroundColor: '#edeff3',
    minHeight: (props) => `calc(100vh - ${props.footer ? 260 : 0}px)`,
    display: 'flex',
    flexDirection: 'column',
    minWidth: (props) => props.minWidth,
    justifyContent: (props) => (props.centered ? 'center' : 'flex-start'),
    '&.noFooter': {
      paddingBottom: '20px !important',
      minHeight: '100vh',
    },
  },
  wrapper: {
    minHeight: '100vh',
  },
}));

function Root({
  header,
  footer,
  children,
  styles,
  paddingTop,
  setTitle,
  centered,
  HeaderProps,
  className,
  minWidth,
  ...other
}) {
  const title = 'Cidadania Já';

  const stylesParams = {
    footer,
    paddingTop,
    centered,
    offsetTop: header ? 8 : 0,
    minWidth,
    addStyle: styles ? { ...styles } : {},
  };

  const classes = useStyles({ ...stylesParams });
  const classNames = className
    ? clsx(classes.root, className)
    : classes.root;
  const { user } = useContext(UserContext);

  const getPageTitle = () => {
    let page = '';
    switch (document.location.href.split('/')[3]) {
      case 'venda':
        page = pageTitles.SALES;
        break;
      case 'servico':
        page = pageTitles.SERVICE;
        break;
      case 'financeiro':
        page = pageTitles.FINANCE;
        break;
      case 'atendimento':
        page = pageTitles.CUSTOMER_SERVICE;
        break;
      default:
        page = pageTitles.CLIENT;
        break;
    }
    return `${setTitle || page} - ${title}`;
  };

  document.title = getPageTitle();

  const showFooter = footer && user && user.userType === CUSTOMER;

  return (
    <div className={classes.wrapper}>
      {header && <Header {...HeaderProps} />}
      <div id="#root" className={clsx(classNames, !showFooter ? 'noFooter' : 'regular')} {...other}>
        {children}
        {!(footer && user) && <div style={{ paddingBottom: 5 }} />}
      </div>
      {showFooter && <Footer />}
    </div>
  );
}

Root.propTypes = {
  header: bool,
  footer: bool,
  styles: object,
  paddingTop: bool,
  setTitle: string,
  centered: bool,
  children: oneOfType([string, node]),
  minWidth: number,
  HeaderProps: object,
};

Root.defaultProps = {
  header: false,
  footer: false,
  styles: {},
  paddingTop: false,
  setTitle: '',
  centered: false,
  children: <></>,
  minWidth: 0,
  HeaderProps: {},
};

export default Root;
