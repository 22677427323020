import { put, takeLatest, all, call } from 'redux-saga/effects';
import * as definitions from 'store/definitions/people';
import * as API from 'api';

function* fetchPortugueses(action) {
    try {
        const { page, rowsPerPage, orderBy, order, search, checked, searchStatus, documentationStatus, customerStatus } = action.payload;
        const filter = { page, size: rowsPerPage, s: search, orderBy, order, checked, searchStatus, documentationStatus, customerStatus };
        const payload = yield call(API.fetchPaged, '/people', filter);
        yield put({
          type: definitions.FETCH_PORTUGUESES_FULFILLED,
          payload: payload,
        });
      } catch (error) {
        yield put({ type: definitions.FETCH_PORTUGUESES_REJECTED, payload: { error } });
      }
}

export default function* rootSaga() {
    yield all([
      takeLatest(definitions.FETCH_PORTUGUESES, fetchPortugueses),
    ]);
}