import {
  FREGUESIA_FORM_CERTIFICATE_TYPE,
  CONSULATE_FORM_CERTIFICATE_TYPE,
  UNDEFINED_FORM_CERTIFICATE_TYPE,
} from 'constants/sales';
// import moment from 'moment';

export const validateForm = (form) => {
  if (form === null || typeof form === 'undefined' || form === {} || form === '') {
    return false;
  }
  if (form.name === '' || form.certificate === '') return false;

  if (form.certificate_type === FREGUESIA_FORM_CERTIFICATE_TYPE) {
    if (form.document_number === '' && form.birthplace === '' && form.assento_year === '' && form.crc === '') return false;
  }

  if (form.certificate_type === CONSULATE_FORM_CERTIFICATE_TYPE) {
    if (form.document_number === '' && form.birthplace === '' && form.consulate === '' && form.assento_year === '') return false;
  }

  if (form.certificate === UNDEFINED_FORM_CERTIFICATE_TYPE) {
    if (
      form.birthdate === ''
      || form.conselho === ''
      || form.freguesia === ''
      || form.father_name === ''
      || form.mother_name === ''
    ) {
      return false;
    }
  }

  return true;
};

export const prepareToSubmit = (form) => ({
  birthdate: form.birthdate,
  certificate_id: form.certificate_id,
  assento_year: form.assento_year,
  crc: form.crc,
  father_name: form.father_name,
  mother_name: form.mother_name,
  name: form.name,
  consulate: form.consulate,
  document_number: form.document_number,
  birthplace: form.birthplace,
  docs: form.docs,
  second_sale: form.second_sale,
  freguesia_id: form.freguesia_id,
  concelho_id: form.concelho_id
});
