import {
  put, takeLatest, all, call,
} from 'redux-saga/effects';
import * as definitions from 'store/definitions/contract';
import { SCOPE_PATH } from 'api/paths';
import * as API from 'api';
import { MESSAGE_SUCCESS_CONTRACT_UPDATE, MESSAGE_ERROR_CONTRACT_UPDATE } from 'constants/common/messages';
import { NOTIFICATION_TYPE_SUCCESS, NOTIFICATION_TYPE_ERROR } from 'constants/notification';
import { SHOW } from 'store/definitions/notifications';

export function* signContract(action) {
  try {
    const { id } = action.payload;
    const fullPath = `${SCOPE_PATH}`;
    const payload = yield call(API.updateAction, fullPath, id, 'sign', {});
    yield put({
      type: definitions.SIGN_CONTRACT_FULFILLED,
      payload,
    });
  } catch (error) {
    yield put({
      type: definitions.SIGN_CONTRACT_REJECTED,
      payload: { error },
    });
  }
}

export function* fetchContract(action) {
  try {
    const { id } = action.payload;
    const fullPath = `${SCOPE_PATH}/${id}/contract-content`;
    const secondPath = `${SCOPE_PATH}/${id}`;
    const payload = yield call(API.fetch, fullPath);
    const secondPayload = yield call(API.fetch, secondPath);
    yield put({
      type: definitions.FETCH_CONTRACT_FULFILLED,
      payload: { content: payload.content, scope: secondPayload.scope },
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_CONTRACT_REJECTED,
      payload: { error },
    });
  }
}

export function* fetchContractContent(action) {
  try {
    const { id } = action.payload;
    const fullPath = `${SCOPE_PATH}/${id}/contract-full-content`;
    const payload = yield call(API.fetch, fullPath);
    yield put({
      type: definitions.FETCH_CONTRACT_CONTENT_FULFILLED,
      payload,
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_CONTRACT_CONTENT_REJECTED,
      payload: { error },
    });
  }
}

export function* updateContractContent(action) {
  try {
    const { id, content } = action.payload;
    const payload = yield call(API.updateAction, SCOPE_PATH, id, 'update-contract', { content });
    yield put({
      type: definitions.UPDATE_CONTRACT_CONTENT_FULFILLED,
      payload,
    });
    yield put({
      type: SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: MESSAGE_SUCCESS_CONTRACT_UPDATE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.UPDATE_CONTRACT_CONTENT_REJECTED,
      payload: { error },
    });
    yield put({
      type: SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_ERROR,
        message: MESSAGE_ERROR_CONTRACT_UPDATE,
      },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.FETCH_CONTRACT, fetchContract),
    takeLatest(definitions.UPDATE_CONTRACT_CONTENT, updateContractContent),
    takeLatest(definitions.FETCH_CONTRACT_CONTENT, fetchContractContent),
    takeLatest(definitions.SIGN_CONTRACT, signContract),
  ]);
}
