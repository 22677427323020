import React from 'react';
import {
  Grid, Typography, Collapse,
} from '@material-ui/core';
import {
  Call as PhoneIcon,
  Email as EmailIcon,
} from '@material-ui/icons/';
import {
  MASTER,
  usersTitles,
} from 'constants/user';
import { semanticDate } from 'global/functions';
import CopyElement from 'global/CopyElement';
import clsx from 'clsx';
import { useStyles } from './style';

function RowHeader({ user, view, expanded }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.bodyContainer, 'editable')}>
      <Collapse in={expanded}>
        <Typography variant="h5">Editar usuário</Typography>
      </Collapse>
      <Collapse in={!expanded}>
        <Grid container spacing={1}>
          <Grid item className={classes.gridMiddleAlign} xs={3}>
            <Typography>
              <CopyElement>{user.name}</CopyElement>
            </Typography>
            {view === MASTER && (
              <Typography color="textSecondary" variant="body2">
                {usersTitles[user.user_type]}
              </Typography>
            )}
          </Grid>
          <Grid item className={classes.gridMiddleAlign} xs={3}>
            <Typography className={classes.pLine} variant="body1">
              <EmailIcon className={classes.icon} color="disabled" fontSize="inherit" />
              <CopyElement>{user.email}</CopyElement>
            </Typography>
            {user.phone && (
            <Typography className={classes.pLine} variant="body1">
              <PhoneIcon className={classes.icon} color="disabled" fontSize="inherit" />
              <CopyElement>{user.phone}</CopyElement>
            </Typography>
            )}
          </Grid>
          <Grid item className={classes.gridMiddleAlign} xs={3}>
            <Typography variant="body2" color="textSecondary">
              Cadastro em
            </Typography>
            <Typography>
              {semanticDate(user.created_at, true)}
            </Typography>
          </Grid>
          <Grid item className={classes.gridMiddleAlign} xs={3}>
            <Typography variant="body2" color="textSecondary">
              Último acesso
            </Typography>
            <Typography>
              {semanticDate(user.updated_at, true)}
            </Typography>
          </Grid>
        </Grid>
      </Collapse>
    </div>
  );
}

export default RowHeader;
