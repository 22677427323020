import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import uniqid from 'uniqid';
import clsx from 'clsx';
import { ServiceContext } from 'contexts/service';
import { object, bool, func, string } from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  FormGroup,
  FormControl,
  Divider,
  Collapse,
} from '@material-ui/core';
import { STEP_DOCSOK, optionsFunc, STEP_HAGUE } from 'constants/process';
import { createSettings as cSettings, updateSettings as uSettings, changeStepProcess } from 'store/actions/processes';
import { showConfirmation } from 'store/actions/confirmations';
import { stopPropagation } from 'global/functions';
import { showNotification } from 'store/actions/notifications';
import { NOTIFICATION_TYPE_ERROR } from 'constants/notification';
import { MESSAGE_FILL_ALL_FIELDS } from 'constants/common/messages';
import { ArrowForward } from '@material-ui/icons';
import { useStyles } from './styles';

function DialogChecklist({
  currentCheck,
  handleCheckDialog,
  checkDialog,
  updateSettings,
  createSettings,
  setUpdate,
  settingsStatus,
  confirmation,
  notification,
  ...props
}) {
  const objectOptions = {};
  Object.keys(optionsFunc).forEach((key) => {
    objectOptions[key] = '';
  });
  const classes = useStyles(props);
  const [options, setOptions] = useState(objectOptions);
  const [requested, setRequested] = useState(false);
  const [error, setError] = useState(false);
  const { setCurrentCheck } = useContext(ServiceContext);

  useEffect(() => {
    const thisSetting = currentCheck.settings.find((a) => a.key === STEP_DOCSOK);
    if (thisSetting) {
      setOptions((o) => ({ ...o, ...JSON.parse(thisSetting.value) }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCheck]);

  const handleChange = (key, op) => {
    const newOp = options[key] === op ? '' : op;
    const newallOptions = { ...options, [key]: newOp };
    if (currentCheck.settings.find((a) => a.key === STEP_DOCSOK) || requested) {
      updateSettings(currentCheck.id, STEP_DOCSOK, newallOptions);
    } else {
      setRequested(true);
      setCurrentCheck({
        ...currentCheck,
        settings: [
          ...currentCheck.settings,
          {
            key: STEP_DOCSOK,
            value: JSON.stringify(newallOptions),
          }],
      });
      createSettings(currentCheck.id, STEP_DOCSOK, newallOptions);
    }
    setOptions({ ...newallOptions });
  };

  let firstKey = '';

  const handleCloseDialog = () => {
    setUpdate(uniqid());
    handleCheckDialog();
  };

  const handleEndStep = (e) => {
    stopPropagation(e);
    const confirmThis = () => {
      props.changeStepProcess(currentCheck.id, STEP_HAGUE, true);
    };

    let go = true;

    Object.keys(options).forEach((a) => {
      if (optionsFunc[a].required && !options[a]) {
        notification(NOTIFICATION_TYPE_ERROR, MESSAGE_FILL_ALL_FIELDS);
        setError(true);
        go = false;
      }
    });

    if (go) {
      confirmation(
        {
          title: 'Confirmar',
          message: `Confirmar a verificação do processo de ${currentCheck.applicant.name}?`,
        },
        {
          confirm: 'Confirmar',
          cancel: 'Cancelar',
        },
        {
          func: confirmThis,
          payload: {},
        },
      );
    }
  };

  return (
    <Dialog
      PaperProps={{
        square: true,
      }}
      open={checkDialog}
      onClose={handleCloseDialog}
      scroll="body"
      aria-labelledby="dialog-endstep-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="dialog-endstep-title">
        {'Análise de documentação'}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" paragraph>
              {'Análise de documentação referente ao processo de '}
              <Typography component="span" variant="subtitle2" className={classes.bolder}>
                {`${currentCheck.products[0] && currentCheck.products[0].title}`}
              </Typography>
              {' de '}
              <Typography component="span" variant="subtitle2" className={classes.bolder}>
                {`${currentCheck.applicant.name} `}
              </Typography>
              {`(${currentCheck.sales_scope.client_id}).`}
            </Typography>
          </Grid>
        </Grid>
        {Object.keys(options).map((key, index) => {
          const show = (optionsFunc[key].dependant && options[firstKey] === 'Sim') || !optionsFunc[key].dependant;
          const larger = key === 'Observações' || key === 'Quais divergências?';
          firstKey = key;
          const thisError = optionsFunc[key].required && error && options[key] === '';
          return (
            <Collapse key={`${key}`} in={show}>
              <>
                <Grid container spacing={2} className={clsx(classes.container, optionsFunc[key].dependant ? 'specific' : 'regular')}>
                  {typeof optionsFunc[key].options !== 'string'
                    ? (
                      <Grid item xs={6} className={clsx(classes.grid, optionsFunc[key].dependant ? 'specific' : 'regular')}>
                        <Typography color={thisError ? 'error' : 'textPrimary'}>
                          {`${key}${thisError ? ' *' : ''}`}
                        </Typography>
                      </Grid>
                    ) : null}
                  <Grid item xs={typeof optionsFunc[key].options === 'string' || larger ? 12 : 6} className={clsx(classes.grid, optionsFunc[key].dependant ? 'specific' : 'regular')}>
                    {typeof optionsFunc[key].options === 'object'
                      ? (
                        <FormGroup row>
                          <FormControl
                            error={thisError}
                            required={optionsFunc[key].required}
                          >
                            {optionsFunc[key].options.map((op) => (
                              <FormControlLabel
                                key={`${key}${op}`}
                                control={(
                                  <Checkbox
                                    checked={options[key] === op}
                                    onChange={() => handleChange(key, op)}
                                    classes={{
                                      root: clsx(classes.checkBox, thisError ? 'error' : 'textPrimary'),
                                      checked: 'checked',
                                    }}
                                  />
                                )}
                                label={(
                                  <Typography color={thisError ? 'error' : 'textPrimary'}>{op}</Typography>
                                )}
                              />
                            ))}
                          </FormControl>
                        </FormGroup>
                      ) : (
                        <TextField
                          variant="outlined"
                          margin="dense"
                          label={key}
                          required={optionsFunc[key].required}
                          error={thisError}
                          value={options[key]}
                          onChange={(e) => handleChange(key, e.target.value)}
                          fullWidth
                          multiline={larger}
                          rows={4}
                        />
                      )}
                  </Grid>
                </Grid>
                {Object.keys(options).length - 1 !== index && (
                  <Divider className={classes.divider} />
                )}
              </>
            </Collapse>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          variant="outlined"
          color="secondary"
        >
          {'Cancelar'}
        </Button>
        <Button
          onClick={(e) => handleEndStep(e)}
          variant="outlined"
          color="primary"
          type="submit"
        >
          {'Confirmar '}
          <ArrowForward />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogChecklist.propTypes = {
  currentCheck: object,
  checkDialog: bool,
  handleCheckDialog: func,
  setUpdate: func.isRequired,
  createSettings: func.isRequired,
  updateSettings: func.isRequired,
  notification: func.isRequired,
  confirmation: func.isRequired,
  settingsStatus: string.isRequired,
};
DialogChecklist.defaultProps = {
  currentCheck: {},
  checkDialog: false,
  handleCheckDialog: () => { },
};
const mapStateToProps = (state) => ({
  settingsStatus: state.processes.settingsStatus,
});
const actions = {
  confirmation: showConfirmation,
  createSettings: cSettings,
  updateSettings: uSettings,
  changeStepProcess,
  notification: showNotification,
};

export default connect(mapStateToProps, actions)(DialogChecklist);
