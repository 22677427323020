import React from 'react';
import { makeStyles, Icon } from '@material-ui/core';
import {
  PAYMENT_CASH,
  PAYMENT_BANK_SLIP,
  PAYMENT_BANK_TRANSFER,
  PAYMENT_MAXI_PAGO,
  PAYMENT_CREDIT_CARD,
  PAYMENT_ELECTRONIC_TERMINAL,
  PAYMENT_BARTE,
} from 'constants/sales';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  fasFix: {
    '&:before': {
      minWidth: 24,
      textAlign: 'center',
    },
  },
}));

function PaymentIcons({ method, style }) {
  const classes = useStyles();
  const fasStyle = {
    display: 'flex',
    alignItems: 'center',
    minWidth: 24,
    minHeight: 20,
  };

  switch (method) {
    case PAYMENT_CASH:
      return <Icon fontSize="small" className={clsx('fas fa-money-bill', classes.fasFix)} style={{ ...fasStyle, ...style }} />;
    case PAYMENT_BANK_SLIP:
      return <Icon fontSize="small" className={clsx('fas fa-file-invoice', classes.fasFix)} style={{ ...fasStyle, ...style }} />;
    case PAYMENT_BANK_TRANSFER:
      return <Icon fontSize="small" className={clsx('fas fa-exchange-alt', classes.fasFix)} style={{ ...fasStyle, ...style }} />;
    case PAYMENT_MAXI_PAGO:
    case PAYMENT_BARTE:
      return <Icon fontSize="small" className={clsx('fas fa-credit-card', classes.fasFix)} style={{ ...fasStyle, ...style }} />;
    case PAYMENT_CREDIT_CARD:
      return <Icon fontSize="small" className={clsx('far fa-credit-card', classes.fasFix)} style={{ ...fasStyle, ...style }} />;
    case PAYMENT_ELECTRONIC_TERMINAL:
      return <Icon fontSize="small" className={clsx('fas fa-cash-register', classes.fasFix)} style={{ ...fasStyle, ...style }} />;
    default:
      return <Icon fontSize="small" className={clsx('fas fa-question-circle', classes.fasFix)} style={{ ...fasStyle, ...style }} />;
  }
}

PaymentIcons.propTypes = {
  method: PropTypes.string.isRequired,
  style: PropTypes.object,
};

PaymentIcons.defaultProps = {
  style: {},
};

export default PaymentIcons;
