import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Paper, Grid, Button, IconButton, Tooltip, Badge } from '@material-ui/core';
import Contact from 'global/modules/Contact';
import { useStyles } from '../style';
import { SaleDashboardContext } from 'contexts/saleDashboard';
import { CheckBox, CheckBoxOutlineBlank, Share } from '@material-ui/icons';
import { UserContext } from 'contexts/user';

function TabRowSales({ item, ...props }) {
  const classes = useStyles();
  const {
    handleSelectMultiple,
    checkMultiSelected,
    handleDialogShareSale,
  } = React.useContext(SaleDashboardContext);
  const userContext = React.useContext(UserContext);
  const selected = checkMultiSelected(item.id);
  return (
    <Paper className={clsx(classes.body, 'selectable', selected ? 'selected' : 'regular')}>
      <Grid container spacing={2} className={classes.flexContainer}>
        <Grid item xs={8}>
          <IconButton
            onClick={() => handleSelectMultiple(item.id)}
            className={classes.checkButton}
          >
            {selected
              ? <CheckBox />
              : <CheckBoxOutlineBlank />
            }
          </IconButton>
          <Contact item={item} customer={item.customer} editable />
        </Grid>
        <Grid item xs={3}>
          <Link to={`/nova-venda/${item.id || ''}`} className={classes.link}>
            <Button fullWidth variant="outlined">
              Preencher escopo de venda
            </Button>
          </Link>
        </Grid>
        <Grid item xs={1}>
        { item.shared.length > 0 && item.salesman_id === userContext.user.id ? (
                <Tooltip placement="left" title="Esta venda possui compartilhamentos">
                <IconButton
                  className={classes.flexButton}
                  onClick={() => handleDialogShareSale([item.id], item.shared)}
                >
                  <Badge badgeContent={item.shared.length} color="error">
                    <Share />
                  </Badge>
                </IconButton>
              </Tooltip>
              ) : (
                <Tooltip placement="left" title="Compartilhar venda">
                  <IconButton
                    className={classes.flexButton}
                    onClick={() => handleDialogShareSale([item.id])}
                  >
                    <Share />
                  </IconButton>
                </Tooltip>
              )}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default TabRowSales;
