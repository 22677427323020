import React from 'react';
import './style.css';
import PhoneInput from 'react-phone-number-input';
import pt from 'react-phone-number-input/locale/pt';
import { countryNames } from 'objects/imports';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

function phoneFieldInput(props) {
  const { inputRef, ...other } = props;

  return (
    <PhoneInput
      labels={{ ...pt, ...countryNames }}
      country="BR"
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      {...other}
    />
  );
}

function PhoneField({ InputLabelProps, ...props }) {
  return (
    <TextField
      {...props}
      InputLabelProps={InputLabelProps}
      InputProps={{
        inputComponent: phoneFieldInput,
      }}
    />
  );
}

PhoneField.propTypes = {
  InputLabelProps: PropTypes.object,
};

PhoneField.defaultProps = {
  InputLabelProps: { shrink: true },
};

export default PhoneField;
