import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchAllOpenScopes } from 'store/actions/sales';
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import {
  Container,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Paper,
  Button,
} from '@material-ui/core';
import uniqid from 'uniqid';

function ClientNotFound(props) {
  const [findClient, setFindClient] = useState('');

  const handleChangeFindClient = (e) => {
    setFindClient(e.target.value);
  };

  const handleSetClient = () => {
    props.history.push(`/nova-venda/${findClient}`);
    props.setReload(uniqid(), findClient);
  };

  useEffect(() => {
    props.fetchAllOpenScopes();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Typography variant="h4" paragraph align="center">Nova venda</Typography>
      <Paper elevation={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Cliente não encontrado, por favor infome um cliente cadastrado.</Typography>
          </Grid>
          <Grid item>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="find-client">
                Clientes associados à sua conta
              </InputLabel>
              <Select
                value={findClient}
                onChange={handleChangeFindClient}
                style={{ minWidth: 600 }}
                input={
                  <OutlinedInput name="find-client" id="find-client" />
                }
              >
                {props.leads.map((lead) => (
                  <MenuItem key={uniqid()} value={lead.id}>
                    {`${lead.customer && lead.customer.name} - ${lead.client_id}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button
              onClick={handleSetClient}
              style={{ height: '100%' }}
              variant="contained"
              color="primary"
              disabled={!(findClient)}
            >
              <ArrowForwardIcon />
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}

const mapStateToProps = (state) => ({ leads: state.sales.allScopes });

export default connect(mapStateToProps, { fetchAllOpenScopes })(ClientNotFound);
