import * as definitions from 'store/definitions/batchpost';

export function processSearch(searchValue, filter, sortBy, sortOrder) {
  return {
    type: definitions.BATCHPOST_SEARCH,
    payload: {
      filter, sortBy, sortOrder, searchValue,
    },
  };
}

export function createBatchPost(data) {
  return {
    type: definitions.CREATE_BATCH_POST,
    payload: { data },
  };
}

export function updateBatchPost(id, data) {
  return {
    type: definitions.UPDATE_BATCH_POST,
    payload: { id, data },
  };
}

export function removeBatchPost(id) {
  return {
    type: definitions.REMOVE_BATCH_POST,
    payload: { id },
  };
}

export function fetchOpenBatchPost() {
  return {
    type: definitions.FETCH_OPEN_BATCHPOSTS,
  };
}

export function fetchDeliveredBatchPost() {
  return {
    type: definitions.FETCH_DELIVERED_BATCHPOSTS,
  };
}

export function fetchBatchPost(status = []) {
  return {
    type: definitions.FETCH_BATCHPOSTS,
    payload: { status },
  };
}

export function clearResponses() {
  return {
    type: definitions.CLEAR_RESPONSES,
  };
}

export function deliveredBatchPost(id, data) {
  return {
    type: definitions.UPDATE_BATCH_POST_STATUS,
    payload: { id, status: 'deliver', data },
  };
}

export function sentBatchPost(id, data) {
  return {
    type: definitions.UPDATE_BATCH_POST_STATUS,
    payload: { id, status: 'sent', data },
  };
}
