/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  InputBase,
  LinearProgress,
  Link,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  ArrowForward as ArrowForwardIcon,
  Call as PhoneIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  Close as CloseIcon,
  CloudDownload as CloudDownloadIcon,
  CloudUploadOutlined as CloudUploadOutlinedIcon,
  DeleteForever as DeleteForeverIcon,
  Description as DescriptionIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  Email as EmailIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import * as pageTitles from 'constants/common/pageTitles';
import { fade } from '@material-ui/core/styles';
import FileDrop from 'react-file-drop';
import clsx from 'clsx';
import Root from 'global/modules/Root';
import NavTabs from 'service/modules/NavTabs';
import Comments from 'global/Comments';
import Loading from 'global/modules/Loading';
import NoResults from 'global/modules/NoResults';
import * as DUMMIES from 'objects/dummies';
import * as API from 'global/API';
import * as $_ from 'global/functions';

const pageTitle = pageTitles.CONCLUSAO;
const stepValue = 7;

const useStyles = makeStyles(theme => ({
  noResults: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& svg': {
      minWidth: 180,
      height: 'auto',
      opacity: .7,
      marginRight: theme.spacing(2),
    }
  },
  minWidthHolder: {
    overflowX: 'auto',
    paddingBottom: theme.spacing(2),
  },
  minWidthContainer: {
    minWidth: theme.spacing(160),
  },
  pageHeader: {
    marginBottom: theme.spacing(5),
  },
  btnholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  pageTitle: {
    lineHeight: `${theme.spacing(5)}px`,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 1),
    '&:hover': {
      backgroundColor: fade('#000000', 0.08),
    },
    marginRight: theme.spacing(2),
    width: 'auto',
    display: 'inline-flex',
    boxShadow: theme.shadows[3],
    height: theme.spacing(5),
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: 90,
    '&:focus, &:hover': {
      width: 280,
    },
  },
  button: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(5),
    '&:last-child': {
      marginLeft: theme.spacing(2),
    },
    border: 'none',
    boxShadow: theme.shadows[3],
  },
  buttonGroup: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(5),
    border: 'none',
    boxShadow: theme.shadows[3],
    marginLeft: '1px !important',
  },
  iconMargin: {
    marginLeft: theme.spacing(1),
  },
  header: {
    padding: theme.spacing(2, 3),
    marginBottom: theme.spacing(1),
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'relative',
    '&.selected': {
      paddingBottom: 0,
    }
  },
  body: {
    userSelect: 'none',
    padding: theme.spacing(2, 3),
    margin: theme.spacing(1.5, 0, 2, 0),
    position: 'relative',
    minHeight: theme.spacing(8),
    transition: theme.transitions.create(['box-shadow', 'min-height'], { duration: '100ms' }),
    boxShadow: theme.shadows[6],
    cursor: 'pointer',
    '&:hover': {
      boxShadow: theme.shadows[0],
    },
    '&.selected': {
      boxShadow: theme.insetShadows[0],
      backgroundColor: theme.palette.grey[400],
    },
  },
  moreverticon: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    marginTop: '-18px',
    padding: theme.spacing(1),
  },
  centeredGrid: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  hoverText: {
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: fade(theme.palette.text.primary, .7),
    '&:hover': {
      color: theme.palette.text.primary,
    }
  },
  hiddenText: {
    height: '1px',
    position: 'absolute',
    top: '2px',
    zIndex: '-1',
    padding: 0,
    left: 0,
    margin: 0,
    border: 'none',
  },
  icon: {
    marginRight: theme.spacing(.5),
    marginTop: theme.spacing(.5),
  },
  pLine: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    whiteSpace: 'nowrap',
    lineHeight: 1.8,
  },
  dialogMoreInfo: {
    padding: theme.spacing(6, 3, 3, 3),
  },
  smallPaper: {
    width: theme.spacing(35),
    display: 'inline-block',
    padding: theme.spacing(2),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    boxShadow: theme.shadows[4],
    '&:hover': {
      boxShadow: theme.shadows[1],
    }
  },
  link: {
    marginRight: theme.spacing(1),
  },
  closeBtn: {
    position: 'absolute',
    top: theme.spacing(-3),
    right: 0,
  },
  gridContainer: {
    paddingBottom: theme.spacing(1),
    position: 'relative',
  },
  moreverticonDialog: {
    top: theme.spacing(3),
    right: 0,
    position: 'absolute',
  },
  fromMdPadding: {
    [theme.breakpoints.up('md')]: {
      paddingRight: `${theme.spacing(5.5)}px !important`,
    },
  },
  beforeMdPadding: {
    [theme.breakpoints.down('md')]: {
      paddingRight: `${theme.spacing(5.5)}px !important`,
    },
  },
  closeBtnTiny: {
    marginRight: theme.spacing(.5),
  },
  closeBtnTinyIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  buttonGroupSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  inlineButton: {
    width: 45,
    height: 45,
    padding: theme.spacing(1),
  },
  fileBox: {
    cursor: 'pointer',
    position: 'relative',
    '& .file-drop-target': {
      position: 'relative',
      minWidth: 380,
      padding: theme.spacing(3),
      backgroundColor: theme.palette.grey[100],
      border: `2px dashed ${theme.palette.grey[400]}`,
      borderRadius: theme.spacing(.5),
    },
    '& .file-drop-dragging-over-frame': {
      backgroundColor: theme.palette.grey[200],
      zIndex: 1000,
    },
    '&:hover .file-drop-target, & .file-drop-dragging-over-target': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover .hover, & .file-drop-dragging-over-target .hover': {
      color: theme.palette.primary.main,
    },
    '& .hover': {
      fontSize: '4rem',
      marginBottom: theme.spacing(-1),
    },
    '&.error .file-drop-target': {
      borderColor: theme.palette.error.main,
    },
    '& .dummyInput': {
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
      opacity: 0,
      cursor: 'pointer',
    }
  },
  smallMargin: {
    '&:not(.right)': {
      marginLeft: theme.spacing(.5),
    },
    '&.right, &.both': {
      marginRight: theme.spacing(.5),
    }
  },
  paragraphWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  linearProgress: {
    margin: theme.spacing(1, 5),
  },
}))

function Conclusao({ ...props }) {
  const classes = useStyles();
  const toCopy = document.queryCommandSupported('copy');

  function CopyElement(props) {
    const textRef = useRef(null);
    const CopyToClipboard = (e) => {
      textRef.current.select();
      document.execCommand('copy');
      e.target.focus();
      setSnackMessage('Copiado para a área de transferência!')
      setSnackState(true);
      $_.stopPropagation(e);
    }
    return (
      <Tooltip title={`Copiar "${props.children}"`} placement="left" onClick={(e) => { CopyToClipboard(e) }}>
        <Typography variant="inherit" component="span" color="inherit" className={classes.hoverText}>
          {props.children}
          <textarea defaultValue={props.children} className={classes.hiddenText} ref={textRef} />
        </Typography>
      </Tooltip>
    )
  }

  const
    [snackMessage, setSnackMessage] = useState('Copiado para a área de transferência!'),
    [snackState, setSnackState] = useState(false),
    [initialServiceClients, setInitialServiceClients] = useState($_.sortArrayOfObjectsByValue([])),
    [commentsDialog, setCommentsDialog] = useState(false),
    [moreInfoDialog, setMoreInfoDialog] = useState(false),
    [serviceClients, setServiceClients] = useState(initialServiceClients),
    [currentClient, setCurrentClient] = useState({}),
    [searchValue, setSearchValue] = useState(''),
    emptyAnnexFile = { file: {}, error: [] },
    [annexFile, setAnnexFile] = useState(emptyAnnexFile),
    [loadingCard, setLoadingCard] = useState(false),
    [annexOpen, setAnnexOpen] = useState(false),
    [confirmDelete, setConfirmDelete] = useState(false),
    [processTypes, setProcessTypes] = useState([]);

  const handleCloseAnnexDialog = (e) => {
    setAnnexOpen(false);
    setAnnexFile(emptyAnnexFile);
    setLoadingCard(false);
    setCurrentClient({});
    $_.stopPropagation(e);
  }
  const handleOpenAnnexDialog = (e, client) => {
    setAnnexOpen(true);
    setCurrentClient(client);
    $_.stopPropagation(e);
  }

  async function handleUploadFile(files, item) {
    let newAnnexFile = annexFile;
    if (Boolean(newAnnexFile.file.title)) {
      newAnnexFile.file = {};
      setAnnexFile(newAnnexFile);
    }
    if (files.length > 0) {
      let addFiles;
      let i = 0;
      const acceptedFiles = ['jpeg', 'tiff', 'bmp', 'png', 'pdf'];

      setLoadingCard(true);


      if (acceptedFiles.indexOf(files[i].type.split('/')[1]) === -1) {
        setSnackMessage('O formato do arquivo é inválido. Por favor, selecione um arquivo de imagem ou .pdf.');
        setSnackState(true);
      }

      else if (files[i].size > 15728640) {
        setSnackMessage('O arquivo é grande demais. Por favor, selecione um arquivo com menos de 15MB.');
        setSnackState(true);
      }

      else {
        let src = await $_.readFileAsync(files[i]);
        addFiles = {
          src,
          title: files[i].name,
          //compressed,
          size: files[i].size,
          type: files[i].type,
        };
      }

      newAnnexFile.file = addFiles;
      const indexOf = annexFile.error.indexOf('file');
      if (indexOf >= 0) {
        let errors = newAnnexFile.error;
        errors.splice(indexOf, 1);
        newAnnexFile.error = errors;
      }

      setAnnexFile(newAnnexFile);
      setLoadingCard(false);
    }
  }

  const handleConfirmAnnex = (e) => {
    const newServiceClients = serviceClients.map(a => {
      if (a.id === currentClient.id) {
        a.fileCertificate = annexFile.file;
      }
      return a;
    });
    setAnnexFile(emptyAnnexFile);
    setServiceClients([...newServiceClients]);
    setAnnexOpen(false);
    $_.stopPropagation(e);
  }

  const handleMoreInfo = (client, e) => {
    setCurrentClient(client);
    setMoreInfoDialog(!moreInfoDialog);
  }

  const handleCloseMoreInfo = e => {
    setMoreInfoDialog(false);
  }

  const handleCloseSnack = e => {
    setSnackState(false);
  }

  const clearSearch = e => {
    setSearchValue('');
    updateList(!sortItems, "");
  }
  const handleSortMenu = e => {
    updateList(!sortItems, searchValue);
    setSortItems(!sortItems);
  }

  const handleSearch = e => {
    const a = e.target.value;
    updateList(sortItems, a);
    setSearchValue(a);
  }

  const handleConfirmDeleteAnnex = () => {
    setConfirmDelete(!confirmDelete);
  }

  const deleteAnnex = () => {
    let newCurrentClient = currentClient;
    const newServiceClients = serviceClients.map(a => {
      if (a.id === currentClient.id) {
        a.fileCertificate = {};
        newCurrentClient = a;
      }
      return a;
    });

    setServiceClients([...newServiceClients]);
    setCurrentClient({ ...newCurrentClient });
    setConfirmDelete(false);
  }

  const updateList = (sort, search) => {
    const regsearch = new RegExp($_.regBuilder(search), 'i');
    let returnable = "";
    if (search.length > 2) {
      returnable = $_.sortArrayOfObjectsByValue(serviceClients, 'completionDate', sort);
      returnable = returnable.filter(a => {
        return (
          a.id.match(regsearch)
          || a.name.match(regsearch)
          || a.email.match(regsearch)
          || a.phone.match(regsearch)
        )
      });
    }
    else returnable = $_.sortArrayOfObjectsByValue(initialServiceClients, 'completionDate', sort);
    setServiceClients(returnable);
  }

  const [sortItems, setSortItems] = useState(false);


  const handleCloseComments = e => {
    setCommentsDialog(false);
  }

  useEffect(() => {
  }, []);

  const filteredClients = serviceClients.filter(a => {
    return a.currentStep === stepValue;
  });

  return (
    <Root setTitle={pageTitles.title(pageTitles.CONCLUSAO, pageTitles.SERVICE)} header footer user={props.user}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackState}
        onClose={handleCloseSnack}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        autoHideDuration={2000}
        message={<span id="message-id">{snackMessage}</span>}
      />
      <Dialog
        open={annexOpen}
        onClose={handleCloseAnnexDialog}
        aria-labelledby="dialog-annex-title"
        scroll="body"
      >
        <DialogTitle id="dialog-annex-title">
          {(currentClient.fileCertificate ? Boolean(currentClient.fileCertificate.title) : false) ? `Certidão portuguesa de ${currentClient.name}` : `Anexar certidão`}
        </DialogTitle>
        <DialogContent>
          {Object.keys(currentClient).length > 0 ?
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {(currentClient.fileCertificate ? Boolean(currentClient.fileCertificate.title) : false)
                  ?
                  <>
                    <Typography gutterBottom>
                      <Tooltip title="Baixar certidão">
                        <Button>
                          <strong className={clsx(classes.smallMargin, 'right')}>
                            {currentClient.fileCertificate.title}
                          </strong> {$_.humanFileSize(currentClient.fileCertificate.size)}
                        </Button>
                      </Tooltip>
                      <Tooltip title="Baixar certidão">
                        <IconButton className={clsx(classes.smallMargin, 'both')} size="small">
                          <CloudDownloadIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Apagar certidão">
                        <IconButton
                          onClick={handleConfirmDeleteAnnex}
                          size="small">
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>
                    </Typography>

                    {currentClient.fileCertificate.type === "application/pdf"
                      ?
                      <embed
                        src={currentClient.fileCertificate.src}
                        style={{ width: '100%', height: 'auto', minHeight: '500px', marginBottom: '-4px' }}
                      />
                      :
                      <img src={currentClient.fileCertificate.src} alt={currentClient.fileCertificate.title} width="100%" height="auto" style={{ marginBottom: '-4px' }} />
                    }
                  </>
                  : null}
              </Grid>
              <Grid item xs={12}>
                {(currentClient.fileCertificate ? Boolean(currentClient.fileCertificate.title) : false) ?
                  <Typography variant="h6" className={annexFile.error.indexOf('file') >= 0 ? classes.error : 'regular'} gutterBottom>Substituir arquivo</Typography>
                  : null}
                <div id="react-file-drop"
                  className={
                    clsx(classes.fileBox,
                      annexFile.error.indexOf('file') >= 0 ? 'error' : 'regular')}>
                  <FileDrop
                    onDrop={(files, e) => { handleUploadFile(files); }}>
                    <Typography align="center">
                      <CloudUploadOutlinedIcon className="hover" fontSize="large" />
                    </Typography>
                    <Typography
                      variant="h6"
                      align="center">Arraste e solte</Typography>
                    <Typography
                      align="center"
                      color="textSecondary">ou <Typography
                        color="primary"
                        component="span">clique aqui</Typography> para selecionar a certidão</Typography>
                    {!Boolean(annexFile.file.title)
                      ? loadingCard
                        ? <LinearProgress className={classes.linearProgress} color="primary" />
                        : null
                      : <Typography className={classes.paragraphWrapper}>
                        <strong className={clsx(classes.smallMargin, 'right')}>{annexFile.file.title} </strong> {$_.humanFileSize(annexFile.file.size)} <CheckCircleOutlinedIcon className={classes.smallMargin} color="primary" />
                      </Typography>}
                  </FileDrop>
                  <input
                    type="file"
                    className={`dummyInput`}
                    id="file-upload"
                    onChange={(event) => handleUploadFile(event.target.files)}
                    accept=".jpeg,.jpg,.tiff,.bmp,.png,.pdf"
                  />
                </div>
              </Grid>
            </Grid>
            : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAnnexDialog} variant="outlined" color="secondary">
            Cancelar
                            </Button>
          <Button onClick={handleConfirmAnnex} disabled={!Boolean(annexFile.file.title)} variant="outlined" color="primary">
            Confirmar envio <ArrowForwardIcon className={classes.iconMargin} />
          </Button>
        </DialogActions>
      </Dialog>
      {currentClient.fileCertificate ?
        <Dialog
          open={confirmDelete}
          onClose={handleConfirmDeleteAnnex}
          maxWidth="sm"
          scroll="body">
          <DialogTitle>
            Deseja apagar o arquivo "<strong>{currentClient.fileCertificate.title}</strong>" referente à certidão de <strong>{currentClient.name}</strong> do sistema?
                        </DialogTitle>
          <DialogActions>
            <Button onClick={handleConfirmDeleteAnnex} variant="outlined" color="secondary">
              Cancelar
                            </Button>
            <Button onClick={deleteAnnex} variant="outlined" color="primary">
              Apagar
                            </Button>
          </DialogActions>
        </Dialog>
        : null}
      <Dialog
        open={commentsDialog}
        onClose={handleCloseComments}
        fullWidth
        maxWidth="md"
        scroll="body">
        <DialogContent className={classes.dialogMoreInfo}>
          {Object.keys(currentClient).length > 0 ?
            <Grid container spacing={2} className={classes.gridContainer}>
              <IconButton onClick={(e) => handleCloseComments(e)} className={classes.closeBtn} >
                <CloseIcon />
              </IconButton>
              <Grid item xs={12} md={8} className={classes.beforeMdPadding}>
                <Typography variant="h4">{currentClient.name}</Typography>
                <Typography variant="h6">{currentClient.id}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" paragraph>Comunicação interna</Typography>
                <Comments
                  inverted
                  allowed
                  master
                  step={1}
                  label={`Escreva seu comentário`}
                />
              </Grid>
            </Grid>
            : null}
        </DialogContent>
      </Dialog>
      <Dialog
        open={moreInfoDialog}
        onClose={handleCloseMoreInfo}
        fullWidth
        maxWidth="md"
        scroll="body">
        <DialogContent className={classes.dialogMoreInfo}>
          {Object.keys(currentClient).length > 0
            ?
            <>
              <Grid container spacing={2} className={classes.gridContainer}>
                <IconButton onClick={(e) => handleCloseMoreInfo(e)} className={classes.closeBtn} >
                  <CloseIcon />
                </IconButton>
                <Grid item xs={12} md={8} className={classes.beforeMdPadding}>
                  <Typography variant="h4">{currentClient.name}</Typography>
                  <Typography variant="h6">{currentClient.id}</Typography>
                </Grid>
                <Grid item xs={12} md={4} className={classes.fromMdPadding}>
                  <Typography className={clsx(classes.pLine)}>
                    <EmailIcon className={classes.icon} color="disabled" fontSize="inherit" />
                    {toCopy ?
                      <CopyElement>{currentClient.email}</CopyElement>
                      : currentClient.email}
                  </Typography>
                  <Typography className={clsx(classes.pLine)}>
                    <PhoneIcon className={classes.icon} color="disabled" fontSize="inherit" />
                    {toCopy ?
                      <CopyElement>{currentClient.phone}</CopyElement>
                      : currentClient.phone}
                  </Typography>
                  <Typography><Link href="alinhamento" underline="always" className={classes.link}>Documentos</Link><Link href="conclusao" underline="always" className={classes.link}>Contrato</Link></Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item>
                  <Typography color="primary" gutterBottom>Data de entrada</Typography>
                  <Typography variant="subtitle2">{$_.semanticDate(currentClient.dateInitialised, true)}</Typography>
                </Grid>
                <Grid item>
                  <Typography color="primary" gutterBottom>Assento</Typography>
                  <Typography variant="subtitle2">Em busca</Typography>
                </Grid>
                <Grid item>
                  <Typography color="primary" gutterBottom>Tipo de processo</Typography>
                  <Typography variant="subtitle2">{processTypes.find(a => { return a.title === currentClient.applicants[0].hiredServices[0]; }).titleClient}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={12}>
                  <Typography variant="h5" paragraph>Cidadãos portugueses da família</Typography>
                </Grid>
                <Grid item xs={12}>
                  {currentClient.registeredCitizens.map((citizen, index) => {
                    return (
                      <Paper key={`${citizen.id}${index}`} className={classes.smallPaper} elevation={1}>
                        <Typography variant="h6">
                          {citizen.name}
                        </Typography>
                        <Typography variant="subtitle2">
                          Nascimento: {citizen.birthDate}
                        </Typography>
                      </Paper>
                    )
                  })}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" paragraph>Outros processos da família</Typography>
                </Grid>
                <Grid item xs={12}>
                  {currentClient.applicants.map((applicant, index) => {
                    return (
                      <Paper key={`${applicant.id}${index}`} className={classes.smallPaper} elevation={1}>
                        <Typography variant="subtitle2">
                          {applicant.id}
                        </Typography>
                        <Typography variant="h6">
                          {applicant.name}
                        </Typography>
                      </Paper>
                    )
                  })}
                </Grid>
              </Grid>
            </>
            : null}
          <>
            <Typography variant="h5" paragraph>Comunicação interna</Typography>
            <Comments
              inverted
              allowed
              master
              step={1}
              label={`Escreva seu comentário`}
            />
          </>
        </DialogContent>
      </Dialog>
      <div className={classes.minWidthHolder}>
        <Container className={classes.minWidthContainer} maxWidth="xl">
          <Grid
            container
            spacing={2}
            className={classes.pageHeader}>
            <Grid item xs={6}>
              <Typography
                variant="h5"
                color="textSecondary"
                className={classes.pageTitle}>
                {`Processos concluídos (${filteredClients.length})`}
              </Typography>

            </Grid>
            <Grid item xs={6} className={classes.btnholder}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Buscar..."
                  onChange={(e) => { handleSearch(e) }}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  value={searchValue}
                  endAdornment={
                    <IconButton onClick={(e) => clearSearch(e)} classes={{ root: classes.closeBtnTiny }} style={{ opacity: Boolean(searchValue) ? 1 : 0 }} size="small">
                      <CloseIcon fontSize="small" classes={{ root: classes.closeBtnTinyIcon }} />
                    </IconButton>
                  }
                />
              </div>
              <Tooltip title="Ordenar por data de conclusão">
                <Button
                  variant="contained"
                  onClick={handleSortMenu}
                  className={classes.buttonGroup}
                  aria-label="Ascendente ou decrescente"
                >
                  Ordenar<Icon style={{ fontSize: '1.2rem' }} className={clsx('fas', sortItems ? 'fa-sort-numeric-up' : 'fa-sort-numeric-down', classes.iconMargin)} />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          {filteredClients.length > 0 ?
            <>
              <Paper className={classes.header}>
                <Grid container spacing={2}>
                  <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Typography variant="body2">
                          Cliente
                                                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body2">
                          Contato
                                                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body2">
                          Data de entrada
                                                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body2">
                          Data de conclusão
                                                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body2">
                          Tipo de processo
                                                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body2">
                          Número de assento
                                                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography align="right" variant="body2">
                      Certidão
                                                </Typography>
                  </Grid>
                </Grid>
              </Paper>
              {serviceClients.filter(a => {
                return a.currentStep === stepValue;
              }).map((client, index) => {
                const AnnexButton = (props) => {
                  const uploaded = props.certificate
                    ? Boolean(props.certificate.title)
                    : false;
                  return (
                    <Tooltip
                      title={uploaded
                        ? `Ver certidão`
                        : `Anexar certidão`}>
                      <IconButton
                        onClick={e => handleOpenAnnexDialog(e, client)}
                        className={
                          clsx(classes.inlineButton, uploaded
                            ? 'uploaded'
                            : 'regular')}>
                        {uploaded ?
                          <DescriptionIcon color="primary" />
                          :
                          <DescriptionOutlinedIcon />}
                      </IconButton>
                    </Tooltip>
                  )
                };
                return (
                  <Paper
                    key={`${client.name}${index}`}
                    className={classes.body}
                    onClick={e => { handleMoreInfo(client, e); }}>
                    <Grid container spacing={2}>
                      <Grid item xs={11} className={classes.centeredGrid}>
                        <Grid container spacing={2}>
                          <Grid item xs={2} className={classes.centeredGrid}>
                            <Typography variant="body1" color="textSecondary">
                              {toCopy
                                ? <CopyElement>{client.id}</CopyElement>
                                : client.id}
                            </Typography>
                            <Typography variant="subtitle2">
                              {toCopy
                                ? <CopyElement>{client.name}</CopyElement>
                                : client.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} className={classes.centeredGrid}>
                            <Typography variant="body1" className={classes.pLine}>
                              <EmailIcon className={classes.icon} color="disabled" fontSize="inherit" />
                              {toCopy ?
                                <CopyElement>{client.email}</CopyElement>
                                : client.email}
                            </Typography>
                            <Typography className={classes.pLine} variant="body1">
                              <PhoneIcon className={classes.icon} color="disabled" fontSize="inherit" />
                              {toCopy ?
                                <CopyElement>{client.phone}</CopyElement>
                                : client.phone}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} className={classes.centeredGrid}>
                            <Typography variant="body1">
                              {$_.semanticDate(client.dateInitialised, true)}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} className={classes.centeredGrid}>
                            <Typography variant="body1">
                              {$_.semanticDate(client.completionDate, true)}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} className={classes.centeredGrid}>
                            <Typography variant="body1">
                              {client.citizenshipType}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} className={classes.centeredGrid}>
                            <Typography variant="body1">
                              {toCopy
                                ? <CopyElement>{client.assento}</CopyElement>
                                : client.assento}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={1} className={classes.centeredGrid} style={{ alignItems: 'flex-end' }}>
                        <AnnexButton certificate={client.fileCertificate} />
                      </Grid>
                    </Grid>
                  </Paper>
                )
              })}
            </>
            :
            <NoResults message={`Nenhum processo encontrado`} icon="lot" />
          }
        </Container>
      </div>
    </Root>
  )
}

export default Conclusao;