/* eslint-disable react/destructuring-assignment */
import React from 'react';
import uniqid from 'uniqid';
import Notifications from 'global/notifications';
import Dialogs from 'global/dialogs';
import { ActionsContext } from 'contexts/actions';

const ActionsProvider = ({ children }) => {
  const [state, setState] = React.useState({
    reload: '',
  });

  const reloadAll = () => setState((o) => ({ ...o, reload: uniqid() }));

  return (
    <ActionsContext.Provider
      value={{
        ...state,
        reloadAll,
      }}
    >
      <Notifications />
      <Dialogs />
      {children}
    </ActionsContext.Provider>
  );
};

export default ActionsProvider;
