import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { userLocations } from 'constants/user';
import { UserContext } from 'contexts/user';

function ReRouteUser({ history }) {
  const { user } = useContext(UserContext);

  useEffect(() => {
    history.push(userLocations(user));
  }, [user, history]);

  return (
    <Skeleton
      style={{
        margin: 0,
        height: '100vh',
      }}
    />
  );
}

ReRouteUser.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default ReRouteUser;
