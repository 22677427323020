export const UPDATE_PAID_PAYMENT = 'UPDATE_PAID_PAYMENT';
export const UPDATE_PAID_PAYMENT_FULFILLED = 'UPDATE_PAID_PAYMENT_FULFILLED';
export const UPDATE_PAID_PAYMENT_REJECTED = 'UPDATE_PAID_PAYMENT_REJECTED';

export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const CREATE_PAYMENT_FULFILLED = 'CREATE_PAYMENT_FULFILLED';
export const CREATE_PAYMENT_REJECTED = 'CREATE_PAYMENT_REJECTED';

export const REMOVE_PAYMENT = 'REMOVE_PAYMENT';
export const REMOVE_PAYMENT_FULFILLED = 'REMOVE_PAYMENT_FULFILLED';
export const REMOVE_PAYMENT_REJECTED = 'REMOVE_PAYMENT_REJECTED';

export const CONFIRM_SALE = 'CONFIRM_SALE';
export const CONFIRM_SALE_FULFILLED = 'CONFIRM_SALE_FULFILLED';
export const CONFIRM_SALE_REJECTED = 'CONFIRM_SALE_REJECTED';

export const LOAD_PAYMENTS = 'LOAD_PAYMENTS';
export const LOAD_PAYMENTS_FULFILLED = 'LOAD_PAYMENTS_FULFILLED';
export const LOAD_PAYMENTS_REJECTED = 'LOAD_PAYMENTS_REJECTED';

export const CHECK_PAYMENT = 'CHECK_PAYMENT';
export const CHECK_PAYMENTS_FULFILLED = 'CHECK_PAYMENTS_FULFILLED';
export const CHECK_PAYMENTS_REJECTED = 'CHECK_PAYMENTS_REJECTED';

export const UNCHECK_PAYMENT = 'UNCHECK_PAYMENT';
export const UNCHECK_PAYMENTS_FULFILLED = 'UNCHECK_PAYMENTS_FULFILLED';
export const UNCHECK_PAYMENTS_REJECTED = 'UNCHECK_PAYMENTS_REJECTED';