import React, { useEffect, useContext } from 'react';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  makeStyles,
  Grid,
  Container,
} from '@material-ui/core';
import { BlipChat } from 'blip-chat-widget';
import { UserContext } from 'contexts/user';
import Alerts from 'global/Alerts';
import Timeline from 'global/modules/Timeline';
import Root from 'global/modules/Root';
import * as $_ from 'global/functions';
import UserCardList from './modules/UserCard';

const useStyles = makeStyles((theme) => ({
  cardsExpand: {
    margin: `${theme.spacing(3, 0, 2, 0)} !important`,
    backgroundColor: 'transparent',
  },
  cardsSummary: {
    backgroundColor: theme.palette.common.white,
  },
  cardsRoot: {
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
  },
}));

function Dashboard(props) {
  const classes = useStyles(props);

  const userContext = useContext(UserContext);
  const { user } = userContext;
  // temp data
  user.phase = 3;
  user.currentPhase = 2;

  const phase = (i) => (
    <Typography variant="h6" color="textSecondary">
      {`${$_.capitalize($_.ordinalInPortuguese(i))} fase`}
    </Typography>
  );

  useEffect(() => {
    const blipColor = '#2CC3D5';
    const blipIcon = '/img/blipicon.png';
    const blipStyle = `
            #message-input #blip-send-message.send-button, .blip-card-container .bubble.right {
                background-color: ${blipColor} !important;
            }
            `;
    // Y2lkYWRhbmlhamE6ZDNiN2Y4MDgtODU3NS00ZTc2LThiMzYtYWRkZjg1MDIyN2Q1
    // Y2lkYWRhbmlhamFjZW50cmFsOmJhNzNiNGZkLTA0ZDktNDY1MS1hZDllLWYyY2EwNWI2ZjNlMA==
    const chatBuilder = new BlipChat()
      .withAppKey('Y2lkYWRhbmlhamE6ZDNiN2Y4MDgtODU3NS00ZTc2LThiMzYtYWRkZjg1MDIyN2Q1')
      .withButton({ color: blipColor, icon: blipIcon })
      .withAuth({
        authType: BlipChat.DEV_AUTH,
        userIdentity: user.id,
        userPassword: user.id,
      })
      .withAccount({
        fullName: user.name,
        email: user.email,
        phoneNumber: user.phone,
        city: user.city,
        timezone: ((new Date()).getTimezoneOffset() / 60) * -1,
        extras: { pt: user.id, Persona: user.persona },
      })
      .withCustomStyle(blipStyle);

    chatBuilder.build();
    // eslint-disable-next-line
  }, []);

  return (
    <Root header footer user={user}>
      <Container maxWidth="xl">
        <Alerts />
        <Timeline user={user} style={{ display: 'none' }} />
        <Typography variant="h5" color="secondary" paragraph>Processos atuais</Typography>
        {user.phase > 1 && phase(user.currentPhase)}
        <Grid container spacing={4}>
          <UserCardList user={user} filterPhase={user.currentPhase} newuser />
        </Grid>

        <ExpansionPanel square classes={{ root: classes.cardsExpand }}>
          <ExpansionPanelSummary classes={{ root: classes.cardsSummary }} expandIcon={<i style={{ fontSize: '0.8em' }} className="fas fa-chevron-down" />}>
            <Typography variant="h5" color="secondary">Próximos processos</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails classes={{ root: classes.cardsRoot }}>
            {phase(3)}
            <Grid container spacing={4}>
              <UserCardList user={user} filterPhase={3} smallCard />
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel square classes={{ root: classes.cardsExpand }}>
          <ExpansionPanelSummary classes={{ root: classes.cardsSummary }} expandIcon={<i style={{ fontSize: '0.8em' }} className="fas fa-chevron-down" />}>
            <Typography variant="h5" color="secondary">Processos passados</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails classes={{ root: classes.cardsRoot }}>
            {phase(1)}
            <Grid container spacing={4}>
              <UserCardList user={user} filterPhase={1} />
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Container>
    </Root>
  );
}

export default Dashboard;
