import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchAll } from 'store/actions/users';
import Root from 'global/modules/Root';
import { UserContext } from 'contexts/user';
import * as pageTitles from 'constants/common/pageTitles';
import {
  Container, Grid,
} from '@material-ui/core';
import { LoadingComponent } from 'global/modules/Loading';
import {
  CUSTOMER, PERSON, MASTER, INTERNAL, INTERNAL_SALES, INTERNAL_SERVICE, MANAGER, INTERNAL_COORDINATOR,
} from 'constants/user';
import UsersHeader from './Header';
import UsersRow from './Row';
import { REQUESTING } from 'constants/common';
import { Pagination } from '@material-ui/lab';

function Users({ status, fetch, users }) {
  const [page, setPage] = useState(1);
  const userContext = useContext(UserContext);
  const [expanded, setExpanded] = useState(null);
  let view = [CUSTOMER, PERSON];
  const internalType = [INTERNAL_SALES, INTERNAL_SERVICE, INTERNAL_COORDINATOR];
  if (userContext.isMaster || userContext.isManager) view = [INTERNAL, ...view, MANAGER];
  if (userContext.isManager) view = [...view, MASTER];

  const handleNavigation = (event, pageNumber) =>  setPage(pageNumber)

  useEffect(() => {
    fetch(view, null, null, 20, page);
    // eslint-disable-next-line
  }, [page]);

  return (
    <Root setTitle={pageTitles.USERS} header paddingTop minWidth={960}>
      <Container maxWidth="xl">
        <UsersHeader user={userContext.user} view={view} internalType={internalType} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {users.data.length === 0 && status === REQUESTING && <LoadingComponent />}
            {users.data.map((userListed) => (
              <UsersRow
                key={`user${userListed.id}`}
                expanded={expanded}
                setExpanded={setExpanded}
                user={userListed}
                view={view}
                internalType={internalType}
                defaultExpand={users.length === 1}
              />
            ))}
          </Grid>
          <Pagination count={users.last_page} page={page} onChange={handleNavigation} />
        </Grid>
      </Container>
    </Root>
  );
}

const mapStateToProps = (state) => ({
  users: state.listUsers.users,
  status: state.listUsers.fetchStatus,
});

export default connect(mapStateToProps, { fetch: fetchAll })(Users);
