import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { MobileStepper, Button, makeStyles, Paper, Menu, MenuItem } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { SalesContext } from 'contexts/sales';
import NextIcon from '@material-ui/icons/ArrowForwardIos';
import PreviousIcon from '@material-ui/icons/ArrowBackIos';
import { confirmScope } from 'store/actions/sales';
import { showConfirmation } from 'store/actions/confirmations';
import ChatBot from 'global/ChatBot';

const useStyles = makeStyles((theme) => ({
  progress: {
    flexGrow: 1,
    margin: '0 auto',
    backgroundColor: 'transparent',
  },
  mobileStepper: {
    margin: theme.spacing(0, 1),
    width: '80%',
    minWidth: 200,
    [theme.breakpoints.down('sm')]: {
      minWidth: 100,
    },
  },
  paperStepper: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: theme.palette.common.white,
    zIndex: 2,
    padding: theme.spacing(0, 2),
  }
}));

function FormProgress({ currentStep, scope, ...props }) {
  const classes = useStyles();
  const saleContext = useContext(SalesContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const allowedNext = saleContext.stepsOk[saleContext.currentStep];

  const handleNextStep = () => {
    if (saleContext.currentStep < 4) saleContext.setStep(saleContext.currentStep + 1);
  };

  const handleBackStep = () => {
    if (saleContext.currentStep > 0) saleContext.setStep(saleContext.currentStep - 1);
  };

  const handleMenu = (e) => {
    if (anchorEl) setAnchorEl(null);
    else setAnchorEl(e.currentTarget); 
  };

  const confirmThis = ({ type }) => {
    props.confirmScope(scope.id, type, scope);
  };

  const handleConfirm = (type) => {
    props.showConfirmation(
      {
        title: 'Gerar contrato',
        message: `Gerar contrato de ${scope.customer.name} para ser assinado ${type === 'online' ? 'online' : 'presencialmente'}?`,
      },
      {
        confirm: 'Gerar contrato',
        cancel: 'Cancelar',
      },
      {
        func: confirmThis,
        payload: { type },
      },
    );
  };

  return (
    <Paper elevation={4} className={classes.paperStepper}>
      <Menu
        id="end-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenu}
      >
        <MenuItem onClick={() => handleConfirm('offline')}>Assinatura presencial</MenuItem>
        <MenuItem onClick={() => handleConfirm('online')}>Assinatura online</MenuItem>
      </Menu>
      <MobileStepper
        variant="progress"
        steps={5}
        position="static"
        activeStep={saleContext.currentStep}
        classes={{
          root: classes.progress,
          progress: classes.mobileStepper,
        }}
        nextButton={(
          <Button
            disabled={!allowedNext}
            onClick={(e) => saleContext.currentStep === 4 ? handleMenu(e) : handleNextStep()}
          >
            {saleContext.currentStep < 4 && (
              <>
                {'Continuar'}
                <NextIcon fontSize="small" />
              </>
            )}
            {saleContext.currentStep === 4 && <>Finalizar</>}
          </Button>
        )}
        backButton={(
          <Button
            onClick={handleBackStep}
            style={{ opacity: saleContext.currentStep === 0 ? 0 : 1 }}
            disabled={saleContext.currentStep === 0}
          >
            <PreviousIcon fontSize="small" />
            Voltar
          </Button>
        )}
      />
      <ChatBot />
    </Paper>
  );
}

FormProgress.propTypes = {
  allowedNext: PropTypes.bool,
};

FormProgress.defaultProps = {
  allowedNext: true,
};

const mapStateToProps = (state) => ({
  confirm: state.sales.confirm,
  scope: state.sales.scope,
});

export default connect(mapStateToProps, { confirmScope, showConfirmation })(FormProgress);
