/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Paper,
  Grid,
  IconButton,
  Typography,
  Button,
  Tooltip,
  Collapse,
  Link,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  ExpandMore as ExpandLessIcon,
  RemoveCircle as RemoveIcon,
  Send,
} from '@material-ui/icons';
import { stopPropagation, toCurrency } from 'global/functions';
import { localeDate } from 'helpers';
import { PICK_DATE_PLANNED } from 'constants/service/menus';
import LotInnerHeader from './LotInnerHeader';
import ProcessInLotRow from './ProcessInLotRow';

function LotRowUi({
  classes,
  isDragging,
  index,
  isActive,
  masterExpanded,
  handleThisExpanded,
  lot,
  handleLotDialog,
  removeLot,
  totalCost,
  lotNames,
  handleDialogDatePick,
}) {
  const getLotName = () => lotNames[lot.id] || `Pacote ${lot.id}`;
  return (
    <Paper
      square
      className={clsx(
        classes.bodyEnvio,
        'paddingNone',
        'lot',
        isDragging && 'dragging',
        index > 0 && 'paddingTop',
        isActive ? 'isActive' : 'regular',
      )}
    >
      <Paper
        square
        className={clsx(classes.bodyEnvio, 'inner')}
      >
        <Grid container spacing={2}>
          <Grid item xs={1} className={classes.centeredGrid}>
            {lot.processes.length > 0
              && (
                <IconButton
                  onClick={(e) => handleThisExpanded(e)}
                  className={clsx(classes.flexButton, masterExpanded ? 'expanded' : 'regular')}
                >
                  <ExpandLessIcon />
                </IconButton>
              )}
          </Grid>
          <Grid item xs={2} className={classes.centeredGrid}>
            <Typography variant="body1">
              {getLotName()}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {`${lot.processes.length} processo${lot.processes.length > 1 ? 's' : ''}`}
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.centeredGrid}>
            <Typography variant="body1" color="textSecondary">
              {'Previsão de envio'}
            </Typography>
            <Typography variant="body1">
              <Link
                component="button"
                variant="body1"
                align="center"
                color="primary"
                style={{ height: '100%' }}
                onClick={(e) => {
                  handleDialogDatePick(true, lot, PICK_DATE_PLANNED, e, lot.planned_date || null);
                }}
              >
                {lot.planned_date ? localeDate(lot.planned_date) : 'Selecionar data'}
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={5} className={classes.centeredGrid}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleLotDialog(lot)}
            >
              {'Confirmar envio'}
              <Send fontSize="small" className={classes.iconMargin} />
            </Button>
          </Grid>
          <Grid item xs={1} className={classes.centeredGrid} style={{ alignItems: 'flex-end' }}>
            <Tooltip title="Desfazer pacote" disableTouchListener placement="left">
              <IconButton
                className={classes.flexButton}
                aria-label="More"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e) => { removeLot(lot.id); stopPropagation(e); }}
              >
                <RemoveIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {isActive && (
            <Grid item xs={12} className={classes.paperAlert}>
              <Typography align="center">
                {'Adicionar processo'}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
      {lot.processes.length > 0 && (
        <Collapse in={masterExpanded}>
          <LotInnerHeader classx={classes.innerHeader} />
          {lot.processes.map((process) => (
            <ProcessInLotRow process={process} key={`processINLOT${process.id}`} lot={lot} />
          ))}
          <Paper square className={clsx(classes.innerBody, 'noBorder')} elevation={0}>
            <Grid container spacing={2}>
              <Grid item xs={8} className={classes.centeredGrid} style={{ justifyContent: 'flex-end' }}>
                <Typography variant="body1" align="right">Valor total</Typography>
              </Grid>
              <Grid item xs={4} className={classes.centeredGrid}>
                <Typography variant="subtitle2">
                  {toCurrency(totalCost.cost, 'EUR')}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Collapse>
      )}
    </Paper>
  );
}

LotRowUi.propTypes = {
  classes: PropTypes.object.isRequired,
  isDragging: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  masterExpanded: PropTypes.bool.isRequired,
  handleThisExpanded: PropTypes.func.isRequired,
  lot: PropTypes.object.isRequired,
  handleLotDialog: PropTypes.func.isRequired,
  removeLot: PropTypes.func.isRequired,
  totalCost: PropTypes.object.isRequired,
  lotNames: PropTypes.object.isRequired,
  handleDialogDatePick: PropTypes.func.isRequired,
};

export default LotRowUi;
