// @ts-nocheck
import React, { useEffect, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { ServiceContext } from 'contexts/service';
import { DEFAULT } from 'constants/common';
import {
  array, string, oneOfType, number, func,
} from 'prop-types';
import {
  Container,
} from '@material-ui/core';
import * as pageTitles from 'constants/common/pageTitles';
import NoResults from 'global/modules/NoResults';
import { sendDocumentationEmail } from 'store/actions/sales';
import { fetchProcessesBySteps, processSearch, clearResponses } from 'store/actions/processes';
import { STEP_HAGUE } from 'constants/process';
import { FULFILLED } from 'constants/session';
import { LoadingComponent } from 'global/modules/Loading';
import Row from './Row';
import Header from './Header';
import { useStyles } from '../style';
import Dialogs from './modules/Dialogs';
import Menus from './modules/Menus';
import Pagination from "@material-ui/lab/Pagination";

function Apostilamento({
  apostilamentoReq,
  status,
  update,
  setUpdate,
  allProcessesReq,
  ...props
}) {
  const classes = useStyles();

  const serviceContext = useContext(ServiceContext);
  const { currentClient, currentProcess, clearMultipleSelect } = serviceContext;
  const [apostilamento, setApostilamento] = useState([...apostilamentoReq]);
  const [allProcesses, setAllProcesses] = useState([...allProcessesReq]);
  const [requesting, setRequesting] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const handleCurrentPage = (e, page) => {
    setCurrentPage(page);
    props.fetchProcessesBySteps([STEP_HAGUE], page);
  }

  useEffect(() => {
    if (status === FULFILLED && requesting) {
      setRequesting(false);
    }
    setApostilamento([...apostilamentoReq]);
    setAllProcesses([...allProcessesReq]);
  }, [status, allProcessesReq, apostilamentoReq, requesting]);

  useEffect(() => {
    if (!requesting) {
      setRequesting(true);
      props.fetchProcessesBySteps([STEP_HAGUE], currentPage);
    }
    // eslint-disable-next-line
  }, [update]);

  useEffect(() => {
    clearMultipleSelect();
  }, [clearMultipleSelect]);

  return (
    <>
      <Header
        filteredSize={apostilamento.length}
        responseSize={allProcesses.length}
        singleResult={apostilamento.length === 1}
        pageTitle={pageTitles.APOSTILAMENTO}
      />
      <Container maxWidth="xl">
        <Menus />
        {apostilamento
          && status === FULFILLED
          && apostilamento.length === 0
          ? (
            <NoResults
              message="Nenhum processo encontrado"
            />
          ) : apostilamento.map((process) => (
            <Row key={`process${process.id}`} singleResult={apostilamento.length === 1} item={process} setUpdate={setUpdate} />
          ))}
        {status !== FULFILLED && apostilamentoReq.length === 0 && (
          <LoadingComponent />
        )}
        {currentProcess !== null
          && Object.keys(currentClient).length > 0
          && (
            <Dialogs
              context={serviceContext}
              classes={classes}
              setUpdate={setUpdate}
              update={update}
            />
          )}

        <Pagination count={props.totalPages} page={currentPage} onChange={handleCurrentPage} />
      </Container>
    </>
  );
}

Apostilamento.propTypes = {
  apostilamentoReq: array.isRequired,
  status: string,
  update: oneOfType([string, number]),
  setUpdate: func,
  allProcessesReq: array.isRequired,
};

Apostilamento.defaultProps = {
  status: DEFAULT,
  update: '',
  setUpdate: () => { },
};

const mapToStateToProps = (state) => ({
  apostilamentoReq: state.processes.collection,
  allProcessesReq: state.processes.allProcesses,
  status: state.processes.status,
  totalPages: state.processes.totalPages,
});

const actions = {
  fetchProcessesBySteps,
  sendDocumentationEmail,
  processSearch,
  clearResponses,
};

export default connect(mapToStateToProps, actions)(Apostilamento);
