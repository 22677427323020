import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import FillLaterField from 'sales/form/StepOne/FillLaterField';
import { FILL_LATER_TEXT } from 'constants/sales';

function NewPeopleFeguesiaForm({ form, onUpdateField, hasError }) {
  return (
    <>
      <Grid item xs={8}>
        <TextField
          fullWidth
          variant="outlined"
          name="document_number"
          label="Número de assento"
          value={form.document_number}
          onChange={e => onUpdateField('document_number', e.target.value)}
          error={hasError && form.document_number === ''}
        />
        <FillLaterField
          checked={form.document_number === FILL_LATER_TEXT}
          onCheck={e => onUpdateField('document_number', e ? FILL_LATER_TEXT : '')}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          name="crc"
          label="CRC"
          value={form.crc}
          onChange={(e) => onUpdateField('crc', e.target.value)}
          error={hasError && form.crc === ''}
        />
        <FillLaterField
          checked={form.crc === FILL_LATER_TEXT}
          onCheck={(e) => onUpdateField('crc', e ? FILL_LATER_TEXT : '')}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          name="assento_year"
          label="Ano de assento"
          value={form.assento_year}
          onChange={(e) => onUpdateField('assento_year', e.target.value)}
          error={hasError && form.assento_year === ''}
        />
        <FillLaterField
          checked={form.assento_year === FILL_LATER_TEXT}
          onCheck={(e) => onUpdateField('assento_year', e ? FILL_LATER_TEXT : '')}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          name="birthplace"
          label="Naturalidade"
          value={form.birthplace}
          onChange={e => onUpdateField('birthplace', e.target.value)}
          error={hasError && form.birthplace === ''}
        />
        <FillLaterField
          checked={form.birthplace === FILL_LATER_TEXT}
          onCheck={e => onUpdateField('birthplace', e ? FILL_LATER_TEXT : '')}
        />
      </Grid>
    </>
  );
}

export default NewPeopleFeguesiaForm;
