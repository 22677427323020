import React, { useContext } from 'react';
import { APOSTILAMENTO_ROW } from 'constants/service/menus';
import { ServiceContext } from 'contexts/service';
import {
  MenuItem, Menu,
} from '@material-ui/core';

export default function Menus() {
  const serviceContext = useContext(ServiceContext);
  const { openMenu, anchorEl } = serviceContext;
  return (
    <Menu
      anchorEl={anchorEl[APOSTILAMENTO_ROW]}
      open={Boolean(anchorEl[APOSTILAMENTO_ROW])}
      onClose={() => openMenu(false, APOSTILAMENTO_ROW)}
    >
      <MenuItem>Reenviar link de alinhamento</MenuItem>
      <MenuItem>Reenviar e-mail de documentação</MenuItem>
      <MenuItem>Reenviar procuração</MenuItem>
    </Menu>
  )
}