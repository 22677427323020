import { all } from 'redux-saga/effects';

import sessionSagas from './session';
import salesSagas from './sales';
import certificatesSagas from './certificates';
import productsSagas from './product';
import usersSagas from './users';
import searchSaga from './search';
import customerSaga from './customer';
import externalAuth from './externalAuth';
import paymentSaga from './payment';
import documentSagas from './documents';
import contractSagas from './contract';
import stepsSagas from './steps';
import commentsSagas from './comments';
import processesSagas from './processes';
import batchpostSagas from './batchpost';
import emailsSagas from './emails';
import exchangeSagas from './exchange';
import peopleSagas from './people';

export default function* rootSaga() {
  yield all([
    sessionSagas(),
    salesSagas(),
    certificatesSagas(),
    productsSagas(),
    commentsSagas(),
    usersSagas(),
    searchSaga(),
    customerSaga(),
    externalAuth(),
    paymentSaga(),
    documentSagas(),
    contractSagas(),
    stepsSagas(),
    processesSagas(),
    batchpostSagas(),
    exchangeSagas(),
    emailsSagas(),
    peopleSagas(),
  ]);
}
