import * as definitions from 'store/definitions/session';
import {
  LOGGED,
  LOGIN_PENDING,
  NO_LOGGED,
  LOGIN_ERROR,
  LOGOUT,
} from 'constants/session';
import {
  DEFAULT, REQUESTING, REJECTED, FULFILLED,
} from 'constants/common';

function Session(state = {
  status: NO_LOGGED,
  user: {},
  updated: DEFAULT,
}, action) {
  switch (action.type) {
    case definitions.CHECK_AUTH:
    case definitions.CHECK_AUTH_PENDING:
      return {
        status: LOGIN_PENDING,
        user: {},
      };

    case definitions.LOGIN_FULFILLED:
    case definitions.CHECK_AUTH_FULFILLED:
      return {
        status: LOGGED,
        user: action.payload.user,
      };

    case definitions.CHECK_AUTH_REJECTED:
    case definitions.LOGIN_REJECTED:
      return {
        status: LOGIN_ERROR,
        user: {},
      };
    case definitions.CHANGE_PASSWORD:
    case definitions.UPDATE_USER_PROFILE:
      return {
        ...state,
        updated: REQUESTING,
      };

    case definitions.CHANGE_PASSWORD_FULFILLED:
    case definitions.UPDATE_USER_PROFILE_FULFILLED:
      return {
        ...state,
        updated: FULFILLED,
      };

    case definitions.CHANGE_PASSWORD_REJECTED:
    case definitions.UPDATE_USER_PROFILE_REJECTED:
      return {
        ...state,
        updated: REJECTED,
      };

    case definitions.LOGOUT:
      return {
        status: LOGOUT,
        user: {},
      };

    default:
      return state;
  }
}

export default Session;
