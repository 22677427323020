import React from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';
import { string } from 'prop-types';

function LotInnerHeader({ classx }) {
  return (
    <Paper square className={classx} elevation={0}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="body2">
            {'Cliente'}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2">
            {'Pronto em'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2">
            {'Vale postal'}
          </Typography>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </Paper>
  );
}

LotInnerHeader.propTypes = {
  classx: string.isRequired,
};

export default LotInnerHeader;
