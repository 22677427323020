import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as crypto from 'helpers/crypto';
import uniqid from 'uniqid';
import {
  Container,
  Typography,
  Paper,
} from '@material-ui/core';
import { newClientFields } from 'constants/newClient';
import { Logo } from 'global/sources/Icons';
import _ from 'lodash';
import Root from 'global/modules/Root';
import { createCustomer } from 'store/actions/customer';
import {
  SESSION_STORAGE_KEY,
  SESSION_VALIDA_TIME_STORAGE_KEY,
  SESSION_TOKEN_STORAGE_KEY,
} from 'constants/session';
import { CUSTOMER } from 'constants/user';
import ClientForm from './Form';
import { useStyles } from '../style';
import { createFormFromURI } from './functions';

function NewClientForm({
  match, params = match.params, ...props
}) {
  const classes = useStyles();
  const [reload, setReload] = useState(uniqid());
  const [form, setForm] = useState(newClientFields);
  const [linkError, setLinkError] = useState(true);
  const [user, setUser] = useState({});
  const checkUser = (checkU = user) => _.isEmpty(checkU) || checkU.userType === CUSTOMER;
  const [externalUser, setExternalUser] = useState(checkUser());

  const handleRefresh = () => setReload(uniqid());

  const checkForm = (checkedUser) => {
    const external = checkUser(checkedUser);
    const { encrypted } = params;
    if (encrypted) {
      let decrypted = {};
      try {
        decrypted = createFormFromURI(crypto.decode(encrypted, true));
      } catch (error) {
        setLinkError(true);
      }

      if (decrypted instanceof Error) {
        setLinkError(true);
        return;
      }

      if (
        (external
          && (!(decrypted.salesmanId) || !(decrypted.clientId) || !(decrypted.internalId))
        )
        || (
          !external
          && (!(decrypted.clientId) || !(decrypted.internalId))
        )
      ) {
        setLinkError(true);
      } else setLinkError(false);

      if (!external && !(decrypted.salesmanId)) {
        decrypted = { ...decrypted, salesmanId: checkedUser.pipedrive_id || checkedUser.id };
      }
      setForm({ ...form, ...decrypted });
    } else if (!external) setLinkError(false);
  };

  const createCustomerAction = (value) => {
    props.createCustomer(value);
  };

  const checkSession = () => {
    let checkedUser = {};
    let external = true;
    const userSession = localStorage.getItem(SESSION_STORAGE_KEY);
    const sessionToken = localStorage.getItem(SESSION_TOKEN_STORAGE_KEY);
    const validTime = localStorage.getItem(SESSION_VALIDA_TIME_STORAGE_KEY);
    if (validTime !== null || sessionToken !== null || userSession !== null) {
      checkedUser = JSON.parse(userSession);
      external = false;
      setForm({ ...form, salesmanId: checkedUser.pipedrive_id || checkedUser.id });
      setUser(checkedUser);
    }
    checkForm(checkedUser);
    setExternalUser(external);
  };

  useEffect(() => {
    checkSession();
    // eslint-disable-next-line
  }, [reload, params]);

  return (
    <Root setTitle="Novo cliente" paddingTop>
      <Container
        maxWidth="md"
        style={{
          paddingBottom: 1,
        }}
      >
        <Logo className={classes.logoImg} />
        {linkError
          ? (
            <Paper elevation={3}>
              <Typography variant="h5" paragraph>Houve um erro com o seu link</Typography>
              {externalUser
                ? (
                  <Typography>Por favor, entre em contato com seu vendedor.</Typography>
                ) : (
                  <Typography>
                    {'Por favor, confire o link ou preencha o formulário '}
                    <Link to="/novo-cliente" className={classes.link} onClick={handleRefresh}>aqui</Link>
                    {'.'}
                  </Typography>
                )}
            </Paper>
          )
          : (
            <ClientForm
              form={form}
              setForm={setForm}
              externalUser={externalUser}
              classes={classes}
              inputClientId={!_.isEmpty(user)}
              createCustomer={createCustomerAction}
              action={props.action}
              sentValues={params.encrypted}
              errorMessage={props.error}
            />
          )}
      </Container>
    </Root>
  );
}

const mapStateToProps = (state) => ({
  action: state.customerSentForm.action,
  error: state.customerSentForm.error
});

const action = {
  createCustomer,
};

export default connect(mapStateToProps, action)(NewClientForm);
