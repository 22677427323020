import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ServiceContext } from 'contexts/service';
import clsx from 'clsx';
import * as $_ from 'global/functions';
import {
  useDrag,
} from 'react-dnd';
import {
  CheckBox as CheckboxIcon,
  CheckBoxOutlineBlank as CheckboxOutlineIcon,
  MoreVert as MoreVertIcon,
} from '@material-ui/icons';
import Contact from 'global/modules/Contact';
import { itemTypes } from 'constants/batchpost';
import { useStyles } from 'service/style';
import { Paper, Grid, Tooltip, Typography, IconButton } from '@material-ui/core';
import { PROCESS } from 'constants/service';
import { ENVIO_PROCESS_ROW } from 'constants/service/menus';

function ProcessRow({ process, index, handleDropProcess }) {
  const classes = useStyles();
  const serviceContext = useContext(ServiceContext);
  const {
    setMultipleSelect,
    handleShowMoreInfo,
    multipleSelect,
    openMenu,
  } = serviceContext;
  const thisselected = multipleSelect.indexOf(process.id) >= 0;
  const [{ isDragging }, drag] = useDrag({
    item: { name: process.id, type: itemTypes.process, process },
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        return;
      }
      const result = monitor.getDropResult();
      handleDropProcess(item.process, result && result.lot ? result.lot.id : null);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const styles = {
    transform: 'translate3d(0, 0, 0)',
  };

  const postalCost = process.products
    && process.products[0]
    && process.products[0].postal_value / 100;

  return (
    <div ref={drag} style={{ ...styles }}>
      <Paper
        square
        className={
          clsx(
            classes.bodyEnvio,
            index === 0 && 'noMarginTop',
            'clickable',
            thisselected
              ? 'selected'
              : 'regular',
            isDragging
              ? 'dragging'
              : 'notDraggin',
          )
        }
        onClick={(e) => {
          if (e.shiftKey || e.ctrlKey) {
            setMultipleSelect(process.id, e);
          } else {
            handleShowMoreInfo(true, process, e, PROCESS);
          }
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={1} className={classes.centeredGrid}>
            <Tooltip title="Selecionar processo para adicionar a um pacote" disableTouchListener placement="left">
              <IconButton
                aria-label="Selecionar cliente"
                className={classes.flexButton}
                onClick={(e) => setMultipleSelect(process.id, e)}
              >
                {thisselected ? <CheckboxIcon /> : <CheckboxOutlineIcon />}
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={4} className={classes.centeredGrid}>
            <Contact
              item={{ client_id: process.identifier }}
              customer={{ name: process.applicant.name }}
              processTitle={process.products[0] && process.products[0].title}
              displayProcess
              short
            />
          </Grid>
          <Grid item xs={4} className={classes.centeredGrid}>
            <Typography variant="body1">
              {$_.semanticDate(process.readyDate, true)}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.centeredGrid}>
            {$_.toCurrency(postalCost || 0, 'EUR')}
          </Grid>
          <Grid item xs={1} className={classes.centeredGrid} style={{ alignItems: 'flex-end' }}>
            <IconButton
              className={classes.flexButton}
              aria-label="More"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(e) => {
                $_.stopPropagation(e);
                openMenu(true, ENVIO_PROCESS_ROW, e.currentTarget, process);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

ProcessRow.propTypes = {
  process: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleDropProcess: PropTypes.func.isRequired,
};

export default ProcessRow;
