import { SCOPE } from 'constants/service';

export const getDocs = (currentClient, objType) => {
  if (Object.keys(currentClient).length === 0) return [];
  const docs = {};

  const typeScope = () => {
    if (Object.prototype.hasOwnProperty.call(currentClient, 'peoples')) {
      currentClient.peoples.forEach((person) => {
        person.documents.forEach((document) => {
          const addDoc = {
            doc_ref: 'PERSON',
            id: document.id,
            title: document.file_name,
            type: document.mime,
            lastModified: document.updated_at,
            size: document.size,
            src: document.url,
            person_id: person.id,
            person_name: person.name,
          };
          if (docs[person.id]) {
            docs[person.id] = [...docs[person.id], addDoc];
          } else {
            docs[person.id] = [addDoc];
          }
        });
      });
    }
    if (Object.prototype.hasOwnProperty.call(currentClient, 'processes') && Object.prototype.hasOwnProperty.call(currentClient, 'client_id')) {
      currentClient.processes.forEach((theProcess) => {
        theProcess.documents.forEach((document) => {
          const addDoc = {
            doc_ref: 'PROCESS',
            id: document.id,
            title: document.file_name,
            type: document.mime,
            lastModified: document.updated_at,
            size: document.size,
            src: document.url,
            person_id: theProcess.applicant.id,
            person_name: theProcess.applicant.name,
          };
          if (docs[theProcess.applicant.id]) {
            docs[theProcess.applicant.id] = [...docs[theProcess.applicant.id], addDoc];
          } else {
            docs[theProcess.applicant.id] = [addDoc];
          }
        });
      });
    }
  };

  const typeProcess = () => {
    if (Object.prototype.hasOwnProperty.call(currentClient, 'applicant') && Object.prototype.hasOwnProperty.call(currentClient.applicant, 'documents')) {
      currentClient.applicant.documents.forEach((document) => {
        const addDoc = {
          doc_ref: 'PROCESS',
          id: document.id,
          title: document.file_name,
          type: document.mime,
          lastModified: document.updated_at,
          size: document.size,
          src: document.url,
          person_id: currentClient.applicant.id,
          person_name: currentClient.applicant.name,
        };
        if (docs[currentClient.applicant.id]) {
          docs[currentClient.applicant.id] = [...docs[currentClient.applicant.id], addDoc];
        } else {
          docs[currentClient.applicant.id] = [addDoc];
        }
      });
    }
  };
  switch (objType) {
    case SCOPE:
      typeScope();
      break;
    default:
      typeProcess();
      break;
  }
  if (Object.prototype.hasOwnProperty.call(currentClient, 'hosts')) {
    currentClient.hosts.forEach((person) => {
      if (!docs[person.id]) {
        if (Object.prototype.hasOwnProperty.call(person, 'documents')) {
          person.documents.forEach((document) => {
            const addDoc = {
              doc_ref: 'PERSON',
              id: document.id,
              title: document.file_name,
              type: document.mime,
              lastModified: document.updated_at,
              size: document.size,
              src: document.url,
              person_id: person.id,
              person_name: person.name,
            };
            if (docs[person.id]) {
              docs[person.id] = [...docs[person.id], addDoc];
            } else {
              docs[person.id] = [addDoc];
            }
          });
        }
      }
    });
  }
  return docs;
};

export const getPossiblePeople = (currentClient, objType) => {
  const possiblePeople = {};

  const typeProcess = () => {
    if (Object.prototype.hasOwnProperty.call(currentClient, 'applicant') && Object.prototype.hasOwnProperty.call(currentClient.applicant, 'documents')) {
      if (!possiblePeople[currentClient.applicant.id]) {
        possiblePeople[currentClient.applicant.id] = currentClient.applicant.name;
      }
    }
  };
  const typeScope = () => {
    if (Object.prototype.hasOwnProperty.call(currentClient, 'peoples')) {
      currentClient.peoples.forEach((person) => {
        if (!possiblePeople[person.id]) {
          possiblePeople[person.id] = person.name;
        }
      });
    }
    if (Object.prototype.hasOwnProperty.call(currentClient, 'processes') && Object.prototype.hasOwnProperty.call(currentClient, 'client_id')) {
      currentClient.processes.forEach((theProcess) => {
        if (!possiblePeople[theProcess.applicant.id]) {
          possiblePeople[theProcess.applicant.id] = theProcess.applicant.name;
        }
      });
    }
  };
  switch (objType) {
    case SCOPE:
      typeScope();
      break;
    default:
      typeProcess();
      break;
  }
  if (Object.prototype.hasOwnProperty.call(currentClient, 'hosts')) {
    currentClient.hosts.forEach((person) => {
      if (!possiblePeople[person.id]) {
        possiblePeople[person.id] = person.name;
      }
    });
  }
  return possiblePeople;
};
