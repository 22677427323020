import React from 'react';
import * as imports from 'objects/imports';
import { Paper, Typography, Grid } from '@material-ui/core';
import { readableBRPhoneNumbers } from 'helpers';

function CustomerInfo({ customer }) {
  const findCountry = () => customer.country != null ? imports.countries.find(a => a.alpha2Code === customer.country != null ? customer.country : 'BR').translations.br : '';
  let phoneNumber = '';
  if (customer && customer.phone) phoneNumber = readableBRPhoneNumbers(customer.phone);
  return (
    <Paper elevation={3}>
      <Typography variant="h5" color="textPrimary" paragraph>Informações do contratante</Typography>
      <Grid container spacing={4}>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1"><strong>Nome:</strong> {customer.name}</Typography>
              <Typography variant="subtitle1"><strong>E-mail:</strong> {customer.email}</Typography>
              <Typography variant="subtitle1"><strong>Telefone:</strong> {phoneNumber}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1"><strong>Endereço</strong></Typography>
              <Typography variant="subtitle1">{customer.address1}, {customer.address2}{customer.address3 ? ` ${customer.address3} ` : ''}/ {customer.address4}</Typography>
              <Typography variant="subtitle1">{customer.city} / {customer.state}</Typography>
              <Typography variant="subtitle1">{findCountry()} {customer.zip_code || ''}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default CustomerInfo;