export const SESSION_STORAGE_KEY = '_userSession';
export const SESSION_TOKEN_STORAGE_KEY = '_accessToken';
export const SESSION_VALIDA_TIME_STORAGE_KEY = '_sessionValidTime';

export const NO_LOGGED = 'NO_LOGGED';
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGGED = 'LOGGED';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';

export const NO_EXTERNAL_AUTH = 'NO_EXTERNAL_AUTH';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';
export const PENDING = 'PENDING';

export const SERVER_TIMEZONE = 'Europe/London';
