import { SHOW, HIDE } from 'store/definitions/notifications';
import { NOTIFICATION_TYPE_INFO } from 'constants/notification';
import { EMPTY } from 'constants/common';

function Notifications(state = {
  variant: NOTIFICATION_TYPE_INFO,
  message: EMPTY,
  open: false,
}, action) {
  switch (action.type) {
    case SHOW:
      return {
        variant: action.payload.variant,
        message: action.payload.message,
        open: true,
      };
    case HIDE:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
}

export default Notifications;
