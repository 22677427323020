export const EMPTY = '';
export const LEGAL_AGE = 18;
export const DAYS_IN_WEEK = 7;
export const DAYS_IN_MONTH = 30;
export const DAYS_IN_YEAR = 365;

export const DEFAULT = 'DEFAULT';
export const REQUESTING = 'REQUESTING';
export const REJECTED = 'REJECTED';
export const FULFILLED = 'FULFILLED';
