import React from 'react';
import 'date-fns';
import { useStyles } from './Products/styles';
import { Container, Grid, Typography, InputBase, IconButton, Paper, MenuItem, FormControl, InputLabel, TextField } from '@material-ui/core';
import { Close as CloseIcon, Search as SearchIcon } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { 
    paymentMethodTitles, 
    paymentStatusTitles, 
    PAYMENT_BANK_SLIP, 
    PAYMENT_BANK_TRANSFER, 
    PAYMENT_CASH, 
    PAYMENT_CREDIT_CARD, 
    PAYMENT_CREDIT_CARD_RECURRENT, 
    PAYMENT_ELECTRONIC_TERMINAL, 
    PAYMENT_ITAU_BANK_SLIP, 
    PAYMENT_MAXI_PAGO 
} from 'constants/sales';

const PortuguesesPageHeader = ({
    title, 
    searchValue, 
    onSearch, 
    checked,
    onChangeChecked,
    docStatus,
    onChangeDocStatus,
    clientStatus,
    onChangeClientStatus,
    searchStatus,
    onChangeSearchStatus,
    hideCheckField = false,
    ...props
}) => {
    const classes = useStyles(props);
    
    const clearSearchValue = () => onSearch('');

    return(
        <Container maxWidth="xl">
            <Grid container spacing={2} className={classes.subMenuHolder}>
                <Grid item xs={12} sm={hideCheckField ? 4 : 2}>
                    <Typography variant="h5" color="textSecondary">{title}</Typography>
                </Grid>
                <Grid item xs={12} sm={2} className={classes.btnholder}>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Buscar..."
                            onChange={(e) => onSearch(e.target.value)}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            value={searchValue}
                            endAdornment={(
                                <IconButton 
                                    onClick={clearSearchValue} 
                                    classes={{ root: classes.closeBtnTiny }} 
                                    style={{ opacity: Boolean(searchValue) ? 1 : 0 }} 
                                    size="small"
                                >
                                    <CloseIcon fontSize="small" classes={{ root: classes.closeBtnTinyIcon }} />
                                </IconButton>
                            )}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <div className={classes.search} style={{width: '100%', padding: 5}}>
                        <TextField
                            id="select-customer-status-filter"
                            value={clientStatus}
                            onChange={onChangeClientStatus}
                            fullWidth
                            select
                        >
                            <MenuItem value="none">Todos os Status Cliente</MenuItem>
                            <MenuItem value="NORMAL">Normal</MenuItem>
                            <MenuItem value="SENSITIVE">Sensível</MenuItem>
                            <MenuItem value="AMBASSADOR">Embaixador</MenuItem>
                            <MenuItem value="CRITICAL">Crítico</MenuItem>
                            <MenuItem value="COMPLAINED">Reclamou</MenuItem>
                        </TextField>
                    </div>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <div className={classes.search} style={{width: '100%', padding: 5}}>
                        <TextField
                            id="select-doc-status-filter"
                            value={searchStatus}
                            onChange={onChangeSearchStatus}
                            fullWidth
                            select
                        >
                            <MenuItem value="none">Todos Status de Busca</MenuItem>
                            <MenuItem value="WAIT_CLIENT_DATA">Aguardando dados do cliente</MenuItem>
                            <MenuItem value="SEARCH_ASSENTO">Buscando assento</MenuItem>
                            <MenuItem value="ASSENTO_FOUND">Assento localizado</MenuItem>
                            <MenuItem value="NEGATIVE_CONSERVATORY">Negativa da conservatória</MenuItem>
                            <MenuItem value="SEARCH_RECORD">Buscando registro</MenuItem>
                            <MenuItem value="RECORD_FOUND">Registro localizado</MenuItem>
                            <MenuItem value="REPRODUCTION_REGISTRATION">Registro em reprodução</MenuItem>
                            <MenuItem value="RECORD_PAID">Registro pago</MenuItem>
                            <MenuItem value="RECORD_WITH_JORDANA">Registro na jordana</MenuItem>
                            <MenuItem value="RECORD_ON_BOTAFOGO">Registro em botafogo</MenuItem>
                        </TextField>
                    </div>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <div className={classes.search} style={{width: '100%', padding: 5}}>
                        <TextField
                            id="select-doc-status-filter"
                            value={docStatus}
                            onChange={onChangeDocStatus}
                            fullWidth
                            select
                        >
                            <MenuItem value="none">Todos Status documentação</MenuItem>
                            <MenuItem value={1}>Status OK</MenuItem>
                            <MenuItem value={0}>Status Não OK</MenuItem>
                        </TextField>
                    </div>
                </Grid>
                {!hideCheckField && ( <Grid item xs={12} sm={2}>
                    <div className={classes.search} style={{width: '100%', padding: 5}}>
                        <TextField
                            id="select-confirmed-filter"
                            value={checked}
                            onChange={onChangeChecked}
                            fullWidth
                            select
                        >
                            <MenuItem value="none">Todos os portugueses</MenuItem>
                            <MenuItem value="CHECKED">Apenas os verificados</MenuItem>
                            <MenuItem value="NOT_CHECKED">Apenas os não verificados</MenuItem>
                            <MenuItem value="NEED_REVIEW">Aguardando Revisão</MenuItem>
                        </TextField>
                    </div>
                </Grid>
                )}
            </Grid>
        </Container>
    );
}

export default PortuguesesPageHeader;

