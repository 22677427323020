import * as definitions from 'store/definitions/steps';

function Steps(state = {
  collection: [],
}, action) {
  switch (action.type) {
    case definitions.FETCH_ALL_STEPS_FULFILLED:
      return {
        collection: action.payload
      }
    default:
      return state;
  }
}

export default Steps;
