import React from 'react';
import { Paper, Typography } from '@material-ui/core';

function Citizens({ portugueses }) {
  return (
    <Paper elevation={3}>
      <Typography variant="h5" gutterBottom>{`Portugu${portugueses.length > 1 ? 'eses' : 'ês'}`}</Typography>
      {portugueses.map(pt => (<Typography key={pt.id}>{pt.name}</Typography>))}
    </Paper>
  )
}

export default Citizens;