import React from 'react';

export const SalesContext = React.createContext({
  currentStep: 0,
  sale: {},
  stepsOk: {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
  },
  phases: {},
  paymentConfirmed: false,
  setStep: step => { },
  setSaleKey: (key, value) => { },
  handleStepOk: (step, value) => { },
  setPaymentConfirmed: (value) => { },
  setPhases: (phases) => { },
});
