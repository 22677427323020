/* eslint-disable max-lines */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { object, bool, string } from 'prop-types';
import { ServiceContext } from 'contexts/service';
import uniqid from 'uniqid';
import { connect } from 'react-redux';
import {
  Paper, IconButton, Button, Grid, Typography,
} from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import Contact from 'global/modules/Contact';
import * as $_ from 'global/functions';
import clsx from 'clsx';
import GridDocs from 'service/modules/GridDocs';
import { PROCESS } from 'constants/service';
import { LoadingComponent } from 'global/modules/Loading';
import { showConfirmation } from 'store/actions/confirmations';
import { DOCUMENTACAO_ROW } from 'constants/service/menus';
import { apiBaseUrl } from 'api';
import Axios from 'axios';
import { PROCESS_PATH } from 'api/paths';
import { confirmDocumentsDocumentation } from 'store/actions/documents';
import { localeDate } from 'helpers';
import { useStyles } from '../style';
import ExpandContent from './ExpandContent';
import { FULFILLED, REJECTED } from 'constants/common';

function Row({ item, singleResult, documentsStatus, ...props }) {
  const classes = useStyles();
  const [process, setProcess] = useState({ ...item, documentationDocs: [], allArrived: false });
  const [toConfirm, setToConfirm] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [posting, setPosting] = useState(false);
  const [refetch, setRefetch] = useState(uniqid());
  const [cascade, setCascade] = useState(uniqid());

  const serviceContext = useContext(ServiceContext);
  const {
    setDocsShown,
    handleShowMoreInfo,
    handleExpanded,
    openMenu,
    expanded,
    handleConfirmEndStepDialog,
  } = serviceContext;
  const handleConfirmEndStep = (e) => {
    $_.stopPropagation(e);
    handleConfirmEndStepDialog(process);
  };
  const docsHaventDelivered = process.documentationDocs.filter((a) => !a.delivered).length;
  const allChecked = toConfirm.length === docsHaventDelivered;

  const buttonDisabled = toConfirm.length === 0;
  const handleConfirmArrived = (e) => {
    $_.stopPropagation(e);
    const confirmArrived = () => {
      props.confirmDocumentsDocumentation(process.id, toConfirm);
      setPosting(true);
    };
    if (!posting) {
      const docsLength = process.documentationDocs
        .filter((a) => toConfirm.indexOf(a.id) >= 0)
        ? process.documentationDocs.filter((a) => toConfirm.indexOf(a.id) >= 0).length
        : 0;
      props.showConfirmation(
        {
          title: 'Confirmar que documentos chegaram',
          message: (
            <>
              <Typography color="textPrimary" variant="subtitle2">
                {`Confirmar que o${docsLength > 1 ? 's' : ''} documento${docsLength > 1 ? 's' : ''}`}
              </Typography>
              <ul>
                {process.documentationDocs.filter((a) => toConfirm.indexOf(a.id) >= 0).map((a) => (
                  <li key={uniqid()} style={{ marginLeft: 16 }}><strong>{a.name}</strong></li>
                ))}
              </ul>
              <Typography color="textPrimary" paragraph>
                {`de ${process.applicant.name} cheg${docsLength > 1 ? 'aram' : 'ou'}?`}
              </Typography>
              <Typography color="error">Atenção:</Typography>
              <Typography color="textPrimary">
                {' Com esta ação, será enviado um email ('}
                <strong>{process.sales_scope.customer.email}</strong>
                {`) para o cliente confirmando o recebimento do${docsLength > 1 ? 's' : ''} documento${docsLength > 1 ? 's' : ''} listado${docsLength > 1 ? 's' : ''}.`}
              </Typography>
            </>
          ),
        },
        {
          confirm: 'Confirmar',
          cancel: 'Cancelar',
        },
        {
          func: confirmArrived,
          payload: {},
        },
      );
    }
  };
  const handleCheckDocs = () => {
    setToConfirm(allChecked
      ? []
      : process.documentationDocs.filter((a) => !a.delivered).map((a) => a.id));
  };
  const setExpand = (id, e) => {
    $_.stopPropagation(e);
    setToConfirm([]);
    handleExpanded(id);
  };
  const thisexpanded = expanded === process.id;
  const showArrowDown = process.documentationDocs.length > 0;

  useEffect(() => {
    if (posting && (documentsStatus === FULFILLED || documentsStatus === REJECTED)) {
      setPosting(false);
      setRefetch(uniqid());
    }
  }, [documentsStatus, posting]);

  useEffect(() => {
    const pathService = `${PROCESS_PATH}/${process.id}/documents`;
    Axios
      .get(`${apiBaseUrl}/${pathService}`)
      .then((res) => {
        const { data } = res;
        setProcess({
          ...process,
          notApplicableDocuments: data.documents && data.documents.length > 0 ? data.documents[0].not_applicable_documents : [],
          documentationDocs: data.documents ? data.documents.filter((a) => !a.company_responsible) : [],
          allArrived: data.all_delivered,
        });
        setFetched(true);
      })
      .catch(() => setFetched(false));
    // eslint-disable-next-line
  }, [item, refetch]);

  useEffect(() => {
    setCascade(uniqid());
  }, [process]);

  return (
    <>
      <Paper
        key={`${process.id}`}
        className={clsx(classes.body, thisexpanded ? 'expanded' : 'regular', 'clickable', singleResult ? 'default' : 'documentacao')}
      >
        <Grid
          onClick={(e) => handleShowMoreInfo(true, process, e, PROCESS)}
          className={clsx(classes.pointer)}
          container
          spacing={2}
        >
          {singleResult || !showArrowDown
            ? null
            : (
              <IconButton
                onClick={(e) => setExpand(process.id, e)}
                className={clsx(classes.floatingButtons, classes.floatingLeft)}
                aria-label="Expandir"
              >
                <ExpandMoreIcon className={clsx(classes.expandIcon, thisexpanded ? 'expanded' : 'regular')} />
              </IconButton>
            )}
          <IconButton
            aria-label={`Ações de menu relacionadas ao processo de ${process.applicant.name}`}
            aria-controls="long-menu"
            aria-haspopup="true"
            className={clsx(classes.floatingButtons, classes.floatingRight)}
            onClick={(e) => {
              $_.stopPropagation(e);
              openMenu(true, DOCUMENTACAO_ROW, e.currentTarget, process);
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Grid item xs={2} className={classes.centeredGrid}>
            <Contact
              item={{ client_id: process.identifier }}
              customer={{ name: process.applicant.name }}
              short
            />
          </Grid>
          <Grid item xs={4} className={classes.centeredGrid}>
            <Grid container spacing={2}>
              <Grid item xs={4} className={classes.centeredGrid}>
                <Typography variant="body1">{process.products[0] && process.products[0].title}</Typography>
              </Grid>
              <Grid item xs={4} className={classes.centeredGrid}>
                <Typography variant="body1">
                  {process.sales_scope.confirmed_date ? localeDate(process.sales_scope.confirmed_date) : 'Ainda não iniciado'}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.centeredGrid}>
                <Typography variant="body1">
                  {process.sales_scope.align_date ? localeDate(process.sales_scope.align_date) : 'Ainda não alinhado'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} className={classes.centeredGrid}>
            <Typography variant="body1">
              {process.align_comments && process.align_comments.length > 0
                ? $_.limitChars(process.align_comments[process.align_comments.length - 1].text, 45)
                : (
                  'Nenhuma observação encontrada.'
                )}
            </Typography>
          </Grid>
          <GridDocs
            currentClient={process}
            xs={1}
            scopeId={process.sales_scope.id}
            clientName={process.applicant.name}
            setDocsShown={setDocsShown}
            docType={PROCESS}
          />
          {!fetched
            ? (
              <Grid item xs={3} className={classes.centeredGrid}>
                <LoadingComponent height={40} margin={0} />
              </Grid>
            ) : (
              <Grid item xs={3} className={classes.centeredGrid}>
                {process.allArrived
                  ? (
                    <Button
                      onClick={(e) => handleConfirmEndStep(e)}
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      {'Documentos completos'}
                    </Button>
                  ) : (
                    <Button
                      onClick={(e) => handleConfirmArrived(e)}
                      variant={buttonDisabled ? 'outlined' : 'contained'}
                      color="primary"
                      disabled={buttonDisabled}
                      classes={{ containedPrimary: classes.alertButton }}
                      fullWidth
                    >
                      {toConfirm.length < 2 ? 'Documento chegou' : 'Documentos chegaram'}
                    </Button>
                  )}
              </Grid>
            )}
        </Grid>
        <ExpandContent
          classes={classes}
          process={process}
          handleCheckDocs={handleCheckDocs}
          setToConfirm={setToConfirm}
          toConfirm={toConfirm}
          thisexpanded={thisexpanded || singleResult}
          allChecked={allChecked}
          setRefetch={setRefetch}
          cascade={cascade}
        />
      </Paper>
    </>
  );
}

Row.defaultProps = {
  item: {},
  singleResult: false,
  documentsStatus: '',
};

Row.propTypes = {
  item: object,
  singleResult: bool,
  documentsStatus: string,
};

const actions = {
  showConfirmation,
  confirmDocumentsDocumentation,
};

const mapStateToProps = (state) => ({
  documentsStatus: state.documents.status,
});

export default connect(mapStateToProps, actions)(Row);
