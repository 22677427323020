import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { changePassword } from 'store/actions/session';
import { UserContext } from 'contexts/user';
import { Grid, Paper, Typography, TextField, Button, InputAdornment, IconButton } from '@material-ui/core';
import zxcvbn from 'zxcvbn';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { NOTIFICATION_TYPE_ERROR } from 'constants/notification';
import { showNotification } from 'store/actions/notifications';
import { FULFILLED } from 'constants/common';
import { prepareTosendPassword, checkPassword, MINIMUM_STRENGTH } from './functions';
import { useStyles } from './style';
import clsx from 'clsx';

function PasswordForm({ sizing, updated, ...props }) {
  const classes = useStyles(props);
  const { user } = useContext(UserContext);
  const formObj = { password: '', passwordConfirmation: '' };
  const [form, setForm] = useState(formObj);
  const [strength, setStrength] = useState(0);
  const [equal, setEqual] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const equalError = !equal && strength >= MINIMUM_STRENGTH && form.passwordConfirmation !== '';
  const enableSubmit = equal && strength >= MINIMUM_STRENGTH && form.passwordConfirmation !== '';

  const handleSubmit = (event) => {
    event.preventDefault();
    const formsend = prepareTosendPassword(form);
    checkPassword(formsend, strength)
      .then(() => props.changePassword(user.id, formsend))
      .catch((error) => props.showNotification(NOTIFICATION_TYPE_ERROR, error.message));
  };

  const handleCheckEqual = (newVal) => {
    const val = newVal || form.passwordConfirmation;
    if (strength >= MINIMUM_STRENGTH) {
      if (form.password === val) {
        setEqual(true);
      } else setEqual(false);
    } else {
      setForm({ ...form, passwordConfirmation: '' });
      setEqual(false);
    }
  };

  const handleChange = (name, value) => {
    if (name === 'password') {
      setStrength(zxcvbn(value).score);
    } else handleCheckEqual(value);
    setForm({ ...form, [name]: value });
  };

  const unequalPasswords = <Typography component="span" color="error">As senhas não são iguais</Typography>;

  const strengthText = () => {
    if (form.password !== '') {
      switch (strength) {
        case 4:
        case 3:
          return <Typography component="span" color="primary">Senha forte</Typography>;
        case 2:
          return <Typography component="span" color="primary">Senha boa</Typography>;
        case 1:
        case 0:
          return <Typography component="span" color="error">Senha fraca</Typography>;
        default:
          return '';
      }
    } else return '';
  };

  const handleShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    handleCheckEqual();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (updated === FULFILLED) {
      setForm(formObj);
    }
    // eslint-disable-next-line
  }, [updated]);

  return (
    <Grid item {...sizing}>
      <Paper
        elevation={1}
        className={classes.paper}
      >
        <Typography
          variant="h6"
          gutterBottom
          className={classes.header}
        >
          Acesso
        </Typography>
        <Grid container spacing={2}>
          <form onSubmit={handleSubmit} style={{ width: '100%', margin: '0 8px' }}>
            <Grid item xs={12} className={classes.formRow}>
              <TextField
                fullWidth
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                name="password"
                label="Nova senha"
                value={form.password}
                required
                helperText={strengthText()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Ver senha"
                        onClick={() => handleShowPassword()}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handleChange('password', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} className={classes.formRow}>
              <TextField
                fullWidth
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                name="passwordConfirmation"
                label="Repita a senha"
                value={form.passwordConfirmation}
                error={equalError}
                helperText={equalError ? unequalPasswords : ''}
                disabled={strength < MINIMUM_STRENGTH}
                required
                onChange={(e) => handleChange('passwordConfirmation', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} className={clsx(classes.formRow, 'btnHolder')}>
              <Typography align="right">
                <Button
                  variant={enableSubmit ? 'contained' : 'outlined'}
                  color="primary"
                  type="submit"
                  disabled={!enableSubmit}
                >
                  Salvar alterações
                </Button>
              </Typography>
            </Grid>
          </form>
        </Grid>
      </Paper>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  updated: state.session.updated,
});

export default connect(mapStateToProps, { changePassword, showNotification })(PasswordForm);
