import React from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogContent, Grid, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Comments from 'global/Comments';
import { COMMENT_TYPE_ALL } from 'constants/common/comments';
import { STEP_ALIGNMENT } from 'constants/process';

function Dialogs({ classes, context, setUpdate, update, ...props}) {
  const { openComments, setOpenComments, currentClient } = context;

  const getStepId = () => {
    const stepObj = props.steps.find(a => a.name === STEP_ALIGNMENT);
    return stepObj ? stepObj.id : null;
  }

  return (
    <Dialog
      open={openComments}
      onClose={setOpenComments}
      fullWidth
      maxWidth="md"
      scroll="body">
      <DialogContent className={classes.dialogMoreInfo}>
        {Object.keys(currentClient).length > 0 ?
          <Grid container spacing={2} className={classes.gridContainer}>
            <IconButton onClick={() => setOpenComments()} className={classes.closeBtn} >
              <Close />
            </IconButton>
            <Grid item xs={12} md={8} className={classes.beforeMdPadding}>
              <Typography variant="h5">{currentClient.customer.name}</Typography>
              <Typography variant="h6" color="textSecondary">{currentClient.client_id}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Comments
                allowed
                step={1}
                whenThereAreResults={<Typography variant="h6" paragraph>Comunicação interna</Typography>}
                label="Escreva sua observação"
                header="Fazer observação"
                commentList={currentClient.processes[0].comments}
                setUpdate={setUpdate}
                update={update}
                salesId={currentClient.id}
                stepId={getStepId()}
                commentType={COMMENT_TYPE_ALL}
              />
            </Grid>
          </Grid>
          : null}
      </DialogContent>
    </Dialog>
  );
}

const actions = {

}

const mapStateToProps = (state) => ({
  steps: state.steps.collection,
});

export default connect(mapStateToProps, actions)(Dialogs);
