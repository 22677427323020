import React from 'react';
import 'date-fns';
import { useStyles } from './Products/styles';
import { Container, Grid, Typography, InputBase, IconButton, Paper, MenuItem, FormControl, InputLabel, TextField, Select, Chip, Input } from '@material-ui/core';
import { Close as CloseIcon, Search as SearchIcon } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
    paymentMethodTitles,
    paymentStatusTitles,
    PAYMENT_BANK_SLIP,
    PAYMENT_BANK_TRANSFER,
    PAYMENT_CASH,
    PAYMENT_CREDIT_CARD,
    PAYMENT_CREDIT_CARD_RECURRENT,
    PAYMENT_ELECTRONIC_TERMINAL,
    PAYMENT_ITAU_BANK_SLIP,
    PAYMENT_MAXI_PAGO,
    PAYMENT_BARTE
} from 'constants/sales';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PaymentPageHeader = ({
    title,
    searchValue,
    onSearch,
    startDate,
    onChangeStartDate,
    endDate,
    onChangeEndDate,
    status,
    onChangeStatus,
    confirmed,
    paymentMethod,
    checked,
    onChangeConfirmed,
    onChangePaymentMethod,
    onChangeChecked,
    ...props
}) => {
    const classes = useStyles(props);

    const clearSearchValue = () => onSearch('');

    return(
        <Container maxWidth="xl">
            <Grid container spacing={2} className={classes.subMenuHolder}>
                <Grid item xs={12} sm={7}>
                    <Typography variant="h5" color="textSecondary">{title}</Typography>
                </Grid>
                <Grid item xs={12} sm={5} className={classes.btnholder}>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Buscar..."
                            onChange={(e) => onSearch(e.target.value)}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            value={searchValue}
                            endAdornment={(
                                <IconButton
                                    onClick={clearSearchValue}
                                    classes={{ root: classes.closeBtnTiny }}
                                    style={{ opacity: Boolean(searchValue) ? 1 : 0 }}
                                    size="small"
                                >
                                    <CloseIcon fontSize="small" classes={{ root: classes.closeBtnTinyIcon }} />
                                </IconButton>
                            )}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Paper style={{paddingBottom: 20}}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around">
                            <Grid xs={12} sm={2}>
                                    <TextField
                                        label="Meio de pagamento"
                                        style={{marginTop: 5}}
                                        id="select-status-filter"
                                        placeholder="Meio de pagamento"
                                        value={paymentMethod}
                                        onChange={onChangePaymentMethod}
                                        fullWidth
                                        select
                                    >
                                        <MenuItem value={null}>
                                            Todos os meios de pagamento
                                        </MenuItem>
                                        <MenuItem value={PAYMENT_CASH}>
                                            {paymentMethodTitles[PAYMENT_CASH]}
                                        </MenuItem>
                                        <MenuItem value={PAYMENT_BANK_TRANSFER}>
                                            {paymentMethodTitles[PAYMENT_BANK_TRANSFER]}
                                        </MenuItem>
                                        <MenuItem value={PAYMENT_BANK_SLIP}>
                                            {paymentMethodTitles[PAYMENT_BANK_SLIP]}
                                        </MenuItem>
                                        <MenuItem value={PAYMENT_ITAU_BANK_SLIP}>
                                            {paymentMethodTitles[PAYMENT_ITAU_BANK_SLIP]}
                                        </MenuItem>
                                        <MenuItem value={PAYMENT_CREDIT_CARD}>
                                            {paymentMethodTitles[PAYMENT_CREDIT_CARD]}
                                        </MenuItem>
                                        <MenuItem value={PAYMENT_MAXI_PAGO}>
                                            {paymentMethodTitles[PAYMENT_MAXI_PAGO]}
                                        </MenuItem>
                                        <MenuItem value={PAYMENT_BARTE}>
                                            {paymentMethodTitles[PAYMENT_BARTE]}
                                        </MenuItem>
                                        <MenuItem value={PAYMENT_ELECTRONIC_TERMINAL}>
                                            {paymentMethodTitles[PAYMENT_ELECTRONIC_TERMINAL]}
                                        </MenuItem>
                                        <MenuItem value={PAYMENT_CREDIT_CARD_RECURRENT}>
                                            {paymentMethodTitles[PAYMENT_CREDIT_CARD_RECURRENT]}
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid xs={12} sm={2}>
                                    <FormControl style={{marginTop: 5, width: '100%'}}>
                                        <InputLabel id="demo-mutiple-chip-label">Status</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-chip-label"
                                            id="demo-mutiple-chip"
                                            label="Status"
                                            multiple
                                            fullWidth
                                            value={status}
                                            onChange={onChangeStatus}
                                            input={<Input id="select-multiple-chip" />}
                                            renderValue={(selected) => (
                                                <div className={classes.chips}>
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={paymentStatusTitles(value)} className={classes.chip} />
                                                    ))}
                                                </div>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value="NEW">{paymentStatusTitles('NEW')}</MenuItem>
                                            <MenuItem value="OPEN">{paymentStatusTitles('OPEN')}</MenuItem>
                                            <MenuItem value="CANCELED">{paymentStatusTitles('CANCELED')}</MenuItem>
                                            <MenuItem value="OVERDUE">{paymentStatusTitles('OVERDUE')}</MenuItem>
                                            <MenuItem value="PAID">Pago</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <KeyboardDatePicker
                                    label="Data Inicial"
                                    style={{marginTop: 5}}
                                    disableToolbar
                                    format="dd/MM/yyyy"
                                    id="date-picker-inline"
                                    value={startDate}
                                    inputVariant="standard"
                                    mask="__/__/____"
                                    maxDate={endDate}
                                    onChange={onChangeStartDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                <KeyboardDatePicker
                                    label="Data Final"
                                    style={{marginTop: 5}}
                                    disableToolbar
                                    format="dd/MM/yyyy"
                                    id="date-picker-inline"
                                    minDate={startDate}
                                    value={endDate}
                                    inputVariant="standard"
                                    onChange={onChangeEndDate}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                />
                                <Grid xs={12} sm={2}>
                                    <TextField
                                        label="Ganho"
                                        style={{marginTop: 5}}
                                        id="select-confirmed-filter"
                                        value={confirmed}
                                        onChange={onChangeConfirmed}
                                        fullWidth
                                        select
                                    >
                                        <MenuItem value={false}>Todas as vendas</MenuItem>
                                        <MenuItem value={true}>Apenas com ganho</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid xs={12} sm={2}>
                                    <TextField
                                        label="Verificadas"
                                        style={{marginTop: 5}}
                                        id="select-confirmed-filter"
                                        value={checked}
                                        onChange={onChangeChecked}
                                        fullWidth
                                        select
                                    >
                                        <MenuItem value={null}>Todas as vendas</MenuItem>
                                        <MenuItem value={0}>Não verificadas</MenuItem>
                                        <MenuItem value={1}>Apenas verificadas</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default PaymentPageHeader;
