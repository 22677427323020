import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { fetchBatchPost } from 'store/actions/batchpost';
import { DELIVERED_BATCHPOST, SENT_BATCHPOST } from 'constants/batchpost';
import Header from './Header';
import Row from './Row';

function Historic({ fetch, collection, update, isIn }) {
  useEffect(() => {
    if (isIn) {
      fetch([DELIVERED_BATCHPOST, SENT_BATCHPOST]);
    }
  }, [fetch, isIn, update]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Header lotsSize={collection.length} />
        {collection && collection.map((lot) => (
          <Row key={`sentLot${lot.id}`} lot={lot} />
        ))}
      </Grid>
    </Grid>
  );
}

const actions = {
  fetch: fetchBatchPost,
};

Historic.propTypes = {
  fetch: PropTypes.func.isRequired,
  collection: PropTypes.array.isRequired,
  isIn: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  collection: state.batchpost.collection,
});

export default connect(mapStateToProps, actions)(Historic);
