import React from 'react';
import ReactExport from 'react-export-excel';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { toCurrency } from 'global/functions';
import moment from 'moment';
import { CloudDownloadOutlined } from '@material-ui/icons';
import { useStyles } from 'service/style';

const { ExcelFile } = ReactExport;
const { ExcelSheet, ExcelColumn } = ExcelFile;

function ExportTable({ processes, lot, totalValue }) {
  const classes = useStyles();
  const dataSet1 = [
    {
      id: lot.id,
      tracking: lot.tracking_code || 'indefinido',
      totalValue: totalValue || 'indefinido',
    },
  ];
  const dataSet2 = processes.map((p) => ({
    identifier: p.identifier || 'indefinido',
    applicant: p && p.applicant ? p.applicant.name : 'indefinido',
    postalValue: p && p.products[0] && p.products[0].postal_value ? toCurrency(p.products[0].postal_value / 100, 'EUR') : 'indefinido',
  }));
  return (
    <ExcelFile
      filename={`Pacote ${lot.tracking_code || lot.id} - Controle de gastos - ${moment(lot.sent_date).format('MMMM')}`}
      element={(
        <Button
          variant="contained"
          classes={{
            containedPrimary: classes.alertButton,
          }}
          fullWidth
          color="primary"
        >
          {'Baixar .xlsx'}
          <CloudDownloadOutlined fontSize="small" className={classes.iconMargin} />
        </Button>
      )}
    >
      <ExcelSheet data={dataSet1} name={`Pacote - ${moment(lot.sent_date).format('MMMM')}`}>
        <ExcelColumn label="Pacote #" value="id" />
        <ExcelColumn label="Número de rastreio" value="tracking" />
        <ExcelColumn label="Valor total" value="totalValue" />
      </ExcelSheet>
      <ExcelSheet data={dataSet2} name="Processos">
        <ExcelColumn label="Processo" value="identifier" />
        <ExcelColumn label="Requerente" value="applicant" />
        <ExcelColumn label="Valor" value="postalValue" />
      </ExcelSheet>
    </ExcelFile>
  );
}

ExportTable.propTypes = {
  processes: PropTypes.array.isRequired,
  lot: PropTypes.object.isRequired,
  totalValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default ExportTable;
