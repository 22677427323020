import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  contractBody: {
    '& h1': {
      paddingTop: 24,
      marginBottom: 32,
      fontSize: '2.125rem',
      fontWeight: 400,
      lineHeight: 1.17,
      color: theme.palette.text.secondary,
    },
    '& table': {
      width: '100%',
      padding: theme.spacing(1),
    },
    '& table td': {
      border: `1px solid ${fade(theme.palette.common.black, 0.2)}`,
      padding: theme.spacing(1),
    },
    '& strong': {
      color: fade(theme.palette.common.black, 0.7),
    },
    '& p': {
      margin: theme.spacing(1, 0),
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
      textAlign: 'justify',
      color: theme.palette.text.primary,
    },
  },
  speedDial: {
    alignItems: 'center',
  },
  actionsWrapper: {
    marginRight: theme.spacing(-5),
  },
  fab: {
    background: theme.palette.common.white,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    '&:hover': {
      background: fade(theme.palette.common.black, 0.1),
      backgroundColor: fade(theme.palette.common.black, 0.1),
    },
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  clientName: {
    marginBottom: 24,
  },
  root: {
    '&:not(.noExtra)': {
      marginBottom: '72px !important',
      minHeight: 'calc(100vh - 72px) !important',
    }
  },
  startButton: {
    display: 'block',
    margin: `${theme.spacing(4)}px auto`,
    padding: theme.spacing(2, 2),
  },
  header: {
    paddingTop: 24,
  },
  description: {
    marginBottom: theme.spacing(5),
  },
  extract: {
    paddingLeft: theme.spacing(6) + 'px !important',
    paddingBottom: theme.spacing(6) + 'px !important',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  extractBody: {
    fontSize: '110%',
    lineHeight: '1.8rem',
  },
  helper: {
    margin: 0,
    textAlign: 'left',
  },
  divider: {
    backgroundColor: theme.palette.grey[300],
    width: '90%',
    margin: theme.spacing(2) + 'px auto',
  },
}));
