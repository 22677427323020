import { PHONE } from 'constants/session/userFormFields';
import { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';

export const MINIMUM_STRENGTH = 2;

const formatLocalToIntl = (phone) => formatPhoneNumberIntl(`+55${phone}`).replace(/\s+/g, '');

export const prepareToSendContact = (form) => ({
  ...form,
  [PHONE]: form[PHONE].includes('+') ? form[PHONE] : formatLocalToIntl(form[PHONE]),
});

export const prepareToSendAddress = (form) => ({
  ...form,
  zip_code: form.zipcode,
});

export const prepareTosendPassword = (form) => ({
  password: form.password,
  password_confirmation: form.passwordConfirmation,
});

export const checkPassword = (form, strength) => new Promise((resolve, reject) => {
  if (form.password_confirmation !== form.password) reject(new Error('As senhas não são iguais'));
  if (strength < MINIMUM_STRENGTH) reject(new Error('A senha inserida é muito fraca'));
  resolve(form);
});

export const validateContact = (form) => new Promise((resolve, reject) => {
  Object.keys(form).forEach((key) => {
    if (form[key] === '' || form[key] === undefined || form[key] === null || form[key].length <= 3) {
      reject(new Error('Por favor, preencha todos os campos'));
    }
    if (key === PHONE && !isValidPhoneNumber(form[PHONE])) {
      reject(new Error('Por favor, insira um número de telefone válido'));
    }
  });
  resolve(true);
});
