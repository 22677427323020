import React, { useState } from 'react';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import ProcessHeader from 'sales/form/StepTwo/ProcessHeader';
import SaleFormStepTwoForm from 'sales/form/StepTwo/Form';
import { PROCESS_FORM_MODE_EDIT } from 'constants/sales';

const useStyles = makeStyles({
  expandIcon: {
    position: 'absolute',
    top: 8,
    right: 0,
  },
  root: {
    padding: 0,
  },
  expansionPanel: {
    padding: '16px 24px',
  },
});

function Process({ people, process }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <ExpansionPanel
        square
        elevation={3}
        expanded={expanded}
        className={classes.expansionPanel}
        onChange={() => setExpanded(!expanded)}
      >
        <ExpansionPanelSummary
          classes={{ root: classes.root, expandIcon: classes.expandIcon }}
          expandIcon={!expanded ? <ExpandMoreIcon /> : <CancelIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <ProcessHeader
            process={process}
            people={people}
            expanded={expanded}
          />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.root }}>
          <SaleFormStepTwoForm process={process} people={people} mode={PROCESS_FORM_MODE_EDIT} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  );
}

export default Process;
