import { put, takeEvery, takeLatest, all, call } from 'redux-saga/effects';
import * as definitions from 'store/definitions/session';
import * as notificationDefinitions from 'store/definitions/notifications';
import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from 'constants/notification';
import moment from 'moment';
import * as API from 'api';
import { AUTH_PATH, USER_PATH } from 'api/paths';
import * as messages from 'constants/common/messages';
import {
  SESSION_STORAGE_KEY,
  SESSION_VALIDA_TIME_STORAGE_KEY,
  SESSION_TOKEN_STORAGE_KEY,
} from 'constants/session';
import {
  storeUserSession,
  storeSessionToken,
  storeSessionTokenValidTime,
  formatUser,
  encryptedRoute,
} from 'helpers';
import * as crypto from 'helpers/crypto';

function* login(action) {
  try {
    const { username, password, previous } = action.payload;
    let decrypt = '';

    if (previous) {
      if (previous.includes('%')) {
        var explodePrevious = previous.split('%');
        decrypt = `${crypto.decode(explodePrevious[0])}`;
      } else {
        decrypt = `${crypto.decode(previous)}`;
      }
    }

    const decrypted = previous ? decrypt.replace(/[0-9]/g, '') : '/';
    const payload = yield call(API.login, AUTH_PATH, { username, password });
    const user = formatUser(payload.user);
    storeUserSession(user);
    storeSessionToken(payload.access_token);
    storeSessionTokenValidTime(payload.expires_in);

    const url = decrypt.length > 1 ? decrypt : '/';

    yield put({ type: definitions.LOGIN_FULFILLED, payload: { user } });
    yield window.location.href = url;
  } catch (error) {
    const errormessage = typeof error.message === 'string';
    let message = error.message;
    if (errormessage && error.message.includes('401')) {
      message = messages.MESSAGE_WRONG_PASSWORD;
    }
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message },
    });
    yield put({
      type: definitions.LOGIN_REJECTED,
      payload: { error },
    });
  }
}

function* checkAuth(action) {
  const { previous } = action.payload;
  const encrypted = encryptedRoute(previous, 'login');
  const userSession = localStorage.getItem(SESSION_STORAGE_KEY);
  const sessionToken = localStorage.getItem(SESSION_TOKEN_STORAGE_KEY);
  const validTime = localStorage.getItem(SESSION_VALIDA_TIME_STORAGE_KEY);

  if (userSession === null || validTime === null || sessionToken === null) {
    localStorage.clear();
    yield window.location.href = encrypted;
    yield put({ type: definitions.LOGOUT, payload: { encrypted } });
    return;
  }

  if (moment().isAfter(moment(validTime))) {
    localStorage.clear();
    yield window.location.href = encrypted;
    yield put({ type: definitions.LOGOUT, payload: { encrypted } });
    return;
  }

  const user = JSON.parse(userSession);
  yield put({ type: definitions.LOGIN_FULFILLED, payload: { user, } });
}

function* logout(action) {
  const { encrypted } = action.payload || { encrypted: null };
  localStorage.clear();
  yield window.location.href = (encrypted || '/login');
}

export function* updateProfile(action) {
  try {
    const { id, fields } = action.payload;
    const payload = yield call(API.update, USER_PATH, id, fields);
    yield put({ type: definitions.UPDATE_USER_PROFILE_FULFILLED, payload });
    const user = formatUser(payload.user);
    storeUserSession(user);
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_ACCOUNT_UPDATED,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.UPDATE_USER_PROFILE_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR }
    });
  }
}

export function* changePassword(action) {
  try {
    const { data, id } = action.payload;
    const fullPath = `${USER_PATH}`;
    const payload = yield call(API.updateAction, fullPath, id, 'reset-password', data);
    yield put({ type: definitions.CHANGE_PASSWORD_FULFILLED, payload });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_ACCOUNT_UPDATED,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.CHANGE_PASSWORD_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(definitions.LOGIN, login),
    takeEvery(definitions.LOGOUT, logout),
    takeEvery(definitions.CHECK_AUTH, checkAuth),
    takeLatest(definitions.UPDATE_USER_PROFILE, updateProfile),
    takeLatest(definitions.CHANGE_PASSWORD, changePassword),
  ]);
}
