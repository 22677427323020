import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, Tabs, Tab } from '@material-ui/core';
import { useStyles } from './styles';
import ProductInfo from './ProductInfo';
import { getFormInfoObj, getFormRequirementObj, getFormPriceObj } from './functions';
import PriceInfo from './PriceInfo';
import RequirementInfo from './RequirementInfo';
import TabPanel, { a11yProps } from './TabPanel';

const Row = ({ product, setUpdate }) => {
  const classes = useStyles({});
  let totalPrice = 0;
  product.prices.forEach((a) => totalPrice += a.price);
  const [formInfo, setFormInfo] = React.useState(getFormInfoObj({}));
  const [formRequirement, setFormRequirement] = React.useState([]);
  const [formPrices, setFormPrices] = React.useState([]);
  const [view, setView] = React.useState(0);
  const handleChangeView = (event, newValue) => setView(newValue);

  React.useEffect(() => {
    setFormInfo(getFormInfoObj(product));
    setFormRequirement([...product.requirements.map((requirement) => ({
      ...requirement,
      ...getFormRequirementObj(requirement)
    })),
    {
      ...getFormRequirementObj(),
      id: 'new',
    },
    ]);
    setFormPrices([
      ...product.prices.map((price) => ({
        ...getFormPriceObj(price),
      })),
      {
        ...getFormPriceObj({}),
        id: 'new',
      },
    ]);
  }, [product]);

  return (
    <>
      <Typography color="textSecondary" variant="h6" paragraph>
        {product.title}
      </Typography>
      <Paper elevation={3} className={classes.paper}>
        <Tabs
          classes={{ root: classes.tabs }}
          value={view}
          onChange={handleChangeView}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label={`Menu do processo de ${product.title}`}
        >
          <Tab label="Informações" {...a11yProps(0)} />
          <Tab label="Documentação" {...a11yProps(1)} />
          <Tab label="Preços" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={view} index={0}>
          <ProductInfo
            formInfo={formInfo}
            setFormInfo={setFormInfo}
            product={product}
            classes={classes}
            setUpdate={setUpdate}
          />
        </TabPanel>
        <TabPanel value={view} index={1}>
          <RequirementInfo
            formRequirement={formRequirement}
            setFormRequirement={setFormRequirement}
            product={product}
            classes={classes}
            setUpdate={setUpdate}
          />
        </TabPanel>
        <TabPanel value={view} index={2}>
          <PriceInfo
            formPrices={formPrices}
            setFormPrices={setFormPrices}
            product={product}
            classes={classes}
            setUpdate={setUpdate}
          />
        </TabPanel>
      </Paper>
    </>
  )
};

Row.propTypes = {
  product: PropTypes.shape({
    category: PropTypes.number,
    prices: PropTypes.array,
  }).isRequired,
};

export default Row;
