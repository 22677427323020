import React, { useState, useEffect } from 'react';
import { PROCESS, SCOPE } from 'constants/service';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  MenuItem,
  TextField,
} from '@material-ui/core';
import uniqid from 'uniqid';
import PropTypes from 'prop-types';
import FilesPreview from 'global/modules/FilesPreview';
import { deleteDocument, sendDocument } from 'store/actions/documents';
import { showConfirmation } from 'store/actions/confirmations';
import FileBox from 'global/filebox';
import { FULFILLED } from 'constants/common';
import { SEND_DOCUMENT } from 'store/definitions/documents';
import { getDocs, getPossiblePeople } from './functions';

function DocumentsList({
  currentClient,
  setUpdate,
  update,
  documentsState,
  show,
  setThis,
  objType,
  ...props
}) {
  const [file, setFile] = useState([]);
  const [documents, setDocuments] = useState(getDocs(currentClient));
  const [possiblePeople, setPossiblePeople] = useState(getPossiblePeople(currentClient));
  const [selectedPersonId, setSelectedPersonId] = useState('');
  const [error, setError] = useState(false);

  const handleChange = (value) => {
    setError(false);
    setSelectedPersonId(value);
  };
  useEffect(() => {
    setDocuments(getDocs(currentClient, objType));
    setPossiblePeople(getPossiblePeople(currentClient, objType));
    // eslint-disable-next-line
  }, [currentClient, update, setThis]);

  useEffect(() => {
    if (documentsState.status === FULFILLED) {
      if (documentsState.lastAction === SEND_DOCUMENT) {
        setFile([]);
      }
      setUpdate(uniqid());
    }
    // eslint-disable-next-line
  }, [documentsState.status]);
  useEffect(() => {
    setError(false);
  }, [file]);
  useEffect(() => {
    if (Object.keys(possiblePeople).length === 1) {
      setSelectedPersonId(Object.keys(possiblePeople)[0] || '');
    } else {
      setSelectedPersonId('');
    }
  }, [possiblePeople]);
  const handleRemoveDoc = (doc) => {
    props.showConfirmation(
      {
        title: 'Remover documento',
        message: `Deseja remover o documento de ${doc.person_name}?`,
      },
      {
        confirm: 'Remover',
        cancel: 'Cancelar',
      },
      {
        func: props.deleteDocument,
        payload: doc.id,
      },
    );
  };
  const handleClear = () => {
    setFile([]);
    setSelectedPersonId('');
  };
  const handleClose = () => {
    props.onClose(false);
    handleClear();
  };
  const handleUpload = () => {
    if (selectedPersonId === '') {
      setError(true);
      return;
    }
    props.sendDocument(selectedPersonId, file);
  };

  return (
    <Dialog
      onClose={handleClose}
      scroll="body"
      aria-labelledby="customized-dialog-title"
      open={show}
      fullWidth={!isMobile}
      fullScreen={isMobile}
      maxWidth={isMobile ? false : 'md'}
    >
      <DialogTitle id="customized-dialog-title">
        {'Documentos'}
      </DialogTitle>
      <DialogContent style={{ padding: '16px 24px', overflow: 'visible' }}>
        {Object.keys(documents).length > 0
          ? Object.keys(documents).map((key) => (
            <Grid key={uniqid()} container spacing={2} style={{ paddingBottom: 16 }}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {`Documento${documents[key].length > 1 ? 's' : ''} de ${documents[key][0].person_name}`}
                </Typography>
              </Grid>
              {documents[key].map((doc) => (
                <FilesPreview
                  key={uniqid()}
                  doc={doc}
                  removeDoc={handleRemoveDoc}
                  expandable
                  fullWidth={documents[key].length === 1 || isMobile}
                  xs={12 / documents[key].length > 6 ? 12 / documents[key].length : 6}
                  sm={12 / documents[key].length > 3 ? 12 / documents[key].length : 3}
                />
              ))}
            </Grid>
          )) : (
            <Typography variant="subtitle2" paragraph>Nenhum documento encontrado</Typography>
          )}
        {Object.keys(possiblePeople).length > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>Adicionar documentos</Typography>
            </Grid>
            <Grid item xs={12}>
              {Object.keys(possiblePeople).length > 1 || !Object.keys(possiblePeople)[0]
                ? (
                  <TextField
                    select
                    fullWidth
                    required
                    variant="outlined"
                    name="selectedPerson"
                    label="Pessoa"
                    value={selectedPersonId}
                    helperText={<Typography component="span">Selecione a pessoa à qual o documento é relacionado</Typography>}
                    error={error}
                    onChange={(e) => handleChange(e.target.value)}
                  >
                    {Object.keys(possiblePeople).map((personId) => (
                      <MenuItem
                        key={uniqid()}
                        value={personId}
                      >
                        {possiblePeople[personId]}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : null}
            </Grid>
            <Grid item xs={12}>
              <FileBox
                id="annex-pt-doc"
                file={file}
                setFile={setFile}
                baseFileAmount={documents.length}
              />
            </Grid>
            <Grid item xs={12}>
              {file.length > 0 && (
                <Typography align="right">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClear}
                  >
                    {'Limpar'}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 16 }}
                    onClick={handleUpload}
                  >
                    {'Enviar'}
                  </Button>
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          {'Fechar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
DocumentsList.propTypes = {
  documents: PropTypes.array,
  show: PropTypes.bool,
  currentClient: PropTypes.object.isRequired,
  update: PropTypes.string,
  setUpdate: PropTypes.func.isRequired,
  objType: PropTypes.oneOf([PROCESS, SCOPE]),
  setThis: PropTypes.string,
};
DocumentsList.defaultProps = {
  update: '',
  documents: [],
  objType: PROCESS,
  show: false,
  setThis: '',
};
const actions = { deleteDocument, showConfirmation, sendDocument };
const mapStateToProps = (state) => ({ documentsState: state.documents });

export default connect(mapStateToProps, actions)(DocumentsList);
