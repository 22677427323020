import React from 'react';
import {
  Grid, Typography, TextField,
} from '@material-ui/core';
import { addressFields } from 'constants/newClient';
import ZipCodeField from 'global/fields/ZipCodeField';
import AutosuggestField from 'global/fields/AutosuggestField';
import * as imports from 'objects/imports';

const brazilianStates = imports.countries.find((country) => country.alpha2Code === 'BR').states;

function Address({
  classes,
  form,
  handleChangeForm,
  handleSelectCountry,
  loadAddressByZipCode,
  getLabel,
  ...props
}) {
  return (
    <>
      <Typography variant="h5" paragraph>Endereço</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <AutosuggestField
                onSelect={handleSelectCountry}
                suggestList={imports.countries}
                label="País"
                value={form.country}
                loadKey="alpha2Code"
                InputLabelProps={{
                  shrink: Boolean(form.country),
                }}
                inputProps={{
                  classes,
                  autoComplete: 'off',
                  id: 'client-country',
                  name: 'country',
                  variant: 'outlined',
                  fullWidth: true,
                  required: true,
                }}
                theme={{
                  container: classes.container,
                  suggestionsContainerOpen: classes.suggestionsContainerOpen,
                  suggestionsList: classes.suggestionsList,
                  suggestion: classes.suggestion,
                }}
              />
            </Grid>
            {form.country === 'BR' && (
              <Grid item xs={12} sm={6}>
                <ZipCodeField
                  value={form.zipCode != null ? form.zipCode : ''}
                  label="Cep"
                  variant="outlined"
                  fullWidth
                  required={form.country === 'BR'}
                  name="zipCode"
                  onBlur={() => loadAddressByZipCode()}
                  onChange={e => handleChangeForm('zipCode', e.target.value)}
                  InputLabelProps={{
                    shrink: Boolean(form.zipCode),
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {addressFields.map(field => (
          <Grid item xs={12} sm={6} key={field.name}>
            <TextField
              id={`client-${field.name}`}
              name={field.name}
              label={getLabel(field.name)}
              variant="outlined"
              fullWidth
              required={field.required}
              value={form[field.name] != null ? form[field.name] : ''}
              onChange={e => handleChangeForm(field.name, e.target.value)}
              aria-describedby={`client-${field.name}`}
              InputLabelProps={{
                shrink: Boolean(form[field.name]),
              }}
            />
          </Grid>
        ))}
        <Grid item xs={12} sm={6}>
          {form.country === 'BR'
            ? (
              <TextField
                select
                fullWidth
                required
                variant="outlined"
                name="state"
                label="Estado"
                SelectProps={{
                  native: true,
                }}
                value={form.state}
                onChange={e => handleChangeForm('state', e.target.value)}
              >
                <option value=""> </option>
                {brazilianStates.map((state) => (
                  <option key={`state${state.value}`} value={state.value}>
                    {state.label}
                  </option>
                ))}
              </TextField>
            )
            : (
              <TextField
                name="state"
                label="Estado"
                variant="outlined"
                fullWidth
                required
                value={form.state != null ? form.state : ''}
                onChange={e => handleChangeForm('state', e.target.value)}
                InputLabelProps={{
                  shrink: Boolean(form.state),
                }}
              />
            )}
        </Grid>
      </Grid>
    </>
  );
}

export default Address;
