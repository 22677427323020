import {
  put, takeLatest, all, call,
} from 'redux-saga/effects';
import * as definitions from 'store/definitions/customer';
import * as API from 'api';
import { SCOPE_PATH } from 'api/paths';

const CREATE_CUSTOMER_TOKEN = '448045399adb97f4a8d1ca33f5f1a541';

export function* createCustomer(action) {
  try {
    const path = `${SCOPE_PATH}?access_token=${CREATE_CUSTOMER_TOKEN}`;
    const payload = yield call(API.create, path, { ...action.payload });
    yield put({
      type: definitions.CREATE_CUSTOMER_FULFILLED,
      payload,
    });
  } catch (error) {
    yield put({
      type: definitions.CREATE_CUSTOMER_REJECTED,
      payload: error.response,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.CREATE_CUSTOMER, createCustomer),
  ]);
}
