export const user = {
    "status": 201,
    "body": {
        "id": "PT84092",
        "active": "true",
        "userType": "1",
        "address1": "Rua Voluntários da Pátria",
        "address2": "45",
        "address3": "6º andar",
        "address4": "Botafogo",
        "applicant": [
            {
                "name": "Joaquim Português",
                "birthDate": "10/08/1910",
                "documentNumber": "238340",
                "bornIn": "Vila perto de um lugar no rio tal",
                "id": "2393",
                "citizen": true
            },
            {
                id: "00001/2",
                lastChange: 1556897295598,
                name: "Fulano da Silva Português",
                stage: ['7'],
                phase: 1,
                fatherName: "Joaquim Português",
                motherName: "Maria da Silva Português",
                citizenId: "2393",
                "birthDate": "10/08/1910",
                hiredServices: ['1','2','5'],
                citizenRelationship: "Filho",
                citizen: false,
                invited: false,
                emailConfirmed: true,
                email: "meu@email.com",
                userDocs: [
                    {
                        title: 'Certidão de Nascimento Inteiro Teor',
                        phase: '1',
                        clientSide: true
                    },
                    {
                        title: 'Transcrição de Casamento',
                        phase: '0',
                        clientSide: true
                    },
                    {
                        title: 'Uma certidão',
                        phase: '0',
                        clientSide: false
                    },
                    {
                        title: 'Outra certidão',
                        phase: '1',
                        clientSide: false
                    }
                ]
            },
            {
                id: "00002/2",
                lastChange: 1556897295598,
                name: "Fulana da Silva Português",
                stage: ['3'],
                phase: 1,
                fatherName: "Cicrano da Silva",
                motherName: "Augusta Sofia da Silva",
                citizenId: "00002/2",
                "birthDate": "10/08/1910",
                hiredServices: ['1','5'],
                citizenRelationship: "Esposa",
                citizen: false,
                invited: false,
                emailConfirmed: false,
                email: undefined,
                userDocs: [
                    {
                        title: 'Certidão de Nascimento Inteiro Teor',
                        phase: '1',
                        clientSide: true
                    },
                    {
                        title: 'Transcrição de Casamento',
                        phase: '0',
                        clientSide: true
                    },
                    {
                        title: 'Antecedentes Criminais',
                        phase: '1',
                        clientSide: true
                    },
                    {
                        title: 'Uma certidão',
                        phase: '0',
                        clientSide: false
                    },
                    {
                        title: 'Outra certidão',
                        phase: '1',
                        clientSide: false
                    }
                ]
            },
            {
                id: "00003/2",
                lastChange: 1556897295598,
                name: "Fulano da Silva Português Jr.",
                stage: ['3'],
                phase: 1,
                fatherName: "#00001",
                motherName: "#00002",
                citizenId: "2393",
                "birthDate": "10/08/1910",
                hiredServices: ['2','5'],
                citizenRelationship: "Neto",
                citizen: false,
                invited: true,
                emailConfirmed: false,
                email: "outro@email.com",
                userDocs: [
                    {
                        title: 'Certidão de Nascimento Inteiro Teor',
                        phase: '1',
                        clientSide: true
                    },
                    {
                        title: 'Uma certidão',
                        phase: '0',
                        clientSide: true
                    },
                    {
                        title: 'Outra certidão',
                        phase: '1',
                        clientSide: false
                    }
                ]
            },
            {
                id: "00004/2",
                lastChange: 1556897295598,
                name: "Fulano da Silva Português Jr.",
                stage: ['4'],
                phase: 1,
                fatherName: "#00001",
                motherName: "#00002",
                citizenId: "2393",
                "birthDate": "10/08/1910",
                hiredServices: ['1','2'],
                citizenRelationship: "Neto",
                citizen: false,
                invited: true,
                emailConfirmed: false,
                email: "outro@email.com",
                userDocs: [
                    {
                        title: 'Certidão de Nascimento Inteiro Teor',
                        phase: '1',
                        clientSide: true
                    },
                    {
                        title: 'Uma certidão',
                        phase: '0',
                        clientSide: false
                    },
                    {
                        title: 'Outra certidão',
                        phase: '1',
                        clientSide: false
                    }
                ]
            },
            {
                id: "00005/2",
                lastChange: 1556897295598,
                name: "Fulano da Silva Português Jr.",
                stage: ['5'],
                phase: 1,
                fatherName: "#00001",
                motherName: "#00002",
                citizenId: "2393",
                "birthDate": "10/08/1910",
                hiredServices: ['1','2','5','6'],
                citizenRelationship: "Neto",
                citizen: false,
                invited: true,
                emailConfirmed: false,
                email: "outro@email.com",
                userDocs: [
                    {
                        title: 'Certidão de Nascimento Inteiro Teor',
                        phase: '1',
                        clientSide: true
                    },
                    {
                        title: 'Uma certidão',
                        phase: '0',
                        clientSide: false
                    },
                    {
                        title: 'Outra certidão',
                        phase: '1',
                        clientSide: false
                    }
                ]
            },{
                id: "00006/2",
                lastChange: 1556897295598,
                name: "Fulano da Silva Português",
                stage: ['1'],
                phase: 2,
                fatherName: "Beltrano Português",
                motherName: "Maria da Silva Português",
                citizenId: "2393",
                "birthDate": "10/08/1910",
                hiredServices: ['2','5','3'],
                citizenRelationship: "Filho",
                citizen: false,
                invited: true,
                emailConfirmed: true,
                email: "meu@email.com",
                userDocs: [
                    {
                        title: 'Certidão de Nascimento Inteiro Teor',
                        phase: '1',
                        clientSide: true
                    },
                    {
                        title: 'Transcrição de Casamento',
                        phase: '0',
                        clientSide: true
                    },
                    {
                        title: 'Uma certidão',
                        phase: '0',
                        clientSide: false
                    },
                    {
                        title: 'Outra certidão',
                        phase: '1',
                        clientSide: false
                    }
                ]
            },
            {
                id: "00007/2",
                lastChange: 1556897295598,
                name: "Fulana da Silva Português",
                stage: ['1'],
                phase: 2,
                fatherName: "Cicrano da Silva",
                motherName: "Augusta Sofia da Silva",
                citizenId: "00001/2",
                "birthDate": "10/08/1910",
                hiredServices: ['1','5'],
                citizenRelationship: "Esposa",
                citizen: false,
                invited: false,
                emailConfirmed: false,
                email: undefined,
                userDocs: [
                    {
                        title: 'Certidão de Nascimento Inteiro Teor',
                        phase: '1',
                        clientSide: true
                    },
                    {
                        title: 'Transcrição de Casamento',
                        phase: '0',
                        clientSide: true
                    },
                    {
                        title: 'Antecedentes Criminais',
                        phase: '1',
                        clientSide: true
                    },
                    {
                        title: 'Uma certidão',
                        phase: '0',
                        clientSide: false
                    },
                    {
                        title: 'Outra certidão',
                        phase: '1',
                        clientSide: false
                    }
                ]
            },
            {
                id: "00008/2",
                lastChange: 1556897295598,
                name: "Fulano da Silva Português Jr.",
                stage: ['6'],
                phase: 2,
                fatherName: "#00001",
                motherName: "#00002",
                citizenId: "2393",
                "birthDate": "10/08/1910",
                hiredServices: ['2','5'],
                citizenRelationship: "Neto",
                citizen: false,
                invited: true,
                emailConfirmed: false,
                email: "outro@email.com",
                userDocs: [
                    {
                        title: 'Certidão de Nascimento Inteiro Teor',
                        phase: '1',
                        clientSide: true
                    },
                    {
                        title: 'Uma certidão',
                        phase: '0',
                        clientSide: false
                    },
                    {
                        title: 'Outra certidão',
                        phase: '1',
                        clientSide: false
                    }
                ]
            },
            {
                id: "00009/2",
                lastChange: 1556897295598,
                name: "Fulana da Silva Português",
                stage: ['1'],
                phase: 3,
                fatherName: "Cicrano da Silva",
                motherName: "Augusta Sofia da Silva",
                citizenId: "00001/2",
                "birthDate": "10/08/1910",
                hiredServices: ['1','2','5'],
                citizenRelationship: "Esposa",
                citizen: false,
                invited: false,
                emailConfirmed: false,
                email: undefined,
                userDocs: [
                    {
                        title: 'Certidão de Nascimento Inteiro Teor',
                        phase: '1',
                        clientSide: true
                    },
                    {
                        title: 'Transcrição de Casamento',
                        phase: '0',
                        clientSide: true
                    },
                    {
                        title: 'Antecedentes Criminais',
                        phase: '1',
                        clientSide: true
                    },
                    {
                        title: 'Uma certidão',
                        phase: '0',
                        clientSide: false
                    },
                    {
                        title: 'Outra certidão',
                        phase: '1',
                        clientSide: false
                    }
                ]
            },
            {
                id: "00010/2",
                lastChange: 1556897295598,
                name: "Fulano da Silva Português Jr.",
                stage: ['1'],
                phase: 3,
                fatherName: "#00001",
                motherName: "#00002",
                citizenId: "2393",
                "birthDate": "10/08/1910",
                hiredServices: ['1','2'],
                citizenRelationship: "Neto",
                citizen: false,
                invited: true,
                emailConfirmed: false,
                email: "outro@email.com",
                userDocs: [
                    {
                        title: 'Certidão de Nascimento Inteiro Teor',
                        phase: '1',
                        clientSide: true
                    },
                    {
                        title: 'Uma certidão',
                        phase: '0',
                        clientSide: false
                    },
                    {
                        title: 'Outra certidão',
                        phase: '1',
                        clientSide: false
                    }
                ]
            }
        ],
        "appointment": "a",
        "city": "Rio de Janeiro",
        "clientNumber": "10",
        "conclusionDate": "a",
        "country": "Brasil",
        "currentStep":2,
        "currentPhase": 2,
        "docsOkDate": "a",
        "documentsReadyDate": "a",
        "email": "meu@email.com.br",
        "gatheredDocsDate": "a",
        "name": "Fulano da Silva Português",
        "phase": 3,
        "phone": "(21) 5555-5555",
        "placeOfBirth": "Rio de Janeiro",
        "processStarted": "a",
        "requirement": "a",
        "requirementDate": "a",
        "sendDate": "a",
        "state": "RJ",
        "zipCode": "22270-900",
        "persona": "tipo de persona",
    }
}

export const portugueses = [
    {
        name: 'Antonio da Costa'
    }
]

export const Alerts = [
    {
        "message": "Seu processo foi iniciado!",
        "title": null,
        "type": "info",
        "expires": 15572433219500
    },
]

export const apostilamento = [
    {
        title: "Certidão de casamento",
        phase: "0",
    },
    {
        title: "Certidão de Nascimento",
        phase: "1",
    },
]

export const confirmacao = [
    {
        title: "Certidão de casamento",
        phase: "0",
    },
    {
        title: "Certidão de Nascimento",
        phase: "1",
    },
    {
        title: "Certidão de casamento",
        phase: "0",
    },
    {
        title: "Certidão de Nascimento",
        phase: "1",
    },
]


export const ServerTime = new Date();

export const eurConversion = 4.8;
