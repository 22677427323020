/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
/* eslint-disable react/prop-types */
/* eslint-disable lines-between-class-members */
import React, { Component } from 'react';
import { ServiceContext } from 'contexts/service';
import * as menus from 'constants/service/menus';
import { PROCESS, menuItems } from 'constants/service';
import { title, SERVICE } from 'constants/common/pageTitles';

class ServiceProvider extends Component {
  state = {
    currentTab: this.props.currentTab || 0,
    currentTitle: this.props.currentTitle || title(menuItems[0], SERVICE),
    multipleSelect: [],
    currentClient: {},
    currentProcess: {},
    currentInfoObj: {},
    currentCheck: {},
    currentLot: {},
    processDetails: false,
    showMoreInfo: false,
    openComments: false,
    docsShown: false,
    searchValue: '',
    sortItems: false,
    date: null,
    datePickDialog: false,
    checkDialog: false,
    block: false,
    whichMenu: '',
    expanded: '',
    lotDialog: false,
    editLotDialog: false,
    confirmEndStepDialog: false,
    confirmBlockDialog: false,
    objType: PROCESS,
    infoType: PROCESS,
    anchorEl: {
      [menus.ALINHAMENTO_ROW]: null,
      [menus.DOCUMENTACAO_ROW]: null,
      [menus.APOSTILAMENTO_ROW]: null,
      [menus.DOCSOK_ROW]: null,
      [menus.DOCSOK_BLOCK_ROW]: null,
      [menus.ENVIO_PROCESS_ROW]: null,
    },
  };

  setObjType = (type) => this.setState(() => ({ objType: type }));

  handleShowMoreInfo = (open, current, e, type) => {
    this.setState((state) => ({
      currentInfoObj: open ? current : state.currentInfoObj,
      infoType: open ? type : state.infoType,
      showMoreInfo: open,
    }));
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  handleProcessDetails = (open, current, e) => {
    this.setState(() => ({ currentProcess: open ? current : {} }));
    this.setState(() => ({ processDetails: open }));
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  setExpanded = (expand) => this.setState(() => ({ expanded: expand }));
  setCurrentProcess = (process) => this.setState(() => ({ currentProcess: process }));
  setCurrentClient = (client) => this.setState(() => ({ currentClient: client }));
  setCurrentInfoObj = (obj) => this.setState(() => ({ currentInfoObj: obj }));
  setCurrentCheck = (check) => this.setState(() => ({ currentCheck: check }));
  setAnchorEl = (anchor, menu) => this.setState((state) => ({ anchorEl: { ...state.anchorEl, [menu]: anchor } }));
  setWhichMenu = (menu) => this.setState(() => ({ whichMenu: menu }));

  handleExpanded = (expand) => {
    this.setExpanded(expand === this.state.expanded ? '' : expand);
  }

  handleEditLotDialog = (lot) => {
    const open = this.state.editLotDialog;
    this.setState((o) => ({
      ...o,
      editLotDialog: !open,
      currentLot: !open && lot ? lot : {},
    }));
  }

  handleLotDialog = (lot) => {
    const open = this.state.lotDialog;
    this.setState((o) => ({
      ...o,
      lotDialog: !open,
      currentLot: !open && lot ? lot : {},
    }));
  }

  handleConfirmEndStepDialog = (client) => {
    const open = this.state.confirmEndStepDialog;
    if (!open && client) this.setState(() => ({ currentClient: client }));
    this.setState(() => ({ confirmEndStepDialog: !open }));
  }

  handleCheckDialog = (procc) => {
    const open = this.state.checkDialog;
    this.setState(() => ({ checkDialog: !open, currentCheck: open ? {} : procc }));
  }

  handleBlockDialog = (client, block) => {
    const open = this.state.confirmBlockDialog;
    this.setState(() => ({ confirmBlockDialog: !open, currentClient: open ? {} : client, block }));
  }

  setDate = (date) => this.setState(() => ({
    date,
  }));

  handleDatePickDialog = (open, client, item, lot, currentDate) => {
    if (client && open && !lot) {
      this.setState(() => ({
        currentClient: { ...client, datePickerItem: item },
      }));
    } else if (lot && open) {
      this.setState(() => ({
        currentLot: { ...client, datePickerItem: item },
      }));
    }
    this.setState(() => ({
      datePickDialog: open,
      date: open ? currentDate || new Date() : null,
    }));
  }

  openMenu = (open, menu, e, currentClient) => {
    this.setAnchorEl(open ? e : null, menu);
    this.setCurrentClient(open ? currentClient : {});
  };

  setDocsShown = (client, e, type) => {
    const open = client.id !== this.state.currentClient.id;
    this.setState(() => ({ currentClient: open ? client : {} }));
    this.setState((state) => ({ docsShown: !state.docsShown }));
    if (type) this.setObjType(type);
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  setCurrentTab = (tab) => this.setState(() => ({ currentTab: tab, currentTitle: title(menuItems[tab], SERVICE) }));

  setOpenComments = (current) => {
    this.setState((state) => ({ currentClient: state.openComments ? {} : current, openComments: !state.openComments }));
  }

  setMultipleSelect = (id, e) => {
    let multipleArray = this.state.multipleSelect;
    const index = multipleArray.indexOf(id);
    if (index >= 0) multipleArray = multipleArray.filter((a) => a !== id);
    else multipleArray = [...multipleArray, id];
    this.setState(() => ({ multipleSelect: multipleArray }));
    e.stopPropagation();
    e.preventDefault();
  }

  setSortItems = () => this.setState((state) => ({ sortItems: !state.sortItems }));
  setSearchValue = (value) => this.setState(() => ({ searchValue: value }));

  clearMultipleSelect = () => this.setState({ multipleSelect: [] });

  render() {
    return (
      <ServiceContext.Provider
        value={{
          ...this.state,
          setDate: this.setDate,
          setCurrentTab: this.setCurrentTab,
          setMultipleSelect: this.setMultipleSelect,
          clearMultipleSelect: this.clearMultipleSelect,
          handleShowMoreInfo: this.handleShowMoreInfo,
          setOpenComments: this.setOpenComments,
          setDocsShown: this.setDocsShown,
          setSearchValue: this.setSearchValue,
          openMenu: this.openMenu,
          setSortItems: this.setSortItems,
          setCurrentClient: this.setCurrentClient,
          handleProcessDetails: this.handleProcessDetails,
          handleExpanded: this.handleExpanded,
          handleConfirmEndStepDialog: this.handleConfirmEndStepDialog,
          handleDatePickDialog: this.handleDatePickDialog,
          handleBlockDialog: this.handleBlockDialog,
          handleCheckDialog: this.handleCheckDialog,
          setCurrentInfoObj: this.setCurrentInfoObj,
          setCurrentCheck: this.setCurrentCheck,
          setExpanded: this.setExpanded,
          handleLotDialog: this.handleLotDialog,
          handleEditLotDialog: this.handleEditLotDialog,
        }}
      >
        {this.props.children}
      </ServiceContext.Provider>
    );
  }
}

export default ServiceProvider;
