import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  paragraphWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fileBox: {
    cursor: 'pointer',
    position: 'relative',
    '& .file-drop-target': {
      position: 'relative',
      padding: theme.spacing(3),
      backgroundColor: theme.palette.grey[100],
      border: `2px dashed ${theme.palette.grey[400]}`,
      borderRadius: theme.spacing(0.5),
    },
    '& .file-drop-dragging-over-frame': {
      backgroundColor: theme.palette.grey[200],
      zIndex: 1000,
    },
    '&:hover .file-drop-target, & .file-drop-dragging-over-target': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover .hover, & .file-drop-dragging-over-target .hover': {
      color: theme.palette.primary.main,
    },
    '& .hover': {
      fontSize: '4rem',
      marginBottom: theme.spacing(-1),
    },
    '&.error .file-drop-target': {
      borderColor: theme.palette.error.main,
    },
    '& .dummyInput': {
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
      opacity: 0,
      cursor: 'pointer',
    },
  },
  linearProgress: {
    margin: theme.spacing(1, 5),
  },
  smallMargin: {
    '&:not(.right)': {
      marginLeft: theme.spacing(0.5),
    },
    '&.right': {
      marginRight: theme.spacing(0.5),
    },
  },
}));