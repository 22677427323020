export const addressFields = [
  {
    name: 'address1',
    required: true,
  },
  {
    name: 'address4',
    required: true,
  },
  {
    name: 'address2',
    required: true,
  },
  {
    name: 'address3',
    required: false,
  },
  {
    name: 'city',
    required: true,
  },
];

let spreadAddressFields = {};
addressFields.forEach((field) => {
  spreadAddressFields = { ...spreadAddressFields, [field.name]: '' };
});

export const newClientFields = {
  clientId: '',
  internalId: '',
  salesmanId: '',
  email: '',
  phone: '',
  name: '',
  birthDate: null,
  gender: '',
  cpf: '',
  rg: '',
  emission: '',
  emissionDate: null,
  country: '',
  zipCode: '',
  ...spreadAddressFields,
  state: '',
};

export const getLabel = (label) => {
  switch (label) {
    case 'address1':
      return 'Logradouro';
    case 'address4':
      return 'Bairro';
    case 'address2':
      return 'Número';
    case 'address3':
      return 'Complemento';
    case 'city':
      return 'Cidade';
    case 'state':
      return 'Estado';
    default:
      return '';
  }
};
