/* eslint-disable max-lines */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import uniqid from 'uniqid';
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
} from '@material-ui/core';
import FilesPreview from 'global/modules/FilesPreview';
import FileBox from 'global/filebox';
import { deleteDocument, sendDocument } from 'store/actions/documents';
import { showNotification } from 'store/actions/notifications';
import { NOTIFICATION_TYPE_ERROR } from 'constants/notification';
import { MESSAGE_ERROR_COMMON_ERROR } from 'constants/common/messages';
import { changeStepProcessSendDocs, blockProcess } from 'store/actions/processes';
import { STEP_HAGUE } from 'constants/process';
import { FULFILLED } from 'constants/session';
import { showConfirmation } from 'store/actions/confirmations';

function Dialogs({
  classes, context, setUpdate, update, actionStatus, ...props
}) {
  const {
    currentClient,
    confirmEndStepDialog,
    handleConfirmEndStepDialog,
    handleBlockDialog,
    confirmBlockDialog,
    // checkDialog,
    // handleCheckDialog,
    block,
  } = context;
  const [file, setFile] = useState([]);
  const [reason, setReason] = useState('');
  //  const [notify, setNotify] = useState(true);
  const handleRemoveDoc = () => setFile([]);

  const handleUploadDocEndstep = () => {
    const goOn = () => {
      props.changeStepProcessSendDocs(
        currentClient.id,
        STEP_HAGUE,
        true,
        currentClient.applicant.id,
        file,
      );
    };

    if (currentClient.applicant.id && file.length > 0) {
      props.showConfirmation(
        {
          title: 'Confirmar',
          message: (
            <>
              {'Confirmar o recebimento de todos os documentos e notificar o cliente (pelo email '}
              <strong>{currentClient.sales_scope.customer.email}</strong>
              {') que eles foram recebidos?`'}
            </>
          ),
        },
        {
          confirm: 'Confirmar',
          cancel: 'Cancelar',
        },
        {
          func: goOn,
          payload: {},
        },
      );
    } else {
      props.showNotification(NOTIFICATION_TYPE_ERROR, MESSAGE_ERROR_COMMON_ERROR);
    }
  };

  const handleBlockProcess = () => {
    props.blockProcess(currentClient.id, reason, block);
  };

  // const handleChangeNotify = () => setNotify(!notify);

  const handleCloseBlockDialog = () => {
    handleBlockDialog();
    setReason('');
  };

  const handleChangeReason = (e) => setReason(e.target.value);

  useEffect(() => {
    if (confirmEndStepDialog && actionStatus === FULFILLED) handleConfirmEndStepDialog();
    if (confirmBlockDialog && actionStatus === FULFILLED) {
      handleCloseBlockDialog();
      setUpdate(uniqid());
    }
    // eslint-disable-next-line
  }, [actionStatus]);

  return (
    <>
      <Dialog
        PaperProps={{
          square: true,
        }}
        open={confirmEndStepDialog}
        onClose={handleConfirmEndStepDialog}
        scroll="body"
        aria-labelledby="dialog-endstep-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="dialog-endstep-title">
          {'Anexar e confirmar documentos'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {file.length > 0
              ? (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      {'Arquivo anexado'}
                    </Typography>
                  </Grid>
                  {file.map((annex) => (
                    <FilesPreview
                      key={uniqid()}
                      doc={annex}
                      removeDoc={handleRemoveDoc}
                      expandable
                      fullWidth={file.length === 1}
                      sm={12 / file.length > 3 ? 12 / file.length : 3}
                    />
                  ))}
                </>
              ) : (
                <Grid item xs={12}>
                  {`Por favor, anexe os documentos referentes ao processo de ${currentClient.applicant.name}`}
                </Grid>
              )}
            <Grid item xs={12}>
              {file.length > 0 && <Typography variant="h6" gutterBottom>Substituir arquivo</Typography>}
              <FileBox
                id="annex-pt-doc"
                file={file}
                setFile={setFile}
                multiple={false}
              />
            </Grid>
            {file.length > 0
              ? (
                <Grid item xs={12}>
                  {`Confirmar que os documentos de ${currentClient.applicant.name} estão completos?`}
                </Grid>
              )
              : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmEndStepDialog}
            variant="outlined"
            color="secondary"
          >
            {'Cancelar'}
          </Button>
          <Button
            onClick={handleUploadDocEndstep}
            disabled={file.length === 0}
            variant="outlined"
            color="primary"
          >
            {'Confirmar'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        PaperProps={{
          square: true,
        }}
        open={confirmBlockDialog}
        onClose={() => handleCloseBlockDialog()}
        scroll="body"
        aria-labelledby="dialog-endstep-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="dialog-endstep-title">
          {block ? 'Travar processo' : 'Destravar processo'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                {block
                  ? `Se encontrou alguma pendência de documentação, indique o travamento do processo de ${currentClient.applicant.name}`
                  : `Deseja destravar o processo de ${currentClient.applicant.name}?`
                }
              </Typography>
              {block && (
                <TextField
                  id="outlined-multiline-static"
                  label="Motivo para a trava do processo"
                  fullWidth
                  multiline
                  value={reason}
                  rows="4"
                  onChange={handleChangeReason}
                  margin="normal"
                  variant="outlined"
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseBlockDialog()}
            variant="outlined"
            color="secondary"
          >
            {'Cancelar'}
          </Button>
          <Button
            onClick={() => handleBlockProcess()}
            disabled={reason === '' && block}
            variant="outlined"
            color="primary"
          >
            {'Confirmar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const actions = {
  deleteDocument,
  sendDocument,
  showNotification,
  changeStepProcessSendDocs,
  showConfirmation,
  blockProcess,
};

const mapStateToProps = (state) => ({
  steps: state.steps.collection,
  actionStatus: state.processes.actionStatus,
});

export default connect(mapStateToProps, actions)(Dialogs);
