function getSaleStep(scope) {
  if (scope.align) return 5;
  if (scope.confirmed) return 4;
  if (scope.confirmed) return 3;
  if (scope.signed_date) return 2;
  if (scope.contract_path) return 1;
  return 0;
}

export function setSalesStep(scope) {
  const scopeAvecStep = scope;
  scopeAvecStep.salesStep = getSaleStep(scope);
  return scopeAvecStep;
}
