import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import uniqid from 'uniqid';
import ActionsContext from 'contexts/actions';
import { Grid, Paper, Typography } from '@material-ui/core';
import TabHeader from 'sales/dashboard/components/MasterTabHeader';
import TabRowSales from 'sales/dashboard/components/TabRowSales';
import { fetchAllLeads, fetchMoreLeads } from 'store/actions/sales';
import { LoadingComponent } from 'global/modules/Loading';
import { REQUESTING, FULFILLED, DEFAULT } from 'constants/common';
import NoResults from 'global/modules/NoResults';
import { useStyles } from './style';
import { getCancelToken, cancel } from 'api';
import LoadMore from './modules/LoadMore';
import { SaleDashboardContext } from 'contexts/saleDashboard';

function MasterTabSales({
  tab,
  tabKey,
  leads,
  update,
  status,
  fetchAll,
  fetchMore,
  currentPage,
  fetchMoreStatus,
  actionStatus,
  ...props
}) {
  const classes = useStyles(props);
  const { reload } = useContext(ActionsContext);
  const { q, sortBy, sortOrder } = useContext(SaleDashboardContext);
  const [requesting, setRequesting] = useState(false);
  const cancelSource = React.useRef(null);

  const handleFetchMore = () => {
    fetchMore(cancelSource.current.token, currentPage + 1, sortBy, sortOrder, q);
  };

  useEffect(() => {
    if (status === FULFILLED && requesting) {
      setRequesting(false);
    }
  }, [status, requesting]);

  useEffect(() => {
    cancelSource.current = getCancelToken().source();
    if (!requesting) {
      setRequesting(true);
      fetchAll(cancelSource.current.token, currentPage, sortBy, sortOrder, q);
    }
    return () => cancel(cancelSource.current);
    // eslint-disable-next-line
  }, [fetchAll, update, reload]);

  useEffect(() => {
    if (!requesting && status !== REQUESTING && status !== DEFAULT) {
      setRequesting(true);
      fetchAll(cancelSource.current.token, currentPage, sortBy, sortOrder, q);
    }
    // eslint-disable-next-line
  }, [sortBy, sortOrder, q]);

  return (
    <Grid>
      <TabHeader
        title={tab.title}
        tabKey={tabKey}
        itemsSort={[
          {
            title: 'Última atualização',
            value: 'updated_at',
          },
          {
            title: 'Entrada',
            value: 'id',
          },
        ]}
      />
      {leads
        && status === FULFILLED
        && leads.length === 0
        ? <NoResults message="Nenhuma venda encontrada" />
        : (
          <Paper className={clsx(classes.header, 'selectable')}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  Cliente
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  Informações
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2">
                  Escopo Venda
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        )}
      {leads
        && leads.length > 0
        && leads.map((lead) => (
          <TabRowSales
            key={uniqid()}
            item={lead}
          />
        ))}
      {status === REQUESTING && fetchMoreStatus !== REQUESTING
        && <LoadingComponent />}
      {leads && leads.length > 0 && status !== DEFAULT && (
        <LoadMore action={handleFetchMore} />
      )}
    </Grid>
  );
}

MasterTabSales.propTypes = {
  tab: PropTypes.object.isRequired,
  tabKey: PropTypes.number.isRequired,
  leads: PropTypes.array.isRequired,
  update: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  fetchAll: PropTypes.func.isRequired,
  fetchMore: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  leads: state.sales.collection,
  status: state.sales.status,
  fetchMoreStatus: state.sales.fetchMoreStatus,
  actionStatus: state.sales.actionStatus,
  totalPages: state.sales.totalPages,
  currentPage: state.sales.currentPage,
});

const actions = {
  fetchAll: fetchAllLeads,
  fetchMore: fetchMoreLeads,
};

export default connect(mapStateToProps, { ...actions })(MasterTabSales);
