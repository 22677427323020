import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, Paper, Grid, Typography, IconButton, Tooltip } from '@material-ui/core';
import { updateProcess } from 'store/actions/sales';
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
} from '@material-ui/icons';
import { sameRelation } from 'constants/sales';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 3),
    marginBottom: 0,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  button: {
    width: 35,
    height: 35,
  },
}));

function PhaseRow({
  process, phase, totalPhases, unique, ...props
}) {
  const classes = useStyles(props);

  const handleMovePhase = (goingUp) => {
    const newPhase = phase - 1;
    props.updateProcess({
      clientId: props.scope.client_id,
      data: { phase: goingUp ? newPhase + 1 : newPhase - 1 },
      scopeId: props.scope.id,
      processId: process.id
    });
  }

  return (
    <Paper elevation={3} className={classes.root}>
      <Grid container spacing={2}>
        <Grid item className={classes.column} xs={4}>
          <Typography color="textSecondary"><strong>Nome</strong></Typography>
          <Typography>{process.applicant.name}</Typography>
          {process.host_relations !== sameRelation && <Typography color="textSecondary">{`${process.host_relations} de ${process.hosts[0].name}`}</Typography>}
        </Grid>
        <Grid item className={classes.column} xs={3}>
          <Typography color="textSecondary"><strong>Data de nascimento</strong></Typography>
          <Typography color="textPrimary">
            {process.applicant.birthdate}
          </Typography>
        </Grid>
        <Grid item className={classes.column} xs={3}>
          <Typography color="textSecondary"><strong>Processo</strong></Typography>
          <Typography>{process.products[0].title}</Typography>
        </Grid>
        <Grid item className={classes.column} xs={2}>
          {((phase > 1 && !unique) || (unique && phase > 1 && phase === totalPhases)) && (
            <Typography align="right">
              <Tooltip title={`Passar para a fase ${phase - 1}`}>
                <IconButton onClick={() => handleMovePhase(false)} aria-label="fase acima" className={classes.button} size="small">
                  <ArrowUpwardIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Typography>
          )}
          {totalPhases >= phase && !unique && (
            <Typography align="right">
              <Tooltip title={`Passar para a fase ${phase + 1}`}>
                <IconButton onClick={() => handleMovePhase(true)} aria-label="fase abaixo" className={classes.button} size="small">
                  <ArrowDownwardIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Typography>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  scope: state.sales.scope,
});

const actions = {
  updateProcess,
};

export default connect(mapStateToProps, actions)(PhaseRow);
