import React from 'react';
import { Typography, Paper, Grid } from '@material-ui/core';
import { useStyles } from 'service/style';
import clsx from 'clsx';
import { array } from 'prop-types';

function ProcessHeader({ items }) {
  const classes = useStyles();
  return (
    <>
      <Typography
        className={clsx(classes.pageHeader, 'smallMargin')}
        variant="h5"
        color="textSecondary"
      >
        {`Processos (${items.length})`}
      </Typography>
      <Paper className={clsx(classes.header, 'envioProcess')}>
        {items.length > 0
          && (
            <Grid container spacing={2}>
              <Grid item xs={1} />
              <Grid item xs={4}>
                <Typography variant="body2">
                  {'Requerente'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {'Emitido em'}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body2">
                  {'Vale postal'}
                </Typography>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          )}
      </Paper>
    </>
  );
}

ProcessHeader.propTypes = {
  items: array.isRequired,
};

export default ProcessHeader;
