import React from 'react';
import {
  Grid, Typography,
} from '@material-ui/core';
import { semanticDate } from 'global/functions';

function DocRow({ document, classes, ...props }) {
  return (
    <Grid
      key={`${document.id}`}
      container
      spacing={0}>
      <Grid
        item
        xs={12}>
        <Typography
          variant="subtitle2">
          {document.name}
          {document.delivered ?
            <Typography
              component="span"
              color="textSecondary"
              className={classes.dateofarrival}
              variant="body2">
              {`Chegou em ${semanticDate(document.delivered_at)}`}
            </Typography>
            : null}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default DocRow;