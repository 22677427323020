import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

function FillLaterField({ checked, onCheck }) {

  const handleOnCheck = (e) => onCheck(e.target.checked)

  return (
    <FormControlLabel
      control={(
        <Checkbox
          checked={checked}
          onChange={handleOnCheck}
        />
      )}
      label="A confirmar"
    />
  );
}

export default FillLaterField
