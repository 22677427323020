import { SHOW_CONFIRMATION, HIDE_CONFIRMATION } from 'store/definitions/confirmations';
import { EMPTY } from 'constants/common';
import { isEmpty } from 'lodash';

const emptyState = {
  text: {
    title: EMPTY,
    message: EMPTY,
  },
  buttons: {
    confirm: 'Confirmar',
    cancel: 'Cancelar',
  },
  onConfirm: () => [],
  open: false,
};

function Notifications(state = emptyState, action) {
  switch (action.type) {
    case SHOW_CONFIRMATION:
      return {
        text: {
          title: action.payload.text.title,
          message: !isEmpty(action.payload.text.message)
            ? action.payload.text.message
            : EMPTY,
        },
        buttons: !isEmpty(action.payload.buttons)
          ? action.payload.buttons
          : emptyState.buttons,
        onConfirm: action.payload.onConfirm,
        open: true,
      };
    case HIDE_CONFIRMATION:
      return {
        ...state,
        open: false,
      }
    default:
      return state;
  }
}

export default Notifications;
