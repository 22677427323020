// @ts-nocheck
import { makeStyles } from "@material-ui/core";
import { fade } from '@material-ui/core/styles/colorManipulator';

export const minMenuItemWidth = 250;

export const loadingKeyframe = `
    @keyframes loading {
        from {left: -100%; width: 30%;}
        50% {width: 30%;}
        70% {width: 70%;}
        80% { left: 50%;}
        95% {left: 120%;}
        to {left: 100%;}
    }
`;

export const useStylesMenu = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(4),
    backgroundColor: 'transparent',
    borderRadius: 0,
  },
  masterRoot: {
    flexGrow: 1,
    marginBottom: 0,
    position: 'relative',
    zIndex: 1,
    borderRadius: 0,
    backgroundColor: 'transparent',
  },
  tab: {
    fontSize: `${theme.typography.body2.fontSize} !important`,
    fontWeight: `${theme.typography.fontWeightMedium} !important`,
    minWidth: minMenuItemWidth,
  },
  tabRippleBg: {
    '& .MuiTouchRipple-root': {
      zIndex: 0,
      backgroundColor: 'transparent',
    }
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: `${theme.palette.common.white} !important`,
    height: '100% !important',
    zIndex: 0,
  },
  tabWrapper: {
    zIndex: 1,
  },
}));

export const useStyles = makeStyles((theme) => ({
  hiddenHolder: {
    display: 'flex',
    alignItems: 'center',
    '& .hide': {
      maxWidth: 0,
      overflow: 'hidden',
      transition: theme.transitions.create('all'),
      minWidth: 0,
      marginRight: 0,
    },
    '&:hover .hide': {
      maxWidth: 50,
      marginRight: theme.spacing(1),
      minWidth: theme.spacing(3),
    },
  },
  middleGridPadding: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  pageHeader: {
    marginBottom: theme.spacing(2),
  },
  deleteButton: {
    backgroundColor: theme.palette.error.dark,
    marginRight: theme.spacing(1),
  },
  dummyInput: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    opacity: 0,
    cursor: 'pointer',
  },
  checkButton: {
    position: 'absolute',
    left: theme.spacing(0.5),
  },
  transition: {
    transition: theme.transitions.create('all'),
    overflow: 'hidden',
    '&.out': {
      paddingTop: 0,
      paddingBottom: 0,
      maxHeight: 0,
    },
    '&.in': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      maxHeight: 100,
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(1.5, 4),
    margin: theme.spacing(1.5, 0),
    position: 'relative',
    minHeight: theme.spacing(8),
    transition: theme.transitions.create('min-height'),
    '&.selectable': {
      paddingLeft: theme.spacing(7),
    },
    '&.selected': {
      boxShadow: theme.insetShadows[0],
      backgroundColor: theme.palette.grey[200],
    },
    '& .MuiGrid-item': {
      flexDirection: 'column',
      justifyContent: 'center',
      display: 'flex',
    },
  },
  hideContent: {
    transition: theme.transitions.create('max-height'),
    width: `calc(100% ${theme.spacing(6)}px)`,
    marginLeft: theme.spacing(-5.5),
    marginRight: theme.spacing(-5.5),
    overflow: 'hidden',
    maxHeight: 0,
  },
  headerContentExpand: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0, 1),
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  bodyContentExpand: {
    padding: theme.spacing(0, 1),
    margin: 0,
    width: '100%',
  },
  header: {
    padding: theme.spacing(0, 4),
    marginBottom: theme.spacing(1),
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'relative',
    '&.selectable': {
      paddingLeft: theme.spacing(7),
    },
  },
  menuItem: {
    minHeight: theme.spacing(4),
  },
  menuItemIcon: {
    minWidth: 'unset',
    paddingRight: theme.spacing(1),
  },
  gap: {
    margin: theme.spacing(0, 3),
    backgroundColor: theme.palette.icons.secondary,
    width: 1,
  },
  gapGrid: {
    display: 'flex',
    minHeight: theme.spacing(6),
    justifyContent: 'center',
  },
  scroll: {
    minWidth: '1100px',
  },
  scrollContainer: {
    overflowX: 'auto',
    padding: 0,
  },
  pLine: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(.5),
    marginTop: theme.spacing(.5),
  },
  flex: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepper: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px !important`,
    backgroundColor: 'transparent',
  },
  connectorFilled: {
    borderColor: theme.palette.primary.main,
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: theme.palette.primary.main,
    },
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: theme.palette.grey[500],
    },
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: theme.palette.icons.secondary,
    },
  },
  connectorLine: {
    borderoColor: theme.palette.grey[300],
    transition: theme.transitions.create('border-color'),
  },
  step: {
    padding: theme.spacing(1) + "px !important",
    borderRadius: '100%',
    border: '1px solid ' + theme.palette.icons.secondary,
    color: theme.palette.icons.secondary,
    width: theme.spacing(5.6),
    height: theme.spacing(5.6),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.error': {
      borderColor: `${theme.palette.error.dark} !important`,
      backgroundColor: `${theme.palette.error.dark} !important`,
    },
    '&.error:hover': {
      backgroundColor: `${theme.palette.common.white} !important`,
      color: `${theme.palette.error.dark} !important`,
    },
    '&.pendent': {
      backgroundColor: `${theme.palette.common.white} !important`,
      color: `${theme.palette.icons.secondary} !important`,
      borderColor: `${theme.palette.icons.secondary} !important`,
    },
  },
  stepFilled: {
    borderColor: theme.palette.primary.main,
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create(['background-color', 'color', 'box-shadow'], { duration: '200ms' }),
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: '#FFFFFF',
      borderColor: theme.palette.primary.main,
      boxShadow: theme.shadows[1],
    },
  },
  stepCompleted: {
    borderColor: theme.palette.icons.primary,
    color: theme.palette.icons.primary,
    transition: theme.transitions.create(['background-color', 'color', 'box-shadow'], { duration: '200ms' }),
    '&:hover': {
      backgroundColor: fade(theme.palette.icons.secondary, 0.2),
      boxShadow: theme.shadows[1],
    },
  },
  stepActive: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.2),
      boxShadow: theme.shadows[1],
    },
  },
  stepButton: {
    height: '100%',
    borderRadius: '100%',
    padding: theme.spacing(2),
  },
  flexButton: {
    width: 45,
    height: 45,
  },
  tableButtonGrid: {
    paddingRight: '0 !important',
    display: 'flex',
    alignItems: 'flex-end !important',
    justifyContent: 'flex-end !important',
    flexDirection: 'row !important',
  },
  lastChildPadding: {
    paddingLeft: `${theme.spacing(4)}px !important`,
  },
  button: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(5),
    '&:last-child': {
      marginLeft: theme.spacing(2),
    },
    border: 'none',
    boxShadow: theme.shadows[1],
  },
  buttonGroup: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(5),
    border: 'none',
    boxShadow: theme.shadows[1],
    marginLeft: '1px !important',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 1),
    '&:hover': {
      backgroundColor: fade('#000000', 0.08),
    },
    marginRight: theme.spacing(2),
    width: 'auto',
    display: 'inline-flex',
    boxShadow: theme.shadows[1],
    height: theme.spacing(5),
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputCloseBtn: {
    transition: theme.transitions.create('opacity'),
    marginRight: theme.spacing(1),
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: 90,
    '&:focus, &:hover': {
      width: 280,
    },
  },
  btnholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  btnIcon: {
    marginLeft: theme.spacing(2),
  },
  hoverText: {
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  hiddenText: {
    height: 1,
    position: 'absolute',
    top: 2,
    zIndex: -1,
    padding: 0,
    left: 0,
    margin: 0,
    border: 'none',
  },
  popMenuIcon: {
    minWidth: theme.spacing(4.5),
  },
  btnIconRight: {
    marginLeft: theme.spacing(1),
  },
  spacingLeft: {
    marginLeft: theme.spacing(1),
  },
  annexButton: {
    position: 'relative',
    overflow: 'hidden',
  },
  annexButtonLabel: {
    zIndex: 1,
  },
  loading: {
    display: 'block',
    position: 'absolute',
    content: '',
    left: 0,
    width: 200,
    height: '100%',
    backgroundColor: fade(theme.palette.primary.main, 0.4),
    animation: 'loading 2s linear infinite',
    zIndex: 0,
  },
  flexEnd: {
    justifyContent: 'flex-end !important',
  },
  visibleDisabledButton: {
    color: `${theme.palette.text.primary} !important`,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));
