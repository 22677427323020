import React from 'react';
import { Grid, Typography, TextField, MenuItem, IconButton, ButtonGroup, Button, makeStyles, Tooltip, FormControlLabel, Checkbox } from '@material-ui/core';
import { AddCircle, DeleteOutlined, Remove, Add } from '@material-ui/icons';
import { sortBy } from 'helpers';
import { getDocInfo } from './functions';
import { find } from 'lodash';
import { fade } from '@material-ui/core/styles';
import { convertCurrency } from 'helpers';
import clsx from 'clsx';
import { deburrify } from 'global/functions';

const useStyles = makeStyles((theme) => ({
  btnContainer: {
    marginLeft: theme.spacing(2),
  },
  btns: {
    borderColor: `${theme.palette.primary.main} !important`,
  },
  amountDisplay: {
    color: `${theme.palette.text.primary} !important`,
    borderTopColor: `${theme.palette.text.secondary} !important`,
    borderBottomColor: `${theme.palette.text.secondary} !important`,
    borderRightColor: 'transparent !important',
    borderLeftColor: 'transparent !important',
  },
  alertBtn: {
    '&:hover': {
      color: `${theme.palette.error.dark} !important`,
      borderColor: `${theme.palette.error.dark} !important`,
      backgroundColor: `${fade(theme.palette.error.dark, 0.2)} !important`,
    },
  },
}));

const MoreSales = ({ products, form, setForm, ...props }) => {
  const classes = useStyles(props);
  const handleSelectExtra = (e) => setForm(
    (o) => ({
      ...o,
      extraProductsSelection: [e.target.value],
    })
  );
  const handleExtraProperty = (id, property, value) => {
    setForm(
      (o) => ({
        ...o,
        extraProducts: o.extraProducts.map(
          (o) => {
            const newExtra = o;
            if (newExtra.id === id) {
              newExtra[property] = value;
            }
            return newExtra;
          }
        )
      })
    );
  };
  const handleAdd = (id) => {
    setForm(
      (o) => {
        return ({
          ...o,
          extraProducts: [...o.extraProducts, { id, use_old_price: false, apostilate: false }],
          extraProductsSelection: [''],
          amounts: {
            ...o.amounts,
            [id]: 1,
          }
        })
      }
    );
  };
  const handleRemove = (id) => {
    setForm(
      (o) => {
        return ({
          ...o,
          extraProducts: [...o.extraProducts.filter((p) => p.id !== id)],
        })
      }
    );
  };
  const handleAmount = (id, increment) => {
    const thisAmount = form.amounts[id] || 0;
    const newAmount = increment ? thisAmount + 1 : thisAmount - 1;
    if (newAmount >= 1) {
      setForm((a) => ({
        ...a,
        amounts: {
          ...a.amounts,
          [id]: newAmount,
        },
      }));
    } else {
      handleRemove(id);
      setForm((a) => ({
        ...a,
        amounts: {
          ...a.amounts,
          [id]: 0,
        }
      }));
    }
  };
  const extraProductsObjects = form.extraProducts.map(
    (o) => ({
      id: o.id,
      title: getDocInfo(products, { id: o.id }).title,
      value: getDocInfo(products, { id: o.id }).prices.reduce((a, b) => a + (b.price || 0), 0),
      oldValue: getDocInfo(products, { id: o.id }).prices.reduce((a, b) => a + (b.old_price || 0), 0),
      canHaveOldPrice: Boolean(getDocInfo(products, { id: o.id }).prices.find((a) => a.old_price)),
      use_old_price: o.use_old_price,
      apostilate: o.apostilate,
    })
  );
  const annexableProducts = products.filter((p) => p.can_attached);
  const availableProducts = annexableProducts.filter((p) => form.products && deburrify(p.title).includes(deburrify(getDocInfo(products, form.products[0]).title)));
  const filteredProducts = availableProducts.filter((p) => !find(form.extraProducts, ['id', p.id]));
  if (availableProducts.length) {
    return (
      <Grid item xs={12}>
        <Typography variant="h5" color="textSecondary" paragraph>
          {'Vendas adicionais'}
        </Typography>
        {extraProductsObjects && extraProductsObjects.length > 0 && (
          <Typography
            component="div"
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {extraProductsObjects.map((product) => (
              <Typography component="div" key={`selectedOldProduct${product.id}`} paragraph>
                <Typography variant="h6" style={{ display: 'flex', alignItems: 'center' }}>
                  {product.title}
                  <ButtonGroup
                    size="small"
                    variant="outlined"
                    className={classes.btnContainer}
                  >
                    <Button
                      className={clsx(classes.btns, form.amounts[product.id] <= 1 ? classes.alertBtn : 'regular')}
                      color="primary"
                      onClick={() => handleAmount(product.id, false)}>
                      {form.amounts[product.id] <= 1 ? <DeleteOutlined fontSize="small" /> : <Remove fontSize="small" />}
                    </Button>
                    <Button
                      className={classes.amountDisplay}
                      disabled
                    >
                      {form.amounts[product.id]}
                    </Button>
                    <Button
                      color="primary"
                      className={classes.btns}
                      onClick={() => handleAmount(product.id, true)}
                    >
                      <Add fontSize="small" />
                    </Button>
                  </ButtonGroup>
                </Typography>
                <Typography>
                  {product.canHaveOldPrice && (
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={product.use_old_price}
                          onChange={(e) => handleExtraProperty(product.id, 'use_old_price', e.target.checked)}
                        />
                      )}
                      label="Usar preço antigo"
                    />

                  )}
                </Typography>
                <Typography color="primary">
                  {'+ '}
                  {convertCurrency((product.use_old_price ? product.oldValue : product.value) * form.amounts[product.id])}
                </Typography>
              </Typography>
            ))}
          </Typography>
        )}
        {filteredProducts && filteredProducts.length > 0
          && (
            <Typography
              component="div"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <TextField
                select
                style={{ minWidth: 300 }}
                variant="outlined"
                name="extraProcess"
                label="Processo"
                value={form.extraProductsSelection}
                onChange={handleSelectExtra}
                InputLabelProps={{
                  shrink: form.extraProductsSelection.length > 0 && form.extraProductsSelection[0] !== '',
                }}
              >
                <MenuItem value="" style={{ display: 'none' }} />
                {filteredProducts && sortBy(filteredProducts, 'title').sort().map((product) => (
                  <MenuItem key={`extraProduct${product.id}`} value={product.id}>{product.title}</MenuItem>
                ))}
              </TextField>
              {form.extraProductsSelection && form.extraProductsSelection.length > 0 && form.extraProductsSelection[0] !== '' && (
                <Tooltip title="Adicionar">
                  <IconButton style={{ marginLeft: 8 }} onClick={() => handleAdd(form.extraProductsSelection[0])}>
                    <AddCircle />
                  </IconButton>
                </Tooltip>
              )}
            </Typography>
          )}
      </Grid>
    );
  }
  return null;
}

export default MoreSales;
