import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialogMoreInfo: {
    padding: `${theme.spacing(6, 3, 3, 3)} !important`,
  },
  gridContainer: {
    paddingBottom: theme.spacing(1),
    position: 'relative',
  },
  beforeMdPadding: {
    [theme.breakpoints.down('md')]: {
      paddingRight: `${theme.spacing(5.5)}px !important`,
    },
  },
  fromMdPadding: {
    [theme.breakpoints.up('md')]: {
      paddingRight: `${theme.spacing(5.5)}px !important`,
    },
  },
  link: {
    marginRight: theme.spacing(1),
  },
  smallPaper: {
    width: theme.spacing(35),
    display: 'inline-block',
    padding: theme.spacing(2),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    boxShadow: theme.shadows[4],
    '&:hover': {
      boxShadow: theme.shadows[1],
    },
  },
  pLine: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    whiteSpace: 'nowrap',
    lineHeight: 1.8,
  },
  icon: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  closeBtn: {
    position: 'absolute',
    top: theme.spacing(-3),
    right: 0,
  },
}));
