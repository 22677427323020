import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Button, makeStyles, CircularProgress } from '@material-ui/core';
import { REQUESTING } from 'constants/common';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    position: 'relative',
    '&.off': {
      '& .button': {
        opacity: 0.5,
      },
      '& .loading': {
        opacity: 1,
        display: 'flex',
      },
    },
    '& .button': {
      transition: theme.transitions.create('all'),
      opacity: 1,
    },
    '& .loading': {
      transition: theme.transitions.create('all'),
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      display: 'none',
      '& .progress': {
        left: '50%',
        top: theme.spacing(1),
        position: 'absolute',
        marginLeft: -10,
      },
    },
  },
}));

const LoadMore = ({ action, status, totalPages, currentPage }) => {
  const classes = useStyles({ status })
  const loading = status === REQUESTING;
  const display = currentPage < totalPages;
  if (display) {
    return (
      <div
        className={clsx(classes.container, loading ? 'off' : 'regular')}
      >
        <Button
          onClick={() => action()}
          className="button"
          fullWidth
          disabled={loading}
          variant="contained"
          color="primary"
        >
          {'Carregar mais'}
        </Button>
        <div className="loading">
          <CircularProgress className="progress" size={20} />
        </div>
      </div>
    );
  }
  return null;
};

const mapStateToProps = (state) => ({
  status: state.sales.fetchMoreStatus,
  totalPages: state.sales.totalPages,
  currentPage: state.sales.currentPage,
});

export default connect(mapStateToProps)(LoadMore)
