import React from 'react';
import MaskedInput from 'react-maskedinput';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

function renderMaskedInput(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask="11111-111"
      placeholderChar="_"
    />
  );
}

function ZipCodeField({ InputLabelProps, ...other }) {
  return (
    <TextField
      {...other}
      InputLabelProps={InputLabelProps}
      InputProps={{
        inputComponent: renderMaskedInput,
      }}
    />
  );
}

ZipCodeField.propTypes = {
  InputLabelProps: PropTypes.object,
};

ZipCodeField.defaultProps = {
  InputLabelProps: { shrink: false },
};

export default ZipCodeField;
