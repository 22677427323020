import * as definitions from 'store/definitions/payment';

export function createPayment(id, data, scopeId) {
  return {
    type: definitions.CREATE_PAYMENT,
    payload: {
      id, data, scopeId,
    },
  };
}

export function removePayment(id, reason, scopeId) {
  return {
    type: definitions.REMOVE_PAYMENT,
    payload: {
      id, reason, scopeId,
    },
  };
}

export function updatePaidPayment(id, data) {
  return {
    type: definitions.UPDATE_PAID_PAYMENT,
    payload: {
      id, data,
    },
  };
}

export function confirmSale(id) {
  return {
    type: definitions.CONFIRM_SALE,
    payload: { id },
  };
}

export function loadPayments(page = 1, orderBy, order, search, startDate, endDate, status, confirmed, paymentMethod, checked) {
  return {
    type: definitions.LOAD_PAYMENTS,
    payload: { page, orderBy, order, search, startDate, endDate, status, confirmed, paymentMethod, checked },
  };
}

export function checkPayment(id, paymentDate) {
  return {
    type: definitions.CHECK_PAYMENT,
    payload: { id, paymentDate },
  };
}

export function uncheckPayment(id) {
  return {
    type: definitions.UNCHECK_PAYMENT,
    payload: { id },
  };
}