import React, { useEffect, useContext } from 'react';
import { SalesContext } from 'contexts/sales';
import { connect } from 'react-redux';
import { Container, Grid, Typography } from '@material-ui/core';
import SaleCost from './SaleCost';
import CustomerInfo from './CustomerInfo';
import Citizens from './Citizens';
import Products from './Products';
import Comments from './Comments';

function SaleFormStepFour(props) {
  const salesContext = useContext(SalesContext);
  const { phases } = salesContext;
  const portugueses = props.scope.peoples.filter(a => !a.applicant);
  const { customer, payments } = props.scope;


  useEffect(() => {
    salesContext.handleStepOk(salesContext.currentStep, true);
    // eslint-disable-next-line
  }, []);

  return (
    <Container maxWidth="xl">
      <Typography variant="h5" paragraph>Resumo do contrato</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <CustomerInfo customer={customer} />
          <SaleCost payments={payments} scope={props.scope} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Citizens portugueses={portugueses} />
          <Products scope={props.scope} phases={phases} />
          <Comments />
        </Grid>
      </Grid>
    </Container >
  );
}

const mapStateToProps = state => ({
  scope: state.sales.scope,
})

export default connect(mapStateToProps)(SaleFormStepFour);
