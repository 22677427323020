import React from 'react';
import {
  Grid, Typography, Tooltip,
} from '@material-ui/core';
import { convertCurrency, localeDate } from 'helpers';
import {
  PAYMENT_BANK_SLIP, paymentMethodTitles, paymentStatusTitles,
} from 'constants/sales';
import { CANCELLED } from 'constants/common/payments';
import PaymentIcons from 'global/sources/Icons/PaymentIcons';
import CopyElement from 'global/CopyElement';

function PaymentRow({ payment }) {
  const marginStyle = { marginRight: 2, marginLeft: 2 };
  if (payment.status === CANCELLED) return null;

  return (
    <Grid item xs={12}>
      <Typography align="right" color="textSecondary" component="div" style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Tooltip title={paymentMethodTitles[payment.payment_method]}>
          <div>
            <PaymentIcons method={payment.payment_method} style={marginStyle} />
          </div>
        </Tooltip>
        {' - '}
        {payment.payment_method === PAYMENT_BANK_SLIP
          ? (
            <Typography component="span" color="textPrimary" style={marginStyle}>
              {`${convertCurrency(payment.value * payment.months)}${payment.months && payment.months > 1 ? ` ${payment.months}x de ${convertCurrency(payment.value)}` : ''}`}
            </Typography>
          ) : (
            <Typography component="span" color="textPrimary" style={marginStyle}>
              {`${convertCurrency(payment.value)}${payment.months && payment.months > 1 ? ` ${payment.months}x de ${convertCurrency(payment.value / payment.months)}` : ''}`}
            </Typography>
          )}
        {' - '}
        {`${payment.payment_method === PAYMENT_BANK_SLIP ? 'Primeira data' : 'Data'} de vencimento: `}
        {localeDate(payment.due_date)}
        {' - '}
        <Typography component="span" color="textPrimary" style={marginStyle}>
          {paymentStatusTitles(payment.status)}
        </Typography>
        {payment.payment_document_url
          ? (
            <Typography component="span" color="textPrimary" style={marginStyle}>
              {' - '}
              <CopyElement value={payment.payment_document_url}>
                {'Copiar link'}
              </CopyElement>
            </Typography>
          ) : null}
      </Typography>
    </Grid>
  );
}

export default PaymentRow;
