import * as definitions from 'store/definitions/users';

export function fetchAll(view, search, type, limit, page) {
  return {
    type: definitions.FETCH_ALL_USERS,
    payload: {
      view,
      search,
      type, 
      limit, page
    },
  };
}

export function usersSearch(searchValue, filter, sortBy, sortOrder) {
  return {
    type: definitions.USERS_SEARCH,
    payload: {
      filter, sortBy, sortOrder, searchValue,
    },
  };
}

export function createUser(payload) {
  const { view, data } = payload;
  return {
    type: definitions.CREATE_USER,
    payload: { view, data },
  };
}

export function updateUser(userId, data) {
  return {
    type: definitions.UPDATE_USER,
    payload: { userId, data },
  };
}

export function removePerson(payload) {
  const { personId, clientId } = payload;
  return {
    type: definitions.REMOVE_USER,
    payload: { personId, clientId },
  };
}
