import React from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import { convertCurrency } from 'helpers';
import { paymentMethodTitles, PAYMENT_BANK_SLIP } from 'constants/sales';
import { semanticDate } from 'global/functions';
import { CANCELLED } from 'constants/common/payments';

function SaleCost({ payments, scope }) {
  return (
    <Paper elevation={3}>
      <Typography variant="h5" color="textPrimary" paragraph>Valor da venda</Typography>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            {`Honorários: ${convertCurrency(scope.total_honorary)}`}
          </Typography>
          {scope.discount && (
            <Typography variant="subtitle2" paragraph>
              {`Desconto: ${scope.discount}%`}
            </Typography>
          )}
          <Typography variant="subtitle2" paragraph>
            {`Total: ${convertCurrency(scope.total)}`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" paragraph>Pagamentos</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle2" gutterBottom>Valor</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" gutterBottom>Forma de pagamento</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" gutterBottom>Data de vencimento</Typography>
            </Grid>
          </Grid>
        </Grid>
        {payments
          .filter((a) => a.status !== CANCELLED)
          .map((payment) => (
            <Grid item key={payment.id} xs={12}>
              <Grid container spacing={2}>
                {payment.payment_method === PAYMENT_BANK_SLIP
                  ? (
                    <Grid item xs={4}>
                      <Typography variant="subtitle1">
                        {`${convertCurrency(payment.value)}`}
                        {payment.months && payment.months > 1 && (
                          <Typography color="textSecondary" component="span">
                            {` (parcela ${payment.month_number} de ${payment.months})`}
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item xs={4}>
                      <Typography variant="subtitle1">
                        {`${convertCurrency(payment.value)}`}
                        {payment.months && payment.months > 1 && (
                          <Typography color="textSecondary" component="span">
                            {` em ${payment.months} vezes`}
                          </Typography>
                        )}
                      </Typography>
                    </Grid>
                  )}
                <Grid item xs={4}>
                  {`${paymentMethodTitles[payment.payment_method]}`}
                </Grid>
                <Grid item xs={4}>
                  {semanticDate(payment.due_date, true)}
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Paper>
  );
}

export default SaleCost;
