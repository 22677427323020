import React from 'react';
import CurrencyFormat from 'react-currency-format'; 
import { TextField } from '@material-ui/core';

function RenderField({ inputRef, ...props }){
  return(
    <CurrencyFormat
      {...props}
      decimalScale={2}
      currency="BRL"
      thousandSeparator="."
      decimalSeparator=","
    />
  );
}

function CurrencyField({ InputProps, ...props }) {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: RenderField,
        ...InputProps
      }}
    />
  );
}

export default CurrencyField;