import React, {useEffect, useContext, useState} from 'react';
import { connect } from 'react-redux';
import { ServiceContext } from 'contexts/service';
import {
  Container,
} from '@material-ui/core';
import * as pageTitles from 'constants/common/pageTitles';
import NoResults from 'global/modules/NoResults';
import { fecthToAlign, confirmAligment, sendDocumentationEmail } from 'store/actions/sales';
import { FULFILLED } from 'constants/common';
import Row from './Row';
import Header from './Header';
import { useStyles } from '../style';
import Dialogs from './modules/Dialogs';
import Menus from './modules/Menus';
import HeaderColumn from './HeaderColumn';
import { getCancelToken, cancel } from 'api';
import Pagination from '@material-ui/lab/Pagination';

function Alinhamento({ align, status, update, setUpdate, allSales, fetch, ...props }) {
  const classes = useStyles();
  const cancelSource = React.useRef(null);

  const serviceContext = useContext(ServiceContext);
  const { currentClient, currentProcess } = serviceContext;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    cancelSource.current = getCancelToken().source();
    fetch(cancelSource.current.token, currentPage);
    return () => cancel(cancelSource.current);
  }, [update, fetch]);

  const handleCurrentPage = (e, page) => {
    setCurrentPage(page);
    fetch(cancelSource.current.token, page);
  }

  return (
    <>
      <Header
        responseSize={allSales.length}
        filteredSize={align.length}
        pageTitle={pageTitles.ALINHAMENTO}
      />
      <Container maxWidth="xl">
        <Menus />
        <HeaderColumn filteredSize={align.length} />
        {align
          && status === FULFILLED
          && align.length === 0
          ? <NoResults message="Nenhum alinhamento encontrado" />
          : align.map((scope) => (
            <Row key={`scope${scope.id}`} scope={scope} />
          ))}
        {currentProcess !== null
          && Object.keys(currentClient).length > 0
          && (
          <Dialogs context={serviceContext} classes={classes} setUpdate={setUpdate} update={update} />
          )}

        <Pagination count={props.totalPages} page={currentPage} onChange={handleCurrentPage} />
      </Container>
    </>
  );
}

const mapToStateToProps = (state) => ({
  align: state.sales.collection,
  allSales: state.sales.allSales,
  scopeStatus: state.sales.scopeStatus,
  status: state.sales.status,
  totalPages: state.sales.totalPages,
  currentPage: state.sales.currentPage
});

const actions = { fetch: fecthToAlign, confirmAligment, sendDocumentationEmail };

export default connect(mapToStateToProps, actions)(Alinhamento);
