import {
  put, takeLatest, all, call, takeEvery,
} from 'redux-saga/effects';
import * as definitions from 'store/definitions/payment';
import { FETCH_ACCOMPANIMENT_BY_SALESMAN, FETCH_SALE_SCOPE_BY_SCOPE_ID } from 'store/definitions/sales';
import { PAYMENT_PATH, SCOPE_PATH } from 'api/paths';
import * as API from 'api';
import * as notificationDefinitions from 'store/definitions/notifications';
import * as messages from 'constants/common/messages';
import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from 'constants/notification';

export function* confirmSale(action) {
  try {
    const { id } = action.payload;
    const data = {
      secret: '$2y$10$Iq5RuPyVesYbzYmuSkHoaufBS3zAWZLsYCKJ8bu7Rh53PLOp28Kq.',
      internal_id: id
    };
    const fullPath = `${SCOPE_PATH}/pipedrive-confirm`;
    const payload = yield call(API.create, fullPath, data);
    yield put({
      type: definitions.CONFIRM_SALE_FULFILLED,
      payload,
    });
    yield put({
      type: FETCH_ACCOMPANIMENT_BY_SALESMAN,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_CONFIRM_SALE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.CONFIRM_SALE_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}


export function* updatePaidPayment(action) {
  try {
    const { id } = action.payload;
    const data = action.payload.data || {};
    const fullPath = `${PAYMENT_PATH}`;
    const payload = yield call(API.updateAction, fullPath, id, 'paid', { ...data });
    yield put({
      type: definitions.UPDATE_PAID_PAYMENT_FULFILLED,
      payload,
    });
    yield put({
      type: FETCH_ACCOMPANIMENT_BY_SALESMAN,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_PAYMENT_CONFIRMED,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.UPDATE_PAID_PAYMENT_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export function* removePayment(action) {
  try {
    const { reason, id, scopeId } = action.payload;
    const fullPath = `${PAYMENT_PATH}`;
    const payload = yield call(API.updateAction, fullPath, id, 'cancel', { reason });
    yield put({
      type: definitions.REMOVE_PAYMENT_FULFILLED,
      payload,
    });
    yield put({
      type: FETCH_SALE_SCOPE_BY_SCOPE_ID,
      payload: { scopeId },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_REMOVE_PAYMENT,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.REMOVE_PAYMENT_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export function* createPayment(action) {
  try {
    const { id, data, scopeId } = action.payload;
    const fullPath = `${PAYMENT_PATH}/${SCOPE_PATH}/${id}`;
    const payload = yield call(API.create, fullPath, { ...data });
    yield put({
      type: definitions.CREATE_PAYMENT_FULFILLED,
      payload,
    });
    yield put({
      type: FETCH_SALE_SCOPE_BY_SCOPE_ID,
      payload: { scopeId },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_ADDED_PAYMENT,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.CREATE_PAYMENT_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export function* loadPayments(action) {
  try {
    const { page, orderBy, order, search, startDate, endDate, status, confirmed, paymentMethod, checked } = action.payload;
    const filter = {
      page,
      s: search,
      startDate,
      endDate,
      orderBy,
      order,
      status,
      confirmed,
      paymentMethod,
      checked
    };
    
    const fullPath = `${SCOPE_PATH}/${PAYMENT_PATH}/list`;
    const payload = yield call(API.fetchPaged, fullPath, filter);
    yield put({
      type: definitions.LOAD_PAYMENTS_FULFILLED,
      payload,
    });
  } catch (error) {
    yield put({
      type: definitions.LOAD_PAYMENTS_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: error.error },
    });
  }
}

export function* checkPayments(action) {
  try {
    const { id, paymentDate } = action.payload;
    const fullPath = `${SCOPE_PATH}/${PAYMENT_PATH}/check`;
    const payload = yield call(API.update, fullPath, id, {paymentDate});
    yield put({
      type: definitions.CHECK_PAYMENTS_FULFILLED,
      payload,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_SUCCESS, message: payload.message },
    });
  } catch (error) {
    yield put({
      type: definitions.CHECK_PAYMENTS_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: error.message },
    });
  }
}

export function* uncheckPayments(action) {
  try {
    const { id } = action.payload;
    const fullPath = `${SCOPE_PATH}/${PAYMENT_PATH}/uncheck`;
    const payload = yield call(API.update, fullPath, id, {});
    yield put({
      type: definitions.UNCHECK_PAYMENTS_FULFILLED,
      payload,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_SUCCESS, message: payload.message },
    });
  } catch (error) {
    yield put({
      type: definitions.UNCHECK_PAYMENTS_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: error.error },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.UPDATE_PAID_PAYMENT, updatePaidPayment),
    takeLatest(definitions.CREATE_PAYMENT, createPayment),
    takeLatest(definitions.REMOVE_PAYMENT, removePayment),
    takeLatest(definitions.CONFIRM_SALE, confirmSale),
    takeLatest(definitions.LOAD_PAYMENTS, loadPayments),
    takeEvery(definitions.CHECK_PAYMENT, checkPayments),
    takeEvery(definitions.UNCHECK_PAYMENT, uncheckPayments),
  ]);
}
