import React from 'react';
import uniqid from 'uniqid';
import Root from 'global/modules/Root';
import {
  Container,
} from '@material-ui/core';
import SaleDashboardProvider from 'providers/saleDashboard';
import { SaleDashboardContext } from 'contexts/saleDashboard';
import SalesDashboardTabs from 'sales/dashboard/Tabs';
import SalesDashboardMasterTabs from 'sales/dashboard/MasterTabs';
import { salesMenu, salesMasterMenu } from 'constants/sales';
import TabSales from 'sales/dashboard/TabSales';
import TabAttendance from 'sales/dashboard/TabAttendance';
import TabHistoric from 'sales/dashboard/TabHistoric';
import OpenMenus from './modules/OpenMenus';
import UserDialogs from './modules/UserDialogs';
import { UserContext } from 'contexts/user';
import MasterTabSales from './MasterTabSales';
import MasterTabAttendance from './MasterTabAttendance';
import MasterTabHistoric from './MasterTabHistoric';
import CoordinatorDialogs from './modules/CoordinatorDialogs';
import ShareSaleDialog from './modules/ShareSaleDialog';
import ChatBot from 'global/ChatBot';

function Sales({
  match, tab = match.params.tab,
}) {
  const { userCoordinator } = React.useContext(UserContext);
  const tabs = ['vendas', 'acompanhamento', 'historico'];
  const tabIndex = tabs.indexOf(tab);
  const [update, setUpdate] = React.useState(uniqid());
  const updateObj = { update, setUpdate };

  return (
    <SaleDashboardProvider currentTab={tabIndex >= 0 ? tabIndex : 0} masterTab={userCoordinator ? 0 : 1}>
      <SaleDashboardContext.Consumer>
        {({ currentTab, setCurrentTab, masterTab, setCurrentMasterTab }) => (
          <Root header minWidth={960}>
            <UserDialogs {...updateObj} />
            <ShareSaleDialog {...updateObj} />
            {!userCoordinator ? null : <CoordinatorDialogs />}
            <OpenMenus />
            <Container maxWidth="xl" style={{ padding: '0', marginTop: 0 }}>
              {userCoordinator && (
                <SalesDashboardMasterTabs
                  selectedTab={masterTab}
                  onChangeTab={setCurrentMasterTab}
                />
              )}
              <SalesDashboardTabs
                selectedTab={currentTab}
                onChangeTab={setCurrentTab}
              />
            </Container>
            {masterTab === 0
              ? (
                <Container maxWidth="xl">
                  {currentTab === 0 && (
                    <MasterTabSales tab={salesMasterMenu[currentTab]} {...updateObj} tabKey={currentTab} />
                  )}
                  {currentTab === 1 && (
                    <MasterTabAttendance tab={salesMasterMenu[currentTab]} {...updateObj} tabKey={currentTab} />
                  )}
                  {currentTab === 2 && (
                    <MasterTabHistoric tab={salesMasterMenu[currentTab]} {...updateObj} tabKey={currentTab} />
                  )}
                </Container>
              ) : (
                <Container maxWidth="xl">
                  {currentTab === 0 && (
                    <TabSales tab={salesMenu[currentTab]} {...updateObj} tabKey={currentTab} />
                  )}
                  {currentTab === 1 && (
                    <TabAttendance tab={salesMenu[currentTab]} {...updateObj} tabKey={currentTab} />
                  )}
                  {currentTab === 2 && (
                    <TabHistoric tab={salesMenu[currentTab]} {...updateObj} tabKey={currentTab} />
                  )}
                </Container>
              )}
              <ChatBot />
          </Root>
        )}
      </SaleDashboardContext.Consumer>
    </SaleDashboardProvider>
  );
}

export default Sales;
