import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Grid, Paper, Typography, IconButton, Tooltip,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  RemoveCircle as RemoveIcon,
} from '@material-ui/icons';
import { humanFileSize, stopPropagation } from 'global/functions';
import { useStyles } from './style';
import Expanded from './Expanded';

function FilesPreview({ doc, removeDoc, expandable, fullWidth, xs, sm, inScope }) {
  const [expanded, setExpanded] = useState(false);
  const type = doc.type || doc.mime;
  const name = doc.file_name || doc.title;
  const src = doc.src || doc.url;

  const classes = useStyles({ inScope });

  const handleExpand = () => {
    setExpanded(true);
  };

  return (
    <Grid key={`${doc.title}${doc.lastModified}`} className={classes.grid} item xs={fullWidth ? 12 : xs} sm={fullWidth ? 12 : sm}>
      {expandable && (
        <Expanded expanded={expanded} setExpanded={setExpanded} doc={doc} />
      )}
      <Paper
        elevation={1}
        className={clsx(classes.fileMiniHolder, inScope ? 'sent' : 'not-sent', expandable ? 'expandable' : 'regular')}
        onClick={() => handleExpand()}
      >
        {removeDoc && (
          <Tooltip title="Remover anexo">
            <IconButton className={classes.removeButton} onClick={(e) => { stopPropagation(e); removeDoc(doc, inScope); }}>
              <RemoveIcon />
            </IconButton>
          </Tooltip>
        )}
        <Grid container spacing={2} className={classes.miniHolderContainer}>
          <Grid item xs={8} className={classes.miniHolderInfo}>
            {name && (
              <Typography variant="subtitle1">
                {name.length > 80
                  ? `${name.substring(0, 75)} ...${name.split('.')[name.split('.').length - 1]}`
                  : name}
              </Typography>
            )}
            {doc.size && (
              <Typography
                color="textSecondary"
                variant="subtitle1"
              >
                {humanFileSize(doc.size)}
              </Typography>
            )}
          </Grid>
          {type && (
            <Grid item xs={4} className={classes.miniHolderItem}>
              {type.includes('pdf')
                ? <i className="fas fa-file-pdf" style={{ fontSize: '5em', margin: '16px' }} />
                : (
                  <div style={{ backgroundImage: `url(${src})` }} className={classes.imgPreview} />
                )}
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
}

FilesPreview.defaultProps = {
  inScope: false,
  expandable: false,
  fullWidth: false,
  xs: 6,
  sm: 3,
};

FilesPreview.propTypes = {
  inScope: PropTypes.bool,
  expandable: PropTypes.bool,
  fullWidth: PropTypes.bool,
  xs: PropTypes.number,
  sm: PropTypes.number,
};

export default FilesPreview;
