import React from 'react';
import { ServiceContext } from 'contexts/service';
import { Paper, Grid, IconButton, Typography, Button, Collapse } from '@material-ui/core';
import { useStyles } from 'service/style';
import clsx from 'clsx';
import { ExpandMore, ErrorOutlineOutlined } from '@material-ui/icons';
import { semanticDate, toCurrency } from 'global/functions';
import CopyElement from 'global/CopyElement';
import ExportTable from '../modules/ExportTable';
import Contact from 'global/modules/Contact';

const Row = ({ lot }) => {
  const { expanded, handleExpanded, handleEditLotDialog } = React.useContext(ServiceContext);
  const isExpanded = expanded === lot.id;
  const hasProcesses = lot && lot.processes && lot.processes.length > 0;
  const classes = useStyles();
  let totalValue = 0;
  if (lot.processes && lot.processes.length > 0) {
    lot.processes.forEach((process) => {
      const value = process.products && process.products[0] ? process.products[0].postal_value : 0;
      totalValue = totalValue + value;
    });
  }
  return (
    <Paper className={clsx(classes.bodyEnvio, 'paddingNone')}>
      <Paper className={clsx(classes.bodyEnvio, 'inner')}>
        <Grid container spacing={2}>
          <Grid item xs={3} className={classes.expandButtonGrid}>
            <div className={classes.lotExpandButtonHolder}>
              {hasProcesses && (
                <IconButton
                  onClick={() => handleExpanded(lot.id)}
                  className={clsx(classes.flexButton, isExpanded ? 'expanded' : 'regular')}>
                  <ExpandMore />
                </IconButton>
              )}
            </div>
            <div className={classes.lotInfo}>
              <Typography variant="body1">
                <CopyElement>
                  {`Pacote ${lot.tracking_code || `#${lot.id}`}`}
                </CopyElement>
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {hasProcesses ? `${lot.processes.length} processo${lot.processes.length > 1 ? 's' : ''}` : ''}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={2} className={classes.centeredGrid}>
            <Typography variant="body1" color="textSecondary">
              {lot.sent_date ? 'Enviado em' : ''}
            </Typography>
            <Typography variant="body1">
              {lot.sent_date && semanticDate(lot.sent_date, true)}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.centeredGrid}>
            <Typography variant="body1" color="textSecondary">
              {lot.tracking_code ? 'Código de rastreio' : ''}
            </Typography>
            <Typography variant="body1">
              {lot.tracking_code
                ? (
                  <CopyElement>
                    {lot.tracking_code}
                  </CopyElement>
                ) : null}
            </Typography>
          </Grid>
          <Grid item xs={5} style={{ paddingRight: 16 }}>
            <Grid container spacing={2}>
              <Grid item xs={6} className={classes.centeredGrid}>
                <Button
                  variant="outlined"
                  onClick={() => handleEditLotDialog(lot)}
                  color="primary">
                    {'Editar informações'}
                </Button>
              </Grid>
              <Grid item xs={6} className={classes.centeredGrid}>
                {hasProcesses
                  ? (
                    <ExportTable processes={lot.processes} lot={lot} totalValue={totalValue ? toCurrency(totalValue / 100, 'EUR') : 0} />
                  ) : (
                    <Button
                      variant="contained"
                      disabled
                    >
                      {'.XLSX Indisponível'}
                      <ErrorOutlineOutlined fontSize="small" className={classes.iconMargin} />
                    </Button>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {hasProcesses && (
        <Collapse in={isExpanded}>
          <Paper square className={classes.innerHeader} elevation={0}>
            <Grid container spacing={2}>
              <Grid item xs={1}>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">
                  {'Requerente'}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align="center" variant="body2">
                  {'Emitido em'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="right" variant="body2">
                  {'Vale postal'}
                </Typography>
              </Grid>
              <Grid item xs={1}>
              </Grid>
            </Grid>
          </Paper>
          {lot.processes.map((process) => (
            <Paper key={`processInLot${lot.id}${process.id}`} square className={clsx(classes.innerBody)} elevation={0}>
              <Grid container spacing={2}>
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={3} className={classes.centeredGrid}>
                  <Contact
                    item={{ client_id: process.identifier }}
                    customer={{ name: process.applicant.name }}
                    short
                  />
                </Grid>
                <Grid item xs={3} className={classes.centeredGrid}>
                  <Typography variant="body1">{process.ready_to_post_date ? semanticDate(process.ready_to_post_date) : ''}</Typography>
                </Grid>
                <Grid item xs={4} className={classes.centeredGrid}>
                  <Typography align="right" variant="body1">
                    {process.products && process.products[0] ? (
                      <CopyElement>
                        {toCurrency(process.products[0].postal_value / 100, 'EUR')}
                      </CopyElement>
                    ) : ''}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                </Grid>
              </Grid>
            </Paper>
          ))}
          <Paper square className={clsx(classes.innerBody, 'noBorder')} elevation={0}>
            <Grid container spacing={2}>
              <Grid item xs={1}>
              </Grid>
              <Grid item xs={10} className={classes.centeredGrid} style={{ justifyContent: 'flex-end' }}>
                <Typography variant="body1" align="right">
                  {'Valor total '}
                  {totalValue ? (
                    <CopyElement children={toCurrency(totalValue / 100, 'EUR')} />
                  ) : ''}
                </Typography>
              </Grid>
              <Grid item xs={1}>
              </Grid>
            </Grid>
          </Paper>
        </Collapse>
      )}
    </Paper>
  );
};

export default Row;
