import React from 'react';
import PropTypes from 'prop-types';
import * as $_ from 'global/functions';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { itemTypes } from 'constants/batchpost';
import {
  useDrag,
} from 'react-dnd';
import Contact from 'global/modules/Contact';
import {
  Paper, Grid, Typography, IconButton, Tooltip,
} from '@material-ui/core';
import { useStyles } from 'service/style';
import {
  Unarchive as UnarchiveIcon,
} from '@material-ui/icons';
import { removeBatchPost, updateBatchPost } from 'store/actions/batchpost';

function ProcessInLotRow({ process, lot, updateLot }) {
  const handleRemoveProcess = (event) => {
    if (event) $_.stopPropagation(event);
    const newProcesses = lot.processes.filter((p) => p.id !== process.id).map((p) => p.id);
    const processesObj = newProcesses.length > 0
      ? { processes: newProcesses }
      : { processes: [false] };
    updateLot(lot.id, processesObj);
  };

  const ref = React.useRef(null);

  const classes = useStyles();
  const [{ isDragging }, drag] = useDrag({
    item: { name: process.id, type: itemTypes.processinLot, process, lot },
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        return;
      }
      handleRemoveProcess();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const styles = {
    transform: 'translate3d(0, 0, 0)',
  };

  const postalCost = process.products
    && process.products[0]
    && process.products[0].postal_value / 100;

  return (
    <div ref={drag} style={{ ...styles }}>
      <Paper square className={clsx(classes.innerBody, isDragging ? 'isDragging' : 'notDragging')} elevation={0}>
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ paddingLeft: 24 }} className={classes.centeredGrid}>
            <Contact
              item={{ client_id: process.identifier }}
              customer={{ name: process.applicant.name }}
              processTitle={process.products[0] && process.products[0].title}
              displayProcess
              short
            />
          </Grid>
          <Grid item xs={4} className={classes.centeredGrid}>
            <Typography variant="body1">
              {$_.semanticDate(process.readyDate)}
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.centeredGrid}>
            <Typography variant="body1">{$_.toCurrency(postalCost, 'EUR')}</Typography>
          </Grid>
          <Grid item xs={1} className={classes.centeredGrid} style={{ alignItems: 'flex-end' }}>
            <Tooltip title="Retirar processo do pacote" disableTouchListener placement="left">
              <IconButton
                ref={ref}
                className={classes.flexButton}
                aria-label="More"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e) => handleRemoveProcess(e)}
              >
                <UnarchiveIcon />
              </IconButton>
            </Tooltip>

          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

ProcessInLotRow.propTypes = {
  process: PropTypes.object.isRequired,
  lot: PropTypes.object.isRequired,
  updateLot: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  actionStatus: state.batchpost.actionStatus,
});

const actions = {
  removeLot: removeBatchPost,
  updateLot: updateBatchPost,
};

export default connect(mapStateToProps, actions)(ProcessInLotRow);
