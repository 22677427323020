import React from 'react';
import { connect } from 'react-redux';
import PropType from 'prop-types';
import { SnackbarContent, Snackbar } from '@material-ui/core';
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_INFO,
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_WARNING,
  NOTIFICATION_TYPE_BLACK,
} from 'constants/notification';
import { hideNotification } from 'store/actions/notifications';
import styles from './styles';
import clsx from 'clsx';

function Notifications({
  open,
  message,
  variant,
  hideNotification,
  ...props
}) {
  const classes = styles(props);

  const handleClose = () => {
    hideNotification();
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={clsx(classes.default, classes[variant])}
        aria-describedby="client-snackbar"
        message={message}
      />
    </Snackbar>
  );
}

Notifications.propTypes = {
  message: PropType.oneOfType([
    PropType.string,
    PropType.node,
  ]).isRequired,
  variant: PropType.oneOf([
    NOTIFICATION_TYPE_ERROR,
    NOTIFICATION_TYPE_INFO,
    NOTIFICATION_TYPE_SUCCESS,
    NOTIFICATION_TYPE_WARNING,
    NOTIFICATION_TYPE_BLACK,
  ]).isRequired,
};

const mapStateToProps = (state) => ({
  variant: state.notifications.variant,
  message: state.notifications.message,
  open: state.notifications.open,
});

export default connect(mapStateToProps, { hideNotification })(Notifications);
