import moment from 'moment-timezone';
import {
  SESSION_STORAGE_KEY,
  SESSION_TOKEN_STORAGE_KEY,
  SESSION_VALIDA_TIME_STORAGE_KEY,
  SERVER_TIMEZONE,
} from 'constants/session';
import { toLower, trim, capitalize, split, lowerCase } from 'lodash';
import { encode } from './crypto';
import { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';

export const decimalCharFromEn = (val) => `${val}`.replace('.', ',');

export const readableBRPhoneNumbers = (phoneNumber) => {
  if (phoneNumber) {
    return phoneNumber.startsWith('+55')
      ? formatPhoneNumber(phoneNumber)
      : formatPhoneNumberIntl(phoneNumber);
  }
};
//eslint-disable-next-line no-useless-escape
export const searchDeburr = (value) => trim(value.replace(/(\(([^\)]+)\))/ig, '')).replace(/\-/g, '');

export const calcSessionValidTime = minutes => moment().add(minutes, 'seconds');

export const returnValidDate = (date) => moment(date).format('YYYY-MM-DD');

export const defaultCasing = (value = '') => trim(split(split(value, ' ').join('-'), '-').reduce((a, b) => `${a} ${b.length > 3 ? capitalize(b) : lowerCase(b)}`, ''));

export const localeDate = (date) => moment(date).tz(SERVER_TIMEZONE).format('DD/MM/YYYY');

export const linebreakToHtml = (string = '') => string.replace(/(?:\r\n|\r|\n)/g, '<br />');
export const htmlToLineBreak = (string = '') => string.replace(/<br \/>/g, '\n');

export const storeUserSession = (user) => {
  if (typeof user === 'undefined') return;
  return localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(user));
};

export const storeSessionToken = (token) => {
  if (typeof token === 'undefined') return;
  return localStorage.setItem(SESSION_TOKEN_STORAGE_KEY, token);
};

export const storeSessionTokenValidTime = (time) => {
  if (typeof time === 'undefined') return;
  return localStorage.setItem(SESSION_VALIDA_TIME_STORAGE_KEY, calcSessionValidTime(time).toString());
};

export const stripValue = (string) => {
  const checkedString = `${string}`;
  return checkedString !== '' ? parseInt(checkedString.replace(/\D/g, '')) : 0;
};

export const convertCurrency = (amount = 0, showCurrency = true, currency = 'BRL') => {
  const currencyObj = showCurrency ? { style: 'currency', currency } : {};
  return (amount / 100).toLocaleString('pt-BR', { minimumFractionDigits: 2, ...currencyObj });
};

export const checkCurrencyValue = (value) => {
  if (typeof value === 'number' || (typeof value === 'string' && !value.includes(','))) {
    return `${value},00`;
  }
  return value;
};

// eslint-disable-next-line no-useless-escape
export const validUrl = (url = '') => Boolean(url.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/));

export const getStoredUSer = () => {
  const user = localStorage.getItem(SESSION_STORAGE_KEY);
  if (user === null) return null;
  return JSON.parse(user);
};

export const sortBy = (value, key) => (
  value.sort((a, b) => {
    if (toLower(a[key]) > toLower(b[key])) return 1;
    if (toLower(a[key]) < toLower(b[key])) return -1;
    return 0;
  })
);

export const semanticPlurals = (array, singular, plural) => array && array.length > 1 ? plural : singular;

export const encryptedRoute = (pathname, route) => {
  if (pathname && pathname !== '/') {
    return `${route ? `/${route}` : ''}/${encode(pathname.replace(/\/$/, '')).replace(/%3D%3D$/, '')}`;
  }
  return route ? `/${route}` : '/';
};

export const formatUser = (userData) => ({
  id: userData.id,
  userType: userData.user_type,
  internalType: userData.internal_type,
  name: userData.name,
  username: userData.username,
  email: userData.email,
  phone: userData.phone,
  birthdate: userData.birthdate,
  gender: userData.gender,
  idDocument: {
    cpf: userData.cpf,
    rg: userData.rg,
    emissor: userData.emissor,
    dispacth_date: userData.dispacth_date,
  },
  address: {
    address1: userData.address1,
    address2: userData.address2,
    address3: userData.address3,
    address4: userData.address4,
    city: userData.city,
    zipcode: userData.zip_code,
    state: userData.state,
    country: userData.country,
  },
});
