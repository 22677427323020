import React, { useContext } from 'react';
import Root from 'global/modules/Root';
import { Container, Typography, Grid } from '@material-ui/core';
import ContactForm from 'global/account/ContactForm';
import AddressForm from 'global/account/AddressForm';
import PasswordForm from 'global/account/PasswordForm';
import { CUSTOMER } from 'constants/user';
import { UserContext } from 'contexts/user';
import { isArray, includes } from 'lodash';

function Account(props) {
  const { user } = useContext(UserContext);
  const { userType } = user;

  const checkAllowed = (check) => {
    if (isArray(check)) return includes(check, userType);
    return check === userType;
  };

  const primarySizing = {
    xs: 12,
    md: 6,
    lg: 4,
  };

  const sizing = [
    {
      ...primarySizing,
      lg: checkAllowed(CUSTOMER) ? 4 : 6,
    },
    primarySizing,
  ];

  return (
    <Root header footer paddingTop>
      <Container maxWidth="xl">
        <Typography
          variant="h4"
          color="textSecondary"
          paragraph
        >
          Informações da conta
        </Typography>
        <Grid container spacing={3}>
          <ContactForm sizing={sizing[0]} />
          {checkAllowed(CUSTOMER) && <AddressForm sizing={sizing[1]} /> }
          <PasswordForm sizing={sizing[0]} />
        </Grid>
      </Container>
    </Root>
  );
}

export default Account;
