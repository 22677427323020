// @ts-nocheck
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { fade } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as pageTitles from 'constants/common/pageTitles';
import {
  Button,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputBase,
  InputLabel,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
  Block as BlockIcon,
  Call as PhoneIcon,
  Check as CheckIcon,
  CheckBox as CheckboxIcon,
  CheckBoxOutlineBlank as CheckboxBlankIcon,
  Close as CloseIcon,
  Email as EmailIcon,
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterIcon,
  MoreVert as MoreVertIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import Root from 'global/modules/Root';
import NavTabs from 'service/modules/NavTabs';
import Comments from 'global/Comments';
import Loading from 'global/modules/Loading';
import NoResults from 'global/modules/NoResults';
import * as DUMMIES from 'objects/dummies';
import * as API from 'global/API';
import * as $_ from 'global/functions';
import * as imports from 'objects/imports';
import CopyElement from 'global/CopyElement';

const pageTitle = pageTitles.ACOMPANHAMENTO;
// NOT RELEVANT FOR TESTING
const stepValue = 6;

const useStyles = makeStyles(theme => ({
  noResults: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& svg': {
      minWidth: 180,
      height: 'auto',
      opacity: .7,
      marginRight: theme.spacing(2),
    }
  },
  error: {
    color: theme.palette.error.main,
  },
  primary: {
    color: theme.palette.primary.main,
  },
  minWidthHolder: {
    overflowX: 'auto',
    overflowY: 'hidden',
    paddingBottom: theme.spacing(2),
  },
  minWidthContainer: {
    minWidth: theme.spacing(160),
  },
  pageHeader: {
    margin: 0,
    minHeight: theme.spacing(5),
  },
  btnholder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  btnContainer: {
    position: 'relative',
  },
  menuItem: {
    minHeight: theme.spacing(4),
  },
  menuItemIcon: {
    minWidth: 'unset',
    paddingRight: theme.spacing(1),
  },
  transition: {
    transition: theme.transitions.create(['max-width']),
    margin: -10,
    padding: 10,
    '&.in': {
      maxWidth: 300,
      overflow: 'visible',
    },
    '&.out': {
      maxWidth: 0,
      overflow: 'hidden',
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 1),
    '&:hover': {
      backgroundColor: fade('#000000', 0.08),
    },
    width: 'auto',
    display: 'inline-flex',
    boxShadow: theme.shadows[3],
    height: theme.spacing(5),
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: 90,
    '&:focus, &:hover': {
      width: 280,
    },
  },
  buttonGroup: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(5),
    border: 'none',
    boxShadow: theme.shadows[3],
    marginLeft: theme.spacing(2),
  },
  iconMargin: {
    marginLeft: theme.spacing(1),
  },
  iconMarginRight: {
    marginRight: theme.spacing(1),
  },
  header: {
    minHeight: theme.spacing(5),
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(1),
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'relative',
    '&:not(.small)': {
      padding: theme.spacing(2, 3, 2, 8),
    },
    '&.small': {
      padding: theme.spacing(2, 6.5, 2, 3),
    },
    '&.selected': {
      paddingBottom: 0,
    }
  },
  innerHeader: {
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[200],
  },
  floatingButtons: {
    position: 'absolute',
    top: '50%',
    height: theme.spacing(5),
    width: theme.spacing(5),
    marginTop: theme.spacing(-2.5),
    padding: theme.spacing(1),
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create(['transform']),
    '&.expanded': {
      transform: 'rotate(180deg)',
    }
  },
  floatingLeft: {
    left: theme.spacing(-5.5),
  },
  floatingRight: {
    right: theme.spacing(-5.5),
  },
  innerBody: {
    padding: theme.spacing(2),
    borderTop: `2px solid ${theme.palette.grey[200]}`,
    '&.isDragging': {
      border: `2px dashed ${theme.palette.grey[300]}`,
      margin: '-2px -2px 0 -2px',
      backgroundColor: theme.palette.grey[50],
    },
    '&.noBorder': {
      border: 'none',
    }
  },
  paddingDrag: {
    paddingBottom: theme.spacing(2),
  },
  paddingTop: {
    paddingTop: theme.spacing(1.5),
  },
  body: {
    margin: theme.spacing(1.5, 0, 2, 0),
    position: 'relative',
    minHeight: theme.spacing(8),
    transition: theme.transitions.create(['box-shadow', 'min-height', 'border-width'], { duration: '100ms' }),
    boxShadow: theme.shadows[6],
    '&:not(.small)': {
      padding: theme.spacing(2, 3, 2, 8),
    },
    '&.small': {
      padding: theme.spacing(2, 6.5, 2, 3),
    },
    '&.lot': {
      margin: '0 !important',
    },
    '&.selected': {
      boxShadow: theme.insetShadows[0],
      backgroundColor: theme.palette.grey[400],
    },
    '&.isActive': {
      border: `2px dashed ${theme.palette.grey[200]}`,
      margin: `calc(${theme.spacing(1.5)}px - 2px) -2px calc(${theme.spacing(2)}px - 2px) -2px`,
      boxShadow: theme.shadows[1],
    },
    '&.paddingNone': {
      padding: '0 !important',
      cursor: 'default',
    },
    '&.inner': {
      margin: '0 !important',
      boxShadow: 'none!important',
    },
    '&.dragging': {
      opacity: 0.7,
      cursor: 'move',
      border: `2px dashed ${theme.palette.grey[200]}`,
      margin: `calc(${theme.spacing(1.5)}px - 2px) -2px calc(${theme.spacing(2)}px - 2px) -2px`,
    },
    '&.noMarginTop': {
      marginTop: '0 !important',
    }
  },
  smallMargin: {
    '&:not(.right)': {
      marginLeft: theme.spacing(.5),
    },
    '&.right': {
      marginRight: theme.spacing(.5),
    }
  },
  paragraphWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperAlert: {
    backgroundColor: theme.palette.grey[100],
    border: `2px dashed ${theme.palette.grey[200]}`,
  },
  moreverticon: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '50%',
    marginTop: '-18px',
    padding: theme.spacing(1),
  },
  centeredGrid: {
    display: 'flex',
    '&:not(.row)': {
      flexDirection: 'column',
      justifyContent: 'center',
    },
    '&.row': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    }
  },
  hoverText: {
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: fade(theme.palette.text.primary, .7),
    '&:hover': {
      color: theme.palette.text.primary,
    }
  },
  hiddenText: {
    height: '1px',
    position: 'absolute',
    top: '2px',
    zIndex: '-1',
    padding: 0,
    left: 0,
    margin: 0,
    border: 'none',
  },
  icon: {
    marginRight: theme.spacing(.5),
    marginTop: theme.spacing(.5),
  },
  pLine: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    whiteSpace: 'nowrap',
    lineHeight: 1.8,
  },
  dialogMoreInfo: {
    padding: theme.spacing(6, 3, 3, 3),
  },
  smallPaper: {
    width: theme.spacing(35),
    display: 'inline-block',
    padding: theme.spacing(2),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    boxShadow: theme.shadows[4],
    '&:hover': {
      boxShadow: theme.shadows[1],
    }
  },
  link: {
    marginRight: theme.spacing(1),
  },
  closeBtn: {
    position: 'absolute',
    top: theme.spacing(-3),
    right: 0,
  },
  gridContainer: {
    paddingBottom: theme.spacing(1),
    position: 'relative',
  },
  moreverticonDialog: {
    top: theme.spacing(3),
    right: 0,
    position: 'absolute',
  },
  fromMdPadding: {
    [theme.breakpoints.up('md')]: {
      paddingRight: `${theme.spacing(5.5)}px !important`,
    },
  },
  beforeMdPadding: {
    [theme.breakpoints.down('md')]: {
      paddingRight: `${theme.spacing(5.5)}px !important`,
    },
  },
  closeBtnTiny: {
    marginRight: theme.spacing(.5),
  },
  closeBtnTinyIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  buttonGroupSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  tabIndicator: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  subMenuHolder: {
    margin: theme.spacing(0, 0, 3, 0),
    width: `100%`,
    paddingBottom: 0,
    borderBottom: `1px solid ${theme.palette.grey[500]}`
  },
  tabButton: {
    minHeight: theme.spacing(8),
  },
  tabLink: {
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  button: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(5),
    '&:last-child': {
      marginLeft: theme.spacing(2),
    },
    border: 'none',
    boxShadow: theme.shadows[1],
  },
  tabsGrid: {
    paddingBottom: '0 !important',
    paddingLeft: '0 !important',
    minHeight: theme.spacing(9),
  },
  actions: {
    transition: theme.transitions.create(['opacity']),
    opacity: 0,
    '&.shown': {
      opacity: 1,
    }
  },
  alertButton: {
    background: theme.palette.alertGradient.main,
    color: theme.palette.grey[900],
    '&:hover': {
      background: theme.palette.alertGradient.hover,
    },
  },
  blankResult: {
    marginTop: theme.spacing(7),
    '&.twoTimes': {
      marginTop: theme.spacing(2),
    }
  },
  fileBox: {
    cursor: 'pointer',
    position: 'relative',
    '& .file-drop-target': {
      minWidth: 380,
      padding: theme.spacing(3),
      backgroundColor: theme.palette.grey[100],
      border: `2px dashed ${theme.palette.grey[400]}`,
      borderRadius: theme.spacing(.5),
    },
    '& .file-drop-dragging-over-frame': {
      backgroundColor: theme.palette.grey[200],
    },
    '&:hover .file-drop-target, & .file-drop-dragging-over-target': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover .hover, &:hover .file-drop-target .hover': {
      color: theme.palette.primary.main,
    },
    '& .hover': {
      fontSize: '4rem',
      marginBottom: theme.spacing(-1),
    },
    '&.error .file-drop-target': {
      borderColor: theme.palette.error.main,
    }
  },
  dummyInput: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    opacity: 0,
    cursor: 'pointer',
  },
  linearProgress: {
    margin: theme.spacing(1, 5),
  }
}))

function Acompanhamento({ ...props }) {
  const classes = useStyles();

  const
    tab = 'para chegar',
    tabs = ['Para chegar', 'Em preparo', 'Na conservatória'],
    tabsDeburr = tabs.map(a => $_.deburrify(a)),
    tabsIndex = tabsDeburr.indexOf($_.deburrify(tab)),
    [menuValue, setMenuValue] = useState(tab && tabsIndex >= 0 ? tabsIndex : 0),
    [snackMessage, setSnackMessage] = useState('Copiado para a área de transferência!'),
    [snackState, setSnackState] = useState(false),
    [initialServiceClients, setInitialServiceClients] = useState($_.sortArrayOfObjectsByValue([]), 'dateInitialised'),
    [anchorDialog, setAnchorDialog] = useState(null),
    [moreInfoDialog, setMoreInfoDialog] = useState(false),
    [serviceClients, setServiceClients] = useState(initialServiceClients),
    [currentClient, setCurrentClient] = useState({}),
    [searchValue, setSearchValue] = useState(''),
    [filter, setFilter] = useState(null),
    [processTypes, setProcessTypes] = useState([]),
    [docsShown, setDocsShown] = useState(false),
    [initialLots, setInitialLots] = useState([]),
    [lots, setLots] = useState([]),
    [expanded, setExpanded] = useState(''),
    [pickedDate, setPickedDate] = useState(new Date()),
    [arrival, setArrival] = useState(false),
    [currentFile, setCurrentFile] = useState({}),
    [currentLot, setCurrentLot] = useState({}),
    [anchorEl, setAnchorEl] = useState(null),
    [conservatoriaSelect, setConservatoriaSelect] = useState(''),
    [dialogConfirmSend, setDialogConfirmSend] = useState(false),
    [confirmBlock, setConfirmBlock] = useState(false),
    [showFile, setShowFile] = useState(false),
    [finishProcessDialog, setFinishProcessDialog] = useState(false),
    mainConservatorias = imports.mainConservatorias,
    initialItems = {
      'conservatorias': {
        title: 'Conservatórias',
        items: mainConservatorias.map(a => {
          return {
            title: a,
            value: $_.deburrify(a)
          }
        }),
      }
    },
    [items, setItems] = useState(initialItems),
    menus = {
      block: [
        <MenuItem
          key="menuUndoBlock"
          onClick={() => handleUnBlock()}>Tirar de travados</MenuItem>,
        <MenuItem
          key="menuEditReason"
          onClick={() => handleBlockConfirm(currentClient, false, true, 'Alterar motivo', 'Alterar')}>Alterar motivo</MenuItem>
      ],
      tosend: [
        <MenuItem
          onClick={() => handleBlockConfirm(currentClient, false, true)}
          key="menuDoBlock">Enviar para travados</MenuItem>
      ],
      demand: [
        <MenuItem
          key="menuUndoDemand"
          onClick={() => handleUnBlock()}>Tirar de exigência</MenuItem>,
        <MenuItem
          key="menuEditReasonDemand"
          onClick={() => handleBlockConfirm(currentClient, false, true, 'Alterar motivo', 'Alterar')}>Alterar motivo</MenuItem>
      ],
      monitoring: [
        <MenuItem
          onClick={() => handleBlockConfirm(currentClient, false, true)}
          key="menuDoBlock">Enviar para exigência</MenuItem>
      ],
    };

  const [filterItems, setFilterItems] = useState([]);

  const handleCloseFile = (e) => {
    setCurrentFile({});
    setShowFile(false);
    $_.stopPropagation(e);
  }

  const handleDialogDatePick = (open, e, lot, send) => {
    setCurrentLot(open && lot ? lot : {});
    setArrival(open);
    if (send) setCurrentClient({ ...currentClient, dialogToSend: true });
    if (!open) setPickedDate(new Date());
    $_.stopPropagation(e);
  }

  const handleFilterMenu = (o) => e => {
    o
      ? setFilter(e.currentTarget)
      : setFilter(null);
  }

  const confirmFinishProcess = process => {
    const open = Boolean(process);
    setFinishProcessDialog(open);
    setCurrentClient(open ? process : {});

  }

  const finishProcess = () => {
    const newServiceClients = serviceClients.map(a => {
      if (a.id === process.id) {
        a.currentStep += 1;
        a.assento = $_.value('numero-assento');
      }
      return a;
    });

    setServiceClients([...newServiceClients]);
    confirmFinishProcess();
  }

  const handleCheckFilter = (item) => {
    let newFilterItems = filterItems;
    const indexof = newFilterItems.indexOf(item.value);
    if (indexof >= 0) {
      newFilterItems.splice(indexof, 1);
    }
    else {
      newFilterItems.push(item.value);
    }
    updateList(false, false, newFilterItems);
    setFilterItems([...newFilterItems]);
    setSearchValue('');
  }

  const handleClearCheck = () => {
    setFilterItems([]);
    updateList();
  }

  const handleConfirmArrived = (e) => {
    const newLots = lots.map(a => {
      if (a.id === currentLot.id) {
        a.arrivalDate = pickedDate;
      }
      return a;
    });
    setExpanded(currentLot.id);
    setLots([...newLots]);
    handleDialogDatePick(false, e);
  }

  const handleConfirmSend = (process) => {
    const open = Boolean(process);
    setCurrentClient(open ? process : {});
    setDialogConfirmSend(open);
  }

  const handleSendProcess = () => {
    const newServiceClients = serviceClients.map(a => {
      if (a.id === currentClient.id) {
        a.conservatoria = conservatoriaSelect === 'outra' ? $_.value('outra-conservatoria') : conservatoriaSelect;
        a.conservatoriaDate = pickedDate;
        a.monitoringStep = 2;
      }
      return a;
    });
    setServiceClients([...newServiceClients]);
    handleConfirmSend();
  }

  const handlePickDate = date => {
    setPickedDate(date);
  }

  const updateLots = (addedLot, newServiceClients) => {
    let initial = addedLot === false ? true : false;
    addedLot = addedLot.length === undefined ? [{ ...addedLot }] : addedLot;
    let newLots = addedLot ? addedLot : lots;
    const clientsInLots = newServiceClients.filter(a => a.inLotToArrive);
    clientsInLots.forEach(a => {
      const findLot = newLots.find(lot => lot.id === a.arrivalLot && a.inLotToArrive);
      if (findLot !== undefined) {
        newLots = newLots.map(newLot => {
          if (newLot.id === a.arrivalLot && newLot.processes.indexOf(a.id) < 0) {
            newLot.processes = [...newLot.processes, a.id]
          }
          return newLot;
        })
      }
      else {
        /* ASSGNING LOTS VALUES FOR TESTING */
        newLots = [...newLots, { id: a.arrivalLot, processes: [a.id], tracking: 'BR ' + $_.integer(1111, 9999), sentDate: $_.integer(1514772000000, 1563989931478) }];
        /* ASSGNING LOTS VALUES FOR TESTING */
      }
    });
    const cleanLots = $_.sortArrayOfObjectsByValue(
      newLots
        .filter(a => Object.keys(a).length > 0 && a.processes.length > 0)
      , 'sentDate'
      , sortItems);
    if (initial) setInitialLots([...cleanLots]);
    setLots([...cleanLots]);
  }

  const handleCloseMoreInfo = e => {
    setMoreInfoDialog(false);
  }

  const handleCloseSnack = e => {
    setSnackState(false);
  }

  const handleMenu = (e, newValue) => {
    clearSearch();
    setFilterItems([]);
    setMenuValue(newValue);
  }

  const clearSearch = () => {
    setSearchValue('');
    updateList(!sortItems, '');
  }
  const handleSortMenu = e => {
    updateList(!sortItems, searchValue);
    setSortItems(!sortItems);
  }

  const handleSearch = e => {
    const a = e.target.value;
    updateList(sortItems, a);
    setSearchValue(a);
  }

  const handleUnBlock = () => {
    const newServiceClients = serviceClients.map(a => {
      if (a.id === currentClient.id) {
        a.blocked = false;
      }
      return a;
    })
    setServiceClients([...newServiceClients]);
    handleVertMenu();
  }

  const handleBlockConfirm = (process, lot, open, title, actionButton) => {
    setConfirmBlock(Boolean(open));
    setCurrentClient(
      open && process
        ? title
          ? { ...process, blockDialog: { title, actionButton } }
          : process
        : {}
    );
    setCurrentLot(open && lot ? lot : {});
    setAnchorEl(null);
  }

  const handleVertMenu = (process, e, menu) => {
    const open = Boolean(process);
    setAnchorEl(open ? e.currentTarget : null);
    setCurrentClient(open ? { ...process, menu } : {});
  }

  const handleProcessInPortugal = (processID, action) => {
    const newProcesses = serviceClients.map(a => {
      if (a.id === processID) {
        a.inLotToArrive = false;
        a.monitoringLotInteracted = action;
        a.monitoringStep = menuValue - 1;
        if (action === 'block') {
          a.blocked = true;
          a.blockDate = (new Date()).getTime();
          a.blockReason = document.getElementById('block-reason').value;
        }
      }
      return a;
    });
    if (action === 'block') handleBlockConfirm();
    setServiceClients([...newProcesses]);
    updateLots(lots, newProcesses);
  }

  const updateList = (sort, search, filters) => {
    search = search || search === '' ? search : searchValue;
    sort = sort ? sort : sortItems;
    let returnable = "";
    switch (menuValue) {
      case 0:
        returnable = $_.sortArrayOfObjectsByValue(initialLots, 'sentDate', sort);
        break;
      case 1:
        const blocked = $_.sortArrayOfObjectsByValue(initialServiceClients.filter(a => a.blocked), 'blockDate', sort);
        const tosend = $_.sortArrayOfObjectsByValue(initialServiceClients.filter(a => !a.blocked), 'arrivalDate', sort);
        returnable = [...blocked, ...tosend];
        break;
      default:
        returnable = $_.sortArrayOfObjectsByValue(initialServiceClients, 'dateInitialised', sort);
        break;
    }
    if (filters && filters.length > 0 && menuValue === 2) {
      returnable = returnable.filter(a => {
        return filters.indexOf($_.deburrify(a.conservatoria)) >= 0
      });
    }
    if (search.length > 2) {
      returnable = returnable.filter(a => {
        return $_.matchable(
          a,
          [
            'id',
            'name',
            'email',
            'phone',
            'blockReason',
            'tracking'
          ],
          search
        );
      });
    }
    if (menuValue === 0)
      setLots([...returnable]);
    else
      setServiceClients(returnable);
  }

  const [sortItems, setSortItems] = useState(false);

  const handleVertMenuDialog = (state, e) => {
    setAnchorDialog(state ? e.currentTarget : null);
    $_.stopPropagation(e);
  }

  const showDocs = (client, e) => {
    setCurrentClient(client);
    setDocsShown(true);
    $_.stopPropagation(e)
  }

  const handleOpenDocs = (show, e) => {
    setDocsShown(Boolean(show));
    $_.stopPropagation(e);
  }


  const filteredServiceClients = [
    lots,
    serviceClients.filter(a => {
      return a.monitoringStep === 0 && a.currentStep === stepValue;
    }),
    serviceClients.filter(a => {
      return a.monitoringStep === 1 && a.currentStep === stepValue;
    })
  ];

  const handleExpand = (id, e) => {
    setExpanded(id === expanded ? null : id);
    $_.stopPropagation(e);
  };

  const handleSelect = e => {
    setConservatoriaSelect(e.target.value);
  }

  useEffect(() => {

    const clientsInLots = initialServiceClients.filter(a => a.inLotToArrive);
    const addConservatoria = initialServiceClients
      .filter(a => Boolean(a.conservatoria) && mainConservatorias.indexOf(a.conservatoria) === -1)
      .map(a => a.conservatoria);
    let allConservatorias = [...mainConservatorias];
    addConservatoria.forEach(a => {
      if (allConservatorias.indexOf(a) === -1) {
        allConservatorias.push(a);
      }
    })
    setItems({
      conservatorias: {
        title: 'Conservatórias',
        items: allConservatorias.map(a => {
          return {
            title: a,
            value: $_.deburrify(a)
          }
        }),
      }
    });


    updateLots(false, clientsInLots);
    // eslint-disable-next-line
  }, []);
  return (
    <Root
      setTitle={pageTitles.title(pageTitles.ACOMPANHAMENTO, pageTitles.SERVICE)}
      header
      footer
      user={props.user}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={snackState}
          onClose={handleCloseSnack}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          autoHideDuration={2000}
          message={<span id="message-id">{snackMessage}</span>}
        />
        <Menu
          id="filterMenu"
          anchorEl={filter}
          open={Boolean(filter)}
          onClose={handleFilterMenu(false)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <MenuItem
            onClick={() => handleClearCheck()}
            dense
            className={classes.menuItem}>
            Limpar
                            </MenuItem>
          <MenuItem disabled className={classes.menuHeader}>
            {items.conservatorias.title}
          </MenuItem>
          {items.conservatorias.items.map((a, index) => {
            return (
              <MenuItem key={`item${index}`} dense onClick={() => { handleCheckFilter(a) }} className={classes.menuItem}>
                <ListItemIcon className={classes.menuItemIcon}>
                  {filterItems.indexOf(a.value) >= 0 ? <CheckboxIcon /> : <CheckboxBlankIcon />}
                </ListItemIcon>
                <ListItemText primary={a.title} />
              </MenuItem>
            )
          })}
        </Menu>
        <Menu
          anchorEl={anchorDialog}
          open={Boolean(anchorDialog)}
          onClose={e => { handleVertMenuDialog(false, e) }}>
          <MenuItem>Enviar procuração</MenuItem>
          <MenuItem>Solicitar cancelamento</MenuItem>
        </Menu>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={e => handleVertMenu(false)}>
          {menus[currentClient.menu]}
        </Menu>
        <Dialog
          open={finishProcessDialog}
          maxWidth="sm"
          fullWidth
          scroll="body"
          onClose={e => confirmFinishProcess()}>
          {Object.keys(currentClient).length > 0 ?
            <>
              <DialogTitle>
                Finalizar processo <Typography component="span" variant="body2"><strong>{currentClient.id}</strong></Typography> de {currentClient.name}
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="numero-assento"
                      label="Número de assento"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => confirmFinishProcess()}
                  variant="outlined"
                  color="secondary">Cancelar</Button>
                <Button
                  onClick={() => finishProcess()}
                  variant="contained"
                  color="primary">Finalizar!</Button>
              </DialogActions>
            </>
            : <DialogContent />}
        </Dialog>
        <Dialog
          open={dialogConfirmSend}
          maxWidth="sm"
          fullWidth
          scroll="body"
          onClose={e => handleConfirmSend()}>
          {Object.keys(currentClient).length > 0 ?

            <>
              <DialogTitle>Sinalizar envio para a conservatória</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label="Data de envio"
                      format="dd/MM/yyyy"
                      value={pickedDate}
                      maxDate={new Date()}
                      InputAdornmentProps={{ position: "end" }}
                      onChange={date => handlePickDate(date)}
                    />
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined">
                      <InputLabel>Conservatória</InputLabel>
                      <Select
                        native
                        value={conservatoriaSelect}
                        onChange={e => handleSelect(e)}
                        input={
                          <OutlinedInput name="conservatoria" id="conservatoria" />
                        }>
                        <option value="" />
                        {imports.mainConservatorias.map(c => (
                          <option key={c} value={c}>{c}</option>
                        ))}
                        <option value={`outra`}>Outra</option>
                      </Select>
                    </FormControl>
                  </Grid>
                  {conservatoriaSelect === 'outra' ?
                    <Grid item>
                      <TextField
                        id="outra-conservatoria"
                        label="Outra conservatória"
                        variant="outlined"
                      />
                    </Grid>
                    : null}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={e => handleConfirmSend()}
                  variant="outlined"
                  color="secondary">Cancelar</Button>
                <Button
                  onClick={e => handleSendProcess()}
                  variant="outlined"
                  color="primary">Sinalizar</Button>
              </DialogActions>
            </>
            : <DialogContent></DialogContent>}
        </Dialog>
        <Dialog
          open={docsShown}
          onClose={e => { handleOpenDocs(false, e) }}
          aria-labelledby="dialog-docs-title"
          aria-describedby="dialog-docs-description"
        >
          {Object.keys(currentClient).length > 0 ?
            <>
              <DialogTitle id="dialog-docs-title">
                Documentos de {currentClient.name}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="dialog-docs-description">
                  Documentos
                        </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={e => { handleOpenDocs(false, e) }} variant="outlined" color="secondary">
                  Fechar
                                    </Button>
              </DialogActions>
            </>
            : <DialogContent></DialogContent>}
        </Dialog>
        <Dialog
          open={confirmBlock}
          onClose={handleBlockConfirm}
          onEntered={e => $_.focusThis('block-reason')}
          scroll="body"
          fullWidth
          maxWidth="sm"
        >
          {Object.keys(currentClient).length === 0 ? <DialogContent /> :
            <>
              <DialogTitle>
                {currentClient.blockDialog ? currentClient.blockDialog.title : `Bloquear processo`}
                <Typography color="textSecondary">{currentClient.id}</Typography>
                <Typography>{currentClient.name}</Typography>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="block-reason"
                      label="Motivo de travamento"
                      multiline
                      rows="3"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleBlockConfirm} variant="outlined" color="secondary">
                  Fechar
                                    </Button>
                <Button onClick={e => handleProcessInPortugal(currentClient.id, 'block')} variant="outlined" color="primary">
                  {currentClient.blockDialog ? currentClient.blockDialog.actionButton : `Bloquear`}
                </Button>
              </DialogActions>
            </>}
        </Dialog>
        <Dialog
          open={showFile}
          onClose={e => { handleCloseFile(e); }}
          aria-labelledby="dialog-docs-title"
          aria-describedby="dialog-docs-description"
        >
          {Object.keys(currentFile).length > 0 ?
            <>
              <DialogTitle id="dialog-docs-title">
                Comprovante
                                </DialogTitle>
              <DialogContent>
                Arquivo: {currentFile.title}
              </DialogContent>
              <DialogActions>
                <Button onClick={e => { handleCloseFile(e); }} variant="outlined" color="secondary">
                  Fechar
                                    </Button>
              </DialogActions>
            </>
            : <DialogContent></DialogContent>}
        </Dialog>
        <Dialog
          open={moreInfoDialog}
          onClose={handleCloseMoreInfo}
          fullWidth
          maxWidth="md"
          scroll="body">
          <DialogContent className={classes.dialogMoreInfo}>
            {Object.keys(currentClient).length > 0
              ?
              <>
                <Grid container spacing={2} className={classes.gridContainer}>
                  <IconButton onClick={(e) => handleCloseMoreInfo(e)} className={classes.closeBtn} >
                    <CloseIcon />
                  </IconButton>

                  <IconButton
                    aria-label="More"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    className={classes.moreverticonDialog}
                    onClick={e => { handleVertMenuDialog(true, e) }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Grid item xs={12} md={8} className={classes.beforeMdPadding}>
                    <Typography variant="h4">{currentClient.name}</Typography>
                    <Typography variant="h6">{currentClient.id}</Typography>
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.fromMdPadding}>
                    <Typography className={clsx(classes.pLine)}>
                      <EmailIcon className={classes.icon} color="disabled" fontSize="inherit" />
                      <CopyElement setStates={{ snackMessage: setSnackMessage, snackState: setSnackState }}>{currentClient.email}</CopyElement>
                    </Typography>
                    <Typography className={clsx(classes.pLine)}>
                      <PhoneIcon className={classes.icon} color="disabled" fontSize="inherit" />
                      <CopyElement setStates={{ snackMessage: setSnackMessage, snackState: setSnackState }}>{currentClient.phone}</CopyElement>
                    </Typography>
                    <Typography><Link component="button" onClick={e => { showDocs(currentClient, e) }} underline="always" className={classes.link}>Documentos</Link><Link href="acompanhamento" underline="always" className={classes.link}>Contrato</Link></Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.gridContainer}>
                  <Grid item>
                    <Typography color="primary" gutterBottom>Data de entrada</Typography>
                    <Typography variant="subtitle2">{$_.semanticDate(currentClient.dateInitialised, true)}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography color="primary" gutterBottom>Assento</Typography>
                    <Typography variant="subtitle2">Em busca</Typography>
                  </Grid>
                  <Grid item>
                    <Typography color="primary" gutterBottom>Tipo de processo</Typography>
                    <Typography variant="subtitle2">{processTypes.length > 0 ? processTypes.find(a => { return a.title === currentClient.applicants[0].hiredServices[0]; }).titleClient : ''}</Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.gridContainer}>
                  <Grid item xs={12}>
                    <Typography variant="h5" paragraph>Cidadãos portugueses da família</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {currentClient.registeredCitizens.map((citizen, index) => {
                      return (
                        <Paper key={`${citizen.id}`} className={classes.smallPaper} elevation={1}>
                          <Typography variant="h6">
                            {citizen.name}
                          </Typography>
                          <Typography variant="subtitle2">
                            Nascimento: {citizen.birthDate}
                          </Typography>
                        </Paper>
                      )
                    })}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" paragraph>Outros processos da família</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {currentClient.applicants.map((applicant, index) => {
                      return (
                        <Paper key={`${applicant.id}`} className={classes.smallPaper} elevation={1}>
                          <Typography variant="subtitle2">
                            {applicant.id}
                          </Typography>
                          <Typography variant="h6">
                            {applicant.name}
                          </Typography>
                        </Paper>
                      )
                    })}
                  </Grid>
                </Grid>
              </>
              : null}
            <>
              <Typography variant="h5" paragraph>Comunicação interna</Typography>
              <Comments
                inverted
                allowed
                master
                step={1}
                label={`Escreva seu comentário`}
              />
            </>
          </DialogContent>
        </Dialog>
        <Dialog
          open={arrival}
          scroll="body"
          PaperProps={{ square: true }}
          onClose={e => { handleDialogDatePick(false, e) }}>
          <DialogContent style={{ padding: 0 }}>
            <KeyboardDatePicker
              placeholder="Selecionar data"
              value={pickedDate}
              label=""
              variant="static"
              onChange={date => { handlePickDate(date); }}
              format="dd/MM/yyyy"
              maxDate={new Date()}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={e => { handleDialogDatePick(false, e, (currentClient && currentClient.dialogToSend)) }}
              color="secondary">
              {(currentClient && currentClient.dialogToSend) ? `Fechar` : `Cancelar`}
            </Button>
            {(currentClient && currentClient.dialogToSend) ? null :
              <Button
                variant="outlined"
                color="primary"
                onClick={e => handleConfirmArrived(e)}
                disabled={setPickedDate === null}>
                Confirmar
                            </Button>
            }
          </DialogActions>
        </Dialog>
        <div className={classes.minWidthHolder}>
          <Container className={classes.minWidthContainer} maxWidth="xl">
            <Grid container spacing={2} className={classes.subMenuHolder}>
              <Grid item xs={6} className={classes.tabsGrid}>
                <Tabs
                  value={menuValue}
                  onChange={handleMenu}
                  textColor="primary"
                  classes={{ indicator: classes.tabIndicator }}
                  variant="standard">
                  {tabs.map((tab, i) => {
                    const link = `${window.location.origin}/servico/${$_.deburrify(pageTitle)}/${tabsDeburr[i]}`;
                    return (
                      <Tab
                        key={`tab${tab}`}
                        onClick={event => {
                          window.history.pushState(tabsDeburr[i], tab, `/servico/${$_.deburrify(pageTitle)}/${tabsDeburr[i]}`);
                          event.preventDefault();
                        }}
                        href={link}
                        component={Link}
                        underline="none"
                        className={classes.tabButton}
                        aria-label={tab}
                        label={
                          <Typography
                            className={classes.tabLink}
                            variant="body2">{tab}</Typography>
                        } />

                    )
                  })}
                </Tabs>
              </Grid>

              <Grid item xs={6} className={clsx(classes.btnholder, classes.tabsGrid)}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Buscar..."
                    onChange={(e) => { handleSearch(e) }}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    value={searchValue}
                    endAdornment={
                      <IconButton onClick={(e) => clearSearch(e)} classes={{ root: classes.closeBtnTiny }} style={{ opacity: Boolean(searchValue) ? 1 : 0 }} size="small">
                        <CloseIcon fontSize="small" classes={{ root: classes.closeBtnTinyIcon }} />
                      </IconButton>
                    }
                  />
                </div>
                <Tooltip title={menuValue === 0 ? `Ordenar pacotes por data de envio` : `Ordenar por data`}>
                  <Button
                    variant="contained"
                    onClick={handleSortMenu}
                    className={classes.buttonGroup}
                    aria-label="Ascendente ou decrescente"
                  >
                    Ordenar<Icon style={{ fontSize: '1.2rem' }} className={clsx('fas', sortItems ? 'fa-sort-numeric-up' : 'fa-sort-numeric-down', classes.iconMargin)} />
                  </Button>
                </Tooltip>
                <div className={clsx(classes.transition, menuValue === 2 ? 'in' : 'out')}>
                  <Button
                    variant="contained"
                    onClick={handleFilterMenu(true)}
                    className={classes.buttonGroup}
                    aria-owns={filter ? 'filterMenu' : undefined}
                    aria-haspopup="true"
                  >
                    Filtrar
                                                    <FilterIcon className={classes.btnIcon} />
                  </Button>
                </div>
              </Grid>
            </Grid>

            <Collapse in={menuValue === 0}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography
                    className={classes.pageHeader}
                    variant="h5"
                    color="textSecondary">
                    {`Pacote${filteredServiceClients[0].length > 1 ? 's' : ''} enviado${filteredServiceClients[0].length > 1 ? 's' : ''} para portugal (${filteredServiceClients[0].length})`}
                  </Typography>

                  {filteredServiceClients[0].length > 0 ?
                    <Paper className={clsx(classes.header)}>
                      <Grid container spacing={2}>
                        <Grid item xs={7}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}>
                              <Typography variant="body2">Rastreio</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="body2">Enviado em</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="body2">Chegou em</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={5}>
                        </Grid>
                      </Grid>
                    </Paper>
                    : null}
                  {filteredServiceClients[0].length > 0 ?
                    filteredServiceClients[0].map((lot, index) => {
                      const singleResult = filteredServiceClients[0].length === 1;
                      const thisexpanded = expanded === lot.id || singleResult;
                      const arrived = Boolean(lot.arrivalDate);
                      const processes = serviceClients.filter(a => lot.processes.indexOf(a.id) >= 0 && a.inLotToArrive);
                      if (processes.length === 0)
                        return null;
                      else
                        return (
                          <Paper
                            key={`pacotes${lot.id}`}
                            square
                            className={
                              clsx(
                                classes.body,
                                'paddingNone',
                              )
                            }>
                            <Paper
                              square
                              className={
                                clsx(
                                  classes.body,
                                  'inner',
                                )
                              }>
                              <Grid container spacing={2} className={classes.btnContainer}>
                                {singleResult ? null :
                                  <IconButton
                                    onClick={e => handleExpand(lot.id, e)}
                                    className={clsx(classes.floatingButtons, classes.floatingLeft)}
                                    aria-label="Expandir">
                                    <ExpandMoreIcon className={clsx(classes.expandIcon, thisexpanded ? 'expanded' : 'regular')} />
                                  </IconButton>}
                                <Grid item xs={7}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={4} className={classes.centeredGrid}>
                                      <Typography variant="body1">
                                        <CopyElement setStates={{ snackMessage: setSnackMessage, snackState: setSnackState }}>
                                          {lot.tracking}
                                        </CopyElement>
                                      </Typography>
                                      <Typography variant="body1" color="textSecondary">
                                        {`${processes.length} processo${processes.length > 1 ? 's' : ''}`}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={4} className={classes.centeredGrid}>
                                      <Typography variant="body1">
                                        {$_.semanticDate(lot.sentDate)}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={4} className={classes.centeredGrid}>
                                      <Typography variant="body1">
                                        {arrived ? $_.semanticDate(lot.arrivalDate) : ``}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={5} className={classes.centeredGrid}>
                                  <Button
                                    variant={arrived ? 'outlined' : 'contained'}
                                    color={arrived ? 'secondary' : 'primary'}
                                    onClick={e => { handleDialogDatePick(true, e, lot) }}
                                    classes={{ containedPrimary: classes.alertButton }}
                                    fullWidth>
                                    {arrived ? `Editar data` : `Sinalizar chegada`}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Paper>
                            {processes.length <= 0 ? null :
                              <Collapse in={thisexpanded}>
                                <Paper square className={classes.innerHeader} elevation={0}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                      <Typography variant="body2">Cliente</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Typography variant="body2" align="center">Emitido em</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Typography variant="body2" align="right" style={{ paddingRight: 12 }}>Ação</Typography>
                                    </Grid>
                                  </Grid>
                                </Paper>
                                {processes.map(process => {
                                  return (
                                    <Paper
                                      key={`${lot.id}processoempacote${process.id}`}
                                      square
                                      elevation={0}
                                      className={classes.innerBody}>
                                      <Grid container spacing={2}>
                                        <Grid item xs={4} className={classes.centeredGrid}>
                                          <Typography variant="body1" color="textSecondary">
                                            <CopyElement setStates={{ snackMessage: setSnackMessage, snackState: setSnackState }}>{process.id}</CopyElement>
                                          </Typography>
                                          <Typography variant="body1" color="textSecondary">
                                            <CopyElement setStates={{ snackMessage: setSnackMessage, snackState: setSnackState }}>{process.name}</CopyElement>
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.centeredGrid}>
                                          <Typography variant="body1" align="center">{$_.semanticDate(process.dateOkHague)}</Typography>
                                        </Grid>
                                        <Grid item xs={4} className={clsx(classes.centeredGrid, 'row')}>
                                          <Tooltip
                                            title={arrived
                                              ? `Bloquear processo`
                                              : `Por favor, sinalize a chegada do pacote`}>
                                            <div>
                                              <IconButton
                                                onClick={e => handleBlockConfirm(process, lot, true)}
                                                disabled={!arrived}
                                                className={classes.error}>
                                                <BlockIcon />
                                              </IconButton>
                                            </div>
                                          </Tooltip>
                                          <Tooltip
                                            title={arrived
                                              ? `O processo chegou e está correto`
                                              : `Por favor, sinalize a chegada do pacote`}>
                                            <div>
                                              <IconButton
                                                onClick={e => handleProcessInPortugal(process.id, 'ok')}
                                                disabled={!arrived}
                                                className={classes.primary}>
                                                <CheckIcon />
                                              </IconButton>
                                            </div>
                                          </Tooltip>
                                        </Grid>
                                      </Grid>
                                    </Paper>
                                  )
                                })}
                              </Collapse>}
                          </Paper>
                        )
                    })
                    :
                    <NoResults message={`Nenhum pacote encontrado`} icon="lot" />
                  }
                </Grid>
              </Grid>
            </Collapse>
            <Collapse in={menuValue === 1}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Typography
                    className={clsx(classes.pageHeader, 'small')}
                    variant="h5"
                    color="textSecondary">
                    {`Travados (${filteredServiceClients[1].filter(a => a.blocked).length})`}
                  </Typography>
                  {filteredServiceClients[1].filter(a => a.blocked).length === 0
                    ?
                    <NoResults message={`Nenhum processo travado encontrado`} />
                    :
                    <>
                      <Paper square className={clsx(classes.header, 'small')}>
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <Typography variant="body2">Cliente</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body2">Travado em</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">Motivo</Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                      {filteredServiceClients[1].filter(a => a.blocked).map(process => {
                        return (
                          <Paper square className={clsx(classes.body, 'small')} key={`blocked${process.id}`}>
                            <Grid container spacing={2}>
                              <Grid item xs={3} className={classes.centeredGrid}>
                                <Typography color="textSecondary"><CopyElement setStates={{ snackMessage: setSnackMessage, snackState: setSnackState }}>{process.id}</CopyElement></Typography>
                                <Typography><CopyElement setStates={{ snackMessage: setSnackMessage, snackState: setSnackState }}>{process.name}</CopyElement></Typography>
                              </Grid>
                              <Grid item xs={3} className={classes.centeredGrid}>
                                <Typography>{$_.semanticDate(process.blockDate, true)}</Typography>
                              </Grid>
                              <Grid item xs={6} className={classes.centeredGrid}>
                                <Typography title={process.blockReason}>{$_.limitChars(process.blockReason, 310)}</Typography>
                              </Grid>
                              <IconButton
                                aria-label="More"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                className={classes.moreverticon}
                                onClick={e => { handleVertMenu(process, e, 'block') }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </Grid>
                          </Paper>
                        )
                      })}
                    </>
                  }
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className={clsx(classes.pageHeader, 'small')}
                    variant="h5"
                    color="textSecondary">
                    {`Para enviar (${filteredServiceClients[1].filter(a => !a.blocked).length})`}
                  </Typography>
                  {filteredServiceClients[1].filter(a => !a.blocked).length === 0
                    ?
                    <NoResults message={`Nenhum processo para enviar encontrado`} />
                    :
                    <>
                      <Paper square className={clsx(classes.header, 'small')}>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <Typography variant="body2">Cliente</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body2">Data de chegada</Typography>
                          </Grid>
                          <Grid item xs={5}>
                          </Grid>
                        </Grid>
                      </Paper>
                      {filteredServiceClients[1].filter(a => !a.blocked).map(process => {
                        return (
                          <Paper square className={clsx(classes.body, 'small')} key={`tosend${process.id}`}>
                            <Grid container spacing={2}>
                              <Grid item xs={4} className={classes.centeredGrid}>
                                <Typography color="textSecondary"><CopyElement setStates={{ snackMessage: setSnackMessage, snackState: setSnackState }}>{process.id}</CopyElement></Typography>
                                <Typography><CopyElement setStates={{ snackMessage: setSnackMessage, snackState: setSnackState }}>{process.name}</CopyElement></Typography>
                              </Grid>
                              <Grid item xs={3} className={classes.centeredGrid}>
                                <Typography>{$_.semanticDate(process.arrivalDate, true)}</Typography>
                              </Grid>
                              <Grid item xs={5} className={classes.centeredGrid}>
                                <Button
                                  onClick={e => handleConfirmSend(process)}
                                  variant="contained"
                                  color="primary">Sinalizar envio</Button>
                              </Grid>
                              <IconButton
                                aria-label="More"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                className={classes.moreverticon}
                                onClick={e => { handleVertMenu(process, e, 'tosend') }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </Grid>
                          </Paper>
                        )
                      })}
                    </>
                  }
                </Grid>
              </Grid>
            </Collapse>
            <Collapse in={menuValue === 2}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Typography
                    className={clsx(classes.pageHeader, 'small')}
                    variant="h5"
                    color="textSecondary">
                    {`Em exigência (${filteredServiceClients[2].filter(a => a.blocked).length})`}
                  </Typography>
                  {filteredServiceClients[2].filter(a => a.blocked).length === 0
                    ?
                    <NoResults message={`Nenhum processo em exigência encontrado`} />
                    :
                    <>
                      <Paper square className={clsx(classes.header, 'small')}>
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <Typography variant="body2">Cliente</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body2">Exigência em</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography variant="body2">Conservatória</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="body2">Motivo</Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                      {filteredServiceClients[2].filter(a => a.blocked).map(process => {
                        return (
                          <Paper square className={clsx(classes.body, 'small')} key={`blocked${process.id}`}>
                            <Grid container spacing={2}>
                              <Grid item xs={3} className={classes.centeredGrid}>
                                <Typography color="textSecondary"><CopyElement setStates={{ snackMessage: setSnackMessage, snackState: setSnackState }}>{process.id}</CopyElement></Typography>
                                <Typography><CopyElement setStates={{ snackMessage: setSnackMessage, snackState: setSnackState }}>{process.name}</CopyElement></Typography>
                              </Grid>
                              <Grid item xs={3} className={classes.centeredGrid}>
                                <Typography>{$_.semanticDate(process.blockDate, true)}</Typography>
                              </Grid>
                              <Grid item xs={2} className={classes.centeredGrid}>
                                <Typography>{process.conservatoria}</Typography>
                              </Grid>
                              <Grid item xs={4} className={classes.centeredGrid}>
                                <Typography title={process.blockReason}>{$_.limitChars(process.blockReason, 200)}</Typography>
                              </Grid>
                              <IconButton
                                aria-label="More"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                className={classes.moreverticon}
                                onClick={e => { handleVertMenu(process, e, 'demand') }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </Grid>
                          </Paper>
                        )
                      })}
                    </>
                  }
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className={clsx(classes.pageHeader, 'small')}
                    variant="h5"
                    color="textSecondary">
                    {`Em andamento (${filteredServiceClients[2].filter(a => !a.blocked).length})`}
                  </Typography>
                  {filteredServiceClients[2].filter(a => !a.blocked).length === 0
                    ?
                    <NoResults message={`Nenhum processo em andamento encontrado`} />
                    :
                    <>
                      <Paper square className={clsx(classes.header, 'small')}>
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <Typography variant="body2">Cliente</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body2">Data de entrada</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography variant="body2">Conservatória</Typography>
                          </Grid>
                          <Grid item xs={4}>
                          </Grid>
                        </Grid>
                      </Paper>
                      {filteredServiceClients[2].filter(a => !a.blocked).map(process => {
                        return (
                          <Paper square className={clsx(classes.body, 'small')} key={`tosend${process.id}`}>
                            <Grid container spacing={2}>
                              <Grid item xs={3} className={classes.centeredGrid}>
                                <Typography color="textSecondary"><CopyElement setStates={{ snackMessage: setSnackMessage, snackState: setSnackState }}>{process.id}</CopyElement></Typography>
                                <Typography><CopyElement setStates={{ snackMessage: setSnackMessage, snackState: setSnackState }}>{process.name}</CopyElement></Typography>
                              </Grid>
                              <Grid item xs={3} className={classes.centeredGrid}>
                                <Typography>{$_.semanticDate(process.conservatoriaDate, true)}</Typography>
                              </Grid>
                              <Grid item xs={2} className={classes.centeredGrid}>
                                <Typography>{process.conservatoria}</Typography>
                              </Grid>
                              <Grid item xs={4} className={classes.centeredGrid}>
                                <Button
                                  onClick={() => confirmFinishProcess(process)}
                                  variant="contained"
                                  color="primary">Concluir</Button>
                              </Grid>
                              <IconButton
                                aria-label="More"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                className={classes.moreverticon}
                                onClick={e => { handleVertMenu(process, e, 'monitoring') }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </Grid>
                          </Paper>
                        )
                      })}
                    </>
                  }
                </Grid>
              </Grid>
            </Collapse>
          </Container>
        </div>
      </MuiPickersUtilsProvider>
    </Root>
  );
}

export default Acompanhamento;
