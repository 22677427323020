import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchMoreHistory, fetchAllHistory } from 'store/actions/sales';
import {
  Grid, Paper, Typography,
} from '@material-ui/core';
import ActionsContext from 'contexts/actions';
import TabHeader from 'sales/dashboard/components/MasterTabHeader';
import TabRowHistoric from 'sales/dashboard/components/TabRowHistoric';
import { LoadingComponent } from 'global/modules/Loading';
import { REQUESTING, FULFILLED, DEFAULT } from 'constants/common';
import NoResults from 'global/modules/NoResults';
import { SaleDashboardContext } from 'contexts/saleDashboard';
import { setSalesStep } from 'sales/functions';
import { useStyles } from './style';
import { getCancelToken, cancel } from 'api';
import LoadMore from './modules/LoadMore';

function MasterTabHistoric({
  tab,
  tabKey,
  historicReq,
  update,
  allSalesReq,
  status,
  fetchAll,
  fetchMore,
  fetchMoreStatus,
  currentPage,
  ...props
}) {
  const classes = useStyles(false);
  const { reload } = useContext(ActionsContext);
  const { setCurrentClient, currentClient, q, sortBy, sortOrder } = useContext(SaleDashboardContext);
  const [historic, setHistoric] = useState([...historicReq]);
  const [allSales, setAllSales] = useState([...allSalesReq]);
  const [requesting, setRequesting] = useState(false);
  const cancelSource = React.useRef(null);

  const handleFetchMore = () => {
    fetchMore(cancelSource.current.token, currentPage + 1, sortBy, sortOrder, q);
  };

  useEffect(() => {
    if (status === FULFILLED && requesting) {
      setRequesting(false);
    }
    setHistoric([...historicReq]);
    setAllSales([...allSalesReq]);
  }, [status, allSalesReq, historicReq, requesting]);

  const historicAvecSteps = historic.map((a) => setSalesStep(a));

  useEffect(() => {
    cancelSource.current = getCancelToken().source();
    if (!requesting) {
      setRequesting(true);
      fetchAll(cancelSource.current.token, currentPage, sortBy, sortOrder, q);
    }
    return () => cancel(cancelSource.current);
    // eslint-disable-next-line
  }, [fetchAll, update, reload]);

  useEffect(() => {
    if (!requesting && status !== REQUESTING && status !== DEFAULT) {
      setRequesting(true);
      fetchAll(cancelSource.current.token, currentPage, sortBy, sortOrder, q);
    }
    // eslint-disable-next-line
  }, [sortBy, sortOrder, q]);

  useEffect(() => {
    if (status === FULFILLED) {
      const maintainClient = allSales.find((a) => a.id === currentClient.id);
      setCurrentClient(maintainClient || currentClient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, allSales]);

  return (
    <Grid>
      <TabHeader
        title={tab.title}
        tabKey={tabKey}
        itemsSort={[
          {
            title: 'Última atualização',
            value: 'updated_at',
          },
          {
            title: 'Entrada',
            value: 'id',
          },
        ]}
      />
      {historicAvecSteps
        && status === FULFILLED
        && historicAvecSteps.length === 0
        ? <NoResults message="Nenhuma venda encontrada" />
        : (
          <Paper className={classes.header}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Cliente</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Informações</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} className={classes.middleGridPadding}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2">Venda</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" component="p" align="center">Contrato</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" component="p" align="right">Pagamento</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} className={classes.lastChildPadding}>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Typography variant="subtitle2">Serviços</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        )}
      {historicAvecSteps
        && historicAvecSteps.length > 0
        && historicAvecSteps.map((item, i) => (
          <TabRowHistoric
            key={`history${item.id}`}
            index={i}
            item={item}
          />
        ))}
      {status === REQUESTING && fetchMoreStatus !== REQUESTING
        && <LoadingComponent />}
      {historicAvecSteps && historicAvecSteps.length > 0 && status !== DEFAULT && (
        <LoadMore action={handleFetchMore} />
      )}
    </Grid>
  );
}

MasterTabHistoric.propTypes = {
  tab: PropTypes.object.isRequired,
  tabKey: PropTypes.number.isRequired,
  historicReq: PropTypes.array.isRequired,
  update: PropTypes.string.isRequired,
  allSalesReq: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  fetchAll: PropTypes.func.isRequired,
  fetchMore: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  allSalesReq: state.sales.allSales,
  historicReq: state.sales.collection,
  status: state.sales.status,
  fetchMoreStatus: state.sales.fetchMoreStatus,
  totalPages: state.sales.totalPages,
  currentPage: state.sales.currentPage,
});

const actions = {
  fetchAll: fetchAllHistory,
  fetchMore: fetchMoreHistory,
};

export default connect(mapStateToProps, { ...actions })(MasterTabHistoric);
