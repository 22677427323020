import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { updateProfile } from 'store/actions/session';
import { UserContext } from 'contexts/user';
import { NAME, EMAIL, PHONE } from 'constants/session/userFormFields';
import {
  Grid, Paper, Typography, TextField, Button,
} from '@material-ui/core';
import PhoneField from 'global/fields/PhoneField';
import { formatPhoneNumber } from 'react-phone-number-input';
import { showNotification } from 'store/actions/notifications';
import { NOTIFICATION_TYPE_ERROR } from 'constants/notification';
import { prepareToSendContact, validateContact } from './functions';
import { useStyles } from './style';
import clsx from 'clsx';

function ContactForm({ sizing, ...props }) {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const initialForm = {
    [NAME]: user[NAME],
    [EMAIL]: user[EMAIL],
    [PHONE]: formatPhoneNumber(user[PHONE]),
  };
  const [form, setForm] = useState(initialForm);

  const handleChange = (name, value) => setForm({ ...form, [name]: value || '' });

  const handleSubmit = (event) => {
    event.preventDefault();
    const preparedForm = prepareToSendContact(form);
    validateContact(preparedForm)
      .then(() => props.updateProfile(user.id, preparedForm))
      .catch((error) => props.showNotification(NOTIFICATION_TYPE_ERROR, error.message));
  };

  return (
    <Grid item {...sizing}>
      <Paper
        elevation={1}
        className={classes.paper}
      >
        <Typography
          variant="h6"
          gutterBottom
          className={classes.header}
        >
          Contato
        </Typography>
        <Grid container spacing={2}>
          <form onSubmit={handleSubmit} style={{ width: '100%', margin: '0 8px' }}>
            <Grid item xs={12} className={classes.formRow}>
              <TextField
                fullWidth
                variant="outlined"
                name={NAME}
                label="Nome"
                value={form[NAME]}
                required
                onChange={(e) => handleChange(NAME, e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.formRow}>
              <TextField
                fullWidth
                variant="outlined"
                type="email"
                name={EMAIL}
                label="Email"
                value={form[EMAIL]}
                required
                onChange={(e) => handleChange(EMAIL, e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.formRow}>
              <PhoneField
                fullWidth
                variant="outlined"
                name={PHONE}
                label="Telefone"
                value={form[PHONE]}
                onChange={(e) => handleChange(PHONE, e)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} className={clsx(classes.formRow, 'btnHolder')}>
              <Typography align="right">
                <Button
                  variant={isEqual(form, initialForm) ? 'outlined' : 'contained'}
                  color="primary"
                  type="submit"
                  disabled={isEqual(form, initialForm)}
                >
                  Salvar alterações
                </Button>
              </Typography>
            </Grid>
          </form>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default connect(null, { updateProfile, showNotification })(ContactForm);
