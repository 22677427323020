import React from 'react';
import uniqid from 'uniqid';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogActions, Button, DialogTitle } from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import TabPanel, { a11yProps } from '../TabPanel';
import { useStyles } from '../styles';
import { getFormInfoObj, getFormRequirementObj, getFormPriceObj, postProductObj, validCreate } from '../functions';
import PriceInfo from '../PriceInfo';
import RequirementInfo from '../RequirementInfo';
import ProductInfo from '../ProductInfo';
import { createProduct } from 'store/actions/product';
import { showNotification } from 'store/actions/notifications';
import { NOTIFICATION_TYPE_ERROR } from 'constants/notification';
import { FULFILLED, REJECTED } from 'constants/common';

const AddDialog = ({ open, handleToggle, setUpdate, setOpen, create, status, notification }) => {
  const classes = useStyles({});
  const [loading, setLoading] = React.useState(false);
  const [view, setView] = React.useState(0);
  const [formInfo, setFormInfo] = React.useState(getFormInfoObj({ category: 0 }));
  const product = {
    title: formInfo.title,
    prices: [
      {
        ...getFormPriceObj({}),
        id: 'new',
      },
    ],
    requirements: [
      {
        ...getFormRequirementObj(),
        id: 'new',
      },
    ]
  };
  const [formRequirement, setFormRequirement] = React.useState(product.requirements);
  const [formPrices, setFormPrices] = React.useState(product.prices);
  const [errors, setErrors] = React.useState([]);
  const handleChangeView = (event, newValue) => setView(newValue);
  const handleAdd = (e) => {
    if (!loading) {
      setErrors([]);
      try {
        validCreate(formInfo, formPrices, formRequirement);
        create(postProductObj(formInfo, formPrices, formRequirement));
        setLoading(true);
      } catch (error) {
        setErrors(error);
        notification(NOTIFICATION_TYPE_ERROR, 'Por favor, preencha todos os campos');
      }
    }
    e.preventDefault();
  };
  React.useEffect(() => {
    const clearAll = () => {
      setOpen(false);
      setFormInfo(getFormInfoObj({ category: 0 }));
      setFormRequirement(product.requirements);
      setFormPrices(product.prices);
    }
    setLoading((o) => {
      if ((status === FULFILLED || status === REJECTED) && o) {
        setUpdate(uniqid());
        if (status !== REJECTED) clearAll();
        return false;
      }
      return o;
    });
  }, [status, setUpdate, setOpen, product.requirements, product.prices]);
  return (
    <Dialog
      onClose={handleToggle}
      open={open}
      scroll="body"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>{'Adicionar produto'}</DialogTitle>
      <DialogContent>
        <Tabs
          value={view}
          onChange={handleChangeView}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label={`Menu do processo de ${formInfo.title}`}
        >
          <Tab label="Informações" {...a11yProps(0)} />
          <Tab label="Documentação" {...a11yProps(1)} />
          <Tab label="Preços" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={view} index={0}>
          <ProductInfo
            dialogErrors={errors}
            formInfo={formInfo}
            setFormInfo={setFormInfo}
            product={product}
            classes={classes}
            setUpdate={setUpdate}
            newProduct
          />
        </TabPanel>
        <TabPanel value={view} index={1}>
          <RequirementInfo
            formRequirement={formRequirement}
            setFormRequirement={setFormRequirement}
            product={product}
            classes={classes}
            setUpdate={setUpdate}
            newProduct
          />
        </TabPanel>
        <TabPanel value={view} index={2}>
          <PriceInfo
            formPrices={formPrices}
            setFormPrices={setFormPrices}
            product={product}
            classes={classes}
            setUpdate={setUpdate}
            newProduct
          />
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleToggle}>
          {'Cancelar'}
        </Button>
        <Button variant="outlined" color="primary" onClick={handleAdd}>
          {'Adicionar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddDialog.propTypes = {
  open: PropTypes.bool,
  handleToggle: PropTypes.func.isRequired,
};

AddDialog.defaultProps = {
  open: false,
};

const mapStateToProps = (state) => ({
  status: state.product.status,
});

const actions = {
  create: createProduct,
  notification: showNotification,
};

export default connect(mapStateToProps, actions)(AddDialog);
