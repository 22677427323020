import * as definitions from '../definitions/session';

export function login(username, password, previous) {
  return {
    type: definitions.LOGIN,
    payload: {
      username,
      password,
      previous,
    },
  };
}

export function checkAuth(previous) {
  return {
    type: definitions.CHECK_AUTH,
    payload: { previous },
  };
}

export function logout(previous) {
  return {
    type: definitions.LOGOUT,
    payload: { previous },
  };
}

export function updateProfile(id, fields) {
  return {
    type: definitions.UPDATE_USER_PROFILE,
    payload: { id, fields },
  };
}

export function changePassword(id, data) {
  return {
    type: definitions.CHANGE_PASSWORD,
    payload: { id, data },
  };
}
