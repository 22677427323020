import React from 'react';
import uniqid from 'uniqid';
import * as PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { ADD_MODE, EDIT_MODE } from 'constants/user';
import RequirementRow from './Row';

const RequirementInfo = ({
  formRequirement,
  setFormRequirement,
  product,
  classes,
  setUpdate,
  newProduct,
}) => {
  const [expanded, setExpanded] = React.useState(formRequirement.length === 1 ? formRequirement[0].id : '');
  const handleExpand = (id) => setExpanded((e) => e === id ? '' : id);
  if (formRequirement.length) {
    return (
      <Grid item xs={12}>
        {formRequirement.filter((r) => r.id !== 'new').map((requirement) => (
          <RequirementRow
            key={requirement.id || uniqid()}
            product={product}
            requirement={requirement}
            expanded={expanded}
            handleExpand={handleExpand}
            formRequirement={formRequirement}
            setFormRequirement={setFormRequirement}
            mode={EDIT_MODE}
            classes={classes}
            setUpdate={setUpdate}
            newProduct={newProduct}
          />
        ))}
        {formRequirement.find((r) => r.id === 'new') && (
          <RequirementRow
            key={'new'}
            product={product}
            requirement={formRequirement.find((r) => r.id === 'new')}
            expanded={expanded}
            handleExpand={handleExpand}
            formRequirement={formRequirement}
            setFormRequirement={setFormRequirement}
            mode={ADD_MODE}
            classes={classes}
            setUpdate={setUpdate}
            newProduct={newProduct}
          />
        )}
      </Grid>
    )
  }
  return null;
}

RequirementInfo.propTyoes = {
  formRequirement: PropTypes.shape({}).isRequired,
  setFormRequirement: PropTypes.func.isRequired,
  product: PropTypes.shape({}).isRequired,
  classes: PropTypes.array.isRequired,
  newProduct: PropTypes.bool,
  setUpdate: PropTypes.func.isRequired,
};

RequirementInfo.defaultProps = {
  newProduct: false,
};

export default RequirementInfo;
