import React from 'react';
import { PROCESS } from 'constants/service';

export const ServiceContext = React.createContext({
  currentTab: 0,
  currentTitle: '',
  multipleSelect: [],
  currentClient: {},
  currentProcess: {},
  currentInfoObj: {},
  currentCheck: {},
  currentLot: {},
  processDetails: false,
  showMoreInfo: false,
  openComments: false,
  docsShown: false,
  searchValue: '',
  block: false,
  sortItems: false,
  editLotDialog: false,
  whichMenu: '',
  anchorEl: { },
  expanded: '',
  confirmEndStep: false,
  confirmBlockDialog: false,
  checkDialog: false,
  objType: PROCESS,
  infoType: PROCESS,
  date: null,
  datePickDialog: false,
  setObjType: (type) => { },
  setDocsShown: (client, e, type) => { },
  setSortItems: () => { },
  setCurrentProcess: () => { },
  setCurrentInfoObj: (obj) => { },
  setSearchValue: (value) => { },
  setWhichMenu: (menu) => { },
  handleShowMoreInfo: (open, current, e, type) => { },
  setCurrentTab: (tab) => { },
  setOpenComments: (current) => { },
  setAnchorEl: (anchor) => { },
  openMenu: (open, menu, e, currentClient) => { },
  handleProcessDetails: (open, currentProcess, e) => { },
  setCurrentClient: (client) => { },
  clearMultipleSelect: () => { },
  setMultipleSelect: (id, e) => { },
  setExpanded: (expand) => { },
  handleExpanded: (expand) => { },
  handleDatePickDialog: (open, client, item, lot, currentDate) => { },
  handleConfirmEndStepDialog: (client) => { },
  handleCheckDialog: (client) => { },
  setCurrentCheck: (check) => { },
  handleBlockDialog: (client, block) => { },
  setDate: (date) => { },
  handleLotDialog: (lot) => { },
  handleEditLotDialog: (lot) => { },
});
