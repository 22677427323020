export const returnCustomerObj = (currentCustomer, fetchClientSales, isProcess, infos) => {
  let infosObj = { ...infos };
  if ((isProcess && currentCustomer.sales_scope) || (!isProcess && currentCustomer.customer)) {
    fetchClientSales(isProcess ? currentCustomer.sales_scope.customer.id : currentCustomer.customer.id);
    const scopeId = isProcess ? currentCustomer.sales_scope.client_id : currentCustomer.client_id;
    const customer = isProcess ? currentCustomer.sales_scope.customer : currentCustomer.customer;
    const processId = isProcess ? currentCustomer.identifier : '';
    const scopeInternalId = isProcess ? currentCustomer.sales_scope_id : currentCustomer.id;
    infosObj = {
      ...infosObj,
      scopeId,
      customer,
      processId,
      scopeInternalId,
    }
  }
  if ((isProcess && currentCustomer.applicant) || (!isProcess && currentCustomer.customer)) {
    const clientName = isProcess ? currentCustomer.applicant.name : currentCustomer.customer.name;
    infosObj = {
      ...infosObj,
      clientName,
    };
  }
  if (!isProcess && currentCustomer.salesman) { 
    infosObj = {
      ...infosObj,
      salesman: currentCustomer.salesman,
    };
  }
  if (isProcess && currentCustomer.comments) {
    infosObj = {
      ...infosObj,
      comments: currentCustomer.comments,
    };
  }
  if (!isProcess && currentCustomer.processes) {
    infosObj = {
      ...infosObj,
      comments: currentCustomer.processes[0] ? currentCustomer.processes[0].comments : [],
    };
  }
  if ((isProcess && currentCustomer.hosts) || (!isProcess && currentCustomer.peoples && currentCustomer.peoples.filter(a => !a.applicant))) {
    const hosts = isProcess ? currentCustomer.hosts : currentCustomer.peoples.filter(a => !a.applicant);
    infosObj = {
      ...infosObj,
      hosts,
    };
  }
  if (isProcess) {
    infosObj = {
      ...infosObj,
      products: currentCustomer.products,
    };
  }

  return ({
    ...infosObj,
  })
}
