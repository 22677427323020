import React, { useEffect } from 'react';
import { Route } from 'react-router';
import { func, string } from 'prop-types';
import { connect } from 'react-redux';
import { LOGGED } from 'constants/session';
import { checkAuth } from 'store/actions/session';
import FillBasicData from './FillBasicData';

function PrivateRoute(props) {
  useEffect(() => {
    props.checkAuth(props.history.location.pathname);
    // eslint-disable-next-line
  }, []);
  const { component: Component, sessionStatus, ...rest } = props;

  return (
    <>
      {sessionStatus === LOGGED && (
        <Route
          render={(routerProps) => (
            <FillBasicData>
              <Component {...routerProps} />
            </FillBasicData>
          )}
          {...rest}
        />
      )}
    </>
  );
}

PrivateRoute.propTypes = {
  checkAuth: func.isRequired,
  component: func.isRequired,
  sessionStatus: string.isRequired,
};

const mapStateToProps = state => ({
  sessionStatus: state.session.status,
});

const actions = {
  checkAuth,
};

export default connect(mapStateToProps, actions)(PrivateRoute);
