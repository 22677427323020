import { put, takeLatest, all, call } from 'redux-saga/effects';
import * as definitions from 'store/definitions/certificates';
import * as API from 'api';
import { CERTIFICATES_PATH } from 'api/paths';

const sortByType = (arr) => {
  return arr.sort((a, b) => {
    if (a.type > b.type) return -1;
    if (a.type < b.type) return 1;
    return 0;
  });
};

function* fetchAll() {
  try {
    const payload = yield call(API.fetch, CERTIFICATES_PATH, {});
    yield put({
      type: definitions.FETCH_ALL_CERTIFICATES_FULFILLED,
      payload: sortByType(payload.products),
    });
  } catch (error) {
    yield put({ type: definitions.FETCH_ALL_CERTIFICATES_REJECTED, payload: { error } });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.FETCH_ALL_CERTIFICATES, fetchAll),
  ]);
}
