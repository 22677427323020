import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { ServiceContext } from 'contexts/service';
import clsx from 'clsx';
import {
  Close as CloseIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import {
  Grid,
  Typography,
  Tooltip,
  Button,
  IconButton,
  Icon,
  InputBase,
  Container,
} from '@material-ui/core';
import { salesSearch } from 'store/actions/sales';
import { useStyles } from '../style';

function Header({ responseSize, filteredSize, pageTitle, ...props }) {
  const classes = useStyles();
  const serviceContext = useContext(ServiceContext);
  const {
    setSearchValue, searchValue, setSortItems, sortItems,
  } = serviceContext;
  const filtered = filteredSize !== responseSize;

  useEffect(() => {
    props.salesSearch(searchValue, false, 'confirmed_date', sortItems);
    // eslint-disable-next-line
  }, [searchValue, sortItems]);

  const clearSearch = () => setSearchValue('');
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={2}
        className={classes.pageHeader}
      >
        <Grid item xs={6}>
          <Typography
            variant="h5"
            color="textSecondary"
            className={classes.pageTitle}
          >
            {`${pageTitle} (${filtered ? `${filteredSize} de ` : ''}${responseSize})`}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.btnholder}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Buscar..."
              onChange={(e) => setSearchValue(e.target.value)}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              value={searchValue}
              endAdornment={(
                <IconButton onClick={() => clearSearch()} classes={{ root: classes.closeBtnTiny }} style={{ opacity: Boolean(searchValue) ? 1 : 0 }} size="small">
                  <CloseIcon fontSize="small" classes={{ root: classes.closeBtnTinyIcon }} />
                </IconButton>
              )}
            />
          </div>
          <Tooltip title="Ordenar por data de entrada em serviços">
            <Button
              variant="contained"
              onClick={setSortItems}
              className={classes.buttonGroup}
              aria-label="Ascendente ou decrescente"
            >
              {'Ordenar'}
              <Icon style={{ fontSize: '1.2rem' }} className={clsx('fas', sortItems ? 'fa-sort-numeric-up' : 'fa-sort-numeric-down', classes.iconMargin)} />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  scopes: state.sales.allSales,
})

export default connect(mapStateToProps, { salesSearch })(Header);
