import React from 'react';
import { genders } from 'objects/imports';
import CopyElement from 'global/CopyElement';
import { FILL_LATER_TEXT } from 'constants/sales';
import { Typography } from '@material-ui/core';
import { object } from 'prop-types';

function Visualization({ citizen, info }) {
  let value = citizen[info.name];
  if (
    info.name === 'gender'
    && genders.find((a) => a.value === citizen[info.name])
    && genders.find((a) => a.value === citizen[info.name]).label
  ) {
    value = genders.find((a) => a.value === citizen[info.name]).label;
  }
  if (info.name === 'certificate_type' && citizen[info.name] && citizen[info.name].name) {
    value = citizen[info.name].name;
  }
  return (
    <Typography component={citizen[info.name] ? 'div' : 'span'}>
      {citizen[info.name] && (
        <Typography
          variant="subtitle2"
          color="textSecondary"
        >
          {`${info.label}: `}
          <CopyElement value={value} disabled={value === FILL_LATER_TEXT}>
            <Typography color="textPrimary" component="span">
              {value}
            </Typography>
          </CopyElement>
        </Typography>
      )}
    </Typography>
  );
}

Visualization.propTypes = {
  citizen: object.isRequired,
  info: object.isRequired,
};

export default Visualization;
