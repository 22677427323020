import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  Divider,
} from '@material-ui/core/';
import { COMMENT_ALL_PROCESS_BY_SALE, POST_COMMENT_PROCESS } from 'store/definitions/comments';
import { COMMENT_TYPE_ALL, COMMENT_TYPE_PROCESS } from 'constants/common/comments';
import { FULFILLED, DEFAULT } from 'constants/common';
import clsx from 'clsx';
import { bool, func, number, oneOfType, string, node, object, oneOf } from 'prop-types';
import { showConfirmation } from 'store/actions/confirmations';

function MakeComment({
  marginBottom,
  onPost,
  step,
  stepId,
  salesId,
  showHeader,
  header,
  label,
  classes,
  master,
  allowed,
  commentType,
  setUpdate,
  commentsState,
  ...props
}) {
  const blankState = { check: false, text: '', step: step || '', title: '' };
  const [state, setState] = useState(blankState);

  const handleChange = (obj, check) => e => {
    const { value } = e.target;
    setState({ ...state, [obj]: check ? e.target.checked : value });
  }

  const handleAddComment = () => {
    const addComment = () => {
      if (commentType === COMMENT_TYPE_ALL) {
        props.commentAll(salesId, state.text, state.check, stepId);
      } else {
        props.processComment(salesId, state.text, state.check, stepId);
      }
    }

    if (state.check) {
      props.showConfirmation(
        {
          title: 'Enviar comentário público',
          message: <>
            <Typography color="error">Atenção!</Typography>
            <Typography>
              {'Ao enviar um comentário público o cliente poderá visualizá-lo.'}
            </Typography>
            <Typography>
              {'Deseja enviar um comentário público?'}
            </Typography>
          </>,
        },
        {
          confirm: 'Enviar',
          cancel: 'Cancelar',
        },
        {
          func: addComment,
        },
      );
    } else addComment();

  }

  useEffect(() => {
    if (commentsState.status === FULFILLED) {
      if (commentsState.lastAction === COMMENT_ALL_PROCESS_BY_SALE || POST_COMMENT_PROCESS) {
        setState(blankState);
      }
    }
    // eslint-disable-next-line
  }, [commentsState.status])

  if (allowed) {
    return (
      <div style={{ textAlign: 'right' }}>
        {showHeader ? <Typography variant="h6" align="left">{header}</Typography> : null}
        <Divider />
        <TextField
          id="comment-text"
          label={label}
          multiline
          fullWidth
          rows="4"
          onChange={handleChange('text')}
          value={state.text}
          className={classes.textField}
          variant="outlined"
        />
        {master
          ? (
            <FormControlLabel
              control={
                <Checkbox checked={state.check} onChange={handleChange('check', true)} value={true} />
              }
              label="Tornar o comentário público para o cliente"
            />
          ) : null}
        <Divider />
        <Button
          variant="contained"
          color="primary"
          className={clsx(classes.button, marginBottom ? classes.marginBottom : 'neutral')}
          onClick={handleAddComment}
        >
          {'Enviar'}
        </Button>
      </div>
    );
  }
  return null;
}

MakeComment.propTypes = {
  marginBottom: bool,
  onPost: func.isRequired,
  step: number,
  stepId: number,
  salesId: oneOfType([number, string]),
  showHeader: bool,
  header: oneOfType([string, node]),
  label: string,
  classes: object,
  master: bool,
  allowed: bool,
  commentType: oneOf([COMMENT_TYPE_ALL, COMMENT_TYPE_PROCESS]),
  setUpdate: func,
  commentsState: object,
}

MakeComment.defaultProps = {
  marginBottom: false,
  step: 0,
  stepId: 0,
  salesId: '',
  showHeader: false,
  header: 'Comentar',
  label: 'Escreva seu comentário',
  classes: {},
  master: false,
  allowed: false,
  commentType: COMMENT_TYPE_ALL,
  setUpdate: () => { },
  commentsState: { status: DEFAULT },
}


export default connect(null, { showConfirmation })(MakeComment);
