import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { SaleDashboardContext } from 'contexts/saleDashboard';
import { includes } from 'lodash';
import { sendContractEmail } from 'store/actions/sales';
import { showConfirmation } from 'store/actions/confirmations';

const getMenus = (menu) => {
  switch (menu) {
    case 2:
      return 'payment';
    case 1:
      return 'contract';
    default:
      return 'scope';
  }
};

const SaleDashboardProvider = ({ children, sendContract, currentTab, masterTab, confirmation, ...props }) => {
  const [state, setState] = React.useState({
    menuEl: {
      scope: null,
      payment: null,
      contract: null,
    },
    sortBy: 'id',
    sortOrder: 'desc',
    q: '',
    step: 0,
    currentTab: currentTab || 0,
    masterTab,
    currentClient: {},
    expanded: null,
    dialogEditContract: false,
    dialogShareSale: false,
    dialogSignedContract: false,
    dialogPayment: false,
    dialogConfirmSale: false,
    masterView: false,
    multiSelect: [],
    shared: []
  });
  const setSortBy = (value) => setState((state) => ({ ...state, sortBy: value }));
  const setSortOrder = (value) => setState((state) => ({ ...state, sortOrder: value }));
  const setSearch = (value) => setState((state) => ({ ...state, q: value }));
  const setCurrentClient = (client) => setState((state) => ({ ...state, currentClient: client }))
  const setMenuEl = (element, menu) => setState((state) => ({
    ...state,
    menuEl: { ...state.menuEl, [menu]: element },
  }));
  const setStep = (newStep) => setState((state) => ({ ...state, step: newStep }));
  const setExpanded = (newExpanded) => setState((state) => ({ ...state, expanded: newExpanded }));
  const setCurrentTab = (tab) => setState((state) => ({ ...state, currentTab: tab }));
  const setCurrentMasterTab = (tab) => setState((state) => ({ ...state, masterTab: tab }));
  const setDialogSignedContract = () => setState((state) => ({
    ...state,
    dialogSignedContract: !state.dialogSignedContract,
  }));
  const setDialogPayment = () => setState((state) => ({ ...state, dialogPayment: !state.dialogPayment }));
  const setDialogConfirmSale = () => setState((state) => ({ ...state, dialogConfirmSale: !state.dialogConfirmSale }));

  const handleDialogShareSale = (ids = [], shared = []) => {
    setState((state) => ({ ...state, dialogShareSale: !state.dialogShareSale, multiSelect: [...ids], shared }));
  };

  const handleDialogSignedContract = (currentClient) => {
    if (currentClient) setCurrentClient(currentClient);
    setDialogSignedContract();
  }

  const handleDialogConfirmSale = (currentClient) => {
    if (currentClient) setCurrentClient(currentClient);
    setDialogConfirmSale();
  }

  const handleDialogPayment = (currentClient) => {
    if (currentClient) setCurrentClient(currentClient);
    setDialogPayment();
  };

  const openMenu = (open, menu, e, currentClient, step) => {
    const setMenu = getMenus(menu);
    setMenuEl(open ? e.currentTarget : null, setMenu);
    if (open) setCurrentClient(currentClient);
    if (step) setStep(step);
  };
  const checkMultiSelected = (id) => includes(state.multiSelect, id);
  const handleSelectMultiple = (id) => {
    const newMulti = checkMultiSelected(id)
      ? state.multiSelect.filter((s) => s !== id)
      : [...state.multiSelect, id];
    setState((s) => ({ ...s, multiSelect: id ? newMulti : [] }));
  };

  const handleDialogEditContract = () => setState((state) => ({ ...state, dialogEditContract: !state.dialogEditContract }));

  const clearSearchVariables = () => {
    setState((state) => ({ ...state, sortBy: 'id', q: '', sortOrder: 'desc' }));
  };

  const handleSendContractEmail = (client) => {
    const sendthis = () => {
      sendContract(client.id);
      openMenu(false, 1);
    }
    if (client && client.id && client.customer && client.customer.email) {
      confirmation(
        {
          title: 'Reenviar e-mail de contrato',
          message: (<Typography component="div">
            {'Deseja reenviar o contrato da venda para o e-mail '}
            <Typography component="span" color="error">
              {client.customer.email}
            </Typography>
            {'?'}
          </Typography>),
        },
        {
          confirm: 'Enviar',
          cancel: 'Cancelar',
        },
        {
          func: () => sendthis(),
        },
      );
    }
  }
  const toggleMasterView = () => setState((state) => ({ ...state, masterView: !state.masterView }));
  const handleMasterView = (view) => setState((state) => ({ ...state, masterView: view }));

  const handleRemoveShare = (user, sale) => console.log(user, sale);

  return (
    <SaleDashboardContext.Provider
      value={{
        ...state,
        setSortBy,
        setSortOrder,
        setSearch,
        openMenu,
        setCurrentMasterTab,
        setCurrentTab,
        setExpanded,
        handleDialogEditContract,
        clearSearchVariables,
        handleDialogShareSale,
        handleDialogSignedContract,
        handleDialogPayment,
        setCurrentClient,
        handleDialogConfirmSale,
        handleSelectMultiple,
        checkMultiSelected,
        handleSendContractEmail,
        toggleMasterView,
        handleMasterView,
        handleRemoveShare,
      }}
    >
      {children}
    </SaleDashboardContext.Provider>
  );
}

const actions = {
  sendContract: sendContractEmail,
  confirmation: showConfirmation,
};

export default connect(null, actions)(SaleDashboardProvider);