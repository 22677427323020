import React from 'react';
import { connect } from 'react-redux';
import Row from './Row';
import Root from 'global/modules/Root';
import { PRICES } from 'constants/common/pageTitles';
import { Container, Grid } from '@material-ui/core';
import { sortBy } from 'helpers';
import Header from './Header';
import { fetchAll } from 'store/actions/product';
import { REQUESTING, FULFILLED } from 'constants/common';
import { LoadingComponent } from 'global/modules/Loading';
import NoResults from 'global/modules/NoResults';

const Products = ({ products, fetch, status, searchStatus }) => {
  const [update, setUpdate] = React.useState('');
  React.useEffect(() => fetch(), [update, fetch]);
  return (
    <Root setTitle={PRICES} header paddingTop>
      <Header setUpdate={setUpdate} />
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          {products && sortBy(products, 'title').sort().map((product) => (
            <Grid key={`produto${product.id}`} item xs={12} md={6} xl={4}>
              <Row product={product} setUpdate={setUpdate} />
            </Grid>
          ))}
          {status === REQUESTING && products.length === 0 && (
            <Grid item xs={12}>
              <LoadingComponent />
            </Grid>
          )}
          {status !== REQUESTING && products.length === 0 && searchStatus === FULFILLED && (
            <Grid item xs={12}>
              <NoResults message="Nenhum tipo de processo encontrado" />
            </Grid>
          )}
        </Grid>
      </Container>
    </Root>
  );
};

const mapStateToProps = (state) => ({
  products: state.product.collection,
  status: state.product.status,
  searchStatus: state.product.searchStatus,
});

export default connect(mapStateToProps, { fetch: fetchAll })(Products);
