/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import uniqid from 'uniqid';
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Link,
} from '@material-ui/core';
import { deleteDocument, sendDocument } from 'store/actions/documents';
import { showNotification } from 'store/actions/notifications';
import { changeStepProcessSendDocs, blockProcess, changeStepProcess } from 'store/actions/processes';
import { FULFILLED } from 'constants/session';
import { showConfirmation } from 'store/actions/confirmations';
import { STEP_ANALYSIS } from 'constants/process';
import { Send } from '@material-ui/icons';
import FileBox from 'global/filebox';
import DatePickerField from 'global/fields/DatePickerField';
import { returnValidDate } from 'helpers';
import { NOTIFICATION_TYPE_ERROR } from 'constants/notification';
import { MESSAGE_ERROR_COMMON_ERROR, MESSAGE_NO_PROCESSES } from 'constants/common/messages';
import { updateBatchPost, sentBatchPost } from 'store/actions/batchpost';
import { Visualization } from 'global/modules/FilesPreview/Expanded';

function Dialogs({
  classes, context, setUpdate, update, actionStatus, settingsStatus, lotActionStatus, sent, ...props
}) {
  const {
    currentClient,
    confirmEndStepDialog,
    handleConfirmEndStepDialog,
    editLotDialog,
    handleEditLotDialog,
    handleBlockDialog,
    confirmBlockDialog,
    checkDialog,
    handleCheckDialog,
    currentLot,
    lotDialog,
    handleLotDialog,
    datePickDialog,
    handleDatePickDialog,
    date,
    setDate,
    block,
  } = context;
  const [reason, setReason] = useState('');
  const emptySendValues = { rastreio: '', file: [], error: [], processes: [] };
  const [sendValues, setSendValues] = useState(emptySendValues);
  const setFile = (file) => setSendValues((o) => ({ ...o, file }));

  useEffect(() => {
    setSendValues({
      ...emptySendValues,
      rastreio: currentLot.tracking_code || '',
      processes: currentLot.processes && currentLot.processes.length ? [...currentLot.processes] : [],
    });
    // eslint-disable-next-line
  }, [currentLot]);

  const handleBlockProcess = () => {
    props.blockProcess(currentClient.id, reason, block);
    props.changeStepProcess(currentClient.id, STEP_ANALYSIS);
  };

  const handleCloseBlockDialog = () => {
    handleBlockDialog();
    setReason('');
  };

  const handleChangeReason = (e) => setReason(e.target.value);

  const handleSaveDate = () => {
    if (datePickDialog && currentLot.datePickerItem && date && currentLot.id) {
      const { datePickerItem } = currentLot;
      props.updateBatchPost(
        currentLot.id,
        {
          [datePickerItem]: returnValidDate(date),
          processes: currentLot.processes.length > 0
            ? currentLot.processes.map((p) => p.id)
            : [false],
        },
      );
    } else {
      props.showNotification(NOTIFICATION_TYPE_ERROR, MESSAGE_ERROR_COMMON_ERROR);
    }
  };

  useEffect(() => {
    if (datePickDialog && lotActionStatus === FULFILLED) {
      setUpdate(uniqid());
      handleDatePickDialog(false);
    }
    if (lotDialog && lotActionStatus === FULFILLED) {
      setUpdate(uniqid());
      handleLotDialog(false);
    }
    if (editLotDialog && lotActionStatus === FULFILLED) {
      setUpdate(uniqid());
      handleEditLotDialog(false);
    }
    // eslint-disable-next-line
  }, [lotActionStatus]);

  useEffect(() => {
    if (confirmEndStepDialog && actionStatus === FULFILLED) {
      handleConfirmEndStepDialog();
    }
    if (confirmBlockDialog && actionStatus === FULFILLED) {
      handleCloseBlockDialog();
      setUpdate(uniqid());
    }
    if (checkDialog && actionStatus === FULFILLED) {
      handleCheckDialog();
      setUpdate(uniqid());
    }
    // eslint-disable-next-line
  }, [actionStatus]);

  const handleSendLot = () => {
    if (currentLot.processes.length > 0) {
      sent(
        currentLot.id,
        {
          tracking_code: sendValues.rastreio,
          // voucher: sendValues.file[0],
        },
      );
    } else {
      props.showNotification(NOTIFICATION_TYPE_ERROR, MESSAGE_NO_PROCESSES);
    }
  };

  const handleUpdateLot = () => {
    if (currentLot.id) {
      const updated = {
        tracking_code: sendValues.rastreio,
        processes: currentLot.processes.length > 0
          ? currentLot.processes.map((p) => p.id)
          : [false],
      }
      if (sendValues.file.length) {
        updated.voucher = sendValues.file[0];
      }
      props.updateBatchPost(currentLot.id, updated);
    } else props.showNotification(NOTIFICATION_TYPE_ERROR, MESSAGE_ERROR_COMMON_ERROR);
  };

  const handleChangeSendValues = (name, value) => setSendValues((o) => ({ ...o, [name]: value }));

  return (
    <>
      <Dialog
        PaperProps={{
          square: true,
        }}
        open={datePickDialog}
        scroll="body"
        onClose={() => handleDatePickDialog(false)}>
        <DialogContent style={{ padding: 0 }}>
          <DatePickerField
            value={date}
            label=""
            variant="static"
            placeholder="Selecionar data"
            onChanged={(nDate) => setDate(nDate)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => { handleDatePickDialog(false); }}
            color="secondary"
          >
            {'Cancelar'}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleSaveDate()}
            disabled={date === null}
          >
            {'Confirmar'}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={lotDialog}
        onClose={handleLotDialog}
        aria-labelledby="dialog-endstep-title"
        scroll="body"
      >
        <DialogTitle id="dialog-endstep-title">
          {`Confirmar envio do pacote ${currentLot.id}`}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                error={sendValues.error.indexOf('rastreio') >= 0}
                fullWidth
                id="sendValues-rastreio"
                label="Código de rastreio"
                variant="outlined"
                name="rastreio"
                value={sendValues.rastreio}
                onChange={(e) => handleChangeSendValues('rastreio', e.target.value)}
                aria-describedby="sendValues-rastreio"
              />
            </Grid>
            {/*
            <Grid item xs={12}>
              <Typography className={sendValues.error.indexOf('file') >= 0 ? classes.error : 'regular'} gutterBottom>
                {'Selecionar comprovante'}
              </Typography>
              <FileBox
                id="annex-comprovante"
                file={sendValues.file}
                setFile={setFile}
                multiple={false}
              />
            </Grid>
            */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLotDialog} variant="outlined" color="secondary">
            {'Cancelar'}
          </Button>
          <Button onClick={handleSendLot} variant="outlined" color="primary">
            {'Confirmar envio'}
            <Send fontSize="small" className={classes.iconMargin} />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editLotDialog}
        onClose={handleEditLotDialog}
        aria-labelledby="dialog-edit-post-title"
        scroll="body"
      >
        <DialogTitle id="dialog-edit-post-title">
          {`Editar informações do pacote ${currentLot.tracking_code || `#${currentLot.id}`}`}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                error={sendValues.error.indexOf('rastreio') >= 0}
                fullWidth
                label="Código de rastreio"
                variant="outlined"
                name="rastreio"
                value={sendValues.rastreio}
                onChange={(e) => handleChangeSendValues('rastreio', e.target.value)}
              />
            </Grid>
            {currentLot.payment_voucher_url && currentLot.payment_voucher_file && (
              <Grid item xs={12}>
                <Typography variant="h6" paragraph>
                  {'Comprovante anexado'}
                </Typography>
                <Visualization
                  doc={{
                    type: currentLot.payment_voucher_file.split('.')[currentLot.payment_voucher_file.split('.').length - 1],
                    title: currentLot.payment_voucher_file,
                  }}
                  docUrl={currentLot.payment_voucher_url}
                  minHeight
                />
                <Typography align="right">
                  <Link href={currentLot.payment_voucher_url}>
                    {'Baixar'}
                  </Link>
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="h6" className={sendValues.error.indexOf('file') >= 0 ? classes.error : 'regular'} paragraph>
                {currentLot.payment_voucher_url && currentLot.payment_voucher_file ? 'Alterar comprovante' : 'Selecionar comprovante'}
              </Typography>
              <FileBox
                id="annex-comprovante"
                file={sendValues.file}
                setFile={setFile}
                multiple={false}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditLotDialog} variant="outlined" color="secondary">
            {'Fechar'}
          </Button>
          <Button onClick={handleUpdateLot} variant="outlined" color="primary">
            {'Alterar informações'}
          </Button>
        </DialogActions>
      </Dialog>
      {Object.keys(currentClient).length > 0 && (
        <Dialog
          PaperProps={{
            square: true,
          }}
          open={confirmBlockDialog}
          onClose={() => handleCloseBlockDialog()}
          scroll="body"
          aria-labelledby="dialog-endstep-title"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="dialog-endstep-title">
            {block ? 'Travar processo' : 'Destravar processo'}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {block
                    ? `Se encontrou alguma pendência de documentação, indique o travamento do processo de ${currentClient.applicant.name}`
                    : `Deseja destravar o processo de ${currentClient.applicant.name}?`}
                </Typography>
                {block && (
                  <TextField
                    id="outlined-multiline-static"
                    label="Motivo para a trava do processo"
                    fullWidth
                    multiline
                    value={reason}
                    rows="4"
                    onChange={handleChangeReason}
                    margin="normal"
                    variant="outlined"
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleCloseBlockDialog()}
              variant="outlined"
              color="secondary"
            >
              {'Cancelar'}
            </Button>
            <Button
              onClick={() => handleBlockProcess()}
              disabled={reason === '' && block}
              variant="outlined"
              color="primary"
            >
              {'Confirmar'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

const actions = {
  deleteDocument,
  sendDocument,
  showNotification,
  changeStepProcessSendDocs,
  showConfirmation,
  blockProcess,
  changeStepProcess,
  updateBatchPost,
  sent: sentBatchPost,
};

const mapStateToProps = (state) => ({
  steps: state.steps.collection,
  lotActionStatus: state.batchpost.actionStatus,
  actionStatus: state.processes.actionStatus,
  settingsStatus: state.processes.settingsStatus,
});

export default connect(mapStateToProps, actions)(Dialogs);
