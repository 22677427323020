import React, { useEffect } from 'react';
import uniqid from 'uniqid';
import { connect } from 'react-redux';
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import { showNotification } from 'store/actions/notifications';
import { NOTIFICATION_TYPE_ERROR } from 'constants/notification';
import { MESSAGE_ERROR_COMMON_ERROR } from 'constants/common/messages';
import { changeProcess } from 'store/actions/processes';
import { FULFILLED } from 'constants/session';
import DatePickerField from 'global/fields/DatePickerField';
import { returnValidDate } from 'helpers';

function Dialogs({ classes, context, setUpdate, update, actionStatus, ...props }) {
  const { currentClient, datePickDialog, handleDatePickDialog, date, setDate } = context;

  const handleSaveDate = () => {
    if (datePickDialog && currentClient.datePickerItem && date && currentClient.id) {
      const { datePickerItem } = currentClient;
      props.changeProcess(currentClient.id, { [datePickerItem]: returnValidDate(date) });
    }
    else {
      props.showNotification(NOTIFICATION_TYPE_ERROR, MESSAGE_ERROR_COMMON_ERROR);
    }
  }

  useEffect(() => {
    if (datePickDialog && actionStatus === FULFILLED) {
      setUpdate(uniqid());
      handleDatePickDialog(false);
    }
    // eslint-disable-next-line
  }, [actionStatus]);

  return (
    <>
      <Dialog
        PaperProps={{
          square: true,
        }}
        open={datePickDialog}
        scroll="body"
        onClose={() => handleDatePickDialog(false)}>
        <DialogContent style={{ padding: 0 }}>
          <DatePickerField
            value={date}
            label=""
            variant="static"
            placeholder="Selecionar data"
            disableFuture
            onChanged={date => setDate(date)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => { handleDatePickDialog(false) }}
            color="secondary">
            {'Cancelar'}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleSaveDate()}
            disabled={date === null}>
            {'Confirmar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const actions = {
  showNotification,
  changeProcess,
};

const mapStateToProps = (state) => ({
  steps: state.steps.collection,
  actionStatus: state.processes.actionStatus,
});

export default connect(mapStateToProps, actions)(Dialogs);
