import React, { useState } from 'react';
import uniqid from 'uniqid';
import PropTypes from 'prop-types';
import ServiceProvider from 'providers/service';
import { ServiceContext } from 'contexts/service';
import { title, SERVICE, CONFIRMACAO } from 'constants/common/pageTitles';
import Root from 'global/modules/Root';
import MoreInfo from 'global/modules/MoreInfo';
import UpdateCurrents from 'service/UpdateCurrents';
import DocumentsList from 'global/modules/Documents';
import VerificacaoList from './List';
import Menus from './modules/Menus';
import Single from './Single';

function Verificacao({ match, history }) {
  const [update, setUpdate] = useState(uniqid());
  const titles = title(CONFIRMACAO, SERVICE);

  return (
    <ServiceProvider currentTitle={titles}>
      <ServiceContext.Consumer>
        {({
          currentTab,
          setDocsShown,
          docsShown,
          currentClient,
          currentInfoObj,
          currentTitle,
          objType,
          infoType,
          handleShowMoreInfo,
          showMoreInfo,
        }) => (
          <>
            <UpdateCurrents update={update} />
            <Root header paddingTop setTitle={currentTitle}>
              {match.params.internalId
                ? <Single id={match.params.internalId} push={history.push} />
                : (
                  <VerificacaoList update={update} push={history.push} />
                )}
            </Root>
            <DocumentsList
              show={docsShown}
              currentClient={currentClient || {}}
              currentTab={currentTab}
              setUpdate={setUpdate}
              update={update}
              objType={objType}
              onClose={() => setDocsShown({})}
            />
            <MoreInfo
              viewType={infoType}
              client={currentInfoObj}
              open={showMoreInfo}
              handleFunc={handleShowMoreInfo}
              update={update}
              setUpdate={setUpdate}
            />
            <Menus setUpdate={setUpdate} />
          </>
        )}
      </ServiceContext.Consumer>
    </ServiceProvider>
  );
}

Verificacao.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      internalId: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

Verificacao.defaultProps = {
  match: {
    params: {
      internalId: '',
    },
  },
};

export default Verificacao;
