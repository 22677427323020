import { UPDATE_DIALOG_CUSTOMER } from 'store/definitions/dialogs';
import { EMPTY } from 'constants/common';

function Dialogs(state = { mode: EMPTY, open: false, currentCustomer: {} }, action) {
  switch (action.type) {
    case UPDATE_DIALOG_CUSTOMER:
      return {
        mode: action.payload.mode,
        open: action.payload.open,
        currentCustomer: action.payload.currentCustomer,
      }
    default:
      return state;
  }
}

export default Dialogs;
