import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { object, func } from 'prop-types';

function FormApplicant({ form, handleChangeForm }) {
  return (
    <Grid item xs={4}>
      <TextField
        fullWidth
        variant="outlined"
        value={form.birthdate}
        label="Data de nascimento"
        InputLabelProps={{
          shrink: Boolean(form.birthdate),
        }}
        onChange={(e) => handleChangeForm('birthdate', e.target.value)}
      />
    </Grid>
  );
}

FormApplicant.propTypes = {
  form: object.isRequired,
  handleChangeForm: func.isRequired,
};

export default FormApplicant;
