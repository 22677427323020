// SPACER
export const SPACER = ' - ';

// helper
export const title = (a, b) => `${a}${SPACER}${b}`;

export const PRICES = 'Produtos';

// SESSIONS
export const SALES = 'Venda';
export const SERVICE = 'Serviço';
export const CLIENT = 'Central do Cliente';
export const FINANCE = 'Financeiro';
export const CUSTOMER_SERVICE = 'Atendimento';

export const USERS = 'Usuários';

export const NEW_CLIENT = 'Novo cliente';
export const SALE_FORM = 'Nova venda';

export const SEARCH = 'Busca';

// SERVICE

export const CONFIRMACAO = 'Verificação';

export const ACOMPANHAMENTO = 'Acompanhamento';
export const ALINHAMENTO = 'Alinhamentos';
export const ANALISE = 'Análise';
export const DOCSOK = 'Docs OK';
export const APOSTILAMENTO = 'Apostilamento';
export const BUSCA = 'Busca';
export const CONCLUSAO = 'Conclusão';
export const DOCUMENTACAO = 'Documentação';
export const ENVIO = 'Envio';
