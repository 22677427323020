import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Container, Typography, Grid, InputBase, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import Root from 'global/modules/Root';
import { useStyles } from './styles';
import { processCategoriesList as tags } from 'constants/sales';
import Row from './Row';
import { LoadingComponent } from 'global/modules/Loading';
import { sortBy } from 'helpers';
import { productSearch } from 'store/actions/product';
import { REQUESTING } from 'constants/common';

function ProductRequirements({ products, search, status, allProducts, ...props }) {
  const classes = useStyles(props);
  const [selectedTags, setSelectedTags] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const handleExpand = (id) => {
    setExpanded(expanded === id ? null : id);
  }

  const handleAddTag = (id) => {
    const newTag = selectedTags[0] !== id ? [id] : [];
    setSelectedTags([...newTag]);
    search(searchValue, [...newTag]);
  }

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    search(e.target.value, selectedTags);
  }

  return (
    <Root header footer paddingTop>
      <Container maxWidth="md">
        <Typography variant="h4" color="textSecondary" paragraph>Informações sobre processos</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>Pesquisar por processo ou documentação:</Typography>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Ex: Convolação"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                fullWidth
                value={searchValue}
                onChange={handleSearch}
                inputProps={{ 'aria-label': 'Search' }}
              />
            </div>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: -8 }}>
            <Typography variant="h6" gutterBottom>Categorias</Typography>
            {tags.map((tag, index) => {
              const selected = selectedTags.indexOf(tag.id) >= 0;
              return (
                <Button
                  key={`tag${tag.id}${tag.label}`}
                  variant={selected ? 'contained' : 'outlined'}
                  size="medium"
                  style={{ marginRight: 12, minWidth: 120 }}
                  color={selected ? 'primary' : 'secondary'}
                  className={clsx(classes.categoryButton, selected ? 'selected' : '')}
                  onClick={() => handleAddTag(tag.id)}
                >
                  {tag.label}
                </Button>
              )
            })}
          </Grid>
          {products.length === 0 && status === REQUESTING
            ? (
              <Grid item xs={12}>
                <LoadingComponent />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography variant="h6" paragraph>
                  {products.length !== allProducts.length ? `Processo${products.length > 1 ? 's' : ''} (${products.length} de ${allProducts.length})` : `Processo${products.length > 1 ? 's' : ''} (${products.length})`}
                </Typography>
                {sortBy(products, 'title').map((item) => (
                  <Row
                    key={item.id}
                    item={item}
                    expanded={expanded}
                    handleExpand={handleExpand}
                    classes={classes}
                    tags={tags}
                    handleAddTag={handleAddTag}
                  />
                ))}
              </Grid>
            )}

        </Grid>
      </Container>
    </Root>
  );
}

const mapStateToProps = (state) => ({
  products: state.product.collection,
  allProducts: state.product.allProducts,
  status: state.product.status,
});

export default connect(mapStateToProps, { search: productSearch })(ProductRequirements);
