import React, { useState } from 'react';
import * as DUMMIES from 'objects/dummies';
import * as $_ from 'global/functions';
import { Container, Typography, Grid, InputBase, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import Root from 'global/modules/Root';

const useStyles = makeStyles(theme => ({
    search: {
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[2],
        color: fade(theme.palette.text.primary, 0.3),
        transition: theme.transitions.create(['color'], { duration: '100ms' }),
        '&:hover, &:focus': {
            color: theme.palette.text.primary,
        },
        marginRight: 0,
        marginLeft: 0,
        width: '100%',
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(2, 1, 2, 7),
        width: '100%',
        lineHeight: '3rem',
        fontSize: '1.5rem',
    },
    buttonSelected: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
            backgroundColor: fade(theme.palette.primary.main, .7),
        },
    },
    tagButton: {
        display: 'contents',
    },
    expansionPanel: {
        padding: 0,
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1,2),
        }
    },
    tagName: {
        marginRight: theme.spacing(1),
    },
    panelContent: {
        flexDirection: 'column'
    },
    categoryGrid: {
        padding: theme.spacing(.5),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
        }
    },
    categoryContainer: {
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
        width: 'calc(100% + '+theme.spacing(4)+'px)',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        }

    },

}));

const tags = [
    {
        id: 0,
        label: 'Processos',
    },
    {
        id: 1,
        label: 'Documentação'
    },
    {
        id: 2,
        label: 'Pagamento'
    }
];

const allQuestions = [];

function Faq({user, ...props}) {
    const classes = useStyles(),
    [selectedTags, setSelectedTags] = useState([]),
    [expanded, setExpanded] = useState(null),
    [search, setSearch] = useState(''),
    [questions, setQuestions] = useState(allQuestions);

    const handleExpand = (id) => {
        setExpanded(expanded === id ? null : id);
    }

    const updateQuestions = (newTags,searchValue) => {
        let newQuestions = allQuestions;
        let newSearch = searchValue !== '' 
        ? new RegExp($_.regBuilder($_.dText(searchValue)), 'i') 
        : /[\s\S]*/;
        if(newTags.length > 0) {
            newQuestions = allQuestions.filter(fQuestion => {
                return newTags.indexOf(fQuestion.category) >= 0 
                && Boolean(fQuestion.question.toLowerCase().match(newSearch));
            })
        }
        else if(searchValue !== '') {
            newQuestions = allQuestions.filter(fQuestion => {
                return Boolean(fQuestion.question.toLowerCase().match(newSearch));
            })
        }
        setQuestions([...newQuestions])
    }

    const handleAddTag = (id, always) => event => {
        let newTags = selectedTags;
        const i = newTags.indexOf(id);
        if (i >= 0) {
            if(!always)
                newTags.splice(i, 1);
        }
        else newTags = [...newTags, id];
        setSelectedTags([...newTags]);
        updateQuestions([...newTags],search);
    }

    const handleSearch = e => {
        setSearch(e.target.value);
        updateQuestions(selectedTags,e.target.value);
    }

    return (
        <Root header footer paddingTop>
        <Container maxWidth="xl">
            <Typography variant="h4" color="textSecondary" paragraph>Perguntas frequentes</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>Qual é a sua dúvida?</Typography>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Ex: Sadipscing elitr, sed diam eirmod?"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            fullWidth
                            value={search}
                            onChange={handleSearch}
                            inputProps={{ 'aria-label': 'Search' }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>Categorias</Typography>
                    <Grid container spacing={0} className={classes.categoryContainer}>
                        {tags.map((tag, index) => {
                            return (
                                <Grid key={`tag${tag}${index}`} item xs={6} sm={4} md={2} className={classes.categoryGrid}>
                                    <Button
                                        variant="outlined"
                                        size="medium"
                                        color="primary"
                                        className={clsx(selectedTags.indexOf(tag.id) >= 0 ? classes.buttonSelected : '',classes.categoryButton)}
                                        onClick={handleAddTag(tag.id)}
                                        fullWidth
                                    >
                                        {tag.label}
                                    </Button>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {questions.map((question, index) => {
                        return (
                            <ExpansionPanel 
                                key={`question${index}`} 
                                expanded={expanded === index} 
                                onChange={e => { handleExpand(index) }}
                                className={classes.expansionPanel}
                                square  
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`${question.question}`}
                                    id={`question ${index} header`}
                                >
                                   {/*} <Typography variant="h6" color="secondary" className={classes.tagName}>{tags.find(a => { return a.id === question.category }).label}</Typography> {*/}
                                    <Typography variant="subtitle2" color="secondary" className={classes.question}>{question.question}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.panelContent}>
                                    <Typography paragraph>
                                        {question.answer}
                                    </Typography>
                                    <Button 
                                        size="small" 
                                        color="primary" 
                                        className={classes.tagButton}
                                        onClick={handleAddTag(question.category,true)}
                                    >
                                        # {tags.find(a => { return a.id === question.category }).label}
                                    </Button>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )
                    })}
                </Grid>

            </Grid>
        </Container>
        </Root>
    );
}

export default Faq;