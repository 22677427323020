import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchHistoricBySalesman } from 'store/actions/sales';
import {
  Grid, Paper, Typography,
} from '@material-ui/core';
import ActionsContext from 'contexts/actions';
import TabHeader from 'sales/dashboard/components/TabHeader';
import TabRowHistoric from 'sales/dashboard/components/TabRowHistoric';
import { LoadingComponent } from 'global/modules/Loading';
import { REQUESTING, FULFILLED } from 'constants/common';
import NoResults from 'global/modules/NoResults';
import { SaleDashboardContext } from 'contexts/saleDashboard';
import { setSalesStep } from 'sales/functions';
import { useStyles } from './style';
import { getCancelToken, cancel } from 'api';
import { Pagination } from '@material-ui/lab';

function TabHistoric({
  tab, tabKey, historicReq, update, allSalesReq, status, fetch, ...props
}) {
  const classes = useStyles(false);
  const { reload } = useContext(ActionsContext);
  const { setCurrentClient, currentClient } = useContext(SaleDashboardContext);
  const [historic, setHistoric] = useState([...historicReq]);
  const [allSales, setAllSales] = useState([...allSalesReq]);
  const [requesting, setRequesting] = useState(false);
  const cancelSource = React.useRef(null);
  const [page, setPage] = useState(1)

  const handleCurrentPage = (e, page) => {
    setPage(page)
  }

  useEffect(() => {
    if (status === FULFILLED && requesting) {
      setRequesting(false);
    }
    setHistoric([...historicReq]);
    setAllSales([...allSalesReq]);
  }, [status, allSalesReq, historicReq, requesting]);

  const historicAvecSteps = historic.map((a) => setSalesStep(a));

  useEffect(() => {
    cancelSource.current = getCancelToken().source();
    if (!requesting) {
      setRequesting(true);
      fetch(cancelSource.current.token);
    }
    return () => cancel(cancelSource.current);

    // eslint-disable-next-line
  }, [update, reload]);

  useEffect(() => {
    if (status === FULFILLED) {
      const maintainClient = allSales.find((a) => a.id === currentClient.id);
      setCurrentClient(maintainClient || currentClient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, allSales]);

  useEffect(() => {
    fetch(cancelSource.current.token, page);
  }, [page])

  return (
    <Grid>
      <TabHeader
        title={tab.title}
        tabKey={tabKey}
        defaultSort="updated_at"
        itemsSort={[
          {
            title: 'Última atualização',
            value: 'updated_at',
          },
          {
            title: 'Nome',
            value: 'name',
          },
        ]}
      />
      {historicAvecSteps
        && status === FULFILLED
        && historicAvecSteps.length === 0
        ? <NoResults message="Nenhuma venda encontrada" />
        : (
          <Paper className={classes.header}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Cliente</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Informações</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} className={classes.middleGridPadding}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2">Venda</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" component="p" align="center">Contrato</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" component="p" align="right">Pagamento</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} className={classes.lastChildPadding}>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Typography variant="subtitle2">Serviços</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        )}
      {historicAvecSteps
        && historicAvecSteps.length > 0
        && historicAvecSteps.map((item, i) => (
          <TabRowHistoric
            key={item.id}
            index={i}
            item={item}
          />
        ))}
      {status === REQUESTING
        && <LoadingComponent />}
        <Pagination count={props.totalPages} page={props.currentPage} onChange={handleCurrentPage} />
    </Grid>
  );
}

TabHistoric.propTypes = {
  tab: PropTypes.object.isRequired,
  tabKey: PropTypes.number.isRequired,
  historicReq: PropTypes.array.isRequired,
  update: PropTypes.string.isRequired,
  allSalesReq: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  fetch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  allSalesReq: state.sales.allSales,
  historicReq: state.sales.collection,
  status: state.sales.status,
  totalPages: state.sales.totalPages,
  currentPage: state.sales.currentPage
});

export default connect(mapStateToProps, { fetch: fetchHistoricBySalesman })(TabHistoric);
