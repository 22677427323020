/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import uniqid from 'uniqid';
import PropTypes from 'prop-types';
import {
  COMMENT_TYPE_PROCESS,
  COMMENT_TYPE_ALL,
} from 'constants/common/comments';
import { commentAll, eraseComment } from 'store/actions/comments';
import { FULFILLED, REQUESTING } from 'constants/common';
import MakeComment from './MakeComment';
import { processComment } from 'store/actions/comments';
import { showConfirmation } from 'store/actions/confirmations';
import CommentRow from './CommentRow';

function Comments({
  allowed,
  master,
  label,
  step,
  stepId,
  testBlank,
  header,
  tiny,
  inverted,
  showHeader,
  commentList,
  commentType,
  salesId,
  onPost,
  allSteps,
  setUpdate,
  update,
  commentsState,
  whenThereAreResults,
  ...props
}) {
  const [comments, setComments] = useState([]);
  const [thisUpdate, setThisUpdate] = useState('');
  
  let renderComments = comments;

  if (!allowed) renderComments = renderComments.filter((a) => a.public);
  if (inverted) {
    renderComments = renderComments.sort((a, b) => {
      const unixTime = (time) => (new Date(time.created_at)).getTime();
      return unixTime(b) - unixTime(a);
    });
  }

  useEffect(() => {
    setComments(commentList);
    // eslint-disable-next-line
  }, [update, commentList]);

  useEffect(() => {
    if (commentsState.status === FULFILLED && thisUpdate === REQUESTING) {
      setUpdate(uniqid());
      setThisUpdate(commentsState.status);
    } else {
      setThisUpdate(commentsState.status);
    }
    // eslint-disable-next-line
  }, [commentsState.status]);

  const handleDeleteComment = (id) => {
    const confirmed = () => {
      props.eraseComment(id);
    }
    props.showConfirmation(
      {
        title: 'Remover comentário',
        message: `Deseja remover o comentário?`,
      },
      {
        confirm: 'Remover',
        cancel: 'Cancelar',
      },
      {
        func: confirmed,
      },
    );
  };

  const makeCommentProps = {
    commentType,
    master,
    allowed,
    label,
    step,
    stepId,
    salesId,
    showHeader,
    onPost,
    header,
    setUpdate,
    commentAll: props.commentAll,
    processComment: props.processComment,
    commentsState,
  };

  return (
    <>
      {inverted ? (
        <MakeComment
          {...makeCommentProps}
          marginBottom
        />
      ) : null}
      {renderComments.length > 0
        ? (
          <>
            {whenThereAreResults || null}
            {renderComments.map((comment, i) => (
              <CommentRow
                key={uniqid()}
                tiny={tiny}
                index={i}
                handleDeleteComment={handleDeleteComment}
                comment={comment}
                renderComments={renderComments}
                allSteps={allSteps}
              />
            ))}
          </>
        ) : null}
      {!inverted ? (
        <MakeComment
          {...makeCommentProps}
        />
      ) : null}
    </>
  );
}

Comments.propTypes = {
  onPost: PropTypes.func,
  showHeader: PropTypes.bool,
  commentList: PropTypes.array,
  salesId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  commentType: PropTypes.oneOf([COMMENT_TYPE_PROCESS, COMMENT_TYPE_ALL]),
  whenThereAreResults: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  inverted: PropTypes.bool,
};

Comments.defaultProps = {
  commentList: [],
  salesId: '',
  commentType: COMMENT_TYPE_PROCESS,
  showHeader: true,
  onPost: () => { },
  whenThereAreResults: <></>,
  inverted: true,
};

const mapStateToProps = (state) => ({
  allSteps: state.steps.collection,
  commentsState: state.comments,
});

export default connect(mapStateToProps, { commentAll, processComment, eraseComment, showConfirmation })(Comments);
