import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2, 3, 9),
    minHeight: '100%',
    position: 'relative',
  },
  formRow: {
    paddingTop: '16px !important',
    paddingBottom: '16px !important',
    '&.btnHolder': {
      bottom: '0px',
      right: '24px',
      margin: '0 -16px',
      width: 'calc(100% - 8px)',
      padding: '16px',
      position: 'absolute',
    },
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: 0,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
}));
