import * as definitions from 'store/definitions/exchange';

function Exchange(state = {
  eurBrl: null,
}, action) {
  switch (action.type) {
    case definitions.FETCH_EURO_EXCHANGE_FULFILLED:
      return {
        eurBrl: action.payload.BRL,
        eurAll: action.payload,
      };
    default:
      return state;
  }
}

export default Exchange;
