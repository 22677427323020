import React, { useContext } from 'react';
import { ENVIO_PROCESS_ROW } from 'constants/service/menus';
import { ServiceContext } from 'contexts/service';
import {
  MenuItem, Menu,
} from '@material-ui/core';
import { stopPropagation } from 'global/functions';

export default function Menus() {
  const serviceContext = useContext(ServiceContext);
  const {
    openMenu,
    anchorEl,
    currentClient,
    handleBlockDialog,
  } = serviceContext;
  const handleBlock = (e) => {
    openMenu(false, ENVIO_PROCESS_ROW);
    handleBlockDialog(currentClient, true);
    stopPropagation(e);
  };
  return (
    <>
      <Menu
        anchorEl={anchorEl[ENVIO_PROCESS_ROW]}
        open={Boolean(anchorEl[ENVIO_PROCESS_ROW])}
        onClose={() => openMenu(false, ENVIO_PROCESS_ROW)}
      >
        <MenuItem onClick={(e) => handleBlock(e)}>
          {'Enviar para travados, em análise'}
        </MenuItem>
      </Menu>
    </>
  );
}
