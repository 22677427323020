import React, { useState } from 'react';
import {
  Grid,
  Button,
  makeStyles,
  Fab,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import {
  AttachFile as AttachIcon,
} from '@material-ui/icons';
import { PEOPLE_FORM_MODE_NEW, MAXIMUM_FILES_ALLOWED, UPLOAD_TEXT, MAXIMUM_TEXT } from 'constants/sales';
import FileBox from 'global/filebox';

const useStyles = makeStyles((theme) => ({
  saveBtn: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  btn: {
    marginTop: theme.spacing(1),
  },
  fab: {
    color: theme.palette.common.white,
  },
}));

function NewPeopleFooterForm({
  mode, upload, file, setFile, onAddDocs, form, onRemove, onUpdate, ...props
}) {
  const classes = useStyles();
  const [uploadDialog, setUploadDialog] = useState(false);
  const [errors, setErrors] = useState([]);
  const baseFileAmount = (form.docs.length + form.documents.length);
  const disableUpload = baseFileAmount >= MAXIMUM_FILES_ALLOWED;
  const uploadText = disableUpload ? MAXIMUM_TEXT : UPLOAD_TEXT;

  const handleDialog = () => {
    setUploadDialog(!uploadDialog);
    setFile([]);
  };

  return (
    <>
      {upload && (
        <Dialog
          PaperProps={{
            square: true,
          }}
          open={uploadDialog}
          onClose={handleDialog}
          scroll="body"
          aria-labelledby="upload-dialog-title"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="upload-dialog-title">Anexar documento do português</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Selecione o documento para anexar às informações do português.
          </DialogContentText>
            <FileBox
              id="annex-pt-doc"
              file={file}
              setFile={setFile}
              errors={errors}
              setErrors={setErrors}
              baseFileAmount={baseFileAmount}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleDialog}
            >
              Cancelar
          </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => { onAddDocs(); handleDialog(); }}
            >
              Enviar
          </Button>
          </DialogActions>
        </Dialog>
      )}
      <Grid container spacing={2}>
        {upload && (
          <Grid item xs={6}>
            <Tooltip placement="left" aria-label={uploadText} title={uploadText}>
              <Fab
                color="primary"
                className={classes.fab}
                disabled={disableUpload}
                onClick={() => handleDialog()}
              >
                <AttachIcon />
              </Fab>
            </Tooltip>
          </Grid>
        )}
        <Grid item xs={upload ? 6 : 12} style={{ textAlign: 'right' }}>
          <Button
            className={classes.saveBtn}
            variant="contained"
            color="secondary"
            onClick={() => { if (mode !== PEOPLE_FORM_MODE_NEW) onRemove(); }}
            type={mode === PEOPLE_FORM_MODE_NEW ? 'reset' : 'button'}
          >
            {mode === PEOPLE_FORM_MODE_NEW ? 'Limpar' : 'Remover Português'}
          </Button>
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            onClick={() => { if (mode !== PEOPLE_FORM_MODE_NEW) onUpdate(); }}
            type={mode === PEOPLE_FORM_MODE_NEW ? 'submit' : 'button'}
          >
            {mode === PEOPLE_FORM_MODE_NEW ? 'Adicionar português' : 'Editar Português'}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default NewPeopleFooterForm;
