import React, { useState } from 'react';
import {
  Grid,
  Tooltip,
  Typography,
  Button,
  Icon,
  IconButton,
  InputBase,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import {
  INTERNAL, CUSTOMER, MASTER, ALL, usersTitles, ADD_MODE,
} from 'constants/user';
import { connect } from 'react-redux';
import {
  Close as CloseIcon,
  Search as SearchIcon,
  PersonAdd as PersonAddIcon,
} from '@material-ui/icons';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import clsx from 'clsx';
import { usersSearch } from 'store/actions/users';
import RowForm from './RowForm';
import { useStyles } from './style';

function UsersHeader({ user, view, internalType, ...props }) {
  const classes = useStyles();
  const [listValue, setListValue] = useState(user.userType === MASTER ? INTERNAL : CUSTOMER);
  const [sortItems, setSortItems] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [initialised, setInitialised] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const listUsers = view;

  const usersTitlesObj = { ...usersTitles, [ALL]: 'Usuário' };

  const handleExpand = () => setExpanded(!expanded);

  const handleSearch = (e) => {
    const newSearchValue = e.target.value;
    props.usersSearch(newSearchValue, listValue, 'created_at', sortItems);
    setSearchValue(newSearchValue);
  };
  const clearSearch = () => {
    props.usersSearch('', listValue, 'created_at', sortItems);
    setSearchValue('');
  };
  const handleSortMenu = () => {
    const newSortItems = !sortItems;
    props.usersSearch(searchValue, listValue, 'created_at', newSortItems);
    setSortItems(newSortItems);
  };
  const handleListValue = (e, v) => {
    const newListValue = v || ALL;
    props.usersSearch(searchValue, newListValue, 'created_at', sortItems);
    setListValue(newListValue);
  };

  if (props.users && props.users.length > 0 && !initialised) {
    props.usersSearch(searchValue, listValue, 'created_at', sortItems);
    setInitialised(true);
  }

  const ToggleUsers = () => (
    <Tooltip title="Tipo de usuário">
      <ToggleButtonGroup
        exclusive
        size="medium"
        value={listValue}
        className={clsx(classes.buttonGroup, 'toggle')}
        onChange={handleListValue}
      >
        {listUsers.map((userValue) => (
          <ToggleButton
            key={userValue}
            classes={{
              root: classes.toggleButton,
              selected: classes.toggleButtonSelected,
            }}
            value={userValue}
          >
            {usersTitlesObj[userValue]}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Tooltip>
  );

  return (
    <Grid
      container
      spacing={2}
      justify="flex-start"
    >
      <Grid item xs={3}>
        <Typography
          variant="h5"
          color="textSecondary"
          className={classes.pageTitle}
        >
          {`${usersTitlesObj[listValue]}s`}
        </Typography>

      </Grid>
      <Grid item xs={9} className={classes.btnholder}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Buscar..."
            onChange={(e) => { handleSearch(e); }}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            value={searchValue}
            endAdornment={(
              <IconButton onClick={(e) => clearSearch(e)} classes={{ root: classes.closeBtnTiny }} style={{ opacity: Boolean(searchValue) ? 1 : 0 }} size="small">
                <CloseIcon fontSize="small" classes={{ root: classes.closeBtnTinyIcon }} />
              </IconButton>
            )}
          />
        </div>
        <ToggleUsers />
        <Tooltip title="Ordenar por data de cadastro">
          <Button
            variant="contained"
            onClick={handleSortMenu}
            className={classes.buttonGroup}
            aria-label="Ascendente ou decrescente"
          >
            {'Ordenar'}
            <Icon style={{ fontSize: '1.2rem' }} className={clsx('fas', sortItems ? 'fa-sort-numeric-up' : 'fa-sort-numeric-down', classes.iconMargin)} />
          </Button>
        </Tooltip>
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <ExpansionPanel
          square
          elevation={3}
          expanded={expanded}
          onChange={handleExpand}
          classes={{ root: clsx(classes.bodyWrapper, expanded ? 'expanded' : 'header') }}
        >
          <ExpansionPanelSummary
            classes={{
              root: classes.bodyRoot,
              expandIcon: classes.buttonEdit,
              content: classes.noMargin,
            }}
            expandIcon={(
              <Tooltip title={expanded ? 'Fechar' : 'Adicionar usuário'}>
                <Button
                  className={classes.addUserButton}
                  variant="contained"
                  color="primary"
                >
                  {expanded
                    ? <CloseIcon className={classes.addUserIcon} />
                    : <PersonAddIcon className={classes.addUserIcon} />}
                </Button>
              </Tooltip>
            )}
          >
            {expanded && <Typography variant="h5">Adicionar usuário</Typography>}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            className={expanded ? classes.contentWrapper : 'notExpanded'}
          >
            <RowForm expanded={expanded} view={view} internalType={internalType} mode={ADD_MODE} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({ users: state.listUsers.users });

export default connect(mapStateToProps, { usersSearch })(UsersHeader);
