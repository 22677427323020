export const CREATE_BATCH_POST = 'CREATE_BATCH_POST';
export const CREATE_BATCH_POST_FULFILLED = 'CREATE_BATCH_POST_FULFILLED';
export const CREATE_BATCH_POST_REJECTED = 'CREATE_BATCH_POST_REJECTED';

export const UPDATE_BATCH_POST = 'UPDATE_BATCH_POST';
export const UPDATE_BATCH_POST_FULFILLED = 'UPDATE_BATCH_POST_FULFILLED';
export const UPDATE_BATCH_POST_REJECTED = 'UPDATE_BATCH_POST_REJECTED';

export const REMOVE_BATCH_POST = 'REMOVE_BATCH_POST';
export const REMOVE_BATCH_POST_FULFILLED = 'REMOVE_BATCH_POST_FULFILLED';
export const REMOVE_BATCH_POST_REJECTED = 'REMOVE_BATCH_POST_REJECTED';

export const FETCH_OPEN_BATCHPOSTS = 'FETCH_OPEN_BATCHPOSTS';
export const FETCH_OPEN_BATCHPOSTS_FULFILLED = 'FETCH_OPEN_BATCHPOSTS_FULFILLED';
export const FETCH_OPEN_BATCHPOSTS_REJECTED = 'FETCH_OPEN_BATCHPOSTS_REJECTED';

export const FETCH_DELIVERED_BATCHPOSTS = 'FETCH_DELIVERED_BATCHPOSTS';
export const FETCH_DELIVERED_BATCHPOSTS_FULFILLED = 'FETCH_DELIVERED_BATCHPOSTS_FULFILLED';
export const FETCH_DELIVERED_BATCHPOSTS_REJECTED = 'FETCH_DELIVERED_BATCHPOSTS_REJECTED';

export const FETCH_BATCHPOSTS = 'FETCH_BATCHPOSTS';
export const FETCH_BATCHPOSTS_FULFILLED = 'FETCH_BATCHPOSTS_FULFILLED';
export const FETCH_BATCHPOSTS_REJECTED = 'FETCH_BATCHPOSTS_REJECTED';

export const UPDATE_BATCH_POST_STATUS = 'UPDATE_BATCH_POST_STATUS';
export const UPDATE_BATCH_POST_STATUS_FULFILLED = 'UPDATE_BATCH_POST_STATUS_FULFILLED';
export const UPDATE_BATCH_POST_STATUS_REJECTED = 'UPDATE_BATCH_POST_STATUS_REJECTED';

export const BATCHPOSTS_DELIVER = 'BATCHPOSTS_DELIVER';
export const BATCHPOSTS_DELIVER_FULFILLED = 'BATCHPOSTS_DELIVER_FULFILLED';
export const BATCHPOSTS_DELIVER_REJECTED = 'BATCHPOSTS_DELIVER_REJECTED';

export const BATCHPOSTS_SENT = 'BATCHPOSTS_SENT';
export const BATCHPOSTS_SENT_FULFILLED = 'BATCHPOSTS_SENT_FULFILLED';
export const BATCHPOSTS_SENT_REJECTED = 'BATCHPOSTS_SENT_REJECTED';

export const BATCHPOST_SEARCH = 'BATCHPOST_SEARCH';
export const BATCHPOST_SEARCH_RESULT = 'BATCHPOST_SEARCH_RESULT';

export const CLEAR_RESPONSES = 'CLEAR_RESPONSES';
export const CLEAR_RESPONSES_FULFILLED = 'CLEAR_RESPONSES_FULFILLED';
