/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import { ServiceContext } from 'contexts/service';
import uniqid from 'uniqid';
import { connect } from 'react-redux';
import {
  Paper, IconButton, Button, Grid,
} from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
  ExpandMore as ExpandMoreIcon,
  CheckBox,
  CheckBoxOutlineBlank,
} from '@material-ui/icons';
import Contact from 'global/modules/Contact';
import { FULFILLED } from 'constants/common';
import * as $_ from 'global/functions';
import clsx from 'clsx';
import { showConfirmation } from 'store/actions/confirmations';
import { APOSTILAMENTO_ROW } from 'constants/service/menus';
import { confirmDocumentsDocumentation } from 'store/actions/documents';
import { PROCESS } from 'constants/service';
import { apiBaseUrl } from 'api';
import Axios from 'axios';
import { PROCESS_PATH } from 'api/paths';
import { LoadingComponent } from 'global/modules/Loading';
import { changeStepProcess } from 'store/actions/processes';
import { STEP_ANALYSIS } from 'constants/process';
import { useStyles } from '../style';
import ExpandContent from './ExpandContent';
import GridActions from './modules/GridActions';

function Row({ item, singleResult, setUpdate, actionStatus, ...props }) {
  const classes = useStyles();
  const [process, setProcess] = useState(item);
  const [fetched, setFetched] = useState(false);
  const [requested, setRequested] = useState(false);
  const serviceContext = useContext(ServiceContext);
  const {
    setMultipleSelect,
    multipleSelect,
    setDocsShown,
    handleShowMoreInfo,
    handleExpanded,
    openMenu,
    expanded,
    handleDatePickDialog,
  } = serviceContext;
  const setExpand = (id, e) => {
    $_.stopPropagation(e);
    handleExpanded(id);
  };
  const thisexpanded = expanded === process.id || singleResult;

  const handleDialogDatePick = (open, client, dateItem, e) => {
    handleDatePickDialog(open, client, dateItem);
    $_.stopPropagation(e);
  };

  const handleEndStep = () => {
    const confirmThis = () => {
      props.changeStepProcess(process.id, STEP_ANALYSIS, true);
      setRequested(true);
    };

    props.showConfirmation(
      {
        title: 'Confirmar',
        message: `Confirmar que os documentos de ${process.applicant.name} foram apostilados?`,
      },
      {
        confirm: 'Confirmar',
        cancel: 'Cancelar',
      },
      {
        func: confirmThis,
        payload: {},
      },
    );
  };

  useEffect(() => {
    const pathService = `${PROCESS_PATH}/${item.id}/documents`;
    Axios
      .get(`${apiBaseUrl}/${pathService}`)
      .then((res) => {
        const { data } = res;
        setProcess({
          ...item,
          documentationDocs: data.documents,
          allArrived: data.all_delivered,
        });
        setFetched(true);
      })
      .catch(() => setFetched(false));
  }, [item]);
  const thisselected = multipleSelect.indexOf(process.id) >= 0;


  useEffect(() => {
    if (actionStatus === FULFILLED && requested) {
      setUpdate(uniqid());
      setRequested(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionStatus, setUpdate]);

  return (
    <>
      <Paper
        key={`${process.id}`}
        className={clsx(classes.body, thisexpanded ? 'expanded' : 'regular', singleResult ? 'default' : 'apostilamento', thisselected ? 'selected' : 'noselect')}
      >
        <Grid
          onClick={(e) => {
            handleShowMoreInfo(true, process, e, PROCESS);
          }}
          className={clsx(classes.pointer)}
          container
          spacing={2}
        >
          {singleResult || true
            ? null
            : (
              <IconButton
                onClick={(e) => setMultipleSelect(process.id, e)}
                className={clsx(classes.floatingButtons, classes.floatingLeft, 'extremeLeft')}
                aria-label="Selecionar"
              >
                {thisselected ? <CheckBox /> : <CheckBoxOutlineBlank />}
              </IconButton>
            )}
          {singleResult
            ? null
            : (
              <IconButton
                onClick={(e) => setExpand(process.id, e)}
                className={clsx(classes.floatingButtons, classes.floatingLeft)}
                aria-label="Expandir"
              >
                <ExpandMoreIcon className={clsx(classes.expandIcon, thisexpanded ? 'expanded' : 'regular')} />
              </IconButton>
            )}
          <Grid item xs={3} className={classes.centeredGrid}>
            <Contact
              item={{ client_id: process.identifier }}
              customer={{ name: process.applicant.name }}
              short
            />
          </Grid>
          <Grid item xs={6} className={classes.centeredGrid}>
            <GridActions
              process={process}
              handleDialogDatePick={handleDialogDatePick}
              setDocsShown={setDocsShown}
            />
          </Grid>
          {!thisselected && (
            <Grid item xs={3} className={classes.centeredGrid}>
              {!fetched
                ? (
                  <Grid item xs={3} className={classes.centeredGrid}>
                    <LoadingComponent height={40} margin={0} />
                  </Grid>
                ) : (
                  <Button
                    onClick={(e) => { handleEndStep(); $_.stopPropagation(e); }}
                    variant={process.receive_to_apostilate_date && process.sent_to_apostilate_date ? 'contained' : 'outlined'}
                    color="primary"
                    disabled={!(
                      process.receive_to_apostilate_date && process.sent_to_apostilate_date
                    )}
                    fullWidth
                  >
                    {'Enviar para a análise'}
                  </Button>
                )}
            </Grid>
          )}
          <IconButton
            aria-label="Ações"
            aria-controls="long-menu"
            aria-haspopup="true"
            className={clsx(classes.floatingButtons, classes.floatingRight)}
            onClick={(e) => {
              $_.stopPropagation(e);
              openMenu(true, APOSTILAMENTO_ROW, e.currentTarget, process);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Grid>
        <ExpandContent
          classes={classes}
          process={process}
          thisexpanded={thisexpanded}
        />
      </Paper>
    </>
  );
}

const actions = {
  showConfirmation,
  confirmDocumentsDocumentation,
  changeStepProcess,
};

const mapStateToProps = (state) => ({
  actionStatus: state.processes.actionStatus,
  action: state.processes.actionStatus,
});

export default connect(mapStateToProps, actions)(Row);
