import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Snackbar,
  SnackbarContent,
  makeStyles,
} from '@material-ui/core/';
import { amber, green } from '@material-ui/core/colors/';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import IconButton from '@material-ui/core/IconButton';
import * as DUMMIES from 'objects/dummies';

const stoppropagation = (e) => {
  e && e.stopPropagation();
  e.nativeEvent && e.nativeEvent.stopImmediatePropagation();
}

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export function Alert({ alert, ...props }) {
  const [alerState, setalertState] = useState(true);
  const handleClose = e => {
    setalertState(false);
    if (e)
      stoppropagation(e)
  }
  const alertTypes = ['success', 'warning', 'error', 'info'];

  function MySnackbarContentWrapper(props) {
    const classes = useStyles1();
    const { className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    );
  }

  MySnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(alertTypes).isRequired,
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={alerState}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <MySnackbarContentWrapper
        onClose={handleClose}
        variant={alertTypes.find(a => { return a === alert.type }) !== undefined ? alert.type : 'info'}
        message={alert.message}
      />
    </Snackbar>
  )
}

function Alerts() {
  return (
    <>
      {
        DUMMIES.Alerts.map((alert, index) => {
          let date = (new Date(alert.expires)).getTime();
          let serverTime = (new Date(DUMMIES.ServerTime)).getTime();
          return (
            <div key={`alert${index}`}>
              {date > serverTime ? <Alert alert={alert} /> : null}
            </div>
          )
        })
      }
    </>
  )
}

export default Alerts;