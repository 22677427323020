import React from 'react';

export const SaleDashboardContext = React.createContext({
  menuEl: {
    scope: null,
    payment: null,
    contract: null,
  },
  sortBy: 'id',
  sortOrder: 'desc',
  q: '',
  currentTab: 0,
  masterTab: 1,
  step: 0,
  currentClient: {},
  expanded: null,
  dialogShareSale: false,
  dialogEditContract: false,
  dialogSignedContract: false,
  dialogPayment: false,
  dialogConfirmSale: false,
  masterView: false,
  multiSelect: [],
  clearSearchVariables: () => { },
  handleDialogEditContract: () => { },
  setSortBy: (value) => { },
  setSearch: (value) => { },
  setSortOrder: (value) => { },
  setCurrentClient: (client) => { },
  setMenuEl: (element, menu) => { },
  openMenu: (open, menu, e, currentClient, step) => { },
  setCurrentTab: (tab) => { },
  setCurrentMasterTab: (tab) => { },
  setExpanded: (newExpanded) => { },
  setDialogSignedContract: () => { },
  handleDialogShareSale: (id = [], shared = []) => { },
  handleDialogSignedContract: (currentClient) => { },
  setDialogPayment: () => { },
  handleDialogConfirmSale: (currentClient) => { },
  handleDialogPayment: (currentClient) => { },
  handleSelectMultiple: (id) => { },
  handleSendContractEmail: (currentClient) => { },
  checkMultiSelected: (id) => Boolean(id),
  toggleMasterView: () => { },
  handleMasterView: (view) => { },
});
