import { processForm } from 'constants/sales';

export const getFormObj = (process, people) => {
  if (process && people) {
    const amounts = {};
    const extraProducts = [];
    process.products.forEach((p) => {
      if (p.can_attached) {
        amounts[p.id] = process.products.filter((o) => o.id === p.id).length;
        if (!extraProducts.find((a) => a.id === p.id)) {
          extraProducts.push({
            is_courtesy: p.is_courtesy,
            apostilate: p.pivot.apostilate,
            use_old_price: p.pivot.use_old_price,
            id: p.id,
          });
        }
      }
    });
    const mainProduct = process.products.find((p) => !p.can_attached);
    return ({
      ...processForm,
      applicantId: process.applicant_id,
      name: people.name,
      birthdate: people.birthdate,
      gender: people.gender,
      hosts: process.hosts[0].id,
      parent_type: process.host_relations,
      is_courtesy: Boolean(process.is_courtesy),
      products: [{
        id: mainProduct.id,
        apostilate: Boolean(mainProduct.pivot.apostilate),
        use_old_price: Boolean(mainProduct.pivot.use_old_price),
        is_courtesy: Boolean(mainProduct.is_courtesy),
      }],
      product: [mainProduct.id],
      amounts,
      extraProducts,
      premium_service: process.premium_service,
      documents: process.documents
    });
  }
  return processForm;
};
