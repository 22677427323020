import React, { useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Tabs,
  Tab,
  Link,
  Typography,
  Tooltip,
  Icon,
  IconButton,
  Button,
  InputBase,
  Container,
} from '@material-ui/core';
import { ServiceContext } from 'contexts/service';
import {
  Close as CloseIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import { useStyles } from 'service/style';
import { kebabCase } from 'lodash';
import { showConfirmation } from 'store/actions/confirmations';
import { processSearch } from 'store/actions/processes';
import { confirmAligmentMultiple } from 'store/actions/sales';

function Header({
  pageTitle,
  menuValue,
  handleMenu,
  tabsKebab,
  tabs,
  ...props
}) {
  const classes = useStyles(props);
  const serviceContext = useContext(ServiceContext);
  const {
    setSearchValue, searchValue, setSortItems, sortItems,
  } = serviceContext;

  const handleChange = (e, variant) => {
    let newValue = searchValue;
    let newSort = sortItems;
    switch (variant) {
      case 'search':
        newValue = e.target.value;
        setSearchValue(e.target.value);
        break;

      default:
        newSort = !newSort;
        setSortItems();
        break;
    }
    props.processSearch(newValue, false, 'align_date', newSort);
  };
  const clearSearch = () => {
    setSearchValue('');
    props.processSearch('', false, 'align_date', sortItems);
  };
  return (
    <Container maxWidth="xl">
      <Grid container spacing={2} className={classes.subMenuHolder}>
        <Grid item xs={6} className={classes.tabsGrid}>
          <Tabs
            value={menuValue}
            onChange={handleMenu}
            textColor="primary"
            classes={{ indicator: classes.tabIndicator }}
            variant="standard"
            indicatorColor="primary"
          >
            {tabs.map((tab, i) => {
              const link = `${window.location.origin}/servico/${kebabCase(pageTitle)}/${tabsKebab[i]}`;
              return (
                <Tab
                  key={`tab${tab}`}
                  onClick={(event) => {
                    window.history.pushState(tabsKebab[i], tab, `/servico/${kebabCase(pageTitle)}/${tabsKebab[i]}`);
                    event.preventDefault();
                  }}
                  href={link}
                  component={Link}
                  underline="none"
                  className={classes.tabButton}
                  aria-label={tab}
                  label={(
                    <Typography
                      className={classes.tabLink}
                      variant="body2"
                      color={menuValue === i ? 'primary' : 'textPrimary'}
                    >
                      {tab}
                    </Typography>
                  )}
                />
              );
            })}
          </Tabs>
        </Grid>
        <Grid item xs={6} className={classes.btnholder}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Buscar..."
              onChange={(e) => handleChange(e, 'search')}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              value={searchValue}
              endAdornment={(
                <IconButton onClick={(e) => clearSearch(e)} classes={{ root: classes.closeBtnTiny }} style={{ opacity: Boolean(searchValue) ? 1 : 0 }} size="small">
                  <CloseIcon fontSize="small" classes={{ root: classes.closeBtnTinyIcon }} />
                </IconButton>
              )}
            />
          </div>
          <Tooltip title="Ordenar por data de alinhamento">
            <Button
              variant="contained"
              onClick={(e) => handleChange(e, 'sort')}
              className={classes.buttonGroup}
              aria-label="Ascendente ou decrescente"
            >
              {'Ordenar'}
              <Icon style={{ fontSize: '1.2rem' }} className={clsx('fas', sortItems ? 'fa-sort-numeric-up' : 'fa-sort-numeric-down', classes.iconMargin)} />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Container>
  );
}

Header.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  tabsKebab: PropTypes.array.isRequired,
  tabs: PropTypes.array.isRequired,
  handleMenu: PropTypes.func.isRequired,
  menuValue: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  scopes: state.sales.allSales,
});

const actions = { processSearch, confirmAligmentMultiple, showConfirmation };

export default connect(mapStateToProps, actions)(Header);
