export const FETCH_ALL_USERS = 'FETCH_ALL_USERS';
export const FETCH_ALL_USERS_FULFILLED = 'FETCH_ALL_USERS_FULFILLED';
export const FETCH_ALL_USERS_REJECTED = 'FETCH_ALL_USERS_REJECTED';

export const USERS_SEARCH = 'USERS_SEARCH';
export const USERS_SEARCH_RESULT = 'USERS_SEARCH_RESULT';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_FULFILLED = 'CREATE_USER_FULFILLED';
export const CREATE_USER_REJECTED = 'CREATE_USER_REJECTED';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_FULFILLED = 'UPDATE_USER_FULFILLED';
export const UPDATE_USER_REJECTED = 'UPDATE_USER_REJECTED';

export const REMOVE_USER = 'REMOVE_USER';
export const REMOVE_USER_FULFILLED = 'REMOVE_USER_FULFILLED';
export const REMOVE_USER_REJECTED = 'REMOVE_USER_REJECTED';
