import * as definitions from 'store/definitions/batchpost';
import { DEFAULT, REQUESTING } from 'constants/common';
import { FULFILLED, REJECTED } from 'constants/session';

const clearState = {
  allLots: [],
  collection: [],
  openBatchPosts: [],
  status: DEFAULT,
  actionStatus: DEFAULT,
};

function Batchpost(state = {
  ...clearState,
}, action) {
  switch (action.type) {
    case definitions.FETCH_OPEN_BATCHPOSTS:
      return {
        ...state,
        status: REQUESTING,
      };
    case definitions.FETCH_OPEN_BATCHPOSTS_REJECTED:
      return {
        ...state,
        status: REJECTED,
      };
    case definitions.FETCH_BATCHPOSTS:
      return {
        ...state,
        status: REQUESTING,
      };
    case definitions.FETCH_BATCHPOSTS_REJECTED:
      return {
        ...state,
        status: REJECTED,
      };
    case definitions.FETCH_OPEN_BATCHPOSTS_FULFILLED:
      return {
        ...state,
        openBatchPosts: action.payload,
        allLots: action.payload,
        status: FULFILLED,
      };
    case definitions.FETCH_DELIVERED_BATCHPOSTS_FULFILLED:
    case definitions.FETCH_BATCHPOSTS_FULFILLED:
      return {
        ...state,
        collection: action.payload,
        allLots: action.payload,
        status: FULFILLED,
      };
    case definitions.FETCH_DELIVERED_BATCHPOSTS:
      return {
        ...state,
        status: REQUESTING,
      };
    case definitions.FETCH_DELIVERED_BATCHPOSTS_REJECTED:
      return {
        ...state,
        status: REJECTED,
      };
    case definitions.BATCHPOSTS_DELIVER:
    case definitions.BATCHPOSTS_SENT:
    case definitions.CREATE_BATCH_POST:
    case definitions.UPDATE_BATCH_POST:
    case definitions.REMOVE_BATCH_POST:
    case definitions.UPDATE_BATCH_POST_STATUS:
      return {
        ...state,
        actionStatus: REQUESTING,
      };
    case definitions.BATCHPOSTS_DELIVER_REJECTED:
    case definitions.BATCHPOSTS_SENT_REJECTED:
    case definitions.CREATE_BATCH_POST_REJECTED:
    case definitions.UPDATE_BATCH_POST_REJECTED:
    case definitions.REMOVE_BATCH_POST_REJECTED:
    case definitions.UPDATE_BATCH_POST_STATUS_REJECTED:
      return {
        ...state,
        actionStatus: REJECTED,
      };
    case definitions.BATCHPOSTS_DELIVER_FULFILLED:
    case definitions.BATCHPOSTS_SENT_FULFILLED:
    case definitions.CREATE_BATCH_POST_FULFILLED:
    case definitions.UPDATE_BATCH_POST_FULFILLED:
    case definitions.REMOVE_BATCH_POST_FULFILLED:
    case definitions.UPDATE_BATCH_POST_STATUS_FULFILLED:
      return {
        ...state,
        actionStatus: FULFILLED,
      };
    case definitions.BATCHPOST_SEARCH_RESULT:
      return {
        ...state,
        collection: action.payload,
      };
    case definitions.CLEAR_RESPONSES_FULFILLED:
      return {
        ...state,
        ...clearState,
      };
    default:
      return state;
  }
}

export default Batchpost;
