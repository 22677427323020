import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: 'calc(100% - 48px) !important',
    paddingBottom: theme.spacing(7),
  },
  editor: {
    marginTop: theme.spacing(1),
    minHeight: theme.spacing(5),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    borderColor: fade(theme.palette.common.black, 0.23),
    borderWidth: '1px',
    borderStyle: 'solid',
    position: 'relative',
    cursor: 'pointer',
    '& .fadeout': {
      opacity: 1,
    },
    '& .fadein': {
      opacity: 0,
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '&.smaller': {
      minHeight: theme.spacing(20),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    '&:hover': {
      '& .fadeout': {
        opacity: 0.2,
      },
      '& .fadein': {
        opacity: 1,
      }
    },
    '& .fadeout, & .fadein': {
      transition: theme.transitions.create('opacity'),
    },
    '& p, & [data-block=true]:not(h2):not(h6)': {
      ...theme.typography.body1,
      minHeight: '1rem',
    },
    '& h6, & h2': {
      ...theme.typography.h6,
    },
    '& strong': {
      fontWeight: 'bolder',
    },
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  addMorePanel: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  addMoreButton: {
    color: theme.palette.common.white,
  },
  removeButton: {
    '&:hover': {
      backgroundColor: `${theme.palette.error.dark} !important`,
      color: `${theme.palette.common.white} !important`,
    },
  },
  tabs: {
    width: 'calc(100% + 64px) !important',
    margin: `${theme.spacing(-3)}px ${theme.spacing(-4)}px ${theme.spacing(3)}px !important`,
  },
  closeBtnTiny: {
    marginRight: theme.spacing(0.5),
  },
  closeBtnTinyIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  buttonGroup: {
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(5),
    border: 'none',
    boxShadow: theme.shadows[1],
    marginLeft: '1px !important',
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
  iconMargin: {
    marginLeft: `${theme.spacing(1)}px !important`,
  },
  subMenuHolder: {
    paddingBottom: `${theme.spacing(4)}px !important`,
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  btnholder: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  },
  search: {
    maxWidth: '100%',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 1),
    '&:hover': {
      backgroundColor: fade('#000000', 0.08),
    },
    marginRight: 0,
    width: 'auto',
    display: 'inline-flex',
    boxShadow: theme.shadows[1],
    height: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
      marginBottom: 0,
    },
  },
  searchDate: {
    maxWidth: '100%',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 1),
    '&:hover': {
      backgroundColor: fade('#000000', 0.08),
    },
    marginRight: 0,
    width: 'auto',
    display: 'inline-flex',
    boxShadow: theme.shadows[1],
    height: theme.spacing(5),
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
      marginBottom: 0,
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputCloseBtn: {
    transition: theme.transitions.create('opacity'),
    marginRight: theme.spacing(1),
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: 90,
    '&:focus, &:hover': {
      width: 230,
      maxWidth: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      '&:focus, &:hover': {
        width: 280,
      },
    },
  },
}));