import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { apiBaseUrl } from 'api';
import { SCOPE_PATH } from 'api/paths';
import PropTypes from 'prop-types';
import axios from 'axios';
import { ServiceContext } from 'contexts/service';
import {
  Paper,
  Typography,
  Grid,
} from '@material-ui/core';
import { showNotification } from 'store/actions/notifications';
import CopyElement from 'global/CopyElement';
import {
  Call as PhoneIcon,
  Email as EmailIcon,
} from '@material-ui/icons';
import { semanticPlurals } from 'helpers';
import clsx from 'clsx';
import { updateDialog } from 'store/actions/dialogs';
import * as $_ from 'global/functions';
import { SCOPE } from 'constants/service';
import { MESSAGE_NO_SALES_FOUND } from 'constants/common/messages';
import { NOTIFICATION_TYPE_WARNING } from 'constants/notification';
import { useStyles } from './style';
import { readableBRPhoneNumbers } from 'helpers'; 

function Result({
  customer, notification, update, setUpdate, ...props
}) {
  const classes = useStyles(props);
  const [processes, setProcesses] = useState({});
  const [error, setError] = useState(false);
  const [sales, setSales] = useState([]);
  const {
    handleShowMoreInfo,
  } = useContext(ServiceContext);
  const handleOpen = () => {
    if (sales.length > 0) {
      handleShowMoreInfo(true, sales[0], null, SCOPE);
    } else {
      notification(NOTIFICATION_TYPE_WARNING, MESSAGE_NO_SALES_FOUND(customer.name));
    }
  };

  let phoneNumber = '';
  if (customer && customer.phone) phoneNumber = readableBRPhoneNumbers(customer.phone);

  useEffect(() => {
    const logProcesses = {};
    if (sales.length > 0 && update) {
      sales.forEach((sale) => {
        sale.processes.forEach((process) => {
          if (process.products[0] && process.products[0].title) {
            const value = logProcesses[process.products[0].title];
            logProcesses[process.products[0].title] = value ? value + 1 : 1;
          }
        });
      });
      setProcesses(logProcesses);
    }
  }, [sales, update]);

  useEffect(() => {
    if (update) {
      axios
        .get(`${apiBaseUrl}/${SCOPE_PATH}/by-customer/${customer.id}`)
        .then((res) => setSales(res.data.scope))
        .catch(() => setError(true));
    }
  }, [customer, update]);

  return (
    <Paper
      key={`${customer.name}${customer.id}`}
      className={clsx(classes.body, sales.length > 0 ? 'pointer' : 'regular')}
      onClick={() => handleOpen()}
    >
      <Grid container spacing={2}>
        <Grid item xs={6} sm={3} className={classes.centeredGrid}>
          <Typography>
            <CopyElement>{customer.name}</CopyElement>
          </Typography>
          {!error
            && (
              <Typography color="textSecondary">
                {sales.length > 0 ? `${sales.length} venda${semanticPlurals(sales, '', 's')}` : 'Nenhuma venda encontrada'}
              </Typography>
            )}
        </Grid>
        <Grid item xs={6} sm={3} className={clsx(classes.centeredGrid, 'center')}>
          <Typography className={classes.pLine}>
            <EmailIcon className={classes.icon} color="disabled" fontSize="inherit" />
            <CopyElement>{customer.email}</CopyElement>
          </Typography>
          <Typography className={classes.pLine}>
            <PhoneIcon className={classes.icon} color="disabled" fontSize="inherit" />
            <CopyElement>{phoneNumber}</CopyElement>
          </Typography>
        </Grid>
        <Grid item xs={3} className={clsx(classes.centeredGrid, 'center', classes.columnLessImportant)}>
          <Typography color="textSecondary">
            {'Data de entrada'}
          </Typography>
          <Typography>{$_.semanticDate(customer.created_at, true)}</Typography>
        </Grid>
        <Grid item xs={3} className={clsx(classes.centeredGrid, 'center', classes.columnLessImportant)}>
          <Typography color="textSecondary">
            {'Processos'}
          </Typography>
          {Object.keys(processes).length > 0
            ? Object.keys(processes).map((key) => (
              <Typography key={`${customer.id}${key}`}>
                {`${processes[key]}x ${key}`}
              </Typography>
            )) : (
              <Typography>
                {'Nenhum processo contratado'}
              </Typography>
            )}
        </Grid>
      </Grid>
    </Paper>
  );
}

Result.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    created_at: PropTypes.string,
  }).isRequired,
  notification: PropTypes.func.isRequired,
};

export default connect(null, { updateDialog, notification: showNotification })(Result);
