import React from 'react';
import { connect } from 'react-redux';
import { UserContext } from 'contexts/user';
import { MASTER, INTERNAL, CUSTOMER, MANAGER, INTERNAL_SALES, INTERNAL_SERVICE, INTERNAL_COORDINATOR } from 'constants/user';

const UserProvider = ({ children, user }) => {
  const [state, setState] = React.useState(user);

  const isMaster = state && state.userType === MASTER;
  const isManager = state && state.userType === MANAGER;
  const isInternal = (state && state.userType === INTERNAL) || isManager || isMaster;
  const isCustomer = state && state.userType === CUSTOMER;
  const userInternal = isInternal || isMaster;
  const userSales = (
    (isInternal && state && state.internalType === INTERNAL_SALES)
    || isMaster
  );
  const userCoordinator = (
    (isInternal && state && state.internalType === INTERNAL_COORDINATOR) || isMaster
  );
  const userCustomer = isCustomer || isMaster;
  const userService = (
    (isInternal && state && state.internalType === INTERNAL_SERVICE)
    || isMaster
  );
  const setUser = (user) => setState(user);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        isMaster,
        isInternal,
        isCustomer,
        isManager,
        userInternal,
        userSales,
        userCustomer,
        userService,
        userCoordinator,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

const mapStateToProps = (state) => ({
  user: state.session.user,
});

export default connect(mapStateToProps)(UserProvider);
