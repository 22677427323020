export const LOGIN = 'LOGIN';
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';

export const LOGOUT = 'LOGOUT';

export const CHECK_AUTH = 'CHECK_AUTH';
export const CHECK_AUTH_PENDING = 'CHECK_AUTH_PENDING';
export const CHECK_AUTH_FULFILLED = 'CHECK_AUTH_FULFILLED';
export const CHECK_AUTH_REJECTED = 'CHECK_AUTH_REJECTED';

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_FULFILLED = 'UPDATE_USER_PROFILE_FULFILLED';
export const UPDATE_USER_PROFILE_REJECTED = 'UPDATE_USER_PROFILE_REJECTED';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_FULFILLED = 'CHANGE_PASSWORD_FULFILLED';
export const CHANGE_PASSWORD_REJECTED = 'CHANGE_PASSWORD_REJECTED';
