import React, { useRef, useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { showNotification } from 'store/actions/notifications';
import { Tooltip, Typography, makeStyles } from '@material-ui/core';
import { NOTIFICATION_TYPE_BLACK } from 'constants/notification';
import { MESSAGE_COPIED } from 'constants/common/messages';
import { string, bool, oneOfType, node, func } from 'prop-types';

const useStyles = makeStyles((theme) => ({
  hoverText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  hiddenText: {
    height: 1,
    position: 'absolute',
    top: 2,
    zIndex: -1,
    padding: 0,
    left: 0,
    margin: 0,
    border: 'none',
  },
}));

function CopyElement({
  component,
  lineBreak,
  placement,
  color,
  noMaxHeight,
  children,
  disabled,
  value,
  notification,
}) {
  const classes = useStyles({});

  const [valueToCopy, setValueToCopy] = useState(value || children);

  useEffect(() => {
    setValueToCopy(value || children);
  }, [value, children]);

  const textRef = useRef(null);
  const toCopy = document.queryCommandSupported('copy');

  const CopyToClipboard = (e) => {
    if (valueToCopy) textRef.current.value = valueToCopy;
    textRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    notification(NOTIFICATION_TYPE_BLACK, MESSAGE_COPIED);
  };

  const handleClick = (e) => {
    if (!disabled) {
      e.preventDefault();
      e.stopPropagation();
      if (toCopy && !isMobile) CopyToClipboard(e);
    }
  };

  const conditionalStyle = {
    cursor: disabled ? 'inherit' : 'pointer',
    maxHeight: noMaxHeight ? 'unset' : '4.5rem',
  };

  return (
    <>
      <Tooltip
        title={toCopy && !disabled ? `Copiar "${valueToCopy}"` : ''}
        placement={placement}
        onClick={(e) => handleClick(e)}
      >
        <Typography
          variant="body1"
          component={component}
          color={color}
          style={conditionalStyle}
          className={classes.hoverText}
        >
          {children}
          {!disabled && (
            <textarea
              defaultValue={valueToCopy || children}
              className={classes.hiddenText}
              ref={textRef}
            />
          )}
        </Typography>
      </Tooltip>
      {lineBreak && <br />}
    </>
  );
}

CopyElement.propTypes = {
  component: string,
  color: string,
  placement: string,
  lineBreak: bool,
  noMaxHeight: bool,
  children: oneOfType([string, node]),
  value: string,
  disabled: bool,
  notification: func.isRequired,
};

CopyElement.defaultProps = {
  component: 'span',
  color: 'inherit',
  placement: 'left',
  lineBreak: false,
  noMaxHeight: false,
  children: '',
  disabled: false,
  value: '',
};

export default connect(null, { notification: showNotification })(CopyElement);
