export const FETCH_ALL_OPEN_SCOPES = 'FETCH_ALL_OPEN_SCOPES';
export const FETCH_ALL_OPEN_SCOPES_REJECTED = 'FETCH_ALL_OPEN_SCOPES_REJECTED';
export const FETCH_ALL_OPEN_SCOPES_FULFILLED = 'FETCH_ALL_OPEN_SCOPES_FULFILLED';

export const FETCH_ALL_LEADS = 'FETCH_ALL_LEADS';
export const FETCH_ALL_LEADS_FULFILLED = 'FETCH_ALL_LEADS_FULFILLED';
export const FETCH_ALL_LEADS_REJECTED = 'FETCH_ALL_LEADS_REJECTED';

export const FETCH_ALL_ACCOMPANIMENT = 'FETCH_ALL_ACCOMPANIMENT';
export const FETCH_ALL_ACCOMPANIMENT_FULFILLED = 'FETCH_ALL_ACCOMPANIMENT_FULFILLED';
export const FETCH_ALL_ACCOMPANIMENT_REJECTED = 'FETCH_ALL_ACCOMPANIMENT_REJECTED';

export const FETCH_ALL_HISTORIC = 'FETCH_ALL_HISTORIC';
export const FETCH_ALL_HISTORIC_FULFILLED = 'FETCH_ALL_HISTORIC_FULFILLED';
export const FETCH_ALL_HISTORIC_REJECTED = 'FETCH_ALL_HISTORIC_REJECTED';

export const FETCH_LEADS_BY_SALESMAN = 'FETCH_LEADS_BY_SALESMAN';
export const FETCH_LEADS_BY_SALESMAN_FULFILLED = 'FETCH_LEADS_BY_SALESMAN_FULFILLED';
export const FETCH_LEADS_BY_SALESMAN_REJECTED = 'FETCH_LEADS_BY_SALESMAN_REJECTED';

export const FETCH_ACCOMPANIMENT_BY_SALESMAN = 'FETCH_ACCOMPANIMENT_BY_SALESMAN';
export const FETCH_ACCOMPANIMENT_BY_SALESMAN_FULFILLED = 'FETCH_ACCOMPANIMENT_BY_SALESMAN_FULFILLED';
export const FETCH_ACCOMPANIMENT_BY_SALESMAN_REJECTED = 'FETCH_ACCOMPANIMENT_BY_SALESMAN_REJECTED';

export const FETCH_HISTORIC_BY_SALESMAN = 'FETCH_HISTORIC_BY_SALESMAN';
export const FETCH_HISTORIC_BY_SALESMAN_FULFILLED = 'FETCH_HISTORIC_BY_SALESMAN_FULFILLED';
export const FETCH_HISTORIC_BY_SALESMAN_REJECTED = 'FETCH_HISTORIC_BY_SALESMAN_REJECTED';

export const REMOVE_DOCUMENT_FROM_PERSON = 'REMOVE_DOCUMENT_FROM_PERSON';
export const REMOVE_DOCUMENT_FROM_PERSON_FULFILLED = 'REMOVE_DOCUMENT_FROM_PERSON_FULFILLED';
export const REMOVE_DOCUMENT_FROM_PERSON_REJECTED = 'REMOVE_DOCUMENT_FROM_PERSON_REJECTED';

export const SALES_SEARCH = 'SEARCH';
export const SALES_SEARCH_RESULT = 'SEARCH_RESULT';

export const RESULTS_CLEAR = 'RESULTS_CLEAR';
export const RESULTS_CLEAR_DONE = 'RESULTS_CLEAR_DONE';

export const FETCH_SALE_SCOPE_BY_SCOPE_ID = 'FETCH_SALE_SCOPE_BY_SCOPE_ID';
export const FETCH_SALE_SCOPE_BY_SCOPE_ID_FULFILLED = 'FETCH_SALE_SCOPE_BY_SCOPE_ID_FULFILLED';
export const FETCH_SALE_SCOPE_BY_SCOPE_ID_REJECTED = 'FETCH_SALE_SCOPE_BY_SCOPE_ID_REJECTED';

export const UPDATE_SALE_SCOPE_BY_ID = 'UPDATE_SALE_SCOPE_BY_ID';
export const UPDATE_SALE_SCOPE_BY_ID_FULFILLED = 'UPDATE_SALE_SCOPE_BY_ID_FULFILLED';
export const UPDATE_SALE_SCOPE_BY_ID_REJECTED = 'UPDATE_SALE_SCOPE_BY_ID_REJECTED';

export const CREATE_SCOPE_BY_CLIENT_ID = 'CREATE_SCOPE_BY_CLIENT_ID';
export const CREATE_SCOPE_BY_CLIENT_ID_FULFILLED = 'CREATE_SCOPE_BY_CLIENT_ID_FULFILLED';
export const CREATE_SCOPE_BY_CLIENT_ID_REJECTED = 'CREATE_SCOPE_BY_CLIENT_ID_REJECTED';

export const CREATE_PERSON = 'CREATE_PERSON';
export const CREATE_PERSON_FULFILLED = 'CREATE_PERSON_FULFILLED';
export const CREATE_PERSON_REJECTED = 'CREATE_PERSON_REJECTED';

export const UPDATE_PERSON = 'UPDATE_PERSON';
export const UPDATE_PERSON_FULFILLED = 'UPDATE_PERSON_FULFILLED';
export const UPDATE_PERSON_REJECTED = 'UPDATE_PERSON_REJECTED';

export const REMOVE_PERSON = 'REMOVE_PERSON';
export const REMOVE_PERSON_FULFILLED = 'REMOVE_PERSON_FULFILLED';
export const REMOVE_PERSON_REJECTED = 'REMOVE_PERSON_REJECTED';

export const CREATE_PROCESS = 'CREATE_PROCESS';
export const CREATE_PROCESS_FULFILLED = 'CREATE_PROCESS_FULFILLED';
export const CREATE_PROCESS_REJECTED = 'CREATE_PROCESS_REJECTED';

export const UPDATE_PROCESS = 'UPDATE_PROCESS';
export const UPDATE_PROCESS_FULFILLED = 'UPDATE_PROCESS_FULFILLED';
export const UPDATE_PROCESS_REJECTED = 'UPDATE_PROCESS_REJECTED';

export const REMOVE_PROCESS = 'REMOVE_PROCESS';
export const REMOVE_PROCESS_FULFILLED = 'REMOVE_PROCESS_FULFILLED';
export const REMOVE_PROCESS_REJECTED = 'REMOVE_PROCESS_REJECTED';

export const UPDATE_SIGNED_CONTRACT = 'UPDATE_SIGNED_CONTRACT';
export const UPDATE_SIGNED_CONTRACT_FULFILLED = 'UPDATE_SIGNED_CONTRACT_FULFILLED';
export const UPDATE_SIGNED_CONTRACT_REJECTED = 'UPDATE_SIGNED_CONTRACT_REJECTED';

export const CONFIRM_SCOPE = 'CONFIRM_SCOPE';
export const CONFIRM_SCOPE_FULFILLED = 'CONFIRM_SCOPE_FULFILLED';
export const CONFIRM_SCOPE_REJECTED = 'CONFIRM_SCOPE_REJECTED';
export const FETCH_TO_ALIGN = 'FETCH_TO_ALIGN';
export const FETCH_TO_ALIGN_FULFILLED = 'FETCH_TO_ALIGN_FULFILLED';
export const FETCH_TO_ALIGN_REJECTED = 'FETCH_TO_ALIGN_REJECTED';

export const CONFIRM_ALIGNMENT = 'CONFIRM_ALIGNMENT';
export const CONFIRM_ALIGNMENT_FULFILLED = 'CONFIRM_ALIGNMENT_FULFILLED';
export const CONFIRM_ALIGNMENT_REJECTED = 'CONFIRM_ALIGNMENT_REJECTED';

export const CONFIRM_ALIGNMENT_MULTIPLE = 'CONFIRM_ALIGNMENT_MULTIPLE';
export const CONFIRM_ALIGNMENT_MULTIPLE_FULFILLED = 'CONFIRM_ALIGNMENT_MULTIPLE_FULFILLED';
export const CONFIRM_ALIGNMENT_MULTIPLE_REJECTED = 'CONFIRM_ALIGNMENT_MULTIPLE_REJECTED';

export const SEND_DOCUMENTATION_EMAIL = 'SEND_DOCUMENTATION_EMAIL';
export const SEND_DOCUMENTATION_EMAIL_FULFILLED = 'SEND_DOCUMENTATION_EMAIL_FULFILLED';
export const SEND_DOCUMENTATION_EMAIL_REJECTED = 'SEND_DOCUMENTATION_EMAIL_REJECTED';

export const SEND_CONTRACT_EMAIL = 'SEND_CONTRACT_EMAIL';
export const SEND_CONTRACT_EMAIL_FULFILLED = 'SEND_CONTRACT_EMAIL_FULFILLED';
export const SEND_CONTRACT_EMAIL_REJECTED = 'SEND_CONTRACT_EMAIL_REJECTED';

export const FETCH_TO_DOCUMENTATION = 'FETCH_TO_DOCUMENTATION';
export const FETCH_TO_DOCUMENTATION_FULFILLED = 'FETCH_TO_DOCUMENTATION_FULFILLED';
export const FETCH_TO_DOCUMENTATION_REJECTED = 'FETCH_TO_DOCUMENTATION_REJECTED';

export const FETCH_SALES_BY_CLIENT = 'FETCH_SALES_BY_CLIENT';
export const FETCH_SALES_BY_CLIENT_FULFILLED = 'FETCH_SALES_BY_CLIENT_FULFILLED';
export const FETCH_SALES_BY_CLIENT_REJECTED = 'FETCH_SALES_BY_CLIENT_REJECTED';

export const FETCH_MODULAR_SALES_BY_CLIENT = 'FETCH_MODULAR_SALES_BY_CLIENT';
export const FETCH_MODULAR_SALES_BY_CLIENT_FULFILLED = 'FETCH_MODULAR_SALES_BY_CLIENT_FULFILLED';
export const FETCH_MODULAR_SALES_BY_CLIENT_REJECTED = 'FETCH_MODULAR_SALES_BY_CLIENT_REJECTED';

export const FETCH_CONFIRMATION_SALES = 'FETCH_CONFIRMATION_SALES';
export const FETCH_CONFIRMATION_SALES_FULFILLED = 'FETCH_CONFIRMATION_SALES_FULFILLED';
export const FETCH_CONFIRMATION_SALES_REJECTED = 'FETCH_CONFIRMATION_SALES_REJECTED';

export const INITIATE_PROCESSES_SALE = 'INITIATE_PROCESSES_SALE';
export const INITIATE_PROCESSES_SALE_FULFILLED = 'INITIATE_PROCESSES_SALE_FULFILLED';
export const INITIATE_PROCESSES_SALE_REJECTED = 'INITIATE_PROCESSES_SALE_REJECTED';

export const DELETE_SCOPE = 'DELETE_SCOPE';
export const DELETE_SCOPE_FULFILLED = 'DELETE_SCOPE_FULFILLED';
export const DELETE_SCOPE_REJECTED = 'DELETE_SCOPE_REJECTED';

export const CHANGE_CLIENT_ID = 'CHANGE_CLIENT_ID';
export const CHANGE_CLIENT_ID_FULFILLED = 'CHANGE_CLIENT_ID_FULFILLED';
export const CHANGE_CLIENT_ID_REJECTED = 'CHANGE_CLIENT_ID_REJECTED';

export const SHARE_SALE = 'SHARE_SALE';
export const SHARE_SALE_FULFILLED = 'SHARE_SALE_FULFILLED';
export const SHARE_SALE_REJECTED = 'SHARE_SALE_REJECTED';

export const BATCH_STATUS = 'BATCH_STATUS';
export const BATCH_STATUS_FULFILLED = 'BATCH_STATUS_FULFILLED';
export const BATCH_STATUS_REJECTED = 'BATCH_STATUS_REJECTED';

export const DELETE_SHARE_SALE = 'DELETE_SHARE_SALE';
export const DELETE_SHARE_SALE_FULFILLED = 'DELETE_SHARE_SALE_FULFILLED';
export const DELETE_SHARE_SALE_REJECTED = 'DELETE_SHARE_SALE_REJECTED';

export const FETCH_PERSON_CONSELHOS = 'FETCH_PERSON_CONSELHOS';
export const FETCH_PERSON_CONSELHOS_FULFILLED = 'FETCH_PERSON_CONSELHOS_FULFILLED';
export const FETCH_PERSON_CONSELHOS_REJECTED = 'FETCH_PERSON_CONSELHOS_REJECTED';

export const FETCH_PERSON_FREGUESIAS = 'FETCH_PERSON_FREGUESIAS';
export const FETCH_PERSON_FREGUESIAS_FULFILLED = 'FETCH_PERSON_FREGUESIAS_FULFILLED';
export const FETCH_PERSON_FREGUESIAS_REJECTED = 'FETCH_PERSON_FREGUESIAS_REJECTED';

export const SEND_CLIENT_INFO = 'SEND_CLIENT_INFO';
export const SEND_CLIENT_INFO_FULFILLED = 'SEND_CLIENT_INFO_FULFILLED';
export const SEND_CLIENT_INFO_REJECTED = 'SEND_CLIENT_INFO_REJECTED';

export const CALCULATE_SALE_SCOPE_BY_ID = 'CALCULATE_SALE_SCOPE_BY_ID';
export const CALCULATE_SALE_SCOPE_BY_ID_FULFILLED = 'CALCULATE_SALE_SCOPE_BY_ID_FULFILLED';
export const CALCULATE_SALE_SCOPE_BY_ID_REJECTED = 'CALCULATE_SALE_SCOPE_BY_ID_REJECTED';
