import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ServiceContext } from 'contexts/service';
import { semanticPlurals } from 'helpers';
import clsx from 'clsx';
import {
  Close as CloseIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import {
  Grid,
  Typography,
  Tooltip,
  Button,
  IconButton,
  Icon,
  InputBase,
  Container,
  Paper,
} from '@material-ui/core';
import { salesSearch } from 'store/actions/sales';
import { useStyles } from '../style';

function Header({
  responseSize, filteredSize, search,
}) {
  const classes = useStyles();
  const serviceContext = useContext(ServiceContext);
  const {
    setSearchValue, searchValue, setSortItems, sortItems,
  } = serviceContext;
  const filtered = filteredSize !== responseSize;

  useEffect(() => {
    search(searchValue, false, 'confirmed_date', sortItems);
  }, [searchValue, sortItems, search]);

  const clearSearch = () => setSearchValue('');
  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={2}
        className={classes.pageHeader}
      >
        <Grid item xs={6}>
          <Typography
            variant="h5"
            color="textSecondary"
            className={classes.pageTitle}
          >
            {`Venda${semanticPlurals(filteredSize, '', 's')} (${filtered ? `${filteredSize} de ` : ''}${responseSize})`}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.btnholder}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Buscar..."
              onChange={(e) => setSearchValue(e.target.value)}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              value={searchValue}
              endAdornment={(
                <IconButton onClick={() => clearSearch()} classes={{ root: classes.closeBtnTiny }} style={{ opacity: searchValue ? 1 : 0 }} size="small">
                  <CloseIcon fontSize="small" classes={{ root: classes.closeBtnTinyIcon }} />
                </IconButton>
              )}
            />
          </div>
          <Tooltip title="Ordenar por data de entrada em serviços">
            <Button
              variant="contained"
              onClick={setSortItems}
              className={classes.buttonGroup}
              aria-label="Ascendente ou decrescente"
            >
              {'Ordenar'}
              <Icon style={{ fontSize: '1.2rem' }} className={clsx('fas', sortItems ? 'fa-sort-numeric-up' : 'fa-sort-numeric-down', classes.iconMargin)} />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      {filteredSize > 0 && (
        <Paper className={classes.header}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="body2">Cliente</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2">Contato</Typography>
            </Grid>
            <Grid item xs={5}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2">Data de entrada</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">
                    {'Documentos'}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body2">
                    {'Processos'}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Container>
  );
}

Header.propTypes = {
  responseSize: PropTypes.number.isRequired,
  filteredSize: PropTypes.number.isRequired,
  search: PropTypes.func.isRequired,
};

export default connect(null, { search: salesSearch })(Header);
