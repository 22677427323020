import React, { useContext } from 'react';
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import * as $_ from 'global/functions';
import { useStyles } from '../style';
// import { CANCELLED } from 'constants/common/payments';
// import { PAYMENT_STATUS_PAID } from 'constants/sales';
import { Loading } from 'global/sources/Icons';
import { SaleDashboardContext } from 'contexts/saleDashboard';

function Service({ item, step }) {
  const classes = useStyles();
  // const confirmedPayments = item.payments.filter(a => a.status === PAYMENT_STATUS_PAID && a.month_number === 1);
  // const listedPayments = item.payments.filter(a => a.status !== CANCELLED && a.month_number === 1);
  // const allPaymentsConfirmed = confirmedPayments.length === listedPayments.length;
  const allPaymentsConfirmed = item.payments && item.payments.length > 0;
  const { handleDialogConfirmSale } = useContext(SaleDashboardContext);

  return (
    <Grid item xs={8} md={9}>
      {step > 3 && (
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body1" color="textSecondary">Entrada em</Typography>
            <Typography variant="body1">{item.confirmed_date && $_.semanticDate(item.confirmed_date, true)}</Typography>
          </Grid>
          {step === 5
            && (
              <Grid item xs={6}>
                <Typography variant="body1" color="textSecondary">Alinhamento em</Typography>
                <Typography variant="body1">
                  {item.align_date ? $_.semanticDate(item.align_date, true) : null}
                </Typography>
              </Grid>
            )}
          {step === 4 && (
            <Grid item xs={6} className={classes.flex}>
              <Typography variant="body1" color="textSecondary">Ainda não alinhado</Typography>
            </Grid>
          )}
        </Grid>
      )}
      {step <= 3 && (
        <Grid container>
          <Grid item xs={12}>
            {allPaymentsConfirmed && step === 2
              ? (
                <Typography variant="body1" color="textSecondary">
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={() => handleDialogConfirmSale(item)}
                  >
                    <Loading style={{ marginRight: 8 }} />
                    {'Confirmar venda'}
                  </Button>
                </Typography>
              ) : (
                <Typography variant="body1" color="textSecondary">Ainda não iniciado</Typography>
              )}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default Service;