import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  Link,
//  Paper,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Call as PhoneIcon,
  Email as EmailIcon,
} from '@material-ui/icons';
import CopyElement from 'global/CopyElement';
import Comments from 'global/Comments';
import clsx from 'clsx';
import * as $_ from 'global/functions';
import { useStyles } from './style';

function DialogUser({
  open, currentCustomer, closeDialog, ...props
}) {
  const classes = useStyles();

  const handleCloseDialog = () => {
    closeDialog(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="md"
      scroll="body"
    >
      <DialogContent className={classes.dialogMoreInfo}>
        {currentCustomer && Object.keys(currentCustomer).length > 0 && (
          <>
            <Grid container spacing={2} className={classes.gridContainer}>
              <IconButton onClick={handleCloseDialog} className={classes.closeBtn}>
                <CloseIcon />
              </IconButton>
              <Grid item xs={12} md={8} className={classes.beforeMdPadding}>
                <Typography variant="h4">{currentCustomer.name}</Typography>
                <Typography variant="h6">{currentCustomer.id}</Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.fromMdPadding}>
                {currentCustomer.email && (
                  <Typography className={clsx(classes.pLine)}>
                    <EmailIcon className={classes.icon} color="disabled" fontSize="inherit" />
                    <CopyElement>{currentCustomer.email}</CopyElement>
                  </Typography>
                )}
                {currentCustomer.phone && (
                  <Typography className={clsx(classes.pLine)}>
                    <PhoneIcon className={classes.icon} color="disabled" fontSize="inherit" />
                    <CopyElement>{currentCustomer.phone}</CopyElement>
                  </Typography>
                )}
                <Typography>
                  <Link href="alinhamento" underline="always" className={classes.link}>Documentos</Link>
                  <Link href="conclusao" underline="always" className={classes.link}>Contrato</Link>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.gridContainer}>
              <Grid item>
                <Typography color="primary" gutterBottom>Data de entrada</Typography>
                <Typography variant="subtitle2">{$_.semanticDate(currentCustomer.dateInitialised, true)}</Typography>
              </Grid>
              <Grid item>
                <Typography color="primary" gutterBottom>Assento</Typography>
                <Typography variant="subtitle2">Em busca</Typography>
              </Grid>
              <Grid item>
                <Typography color="primary" gutterBottom>Tipo de processo</Typography>
                <Typography variant="subtitle2">{currentCustomer.applicants && props.products.length > 0 && props.products.find((a) => { return a.title === currentCustomer.applicants[0].hiredServices[0]; }).titleClient}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.gridContainer}>
              <Grid item xs={12}>
                <Typography variant="h5" paragraph>Cidadãos portugueses da família</Typography>
              </Grid>
              <Grid item xs={12}>
                {/* currentCustomer.registeredCitizens.map((citizen, index) => {
                  return (
                    <Paper key={`${citizen.id}${index}`} className={classes.smallPaper} square elevation={1}>
                      <Typography variant="h6">
                        {citizen.name}
                      </Typography>
                      <Typography variant="subtitle2">
                        {`Nascimento: ${citizen.birthDate}`}
                      </Typography>
                    </Paper>
                  );
                }) */}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" paragraph>Outros processos da família</Typography>
              </Grid>
              <Grid item xs={12}>
                {/* currentCustomer.applicants.map((applicant, index) => {
                  return (
                    <Paper key={`${applicant.id}${index}`} className={classes.smallPaper} square elevation={1}>
                      <Typography variant="subtitle2">
                        {applicant.id}
                      </Typography>
                      <Typography variant="h6">
                        {applicant.name}
                      </Typography>
                    </Paper>
                  );
                }) */}
              </Grid>
            </Grid>
          </>
        )}
        <>
          <Typography variant="h5" paragraph>Comunicação interna</Typography>
          <Comments
            inverted
            allowed
            master
            step={1}
            label="Escreva seu comentário"
            onPost={() => console.log('')}
          />
        </>
      </DialogContent>
    </Dialog>
  );
}

DialogUser.propTypes = {
  open: PropTypes.bool.isRequired,
  currentCustomer: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default DialogUser;
