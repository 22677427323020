export const COMMENT_ALL_PROCESS_BY_SALE = 'COMMENT_ALL_PROCESS_BY_SALE';
export const COMMENT_ALL_PROCESS_BY_SALE_FULFILLED = 'COMMENT_ALL_PROCESS_BY_SALE_FULFILLED';
export const COMMENT_ALL_PROCESS_BY_SALE_REJECTED = 'COMMENT_ALL_PROCESS_BY_SALE_REJECTED';

export const POST_COMMENT_PROCESS = 'POST_COMMENT_PROCESS';
export const POST_COMMENT_PROCESS_FULFILLED = 'POST_COMMENT_PROCESS_FULFILLED';
export const POST_COMMENT_PROCESS_REJECTED = 'POST_COMMENT_PROCESS_REJECTED';

export const ERASE_COMMENT = 'ERASE_COMMENT';
export const ERASE_COMMENT_FULFILLED = 'ERASE_COMMENT_FULFILLED';
export const ERASE_COMMENT_REJECTED = 'ERASE_COMMENT_REJECTED';
