import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { hideConfirmation } from 'store/actions/confirmations';
import { EMPTY } from 'constants/common';
import { isObject } from 'lodash';

function AlertDialog({
  message, cancelText, confirmText, title, onConfirm, open, ...props
}) {
  function handleClose() {
    props.hideConfirmation();
  }

  const handleConfirm = () => {
    if (isObject(onConfirm)) {
      onConfirm.func(onConfirm.payload);
    } else {
      onConfirm();
    }
    props.hideConfirmation();
  };

  return (
    <div>
      <Dialog
        PaperProps={{
          square: true,
        }}
        open={open}
        onClose={handleClose}
        scroll="body"
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {message !== EMPTY && message}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="secondary"
          >
            {cancelText}
          </Button>
          <Button
            onClick={() => handleConfirm()}
            variant="outlined"
            color="primary"
            autoFocus
          >
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AlertDialog.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  onConfirm: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  hideConfirmation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  title: state.confirmations.text.title,
  message: state.confirmations.text.message,
  confirmText: state.confirmations.buttons.confirm,
  cancelText: state.confirmations.buttons.cancel,
  onConfirm: state.confirmations.onConfirm,
  open: state.confirmations.open,
});

export default connect(mapStateToProps, { hideConfirmation })(AlertDialog);
