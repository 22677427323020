import React, { useEffect } from 'react';
import {
  paymentMethodTitles,
  PAYMENT_BANK_SLIP,
  PAYMENT_ITAU_BANK_SLIP,
  PAYMENT_CREDIT_CARD_RECURRENT,
} from 'constants/sales';
import { semanticDate } from 'global/functions';
import {
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import { convertCurrency, decimalCharFromEn } from 'helpers';

const PaymentRowShow = ({ row, form }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Typography variant="subtitle2" color="textSecondary"><strong>Valor</strong></Typography>
        
        {(
          row.payment_method === PAYMENT_BANK_SLIP || 
          row.payment_method === PAYMENT_ITAU_BANK_SLIP || 
          row.payment_method === PAYMENT_CREDIT_CARD_RECURRENT
          ) && (
          <Typography>
            {`${convertCurrency(row.value * row.months)}${row.months && row.months > 1 ? ` em ${row.months} vezes de ${convertCurrency(row.value)}` : ''}`}
          </Typography>
        )}

        {(
          row.payment_method !== PAYMENT_BANK_SLIP && 
          row.payment_method !== PAYMENT_ITAU_BANK_SLIP && 
          row.payment_method !== PAYMENT_CREDIT_CARD_RECURRENT
          ) && (
           <Typography>
              {Boolean(row.interest_percentage) ? (
                <div>
                  {`${convertCurrency(row.value)}${row.months && row.months > 1 ? ` em ${row.months} vezes de ${convertCurrency(row.value / row.months)}` : ''}`}
                  <Typography color="textSecondary" component="span">
                    {` (juros de ${decimalCharFromEn(row.interest_percentage)}%)`}
                  </Typography>
                </div>
            ) : (
              <div>
                {`${convertCurrency(row.value)}${row.months && row.months > 1 ? ` em ${row.months} vezes de ${convertCurrency(row.value / row.months)}` : ''}`}
              </div>
            )}
           </Typography>
        )}
      </Grid>
      <Grid item xs={3}>
        <Typography variant="subtitle2" color="textSecondary"><strong>Forma de pagamento</strong></Typography>
        <Typography>{paymentMethodTitles[row.payment_method]}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="subtitle2" color="textSecondary"><strong>{`${form.payment_method === PAYMENT_BANK_SLIP ? 'Primeira data' : 'Data'} de vencimento`}</strong></Typography>
        <Typography>{semanticDate(row.due_date)}</Typography>
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'right' }}>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
        >
          Remover
        </Button>
      </Grid>
    </Grid >
  );
}

export default PaymentRowShow;
