import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import * as definitions from 'store/definitions/processes';
import * as API from 'api';
import * as selectors from 'store/selectors/processes';
import * as notificationsDefinitions from 'store/definitions/notifications';
import { PROCESS_PATH } from 'api/paths';
import { SEND_DOCUMENT } from 'store/definitions/documents';
import { NOTIFICATION_TYPE_SUCCESS, NOTIFICATION_TYPE_ERROR } from 'constants/notification';
import { MESSAGE_SUCCESS_COMMON_UPDATE, MESSAGE_ERROR_COMMON_ERROR, MESSAGE_ERROR_SMTP } from 'constants/common/messages';

function* resultsClear() {
  yield put({ type: definitions.PROCESS_RESULTS_CLEAR_DONE });
}

function* fetchByStep(action) {
  try {
    const { step, page } = action.payload;
    const fullPath = `${PROCESS_PATH}/steps`;
    const payload = yield call(API.fetch, fullPath, { steps: step.join(','), page });
    yield put({ type: definitions.FETCH_PROCESSES_BY_STEP_FULFILLED, payload: payload.process });
  } catch (error) {
    yield put({ type: definitions.FETCH_PROCESSES_BY_STEP_REJECTED, payload: { error } });
  }
}

function* updateProcess(action) {
  try {
    const { id, data } = action.payload;
    const fullPath = `${PROCESS_PATH}`;
    const payload = yield call(API.update, fullPath, id, { ...data });
    yield put({
      type: definitions.UPDATE_PROCESS_FULFILLED,
      payload,
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: MESSAGE_SUCCESS_COMMON_UPDATE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.UPDATE_PROCESS_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: MESSAGE_ERROR_COMMON_ERROR }
    });
  }
}

function* updateProcessStep(action) {
  try {
    const { processId, stepName, notify } = action.payload;
    const pathService = `${PROCESS_PATH}/${processId}/step`;
    const payload = yield call(API.updateAction, pathService, stepName, 'update', {}, { notify });
    yield put({
      type: definitions.PROCESS_CHANGE_STEP_FULFILLED,
      payload,
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: MESSAGE_SUCCESS_COMMON_UPDATE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.PROCESS_CHANGE_STEP_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

function* blockProcess(action) {
  try {
    const { block, reason, processId } = action.payload;
    const pathService = `${PROCESS_PATH}`;
    const payload = yield call(API.updateAction, pathService, processId, block ? 'block' : 'unblock', { reason }, {});
    yield put({
      type: definitions.BLOCK_PROCESS_FULFILLED,
      payload,
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: MESSAGE_SUCCESS_COMMON_UPDATE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.BLOCK_PROCESS_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

function* updateProcessStepSendDocument(action) {
  try {
    const { processId, stepName, notify, personId, documents } = action.payload;
    const pathService = `${PROCESS_PATH}/${processId}/step`;
    const payload = yield call(API.updateAction, pathService, stepName, 'update', {}, { notify });
    if (personId) {
      yield put({
        type: SEND_DOCUMENT,
        payload: { personId, documents, noNotification: true },
      });
    }
    yield put({
      type: definitions.PROCESS_CHANGE_STEP_SEND_DOC_FULFILLED,
      payload,
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: MESSAGE_SUCCESS_COMMON_UPDATE,
      },
    });
  } catch (error) {
    let message = MESSAGE_ERROR_COMMON_ERROR;
    if (
      Object.prototype.hasOwnProperty.call(error, 'response')
      && Object.prototype.hasOwnProperty.call(error.response, 'data')
      && JSON.stringify(error.response.data).toLowerCase().includes('smtp')
    ) {
      message = MESSAGE_ERROR_SMTP;
    }
    yield put({
      type: definitions.PROCESS_CHANGE_STEP_SEND_DOC_REJECTED,
      payload: { error }
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message }
    });
  }
}

export function* processSearch(action) {
  const {
    searchValue, filter, sortBy, sortOrder,
  } = action.payload;
  const payload = yield select(
    (state) => selectors.filterProcess(state)(searchValue, filter, sortBy, sortOrder)
  );
  yield put({ type: definitions.PROCESS_SEARCH_RESULT, payload });
}

function* deleteSetting(action) {
  try {
    const { processId, key } = action.payload;
    const pathService = `${PROCESS_PATH}/${processId}/setting`;
    const payload = yield call(API.remove, pathService, key);
    yield put({
      type: definitions.DELETE_PROCESS_SETTINGS_FULFILLED,
      payload,
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: MESSAGE_SUCCESS_COMMON_UPDATE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.DELETE_PROCESS_SETTINGS_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

function* createSetting(action) {
  try {
    const { processId, key, data } = action.payload;
    const pathService = `${PROCESS_PATH}/${processId}/setting`;
    const postObject = {
      key,
      value: data,
    };
    const payload = yield call(API.create, pathService, postObject);
    yield put({
      type: definitions.CREATE_PROCESS_SETTINGS_FULFILLED,
      payload,
    });
  } catch (error) {
    yield put({
      type: definitions.CREATE_PROCESS_SETTINGS_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

function* changeSetting(action) {
  try {
    const { processId, data, key } = action.payload;
    const postObject = {
      key,
      value: data,
    };
    const pathService = `${PROCESS_PATH}`;
    const payload = yield call(API.updateAction, pathService, processId, 'setting', postObject);
    yield put({
      type: definitions.UPDATE_PROCESS_SETTINGS_FULFILLED,
      payload,
    });
  } catch (error) {
    yield put({
      type: definitions.UPDATE_PROCESS_SETTINGS_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.UPDATE_PROCESS_SETTINGS, changeSetting),
    takeLatest(definitions.CREATE_PROCESS_SETTINGS, createSetting),
    takeLatest(definitions.DELETE_PROCESS_SETTINGS, deleteSetting),
    takeLatest(definitions.FETCH_PROCESSES_BY_STEP, fetchByStep),
    takeLatest(definitions.PROCESS_SEARCH, processSearch),
    takeLatest(definitions.UPDATE_PROCESS, updateProcess),
    takeLatest(definitions.PROCESS_CHANGE_STEP, updateProcessStep),
    takeLatest(definitions.PROCESS_CHANGE_STEP_SEND_DOC, updateProcessStepSendDocument),
    takeLatest(definitions.PROCESS_RESULTS_CLEAR, resultsClear),
    takeLatest(definitions.BLOCK_PROCESS, blockProcess),
  ]);
}
