import React from 'react';
import uniqid from 'uniqid';
import {
  paymentMethodTitles,
  installmentsInterest,
  interestSalesMan,
  interest,
  getInstallmentsInterests,
  getInstallmentInterestsList,
  PAYMENT_BANK_SLIP,
  PAYMENT_ITAU_BANK_SLIP,
  PAYMENT_CREDIT_CARD,
  PAYMENT_MAXI_PAGO,
  PAYMENT_ELECTRONIC_TERMINAL,
  PAYMENT_CREDIT_CARD_RECURRENT,
  paymentInstallments,
  getInstallmentInterestsListByPlan,
  PAYMENT_BARTE,
} from 'constants/sales';
import moment from 'moment';
import DatePickerField from 'global/fields/DatePickerField';
import {
  Grid,
  TextField,
  MenuItem,
  Button,
  InputAdornment,
  Tooltip,
  IconButton,
  Typography,
  ListItemIcon,
  FormControl,
  Select,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {
  RadioButtonUnchecked,
  RadioButtonChecked,
} from '@material-ui/icons';
import CurrencyField from 'global/fields/CurrencyField';
import PaymentIcons from 'global/sources/Icons/PaymentIcons';
import { convertCurrency, stripValue, decimalCharFromEn } from 'helpers';
import { chargable } from './functions';
import { PAYMENT_METHOD_ONLINE_IUGU } from 'objects/imports';

const PaymentRowAdd = ({
  amountLeft, form, total, classes, setValueAsTotal, familyPlan, handleCents, handleChange, handleChangeMethod,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          {'Valor restante: '}
          <strong>{convertCurrency(amountLeft)}</strong>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <CurrencyField
          value={form.value}
          variant="outlined"
          fullWidth
          required
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={`Cobrar ${amountLeft === total ? 'valor total' : 'valor restante'}`}>
                  <IconButton
                    onClick={setValueAsTotal}
                  >
                    {stripValue(form.value) === amountLeft
                      ? (
                        <RadioButtonChecked />
                      ) : (
                        <RadioButtonUnchecked />
                      )}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          label="Valor"
          onBlur={handleCents}
          onChange={(e) => handleChange('value', e.target.value)}
        />
        {chargable(form.payment_method) &&
        form.interest_percentage !== 0 && (
          <Typography style={{ padding: 8 }}>
            <Typography component="span" color="textSecondary">{'+ '}</Typography>
            {`${convertCurrency(form.interest_total)}`}
            <Typography component="span" color="textSecondary">
              {` de juros (${decimalCharFromEn(form.interest_percentage)}%)`}
            </Typography>
            <Typography component="span" color="error">
              { form.chargeInterest ? ' - Adicionado para o cliente' : ' - Retirado do vendedor'}
            </Typography>

          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={form.payment_method === PAYMENT_CREDIT_CARD
          || form.payment_method === PAYMENT_MAXI_PAGO
          || form.payment_method === PAYMENT_BARTE
          || form.payment_method === PAYMENT_BANK_SLIP
          || form.payment_method === PAYMENT_CREDIT_CARD_RECURRENT
          || form.payment_method === PAYMENT_ITAU_BANK_SLIP
          || form.payment_method === PAYMENT_ELECTRONIC_TERMINAL
          ? 3
          : 4}
      >
        <FormControl
          variant="outlined"
          required
          fullWidth
        >
          <InputLabel>Forma de Pagamento</InputLabel>
          <Select
            value={form.payment_method}
            onChange={(e) => handleChangeMethod(e.target.value)}
            classes={{ selectMenu: classes.select }}
          >
            {Object.keys(paymentMethodTitles).map((key) => (
              <MenuItem
                key={uniqid()}
                value={key}
              >
                <ListItemIcon
                  style={{ minWidth: 'unset', marginRight: 16 }}
                >
                  <PaymentIcons method={key} />
                </ListItemIcon>
                <Typography variant="inherit">{paymentMethodTitles[key]}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {chargable(form.payment_method) &&
        form.interest_percentage !== 0 && (
          <FormControlLabel
            style={{ marginLeft: 6 }}
            control={
              <Checkbox checked={form.chargeInterest} onChange={(e) => handleChange('chargeInterest', e.target.checked)} />
            }
            label="Cobrar juros do cliente"
          />
        )}
      </Grid>
      {(form.payment_method === PAYMENT_CREDIT_CARD
        || form.payment_method === PAYMENT_MAXI_PAGO
        || form.payment_method === PAYMENT_BARTE
        || form.payment_method === PAYMENT_BANK_SLIP
        || form.payment_method === PAYMENT_CREDIT_CARD_RECURRENT
        || form.payment_method === PAYMENT_ITAU_BANK_SLIP
        || form.payment_method === PAYMENT_ELECTRONIC_TERMINAL)
        && (
          <Grid item xs={1}>
            <TextField
              select
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              label="Parcelas"
              value={form.months}
              onChange={(e) => handleChange('months', e.target.value)}
              SelectProps={{
                classes: {
                  root: classes.selectPadding,
                }
              }}
            >
              {getInstallmentInterestsListByPlan(familyPlan, form.payment_method).map((key, index) => {
                const strippedValue = stripValue(form.value);
                let returnval = (
                  <Typography className={classes.hiddenOverflow}>
                    {`${key > 1 ? `${key} vezes${strippedValue > 0 ? ` de ${convertCurrency(strippedValue / key)}` : ''}` : 'à vista'}`}
                  </Typography>
                );
                if (chargable(form.payment_method)) {
                  const installmentInterestObj = getInstallmentsInterests(form.chargeInterest, familyPlan);
                  const getInterest = getInstallmentInterestsList(form.chargeInterest, familyPlan);
                  const installmentInterest = installmentInterestObj[index].monthly;
                  const installmentInterestTotal = installmentInterestObj[index].total;
                  const realValue = strippedValue / 100;
                  const realInstallmentValue = realValue / key;
                  const installmentValue = realValue + ((realValue * getInterest[(key - 1)]) / 100);
                  returnval = (
                    <Typography className={classes.hiddenOverflow}>
                      {`${key}x `}
                      <Typography component="span" color="textSecondary">
                         de ${convertCurrency((installmentValue * 100) / key)}
                        {`${getInterest[(key - 1)] > 0 ? ` (juros de ${getInterest[(key - 1)].toFixed(2)}% a/m, juros total de ${installmentInterestTotal}%)` : ' sem juros'}`}
                      </Typography>
                    </Typography>
                  );
                }
                return (
                  <MenuItem key={uniqid()} value={key}>
                    {returnval}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
        )}
      <Grid item xs={4}>
        <DatePickerField
          fullWidth
          required
          InputLabelProps={{
            shrink: true,
          }}
          value={form.due_date}
          minDate={form.paymento_method === PAYMENT_METHOD_ONLINE_IUGU ? moment().add(1, 'days') : moment()}
          label="Data de vencimento"
          onChanged={(date) => handleChange('due_date', date)}
        />
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
        >
          Adicionar
        </Button>
      </Grid>
    </Grid>
  )
};

export default PaymentRowAdd;
