import { createSelector } from 'reselect';
import * as $_ from 'global/functions';
import { setSalesStep } from 'sales/functions';

const allSales = (state) => state.sales.allSales;

const basicSearchValues = ['name', 'phone', 'email'];

export const filterSales = createSelector(
  allSales,
  (salesList) => (searchValue, filter, sortBy, sortOrder) => {
    let returnValue = salesList.map(a => setSalesStep(a));
    if (!returnValue || returnValue.length === 0) return [];
    if (filter.title) returnValue = returnValue.filter(a => a.salesStep === filter.value) || [];
    if (searchValue) {
      returnValue = returnValue.filter((process) => (
        $_.matchable(
          process,
          'client_id',
          searchValue,
        ) || $_.matchable(
          process.customer,
          basicSearchValues,
          searchValue,
        )
      ));
    }
    if (sortBy) {
      switch (sortBy) {
        case 'name':
          returnValue = returnValue.sort((a, b) => {
            if (a.customer[sortBy] < b.customer[sortBy]) {
              return sortOrder ? -1 : 1;
            }
            if (a.customer[sortBy] > b.customer[sortBy]) {
              return sortOrder ? 1 : -1;
            }
            return 0;
          });
          break;
        default:
          returnValue = returnValue.sort((a, b) => {
            if (a[sortBy] < b[sortBy]) {
              return sortOrder ? -1 : 1;
            }
            if (a[sortBy] > b[sortBy]) {
              return sortOrder ? 1 : -1;
            }
            return 0;
          });
          break;
      }
    }

    return Array.from(returnValue);
  },
);
