import React from 'react';
import { Typography, Container } from '@material-ui/core';
import Contact from 'global/modules/Contact';
function SaleFormHeader({ scope }) {
  return (
    <>
      <Container maxWidth="xl">
        <Typography variant="h4" align="center" gutterBottom>
          Nova venda { scope.confirmed && ( ` - Venda já ganha`) } { scope.signed_date !== null && (` - Contrato já assinado`)}
        </Typography>
        <Contact
          item={{ client_id: scope.client_id }}
          customer={(scope && scope.customer) || {}}
          vertical
          editable
        />
      </Container>
    </>
  );
}

export default SaleFormHeader;
