export const SEARCH_STATUS_LIST = [
    {value: 'WAIT_CLIENT_DATA', name: 'Aguardando dados do cliente'},
    {value: 'SEARCH_ASSENTO', name: 'Buscando assento'},
    {value: 'ASSENTO_FOUND', name: 'Assento localizado'},
    {value: 'NEGATIVE_CONSERVATORY', name: 'Negativa da conservatória'},
    {value: 'SEARCH_RECORD', name: 'Buscando registro'},
    {value: 'RECORD_FOUND', name: 'Registro localizado'},
    {value: 'REPRODUCTION_REGISTRATION', name: 'Registro em reprodução'},
    {value: 'RECORD_PAID', name: 'Registro pago'},
    {value: 'RECORD_WITH_JORDANA', name: 'Registro na jordana'},
    {value: 'RECORD_ON_BOTAFOGO', name: 'Registro em botafogo'},
    {value: 'TO_SEARCH', name: 'A buscar'},
    {value: 'NOT_FOUND', name: 'Não encontrado'},
    {value: 'CJ_PORTUGUESE', name: 'Português pelo CJ'},
    {value: 'APPROVED_IN_PORTO', name: 'Aprovado em Porto'},
    {value: 'DONE', name: 'Concluído'},
];

// 'WAIT_CLIENT_DATA','SEARCH_ASSENTO','ASSENTO_FOUND','NEGATIVE_CONSERVATORY','SEARCH_RECORD','RECORD_FOUND','REPRODUCTION_REGISTRATION','RECORD_PAID','RECORD_WITH_JORDANA','RECORD_ON_BOTAFOGO','TO_SEARCH','NOT_FOUND','CJ_PORTUGUESE','APPROVED_IN_PORTO','DONE'