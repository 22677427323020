import React, { useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Typography,
  InputBase,
  IconButton,
  ButtonGroup,
  Button,
  Icon,
  Grid,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  Share,
  Close as CloseIcon,
  FilterList as FilterIcon,
  CheckBox as CheckboxIcon,
  CheckBoxOutlineBlank as CheckboxBlankIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import { salesSearch, removeSales } from 'store/actions/sales';
import { useStyles } from '../style';
import { filterItems } from 'constants/sales';
import { SaleDashboardContext } from 'contexts/saleDashboard';
import { Delete } from '@material-ui/icons';
import { showConfirmation } from 'store/actions/confirmations';

function TabHeader({
  title, tabKey, disableSortMenu, remove, defaultSort, itemsSort, confirmation, masterUser, ...props
}) {
  const classes = useStyles();
  const { multiSelect, handleDialogShareSale } = React.useContext(SaleDashboardContext);
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState(defaultSort || 'name');
  const [sortList, setSortList] = useState(true);
  const [filterMenu, setFilterMenu] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [sortMenu, setSortMenu] = useState(null);
  const selecting = multiSelect.length > 0;

  const handleSort = () => {
    const newSortList = !sortList;
    setSortList(newSortList);
    props.salesSearch(searchValue, filter, sort, newSortList);
  };

  const popMenu = (e) => (e ? e.currentTarget : null);

  const handleSortMenu = (e) => setSortMenu(popMenu(e));

  const handleFilterMenu = (e) => setFilterMenu(popMenu(e));

  const handleCheckSort = (item) => {
    const newSort = item.value;
    setSort(newSort);
    handleSortMenu(false);
    props.salesSearch(searchValue, filter, newSort, sortList);
  };

  const handleCheckFilter = (subItem) => {
    const newFilter = _.isEqual(subItem, filter) || !subItem ? {} : subItem;
    setFilter(newFilter);
    props.salesSearch(searchValue, newFilter, sort, sortList);
  };

  const handleSearchValue = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    props.salesSearch(value, filter, sort, sortList);
  };

  const clearSearch = () => {
    setSearchValue('');
    props.salesSearch('', filter, sort, sortList);
  };

  const checked = (item) => _.isEqual(item, filter);
  const handleDeleteSales = () => {
    const confirmed = () => remove(multiSelect);
    confirmation(
      {
        title: `Remover venda${multiSelect.length > 1 ? 's' : ''}?`,
        message: <Typography variant="inherit" color="error">{'Observação: Esta ação não poderá ser desfeita!'}</Typography>,
      },
      {
        confirm: 'Remover',
        cancel: 'Cancelar',
      },
      {
        func: confirmed,
      },
    );
  };

  return (
    <Grid
      container
      spacing={2}
      className={classes.pageHeader}
      style={{ padding: 20 }}
    >
      <Menu
        id="sort-menu"
        anchorEl={sortMenu}
        keepMounted
        open={Boolean(sortMenu)}
        onClose={() => handleSortMenu(false)}
      >
        <MenuItem disabled>Ordenar</MenuItem>
        {itemsSort.map((item) => (
          <MenuItem
            key={`SortItem-${item.title}`}
            onClick={() => handleCheckSort(item)}
            dense
          >
            <ListItemIcon className={classes.menuItemIcon}>
              {(item.value === sort) ? <CheckboxIcon /> : <CheckboxBlankIcon />}
            </ListItemIcon>
            <ListItemText primary={item.title} />
          </MenuItem>
        ))}
      </Menu>
      <Menu
        id="filter-menu"
        anchorEl={filterMenu}
        keepMounted
        open={Boolean(filterMenu)}
        onClose={() => handleFilterMenu(false)}
      >
        <MenuItem
          dense
          key="clearAll"
          onClick={() => handleCheckFilter(false)}
          className={classes.menuItem}
        >
          <ListItemText primary="Limpar" />
        </MenuItem>
        {filterItems.map((item) => (
          <div key={`Header-${item.title}`}>
            <MenuItem disabled>{item.title}</MenuItem>
            {item.items.map((subItem) => (
              <MenuItem
                dense
                key={`SubItem-${subItem.title}`}
                onClick={() => handleCheckFilter(subItem)}
                className={classes.menuItem}
              >
                <ListItemIcon className={classes.menuItemIcon}>
                  {checked(subItem) ? <CheckboxIcon /> : <CheckboxBlankIcon />}
                </ListItemIcon>
                <ListItemText primary={subItem.title} />
              </MenuItem>
            ))}
          </div>
        ))}
      </Menu>
      <Grid item xs={4}>
        <Typography
          variant="h5"
          color="textSecondary"
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={8} className={classes.btnholder}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Buscar..."
            onChange={(e) => handleSearchValue(e)}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            value={searchValue}
            endAdornment={(
              <IconButton
                onClick={(e) => clearSearch(e)}
                className={classes.inputCloseBtn}
                style={{ opacity: searchValue ? 1 : 0 }}
                size="small"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          />
        </div>
        <ButtonGroup>
          <Tooltip
            title={disableSortMenu
              ? ''
              : 'Ordenar lista'}
          >
            <Button
              variant="contained"
              onClick={(e) => handleSortMenu(e)}
              classes={{
                root: classes.button,
                disabled: classes.visibleDisabledButton,
              }}
              aria-owns={sortList ? 'sortMenu' : undefined}
              aria-haspopup="true"
              disabled={disableSortMenu}
            >
              {disableSortMenu ? 'Ordenar' : itemsSort.find((a) => a.value === sort).title}
            </Button>
          </Tooltip>
          <Tooltip
            title={`Ordenar lista por ordem ${!sortList ? 'ascendente' : 'decrescente'}`}
          >
            <Button
              variant="contained"
              onClick={() => handleSort()}
              className={classes.buttonGroup}
              aria-label="Ascendente ou decrescente"
            >
              <Icon
                fontSize="small"
                className={`fas ${sortList ? 'fa-sort-amount-up' : 'fa-sort-amount-down'}`}
              />
            </Button>
          </Tooltip>
        </ButtonGroup>
        {tabKey === 1
          && (
            <Tooltip
              title="Filtrar lista"
            >
              <Button
                variant="contained"
                onClick={(e) => handleFilterMenu(e)}
                className={classes.button}
                aria-owns={filter ? 'filterMenu' : undefined}
                aria-haspopup="true"
              >
                {'Filtrar'}
                <FilterIcon fontSize="small" className={classes.btnIcon} />
              </Button>
            </Tooltip>
          )}
      </Grid>
      <Grid item xs={12} className={clsx(classes.transition, selecting ? 'in' : 'out')}>
        <Grid container>
          <Grid item xs={12}>
            <Typography align="right">
              <Button
                variant="contained"
                color="secondary"
                className={classes.deleteButton}
                disabled={!selecting}
                onClick={handleDeleteSales}
                endIcon={<Delete />}
              >
                {`Remover venda${multiSelect.length > 1 ? 's' : ''}`}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!selecting}
                onClick={() => handleDialogShareSale(multiSelect)}
                endIcon={<Share />}
              >
                {`Compartilhar venda${multiSelect.length > 1 ? 's' : ''}`}
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

TabHeader.propTypes = {
  itemsSort: PropTypes.arrayOf(PropTypes.object),
};

TabHeader.defaultProps = {
  itemsSort: [
    {
      title: 'Nome',
      value: 'name',
    },
    {
      title: 'Etapa',
      value: 'salesStep',
    },
  ],
};

export default connect(null, { salesSearch, remove: removeSales, confirmation: showConfirmation })(TabHeader);
