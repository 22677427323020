import { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { ServiceContext } from 'contexts/service';
import { FULFILLED } from 'constants/session';

function UpdateCurrents({ allProcesses, allSales, scopeStatus, modularStatus, status, update }) {
  const serviceContext = useContext(ServiceContext);
  const { currentClient, currentInfoObj, setCurrentClient, setCurrentInfoObj } = serviceContext;

  useEffect(() => {
    if (Object.keys(currentClient).length > 0 && currentClient.id && status === FULFILLED) {
      setCurrentClient(allProcesses.find((a) => a.id === currentClient.id) || currentClient);
    }
    if (Object.keys(currentInfoObj).length > 0 && currentInfoObj.id && status === FULFILLED) {
      setCurrentInfoObj(allProcesses.find((a) => a.id === currentInfoObj.id) || currentInfoObj);
    }
    // eslint-disable-next-line
  }, [allProcesses, update]);

  useEffect(() => {
    if (Object.keys(currentClient).length > 0 && currentClient.id && scopeStatus === FULFILLED) {
      setCurrentClient(allSales.find((a) => a.id === currentClient.id) || currentClient);
    }
    if (Object.keys(currentInfoObj).length > 0
      && currentInfoObj.id
      && (scopeStatus === FULFILLED || modularStatus === FULFILLED)) {
      setCurrentInfoObj(allSales.find((a) => a.id === currentInfoObj.id) || currentInfoObj);
    }
    // eslint-disable-next-line
  }, [allSales, update]);

  return null;
}

const mapStateToProps = (state) => ({
  allSales: state.sales.allSales,
  scopeStatus: state.sales.scopeStatus,
  modularStatus: state.sales.modularStatus,
  allProcesses: state.processes.allProcesses,
  status: state.processes.status,
});

export default connect(mapStateToProps)(UpdateCurrents);
