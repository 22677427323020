/* eslint-disable camelcase */
import {
  put, takeLatest, all, call, select,
} from 'redux-saga/effects';
import * as definitions from 'store/definitions/batchpost';
import * as API from 'api';
import { BATCHPOST_PATH, BATCHPOSTS_PATH } from 'api/paths';
import * as notificationsDefinitions from 'store/definitions/notifications';
import { NEW_BATCHPOST, DELIVERED_BATCHPOST } from 'constants/batchpost';
import moment from 'moment';
import { SERVER_TIMEZONE } from 'constants/session';
import { NOTIFICATION_TYPE_SUCCESS, NOTIFICATION_TYPE_ERROR } from 'constants/notification';
import { MESSAGE_SUCCESS_COMMON_UPDATE, MESSAGE_SUCCESS_COMMON_CREATE, MESSAGE_ERROR_COMMON_ERROR } from 'constants/common/messages';
import { returnValidDate } from 'helpers';
import { filterBatchPost } from 'store/selectors/batchpost';

export function* createBatchPost(action) {
  try {
    const { data } = action.payload;
    const payload = yield call(API.create, BATCHPOST_PATH, data);
    yield put({
      type: definitions.CREATE_BATCH_POST_FULFILLED,
      payload,
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: MESSAGE_SUCCESS_COMMON_CREATE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.CREATE_BATCH_POST_REJECTED,
      error: { error },
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_ERROR,
        message: MESSAGE_ERROR_COMMON_ERROR,
      },
    });
  }
}

function* resultsClear() {
  yield put({ type: definitions.CLEAR_RESPONSES_FULFILLED });
}

export function* updateBatchPost(action) {
  try {
    const { id, data } = action.payload;
    const payload = yield call(API.update, BATCHPOST_PATH, id, data);
    yield put({
      type: definitions.UPDATE_BATCH_POST_FULFILLED,
      payload,
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: MESSAGE_SUCCESS_COMMON_UPDATE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.UPDATE_BATCH_POST_REJECTED,
      error: { error },
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_ERROR,
        message: MESSAGE_ERROR_COMMON_ERROR,
      },
    });
  }
}

export function* removeBatchPost(action) {
  try {
    const { id } = action.payload;
    const payload = yield call(API.remove, BATCHPOST_PATH, id);
    yield put({
      type: definitions.REMOVE_BATCH_POST_FULFILLED,
      payload,
    });
  } catch (error) {
    yield put({
      type: definitions.REMOVE_BATCH_POST_REJECTED,
      error: { error },
    });
  }
}

export function* lotSearch(action) {
  const {
    searchValue, filter, sortBy, sortOrder,
  } = action.payload;
  const payload = yield select(
    (state) => filterBatchPost(state)(searchValue, filter, sortBy, sortOrder)
  );
  yield put({ type: definitions.BATCHPOST_SEARCH_RESULT, payload });
}

export function* fetchOpenBatchPost() {
  try {
    const payload = yield call(API.fetch, BATCHPOSTS_PATH, { status: NEW_BATCHPOST });
    yield put({
      type: definitions.FETCH_OPEN_BATCHPOSTS_FULFILLED,
      payload: payload.batch_posts,
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_OPEN_BATCHPOSTS_REJECTED,
      error: { error },
    });
  }
}

export function* fetchDeliveredBatchPost() {
  try {
    const payload = yield call(API.fetch, BATCHPOSTS_PATH, { status: DELIVERED_BATCHPOST });
    yield put({
      type: definitions.FETCH_DELIVERED_BATCHPOSTS_FULFILLED,
      payload: payload.batch_posts,
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_DELIVERED_BATCHPOSTS_REJECTED,
      error: { error },
    });
  }
}

export function* fetchBatchPost(action) {
  try {
    const { status } = action.payload;
    const payload = yield call(API.fetch, BATCHPOSTS_PATH, { status: status.join(',') });
    yield put({
      type: definitions.FETCH_BATCHPOSTS_FULFILLED,
      payload: payload.batch_posts,
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_BATCHPOSTS_REJECTED,
      error: { error },
    });
  }
}

export function* updateBatchPostStatus(action) {
  try {
    const { id, status, data } = action.payload;
    const date = returnValidDate(moment().tz(SERVER_TIMEZONE));
    const dateName = status === 'deliver' ? 'delivered_date' : 'sent_date';
    const sendData = { ...data, [dateName]: date };
    const payload = yield call(API.updateAction, BATCHPOST_PATH, id, status, sendData);
    yield put({
      type: definitions.UPDATE_BATCH_POST_STATUS_FULFILLED,
      payload,
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: MESSAGE_SUCCESS_COMMON_UPDATE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.UPDATE_BATCH_POST_STATUS_REJECTED,
      error: { error },
    });
    yield put({
      type: notificationsDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_ERROR,
        message: MESSAGE_ERROR_COMMON_ERROR,
      },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.CREATE_BATCH_POST, createBatchPost),
    takeLatest(definitions.REMOVE_BATCH_POST, removeBatchPost),
    takeLatest(definitions.UPDATE_BATCH_POST, updateBatchPost),
    takeLatest(definitions.UPDATE_BATCH_POST_STATUS, updateBatchPostStatus),
    takeLatest(definitions.FETCH_OPEN_BATCHPOSTS, fetchOpenBatchPost),
    takeLatest(definitions.FETCH_BATCHPOSTS, fetchBatchPost),
    takeLatest(definitions.CLEAR_RESPONSES, resultsClear),
  ]);
}
