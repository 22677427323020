import {
  put, takeLatest, all, call,
} from 'redux-saga/effects';
import { SEARCH_PATH } from 'api/paths';
import * as definitions from 'store/definitions/search';
import * as API from 'api';

export function* searchCustomers(action) {
  try {
    const { param } = action.payload;
    const filter = { s: param };
    const fullPath = `${SEARCH_PATH}`;
    const payload = yield call(API.fetch, fullPath, filter);
    yield put({
      type: definitions.SEARCH_CUSTOMERS_BY_PARAMS_FULFILLED,
      payload,
    });
  } catch (error) {
    yield put({
      type: definitions.SEARCH_CUSTOMERS_BY_PARAMS_REJECTED,
      payload: { error },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.SEARCH_CUSTOMERS_BY_PARAMS, searchCustomers),
  ]);
}
