import React from 'react';
import {
  Grid, Paper, Typography, Button,
} from '@material-ui/core';

function Progress({ step, handleStep }) {
  return (
    <Paper elevation={2} style={{ position: 'fixed', bottom: 0, left: 0, padding: '16px 24px', width: '100%', }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography align="left">
            {step && (
              <Button
                color="primary"
                variant="outlined"
                onClick={handleStep}
              >
                {'Voltar'}
              </Button>
            )}
          </Typography>

        </Grid>
        <Grid item xs={6}>
          <Typography align="right">
            {!step && (
              <Button
                color="primary"
                variant="outlined"
                onClick={handleStep}
              >
                {'Prosseguir'}
              </Button>
            )}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Progress;
