import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Grid,
  Paper,
  InputAdornment,
  IconButton,
  Button,
  TextField,
  makeStyles,
  FormControlLabel,
  Checkbox,
//  Link,
  Typography,
} from '@material-ui/core';
import { login } from 'store/actions/session';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { CircleLogo } from 'global/sources/Icons';
import { LOGIN_ERROR, LOGGED } from 'constants/session';
import Notifications from 'global/notifications';
import Root from './modules/Root';
import * as $_ from './functions';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
  },
  helpBtns: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    alignItems: 'center',
    display: 'flex',
  },
  helpBtn: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    width: '100%',
  },
  logoImg: {
    width: 95,
    height: 'auto',
    display: 'block',
    margin: '24px auto',
  },
  paperContainer: {
    padding: theme.spacing(3, 4),
    position: 'relative',
    minHeight: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
}));

function Login(props) {
  const classes = useStyles();

  const [form, setForm] = useState({ login: '', password: '' });
  const [error, setError] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState('true');
  const { previous } = props.match.params;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    if (value.length > 3 && value !== '' && error.indexOf(name) >= 0) setError([error.filter((a) => a !== name)]);
  };

  const handleRemember = () => setRemember((o) => o === 'true' ? 'new-password' : 'true');

  const handleSend = (e) => {
    e.preventDefault();
    let err = [];
    // eslint-disable-next-line
    for (const field in form) {
      if (form[field] === '' || form[field].length <= 3) {
        err = [...err, field];
      }
    }
    setError([...err]);
    if (err.length === 0) {
      props.login(form.login, form.password, previous);
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Root centered>
      <Container maxWidth="sm">
        <CircleLogo className={classes.logoImg} />
        <Paper elevation={6} className={classes.paperContainer}>
          <form onSubmit={handleSend}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  aria-describedby="register-login"
                  error={error.indexOf('login') >= 0 || props.logginError}
                  fullWidth
                  id="register-login"
                  label="E-mail"
                  name="login"
                  onChange={(e) => handleChange(e)}
                  onKeyDown={(e) => { if (e.key === 'Enter') $_.focusThis('register-password'); }}
                  value={form.login}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  aria-describedby="register-password"
                  error={error.indexOf('password') >= 0 || props.logginError}
                  fullWidth
                  id="register-password"
                  label="Senha"
                  name="password"
                  onChange={(e) => handleChange(e)}
                  onKeyDown={(e) => { if (e.key === 'Enter') { handleSend(e); } }}
                  type={showPassword ? 'text' : 'password'}
                  value={form.password}
                  variant="outlined"
                  autoComplete={remember}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Ver senha"
                          onClick={() => handleShowPassword()}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} className={classes.helpBtns}>
                <FormControlLabel
                  control={
                    <Checkbox checked={remember === 'true'} size="small" color={remember === 'true' ? 'primary' : 'secondary'} onChange={handleRemember} value={'true'} />
                  }
                  label={<Typography variant="body2">Lembrar de mim</Typography>}
                />
              </Grid>
              {/*}
              <Grid item xs={6} className={classes.helpBtns}>
                <Link
                  component="button"
                  variant="body2"
                  align="right"
                  className={classes.helpBtn}
                >
                  Esqueci minha senha
                </Link>
              </Grid>
                {*/}
              <Grid item xs={12}>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  type="submit"
                  className={classes.button}
                  onClick={(e) => handleSend(e)}
                >
                  Entrar
              </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
        <Notifications />
      </Container>
    </Root>
  );
}

function mapStateToProps(state) {
  return {
    logginError: state.session.status === LOGIN_ERROR,
    logged: state.session.status === LOGGED,
  };
}

export default connect(mapStateToProps, { login })(Login);
