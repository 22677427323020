import * as definitions from 'store/definitions/search';
import {
  DEFAULT,
  REQUESTING,
  REJECTED,
  FULFILLED,
} from 'constants/common';

function Search(state = {
  results: [],
  status: DEFAULT,
}, action) {
  switch (action.type) {
    case definitions.SEARCH_CUSTOMERS_BY_PARAMS_FULFILLED:
      return {
        ...state,
        results: action.payload,
        status: FULFILLED,
      };
    case definitions.SEARCH_CUSTOMERS_BY_PARAMS_REJECTED:
      return {
        ...state,
        status: REJECTED,
      };
    case definitions.SEARCH_CUSTOMERS_BY_PARAMS:
      return {
        ...state,
        status: REQUESTING,
        results: [],
      };
    default:
      return state;
  }
}

export default Search;
