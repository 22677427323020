import React, { useState, useEffect, useContext } from 'react';
import uniqid from 'uniqid';
import { connect } from 'react-redux';
import {
  Container,
  Paper,
  Typography,
  Grid,
} from '@material-ui/core';
import { fetchClientSales } from 'store/actions/sales';
import { FULFILLED, DEFAULT, REQUESTING } from 'constants/common';
import { UserContext } from 'contexts/user';
import Root from 'global/modules/Root';
import { CircleLogo, Logo } from 'global/sources/Icons';
import { semanticPlurals } from 'helpers';
import ListRow from './ListRow';

function Contracts({
  match, params = match.params, sales, ...props
}) {
  const [requesting, setRequesting] = useState({
    requesting: true,
    error: false,
    noneOpen: false,
  });
  const { user } = useContext(UserContext);
  const { push } = props.history;
  const { id } = params;

  useEffect(() => {
    props.fetchClientSales(user.id);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (sales.status !== DEFAULT && sales.status !== REQUESTING) {
      setRequesting({ ...requesting, requesting: false });
    }
    if (sales.status === FULFILLED) {
      if (sales.collection && sales.collection.length > 0) {
        const openContracts = sales.collection.filter((a) => !(a.signed_date) && a.contract_path);
        if (openContracts && openContracts.length > 0) {
          push(`/contrato/${openContracts[openContracts.length - 1].id}`);
        } else {
          setRequesting({ ...requesting, error: false });
        }
      } else {
        setRequesting({ ...requesting, error: true });
      }
    }
    // eslint-disable-next-line
  }, [sales]);

  if (requesting.error) {
    return (
      <Root centered>
        <Container maxWidth="sm">
          <CircleLogo
            style={{
              margin: '0 auto',
              display: 'block',
              width: 110,
              maxWidth: '80%',
              height: 'auto',
              marginBottom: 40,
            }}
          />
          <Paper elevation={3}>
            <Typography variant="subtitle2" align="center">
              {'Não foram encontrados contratos em aberto'}
            </Typography>
          </Paper>
        </Container>
      </Root>
    );
  }

  const openContracts = sales.collection && sales.collection.filter(a => !a.signed_date);
  const signedContracts = sales.collection && sales.collection.filter(a => a.signed_date);

  if (sales.collection && sales.collection.length > 0) return (
    <Root centered>
      <Container maxWidth="sm">
        <Logo
          style={{
            margin: '0 auto',
            display: 'block',
            width: '80%',
            height: 'auto',
            marginBottom: 40,
          }}
        />
        {openContracts && openContracts.length > 0 && (
          <>
            <Typography variant="h5" paragraph>
              {`Contrato${semanticPlurals(openContracts, '', 's')} em aberto`}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {openContracts.map((contract, index) => (
                  <ListRow key={uniqid()} contract={contract} index={index} push={push} />
                ))}
              </Grid>
            </Grid>
          </>
        )}
        {signedContracts && signedContracts.length > 0 && (
          <>
            <Typography variant="h5" paragraph>
              {`Contrato${semanticPlurals(signedContracts, '', 's')} assinado${semanticPlurals(signedContracts, '', 's')}`}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {signedContracts.map((contract, index) => (
                  <ListRow key={uniqid()} contract={contract} index={index} push={push} />
                ))}
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </Root>
  );

  return null;
}

const mapStateToProps = (state) => ({
  sales: state.sales,
});

export default connect(mapStateToProps, { fetchClientSales })(Contracts);
