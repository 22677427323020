import React from 'react';
import { PROCESS_FORM_MODE_NEW } from 'constants/sales';
import { Grid, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  saveBtn: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  btn: {
    marginTop: theme.spacing(1),
  },
}));

function FooterForm({
  mode, onUpdate, onDelete,
}) {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <Button
          className={classes.saveBtn}
          variant="contained"
          color="secondary"
          onClick={e => { if (mode !== PROCESS_FORM_MODE_NEW) onDelete(e); }}
          type={mode === PROCESS_FORM_MODE_NEW ? 'reset' : 'button'}
        >
          {mode === PROCESS_FORM_MODE_NEW ? 'Limpar' : 'Remover processo'}
        </Button>
        <Button
          className={classes.btn}
          variant="contained"
          color="primary"
          onClick={e => { if (mode !== PROCESS_FORM_MODE_NEW) onUpdate(e); }}
          type={mode === PROCESS_FORM_MODE_NEW ? 'submit' : 'button'}
        >
          {mode === PROCESS_FORM_MODE_NEW ? 'Adicionar processo' : 'Editar processo'}
        </Button>
      </Grid>
    </Grid>
  );
}

export default FooterForm;
