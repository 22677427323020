// @ts-nocheck
import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  grow: {
    display: 'flex',
    flexGrow: 1,
  },
  userName: {
    padding: theme.spacing(0, 2),
    textTransform: 'none',
  },
  appbarRoot: {
    backgroundColor: theme.palette.background.paper,
  },
  divider: {
    backgroundColor: fade(theme.palette.text.secondary, 0.3),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  listIcon: {
    minWidth: 'unset',
    paddingRight: theme.spacing(1),
  },
  toolbarRoot: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    minHeight: theme.spacing(8),
  },
  headerButton: {
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.05),
    },
  },
  translateCenter: {
    [theme.breakpoints.up('sm')]: {
      left: '50% !important',
      position: 'absolute !important',
      WebkitTransform: 'translateX(-50%) !important',
      transform: 'translateX(-50%) !important',
    },
  },
  smallLogo: {
    width: '55px',
    display: 'block',
    minHeight: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  logo: {
    width: '140px',
    display: 'none',
    minHeight: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.black,
    transition: theme.transitions.create('box-shadow'),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      flexGrow: 0,
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(0.5),
    transition: theme.transitions.create(['width', 'min-width']),
    width: '100%',
    minWidth: 0,
    height: 28,
    lineHeight: 28,
    boxShadow: theme.shadows[0],
    borderRadius: theme.spacing(0.5),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: fade(theme.palette.common.black, 0.2),
    '&:hover, &:focus': {
      boxShadow: theme.shadows[1],
      borderColor: fade(theme.palette.common.black, 0.3),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 1, 1, 7),
      width: 150,
      '&:focus': {
        width: 'calc(100vw - 900px)',
        minWidth: 250,
      },
    },
  },
  menuPaper: {
    marginTop: theme.spacing(5),
    maxHeight: `calc(100% -c${theme.spacing(5)}px)`,
  },
  displayMobile: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  btnDrawer: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  userMenuIcon: {
    width: '1.3em',
    height: '1.3em',
  },
  menuLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline'
    },
  },
  headerContainer: {
    margin: 0,
    padding: 0,
    maxWidth: '100vw',
    [theme.breakpoints.up('sm')]: {
      margin: '0 auto',
      padding: theme.spacing(0, 2),
    },
  },
  userBtn: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
    '& .userName': {
      padding: theme.spacing(0, 1),
    },
    '&:hover .userName, &:hover .downIcon': {
      color: theme.palette.primary.main,
    },
    '& .userName, & .downIcon': {
      color: theme.palette.text.secondary,
    },
  },
}));