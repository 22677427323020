export const menuItems = [
  'Alinhamento',
  'Documentação',
  'Docs OK',
  'Apostilamento',
  'Análise',
  'Envio',
];

export const devMenuItems = [
  'Acompanhamento',
  'Conclusão',
];

export const PROCESS = 'PROCESS';
export const SCOPE = 'SCOPE';
