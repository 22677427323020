import * as definitions from 'store/definitions/comments';
import { DEFAULT, REJECTED, REQUESTING, FULFILLED, EMPTY } from 'constants/common';

function Comments(state = {
  status: DEFAULT,
  lastAction: EMPTY,
}, action) {
  switch (action.type) {
    case definitions.COMMENT_ALL_PROCESS_BY_SALE:
    case definitions.POST_COMMENT_PROCESS:
    case definitions.ERASE_COMMENT:
      return {
        ...state,
        status: REQUESTING,
        lastAction: action.type,
      };
    case definitions.COMMENT_ALL_PROCESS_BY_SALE_FULFILLED:
    case definitions.POST_COMMENT_PROCESS_FULFILLED:
    case definitions.ERASE_COMMENT_FULFILLED:
      return {
        ...state,
        status: FULFILLED,
      };
    case definitions.COMMENT_ALL_PROCESS_BY_SALE_REJECTED:
    case definitions.POST_COMMENT_PROCESS_REJECTED:
    case definitions.ERASE_COMMENT_REJECTED:
      return {
        ...state,
        status: REJECTED,
      };
    default:
      return {
        ...state,
      };
  }
}

export default Comments;
