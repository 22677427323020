import { DEFAULT, FULFILLED } from 'constants/common';
import * as definitions from 'store/definitions/people';

function People(state = {
    status: DEFAULT,
    people: {
        data:[],
        total: 0,
        per_page: 20
      }
  }, action){
    switch (action.type) {
        case definitions.FETCH_PORTUGUESES_FULFILLED:
            return {
                ...state,
                status: FULFILLED,
                people: action.payload
            }
        default:
            return {
                ...state,
            }
    }
}

export default People;