import * as definitions from 'store/definitions/sales';

export function removeSales(scopes = []) {
  return {
    type: definitions.DELETE_SCOPE,
    payload: { scopes },
  };
}

export function fetchAllOpenScopes(id) {
  return {
    type: definitions.FETCH_ALL_OPEN_SCOPES,
    payload: { id },
  };
}

export const fetchAllLeads = (cancelToken, page = '1', sortBy = 'id', sortOrder = 'desc', q = '') => ({
  type: definitions.FETCH_ALL_LEADS,
  payload: { cancelToken, page, sortBy, sortOrder, q, add: false },
});

export const fetchMoreLeads = (cancelToken, page = '1', sortBy = 'id', sortOrder = 'desc', q = '') => ({
  type: definitions.FETCH_ALL_LEADS,
  payload: { cancelToken, page, sortBy, sortOrder, q, add: true },
});

export const fetchAllAccompaniment = (cancelToken, page = '1', sortBy = 'id', sortOrder = 'desc', q = '') => ({
  type: definitions.FETCH_ALL_ACCOMPANIMENT,
  payload: { cancelToken, page, sortBy, sortOrder, q, add: false },
});

export const fetchMoreAccompaniment = (cancelToken, page = '1', sortBy = 'id', sortOrder = 'desc', q = '') => ({
  type: definitions.FETCH_ALL_ACCOMPANIMENT,
  payload: { cancelToken, page, sortBy, sortOrder, q, add: true },
});

export const fetchAllHistory = (cancelToken, page = '1', sortBy = 'id', sortOrder = 'desc', q = '') => ({
  type: definitions.FETCH_ALL_HISTORIC,
  payload: { cancelToken, page, sortBy, sortOrder, q, add: false },
});

export const fetchMoreHistory = (cancelToken, page = '1', sortBy = 'id', sortOrder = 'desc', q = '') => ({
  type: definitions.FETCH_ALL_HISTORIC,
  payload: { cancelToken, page, sortBy, sortOrder, q, add: true },
});

export function fetchLeadsBySalesman(cancelToken, page = 1) {
  return {
    type: definitions.FETCH_LEADS_BY_SALESMAN,
    payload: { cancelToken, page },
  };
}

export function fetchAccompanimentBySalesman(cancelToken, page = 1) {
  return {
    type: definitions.FETCH_ACCOMPANIMENT_BY_SALESMAN,
    payload: { cancelToken, page },
  };
}

export function fetchHistoricBySalesman(cancelToken, page = 1) {
  return {
    type: definitions.FETCH_HISTORIC_BY_SALESMAN,
    payload: { cancelToken, page },
  };
}

export function resultsClear() {
  return {
    type: definitions.RESULTS_CLEAR,
  };
}

export function salesSearch(searchValue, filter, sortBy, sortOrder) {
  return {
    type: definitions.SALES_SEARCH,
    payload: {
      filter, sortBy, sortOrder, searchValue,
    },
  };
}

export function fetchSaleScopeByClientd(scopeId) {
  return {
    type: definitions.FETCH_SALE_SCOPE_BY_SCOPE_ID,
    payload: { scopeId },
  };
}

export function updateSaleScopeById(scopeId, clientId, data) {
  return {
    type: definitions.UPDATE_SALE_SCOPE_BY_ID,
    payload: { clientId, scopeId, data },
  };
}

export function calculateSale(scopeId, withoutDiscounts) {
  return {
    type: definitions.CALCULATE_SALE_SCOPE_BY_ID,
    payload: { scopeId, withoutDiscounts },
  };
}

export function confirmScope(id, type, data) {
  return {
    type: definitions.CONFIRM_SCOPE,
    payload: { id, type, data },
  };
}

export function createPerson(payload) {
  const { clientId, scopeId, data } = payload;
  return {
    type: definitions.CREATE_PERSON,
    payload: { clientId, scopeId, data },
  };
}

export function updatePerson(payload) {
  const { personId, data, clientId, scopeId } = payload;
  return {
    type: definitions.UPDATE_PERSON,
    payload: { personId, data, clientId, scopeId },
  };
}

export function removePerson(payload) {
  const { personId, scopeId } = payload;
  return {
    type: definitions.REMOVE_PERSON,
    payload: { personId, scopeId },
  };
}

export function createProcess(payload) {
  const { data, clientId, scopeId } = payload;
  return {
    type: definitions.CREATE_PROCESS,
    payload: { data, clientId, scopeId },
  };
}

export function updateProcess(payload) {
  const { processId, data, clientId, scopeId } = payload;
  return {
    type: definitions.UPDATE_PROCESS,
    payload: { processId, data, clientId, scopeId },
  };
}

export function removeDocument(payload) {
  const { docId, clientId } = payload;
  return {
    type: definitions.REMOVE_DOCUMENT_FROM_PERSON,
    payload: { clientId, docId },
  };
}

export function removeProcess(payload) {
  const { processId, clientId, scopeId } = payload;
  return {
    type: definitions.REMOVE_PROCESS,
    payload: { processId, clientId, scopeId },
  };
}

export function updateSignedContact(contractId, data) {
  return {
    type: definitions.UPDATE_SIGNED_CONTRACT,
    payload: { contractId, data },
  };
}
export function fecthToAlign(cancelToken, page = 1) {
  return {
    type: definitions.FETCH_TO_ALIGN,
    payload: { cancelToken, page }
  };
}

export function fetchClientSales(id) {
  return {
    type: definitions.FETCH_SALES_BY_CLIENT,
    payload: { id },
  };
}

export function fetchModularClientSales(id) {
  return {
    type: definitions.FETCH_MODULAR_SALES_BY_CLIENT,
    payload: { id },
  };
}

export function confirmAligmentMultiple(scopeId, last) {
  return {
    type: definitions.CONFIRM_ALIGNMENT_MULTIPLE,
    payload: { scopeId, last },
  };
}

export function confirmAligment(scopeId) {
  return {
    type: definitions.CONFIRM_ALIGNMENT,
    payload: { scopeId },
  };
}

export function sendDocumentationEmail(id) {
  return {
    type: definitions.SEND_DOCUMENTATION_EMAIL,
    payload: { id },
  };
}

export function sendContractEmail(id) {
  return {
    type: definitions.SEND_CONTRACT_EMAIL,
    payload: { id },
  };
}

export function fecthToDocumentation() {
  return {
    type: definitions.FETCH_TO_DOCUMENTATION,
  };
}

export function fetchConfirmations() {
  return {
    type: definitions.FETCH_CONFIRMATION_SALES,
  };
}

export function setAlignment(scopeId) {
  return {
    type: definitions.INITIATE_PROCESSES_SALE,
    payload: { scopeId },
  };
}

export function changeClientId(clientId, scopeId) {
  return {
    type: definitions.CHANGE_CLIENT_ID,
    payload: { clientId, scopeId },
  };
}

export const shareSale = (scopeId, userId, last = false) => ({
  type: definitions.SHARE_SALE,
  payload: { scopeId, userId, last },
})

export const deleteShareSale = (scopeId, userId) => ({
  type: definitions.DELETE_SHARE_SALE,
  payload: { scopeId, userId },
})

export function fetchConselhos() {
  return {
    type: definitions.FETCH_PERSON_CONSELHOS,
  };
}

export function fetchFreguesiasByConselhos(id) {
  return {
    type: definitions.FETCH_PERSON_FREGUESIAS,
    payload: { id },
  };
}

export function sendClientInfo(id) {
  return {
    type: definitions.SEND_CLIENT_INFO,
    payload: { id },
  };
}
