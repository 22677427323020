import React from 'react';
import { connect } from 'react-redux';
import { Typography, Grid, TextField, FormControlLabel, Checkbox, ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanel, ExpansionPanelActions, Button } from '@material-ui/core';
import { ExpandMore, Delete } from '@material-ui/icons';
import { isEqual } from 'lodash';
import uniqid from 'uniqid';
import { updateRequirement, removeRequirement, addRequirementToProduct } from 'store/actions/product';
import { showConfirmation } from 'store/actions/confirmations';
import { FULFILLED, REJECTED } from 'constants/common';
import { getFormRequirementObj, postFormRequirementObj } from '../functions';
import { ADD_MODE } from 'constants/user';

const RequirementRow = ({
  requirement,
  formRequirement,
  setFormRequirement,
  handleExpand,
  product,
  expanded,
  mode,
  classes,
  post,
  remove,
  setUpdate,
  update,
  status,
  confirmation,
  newProduct,
  ...props
}) => {
  const originalRequirement = product.requirements.find((r) => r.id === (mode !== ADD_MODE ? requirement.id : 'new'));
  const [loading, setLoading] = React.useState(false);
  const clearNew = (oldrequirements) => {
    setFormRequirement([...oldrequirements.filter((r) => r.id !== 'new'), getFormRequirementObj({ id: 'new' })])
  };
  const hasChanged = !isEqual(requirement, ({
    ...originalRequirement,
    ...getFormRequirementObj(originalRequirement)
  }));
  const handleChange = (name, value) => {
    setFormRequirement((o) => ([
      ...o.map((r) => {
        if (r.id === requirement.id) return ({ ...r, [name]: value });
        return r;
      }),
    ]))
  };
  const handleUpdate = (e) => {
    if (!newProduct) {
      confirmation(
        {
          title: 'Salvar informações da documentação',
          message: `Deseja salvar alterações nas informações da documentação ${requirement.name} do processo ${product.title}?`,
        },
        {
          confirm: 'Salvar',
          cancel: 'Cancelar',
        },
        {
          func: () => {
            update(requirement.id, postFormRequirementObj(requirement));
            setLoading(true);
          },
        },
      );
    } else {
      const newRequirements = formRequirement.map((r) => {
        if (r.id === requirement.id) return ({ ...postFormRequirementObj(requirement) });
        return r;
      });
      clearNew([...newRequirements]);
    }
    e.preventDefault();
  };
  const handlePost = (e) => {
    if (!newProduct) {
      confirmation(
        {
          title: 'Adicionar documentação',
          message: `Deseja adicionar a documentação ${requirement.name} ao processo ${product.title}?`,
        },
        {
          confirm: 'Adicionar',
          cancel: 'Cancelar',
        },
        {
          func: () => {
            post(product.id, postFormRequirementObj(requirement));
            setLoading(true);
          },
        },
      );
    } else {
      clearNew([...formRequirement, { ...postFormRequirementObj(requirement), id: uniqid() }]);
    }
    e.preventDefault();
  };
  const handleRemove = (e) => {
    if (!newProduct) {
      confirmation(
        {
          title: 'Remover documentação do sistema',
          message: (
            <>
              <Typography variant="inherit">{`Deseja REMOVER a documentação ${requirement.name} do processo ${product.title} do sistema?`}</Typography>
              <Typography variant="inherit" color="error">{' Observação: Esta ação não poderá ser desfeita!'}</Typography>
            </>
          ),
        },
        {
          confirm: 'Remover',
          cancel: 'Cancelar',
        },
        {
          func: () => {
            remove(requirement.id);
            setLoading(true);
          },
        },
      );
    } else {
      clearNew([...formRequirement.filter((d) => d.id !== requirement.id)]);
    }
    e.preventDefault();
  };
  React.useEffect(() => {
    setLoading((o) => {
      if ((status === FULFILLED || status === REJECTED) && o) {
        setUpdate(uniqid());
        return false;
      }
      return o;
    });
  }, [status, setUpdate]);
  return (
    <form
      onSubmit={(e) => {
        if (mode !== ADD_MODE) handleUpdate(e);
        else handlePost(e)
      }}
      style={{ marginTop: 16 }}
    >
      <ExpansionPanel expanded={expanded === requirement.id} onChange={() => handleExpand(requirement.id)}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMore />}
          classes={{
            root: mode !== ADD_MODE ? 'regular' : classes.addMorePanel,
            expandIcon: mode !== ADD_MODE ? 'regular' : classes.addMoreButton,
          }}
        >
          {mode !== ADD_MODE ? requirement.name : `${requirement.name || 'Adicionar uma documentação'}`}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={2} style={{ marginTop: 16 }}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                value={requirement.name}
                label="Título"
                onChange={(e) => handleChange('name', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography color={requirement.hague ? 'primary' : 'textPrimary'}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color={requirement.hague ? 'primary' : 'secondary'}
                      checked={requirement.hague}
                      onChange={(e) => handleChange('hague', e.target.checked)}
                    />
                  )}
                  label={requirement.hague ? 'Precisa ser apostilado' : 'Não precisa ser apostilado'}
                />
              </Typography>
              <Typography color={requirement.company_responsible ? 'primary' : 'textPrimary'}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color={requirement.company_responsible ? 'primary' : 'secondary'}
                      checked={requirement.company_responsible}
                      onChange={(e) => handleChange('company_responsible', e.target.checked)}
                    />
                  )}
                  label={requirement.company_responsible ? 'A empresa é responsável por emitir este documento' : 'O cliente é responsável por emitir este documento'}
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                value={requirement.variation}
                label="Variação aceita"
                onChange={(e) => handleChange('variation', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                required
                value={requirement.format}
                label="Formato"
                onChange={(e) => handleChange('format', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                value={requirement.exception}
                label="Exceção"
                onChange={(e) => handleChange('exception', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                value={requirement.specification}
                label="Especificação"
                onChange={(e) => handleChange('specification', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                value={requirement.from_who}
                label="De quem..."
                onChange={(e) => handleChange('from_who', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                rows={5}
                variant="outlined"
                fullWidth
                value={requirement.obs}
                label="Observação"
                onChange={(e) => handleChange('obs', e.target.value)}
              />
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
        <ExpansionPanelActions>
          {mode !== ADD_MODE && (
            <Button color="secondary" onClick={handleRemove} className={classes.removeButton} variant="outlined">
              <Delete />
            </Button>
          )}
          {(!newProduct || mode === ADD_MODE) && (
            <Button
              variant={hasChanged ? 'contained' : 'outlined'}
              color={hasChanged ? 'primary' : 'secondary'}
              disabled={!hasChanged || loading}
              type="submit"
            >
              {mode !== ADD_MODE ? 'Salvar alterações' : 'Criar documentação'}
            </Button>
          )}
        </ExpansionPanelActions>
      </ExpansionPanel>
    </form >
  );
};

const mapStateToProps = (state) => ({
  status: state.product.requirementStatus,
});

const actions = {
  post: addRequirementToProduct,
  update: updateRequirement,
  confirmation: showConfirmation,
  remove: removeRequirement,
};

export default connect(mapStateToProps, actions)(RequirementRow);
