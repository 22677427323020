import React from 'react';
import {
  Paper,
  Typography,
  Grid,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { CloudDownload, Visibility, Edit } from '@material-ui/icons';
import { apiBaseUrl } from 'api';
import { localeDate } from 'helpers';

function ListRow({ contract, index, push }) {
  return (
    <Paper elevation={3} style={{ padding: '16px 24px', marginBottom: 16 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle2">
            <Typography component="span" color="textSecondary">{`#${index + 1}. `}</Typography>
            <Typography component="span">{`Assinado em ${localeDate(contract.signed_date)}`}</Typography>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography align="right">
            {contract.id && (
              <Tooltip title="Visualizar contrato">
                <IconButton
                  onClick={() => push(`/contrato/${contract.id}`)}
                >
                  {contract.contract_path ? <Visibility /> : <Edit />}
                </IconButton>
              </Tooltip>
            )}
            {contract.contract_path && (
              <Tooltip title="Baixar contrato em PDF">
                <IconButton
                  onClick={() => window.open(`${apiBaseUrl}/contracts/${contract.contract_path}`)}
                >
                  <CloudDownload />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ListRow;