import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { DOCUMENTACAO_ROW } from 'constants/service/menus';
import { ServiceContext } from 'contexts/service';
import { emailDocumentation, emailWelcome } from 'store/actions/emails';
import { showConfirmation } from 'store/actions/confirmations';
import {
  MenuItem, Menu, Typography,
} from '@material-ui/core';

function Menus({ documentation, welcome, confirmation }) {
  const serviceContext = useContext(ServiceContext);
  const { openMenu, anchorEl, currentClient } = serviceContext;
  const DOCUMENTATION = 'documentation';

  const handleEmail = (variant) => {

    const confirm = () => {
      if (variant === DOCUMENTATION) {
        documentation(currentClient.id);
      }
      openMenu(false, DOCUMENTACAO_ROW);
    };

    confirmation(
      {
        title: 'Reenviar email',
        message: <>
          {`Reenviar email de documentção de ${currentClient.sales_scope && currentClient.sales_scope && currentClient.sales_scope.customer.name} para o e-mail `}
          <Typography variant="inherit" component="span" color="error">
            {`${currentClient.sales_scope && currentClient.sales_scope && currentClient.sales_scope.customer.email}`}
          </Typography>
          {'?'}
        </>,
      },
      {
        confirm: 'Confirmar',
        cancel: 'Cancelar',
      },
      {
        func: confirm,
        payload: {},
      },
    );
  };
  return (
    <Menu
      anchorEl={anchorEl[DOCUMENTACAO_ROW]}
      open={Boolean(anchorEl[DOCUMENTACAO_ROW])}
      onClose={() => openMenu(false, DOCUMENTACAO_ROW)}
    >
      <MenuItem onClick={() => handleEmail(DOCUMENTATION)}>
        {'Reenviar e-mail de documentação'}
      </MenuItem>
    </Menu>
  )
}

const actions = {
  documentation: emailDocumentation,
  welcome: emailWelcome,
  confirmation: showConfirmation,
};

export default connect(null, actions)(Menus);
