import { DAYS_IN_YEAR, DAYS_IN_MONTH } from "constants/common";
import { validUrl, htmlToLineBreak, convertCurrency, stripValue } from "helpers";

const getMonthsandYears = (days = 0) => {
  const resto = days % DAYS_IN_YEAR;
  const years = Math.floor(days / DAYS_IN_YEAR);
  const months = Math.floor(resto / DAYS_IN_MONTH);
  return ({ months, years });
};

const getDays = (years = 0, months = 0) => {
  let days = 0;
  days = days + years * DAYS_IN_YEAR;
  days = days + months * DAYS_IN_MONTH;
  return days;
};

export const getFormInfoObj = (form = {}) => ({
  title: form.title || '',
  initials: form.initials || '',
  category: form.category === undefined ? 0 : parseInt(form.category, 10),
  can_attached: Boolean(form.can_attached),
  is_courtesy: Boolean(form.is_courtesy),
  maximum: getMonthsandYears(form.maximum_days_to_completion),
  maximum_days_to_completion: form.maximum_days_to_completion || '',
  minimum: getMonthsandYears(form.minimum_days_to_completion),
  minimum_days_to_completion: form.minimum_days_to_completion || '',
  link_procuracao: form.link_procuracao || '',
  description: htmlToLineBreak(form.description || ''),
});

export const setFormInfoObj = (form = {}) => ({
  title: form.title || '',
  initials: form.initials || '',
  category: form.category === undefined ? 0 : parseInt(form.category, 10),
  can_attached: Boolean(form.can_attached),
  is_courtesy: Boolean(form.is_courtesy),
  maximum: form.maximum,
  maximum_days_to_completion: getDays(form.maximum.years, form.maximum.months),
  minimum: form.minimum,
  minimum_days_to_completion: getDays(form.minimum.years, form.minimum.months),
  link_procuracao: form.link_procuracao || '',
  description: htmlToLineBreak(form.description || ''),
});

export const postFormObj = (form = {}) => ({
  title: form.title || '',
  initials: form.initials || '',
  can_attached: Boolean(form.can_attached),
  is_courtesy: Boolean(form.is_courtesy),
  category: form.category === undefined ? 0 : parseInt(form.category, 10),
  maximum_days_to_completion: getDays(form.maximum.years, form.maximum.months),
  minimum_days_to_completion: getDays(form.minimum.years, form.minimum.months),
  link_procuracao: form.link_procuracao || '',
  description: form.description || '',
});

export const getFormRequirementObj = (form = {}) => ({
  id: form.id,
  company_responsible: Boolean(form.company_responsible),
  exception: form.exception || '',
  name: form.name || '',
  format: form.format || '',
  hague: Boolean(form.hague),
  obs: form.obs || '',
  provided: Boolean(form.provided),
  specification: form.specification || '',
  variation: form.variation || '',
});

export const postFormRequirementObj = (form = {}) => ({
  name: form.name || '',
  company_responsible: Boolean(form.company_responsible),
  exception: form.exception || '',
  format: form.format || '',
  hague: Boolean(form.hague),
  obs: form.obs || '',
  provided: Boolean(form.provided),
  specification: form.specification || '',
  variation: form.variation || '',
  from_who: form.from_who || null
});

export const getFormPriceObj = (form = {}) => ({
  id: form.id,
  name: form.name || '',
  price: convertCurrency(form.price, false),
  old_price: convertCurrency(form.old_price, false),
});

export const postFormPriceObj = (form = {}) => ({
  name: form.name || '',
  price: stripValue(form.price || 0),
  old_price: stripValue(form.old_price || 0),
});

export const postProductObj = (form = {}, prices = [], requirements = []) => ({
  ...postFormObj(form),
  prices: prices.filter((p) => p.id !== 'new').map((p) => postFormPriceObj(p)),
  requirements: requirements.filter((r) => r.id !== 'new').map((r) => postFormRequirementObj(r)),
});

export const valid = (form = {}) => {
  const errors = [];
  if (form.link_procuracao.length > 3 && !validUrl(form.link_procuracao)) errors.push('link_procuracao');
  if (errors.length) throw errors;
  return true;
};

export const validCreate = (form = {}, prices = [], requirements = []) => {
  const errors = [];
  const mustHave = ['title', 'initials', 'category'];
  mustHave.forEach((must) => {
    if (`${form[must]}` === '') errors.push(must);
  });
  if (prices.length === 0) errors.push('prices');
  if (requirements.length === 0) errors.push('requirements');
  if (errors.length) throw errors;
  return true;
};
