// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  InputBase,
  IconButton,
  Container,
  Typography,
} from '@material-ui/core';
import { SEARCH } from 'constants/common/pageTitles';
import {
  Search as SearchIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import _ from 'lodash';
import { searchCustomers } from 'store/actions/search';
import { LoadingComponent } from 'global/modules/Loading';
import Root from 'global/modules/Root';
import * as $_ from 'global/functions';
import NoResults from 'global/modules/NoResults';
import { REQUESTING, DEFAULT, FULFILLED } from 'constants/common';
import { useStyles } from './style';
import Result from './ResultRow';


function Results({
  value, results, status, history, search, update, setUpdate, ...props
}) {
  const fullValue = value || '';
  const classes = useStyles(props);
  const title = `${fullValue ? `"${fullValue}" - ` : ''}`;
  const [searchValue, setSearchValue] = useState(fullValue || '');

  const forwardSearch = () => {
    if (searchValue !== '') {
      history.push(`/buscar/${searchValue.trim()}`);
      search(searchValue.trim());
      setSearchValue((o) => o.trim());
    }
  };

  const handleChangeValue = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (!_.isEmpty(searchValue) && !_.isUndefined(searchValue)) search(searchValue);
    // eslint-disable-next-line
  }, [search, update, value]);

  return (
    <Root
      setTitle={`${title}${SEARCH}`}
      paddingTop
      centered={_.isEmpty(value)}
      header
      HeaderProps={{
        noSearch: true,
      }}
      className={classes.root}
    >
      <Container maxWidth="xl">
        <Paper className={clsx(_.isEmpty(value) ? 'single' : 'results', classes.inputRoot)} elevation={4}>
          <InputBase
            className={classes.input}
            id="search-value"
            placeholder="Buscar por processo ou cliente"
            inputProps={{ 'aria-label': 'Buscar por processo ou cliente' }}
            value={searchValue}
            onChange={(e) => handleChangeValue(e)}
            onKeyDown={(e) => { if ($_.deburrify(e.key) === 'enter') forwardSearch(); }}
          />
          <IconButton
            className={classes.iconButton}
            aria-label="search"
            onClick={() => forwardSearch()}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
        {results && status === FULFILLED && results.length > 0 && (
          <>
            <Typography
              variant="h5"
              color="textSecondary"
              className={classes.pageTitle}
            >
              {`Clientes encontrados (${results.length})`}
            </Typography>
            {results.map((result, index) => (
              <Result
                update={update}
                setUpdate={setUpdate}
                classes={classes}
                key={`Result${result.id}`}
                customer={result}
                index={index}
              />
            ))}
          </>
        )}
        {results && status === FULFILLED && results.length === 0 && (
          <NoResults message="Nenhum cliente encontrado." />
        )}
        {status === REQUESTING && (
          <LoadingComponent />
        )}
      </Container>
      <div style={{ paddingBottom: 1 }} />
    </Root>
  );
}

Results.propTypes = {
  value: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  results: PropTypes.array,
  status: PropTypes.string,
  search: PropTypes.func.isRequired,
};

Results.defaultProps = {
  value: '',
  results: [],
  status: DEFAULT,
};

const mapStateToProps = (state) => ({
  results: state.search.results,
  status: state.search.status,
  products: state.product.allProducts,
});

const actions = {
  search: searchCustomers,
};

export default connect(mapStateToProps, actions)(Results);
