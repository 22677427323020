import React from 'react';
import {
  SwipeableDrawer,
  Drawer,
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';

function CustomDrawer({ children, onOpen, ...props }) {
  if (isMobile) {
    return (
      <SwipeableDrawer onOpen={onOpen} {...props}>{children}</SwipeableDrawer>
    );
  }
  return (
    <Drawer {...props}>{children}</Drawer>
  );
}

export default CustomDrawer;
