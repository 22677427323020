import { returnValidDate, readableBRPhoneNumbers } from 'helpers';

export const formatThis = (form) => {
  let phoneNumber = form.phone;
  if (phoneNumber) phoneNumber = readableBRPhoneNumbers(form.phone);
  return ({
    name: form.name,
    email: form.email,
    phone: phoneNumber,
    gender: form.gender,
    cpf: form.cpf,
    address1: form.address1,
    address2: form.address2,
    address3: form.address3,
    address4: form.address4,
    city: form.city,
    state: form.state,
    country: form.country,
    birthDate: form.birthdate,
    emission: form.emissor,
    emissionDate: form.dispatch_date,
    rg: form.rg,
    zipCode: form.zip_code,
  });
}

export const formatToPost = (form, initial) => {
  const postObj = {
    internalId: form.internalId,
    clientId: form.clientId,
    name: form.name,
    email: form.email,
    gender: form.gender,
    cpf: form.cpf,
    address1: form.address1,
    address2: form.address2,
    address3: form.address3,
    address4: form.address4,
    city: form.city,
    state: form.state,
    country: form.country,
    birthdate: returnValidDate(form.birthDate),
    emissor: form.emission,
    dispatch_date: returnValidDate(form.emissionDate),
    rg: form.rg,
    zip_code: form.zipCode,
  };
  if(form.phone
    && form.phone.startsWith('+')
    && initial
    && initial.phone !== form.phone) postObj.phone = form.phone;
  return postObj;
}
