import React, { Component } from 'react';
import { LoaderContext } from 'contexts/user';
import Loading from 'global/modules/Loading';

class LoaderProvider extends Component {

  state = { loading: false }

  isLoading = (show) => this.setState({ loading: show });

  render() {
    return (
      <LoaderContext.Provider value={{
        isLoading: this.isLoading,
      }}>
        {this.state.loading ? (<Loading />) : (this.props.children)}
      </LoaderContext.Provider>
    );
  }
}

export default LoaderProvider;
