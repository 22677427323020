import React, { useState, useContext } from 'react';
import * as imports from 'objects/imports';
import { Tooltip, Container, Paper, Grid, Collapse, Icon } from '@material-ui/core';
import CloseButton from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { isMobile } from 'react-device-detect';
import { makeStyles, } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as $_ from '../functions';
import { UserContext } from 'contexts/user';

let currentStep = 0;
const steps = imports.userSteps;
const sizeSteps = steps.length - 1;

const useStyles = makeStyles(theme => ({
  closeBtn: {
    width: '2em',
    height: '2em',
    position: 'absolute',
    top: '50%',
    marginTop: '-1em',
    right: 0,
    [theme.breakpoints.down('sm')]: {
      right: '50%',
      top: '100%',
      marginTop: 0,
      marginRight: '-1em',
    }
  },
  closeIcon: {
    width: '100%',
    height: '100%',
  },
  stepPaper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.grey[50],
  },
  step: {
    height: '34px',
    width: '34px',
    marginTop: '-17px !important',
    marginRight: '0 !important',
    marginBottom: '0 !important',
    marginLeft: '-17px !important',
    position: 'absolute',
    top: '50%',
    backgroundColor: '#50705f',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    transition: theme.transitions.create('all', { duration: '80ms' }),
    padding: 0,
    lineHeight: '1em',
    textAlign: 'center',
    '& i': {
      margin: 0,
      color: 'transparent',
    },
    '&:hover, &.active': {
      fontSize: '150%',
      height: '3rem',
      width: '3rem',
      marginTop: '-1.5rem !important',
      marginRight: '0 !important',
      marginBottom: '0 !important',
      marginLeft: '-1.5rem !important',
      backgroundColor: '#5fb889 !important',
      zIndex: '80',
      '& i': {
        color: '#FFFFFF',
      }
    },
    '&.disabled': {
      backgroundColor: theme.palette.grey[600],
    }
  },
  stepSelected: {
    boxShadow: '#6fd79f 2px 2px 2px',
    '& i': {
      color: '#FFFFFF',
    }
  },
  icon: {
    fontSize: '3rem',
    width: '10%',
    height: 'auto',
    textAlign: 'center',
    minWidth: theme.spacing(10),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      fontSize: '2.5rem',
    }
  },
  iconHolder: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    webkitBoxAlign: 'center',
    msFlexalign: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginRight: 0,
      marginBottom: theme.spacing(7.5),
    }
  },
  content: {
    display: 'block',
    webkitBoxFlex: '1',
    msFlex: '1 1 auto',
    flex: '1 1 auto',
    verticalAlign: 'middle',
    marginRight: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      textAlign: 'justify',
    }
  },
  timeline: {
    position: 'relative',
    margin: theme.spacing(0, 2),
    height: '4rem',
  },
  timelineLine: {
    position: 'absolute',
    top: '50%',
    height: '13px',
    background: theme.palette.grey[400],
    width: '100%',
    marginTop: '-8px',
  },
  timelineContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  collapse: {
    margin: theme.spacing(0, -2),
    padding: theme.spacing(0, 2),
  }

}));

function Timeline(props) {
  const classes = useStyles();
  const user = useContext(UserContext).user;
  user.currentStep = 2; // TODO: TEMP DATA
  currentStep = user.currentStep
  let showTag = currentStep === 0 || currentStep === sizeSteps ? false : true;

  const [stepContent, setstepContent] = useState({ open: false, id: 0 });

  const hideStepDescription = (e) => {
    e.preventDefault();
    let updateStep = { ...stepContent, open: false };

    setstepContent(updateStep);
  }

  function TimelineStep({ step, index }) {
    const showStepDescription = (e) => {
      e.preventDefault();
      setstepContent({ open: true, id: step.id });
    }
    let a;
    let b;
    if (isMobile) b = `${index * (100 / sizeSteps)}%`
    if (currentStep === 0 || currentStep === sizeSteps) {
      if (index === 0 && currentStep === 0) {
        a = "active"
        b = b ? b : "0%"
      }
      else if (index === sizeSteps && currentStep === sizeSteps) {
        a = "active"
        b = b ? b : "100%"
      }
      else {
        if (currentStep === sizeSteps) {
          a = null;
        }
        else if (currentStep === 0) {
          a = "disabled"
        }
        b = b ? b : `${index * (100 / sizeSteps)}%`
      }
    }
    else {
      if (index === currentStep && currentStep > 0 && currentStep < sizeSteps) {
        a = "active"
        b = b ? b : "50%"
      }
      else if (index > currentStep) {
        a = "disabled"
        let x = 50;
        let y = sizeSteps - currentStep
        let z = x / y
        let r = z * (index - currentStep)
        b = b ? b : `${x + r}%`
      }
      else if (index < currentStep) {
        if (index === 0) {
          a = null;
          b = b ? b : "0%";
        }
        else {
          a = null;
          let x = 50;
          let z = x / currentStep
          let r = z * index
          b = b ? b : `${r}%`
        }
      }
    }

    const leftValue = { left: b }
    return (
      <>
        <Tooltip title={step.title} disableTouchListener placement="left">
          <div className={clsx(stepContent.id === index && stepContent.open && classes.stepSelected, a, classes.step)} style={leftValue} onClick={stepContent.id === index && stepContent.open ? hideStepDescription : showStepDescription}><i className={step.iconclass} /></div>
        </Tooltip>
      </>
    )
  }
  return (
    <>
      <Container maxWidth="xl" classes={{ root: classes.timelineContainer }}>
        {imports.userSteps[currentStep].daystilcompletion > 0 && showTag ?
          <Typography variant="subtitle2" align={isMobile ? `left` : `center`} color="textSecondary">
            {showTag ? 'Etapa atual' : null}
          </Typography>
          : null}
        <div className={clsx(classes.timeline, 'timeline')}>
          <div className={classes.timelineLine} />
          {steps.map((step, index) => {
            return <TimelineStep key={`step${step.id}`} step={step} index={index} />
          })}
        </div>
        <Collapse in={stepContent.open} classes={{ container: classes.collapse }}>
          <Paper elevation={1} className={classes.stepPaper}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.iconHolder}>
                <Icon className={clsx(classes.icon, steps[stepContent.id].iconclass)} color="primary" />
                <div className={classes.content}>
                  <Typography variant="h6" className={classes.header}>
                    {steps[stepContent.id].title}
                  </Typography>
                  <Typography variant="body1" className={classes.description}>
                    {steps[stepContent.id].timelineDescription}
                  </Typography>
                </div>
                <IconButton onClick={hideStepDescription} className={classes.closeBtn}>
                  <CloseButton className={classes.closeIcon} />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        </Collapse>
        {imports.userSteps[currentStep].daystilcompletion > 0 && showTag ?
          <Typography variant="subtitle2" align={isMobile ? `left` : `center`} color="textSecondary">
            Aproximadamente <strong>{$_.displayTime(imports.userSteps[currentStep].daystilcompletion)}</strong> até a próxima etapa
            </Typography>
          : null}
      </Container>
    </>
  )
}

export default Timeline;
