import { createSelector } from 'reselect';
import * as $_ from 'global/functions';

const allProcesses = (state) => state.processes.allProcesses;

const basicSearchValues = ['tracking_code', 'id'];

export const filterBatchPost = createSelector(
  allProcesses,
  (processList) => (searchValue, filter, sortBy, sortOrder) => {
    let returnValue = processList || [];
    if (!returnValue || returnValue.length === 0) return [];
    if (searchValue) {
      returnValue = returnValue.filter((lot) => (
        $_.matchable(
          lot.processes,
          'identifier',
          searchValue,
        ) || $_.matchable(
          lot,
          basicSearchValues,
          searchValue,
        )
      ));
    }
    if (sortBy) {
      switch (sortBy) {
        case 'name':
          returnValue = returnValue.sort((a, b) => {
            if (a.applicant[sortBy] < b.applicant[sortBy]) {
              return sortOrder ? -1 : 1;
            }
            if (a.applicant[sortBy] > b.applicant[sortBy]) {
              return sortOrder ? 1 : -1;
            }
            return 0;
          });
          break;
        case 'confirmed_date':
        case 'align_date':
        case 'client_id':
          returnValue = returnValue.sort((a, b) => {
            const firstValue = a.sales_scope[sortBy] || '';
            const secondValue = b.sales_scope[sortBy] || '';
            if (firstValue < secondValue) {
              return sortOrder ? -1 : 1;
            }
            if (firstValue > secondValue) {
              return sortOrder ? 1 : -1;
            }
            return 0;
          });
          break;
        default:
          returnValue = returnValue.sort((a, b) => {
            if (a[sortBy] < b[sortBy]) {
              return sortOrder ? -1 : 1;
            }
            if (a[sortBy] > b[sortBy]) {
              return sortOrder ? 1 : -1;
            }
            return 0;
          });
          break;
      }
    }

    return Array.from(returnValue);
  },
);
