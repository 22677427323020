import React from 'react';
import { Typography, Paper, Grid } from '@material-ui/core';
import { useStyles } from '../style';
import { bool, number } from 'prop-types';

function HeaderColumn({ responseSize, filteredSize, blocked, ...props }) {
  const classes = useStyles(props);
  const filtered = filteredSize !== responseSize;
  return (
    <>
      {blocked
        ? (
          <Typography
            variant="h5"
            color="textSecondary"
            className={classes.pageTitle}
          >
            {`Travado${responseSize === 1 ? '' : 's'} (${filtered ? `${filteredSize} de ` : ''}${responseSize})`}
          </Typography>
        ) : (
          <Typography
            variant="h5"
            color="textSecondary"
            className={classes.pageTitle}
          >
            {`Pronto${responseSize === 1 ? '' : 's'} (${filtered ? `${filteredSize} de ` : ''}${responseSize})`}
          </Typography>
        )}
      {filteredSize > 0 && blocked && (
        <Paper className={classes.header}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body2">
                {'Requerente'}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2">
                {'Documentos'}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2">
                {'Data'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">
                {'Motivo'}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
      {filteredSize > 0 && !blocked && (
        <Paper className={classes.header}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body2">
                {'Requerente'}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">
                {'Pronto em'}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2">
                {'Documentos'}
              </Typography>
            </Grid>
            <Grid item xs={3} />
          </Grid>
        </Paper>
      )
      }
    </>
  )
}

HeaderColumn.propTypes = {
  blocked: bool,
  responseSize: number,
  filteredSize: number,
}

HeaderColumn.defaultProps = {
  blocked: false,
  responseSize: 0,
  filteredSize: 0,
}

export default HeaderColumn;
