/* eslint-disable max-lines */
import {
  put, takeLatest, all, call, select,
} from 'redux-saga/effects';
import * as definitions from 'store/definitions/sales';
import { SALES_PATH, PERSON_PATH, SCOPE_PATH, DOCUMENT_PATH, SCOPES_PATH } from 'api/paths';
import * as API from 'api';
import * as selectors from 'store/selectors/sales';
import * as notificationDefinitions from 'store/definitions/notifications';
import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from 'constants/notification';
import * as messages from 'constants/common/messages';
import { sortBy } from 'helpers';
import { STEP_CHECKING } from 'constants/process';

const getFetchScopeByIdObject = (scopeId) => ({
  type: definitions.FETCH_SALE_SCOPE_BY_SCOPE_ID,
  payload: { scopeId },
});

export function* patchScopeAlignment(action) {
  try {
    const { scopeId } = action.payload;
    const payload = yield call(API.updateAction, SCOPE_PATH, scopeId, 'set-alignment', {});
    yield put({
      type: definitions.INITIATE_PROCESSES_SALE_FULFILLED,
      payload,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_COMMON_UPDATE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.INITIATE_PROCESSES_SALE_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export function* fetchConfirmationSales() {
  try {
    const fullPath = `${SCOPE_PATH}/by-steps/${STEP_CHECKING}?size=500`;
    const payload = yield call(API.fetch, fullPath);
    yield put({
      type: definitions.FETCH_CONFIRMATION_SALES_FULFILLED,
      payload: payload.scopes,
    });
  } catch (error) {
    console.error(error)
    yield put({
      type: definitions.FETCH_CONFIRMATION_SALES_REJECTED,
      payload: { error },
    });
  }
}

export function* fetchAllOpenScopes() {
  try {
    const fullPath1 = `${SCOPE_PATH}/salesman/leads`;
    const payload1 = yield call(API.fetch, fullPath1);
    const fullPath2 = `${SCOPE_PATH}/salesman/accompaniment`;
    const payload2 = yield call(API.fetch, fullPath2);
    const payload = [...payload1.scopes, ...payload2];
    yield put({
      type: definitions.FETCH_ALL_OPEN_SCOPES_FULFILLED,
      payload: sortBy(payload, 'updated_at'),
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_ALL_OPEN_SCOPES_REJECTED,
      payload: { error },
    });
  }
}

export function* fetchAllLeads(action) {
  const { add } = action.payload;
  try {
    const { cancelToken, page, sortBy, sortOrder, q } = action.payload;
    const fullPath = `${SCOPE_PATH}/by-step/opened`;
    const filter = {
      page,
      sort: `${sortBy},${sortOrder}`,
      q,
    };
    const payload = yield call(API.fetch, fullPath, { ...filter }, cancelToken);
    yield put({
      type: definitions.FETCH_ALL_LEADS_FULFILLED,
      payload,
      add,
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_ALL_LEADS_REJECTED,
      payload: { error },
      add,
    });
  }
}

export function* fetchAllAccompaniment(action) {
  const { add } = action.payload;
  try {
    const { cancelToken, page, sortBy, sortOrder, q } = action.payload;
    const fullPath = `${SCOPE_PATH}/by-step/accompaniment`;
    const filter = {
      page,
      sort: `${sortBy},${sortOrder}`,
      q,
    };
    const payload = yield call(API.fetch, fullPath, { ...filter }, cancelToken);
    yield put({
      type: definitions.FETCH_ALL_ACCOMPANIMENT_FULFILLED,
      payload,
      add,
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_ALL_ACCOMPANIMENT_REJECTED,
      payload: { error },
      add,
    });
  }
}

export function* fetchAllHistory(action) {
  const { add } = action.payload;
  try {
    const { cancelToken, page, sortBy, sortOrder, q } = action.payload;
    const fullPath = `${SCOPE_PATH}/by-step/historic`;
    const filter = {
      page,
      sort: `${sortBy},${sortOrder}`,
      q,
    };
    const payload = yield call(API.fetch, fullPath, { ...filter }, cancelToken);
    yield put({
      type: definitions.FETCH_ALL_HISTORIC_FULFILLED,
      payload,
      add,
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_ALL_HISTORIC_REJECTED,
      payload: { error },
      add,
    });
  }
}

export function* fetchLeadsBySalesman(action) {
  try {
    const { cancelToken, page } = action.payload;
    const fullPath = `${SCOPE_PATH}/salesman/leads`;
    const payload = yield call(API.fetch, fullPath, {page: page}, cancelToken);
    yield put({
      type: definitions.FETCH_LEADS_BY_SALESMAN_FULFILLED,
      payload: payload,
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_LEADS_BY_SALESMAN_REJECTED,
      payload: { error },
    });
  }
}

export function* fetchAccompanimentBySalesman(action) {
  try {
    const { cancelToken, page } = action.payload;
    const fullPath = `${SCOPE_PATH}/salesman/accompaniment`;
    const payload = yield call(API.fetch, fullPath, {page: page}, cancelToken);
    yield put({
      type: definitions.FETCH_ACCOMPANIMENT_BY_SALESMAN_FULFILLED,
      payload: payload,
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_ACCOMPANIMENT_BY_SALESMAN_REJECTED,
      payload: { error },
    });
  }
}

export function* fetchHistoricBySalesman(action) {
  try {
    const { cancelToken, page } = action.payload;
    const fullPath = `${SCOPE_PATH}/salesman/historic`;
    const payload = yield call(API.fetch, fullPath, {page: page}, cancelToken);
    yield put({
      type: definitions.FETCH_HISTORIC_BY_SALESMAN_FULFILLED,
      payload: payload,
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_HISTORIC_BY_SALESMAN_REJECTED,
      payload: { error },
    });
  }
}

export function* resultsClear() {
  yield put({ type: definitions.RESULTS_CLEAR_DONE });
}

export function* salesSearch(action) {
  const {
    searchValue, filter, sortBy, sortOrder,
  } = action.payload;
  const payload = yield select(
    (state) => selectors.filterSales(state)(searchValue, filter, sortBy, sortOrder)
  );
  yield put({ type: definitions.SALES_SEARCH_RESULT, payload });
}

export function* createScopeByClientId(action) {
  try {
    const { clientId, data } = action.payload;
    const fullPath = `${SCOPE_PATH}`;
    const payload = yield call(API.create, fullPath, { clientId, ...data });
    yield put({
      type: definitions.CREATE_SCOPE_BY_CLIENT_ID_FULFILLED,
      payload,
    });
  } catch (error) {
    yield put({
      type: definitions.CREATE_SCOPE_BY_CLIENT_ID_REJECTED,
      payload: { error },
    });
  }
}

export function* fetchScopeByClientId(action) {
  try {
    const { scopeId } = action.payload;
    const fullPath = `${SCOPE_PATH}/${scopeId}`
    const payload = yield call(API.fetch, fullPath, {});
    yield put({
      type: definitions.FETCH_SALE_SCOPE_BY_SCOPE_ID_FULFILLED,
      payload: payload.scope,
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_SALE_SCOPE_BY_SCOPE_ID_REJECTED,
      payload: { error },
    });
  }
}

export function* updateScopeById(action) {
  try {
    const { scopeId, data } = action.payload;
    const fullPath = `${SCOPE_PATH}`;
    const payload = yield call(API.update, fullPath, scopeId, data);
    yield put({
      type: definitions.UPDATE_SALE_SCOPE_BY_ID_FULFILLED,
      payload,
    });
    if (scopeId) yield put(getFetchScopeByIdObject(scopeId));
  } catch (error) {
    yield put({
      type: definitions.UPDATE_SALE_SCOPE_BY_ID_REJECTED,
      payload: { error },
    });
  }
}

export function* calculateScopeById(action) {
  try {
    const { scopeId, withoutDiscounts } = action.payload;
    const fullPath = `${SCOPE_PATH}`;
    const payload = yield call(API.updateAction, fullPath, scopeId, 'calculate', null, {'no-discounts': withoutDiscounts});
    yield put({
      type: definitions.CALCULATE_SALE_SCOPE_BY_ID_FULFILLED,
      payload,
    });
    if (scopeId) yield put(getFetchScopeByIdObject(scopeId));
  } catch (error) {
    yield put({
      type: definitions.CALCULATE_SALE_SCOPE_BY_ID_REJECTED,
      payload: { error },
    });
  }
}

export function* removeDocument(action) {
  try {
    const { docId, scopeId } = action.payload;
    const payload = yield call(API.remove, `${DOCUMENT_PATH}`, docId);
    yield put({
      type: definitions.REMOVE_DOCUMENT_FROM_PERSON_FULFILLED,
      payload,
    });
    if (scopeId) yield put(getFetchScopeByIdObject(scopeId));
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_REMOVE_FILE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.REMOVE_DOCUMENT_FROM_PERSON_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export function* createPerson(action) {
  try {
    const { clientId, data, scopeId } = action.payload;
    const fullPath = `${PERSON_PATH}`;
    const payload = yield call(API.create, fullPath, { clientId, ...data });
    yield put({
      type: definitions.CREATE_PERSON_FULFILLED,
      payload: payload.scope,
    });
    if (scopeId) yield put(getFetchScopeByIdObject(scopeId));
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_COMMON_CREATE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.CREATE_PERSON_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export function* updatePerson(action) {
  try {
    const { scopeId, personId, data } = action.payload;
    const fullPath = `${PERSON_PATH}`;
    const payload = yield call(API.update, fullPath, personId, { ...data });
    yield put({
      type: definitions.UPDATE_PERSON_FULFILLED,
      payload,
    });
    if (scopeId) yield put(getFetchScopeByIdObject(scopeId));
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_COMMON_UPDATE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.UPDATE_PERSON_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export function* removePerson(action) {
  try {
    const { personId, scopeId } = action.payload;
    const fullPath = `${PERSON_PATH}`;
    const payload = yield call(API.remove, fullPath, personId);
    yield put({
      type: definitions.REMOVE_PERSON_FULFILLED,
      payload,
    });
    if (scopeId) yield put(getFetchScopeByIdObject(scopeId));
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_REMOVE_PERSON,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.REMOVE_PERSON_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export function* confirmScope(action) {
  try {
    const { id, type, data } = action.payload;
    const fullPath = `${SCOPE_PATH}/${id}/confirm/${type}`;
    const payload = yield call(API.create, fullPath, { ...data });
    yield put({
      type: definitions.SEND_CLIENT_INFO,
      payload: { id }
    })
    yield put({
      type: definitions.CONFIRM_SCOPE_FULFILLED,
      payload,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_CONFIRM_SCOPE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.CONFIRM_SCOPE_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export function* createProcess(action) {
  try {
    const { data, clientId, scopeId } = action.payload;
    const fullPath = `${SALES_PATH}`;
    const payload = yield call(API.create, fullPath, { ...data, clientId });
    yield put({
      type: definitions.CREATE_PROCESS_FULFILLED,
      payload,
    });
    if (scopeId) yield put(getFetchScopeByIdObject(scopeId));
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_CREATE_PROCESS,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.CREATE_PROCESS_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export function* updateProcess(action) {
  try {
    const { processId, scopeId, data } = action.payload;
    const fullPath = `${SALES_PATH}`;
    const payload = yield call(API.update, fullPath, processId, { ...data });
    yield put({
      type: definitions.UPDATE_PROCESS_FULFILLED,
      payload,
    });
    if (scopeId) yield put(getFetchScopeByIdObject(scopeId));
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_COMMON_UPDATE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.UPDATE_PROCESS_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export function* removeProcess(action) {
  try {
    const { processId, scopeId } = action.payload;
    const fullPath = `${SALES_PATH}`;
    const payload = yield call(API.remove, fullPath, processId);
    yield put({
      type: definitions.REMOVE_PROCESS_FULFILLED,
      payload,
    });
    if (scopeId) yield put(getFetchScopeByIdObject(scopeId));
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_REMOVE_PROCESS,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.REMOVE_PROCESS_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export function* fetchClientSales(action) {
  try {
    const { id } = action.payload;
    const fullpath = `${SCOPE_PATH}/by-customer`;
    const payload = yield call(API.fetchById, fullpath, id);
    yield put({
      type: definitions.FETCH_SALES_BY_CLIENT_FULFILLED,
      payload: payload.scope,
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_SALES_BY_CLIENT_REJECTED,
      payload: { error },
    });
  }
}

export function* fetchModularClientSales(action) {
  try {
    const { id } = action.payload;
    const fullpath = `${SCOPE_PATH}/by-customer`;
    const payload = yield call(API.fetchById, fullpath, id);
    yield put({
      type: definitions.FETCH_MODULAR_SALES_BY_CLIENT_FULFILLED,
      payload: payload.scope,
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_MODULAR_SALES_BY_CLIENT_REJECTED,
      payload: { error },
    });
  }
}

export function* updateSignedContact(action) {
  try {
    const { contractId } = action.payload;
    const data = action.payload.data || {};
    const fullPath = `${SCOPE_PATH}`;
    const payload = yield call(API.updateAction, fullPath, contractId, 'signed-contract', { ...data });
    yield put({
      type: definitions.UPDATE_SIGNED_CONTRACT_FULFILLED,
      payload,
    });
    yield put({
      type: definitions.FETCH_ACCOMPANIMENT_BY_SALESMAN,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_SUCCESS, message: messages.MESSAGE_SUCCESS_SENT_FILE },
    });
  } catch (error) {
    yield put({
      type: definitions.UPDATE_SIGNED_CONTRACT_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}


export function* toAlign(action) {
  try {
    const { cancelToken, page } = action.payload;
    const fullPath = `${SCOPE_PATH}/align`;
    const payload = yield call(API.fetch, fullPath, {page}, cancelToken);
    yield put({
      type: definitions.FETCH_TO_ALIGN_FULFILLED,
      payload,
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_TO_ALIGN_REJECTED,
      payload: { error },
    });
  }
}

export function* confirmAligmentMultiple(action) {
  try {
    const { scopeId, last } = action.payload;
    const fullPath = `${SCOPE_PATH}/${scopeId}/confirm-align`;
    const payload = yield call(API.update, fullPath, '', {});
    yield put({
      type: definitions.FETCH_TO_ALIGN,
    });
    yield put({
      type: definitions.CONFIRM_ALIGNMENT_FULFILLED,
      payload,
    });
    if (last) {
      yield put({
        type: notificationDefinitions.SHOW,
        payload: { variant: NOTIFICATION_TYPE_SUCCESS, message: messages.MESSAGE_ALIGN_MULTIPLE_SUCCESS },
      });
    }
  } catch (error) {
    yield put({
      type: definitions.CONFIRM_ALIGNMENT_REJECTED,
      payload: { error },
    });
  }
}

export function* confirmAligment(action) {
  try {
    const { scopeId } = action.payload;
    const fullPath = `${SCOPE_PATH}/${scopeId}/confirm-align`;
    const payload = yield call(API.update, fullPath, '', {});
    yield put({
      type: definitions.FETCH_TO_ALIGN,
    });
    yield put({
      type: definitions.CONFIRM_ALIGNMENT_FULFILLED,
      payload,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_SUCCESS, message: messages.MESSAGE_ALIGN_SUCCESS },
    });
  } catch (error) {
    yield put({
      type: definitions.CONFIRM_ALIGNMENT_REJECTED,
      payload: { error },
    });
  }
}

export function* sendContractEmail(action) {
  try {
    alert(action.payload.id);
    const { id } = action.payload;
    const fullPath = `${SCOPE_PATH}/${id}/send-checklist-email`;
    const payload = yield call(API.fetch, fullPath, {});
    yield put({
      type: definitions.SEND_CONTRACT_EMAIL_FULFILLED,
      payload,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_SUCCESS, message: payload.message },
    });
  } catch (error) {
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: error.message },
    });
    yield put({
      type: definitions.SEND_CONTRACT_EMAIL_REJECTED,
      payload: { error },
    });
  }
}

export function* sendDocumentationEmail(action) {
  try {
    const { id } = action.payload;
    const fullPath = `${SCOPE_PATH}/${id}/send-documents-email`;
    const payload = yield call(API.fetch, fullPath, {});
    yield put({
      type: definitions.SEND_DOCUMENTATION_EMAIL_FULFILLED,
      payload,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_SUCCESS, message: payload.message },
    });
  } catch (error) {
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: error.message },
    });
    yield put({
      type: definitions.SEND_DOCUMENTATION_EMAIL_REJECTED,
      payload: { error },
    });
  }
}

export function* toDocumentation() {
  try {
    const fullPath = `${SCOPE_PATH}/documentation`;
    const payload = yield call(API.fetch, fullPath);
    yield put({
      type: definitions.FETCH_TO_DOCUMENTATION_FULFILLED,
      payload,
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_TO_DOCUMENTATION_REJECTED,
      payload: { error },
    });
  }
}

export function* removeScopes(action) {
  try {
    const { scopes } = action.payload;
    const fullPath = `${SCOPE_PATH}/delete`;
    const payload = yield call(API.remove, fullPath, '', { scopes });
    yield put({
      type: definitions.DELETE_SCOPE_FULFILLED,
      payload,
    });
  } catch (error) {
    yield put({
      type: definitions.DELETE_SCOPE_REJECTED,
      payload: { error },
    });
  }
}

export function* patchClientId(action) {
  try {
    const { clientId, scopeId } = action;
    const payload = yield call(API.updateAction, SCOPE_PATH, scopeId, 'update-client-id', { client_id: clientId });
    yield put({
      type: definitions.CHANGE_CLIENT_ID_FULFILLED,
      payload,
    })
  } catch (error) {
    yield put({
      type: definitions.CHANGE_CLIENT_ID_REJECTED,
      payload: { error },
    });
  }
}

export function* postSharedSale(action) {
  const { last } = action.payload;
  try {
    const { scopeId, userId } = action.payload;
    const fullPath = `${SCOPES_PATH}/share`;
    const payload = yield call(API.create, fullPath, { sale_scope_id: scopeId, user_id: userId });
    yield put({
      type: definitions.SHARE_SALE_FULFILLED,
      payload,
    });
    if (last) {
      yield put({
        type: definitions.BATCH_STATUS_FULFILLED,
      });
      yield put({
        type: notificationDefinitions.SHOW,
        payload: {
          variant: NOTIFICATION_TYPE_SUCCESS,
          message: 'Compartilhado com sucesso!',
        },
      });
    }
  } catch (error) {
    if (last) {
      yield put({
        type: definitions.BATCH_STATUS_REJECTED,
      });
    }
    yield put({
      type: definitions.SHARE_SALE_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export function* deleteSharedSale(action) {
  try {
    const { scopeId, userId } = action.payload;
    const fullPath = `${SCOPES_PATH}/shared/${scopeId}/user/${userId}`;
    const payload = yield call(API.remove, fullPath, '');
    yield put({
      type: definitions.DELETE_SHARE_SALE_FULFILLED,
      payload,
    });
    yield put({
      type: definitions.BATCH_STATUS_FULFILLED,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: 'Compartilhamento removido com sucesso!',
      },
    });
  } catch (error) {
    yield put({
      type: definitions.DELETE_SHARE_SALE_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: { variant: NOTIFICATION_TYPE_ERROR, message: messages.MESSAGE_ERROR_COMMON_ERROR },
    });
  }
}

export function* fetchConselhos() {
  try {
    const fullPath = `${PERSON_PATH}/conselhos`;
    const payload = yield call(API.fetch, fullPath);
    yield put({
      type: definitions.FETCH_PERSON_CONSELHOS_FULFILLED,
      payload,
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_PERSON_CONSELHOS_REJECTED,
      payload: { error },
    });
  }
}

export function* fetchFreguesias(action) {
  try {
    const { id } = action.payload;
    const fullPath = `${PERSON_PATH}/conselhos/${id}/freguesias`;
    const payload = yield call(API.fetch, fullPath);
    yield put({
      type: definitions.FETCH_PERSON_FREGUESIAS_FULFILLED,
      payload,
    });
  } catch (error) {
    yield put({
      type: definitions.FETCH_PERSON_FREGUESIAS_REJECTED,
      payload: { error },
    });
  }
}

export function* sendClientInfo(action) {
  try {
    const { id } = action.payload;
    const fullPath = `${SCOPE_PATH}/${id}/send-client-info`
    const payload = yield call(API.fetch, fullPath, {});
    yield put({
      type: definitions.SEND_CLIENT_INFO_FULFILLED,
    });
  } catch (error) {
    yield put({
      type: definitions.SEND_CLIENT_INFO_REJECTED,
      payload: { error },
    });
  }
}


export default function* rootSaga() {
  yield all([
    takeLatest(definitions.DELETE_SCOPE, removeScopes),
    takeLatest(definitions.CHANGE_CLIENT_ID, patchClientId),
    takeLatest(definitions.CONFIRM_ALIGNMENT_MULTIPLE, confirmAligmentMultiple),
    takeLatest(definitions.CONFIRM_ALIGNMENT, confirmAligment),
    takeLatest(definitions.CONFIRM_SCOPE, confirmScope),
    takeLatest(definitions.CREATE_PERSON, createPerson),
    takeLatest(definitions.CREATE_PROCESS, createProcess),
    takeLatest(definitions.CREATE_SCOPE_BY_CLIENT_ID, createScopeByClientId),
    takeLatest(definitions.FETCH_ALL_OPEN_SCOPES, fetchAllOpenScopes),
    takeLatest(definitions.FETCH_ACCOMPANIMENT_BY_SALESMAN, fetchAccompanimentBySalesman),
    takeLatest(definitions.FETCH_CONFIRMATION_SALES, fetchConfirmationSales),
    takeLatest(definitions.INITIATE_PROCESSES_SALE, patchScopeAlignment),
    takeLatest(definitions.FETCH_HISTORIC_BY_SALESMAN, fetchHistoricBySalesman),
    takeLatest(definitions.FETCH_LEADS_BY_SALESMAN, fetchLeadsBySalesman),
    takeLatest(definitions.FETCH_SALES_BY_CLIENT, fetchClientSales),
    takeLatest(definitions.FETCH_ALL_LEADS, fetchAllLeads),
    takeLatest(definitions.FETCH_ALL_ACCOMPANIMENT, fetchAllAccompaniment),
    takeLatest(definitions.FETCH_ALL_HISTORIC, fetchAllHistory),
    takeLatest(definitions.FETCH_MODULAR_SALES_BY_CLIENT, fetchModularClientSales),
    takeLatest(definitions.FETCH_SALE_SCOPE_BY_SCOPE_ID, fetchScopeByClientId),
    takeLatest(definitions.FETCH_TO_ALIGN, toAlign),
    takeLatest(definitions.FETCH_TO_DOCUMENTATION, toDocumentation),
    takeLatest(definitions.REMOVE_DOCUMENT_FROM_PERSON, removeDocument),
    takeLatest(definitions.REMOVE_PERSON, removePerson),
    takeLatest(definitions.REMOVE_PROCESS, removeProcess),
    takeLatest(definitions.RESULTS_CLEAR, resultsClear),
    takeLatest(definitions.SALES_SEARCH, salesSearch),
    takeLatest(definitions.SEND_CONTRACT_EMAIL, sendContractEmail),
    takeLatest(definitions.SEND_DOCUMENTATION_EMAIL, sendDocumentationEmail),
    takeLatest(definitions.SHARE_SALE, postSharedSale),
    takeLatest(definitions.UPDATE_PERSON, updatePerson),
    takeLatest(definitions.UPDATE_PROCESS, updateProcess),
    takeLatest(definitions.UPDATE_SALE_SCOPE_BY_ID, updateScopeById),
    takeLatest(definitions.UPDATE_SIGNED_CONTRACT, updateSignedContact),
    takeLatest(definitions.DELETE_SHARE_SALE, deleteSharedSale),
    takeLatest(definitions.FETCH_PERSON_CONSELHOS, fetchConselhos),
    takeLatest(definitions.FETCH_PERSON_FREGUESIAS, fetchFreguesias),
    takeLatest(definitions.SEND_CLIENT_INFO, sendClientInfo),
    takeLatest(definitions.CALCULATE_SALE_SCOPE_BY_ID, calculateScopeById),
  ]);
}
