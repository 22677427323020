import React from 'react';
import { connect } from 'react-redux';
import { ActionsContext } from 'contexts/actions';
import { useStyles } from 'service/style';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import { fetchConfirmations } from 'store/actions/sales';
import NoResults from 'global/modules/NoResults';
import { DEFAULT, FULFILLED } from 'constants/common';
import { LoadingComponent } from 'global/modules/Loading';
import ConfirmationRow from './Row';
import Header from './Header';

function VerificacaoList({
  update, fetch, confirmation, allSales, status, push,
}) {
  const classes = useStyles();
  const { reload } = React.useContext(ActionsContext);

  React.useEffect(() => {fetch();}, [update, fetch, reload]);

  return (
    <div className={classes.minWidthHolder}>
      <div className={classes.minWidthContainer}>
        <Header
          responseSize={allSales.length}
          filteredSize={confirmation.length}
        />
        <Container maxWidth="xl">
          {status === FULFILLED
            && confirmation.length === 0
            ? (
              <NoResults
                message="Nenhum processo encontrado"
              />
            ) : confirmation.map((item) => (
              <ConfirmationRow key={item.id} item={item} push={push} />
            ))}
          {status !== FULFILLED && (
            <LoadingComponent />
          )}
        </Container>
      </div>
    </div>
  );
}

VerificacaoList.propTypes = {
  update: PropTypes.string,
  status: PropTypes.string,
  confirmation: PropTypes.array,
  allSales: PropTypes.array,
  push: PropTypes.func.isRequired,
};

VerificacaoList.defaultProps = {
  status: DEFAULT,
  update: '',
  confirmation: [],
  allSales: [],
};

const mapStateToProps = (state) => ({
  confirmation: state.sales.collection,
  allSales: state.sales.allSales,
  status: state.sales.status,
});

export default connect(mapStateToProps, { fetch: fetchConfirmations })(VerificacaoList);
