import React, { useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Typography,
  InputBase,
  IconButton,
  ButtonGroup,
  Button,
  Icon,
  Grid,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  Close as CloseIcon,
  CheckBox as CheckboxIcon,
  CheckBoxOutlineBlank as CheckboxBlankIcon,
  Share,
} from '@material-ui/icons';
import { salesSearch, removeSales } from 'store/actions/sales';
import { useStyles } from '../style';
import { ASC, DESC } from 'constants/sales';
import { SaleDashboardContext } from 'contexts/saleDashboard';
import { Delete } from '@material-ui/icons';
import { showConfirmation } from 'store/actions/confirmations';
import { searchDeburr } from 'helpers';

function MasterTabHeader({
  title, tabKey, disableSortMenu, remove, defaultSort, itemsSort, confirmation, masterUser, ...props
}) {
  const classes = useStyles({});
  const { multiSelect, q, setSearch, sortBy, setSortBy, sortOrder, setSortOrder, clearSearchVariables, handleDialogShareSale } = React.useContext(SaleDashboardContext);
  const [sortMenu, setSortMenu] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const selecting = multiSelect.length > 0;
  const [sameValues, setSameValues] = React.useState(true);

  React.useEffect(() => {
    return () => {
      clearSearchVariables();
    };
    //eslint-disable-next-line
  }, []);

  const handleSort = () => {
    setSortOrder(sortOrder === ASC ? DESC : ASC);
  };

  const popMenu = (e) => (e ? e.currentTarget : null);

  const handleSortMenu = (e) => setSortMenu(popMenu(e));

  const handleCheckSort = (item) => {
    const newSort = item.value;
    setSortBy(newSort);
    handleSortMenu(false);
  };
  const handleUpdateSearch = () => {
    setSearch(searchDeburr(searchValue));
  };

  const handleSearchValue = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const clearSearch = () => {
    setSearch('');
    setSearchValue('');
  };

  const handleDeleteSales = () => {
    const confirmed = () => remove(multiSelect);
    confirmation(
      {
        title: `Remover venda${multiSelect.length > 1 ? 's' : ''}?`,
        message: <Typography variant="inherit" color="error">{'Observação: Esta ação não poderá ser desfeita!'}</Typography>,
      },
      {
        confirm: 'Remover',
        cancel: 'Cancelar',
      },
      {
        func: confirmed,
      },
    );
  };

  React.useEffect(() => {
    setSameValues(searchDeburr(searchValue) === searchDeburr(q));
  }, [searchValue, q]);

  React.useEffect(() => {
    setSortBy(defaultSort || 'id');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSort]);

  return (
    <Grid
      container
      spacing={2}
      className={classes.pageHeader}
      style={{ padding: 20 }}
    >
      <Menu
        id="sort-menu"
        anchorEl={sortMenu}
        keepMounted
        open={Boolean(sortMenu)}
        onClose={() => handleSortMenu(false)}
      >
        <MenuItem disabled>Ordenar</MenuItem>
        {itemsSort.map((item) => (
          <MenuItem
            key={`SortItem-${item.title}`}
            onClick={() => handleCheckSort(item)}
            dense
          >
            <ListItemIcon className={classes.menuItemIcon}>
              {(item.value === sortBy) ? <CheckboxIcon /> : <CheckboxBlankIcon />}
            </ListItemIcon>
            <ListItemText primary={item.title} />
          </MenuItem>
        ))}
      </Menu>
      <Grid item xs={4}>
        <Typography
          variant="h5"
          color="textSecondary"
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={8} className={classes.btnholder}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            {sameValues
              ? (
                <SearchIcon />
              ) : (
                <IconButton
                  onClick={handleUpdateSearch}
                  className="fa fa-reply"
                  size="small"
                  style={{
                    transform: 'scale3d(1, -1, 1)',
                  }}
                />
              )}
          </div>
          <InputBase
            placeholder="Buscar..."
            onChange={(e) => handleSearchValue(e)}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleUpdateSearch();
              }
            }}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            value={searchValue}
            endAdornment={(
              <IconButton
                onClick={(e) => clearSearch(e)}
                className={classes.inputCloseBtn}
                style={{ opacity: q ? 1 : 0 }}
                size="small"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          />
        </div>
        <ButtonGroup>
          <Tooltip
            title={disableSortMenu
              ? ''
              : 'Ordenar lista'}
          >
            <Button
              variant="contained"
              onClick={(e) => handleSortMenu(e)}
              classes={{
                root: classes.button,
                disabled: classes.visibleDisabledButton,
              }}
              aria-haspopup="true"
              disabled={disableSortMenu}
            >
              {disableSortMenu ? 'Ordenar' : null}
              {!disableSortMenu && itemsSort.find((a) => a.value === sortBy) ? itemsSort.find((a) => a.value === sortBy).title : 'Ordenar'}
            </Button>
          </Tooltip>
          <Tooltip
            title={`Ordenar lista por ordem ${sortOrder === ASC ? 'ascendente' : 'decrescente'}`}
          >
            <Button
              variant="contained"
              onClick={() => handleSort()}
              className={classes.buttonGroup}
              aria-label="Ascendente ou decrescente"
            >
              <Icon
                fontSize="small"
                className={`fas ${sortOrder === ASC ? 'fa-sort-amount-up' : 'fa-sort-amount-down'}`}
              />
            </Button>
          </Tooltip>
        </ButtonGroup>
      </Grid>
      <Grid item xs={12} className={clsx(classes.transition, selecting ? 'in' : 'out')}>
        <Grid container>
          <Grid item xs={12}>
            <Typography align="right">
              <Button
                variant="contained"
                color="secondary"
                className={classes.deleteButton}
                disabled={!selecting}
                onClick={handleDeleteSales}
                endIcon={<Delete />}
              >
                {`Remover venda${multiSelect.length > 1 ? 's' : ''}`}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!selecting}
                onClick={() => handleDialogShareSale(multiSelect)}
                endIcon={<Share />}
              >
                {`Compartilhar venda${multiSelect.length > 1 ? 's' : ''}`}
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

MasterTabHeader.propTypes = {
  itemsSort: PropTypes.arrayOf(PropTypes.object),
};

MasterTabHeader.defaultProps = {
  itemsSort: [
    {
      title: 'Nome',
      value: 'name',
    },
    {
      title: 'Etapa',
      value: 'salesStep',
    },
  ],
};

export default connect(null, { salesSearch, remove: removeSales, confirmation: showConfirmation })(MasterTabHeader);
