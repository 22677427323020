/* eslint-disable max-lines */
/* eslint-disable consistent-return */
// @ts-nocheck
import React, { useRef, useEffect } from 'react';
import { deburr } from 'lodash';
import * as imports from 'objects/imports';
import moment from 'moment';
import { DAYS_IN_YEAR, DAYS_IN_MONTH } from 'constants/common';
import { SERVER_TIMEZONE } from 'constants/session';
// Hook
export function useEventListener(eventName, handler, element = global) {
  // Create a ref that stores handler
  const savedHandler = useRef();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element], // Re-run if eventName or element changes
  );
}

export function ID() {
  return `_${Math.random().toString(36).substr(2, 9)}`;
}

export function deburrify(value) {
  return deburr(value).toLowerCase().replace(/ /g, '-');
}

export function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function genderize(value, gender) {
  let a = 'o';
  if (gender === 'Feminino') a = 'a';
  value = value.substr(0, value.length - 1) + a;
  return value;
}

export function capitalize(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function createMarkup(html) {
  return { __html: html };
}

export function maskThis(input, type) {
  function checkValue(str, max) {
    if (str.charAt(0) !== '0' || str === '00') {
      let num = parseInt(str, 10);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      str = num > parseInt(max.toString().charAt(0), 10) && num.toString().length === 1
        ? `0${num}`
        : num.toString();
    }
    return str;
  }

  let output;

  if (!type || type === 'date') {
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    const values = input.split('/').map((v) => v.replace(/\D/g, ''));

    if (values[0]) values[0] = checkValue(values[0], 31);
    if (values[1]) values[1] = checkValue(values[1], 12);
    output = values.map((v, i) => (v.length === 2 && i < 2 ? `${v} / ` : v));
    output = output.join('').substr(0, 14);
  } else if (type === 'cpf') {
    if (input.length <= 14) {
      output = input.toString().replace(/\D/g, '');
      output = output.replace(/(\d{3})(\d)/, '$1.$2');
      output = output.replace(/(\d{3})(\d)/, '$1.$2');
      output = output.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else { output = input.substr(0, 14); }
  } else if (type === 'cep') {
    if (input.length <= 9) {
      output = input.toString().replace(/\D/g, '');
      output = output.replace(/(\d{5})(\d{1,3})$/, '$1-$2');
    } else { output = input.substr(0, 9); }
  }
  return output;
}

export function ordinalInPortuguese(value) {
  const valuex = value.toString();
  let i = valuex.length - 1;
  let result = '';
  while (i >= 0) {
    if (i === valuex.length - 1) { result = imports.ordinalNumbers[1][valuex.charAt(i)] + (i === 0 ? '' : " " + result); }
    else { result = imports.ordinalNumbers[0][valuex.charAt(i)] + (i === 0 ? '' : " " + result); }
    i--;
  }
  return result;
}

export function regBuilder(value) {
  const r = value.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
  return new RegExp(r);
}

export function semanticDate(value, minified = false) {
  const { semanticMonths, shortenedMonths } = imports;
  const date = moment(value).tz(SERVER_TIMEZONE);
  const month = semanticMonths[date.month()];
  const monthSmaller = shortenedMonths[date.month()];
  const day = date.date();
  const year = `${date.year()}`;
  if (minified) {
    return (
      <>
        <span className="bp-xs">
          {`${day} ${monthSmaller} ${year.substring(2, 4)}`}
        </span>
        <span className="bp-md">
          {`${day} ${month} ${year}`}
        </span>
      </>
    );
  }
  return `${date.format('LL')}`;
}

export function limitChars(value, limit, ellipsis = '...') {
  return value.length > limit ? value.substring(0, limit) + ellipsis : value;
}

export function matchable(object, keys, valueToMatch) {
  let check = false;
  valueToMatch = new RegExp(regBuilder(valueToMatch), 'i');
  keys = typeof keys === 'object' && keys.length ? keys : [keys];
  keys.forEach((key) => {
    if (
      (
        object[key]
        && typeof object[key] === 'string'
        && Boolean(object[key].match(valueToMatch))
      ) || (
        object.length
        && object.find((a) => Boolean(a[key].match(valueToMatch)))
      )
    ) {
      check = true;
    }
  });

  return check;
}

export function byId(id) {
  return document.getElementById(id);
}

export function focusThis(id) {
  const el = id instanceof Element
    ? id
    : byId(id);
  if (el) el.focus();
}


export function value(id) {
  const el = id instanceof Element
    ? id
    : byId(id);
  return el ? el.value : '';
}

export function sortArrayofObjectsKeys(array) {
  let newArray = [];
  array.forEach((item) => {
    const ordered = {};
    Object.keys(item).sort().forEach((key) => {
      ordered[key] = item[key];
    });
    newArray = [...newArray, ordered];
  });
  return newArray;
}

export function remove(array, element) {
  return array.filter((el) => el !== element);
}


export function sortArrayOfObjectsByValue(array, key, order) {
  return array.sort((a, b) => {
    const valueA = a[key] ? typeof a[key] === 'string' ? a[key].toUpperCase() : a[key] : 0;
    const valueB = b[key] ? typeof b[key] === 'string' ? b[key].toUpperCase() : b[key] : 1;

    let comparison = 0;
    if (order) {
      if (valueA > valueB) {
        comparison = 1;
      } else if (valueA < valueB) {
        comparison = -1;
      }
    } else if (valueA < valueB) {
      comparison = 1;
    } else if (valueA > valueB) {
      comparison = -1;
    }
    return comparison;
  });
}

export function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

export function humanFileSize(size) {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(parseInt(size, 10) / Math.pow(1024, i)).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
}

export function toCurrency(value, currency) {
  return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: currency || 'BRL' });
}

export function integer(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function validaCPF(value) {
  let Soma;
  let Resto;
  Soma = 0;
  const strCPF = value.replace(/\D/g, '');

  if (strCPF === '00000000000') return false;

  for (let i = 1; i <= 9; i++) Soma += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11)) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++) Soma += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto === 10) || (Resto === 11)) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11), 10)) return false;
  return true;
}

export function displayTime(days) {
  if (!days) return '';
  const resto = days % DAYS_IN_YEAR;
  const years = Math.floor(days / DAYS_IN_YEAR);
  const months = Math.floor(resto / DAYS_IN_MONTH);
  const displayYears = years > 1 ? `${years} anos` : `${years} ano`;
  const displayMonths = months > 1 ? `${months} meses` : `${months} mês`;
  const ampersand = years > 0 && months > 0 ? 'e ' : '';
  return `${years > 0 ? `${displayYears} ` : ''}${months > 0 ? `${ampersand}${displayMonths}` : ''}`;
}

export function dText(value) {
  return deburr(value).toLowerCase();
}

export function convertDate(value) {
  return new Date(value).toLocaleDateString();
}

export function stopPropagation(event) {
  event.preventDefault();
  event.stopPropagation();
  event.nativeEvent.stopImmediatePropagation();
}
