/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Confirmations from 'global/confirmations';
import { UserContext } from 'contexts/user';
import LoaderProvider from 'providers/loader';
import SaleForm from 'sales/form';
import Contract from 'global/Contract';
import Contracts from 'global/Contract/List';
import Verificacao from 'service/Verificacao';
import Account from './account';
import Service from '../service';
import Faq from './Faq';
import ClientDashboard from '../client/Dashboard';
import Payments from '../client/Payments';
import Sales from '../sales/dashboard';
import Results from '../search';
import Users from '../users';
import ReRouteUser from './ReRouteUser';
import Products from './Products';
import ProductRequirements from './ProductRequirements';
import Portugueses from 'client/Portugueses';
import PortuguesesCheck from 'client/PortuguesesCheck';

function Conditional({ route, condition, ...s }) {
  if (!condition) return <ReRouteUser {...s} />;
  return route;
}

function Body() {
  return (
    <UserContext.Consumer>
      {({
        user,
        isMaster,
        isManager,
        userCoordinator,
        userInternal,
        userSales,
        userCustomer,
        userService,
      }) => (
          <LoaderProvider>
            <Switch>
              {/* * CLIENT ROUTES * */}
              <Route exact path="/pagamentos" render={(s) => <Conditional condition={isMaster} {...s} route={<Payments {...s} />} />} />

              <Route exact path="/portugueses" render={(s) => <Conditional condition={isMaster} {...s} route={<Portugueses {...s} />} />} />

              <Route exact path="/portugueses/check" render={(s) => <Conditional condition={isMaster} {...s} route={<PortuguesesCheck {...s} />} />} />

              <Route exact path="/client" render={(s) => <Conditional condition={isMaster} {...s} route={<ClientDashboard {...s} />} />} />

              <Route exact path="/editar-processos" render={(s) => <Conditional condition={isManager || isMaster} {...s} route={<Products {...s} />} />} />

              <Route exact path="/contrato/:id" render={(s) => <Contract {...s} />} />
              <Route exact path="/contrato" render={(s) => <Contracts {...s} />} />

              <Route exact path="/processos" render={(s) => <Conditional condition={isMaster || userInternal} {...s} route={<ProductRequirements {...s} />} />} />

              {/* * Serviço * */}
              <Route exact path={['/servico', '/servico/:tab', '/servico/:tab/:subtab']} render={(s) => <Conditional {...s} condition={userService} route={<Service {...s} isAuth="true" user={user} />} />} />
              <Route exact path={['/verificacao', '/verificacao/:internalId']} render={(s) => <Conditional {...s} condition={userService} route={<Verificacao {...s} />} />} />

              {/* *  * */}
              <Route exact path="/conta" render={(s) => <Account {...s} />} />
              <Route exact path="/faq" render={(s) => <Faq {...s} isAuth="true" user={user} />} />

              {/* * Venda * */}
              <Route exact path={['/venda', '/venda/:tab']} render={(s) => <Conditional condition={userSales || isManager} {...s} route={<Sales {...s} />} />} />
              <Route exact path={['/nova-venda/', '/nova-venda/:client']} render={(s) => <Conditional condition={userSales || isManager} {...s} route={<SaleForm {...s} />} />} />

              {/* BUSCA INTERNA */}
              <Route exact path={['/buscar', '/buscar/*']} render={(s) => <Conditional condition={userInternal || isManager} {...s} route={<Results {...s} />} />} />

              {/* MASTER */}
              <Route exact path="/usuarios" render={(s) => <Conditional condition={isMaster || isManager} route={<Users {...s} />} {...s} />} />

              {/* <Route component={Error} /> */}
              <Route render={(s) => <ReRouteUser {...s} />} />
            </Switch>
            <Confirmations />
          </LoaderProvider>
        )}
    </UserContext.Consumer>
  );
}

export default Body;
