import React, { useEffect } from 'react';
import {
  Paper,
  Container,
  makeStyles,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableSortLabel,
  TableHead,
  TableBody,
  Typography,
  TablePagination,
  Tooltip,
  Button,
  Link as ULink,
  ButtonGroup,
  Modal,
  Fade,
  TextField,
  MenuItem,
  Grid,
  Menu
} from '@material-ui/core/';
import Root from 'global/modules/Root';
import { connect } from 'react-redux';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Create from '@material-ui/icons/Create';
import AddIcon from '@material-ui/icons/Add';
import 'date-fns';
import DescriptionIcon from '@material-ui/icons/Description';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PortuguesesPageHeader from 'global/PortuguesesPageHeader';
import { fetchPortugueses } from 'store/actions/people';
import { deleteDocument } from 'store/actions/documents';
import { updatePerson } from 'store/actions/sales';
import { fetchFreguesiasByConselhos } from 'store/actions/sales';
import FileBox from 'global/filebox';
import { SEARCH_STATUS_LIST } from 'constants/people';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(0, 4),
    marginBottom: theme.spacing(1),
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'relative',
  },
  body: {
    padding: theme.spacing(1.5, 4),
    margin: theme.spacing(1.5, 0),
    position: 'relative',
    minHeight: theme.spacing(8),
    transition: theme.transitions.create('min-height'),
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'start',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: '80%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5
  },
  row: {
    flexGrow: 1,
  }
}));

function Portugueses({ loadPeople, portugueses, checkPeople, ...props }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('sales_scopes.created_at');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState('');
  const [processing, setProcessing] = React.useState(false);
  const [checked, setChecked] = React.useState(null);
  const [searchStatus, setSearchStatus] = React.useState('none');
  const [customerStatus, setClientStatus] = React.useState('none');
  const [documentationStatus, setDocStatus] = React.useState('none');
  const [personInEditMode, setPersonInEditModel] = React.useState(null);
  const [docsToAdd, setDocsToAdd] = React.useState([]);
  const [errors, setErrors] = React.useState([]);
  const baseFileAmount = (personInEditMode !== null && personInEditMode.doc !== undefined ? personInEditMode.docs.length : 0);
  const [anchorEl, setAnchorEl] = React.useState({});
  const open = Boolean(anchorEl);

const handleClickDocsButton = (event, docId) => {
  setAnchorEl(prevState => ({
    ...prevState,
    [docId]: event.currentTarget
  }));
};

const handleCloseDocsButton = (docId) => {
  setAnchorEl(prevState => ({
    ...prevState,
    [docId]: null
  }));
};

  const handleAddDocs = () => {
    handleOnEditPersonData('docs', [...personInEditMode.docs, ...docsToAdd]);
    setDocsToAdd([]);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const headCells = [
    { id: 'sales_pt', numeric: true, disablePadding: false, label: 'PT vendas', orderable: false },
    { id: 'sales_scopes.created_at', numeric: true, disablePadding: false, label: 'Data Venda', orderable: true },
    { id: 'people.second_sale', numeric: false, disablePadding: false, label: 'Segunda venda', orderable: true },
    { id: 'certificates.name', numeric: false, disablePadding: false, label: 'Tipo de Certificado', orderable: true },
    { id: 'assento', numeric: false, disablePadding: false, label: 'Assento', orderable: true },
    { id: 'assento_year', numeric: false, disablePadding: false, label: 'Ano do Assento', orderable: false },
    { id: 'crc', numeric: false, disablePadding: false, label: 'CRC', orderable: false },
    { id: 'people.name', numeric: false, disablePadding: true, label: 'Nome do Português', orderable: true },
    { id: 'father_name', numeric: false, disablePadding: false, label: 'Nome do Pai', orderable: true },
    { id: 'mother_name', numeric: false, disablePadding: false, label: 'Nome da mãe', orderable: true },
    { id: 'birthdate', numeric: false, disablePadding: false, label: 'Data / Ano de Nascimento', orderable: true },
    { id: 'concelho', numeric: false, disablePadding: false, label: 'Concelho', orderable: true },
    { id: 'freguesia.name', numeric: true, disablePadding: true, label: 'Freguesia', orderable: false },
    { id: 'docs', numeric: true, disablePadding: true, label: 'Documentos', orderable: false },
    { id: 'search_status_name', numeric: false, disablePadding: true, label: 'Status Busca', orderable: false },
    { id: 'documentation_status', numeric: false, disablePadding: true, label: 'Status Documentação', orderable: false },
    { id: 'actions', numeric: false, disablePadding: false, label: '', orderable: false },
  ];

  const handleSearchPayment = (value) => {
    setSearch(value);
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event, newRowsPage) => setRowsPerPage(parseInt(event.target.value, 10));

  const handleOnCloseModal = () => setPersonInEditModel(null);

  const handleSubmitPerson = async () => {
    await handleProcessing(
      personInEditMode.sales_scope_id,
      personInEditMode.people_id,
      {
        second_sale: personInEditMode.second_sale,
        certificate_id: personInEditMode.certificate_id,
        name: personInEditMode.name,
        father_name: personInEditMode.father_name,
        mother_name: personInEditMode.mother_name,
        concelho_id: personInEditMode.concelho_id,
        freguesia_id: personInEditMode.freguesia_id,
        birthdate: personInEditMode.birthdate,
        checked: personInEditMode.checked,
        assento: personInEditMode.assento,
        assento_year: personInEditMode.assento_year,
        crc: personInEditMode.crc,
        docs: docsToAdd,
        search_status: personInEditMode.search_status
      }
    );
    setPersonInEditModel(null);
    setDocsToAdd([]);
  }

  const handleOnOpenModal = () => {

    if (personInEditMode.concelho_id !== null) {
        props.loadFreguesias(personInEditMode.concelho_id)
    }
  }

  const handleOnEditPersonData = (key, value) => {
    setPersonInEditModel({...personInEditMode, [key]: value});

    console.log(personInEditMode);
  }

  const handleProcessing = async (scopeId, personId, data) => {
    setProcessing(true);
    checkPeople({scopeId, personId, data});
    setTimeout(() => loadPeople(page, rowsPerPage, orderBy, order, search, checked, searchStatus, documentationStatus, customerStatus), 500);
    setProcessing(false)
  }

  const handleChecked = (event) => setChecked(event.target.value);
  const handleSearchStatus = (event) => setSearchStatus(event.target.value);
  const handleDocStatus = (event) => setDocStatus(event.target.value);
  const handleClientStatus = (event) => setClientStatus(event.target.value);

  const handleChangeConcelho = (id) => {
    props.loadFreguesias(id);
  }

  useEffect(() => {
    loadPeople(
      page,
      rowsPerPage,
      orderBy,
      order,
      search,
      checked,
      searchStatus,
      documentationStatus,
      customerStatus
    );
  }, [page, rowsPerPage, search, orderBy, order, checked, searchStatus, documentationStatus, customerStatus])

  const handleCheckedButtonTooltip = (row) => {

    if (row.checked === 'NOT_CHECKED') return 'Não conferido';
    if (row.checked === 'CHECKED') return 'Conferido';
    if (row.checked === 'NEED_REVIEW') return 'Atualizado recentemente. Aguaradando Revisão';
  }

  const handleCheckedButtonColor = row => {
    if (row.checked === 'NOT_CHECKED') return 'secondary';
    if (row.checked === 'CHECKED') return 'primary';
    if (row.checked === 'NEED_REVIEW') return 'warning';
  }

  const handleDeleteDoc = async (id) => {
    handleCloseDocsButton(id);
    if (window.confirm('Tem certeza que deseja apagar esse documento?')) {
      setProcessing(true);
      await props.deleteDocument(id);
      setTimeout(() => loadPeople(page, rowsPerPage, orderBy, order, search, checked, searchStatus, documentationStatus, customerStatus), 500);
      setProcessing(false)
    }
  }

  return (
    <Root header footer paddingTop>
      <PortuguesesPageHeader
        title="Portugueses"
        searchValue={search}
        onSearch={handleSearchPayment}
        checked={checked}
        onChangeChecked={handleChecked}
        docStatus={documentationStatus}
        onChangeDocStatus={handleDocStatus}
        clientStatus={customerStatus}
        onChangeClientStatus={handleClientStatus}
        searchStatus={searchStatus}
        onChangeSearchStatus={handleSearchStatus}
      />
      <Container maxWidth="xl">
        <Paper>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align="center"
                      padding="default"
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={() => headCell.orderable ? handleRequestSort(headCell.id) : null}
                      >
                      {headCell.label}
                        {orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {portugueses.data.map(row => (
                  <TableRow id={row.id} key={`people-${row.people_id}`}>
                    <TableCell padding="default">
                      <Typography variant="body1" color="textSecondary">{row.sales_pt}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                        <Typography variant="body1" color="textSecondary">{row.sales_date}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                    <Typography variant="body1" color="textSecondary">{row.second_sale ? 'Sim' : 'Não'}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <Typography variant="body1" color="textSecondary">{row.certificate_name}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <Typography variant="body1" color="textSecondary">{row.assento !== null ? row.assento : 'Não informado'}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <Typography variant="body1" color="textSecondary">{row.assento_year !== null ? row.assento_year : 'Não informado'}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <Typography variant="body1" color="textSecondary">{row.crc !== null ? row.crc : 'Não informado'}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <Typography variant="body1" color="textSecondary">{row.name}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <Typography variant="body1" color="textSecondary">{row.father_name}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <Typography variant="body1" color="textSecondary">{row.mother_name}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <Typography variant="body1" color="textSecondary">{row.birthdate}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <Typography variant="body1" color="textSecondary">{row.concelho_id !== null ? row.concelho : 'Não informado'}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <Typography variant="body1" color="textSecondary">{row.freguesia_id !== null ? row.freguesia : 'Não informado'}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <ButtonGroup size="small" aria-label="small outlined button group">
                      {row.documents.map(doc => (
                        <div title={doc.name} key={`doc-${doc.id}`}>
                          <Button
                            id={`doc-btn-${doc.id}`}
                            aria-controls={open ? `doc-menu-${doc.id}` : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={(e) => handleClickDocsButton(e, doc.id)}
                          >
                            <DescriptionIcon />
                          </Button>
                          <Menu
                            id={`doc-menu-${doc.id}`}
                            aria-labelledby={`doc-btn-${doc.id}`}
                            anchorEl={anchorEl[doc.id]}
                            open={Boolean(anchorEl[doc.id])}
                            onClose={()=>handleCloseDocsButton(doc.id)}
                          >
                            <MenuItem key={Math.random()} onClick={() => { window.open(doc.url, '_blank'); handleCloseDocsButton(doc.id) }}>Visualizar</MenuItem>
                            <MenuItem key={Math.random()} onClick={() => handleDeleteDoc(doc.id)}>Remover</MenuItem>
                          </Menu>
                      </div>
                      ))}
                      </ButtonGroup>
                    </TableCell>
                    <TableCell padding="default">
                      <Typography variant="body1" color="textSecondary">{row.search_status_name}</Typography>
                    </TableCell>
                    <TableCell padding="default">
                      <Typography variant="body1" color="textSecondary">{row.documentation_status ? 'OK' : 'Não OK'}</Typography>
                    </TableCell>

                    <TableCell padding="default">
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                    <Tooltip title={handleCheckedButtonTooltip(row)}>
                        <Button
                          variant="outlined"
                          color={handleCheckedButtonColor(row)}
                          onClick={() => handleProcessing(row.sales_scope_id, row.people_id, { checked: row.checked === 'NOT_CHECKED' ? 'CHECKED' : 'NOT_CHECKED' })}
                        >
                          {row.checked == 0 ? (<CheckCircleOutlineIcon />) : (<CheckCircleIcon />)}
                        </Button>
                      </Tooltip>
                      <Tooltip title="Editar Dados do português">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setPersonInEditModel(row)}
                        >
                          <Create />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Adicionar português a venda">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => window.open('/nova-venda/' + row.sales_scope_id, '_blank')}
                        >
                          <AddIcon />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={portugueses.total}
            rowsPerPage={portugueses.per_page}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Container>
      <Modal
        open={personInEditMode !== null}
        onClose={handleOnCloseModal}
        onRendered={handleOnOpenModal}
        className={classes.modal}
      >
        <Fade in={personInEditMode !== null}>
          <div className={classes.paper}>
            <Grid container spacing={2} className={classes.row}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" style={{marginBottom: 40}}>
                  Editar dados do português
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Segunda Venda?"
                  variant="outlined"
                  select
                  value={personInEditMode ? personInEditMode.second_sale : 0}
                  onChange={(e) => handleOnEditPersonData('second_sale', e.target.value)}
                  style={{marginBottom: 20}}
                >
                  <MenuItem value={1}>Sim</MenuItem>
                  <MenuItem value={0}>Não</MenuItem>
                </TextField>

                <TextField
                  fullWidth
                  label="Nome do português"
                  variant="outlined"
                  value={personInEditMode ? personInEditMode.name : ''}
                  onChange={(e) => handleOnEditPersonData('name', e.target.value)}
                  style={{marginBottom: 20}}
                />

                <TextField
                  fullWidth
                  label="Data ou ano de nascimento do português"
                  variant="outlined"
                  value={personInEditMode ? personInEditMode.birthdate : ''}
                  onChange={(e) => handleOnEditPersonData('birthdate', e.target.value)}
                  style={{marginBottom: 20}}
              />

              <TextField
                fullWidth
                label="Nome do Pai"
                variant="outlined"
                value={personInEditMode ? personInEditMode.father_name : ''}
                onChange={(e) => handleOnEditPersonData('father_name', e.target.value)}
                style={{marginBottom: 20}}
              />

              <TextField
                fullWidth
                label="Nome da mãe"
                variant="outlined"
                value={personInEditMode ? personInEditMode.mother_name : ''}
                onChange={(e) => handleOnEditPersonData('mother_name', e.target.value)}
                style={{marginBottom: 20}}
              />

              <TextField
                  fullWidth
                  label="Assento"
                  variant="outlined"
                  value={personInEditMode ? personInEditMode.assento : ''}
                  onChange={(e) => handleOnEditPersonData('assento', e.target.value)}
                  style={{marginBottom: 20}}
                />

              <TextField
                fullWidth
                label="Ano do assento"
                variant="outlined"
                value={personInEditMode ? personInEditMode.assento_year : ''}
                onChange={(e) => handleOnEditPersonData('assento_year', e.target.value)}
                style={{marginBottom: 20}}
              />

              <TextField
                fullWidth
                label="Status de busca"
                variant="outlined"
                select
                value={personInEditMode ? personInEditMode.search_status : ''}
                onChange={(e) => handleOnEditPersonData('search_status', e.target.value)}
                style={{marginBottom: 20}}
              >
                  <MenuItem value={null}>Selecione</MenuItem>
                  {SEARCH_STATUS_LIST.map((status) => (
                    <MenuItem key={status.value} value={status.value}>{status.name}</MenuItem>
                  ))}
                </TextField>

              </Grid>
              <Grid item xs={12} sm={6}>

                <TextField
                  fullWidth
                  label="CRC"
                  variant="outlined"
                  value={personInEditMode ? personInEditMode.crc : ''}
                  onChange={(e) => handleOnEditPersonData('crc', e.target.value)}
                  style={{marginBottom: 20}}
                />

                <TextField
                  fullWidth
                  label="Nome do Pai"
                  variant="outlined"
                  value={personInEditMode ? personInEditMode.father_name : ''}
                  onChange={(e) => handleOnEditPersonData('father_name', e.target.value)}
                  style={{marginBottom: 20}}
                />

                <TextField
                  fullWidth
                  label="Nome da mãe"
                  variant="outlined"
                  value={personInEditMode ? personInEditMode.mother_name : ''}
                  onChange={(e) => handleOnEditPersonData('mother_name', e.target.value)}
                  style={{marginBottom: 20}}
                />

                <TextField
                  fullWidth
                  label="Certificado"
                  variant="outlined"
                  select
                  value={personInEditMode ? personInEditMode.certificate_id : ''}
                  onChange={(e) => handleOnEditPersonData('certificate_id', e.target.value)}
                  style={{marginBottom: 20}}
                >
                  {props.certificates.filter((e) => e.id !== 1).map((certificate) => (
                    <MenuItem key={certificate.id} value={certificate.id}>{certificate.name}</MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  label="Concelho"
                  variant="outlined"
                  select
                  value={personInEditMode ? personInEditMode.concelho_id : ''}
                  onChange={(e) => {
                    handleOnEditPersonData('concelho_id', e.target.value);
                    setTimeout(() => handleChangeConcelho(e.target.value))
                  }}
                  style={{marginBottom: 20}}
                >
                  {props.concelhos.map((concelho) => (
                    <MenuItem key={concelho.id} value={concelho.id}>{concelho.name}</MenuItem>
                  ))}
                </TextField>

                <TextField
                  fullWidth
                  label="Freguesia"
                  variant="outlined"
                  select
                  value={personInEditMode ? personInEditMode.freguesia_id : ''}
                  onChange={(e) => handleOnEditPersonData('freguesia_id', e.target.value)}
                  style={{marginBottom: 20}}
                >
                  {props.freguesias.map((freguesia) => (
                    <MenuItem key={freguesia.id} value={freguesia.id}>{freguesia.name}</MenuItem>
                  ))}
                </TextField>

                <FileBox
                  id="annex-pt-doc"
                  file={docsToAdd}
                  setFile={setDocsToAdd}
                  errors={errors}
                  setErrors={setErrors}
                  baseFileAmount={baseFileAmount}
                />
              </Grid>
            </Grid>

            <Button
              color="secondary"
              variant="outlined"
              style={{marginTop: 10, marginRight: 20}}
              onClick={handleOnCloseModal}
            >
              <Typography>Cancelar</Typography>
            </Button>
            <Button
              color="primary"
              variant="contained"
              style={{marginTop: 10}}
              onClick={handleSubmitPerson}
            >
              <Typography>Salvar dados</Typography>
            </Button>
          </div>
        </Fade>

      </Modal>
    </Root>
  )
}

const mapStateToProps = (state) => ({
  portugueses: state.people.people,
  certificates: state.certificates.collection,
  concelhos: state.sales.conselhos,
  freguesias: state.sales.freguesias,
});

const actions = {
  loadPeople: fetchPortugueses,
  checkPeople: updatePerson,
  loadFreguesias: fetchFreguesiasByConselhos,
  deleteDocument: deleteDocument
}

export default connect(mapStateToProps, { ...actions })(Portugueses);
