import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { convertToRaw } from 'draft-js';
import MUIRichTextEditor from 'mui-rte';
import draftToHtml from 'draftjs-to-html';

const EditDescriptionModal = ({ open, formInfo, setFormInfo, handleDescription, richContent, classes }) => {
  const [richState, setRichState] = React.useState(richContent);
  const handleChange = (data) => {
    setRichState(data);
  }
  const handleSaveDescription = (description) => {
    setFormInfo((o) => ({ ...o, description }));
  };
  const handleSave = () => {
    const rawState = convertToRaw(richState._immutable.currentContent);
    const markup = draftToHtml({
      ...rawState,
      blocks: rawState.blocks.map((b) => ({
        ...b,
        type: b.type === 'header-two' ? 'header-six' : b.type,
      })),
    }).replace(/<p><\/p>/g, '<p><br /></p>');
    handleSaveDescription(markup);
    handleDescription();
  };
  return (
    <Dialog
      fullWidth
      scroll="body"
      maxWidth="sm"
      open={open}
      onClose={handleDescription}
    >
      <DialogTitle>
        {`Editar descrição de ${formInfo.title}`}
      </DialogTitle>
      <DialogContent>
        <div className={clsx(classes.editor, 'smaller')}>
          <MUIRichTextEditor
            label={formInfo.description ? '' : 'Escreva uma descrição...'}
            controls={['title', 'bold', 'italic', 'underline', 'strikethrough', 'link', 'undo', 'redo', 'quote', 'OL', 'UL', 'clear']}
            inlineToolbar={true}
            inlineToolbarControls={['title', 'bold', 'italic', 'underline']}
            value={richContent}
            onChange={(data) => handleChange(data)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={handleDescription}>
          {'Cancelar'}
        </Button>
        <Button variant="outlined" color="primary" onClick={handleSave}>
          {'Editar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditDescriptionModal.propTypes = {
  open: PropTypes.bool,
  handleDescription: PropTypes.func.isRequired,
  formInfo: PropTypes.shape({}).isRequired,
  setLoading: PropTypes.func.isRequired,
};

EditDescriptionModal.defaultProps = {
  open: false,
};

export default EditDescriptionModal;
