import { returnValidDate } from 'helpers';
import _ from 'lodash';
import { format as formatCPF } from 'gerador-validador-cpf';

export const createPostObject = (form) => ({
  antProcess: '',
  comment: '',
  clientId: form.clientId,
  internalId: form.internalId,
  salesManId: form.salesmanId,
  client: {
    name: form.name,
    email: form.email,
    phone: form.phone,
    birthdate: returnValidDate(form.birthDate),
    gender: form.gender,
    cpf: formatCPF(form.cpf),
    rg: form.rg,
    emissor: form.emission,
    dispatch_date: returnValidDate(form.emissionDate),
    address1: form.address1,
    address2: form.address2,
    address3: form.address3,
    address4: form.address4,
    zip_code: form.zipCode,
    city: form.city,
    state: form.state,
    country: form.country,
  },
});

export const createFormFromURI = (object = {}) => ({
  clientId: object.pt,
  internalId: object.deal_id,
  name: _.startCase(_.toLower(object.nome_cli)),
  email: object.email_cli,
  salesmanId: object.id_vend,
  phone: object.phone || '',
  birthdate: object.birthdate || null,
  gender: object.gender || '',
  cpf: object.cpf || '',
  rg: object.rg || '',
  emissor: object.emissor || '',
  dispatch_date: object.dispatch_date || null,
  address1: object.address1 || '',
  address2: object.address2 || '',
  address3: object.address3 || '',
  address4: object.address4 || '',
  zip_code: object.zip_code || '',
  city: object.city || '',
  state: object.state || '',
  country: object.country || '',
});
