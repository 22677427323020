import React from 'react';
import { connect } from 'react-redux';
import { number, func } from 'prop-types';
import { Tabs, Paper, Tab } from '@material-ui/core';
import { salesMasterTabs } from 'constants/sales';
import { resultsClear } from 'store/actions/sales';
import * as $_ from 'global/functions';
import { useStylesMenu, minMenuItemWidth } from './style';
import { SaleDashboardContext } from 'contexts/saleDashboard';
import ActionsContext from 'contexts/actions';
import { FULFILLED } from 'constants/session';

function SalesDashboardMasterTabs({ selectedTab, onChangeTab, actionStatus, ...props }) {
  const classes = useStylesMenu();
  const { handleSelectMultiple, multiSelect } = React.useContext(SaleDashboardContext);
  const handleSelectTab = (event, tab) => onChangeTab(tab);

  const { reloadAll } = React.useContext(ActionsContext);

  React.useEffect(() => {
    if (actionStatus === FULFILLED && multiSelect.length) {
      reloadAll();
      handleSelectMultiple();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionStatus]);

  return (
    <Paper className={classes.masterRoot}>
      <Tabs
        value={selectedTab}
        onChange={handleSelectTab}
        classes={{
          indicator: classes.indicator,
        }}
        textColor="primary"
        TabIndicatorProps={{
          children: <div />,
        }}
        variant={
          salesMasterTabs.length * minMenuItemWidth > window.innerWidth ? 'scrollable' : 'fullWidth'
        }
      >
        {salesMasterTabs.map((itemMenu) => (
          <Tab
            key={`submenu-${itemMenu.name}`}
            component="a"
            onClick={(event) => {
              handleSelectMultiple();
              props.resultsClear();
              event.preventDefault();
            }}
            href={$_.deburrify(itemMenu.name)}
            className={classes.tab}
            aria-label={itemMenu.title}
            label={itemMenu.name}
            classes={{
              fullWidth: classes.tabRippleBg,
              wrapper: classes.tabWrapper,
            }}
          />
        ))}
      </Tabs>
    </Paper>
  );
}

SalesDashboardMasterTabs.propTypes = {
  selectedTab: number.isRequired,
  onChangeTab: func.isRequired,
};

const mapStateToProps = (state) => ({
  actionStatus: state.sales.actionStatus,
})

export default connect(mapStateToProps, { resultsClear })(SalesDashboardMasterTabs);
