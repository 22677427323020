import React from 'react';
import * as PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { connect } from 'react-redux';
import { Typography, Grid, Button } from '@material-ui/core';
import { isEqual } from 'lodash';
import { postFormPriceObj, getFormPriceObj } from '../functions';
import { batchUpdatePrice, addPriceToProduct, removePrice } from 'store/actions/product';
import { showConfirmation } from 'store/actions/confirmations';
import { FULFILLED, REJECTED } from 'constants/common';
import { ADD_MODE, EDIT_MODE } from 'constants/user';
import PriceRow from './Row';
import { checkCurrencyValue } from 'helpers';

const PriceInfo = ({
  formPrices,
  setFormPrices,
  product,
  classes,
  status,
  batchUpdate,
  createPrice,
  confirmation,
  setUpdate,
  remove,
  mode,
  newProduct,
}) => {
  const [loading, setLoading] = React.useState(false);
  const resetNew = (oldPrices) => {
    const newFormPrices = oldPrices.map((p) => {
      if (p.id === 'new') {
        return ({ ...getFormPriceObj({}), id: 'new' });
      }
      return p;
    });
    setFormPrices([...newFormPrices]);
  };
  const hasChanged = !isEqual(formPrices.filter((p) => p.id !== 'new'), [
    ...product.prices.map((price) => ({
      ...getFormPriceObj(price),
    })),
  ]);
  const handleUpdateAll = () => {
    const priceObjects = [];
    formPrices.filter((p) => p.id !== 'new').forEach((price) => {
      const originalPrice = product.prices.find((p) => p.id === price.id);
      if (!isEqual(getFormPriceObj(originalPrice), price)) {
        priceObjects.push(price);
      }
    });
    if (!newProduct) {
      confirmation(
        {
          title: 'Salvar alterações de preço',
          message: `Deseja salvar alterações nos valores do processo ${product.title}?`,
        },
        {
          confirm: 'Salvar',
          cancel: 'Cancelar',
        },
        {
          func: () => {
            priceObjects.forEach((price, i) => {
              batchUpdate(price.id, postFormPriceObj(price), (i + 1) === priceObjects.length);
            });
            setLoading(true);
          },
        },
      );
    } else {
      const updatePricesState = priceObjects.map((price) => ({ ...postFormPriceObj(price), id: uniqid() }));
      resetNew([...formPrices, ...updatePricesState]);
    }
  };
  const handleRemove = (id) => {
    if (!newProduct) {
      const thisPrice = formPrices.find((a) => a.id === id);
      confirmation(
        {
          title: 'Remover valor do processo',
          message: (
            <>
              <Typography variant="inherit">{`Deseja REMOVER o valor ${thisPrice.name} do processo ${product.title} do sistema?`}</Typography>
              <Typography variant="inherit" color="error">{' Observação: Esta ação não poderá ser desfeita!'}</Typography>
            </>
          ),
        },
        {
          confirm: 'Remover',
          cancel: 'Cancelar',
        },
        {
          func: () => {
            remove(id);
            setLoading(true);
          },
        },
      );
    } else {
      resetNew([...formPrices.filter((o) => o.id !== id)]);
    }
  };
  const handleSubmit = (e) => {
    const newPrice = formPrices.find((p) => p.id === 'new');
    if (!newProduct) {
      createPrice(product.id, postFormPriceObj(newPrice));
      setLoading(true);
    } else {
      resetNew([...formPrices, {
        ...postFormPriceObj({...newPrice}),
        price: checkCurrencyValue(newPrice.price),
        old_price: checkCurrencyValue(newPrice.old_price || 0),
        id: uniqid(),
      }]);
    }
    e.preventDefault();
  };
  React.useEffect(() => {
    setLoading((o) => {
      if ((status === FULFILLED || status === REJECTED) && o) {
        setUpdate(uniqid());
        return false;
      }
      return o;
    });
  }, [status, setUpdate]);
  const handleChange = (id, name, value) => {
    setFormPrices((p) => {
      const newPrices = p.map((a) => {
        if (a.id === id) {
          return ({ ...a, [name]: value });
        }
        return a;
      });
      return ([...newPrices]);
    });
  };

  const handleCents = (id, name, value) => {
    if (value !== '' && !value.includes(',')) {
      handleChange(id, name, `${value},00`);
    }
  };

  const spreadToRows = {
    handleCents,
    handleSubmit,
    handleChange,
    handleRemove,
    classes,
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {formPrices.length > 0 && formPrices.filter((p) => p.id !== 'new').map((price) => (
          <PriceRow key={`price${price.id}`} price={price} {...spreadToRows} />
        ))}
        <PriceRow price={formPrices.find((p) => p.id === 'new')} mode={ADD_MODE} {...spreadToRows} />
      </Grid>
      {!newProduct && (
        <Grid item xs={12}>
          <Typography style={{ position: 'absolute', right: 16, bottom: 16 }}>
            <Button
              variant={hasChanged ? 'contained' : 'outlined'}
              color={hasChanged ? 'primary' : 'secondary'}
              disabled={!hasChanged || loading}
              onClick={() => handleUpdateAll()}
            >
              {'Salvar alterações'}
            </Button>
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

PriceInfo.propTyoes = {
  formInfo: PropTypes.shape({}).isRequired,
  setFormInfo: PropTypes.func.isRequired,
  product: PropTypes.shape({}).isRequired,
  classes: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  update: PropTypes.string.isRequired,
  confirmation: PropTypes.func.isRequired,
  setUpdate: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  newProduct: PropTypes.bool,
  mode: PropTypes.oneOf([ADD_MODE, EDIT_MODE]),
};

PriceInfo.defaultProps = {
  mode: EDIT_MODE,
  newProduct: false,
};

const mapStateToProps = (state) => ({
  status: state.product.priceStatus,
});

const actions = {
  batchUpdate: batchUpdatePrice,
  confirmation: showConfirmation,
  remove: removePrice,
  createPrice: addPriceToProduct,
};

export default connect(mapStateToProps, actions)(PriceInfo);
