import React from 'react';
import { Typography, Grid, InputAdornment, IconButton, TextField } from '@material-ui/core';
import { RemoveCircle, AddCircle } from '@material-ui/icons';
import CurrencyField from 'global/fields/CurrencyField';
import { EDIT_MODE } from 'constants/user';

const PriceRow = ({ price, handleCents, mode = EDIT_MODE, handleSubmit, handleChange, handleRemove, classes, ...props }) => {
  return (
    <Typography paragraph component="div">
      <form onSubmit={handleSubmit} style={{ marginTop: 16 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              required
              fullWidth
              value={price.name}
              label="Título"
              onChange={(e) => handleChange(price.id, 'name', e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <CurrencyField
              value={price.price}
              required
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{ startAdornment: <InputAdornment position="start">R$</InputAdornment> }}
              label="Valor"
              className={classes.minwidthInput}
              onBlur={(e) => handleCents(price.id, 'price', e.target.value)}
              onKeyDown={(e) => { if (e.keyCode === 13) handleCents(); }}
              onChange={(e) => handleChange(price.id, 'price', e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <CurrencyField
              value={price.old_price}
              variant="outlined"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{ startAdornment: <InputAdornment position="start">R$</InputAdornment> }}
              label="Valor antigo"
              className={classes.minwidthInput}
              onBlur={(e) => handleCents(price.id, 'old_price', e.target.value)}
              onKeyDown={(e) => { if (e.keyCode === 13) handleCents(); }}
              onChange={(e) => handleChange(price.id, 'old_price', e.target.value)}
            />
          </Grid>
          <Grid item xs={2} className={classes.center}>
            {mode === EDIT_MODE
            ? (
              <IconButton size="small" onClick={() => handleRemove(price.id)}>
                <RemoveCircle fontSize="small" />
              </IconButton>
            ) : (
              <IconButton size="small" type="submit">
                <AddCircle fontSize="small" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </form>
    </Typography>
  )
};

export default PriceRow;
