export const AUTH_PATH = 'oauth/token';
export const SALES_PATH = 'process';
export const CERTIFICATES_PATH = 'certificates';
export const PRODUCT_PATH = 'products';
export const PRODUCT_SINGLE_PATH = 'product';
export const PERSON_PATH = 'people';
export const SCOPE_PATH = 'salesscope';
export const SCOPES_PATH = 'sales-scopes';
export const USER_PATH = 'user';
export const PRICE_PATH = 'price';
export const REQUIREMENT_PATH = 'requirement';
export const SEARCH_PATH = 'customers';
export const DOCUMENT_PATH = 'document';
export const PAYMENT_PATH = 'payment';
export const STEP_PATH = 'process-steps';
export const PROCESS_PATH = 'process';
export const COMMENT_PATH = 'comment';
export const BATCHPOST_PATH = 'batch-post';
export const BATCHPOSTS_PATH = 'batch-posts';
export const EUR_EXCHANGE_URL = 'https://api.exchangeratesapi.io/latest';
