import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { object, func } from 'prop-types';

function FormConsulate({ formfields, handleUpdateForm }) {
  return (
    <>
      <Grid item xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          name="document_number"
          label="Número de assento"
          value={formfields.document_number}
          onChange={(e) => handleUpdateForm('document_number', e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          name="assento_year"
          label="Ano do assento"
          value={formfields.assento_year}
          onChange={(e) => handleUpdateForm('assento_year', e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          name="crc"
          label="CRC"
          value={formfields.crc}
          onChange={(e) => handleUpdateForm('crc', e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          name="birthplace"
          label="Naturalidade"
          value={formfields.birthplace}
          onChange={(e) => handleUpdateForm('birthplace', e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          name="consulate"
          label="Consulado"
          value={formfields.consulate}
          onChange={(e) => handleUpdateForm('consulate', e.target.value)}
        />
      </Grid>
    </>
  );
}

FormConsulate.propTypes = {
  formfields: object.isRequired,
  handleUpdateForm: func.isRequired,
};

export default FormConsulate;
