/* eslint-disable react/prop-types */
import React from 'react';
import {
  Grid, Typography, FormControlLabel, Collapse, Checkbox, List,
} from '@material-ui/core';
import DocRow from './DocRow';

function ExpandContent({
  classes,
  process,
  handleCheckDocs,
  setToConfirm,
  allChecked,
  thisexpanded,
  toConfirm,
  setRefetch,
  cascade,
}) {
  const [nonApplicables, setNonApplicables] = React.useState([]);
  const [documentationDocs, setDocumentationDocs] = React.useState([]);
  React.useEffect(() => {
    setDocumentationDocs(process.documentationDocs);
  }, [process]);

  React.useEffect(() => {
    setNonApplicables(process.notApplicableDocuments || []);
  }, [process, cascade]);

  return (
    <Collapse in={thisexpanded}>
      <Grid className={classes.bodyExpand} style={{ paddingTop: 24 }} container spacing={2}>
        <Grid item xs={12}>
          {documentationDocs && documentationDocs.length > 1 && (
            <>
              {process.allArrived
                ? (
                  <Typography variant="subtitle2">
                    <strong>
                      {'Documentos'}
                    </strong>
                  </Typography>
                ) : (
                  <FormControlLabel
                    control={(
                      <Checkbox
                        name="applicant"
                        checked={process.allArrived || allChecked}
                        classes={{ disabled: classes.checkboxDisabled, checked: classes.checked }}
                        onChange={() => handleCheckDocs()}
                      />
                    )}
                    classes={{ root: classes.list }}
                    label={(
                      <Typography variant="subtitle2">
                        <strong>
                          {'Documentos'}
                        </strong>
                      </Typography>
                    )}
                  />
                )}
            </>
          )}
          {documentationDocs && documentationDocs.length > 0 && (
            <List style={{ minWidth: 720, width: '45%' }}>
              {documentationDocs.map((document) => (
                <DocRow
                  key={document.id}
                  processId={process.id}
                  nonApplicables={nonApplicables}
                  setRefetch={setRefetch}
                  document={document}
                  allArrived={process.allArrived}
                  classes={classes}
                  setToConfirm={setToConfirm}
                  toConfirm={toConfirm}
                  cascade={cascade}
                />
              ))}
            </List>
          )}
        </Grid>
      </Grid>
    </Collapse>
  );
}

export default ExpandContent;
