import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { logger } from 'redux-logger';
import reducer from 'store/reducers';
import rootSaga from 'store/sagas';
import { encryptedRoute } from 'helpers';
import * as notificationDefinitions from 'store/definitions/notifications';
import { NOTIFICATION_TYPE_ERROR } from 'constants/notification';
import { MESSAGE_AUTH_ERROR } from 'constants/common/messages';
import { LOGOUT } from './definitions/session';

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory({ basename: process.env.REACT_APP_BASE_URL });
const reduxRouterMiddleware = routerMiddleware(history);

const authInterceptor = ({ dispatch }) => (next) => (action) => {
  const { pathname } = history.location;
  if (action.payload
    && action.payload.error
    && action.payload.error.message
    && action.payload.error.message.includes('401')
    && !pathname.includes('login')) {
    const encrypted = encryptedRoute(pathname, 'login');
    dispatch({ type: LOGOUT, payload: { encrypted } });
    dispatch({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_ERROR,
        message: MESSAGE_AUTH_ERROR,
      },
    });
  } else next(action);
};

let middleware = [
  sagaMiddleware,
  reduxRouterMiddleware,
  authInterceptor,
];

if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware, logger]
}

const store = createStore(
  reducer(history),
  compose(
    applyMiddleware(...middleware)
  ),
);
sagaMiddleware.run(rootSaga);

export default store;
