import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CopyElement from 'global/CopyElement';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Call';
import { Grid, Typography, IconButton, Tooltip } from '@material-ui/core';
import { useStyles } from '../../sales/dashboard/style';
import { Edit } from '@material-ui/icons';
import { DIALOG_EDIT_CUSTOMER } from 'constants/dialogs';
import { updateDialog } from 'store/actions/dialogs';
import { stopPropagation } from 'global/functions';
import { readableBRPhoneNumbers } from 'helpers';

function Contact({
  item, customer, short = false, editable = false, vertical = false, displayProcess, processTitle, dialog, ...props
}) {
  const classes = useStyles(props);
  let phoneNumber = '';
  if (customer && customer.phone) phoneNumber = readableBRPhoneNumbers(customer.phone);
  const openEditDialog = (e) => {
    stopPropagation(e);
    dialog({
      mode: DIALOG_EDIT_CUSTOMER,
      open: true,
      currentCustomer: customer,
    });
  };
  const verticalStyle = vertical ? { marginBottom: 8 } : { };
  return (
    <Grid container spacing={2} style={verticalStyle}>
      <Grid item xs={short || vertical ? 12 : 6} className={classes.flex}>
        <div className={classes.hiddenHolder}>
          {editable && (
            <div className="hide">
              <Tooltip title="Editar informações">
                <IconButton size="small" onClick={(e) => openEditDialog(e)}>
                  <Edit fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
          )}
          <div>
            {displayProcess && processTitle && <Typography color="textSecondary">{processTitle}</Typography>}
            <Typography variant={vertical ? 'h6' : 'body1'}>
              <CopyElement>{customer.name}</CopyElement>
            </Typography>
            <Typography variant={vertical ? 'body2' : 'body1'}>
              <CopyElement color="textSecondary" value={item.client_id}>
                {item.client_id}
              </CopyElement>
            </Typography>
          </div>
        </div>
      </Grid>
      {!short && (
        <Grid item xs={vertical ? 12 : 6} className={classes.flex}>
          <Typography className={classes.pLine} variant="body1" color="textSecondary">
            <EmailIcon className={classes.icon} color="disabled" fontSize="inherit" />
            <CopyElement>{customer.email}</CopyElement>
          </Typography>
          <Typography className={classes.pLine} variant="body1" color="textSecondary">
            <PhoneIcon className={classes.icon} color="disabled" fontSize="inherit" />
            <CopyElement>{phoneNumber}</CopyElement>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

Contact.defaultProps = {
  short: false,
  displayProcess: false,
  editable: false,
  processTitle: '',
  vertical: false,
};

Contact.propTypes = {
  vertical: PropTypes.bool,
  displayProcess: PropTypes.bool,
  short: PropTypes.bool,
  editable: PropTypes.bool,
  processTitle: PropTypes.string,
  dialog: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
};

export default connect(null, { dialog: updateDialog })(Contact);
