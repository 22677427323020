import React, { useEffect, useContext } from 'react';
import { SalesContext } from 'contexts/sales';
import {
  Container,
} from '@material-ui/core';
import Payment from './Payment';


function SaleFormStepFour(props) {
  const salesContext = useContext(SalesContext);

  useEffect(() => {
    const allowNext = (salesContext.paymentConfirmed);
    salesContext.handleStepOk(salesContext.currentStep, allowNext);
  // eslint-disable-next-line
  }, [salesContext.paymentConfirmed]);

  return (
    <Container maxWidth="xl">
      <Payment />
    </Container>
  );
}

export default SaleFormStepFour;
