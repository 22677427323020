import React from 'react';
import uniqid from 'uniqid';
import { ordinalInPortuguese } from 'global/functions';
import { Paper, Typography, Grid } from '@material-ui/core';

function Products({ scope, phases }) {
  return (
    <Paper elevation={3}>
      <Typography variant="h5" color="textPrimary" paragraph>
        {`Processo${scope.processes.length > 1 ? 's' : ''} contratado${scope.processes.length > 1 ? 's' : ''}`}
      </Typography>
      {Object.keys(phases).map((phase, index) => (
        <Grid container key={uniqid()}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" style={{ marginTop: index === 0 ? 0 : 30 }}>{`${ordinalInPortuguese(index + 1)} fase`}</Typography>
          </Grid>
          {phases[phase].map(process => (
            <Grid item key={uniqid()} xs={12}>
              <Typography>
                {`${process.products[0].title} - ${process.applicant.name}`}
              </Typography>
            </Grid>
          ))}
        </Grid>
      ))}
    </Paper>
  )
}

export default Products;