/* eslint-disable max-lines */
// @ts-nocheck
/* eslint-disable no-mixed-operators */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import { SalesContext } from 'contexts/sales';
import { connect } from 'react-redux';
import {
  makeStyles,
  Grid,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  ButtonGroup,
  Button,
  TextField,
  MenuItem,
  Select,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { updateSaleScopeById } from 'store/actions/sales';
import { convertCurrency, stripValue } from 'helpers';
import uniqid from 'uniqid';
import { CANCELLED } from 'constants/common/payments';
import CurrencyField from 'global/fields/CurrencyField';
import PaymentRow from './PaymentRow';

const useStyles = makeStyles(() => ({
  minwidthInput: {
    minWidth: 250,
  },
}));

function Payment({ scope, ...props }) {
  const classes = useStyles(props);
  const salesContext = useContext(SalesContext);
  const [familyPlan, setFamilyPlan] = useState(scope.family_plan);

  const payments = scope.payments.filter((payment) => payment.status !== CANCELLED);

  const filterProcessesNoCourtesy = scope.processes.filter((process) => !process.is_courtesy);

  let amountLeft = parseInt(scope.total, 10);
  payments.forEach((pay) => {
    amountLeft -= parseInt(pay.value, 10);
  });
  const totalDiscount = 99;
  const honoraries = scope.total_honorary;
  const totalHonorary = (Math.abs(scope.total_honorary / (scope.discount - 100)) * 100);
  const totalDiscountDollars = (`${((totalHonorary / 100 * totalDiscount) / 100).toFixed(2)}`).replace('.', ',');
  const scopeDiscount = scope.discount || '';
  const [discount, setDiscount] = useState(scopeDiscount);
  const discountedDollars = convertCurrency(totalHonorary / 100 * discount);
  const [discountDollars, setDiscountDollars] = useState(scope.discount === 0 ? '' : discountedDollars);
  const [discountOption, setDiscountOption] = useState('%');
  const [cashBack, setCashBack] = useState(false);
  const [showCashBackFields, setShowCashBackFields] = useState(false)

  const handleFocus = (w) => {
    if (w) {
      if (!parseFloat(discountDollars)) setDiscountDollars('');
    } else {
      if (!parseFloat(discount)) setDiscount('');
    }
  };

  const handleSetCashBack = event => {
    setCashBack(event.target.checked)

    if (!event.target.checked) {
      salesContext.setSaleKey('cash_back_percentage', '')
      props.updateSaleScopeById(scope.id, scope.client_id, { cash_back_percentage: null })
    }
  }

  const handleChangeDollars = (value) => {
    setDiscountDollars(value);
  };
  
  const handleRequestDiscount = (newDiscount) => (
    props.updateSaleScopeById(scope.id, scope.client_id, { discount: newDiscount || discount })
  );
  
  const handleDiscountOption = (option) => setDiscountOption(option);
  
  const calculateDiscount = (valueInDollars, newDiscount) => {
    const percentage = newDiscount || discount;
    const dollars = valueInDollars || discountDollars;
    setDiscountDollars(dollars);
    setDiscount(percentage);
    handleRequestDiscount(percentage);
  };

  const handleCents = (value) => {
    const valueToHandle = value || discountDollars;
    let newValue = `${valueToHandle}`;
    if (newValue !== '' && !newValue.includes(',')) {
      newValue += ',00';
    }
    if (stripValue(newValue) > stripValue(totalDiscountDollars)) {
      newValue = convertCurrency(stripValue(totalDiscountDollars), false);
    }
    const cashbackDiscountAmount = scope.cash_back_discount_amount === null ? scope.cash_back_discount_amount : 0;
    const newDiscount = ((stripValue(newValue) / (honoraries + cashbackDiscountAmount)) * 100).toFixed(3);
    calculateDiscount(newValue, newDiscount);
  };

  const getTargetItereset = () => scope.isCustomerPayInterest ? ' (Adicionado para o cliente)' : ' (Retirado do vendedor)';

  const handeDiscountPercentage = () => calculateDiscount(discountedDollars, discount);

  const handleChangeDiscount = (value) => {
    const newValue = value < totalDiscount ? value : totalDiscount;
    setDiscount(newValue);
  };

  const handleSelectFamilyPlan = (e) => {
    salesContext.setSaleKey('family_plan', e.target.value)
    setFamilyPlan(e.target.value)
    props.updateSaleScopeById(scope.id, scope.client_id, { family_plan: e.target.value })

    // if (e.target.value === 'NONE' || e.target.value === 'INITIAL_PORTUGUESE_FAMILY') {
    //   setShowCashBackFields(true);
    // } else {
    //   setShowCashBackFields(false);
    // }
  }

  const handleCashbackDiscount = (e) => {
    salesContext.setSaleKey('cash_back_percentage', e.target.value)
    props.updateSaleScopeById(scope.id, scope.client_id, { cash_back_percentage: e.target.value })
  }

  useEffect(() => {
    salesContext.setPaymentConfirmed(amountLeft === 0);
    // eslint-disable-next-line
  }, [amountLeft]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" paragraph>Pagamento</Typography>
        <Paper elevation={3} style={{ display: 'inline-block' }}>
          <Grid container spacing={4}>
            <Grid item>
              <Typography variant="h6" paragraph>Valor da venda</Typography>
              <Typography variant="subtitle2">
                {'Valor parcial: '}
                {convertCurrency(scope.subtotal)}
              </Typography>
              <Typography variant="subtitle2">
                {'Honorários: '}
                {convertCurrency(scope.total_honorary)}
              </Typography>
              {Boolean(scope.interest_total) && (
                <Typography variant="subtitle2">
                  {'Total de juros: '}
                  {convertCurrency(scope.interest_total)}
                  {getTargetItereset()}
                </Typography>
              )}
              {scope.discount > 0 && (
                <Typography variant="subtitle2">
                  {'Desconto '}
                  <strong>{scope.discount}</strong>
                  {'%'}
                </Typography>
              )}
              {(scope.cash_back_discount_amount !== null && scope.cash_back_discount_amount > 0) && (
                <Typography variant="subtitle2">
                  {'Desconto taxas plano familia: '}
                  {convertCurrency(scope.cash_back_discount_amount)}
                </Typography>
              )}
              <Typography variant="subtitle1">
                <strong>
                  {'Total '}
                  {convertCurrency(scope.total)}
                </strong>
              </Typography>
              {(scope.cash_back_value !== null && scope.cash_back_value !== 0) && (
                <Typography variant="subtitle2">
                  {'Total de cashback: '}
                  {convertCurrency(scope.cash_back_value)}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <Grid container spacing={2} style={{ flexDirection: 'column' }}>
                <Grid item>
                  <Typography variant="h6">Aplicar desconto</Typography>
                </Grid>
                <Grid item>
                  <ButtonGroup size="small" aria-label="Opções de desconto">
                    <Button
                      variant={discountOption === '%' ? 'contained' : 'outlined'}
                      disabled={discountOption === '%'}
                      onClick={() => handleDiscountOption('%')}
                    >
                      {'Porcentagem'}
                    </Button>
                    <Button
                      variant={discountOption === '$' ? 'contained' : 'outlined'}
                      disabled={discountOption === '$'}
                      onClick={() => handleDiscountOption('$')}
                    >
                      {'Valor'}
                    </Button>
                  </ButtonGroup>
                </Grid>

                {discountOption === '%'
                  ? (
                    <Grid item>
                      <FormControl variant="outlined">
                        <InputLabel htmlFor="discount">Porcentagem</InputLabel>
                        <OutlinedInput
                          id="discount"
                          name="discount"
                          value={discount}
                          onChange={(e) => handleChangeDiscount(e.target.value)}
                          onBlur={() => handeDiscountPercentage()}
                          onFocus={() => handleFocus(false)}
                          onKeyDown={(e) => { if (e.keyCode === 13) handeDiscountPercentage(); }}
                          aria-describedby="discount"
                          className={classes.minwidthInput}
                          endAdornment={<InputAdornment position="end">%</InputAdornment>}
                        />
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item>
                      <CurrencyField
                        value={discountDollars}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{ startAdornment: <InputAdornment position="start">R$</InputAdornment> }}
                        label="Valor"
                        className={classes.minwidthInput}
                        onFocus={() => handleFocus(true)}
                        onBlur={() => handleCents()}
                        onKeyDown={(e) => { if (e.keyCode === 13) handleCents(); }}
                        onChange={(e) => handleChangeDollars(e.target.value)}
                      />
                    </Grid>
                  )}
              </Grid>
              <Grid item style={{marginTop: 20}}>
                <FormControl
                  variant="outlined"
                  required
                  fullWidth
                >
                  <InputLabel>Plano Familia</InputLabel>
                  <Select
                    value={familyPlan}
                    onChange={handleSelectFamilyPlan}
                    classes={{ selectMenu: classes.select }}
                  >
                    {filterProcessesNoCourtesy.length > 0 && (
                      <MenuItem value="NONE">Plano Cidadão</MenuItem>
                    )}
                    {filterProcessesNoCourtesy.length >= 3 && (
                      <MenuItem value="INITIAL_PORTUGUESE_FAMILY">Plano Origens</MenuItem>
                    )}
                    {filterProcessesNoCourtesy.length >= 5 && (
                      <MenuItem value="SMALL_PORTUGUESE_FAMILY">Plano Sucessões</MenuItem>
                    )}
                    {filterProcessesNoCourtesy.length >= 8 && (
                      <MenuItem value="BIG_PORTUGUESE_FAMILY">Plano Gerações</MenuItem>
                    )}
                    {/* <MenuItem value="PROMOTION_SMALL">Promoção (5%)</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item style={{marginTop: 20}}>
                { showCashBackFields ? (
                  <div>
                    <FormControlLabel
                      control={<Checkbox checked={cashBack} onChange={(e) => handleSetCashBack(e)} name="cashback" />}
                      label="Definir cashback"
                    />
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="discount">Porcentagem de cashback</InputLabel>
                        <OutlinedInput
                          id="cash_back_percentage"
                          name="cash_back_percentage"
                          value={salesContext.sale.cash_back_percentage}
                          onChange={handleCashbackDiscount}
                          aria-describedby="cash_back_percentage"
                          className={classes.minwidthInput}
                          disabled={!cashBack}
                          endAdornment={<InputAdornment position="end">%</InputAdornment>}
                        />
                      </FormControl>
                  </div>
                ) : (<div />)}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Grid container spacing={2}>
          {payments.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="h5">Pagamentos registrados</Typography>
            </Grid>
          )}
          {payments.filter((a) => a.month_number === 1).map((row) => (
            <Grid key={uniqid()} item xs={12}>
              <PaymentRow row={row} scope={scope} mode="EDIT" />
            </Grid>
          ))}
          {amountLeft > 0 && (
            <Grid item xs={12}>
              <Typography variant="h5" paragraph>Adicionar pagamento</Typography>
              <PaymentRow scope={scope} amountLeft={amountLeft} mode="ADD" />
            </Grid>
          )}
          {amountLeft <= 0 && (
            <Grid item xs={12} style={{ marginTop: -8, marginBottom: 16 }}>
              {amountLeft === 0
                ? <Typography variant="h5" color="primary">Pagamentos registrados!</Typography>
                : (
                  <Typography variant="h5" color="error">
                    {'Os pagamentos registrados excedem o total da venda em '}
                    <strong>{convertCurrency(amountLeft * -1)}</strong>
                    {'!'}
                  </Typography>
                )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  scope: state.sales.scope,
  status: state.payment.status,
});

export default connect(mapStateToProps, { updateSaleScopeById })(Payment);
