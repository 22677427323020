import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid, Collapse, TextField, MenuItem, Button, IconButton, InputAdornment,
} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {
  usersTitles,
  ADD_MODE,
  EDIT_MODE,
  userForm,
  PERSON,
  MASTER,
  INTERNAL,
  internalsTitles,
  INTERNAL_SALES,
} from 'constants/user';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import PhoneField from 'global/fields/PhoneField';
import { createUser } from 'store/actions/users';
import { useStyles } from './style';

function RowForm({
  user, view, expanded, mode, internalType, ...props
}) {
  const classes = useStyles();

  const addMode = mode === ADD_MODE;

  const initialValues = addMode ? userForm : { ...user, pipedrive_id: user.pipedrive_id || '' };

  const [form, setForm] = useState(initialValues);
  const [showPassword, setShowPassword] = useState(true);

  const handleSubmit = (e) => {
    props.createUser({ view, data: form });
    e.preventDefault();
  };
  const handleClear = (e) => { setForm(initialValues); e.preventDefault(); };

  const handleChange = (name, value) => {
    const obj = { ...form, [name]: value };
    if (name === 'email') obj.username = value;
    setForm({ ...obj });
  };

  const handleShowPassword = () => setShowPassword((o) => !o);

  const notEdited = isEqual(form, initialValues);

  return (
    <Collapse style={{ width: '100%' }} in={expanded}>
      <form onSubmit={handleSubmit} onReset={handleClear} className={clsx(classes.bodyContainer, 'editable')}>
        <Grid container spacing={2} justify="flex-start">
          <Grid item xs={12} md={2}>
            <TextField
              onChange={(e) => handleChange('user_type', e.target.value)}
              style={{ textAlign: 'left' }}
              select
              fullWidth
              variant="outlined"
              name="user_type"
              label="Tipo de usuário"
              value={form.user_type}
            >
              {view.map((type) => (
                <MenuItem
                  key={`usertype${type}`}
                  value={type}
                >
                  {usersTitles[type]}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {form.user_type === INTERNAL
            && (
              <Grid item xs={12} md={2}>
                <TextField
                  onChange={(e) => handleChange('internal_type', e.target.value)}
                  style={{ textAlign: 'left' }}
                  select
                  fullWidth
                  variant="outlined"
                  name="internal_type"
                  label="Área interna"
                  value={form.internal_type}
                >
                  {internalType.map((type) => (
                    <MenuItem
                      key={`tipo${type}`}
                      value={type}
                    >
                      {internalsTitles[type]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
          {form.internal_type === INTERNAL_SALES && form.user_type === INTERNAL
            && (
              <Grid item xs={12} md={4}>
                <TextField
                  onChange={(e) => handleChange('pipedrive_id', e.target.value)}
                  fullWidth
                  variant="outlined"
                  name="pipedrive_id"
                  label="ID no PipeDrive"
                  value={form.pipedrive_id}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            )}
          {form.user_type !== INTERNAL
            ? (
              <Grid item xs={12} md={10} />
            ) : (
              <Grid item xs={12} md={form.internal_type === INTERNAL_SALES ? 4 : 8} />
            )}
          <Grid item xs={12} md={4}>
            <TextField
              onChange={(e) => handleChange('name', e.target.value)}
              fullWidth
              variant="outlined"
              required
              name="name"
              label="Nome"
              value={form.name}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          {form.user_type !== PERSON
            && form.user_type !== MASTER
            && form.user_type !== INTERNAL
            && (
              <Grid item xs={12} md={4}>
                <PhoneField
                  fullWidth
                  variant="outlined"
                  name="phone"
                  label="Telefone"
                  onChange={(e) => handleChange('phone', e)}
                  value={form.phone}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            )}
          <Grid item xs={12} md={4}>
            <TextField
              onChange={(e) => handleChange('email', e.target.value)}
              fullWidth
              required
              variant="outlined"
              name="email"
              label="E-mail"
              value={form.email}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <input type="hidden" name="username" value={form.username} />
          </Grid>
          {mode !== EDIT_MODE
          ? (
              <Grid item xs={12} md={4}>
                <TextField
                  aria-describedby={`register-password${form.id}`}
                  required
                  fullWidth
                  id={`register-password${form.id}`}
                  label="Senha"
                  name="password"
                  onChange={(e) => handleChange('password', e.target.value)}
                  type={showPassword ? 'text' : 'password'}
                  value={form.password}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Ver senha"
                          onClick={() => handleShowPassword()}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            ) : null}
        </Grid>
        <Grid container spacing={2} justify="flex-end">
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.actionButton}
              type="reset"
            >
              {addMode ? 'Limpar' : 'Desfazer'}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={notEdited}
              className={clsx(classes.actionButton, 'submit')}
            >
              {addMode ? 'Adicionar' : 'Editar'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Collapse>
  );
}

RowForm.propTypes = {
  user: PropTypes.shape({}),
  view: PropTypes.array.isRequired,
  expanded: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf([EDIT_MODE, ADD_MODE]),
  internalType: PropTypes.array.isRequired,
};

RowForm.defaultProps = {
  mode: EDIT_MODE,
  user: {},
};

export default connect(null, { createUser })(RowForm);
