import * as definitions from 'store/definitions/contract';
import { DEFAULT, REJECTED, REQUESTING, FULFILLED } from 'constants/common';

function Contract(state = {
  content: '',
  status: DEFAULT,
  actionStatus: DEFAULT,
  signed: DEFAULT,
}, action) {
  switch (action.type) {
    case definitions.UPDATE_CONTRACT_CONTENT:
      return {
        ...state,
        actionStatus: REQUESTING,
      };
    case definitions.UPDATE_CONTRACT_CONTENT_FULFILLED:
      return {
        ...state,
        actionStatus: FULFILLED,
      };
    case definitions.UPDATE_CONTRACT_CONTENT_REJECTED:
      return {
        ...state,
        actionStatus: REJECTED,
      };
    case definitions.FETCH_CONTRACT_CONTENT:
      return {
        ...state,
        content: '',
        status: REQUESTING,
      };
    case definitions.FETCH_CONTRACT_CONTENT_FULFILLED:
      return {
        ...state,
        content: action.payload.content,
        status: FULFILLED,
      };
    case definitions.FETCH_CONTRACT_CONTENT_REJECTED:
      return {
        ...state,
        status: REJECTED,
      };
    case definitions.FETCH_CONTRACT_FULFILLED:
      return {
        ...state,
        content: action.payload.content,
        scope: action.payload.scope,
        status: FULFILLED,
      };
    case definitions.FETCH_CONTRACT_REJECTED:
      return {
        ...state,
        content: '',
        status: REJECTED,
      };
    case definitions.SIGN_CONTRACT_FULFILLED:
      return {
        ...state,
        signed: FULFILLED
      };
    case definitions.SIGN_CONTRACT_REJECTED:
      return {
        ...state,
        signed: REJECTED
      };
    case definitions.SIGN_CONTRACT:
      return {
        ...state,
        signed: REQUESTING
      };
    case definitions.FETCH_CONTRACT:
      return {
        ...state,
        content: '',
        status: REQUESTING,
      };
    default:
      return {
        ...state,
      };
  }
}

export default Contract;
