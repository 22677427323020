import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { SalesContext } from 'contexts/sales';
import uniqid from 'uniqid';
import {
  Typography, Grid, Paper, Container,
} from '@material-ui/core';
import { PROCESS_FORM_MODE_NEW } from 'constants/sales';
import ProcessFormEdit from 'sales/form/StepTwo/Process';
import SaleFormStepTwoForm from './Form';
import Emission from './Emission';

function SaleFormStepTwo(props) {
  const peoples = props.scope.peoples || [];

  const salesContext = useContext(SalesContext);

  useEffect(() => {
    const allowNext = (props.scope && props.scope.processes && props.scope.processes.length > 0);
    salesContext.handleStepOk(salesContext.currentStep, allowNext);
    // eslint-disable-next-line
  }, [props.scope.processes]);

  return (
    <Container maxWidth="xl">
      {props.scope.processes.length > 0 && (
        <Typography variant="h5" paragraph>
          Processos cadastrados
        </Typography>
      )}
      {peoples && peoples.length > 0
        && props.scope.processes
        && props.scope.processes.map((process) => {
          const people = peoples.find(a => a.id === process.applicant_id);
          if (!people) return null;
          return (
            <ProcessFormEdit key={uniqid()} people={people} process={process} />
          );
        })
      }
      <Typography variant="h5" paragraph>
        Cadastrar processo
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={3}>
            <SaleFormStepTwoForm mode={PROCESS_FORM_MODE_NEW} />
          </Paper>
          {/* <Emission scope={props.scope} /> */}
        </Grid>
      </Grid>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  scope: state.sales.scope,
});

export default connect(mapStateToProps)(SaleFormStepTwo);
