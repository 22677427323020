/* eslint-disable max-lines */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { object } from 'prop-types';
import { ServiceContext } from 'contexts/service';
import { connect } from 'react-redux';
import {
  Paper, IconButton, Grid, Button,
} from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
} from '@material-ui/icons';
import Contact from 'global/modules/Contact';
import * as $_ from 'global/functions';
import clsx from 'clsx';
import GridDocs from 'service/modules/GridDocs';
import { PROCESS } from 'constants/service';
import { showConfirmation } from 'store/actions/confirmations';
import { DOCSOK_ROW } from 'constants/service/menus';
import { confirmDocumentsDocumentation } from 'store/actions/documents';
import { useStyles } from '../style';

function Row({ item, ...props }) {
  const classes = useStyles(props);
  const process = item;

  const serviceContext = useContext(ServiceContext);
  const {
    handleCheckDialog,
    setDocsShown,
    handleShowMoreInfo,
    openMenu,
  } = serviceContext;

  return (
    <>
      <Paper
        key={`${process.id}`}
        className={clsx(classes.body, classes.pointer)}
        onClick={(e) => handleShowMoreInfo(true, process, e, PROCESS)}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={4} className={classes.centeredGrid}>
            <Contact
              item={{ client_id: process.identifier }}
              customer={{ name: process.applicant.name }}
              processTitle={process.products[0] && process.products[0].title}
              short
              displayProcess
            />
          </Grid>
          <Grid item xs={3} className={classes.centeredGrid}>
            {process.documentation_date && $_.semanticDate(process.documentation_date, true)}
          </Grid>
          <GridDocs
            currentClient={process}
            xs={2}
            scopeId={process.sales_scope.id}
            clientName={process.applicant.name}
            setDocsShown={setDocsShown}
            docType={PROCESS}
          />
          <Grid item xs={3} className={classes.centeredGrid} style={{ paddingRight: 16 }}>
            <Button
              onClick={(e) => { handleCheckDialog(process); $_.stopPropagation(e); }}
              variant="contained"
              color="primary"
            >
              {'Conferir'}
            </Button>
          </Grid>
        </Grid>
        <IconButton
          aria-label={`Ações de menu relacionadas ao processo de ${process.applicant.name}`}
          aria-controls="long-menu"
          aria-haspopup="true"
          className={clsx(classes.floatingButtons, classes.floatingRight, 'rootChild')}
          onClick={(e) => {
            $_.stopPropagation(e);
            openMenu(true, DOCSOK_ROW, e.currentTarget, process);
          }}
        >
          <MoreVertIcon />
        </IconButton>
      </Paper>
    </>
  );
}

Row.defaultProps = {
  item: {},
};

Row.propTypes = {
  item: object,
};

const actions = {
  showConfirmation,
  confirmDocumentsDocumentation,
};

export default connect(null, actions)(Row);
