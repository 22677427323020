import _ from 'lodash';

export const validateForm = (form) => {
  console.log(form)
  if (form === null || typeof form === 'undefined' || form === {} || form === '') {
    return false;
  }
  if (form.hosts.length === 0 || form.parent_type.length === 0 || form.products.length === 0 || (form.products[0].id === 21 && form.documents.length === 0)) {
    return false;
  }
  // eslint-disable-next-line
  for (const field in form) {
    if (
      [
        'hosts',
        'parent_type',
        'products',
        'clientId',
        'product',
        'applicantId',
        'extraProducts',
        'extraProductsSelection',
        'amounts',
        'documents',
        'gender'
      ].indexOf(field) === -1
      && form[field] !== null && form[field] && form[field].length < 3) {
        console.log('aquii 3')
      return false;
    }
  }
  return true;
};

export const getDocInfo = (products, product) => _.first(_.filter(products, ['id', product.id]));

export const getTitlesAndTotalValue = (process, products) => {
  const titles = [];
  let totalCost = 0;
  process.products.forEach((product, i) => {
    const useOldPrice = (product.use_old_price) || (product.pivot && product.pivot.use_old_price);
    if (product.prices) {
      totalCost = totalCost + product.prices.reduce((a, b) => a + (useOldPrice ? parseInt(b.use_old_price, 10) : parseInt(b.price, 10)), 0);
    }
    const size = process.products.filter((b) => product.title === b.title).length;
    if (!titles.join(' ').includes(product.title)) {
      titles.push(`${size > 1 ? `${size}x ` : ''}${product.title}`);
    }
  });
  return ({
    titles,
    totalCost,
  });
}

const getExtraProducts = (form) => {
  const products = [...form.products];
  if (form.amounts) {
    Object.keys(form.amounts).forEach((id) => {
      const findExtraProduct = form.extraProducts.find((p) => `${p.id}` === `${id}`);
      if (findExtraProduct) {
        const amount = form.amounts[id];
        products.push(
          ...Array
            .from(Array(amount))
            .map(() => ({
              id,
              use_old_price: findExtraProduct.use_old_price || false,
              apostilate: findExtraProduct.apostilate || false,
            }))
        )
      }
    });
  }
  return products;
};

export const prepareToUpdatePerson = (form) => ({
  name: form.name,
  birthdate: form.birthdate,
  gender: form.gender,
});

export const prepareToUpdate = (form) => ({
  applicant_id: form.applicantId,
  name: form.name,
  birthdate: form.birthdate,
  gender: form.gender,
  hosts: [form.hosts],
  host_relations: [form.parent_type],
  is_courtesy: form.is_courtesy,
  products: getExtraProducts(form),
  phase: form.phase,
  premium_service: form.premium_service,
  documents: form.documents,
});

export const prepareToUpdateProcess = (form) => ({
  hosts: [form.hosts],
  host_relations: [form.parent_type],
  is_courtesy: form.is_courtesy,
  products: getExtraProducts(form),
  phase: form.phase,
  premium_service: form.premium_service,
  documents: form.documents,
});

export const prepareToSubmit = (form) => ({
  name: form.name,
  birthdate: form.birthdate,
  gender: form.gender,
  hosts: [form.hosts],
  host_relations: [form.parent_type],
  is_courtesy: form.is_courtesy,
  products: getExtraProducts(form),
  phase: form.phase,
  premium_service: form.premium_service,
  documents: form.documents,
});
