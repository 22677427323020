// @ts-nocheck
import React, { useState, useContext } from 'react';
import clsx from 'clsx';
import { UserContext } from 'contexts/user';
import uniqid from 'uniqid';
import {
  Grid,
  Paper,
  Typography,
  IconButton,
} from '@material-ui/core';
import { object, func, bool } from 'prop-types';
import { Edit as EditIcon, Close } from '@material-ui/icons';
import Edit from 'global/modules/CitizenEdit';
import { infosCitizen, infosApplicant } from 'constants/citizens';
import Visualization from './CitizenInfoRowVisualization';

function CitizenInfoRow({
  citizen, classes, setUpdate, applicant, applicantSelf,
}) {
  const { userService } = useContext(UserContext);
  const [editing, setEditing] = useState(false);
  const handleEdit = () => setEditing((s) => !s);
  const showInfosApplicant = applicantSelf ? infosCitizen : infosApplicant;
  if (applicant) {
    return (
      <Grid item xs={12} md={!editing ? 6 : 12} className={classes.transitions}>
        <Paper className={classes.smallPaper} elevation={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className={classes.maxWidthName} variant="h6">
                {editing ? 'Alterar informações' : citizen.name}
              </Typography>
              {userService && (
                <IconButton onClick={handleEdit} className={clsx(classes.editBtn, editing ? 'editing' : 'regular')}>
                  {editing ? <Close /> : <EditIcon />}
                </IconButton>
              )}
              {!editing && showInfosApplicant.map((info) => (
                <Visualization key={`visualization${citizen.id}${info.name}${uniqid()}`} citizen={citizen} info={info} />
              ))}
              {editing && (
                <Edit
                  citizen={citizen}
                  infos={showInfosApplicant}
                  classes={classes}
                  setEditing={setEditing}
                  editing={editing}
                  setUpdate={setUpdate}
                  applicantSelf={applicantSelf}
                  applicant
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }
  return (
    <Grid key={`${citizen.id}`} item xs={12} md={!editing ? 6 : 12} className={classes.transitions}>
      <Paper className={classes.smallPaper} elevation={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.maxWidthName} variant="h6">
              {editing ? 'Alterar informações' : citizen.name}
            </Typography>
            <IconButton onClick={handleEdit} className={clsx(classes.editBtn, editing ? 'editing' : 'regular')}>
              {editing ? <Close /> : <EditIcon />}
            </IconButton>
            {!editing && infosCitizen.map((info) => <Visualization key={`visualization${citizen.id}${info.name}`} citizen={citizen} info={info} />)}
            {editing && (
              <Edit
                citizen={citizen}
                infos={infosCitizen}
                classes={classes}
                setEditing={setEditing}
                editing={editing}
                setUpdate={setUpdate}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

CitizenInfoRow.propTypes = {
  citizen: object.isRequired,
  classes: object.isRequired,
  setUpdate: func.isRequired,
  applicant: bool,
  applicantSelf: bool,
};

CitizenInfoRow.defaultProps = {
  applicant: false,
  applicantSelf: false,
};

export default CitizenInfoRow;
