/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import {
  Container,
  Paper,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Typography,
  Grid,
  IconButton,
  Tooltip,
  Link,
} from '@material-ui/core';
import { fetchContract, signOnlineContract } from 'store/actions/contract';
import Root from 'global/modules/Root';
import { UserContext } from 'contexts/user';
import { MASTER, INTERNAL, CUSTOMER } from 'constants/user';
import { FULFILLED, REQUESTING, DEFAULT } from 'constants/common';
import { CircleLogo } from 'global/sources/Icons';
import Countdown from 'global/modules/Countdown';
import { Print, Close, Create, Description } from '@material-ui/icons';
import { apiBaseUrl } from 'api';
import { semanticDate } from 'global/functions';
import Progress from './Progress';
import { useStyles } from './style';
import ContractBody from './ContractBody';
import Checklist from './Checklist';
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@material-ui/lab';

function Contract({ match, params = match.params, ...props }) {
  const { user } = useContext(UserContext);
  const classes = useStyles();
  const { id } = params;

  const [signed, setSigned] = useState(false);
  const [signingAllowed, setSigningAllowed] = useState(false);
  const internalUsers = (user.userType === INTERNAL || user.userType === MASTER);
  const viewingAllowed = internalUsers || (user.userType === CUSTOMER && signingAllowed);

  const [form, setForm] = useState({
    agree: false,
    email: false,
  });
  const [error, setError] = useState(false);
  const [step, setStep] = useState(false);
  const [dialOpen, setDialOpen] = useState(false);
  const alreadySigned = props.contract && props.contract.scope && props.contract.scope.signed_date;
  const handleStep = () => setStep(!step);

  const handleRefreshError = () => {
    setSigned(false);
    handleStep();
  };

  const handleCheck = (item) => {
    const a = !form[item];
    setForm({ ...form, [item]: !form[item] });
    if (item === 'agree' && a) setError(false);
  };

  const validateSend = () => {
    if (form.agree) {
      props.signOnlineContract(id);
    } else setError(true);
  };

  const toggleDial = () => {
    setDialOpen((o) => !o);
  };

  const newWindow = (link, id) => {
    window.open(link, `contract${id}`)
  };

  useEffect(() => {
    if (props.contract.signed !== REQUESTING && props.contract.signed !== DEFAULT) {
      setSigned(true);
    } else {
      setSigned(false);
    }
    // eslint-disable-next-line
  }, [props.contract.signed]);

  useEffect(() => {
    if (id) {
      props.fetchContract(id);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (props.contract.status === FULFILLED) {
      setSigningAllowed(user.id === props.contract.scope.customer_id || user.userType === MASTER);
    }
    // eslint-disable-next-line
  }, [props.contract]);

  if (props.contract.signed !== REQUESTING && props.contract.signed !== DEFAULT && signed) {
    return (
      <Root centered setTitle={`${props.contract.signed === FULFILLED ? 'Sucesso!' : 'Erro :('} - Contrato`} paddingTop>
        <Container
          maxWidth="md"
          style={{
            paddingBottom: 1,
          }}
        >
          <CircleLogo
            style={{
              width: 100,
              height: 'auto',
              display: 'block',
              margin: '24px auto 16px',
              maxWidth: '80%',
            }}
          />
          <Typography
            variant="h5"
            color="textSecondary"
            align="center"
            paragraph
          >
            {props.contract.signed === FULFILLED ? 'Contrato assinado. Obrigado por confiar seu sonho em nós!' : 'Erro, por favor tente novamente'}
          </Typography>
          <Countdown timer={10} pushFunction={props.history.push} onEnd={props.contract.signed === FULFILLED ? '/' : () => { handleRefreshError(); }} />
        </Container>
      </Root>
    );
  }

  if (viewingAllowed) {
    return (
      <Root setTitle="Contrato" paddingTop className={clsx(classes.root, !signingAllowed ? 'noExtra' : 'regular')} minWidth={568}>
        <Container maxWidth="md">
          {!signingAllowed
            && props.contract
            && props.contract.scope
            && props.contract.scope.customer
            && (
              <Grid container spacing={2} className={classes.clientName}>
                <Grid item xs={12} sm={6} className={classes.center}>
                  <Typography variant="h6">
                    {`Contrato de ${props.contract.scope.customer.name}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography component="div" align="right">
                    {props.contract.scope.signed_contract_path
                      ? (
                        <SpeedDial
                          ariaLabel="Ver contratos"
                          direction="left"
                          icon={<SpeedDialIcon icon={<Print />} openIcon={<Close />} />}
                          FabProps={{
                            size: 'small',
                            className: classes.fab,
                          }}
                          classes={{
                            root: classes.speedDial,
                            actions: classes.actionsWrapper,
                          }}
                          open={dialOpen}
                          onClose={toggleDial}
                          onOpen={toggleDial}
                        >
                          <SpeedDialAction
                            key="signedContract"
                            icon={<Create />}
                            tooltipTitle="Ver contrato assinado"
                            onClick={() => newWindow(props.contract.scope.signed_contract_path, `${props.contract.id}SIGNED`)}
                          />
                          <SpeedDialAction
                            key="unsignedContract"
                            icon={<Description />}
                            tooltipTitle="Ver contrato"
                            onClick={() => newWindow(props.contract.scope.contract_path, `${props.contract.id}NOTSIGNED`)}
                          />
                        </SpeedDial>
                      ) : (
                        <Tooltip title="Baixar o contrato em PDF">
                          <Link
                            target="_blank"
                            href={`${apiBaseUrl}/contracts/${props.contract.scope.contract_path}`}
                          >
                            <IconButton>
                              <Print fontSize="large" />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      )}
                  </Typography>
                </Grid>
              </Grid>
            )
          }
          {
            props.contract.status === FULFILLED && (
              <Paper elevation={3}>
                {!step && signingAllowed
                  ? (
                    <Checklist scope={props.contract.scope} />
                  ) : (
                    <ContractBody content={props.contract.content} />
                  )}
                {step && signingAllowed && !alreadySigned && (
                  <>
                    <FormControl
                      required
                      error={error}
                      fullWidth
                    >
                      <FormControlLabel
                        control={<Checkbox checked={form.agree} />}
                        onChange={() => handleCheck('agree')}
                        label="Li e concordo com os Termos de Serviço da prestação de serviço do Cidadania Já."
                      />
                      {error && (
                        <FormHelperText
                          className={classes.helper}
                        >
                          {'Você precisa aceitar estes Termos de Serviço para continuar.'}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Button
                      className={classes.startButton}
                      onClick={validateSend}
                      variant="contained"
                      color="primary"
                    >
                      {'Concordar'}
                    </Button>
                  </>
                )}
                {alreadySigned && (
                  <Typography
                    color="primary"
                    variant="h6"
                    style={{ marginTop: 16 }}
                  >
                    {`Contrato assinado em ${semanticDate(props.contract.scope.signed_date)} pela empresa contratada Cidadania Já e pelo(a) contratante ${props.contract.scope.customer.name} em comum acordo.`}
                  </Typography>
                )}
              </Paper>
            )
          }
        </Container >
        {
          props.contract.status === FULFILLED && signingAllowed && (
            <Progress step={step} handleStep={handleStep} />
          )
        }
      </Root >
    );
  }
  return null;
}

const mapStateToProps = (state) => ({
  contract: state.contract,
});

export default connect(mapStateToProps, { signOnlineContract, fetchContract })(Contract);
