import React, { useState } from 'react';
import uniqid from 'uniqid';
import PropTypes from 'prop-types';
import ServiceProvider from 'providers/service';
import { ServiceContext } from 'contexts/service';
import MoreInfo from 'global/modules/MoreInfo';
import UpdateCurrents from 'service/UpdateCurrents';
import DocumentsList from 'global/modules/Documents';
import Results from './Results';

function Search({ match, history }) {
  const [update, setUpdate] = useState(uniqid());
  const value = Object.values(match.params)[0];

  return (
    <ServiceProvider>
      <ServiceContext.Consumer>
        {({
          currentTab,
          setDocsShown,
          docsShown,
          currentClient,
          currentInfoObj,
          objType,
          infoType,
          handleShowMoreInfo,
          showMoreInfo,
        }) => (
          <>
            <UpdateCurrents
              update={update}
            />
            <Results value={value} update={update} setUpdate={setUpdate} history={history} />
            <DocumentsList
              show={docsShown}
              currentClient={currentClient || {}}
              currentTab={currentTab}
              setUpdate={setUpdate}
              update={update}
              objType={objType}
              onClose={() => setDocsShown({})}
            />
            <MoreInfo
              viewType={infoType}
              client={currentInfoObj}
              open={showMoreInfo}
              handleFunc={handleShowMoreInfo}
              update={update}
              setUpdate={setUpdate}
            />
          </>
        )}
      </ServiceContext.Consumer>
    </ServiceProvider>
  );
}

Search.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      0: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

Search.defaultProps = {
  match: {
    params: {
      0: '',
    },
  },
};

export default Search;
