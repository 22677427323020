import React from 'react'
import * as imports from 'objects/imports';
import { Container, Grid, Typography, makeStyles, Link, IconButton } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#acb0b9',
    color: theme.palette.common.white,
    zIndex: 100,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    margin: 0,
    padding: theme.spacing(2, 0)
  },
  brand: {
    width: '55px',
    height: '44px',
    display: 'block',
    overflow: 'hidden',
    textIndent: '-99999px',
    backgroundImage: 'url(../img/icon.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '85%',
    margin: '0 0 -10px auto',
  },
  links: {
    padding: 0,
  },
  link: {
    '&, &:hover': {
      color: theme.palette.common.white,
    }
  },
  social: {
    textAlign: 'right',
  },
  socialButton: {
    background: theme.palette.common.white,
    borderRadius: 0,
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1.5),
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  socialButtonLabel: {
    width: '30px',
    height: '30px',
    alignItems: 'center',
    justifyContent: 'center',
    '& i': {
      margin: 0,
      height: '100%',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
    },
  },
}));

function Footer() {
  const classes = useStyles();
  const navLinkContent = [];

  imports.SiteInfo.sitemaplinks.forEach((navLink, index) => {
    navLinkContent.pop(<a key={`nav${index}`} href={navLink.link}><Typography variant="body1">{navLink.title}</Typography></a>);
  });

  const socialContent = [];

  imports.SiteInfo.socialmedia.forEach((social, index) => {
    socialContent.pop(<a key={`social${index}`} href={social.link} title={social.title}><i className={social.iconclass} /></a>);
  });

  return (
    <footer className={classes.root}>
      <Container maxWidth="xl">
        <Grid spacing={2} container>
          <Grid item xs={6}>
            <Typography variant="h4">
              {imports.SiteInfo.title}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Link
              href={imports.SiteInfo.link}
              title={imports.SiteInfo.title}
              className={classes.brand}
            >
              {imports.SiteInfo.title}
            </Link>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">{imports.SiteInfo.description}</Typography>
          </Grid>
          <Grid item xs={6} className={classes.links}>
            <Grid container spacing={1}>
              {imports.SiteInfo.sitemaplinks.map(link => (
                <Grid key={`link${link.link}`} item xs={4}>
                  <Link
                    href={link.link}
                    underline="hover"
                    title={link.title}
                    className={classes.link}
                  >
                    {link.title}
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Typography
              variant="subtitle1"
              style={{ whiteSpace: 'nowrap' }}
            >
              {imports.SiteInfo.copyright}
            </Typography>
            <Typography variant="subtitle1">
              {imports.SiteInfo.copyright2}
            </Typography>
          </Grid>
          <Grid item xs={5} className={classes.social}>
            {imports.SiteInfo.socialmedia.map((social, index) => (
              <Link
                key={`social${index}`}
                title={social.title}
                href={social.link}
                underline="none"
              >
                <IconButton
                  classes={{
                    root: classes.socialButton,
                    label: classes.socialButtonLabel,
                  }}
                >
                  <i className={social.iconclass} />
                </IconButton>
              </Link>
            ))}
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}

export default Footer;
