import React, { Component } from 'react';
import { SalesContext } from 'contexts/sales';
import { salesForm } from 'constants/sales';

class SalesProvider extends Component {
  state = {
    currentStep: 0,
    sale: salesForm,
    paymentConfirmed: false,
    stepsOk: {
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,
    },
    phases: {},
  }

  setStep = step => this.setState(state => ({ currentStep: step }))
  setSaleKey = (key, value) => this.setState(state => ({ sale: { ...state.sale, [key]: value } }))
  handleStepOk = (step, value) => this.setState((state) => ({ stepsOk: { ...state.stepsOk, [step]: value } }))
  setPaymentConfirmed = (value) => this.setState((state) => ({ paymentConfirmed: value }))
  setPhases = (phases) => this.setState((state) => ({ phases }))

  render() {
    return (
      <SalesContext.Provider value={{
        ...this.state,
        setSaleKey: this.setSaleKey,
        setStep: this.setStep,
        handleStepOk: this.handleStepOk,
        setPaymentConfirmed: this.setPaymentConfirmed,
        setPhases: this.setPhases,
      }}>
        {this.props.children}
      </SalesContext.Provider>
    );
  }
}

export default SalesProvider;
