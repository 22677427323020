import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import uniqid from 'uniqid';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  TextField,
  MenuItem,
  Typography,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { SaleDashboardContext } from 'contexts/saleDashboard';
import {
  paymentMethodTitles,
  PAYMENT_CASH,
  PAYMENT_CREDIT_CARD,
  PAYMENT_STATUS_PAID,
  PAYMENT_BANK_SLIP,
} from 'constants/sales';
import { convertCurrency, localeDate } from 'helpers';
import { updatePaidPayment, confirmSale } from 'store/actions/payment';
import { updateSignedContact } from 'store/actions/sales';
import FileBox from 'global/filebox';
import { CANCELLED } from 'constants/common/payments';
import FilesPreview from 'global/modules/FilesPreview';
import { apiBaseUrl } from 'api';
import { semanticDate } from 'global/functions';
import moment from 'moment';
import { FULFILLED, REQUESTING } from 'constants/common';
import { showNotification } from 'store/actions/notifications';
import { MESSAGE_ERROR_ADD_PAYMENT } from 'constants/common/messages';
import { NOTIFICATION_TYPE_ERROR } from 'constants/notification';
import { Loading } from 'global/sources/Icons';

function UserDialogs({ status, setUpdate, update, ...props }) {
  //  const classes = useStyles();
  const dashContext = useContext(SaleDashboardContext);
  const {
    currentClient,
    dialogSignedContract,
    handleDialogSignedContract,
    dialogPayment,
    handleDialogPayment,
    dialogConfirmSale,
    handleDialogConfirmSale,
  } = dashContext;
  const [file, setFile] = useState([]);
  const [sent, setSent] = useState(false);
  const [sentSale, setSentSale] = useState(false);
  const paymentObj = { id: '', payment_method: '' };
  const [currentPayment, setCurrentPayment] = useState({ ...paymentObj });

  const paymentsLength = currentClient.payments
    ? currentClient.payments
      .filter((a) => a.payment_method !== PAYMENT_CREDIT_CARD && a.status !== CANCELLED && a.month_number === 1).length
    : 0;

  const closeThis = () => {
    setCurrentPayment(paymentObj);
    handleDialogSignedContract();
  };

  const handleSendContract = () => {
    if (file.length > 0) {
      props.updateSignedContact(currentClient.id, file[0]);
      closeThis();
    }
  };

  const handleSendPaymentConfirmation = () => {
    if (currentPayment.payment_method === PAYMENT_CASH
      || (currentPayment.payment_method !== PAYMENT_CASH && file.length > 0)) {
      props.updatePaidPayment(
        currentPayment.id,
        currentPayment.payment_method !== PAYMENT_CASH ? { doc: file[0] } : {},
      );
      setSent(true);
    } else {
      props.showNotification(NOTIFICATION_TYPE_ERROR, MESSAGE_ERROR_ADD_PAYMENT);
    }
  };

  useEffect(() => {
    if (status === FULFILLED && sent) {
      setUpdate(uniqid());
      setFile([]);
      setSent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, handleDialogPayment, setUpdate]);


  useEffect(() => {
    if (status === FULFILLED && sentSale && dialogConfirmSale) {
      setUpdate(uniqid());
      setSentSale(false);
      handleDialogConfirmSale();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, handleDialogConfirmSale, setUpdate]);

  const handleChangePayment = (id) => {
    setCurrentPayment(currentClient.payments.find((a) => a.id === id));
  };

  const getDocObj = (obj) => (
    {
      type: obj.split('.')[obj.split('.').length - 1],
      title: obj,
      src: `${apiBaseUrl}/documents/${obj}`,
    }
  );

  const handleConfirmSale = () => {
    props.confirmSale(currentClient.internal_id);
    setSentSale(true);
  };

  useEffect(() => {
    if (currentClient.payments && currentPayment.id) {
      setCurrentPayment((p) => currentClient.payments.find((a) => a.id === p.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient]);

  useEffect(() => {
    setFile([]);
    setCurrentPayment({ ...paymentObj });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogPayment, dialogSignedContract]);

  return (
    <>
      <Dialog
        PaperProps={{
          square: true,
        }}
        open={dialogSignedContract}
        onClose={closeThis}
        scroll="body"
      >
        <DialogTitle id="signed-contract-title">
          {`Enviar contrato de ${currentClient.customer && currentClient.customer.name}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {!currentClient.signed_contract_path
              ? 'Se o cliente assinou o contrato presencialmente, por favor anexe o documento assinado e digitalizado.'
              : 'Um contrato assinado já foi anexado, se desejar, substitua o arquivo abaixo'}
          </DialogContentText>
          <FileBox
            file={file}
            setFile={setFile}
            multiple={false}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeThis} color="secondary" variant="outlined">Fechar</Button>
          {file.length > 0 && (
            <Button onClick={handleSendContract} color="primary" variant="outlined">Enviar</Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        PaperProps={{
          square: true,
        }}
        open={dialogConfirmSale}
        onClose={handleDialogConfirmSale}
        scroll="body"
      >
        <DialogTitle>
          {'Confirmar venda de '}
          {currentClient.customer && currentClient.customer.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Confirmar que a venda ${currentClient.client_id} de ${currentClient.customer && currentClient.customer.name} foi paga e pode entrar em serviços?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogConfirmSale} color="secondary" variant="outlined">
            {'Fechar'}
          </Button>
          <Tooltip title="Confirmar!">
            <Button
              onClick={handleConfirmSale}
              color="primary"
              variant="outlined"
              disabled={status === REQUESTING}
            >
              <Loading />
            </Button>
          </Tooltip>
        </DialogActions>
      </Dialog>
      <Dialog
        PaperProps={{
          square: true,
        }}
        open={dialogPayment}
        onClose={handleDialogPayment}
        scroll="body"
      >
        <DialogTitle id="Payments">
          {'Enviar comprovante de pagamento de '}
          {currentClient.customer && currentClient.customer.name}
        </DialogTitle>
        {paymentsLength > 0
          ? (
            <DialogContent>
              <DialogContentText>
                {paymentsLength > 1
                  ? 'Por favor, selecione abaixo qual pagamento é referente ao comprovante'
                  : 'Por favor, envie o comprovante abaixo'}
              </DialogContentText>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    select
                    fullWidth
                    value={currentPayment.id}
                    variant="outlined"
                    name="payments"
                    label="Pagamentos"
                    onChange={(e) => handleChangePayment(e.target.value)}
                  >
                    {currentClient.payments
                      .filter((a) => a.status !== CANCELLED && a.month_number === 1)
                      .map((payment) => {
                        if (payment.payment_method === PAYMENT_BANK_SLIP) {
                          return (
                            <MenuItem key={payment.id} value={payment.id}>
                              <Typography color="textPrimary">
                                {`${paymentMethodTitles[payment.payment_method]}`}
                                {`- 1ª parcela de ${convertCurrency(payment.value)} -`}
                                {payment.months && payment.months > 1
                                  ? <Typography color="textSecondary" component="span">{` (${convertCurrency(payment.value * payment.months)} em ${payment.months}x)`}</Typography>
                                  : ''}
                                {payment.due_date ? ` - Vencimento ${localeDate(payment.due_date)}` : ''}
                              </Typography>
                            </MenuItem>
                          );
                        }
                        return (
                          <MenuItem key={payment.id} value={payment.id}>
                            <Typography color="textPrimary">
                              {`${paymentMethodTitles[payment.payment_method]} - ${convertCurrency(payment.value)}${payment.months && payment.months > 1 ? ` ${payment.months}x de ${convertCurrency(payment.value / payment.months)}` : ''}`}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </Grid>
                {currentPayment.id !== '' && currentPayment.status === PAYMENT_STATUS_PAID && (
                  <Grid item xs={12}>
                    <Typography color="primary">
                      {`Pagamento confirmado ${currentPayment.payment_date ? `em ${semanticDate(currentPayment.payment_date)} às ${moment(currentPayment.payment_date).format('LTS')}` : ''}`}
                    </Typography>
                  </Grid>
                )}
                {currentPayment.id !== '' && currentPayment.payment_doc && (
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="h6">Comprovante anexado</Typography>
                      </Grid>
                      {currentPayment.payment_doc && (
                        <FilesPreview
                          key={uniqid()}
                          fullWidth
                          doc={getDocObj(currentPayment.payment_doc)}
                          expandable
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
                {currentPayment && currentPayment.id !== '' && currentPayment.payment_method === PAYMENT_CASH
                  && (
                    <Grid item xs={12}>
                      <Typography>
                        {'Se você recebeu o dinheiro em espécie em mãos, confirme o pagamento para que o financeiro seja informado.'}
                      </Typography>
                    </Grid>
                  )}
                {currentPayment && currentPayment.id !== '' && currentPayment.payment_method !== PAYMENT_CASH && (
                  <Grid item xs={12}>
                    {currentPayment.payment_doc && (
                      <Typography variant="h6" paragraph>Alterar comprovante</Typography>
                    )}
                    <FileBox
                      file={file}
                      setFile={setFile}
                      multiple={false}
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>
          ) : (
            <DialogContent>
              <DialogContentText>Nenhum pagamento encontrado</DialogContentText>
            </DialogContent>
          )}
        <DialogActions>
          <Button onClick={handleDialogPayment} color="secondary" variant="outlined">
            {'Fechar'}
          </Button>
          {currentPayment.id !== '' && paymentsLength > 0 && (
            <Button onClick={handleSendPaymentConfirmation} color="primary" variant="outlined">
              {currentPayment.payment_method === PAYMENT_CASH ? 'Confirmar pagamento em espécie' : 'Enviar'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  status: state.payment.status,
});

const actions = {
  confirmSale,
  updatePaidPayment,
  updateSignedContact,
  showNotification,
};

export default connect(mapStateToProps, actions)(UserDialogs);
