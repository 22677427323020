import React from 'react';
import { GENDER_FEMALE } from 'objects/imports';
import PropTypes from 'prop-types';
import { semanticPlurals } from 'helpers';
import clsx from 'clsx';
import { STEP_CONCLUSION } from 'constants/process';
import uniqid from 'uniqid';
import {
  Grid,
  Typography,
  Paper,
} from '@material-ui/core';
import { FULFILLED } from 'constants/session';
import { REQUESTING } from 'constants/common';
import { LoadingComponent } from '../Loading';
import CitizenInfoRow from './CitizenInfoRow';

function DetailsGrid({
  infos, isProcess, currentCustomer, classes, handleSubset, modularStatus, setUpdate,
}) {
  const filteredProcesses = isProcess
    ? infos.processes
      .filter((a) => a.id !== currentCustomer.id && a.step.name !== STEP_CONCLUSION)
    : infos.processes.filter((a) => a.step.name !== STEP_CONCLUSION);
  const filteredConcluded = infos.processes.filter((a) => a.step.name === STEP_CONCLUSION);
  let filteredHosts = infos.hosts;
  if (isProcess) {
    filteredHosts = infos.hosts.filter((a) => a.id !== currentCustomer.applicant_id);
  }
  const applicantSelf = Boolean(infos.hosts.find((a) => a.id === currentCustomer.applicant_id));
  const applicantFemale = currentCustomer.applicant
    && currentCustomer.applicant.gender === GENDER_FEMALE;
  return (
    <Grid container spacing={2} className={classes.gridContainer}>
      {currentCustomer.applicant && (
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            {`Informações d${applicantFemale ? 'a' : 'o'} requerente `}
            {`${applicantSelf ? `e cidad${applicantFemale ? 'ã' : 'ão'} português${applicantFemale ? 'a' : ''} do processo` : ''}`}
          </Typography>
        </Grid>
      )}
      {currentCustomer.applicant && (
        <Grid item xs={12}>
          <Grid container spacing={2} style={{ marginBottom: 8 }}>
            <CitizenInfoRow
              key={uniqid()}
              classes={classes}
              citizen={currentCustomer.applicant}
              setUpdate={setUpdate}
              applicant
              applicantSelf={applicantSelf}
            />
          </Grid>
        </Grid>
      )}
      {filteredHosts.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            {`Cidadão${semanticPlurals(filteredHosts, '', 's')} portugu${semanticPlurals(filteredHosts, 'ês', 'eses')} `}
            {`${isProcess ? 'do processo' : `associado${semanticPlurals(filteredHosts, '', 's')} ao cliente`}`}
          </Typography>
        </Grid>
      )}
      {filteredHosts.length > 0 && (
        <Grid item xs={12}>
          <Grid container spacing={2} style={{ marginBottom: 8 }}>
            {filteredHosts.map((citizen) => (
              <CitizenInfoRow
                key={uniqid()}
                classes={classes}
                citizen={citizen}
                setUpdate={setUpdate}
              />
            ))}
          </Grid>
        </Grid>
      )}
      {filteredProcesses.length > 0 && modularStatus === FULFILLED
        ? (
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              {isProcess
                ? `Outros processos relacionados ao cliente ${infos.customer.name}`
                : 'Processos relacionados ao cliente'}
            </Typography>
          </Grid>
        ) : null}
      {modularStatus === REQUESTING && (
        <Grid item xs={12}>
          <LoadingComponent height={30} margin={0} />
        </Grid>
      )}
      {filteredProcesses.length > 0 && modularStatus === FULFILLED
        ? (
          <Grid item xs={12}>
            <Grid container spacing={2} style={{ marginBottom: 8 }}>
              {filteredProcesses.map((process) => (
                <Grid key={`PROCESSO${process.id}`} item xs={12} sm={6} md={4}>
                  <Paper
                    className={clsx(classes.smallPaper, 'pointer')}
                    elevation={1}
                    onClick={() => handleSubset(process)}
                  >
                    <Typography variant="subtitle2">
                      {process.identifier}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      {process.products && process.products[0] && process.products[0].title}
                    </Typography>
                    <Typography variant="h6">
                      {process.applicant.name}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : null}
      {modularStatus === REQUESTING && (
        <Grid item xs={12}>
          <LoadingComponent height={150} margin={0} />
        </Grid>
      )}
      {filteredConcluded && filteredConcluded.length > 0 && modularStatus === FULFILLED
        ? (
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              {isProcess
                ? `Processos concluídos relacionados ao cliente ${infos.customer.name}`
                : 'Processos concluídos relacionados ao cliente'}
            </Typography>
          </Grid>
        ) : null}
      {filteredConcluded && filteredConcluded.length > 0 && modularStatus === FULFILLED
        ? (
          <Grid item xs={12}>
            <Grid container spacing={2} style={{ marginBottom: 8 }}>
              {filteredConcluded.map((process) => (
                <Grid key={`PROCESSO${process.id}`} item xs={12} sm={6} md={4}>
                  <Paper
                    className={clsx(classes.smallPaper, 'pointer')}
                    elevation={1}
                    onClick={() => handleSubset(process)}
                  >
                    <Typography variant="subtitle2">
                      {process.identifier}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      {process.products && process.products[0] && process.products[0].title}
                    </Typography>
                    <Typography variant="h6">
                      {process.applicant.name}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : null}
    </Grid>
  );
}

DetailsGrid.propTypes = {
  infos: PropTypes.object.isRequired,
  isProcess: PropTypes.bool.isRequired,
  currentCustomer: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  handleSubset: PropTypes.func.isRequired,
  modularStatus: PropTypes.string.isRequired,
  setUpdate: PropTypes.func.isRequired,
};

export default DetailsGrid;
