import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Session from './session';
import Notifications from './notifications';
import Confirmations from './confirmations';
import Sales from './sales';
import Certificate from './certificate';
import Product from './product';
import Users from './users';
import Search from './search';
import Dialogs from './dialogs';
import Customer from './customer';
import ExternalAuth from './externalAuth';
import Payment from './payment';
import Contract from './contract';
import Documents from './documents';
import Steps from './steps';
import Comments from './comments';
import Processes from './processes';
import Batchpost from './batchpost';
import Exchange from './exchange';
import People from './people';

export default (history) => combineReducers({
  certificates: Certificate,
  comments: Comments,
  confirmations: Confirmations,
  contract: Contract,
  customerSentForm: Customer,
  dialogs: Dialogs,
  documents: Documents,
  externalAuth: ExternalAuth,
  exchange: Exchange,
  listUsers: Users,
  notifications: Notifications,
  payment: Payment,
  processes: Processes,
  product: Product,
  router: connectRouter(history),
  sales: Sales,
  search: Search,
  session: Session,
  steps: Steps,
  batchpost: Batchpost,
  people: People
});
