export const VERIFICACAO_ROW = 'VERIFICACAO_ROW';
export const ALINHAMENTO_ROW = 'ALINHAMENTO_ROW';
export const DOCUMENTACAO_ROW = 'DOCUMENTACAO_ROW';
export const APOSTILAMENTO_ROW = 'APOSTILAMENTO_ROW';
export const DOCSOK_ROW = 'DOCSOK_ROW';
export const DOCSOK_BLOCK_ROW = 'DOCSOK_BLOCK_ROW';
export const ENVIO_PROCESS_ROW = 'ENVIO_PROCESS_ROW';

export const PICK_DATE_SENT = 'sent_to_apostilate_date';
export const PICK_DATE_ARRIVED = 'receive_to_apostilate_date';
export const PICK_DATE_PLANNED = 'planned_date';
