// @ts-nocheck
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState } from 'react';
import uniqid from 'uniqid';
import { kebabCase } from 'lodash';
import { UserContext } from 'contexts/user';
import ServiceProvider from 'providers/service';
import { ServiceContext } from 'contexts/service';
import { title, SERVICE } from 'constants/common/pageTitles';
import { menuItems } from 'constants/service';
import Root from 'global/modules/Root';
import MoreInfo from 'global/modules/MoreInfo';
import DocumentsList from 'global/modules/Documents';
import Alinhamento from './Alinhamento';
import Documentacao from './Documentacao';
import DocsOK from './DocsOK';
import Apostilamento from './Apostilamento';
import Analise from './Analise';
import Envio from './Envio';
import Acompanhamento from './Acompanhamento';
import Conclusao from './Conclusao';
import NavTabs from './modules/NavTabs';
import { useStyles } from './style';
import UpdateCurrents from './UpdateCurrents';

function Service({ match, ...props }) {
  const { user } = useContext(UserContext);
  const { tab, subtab } = match.params;
  const classes = useStyles(props);
  const [update, setUpdate] = useState(uniqid());
  const deburrMenu = menuItems.map((a) => kebabCase(a));
  const index = deburrMenu.indexOf(tab);
  const tabIndex = index >= 0 ? index : 0;
  const titles = title(menuItems[tabIndex], SERVICE);
  const sectionProps = { update, setUpdate, subtab };
  const items = [
    <Alinhamento key="servicetabAlinhamento" {...sectionProps} />,
    <Documentacao key="servicetabDocumentacao" {...sectionProps} />,
    <DocsOK key="servicetabDocsOK" {...sectionProps} />,
    <Apostilamento key="servicetabApostilamento" {...sectionProps} />,
    <Analise key="servicetabAnalise" {...sectionProps} />,
    <Envio key="servicetabEnvio" {...sectionProps} />,
    <Acompanhamento key="servicetabAcompanhamento" {...sectionProps} />,
    <Conclusao key="servicetabConclusao" {...sectionProps} />,
  ];

  return (
    <ServiceProvider currentTab={tabIndex} currentTitle={titles}>
      <ServiceContext.Consumer>
        {({
          currentTab,
          setDocsShown,
          docsShown,
          currentClient,
          currentInfoObj,
          currentTitle,
          objType,
          infoType,
          handleShowMoreInfo,
          showMoreInfo,
        }) => (
          <>
            <UpdateCurrents
              update={update}
            />
            <Root header setTitle={currentTitle}>
              <NavTabs user={user} />
              <div className={classes.minWidthHolder}>
                <div className={classes.minWidthContainer}>
                  {items[currentTab]}
                </div>
              </div>
            </Root>
            <DocumentsList
              show={docsShown}
              currentClient={currentClient || {}}
              currentTab={currentTab}
              setUpdate={setUpdate}
              update={update}
              objType={objType}
              onClose={() => setDocsShown({})}
            />
            <MoreInfo
              viewType={infoType}
              client={currentInfoObj}
              open={showMoreInfo}
              handleFunc={handleShowMoreInfo}
              update={update}
              setUpdate={setUpdate}
            />
          </>
        )}
      </ServiceContext.Consumer>
    </ServiceProvider>
  );
}

export default Service;
