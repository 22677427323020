import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { SalesContext } from 'contexts/sales';
import uniqid from 'uniqid';
import { Container, Typography } from '@material-ui/core';
import SaleFormPeople from 'sales/form/StepOne/People';
import NewPeopleForm from 'sales/form/StepOne/NewPeople';

function SaleFormStepOne({ scope, ...props }) {
  const peoples = scope.peoples ? scope.peoples.filter((people) => !people.applicant) : [];
  const salesContext = useContext(SalesContext);

  useEffect(() => {
    const allowNext = scope && peoples && peoples.length > 0;
    salesContext.handleStepOk(salesContext.currentStep, allowNext);
  // eslint-disable-next-line
  }, [props.status, scope]);

  return (
    <Container maxWidth="xl">
      {peoples && peoples.length > 0 && (
        <Typography variant="h5" paragraph>
          Portugueses cadastrados
        </Typography>
      )}
      {peoples && peoples.map(people => (
        <SaleFormPeople key={uniqid()} people={people} />
      ))}
      <NewPeopleForm />
    </Container>
  );
}

const mapStateToProps = state => ({
  scope: state.sales.scope,
  status: state.sales.status,
});

export default connect(mapStateToProps)(SaleFormStepOne);
