export const NAME = 'name';
export const EMAIL = 'email';
export const PHONE = 'phone';

export const autoAddressFields = ['address1', 'address4', 'address2', 'address3', 'city', 'state'];

export const addressFormFields = {
  address1: '',
  address2: '',
  address3: '',
  address4: '',
  city: '',
  zipcode: '',
  state: '',
  country: '',
};
