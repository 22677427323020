import React from 'react';
import {
  useDrop,
} from 'react-dnd';
import { itemTypes } from 'constants/batchpost';
import NoResults from 'global/modules/NoResults';
import { Paper, Grid, Typography } from '@material-ui/core';
import { useStyles } from 'service/style';

function ColumnLot({ index, style, children, head, fulfilled, showResults, ...props }) {
  const classes = useStyles();
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: itemTypes.process,
    drop: (item, monitor) => {
      const result = monitor.getDropResult();
      return { name: 'lot', process: item.process, lot: result ? result.lot : null, side: 'lot' }
    },
    collect: monitor => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  }),
    styles = {
      height: fulfilled ? '100%' : 'unset',
      margin: -16,
      padding: 16,
      transform: 'translate3d(0, 0, 0)',
    };

  return (
    <div ref={drop} style={{ ...style, ...styles }}>
      {head}
      {!showResults && fulfilled && !(isOver && canDrop) ? (
        <NoResults message={`Nenhum pacote encontrado`} icon="lot" />
      ) : null}
      {isOver && fulfilled && canDrop
        ? (
          <Paper
            square
            className={classes.body}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.paperAlert}>
                <Typography align="center">
                  {'Iniciar novo pacote'}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        ) : null}
      {showResults
        ? children
        : null}
    </div>
  )
}

export default ColumnLot;
