import React from 'react';
import Tween from 'rc-tween-one';
import { makeStyles, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { times } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import uniqid from 'uniqid';
import * as Icons from '../sources/Icons';

const useStyles = makeStyles((theme) => ({
  surface: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    left: 0,
    top: 0,
    backgroundColor: theme.palette.common.white,
  },
  bigode: {
    margin: '0 auto',
    display: 'block',
    width: 110,
    height: '100%',
    position: 'relative',
    animation: 'shake 1500ms linear 0ms infinite',
  },
  loadingPaper: {
    backgroundColor: 'transparent',
  },
  linearProgress: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
  linearProgressBar: {
    opacity: 0.2,
  },
  skeletonBg: {
    background: 'rgba(0,0,0,0.08)',
    '&.fade': {
      background: 'linear-gradient(180deg, rgba(0,0,0,0.08) 0%, rgba(0,0,0,0.08) 75%, rgba(0,0,0,0) 100%)',
    },
  },
}));

export function LoadingComponent({
  width, height, margin, elevation, rows, fade, style,
}) {
  const classes = useStyles();

  const el = [];
  times(rows, () => {
    el.push(
      <Skeleton key={uniqid()} width={width} height={height} style={{ margin: `${margin} 0`, ...style }} className={clsx(classes.skeletonBg, fade ? 'fade' : 'regular')} />,
    );
  });

  return (
    <Paper
      className={classes.loadingPaper}
      elevation={elevation}
    >
      {el}
    </Paper>
  );
}

LoadingComponent.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  style: PropTypes.object,
  fade: PropTypes.bool,
  elevation: PropTypes.number,
  rows: PropTypes.number,
};

LoadingComponent.defaultProps = {
  width: '100%',
  height: '60px',
  margin: '15px',
  elevation: 0,
  style: {},
  fade: false,
  rows: 1,
};
function Loading() {
  const classes = useStyles();
  return (
    <div className={classes.surface}>
      <style>
        {'.bgd1 { fill:url(#gradientthis); }'}
      </style>
      <Tween
        animation={[
          { translateX: 0, translateY: 0, rotate: '0deg', duration: 50 },
          { translateX: 5, translateY: 6, rotate: '30deg', duration: 250 },
          { translateX: -5, translateY: 6, rotate: '-30deg', duration: 250 },
        ]}
        style={{
          height: '100%',
        }}
        repeat={-1}
        yoyo
      >
        <Icons.Loading classes={{ root: classes.bigode }} />
      </Tween>
    </div>
  );
}

export default Loading;
