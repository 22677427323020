
import { grey } from '@material-ui/core/colors';
import { fade } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  bolder: {
    fontWeight: 600,
  },
  divider: {
    backgroundColor: grey[300],
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  grid: {
    '&.specific': {
      backgroundColor: fade(theme.palette.primary.main, 0.08),
    },
  },
  container: {
    '&.specific': {
      width: '100%',
      margin: 0,
    },
  },
  checkBox: {
    '&.error': {
      color: theme.palette.error.main,
    },
    '&.textPrimary': {
      color: theme.palette.text.secondary,
    },
    '&.checked': {
      color: theme.palette.primary.main,
    },
  },
}));
