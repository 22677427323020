import React, { useEffect } from 'react';
import { element, func } from 'prop-types';
import { connect } from 'react-redux';
import UserProvider from 'providers/user';
import { insertToken } from 'api';
import { fetchAll as fetchAllCertificates } from 'store/actions/certificate';
import { fetchAll as fetchAllProducts } from 'store/actions/product';
import { fetchAll as fetchAllSteps } from 'store/actions/steps';
import { fetchConselhos } from 'store/actions/sales';

function FillBasicData({ children, products, steps, certificates, ...props }) {
  useEffect(() => {
    insertToken();
    products();
    steps();
    certificates();
    props.fetchConselhos();
  }, [products, steps, certificates]);

  return (
    <UserProvider>
      {children}
    </UserProvider>
  );
}

FillBasicData.propTypes = {
  children: element.isRequired,
  products: func.isRequired,
  steps: func.isRequired,
  certificates: func.isRequired,
};

const actions = {
  certificates: fetchAllCertificates,
  products: fetchAllProducts,
  steps: fetchAllSteps,
  fetchConselhos: fetchConselhos
};

export default connect(null, actions)(FillBasicData);
