import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import '../styles/chatbot.css';
import { history } from '../store';

function ChatBot (props) {

    useEffect(() => {
        if (props.session.status === 'LOGGED' && (props.session.user !== null && props.session.user.userType !== 'CUSTOMER') && history.location.pathname !== '/pagamentos' && history.location.pathname !== '/portugueses' && history.location.pathname !== '/portugueses/check') {
            var script = document.createElement("script");
            script.type = 'text/javascript';
            script.async = true;
            script.innerHTML = "var $_Huggy = { defaultCountry: '+55', widget_id: '25674', company: '317059' }; (function(i,s,o,g,r,a,m){ i[r]={context:{id:'73c8311ec498371d95eaa2ab4f9f6aed'}};a=o;o=s.createElement(o); o.async=1;o.src=g;m=s.getElementsByTagName(a)[0];m.parentNode.insertBefore(o,m); })(window,document,'script','https://js.huggy.chat/widget.min.js','pwz');";
            document.body.appendChild(script);

        }
    }, [props.session]);

    return(
        <div></div>
    );
}

const mapStateToProps = (state) => ({
    session: state.session
});

export default connect(mapStateToProps, null)(ChatBot);