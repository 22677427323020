/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { localeDate } from 'helpers';
import { Link, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { PICK_DATE_ARRIVED, PICK_DATE_SENT } from 'constants/service/menus';
import { PROCESS } from 'constants/service';
import GridDocs from 'service/modules/GridDocs';
import { object, func } from 'prop-types';
import { useStyles } from 'service/style';

function GridActions({
  process, handleDialogDatePick, setDocsShown,
}) {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={3} className={classes.centeredGrid}>
        {process.products[0] && (
          <Typography variant="body1">{process.products[0].title}</Typography>
        )}
      </Grid>
      <Grid item xs={3} className={clsx(classes.centeredGrid, classes.noPadding)}>
        <Link
          component="button"
          variant="body1"
          align="center"
          color="primary"
          style={{ height: '100%' }}
          aria-label={`Selecionar data de envio dos documentos do processo de ${process.applicant.name} para apostilamento`}
          onClick={(e) => handleDialogDatePick(true, process, PICK_DATE_SENT, e)}
        >
          {process.sent_to_apostilate_date ? localeDate(process.sent_to_apostilate_date) : 'Selecionar data'}
        </Link>
      </Grid>
      <Grid item xs={3} className={clsx(classes.centeredGrid, classes.noPadding)}>
        <Link
          component="button"
          variant="body1"
          align="center"
          color="primary"
          aria-label={`Selecionar data de chegada dos documentos do processo de ${process.applicant.name} para apostilamento`}
          onClick={(e) => handleDialogDatePick(true, process, PICK_DATE_ARRIVED, e)}
        >
          {process.receive_to_apostilate_date ? localeDate(process.receive_to_apostilate_date) : 'Selecionar data'}
        </Link>
      </Grid>
      <GridDocs
        currentClient={process}
        xs={3}
        scopeId={process.sales_scope.id}
        clientName={process.applicant.name}
        setDocsShown={setDocsShown}
        docType={PROCESS}
      />
    </Grid>
  );
}

GridActions.propTypes = {
  process: object.isRequired,
  handleDialogDatePick: func.isRequired,
  setDocsShown: func.isRequired,
};

export default GridActions;
