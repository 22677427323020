import React from 'react';
import {
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';
import PhoneField from 'global/fields/PhoneField';

function Contact({
  handleChangeForm,
  form,
}) {
  return (
    <>
      <Typography variant="h5" paragraph>Contato</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            type="email"
            name="email"
            label="Email"
            value={form.email}
            required
            onChange={e => handleChangeForm('email', e.target.value.replace(' ', ''))}
            InputLabelProps={{
              shrink: Boolean(form.email),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneField
            fullWidth
            required
            variant="outlined"
            name="phone"
            label="Telefone"
            value={form.phone != null ? form.phone : ''}
            onChange={e => handleChangeForm('phone', e || '')}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Contact;
