// @ts-nocheck
import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { menuLinks, serviceMenu, masterMenu, menuGlobalInternal } from 'constants/menu';
import {
  Container,
  AppBar,
  Toolbar,
  Menu,
  ListItem,
  Button,
  IconButton,
  List,
  Typography,
  InputBase,
  Divider,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { Menu as MenuIcon, AccountCircle, Search as SearchIcon, ArrowDropDown as ArrowDownIcon } from '@material-ui/icons';
import { UserContext } from 'contexts/user';
import clsx from 'clsx';
import _ from 'lodash';
import { logout } from 'store/actions/session';
import * as $_ from 'global/functions';
import { CircleLogo, Logo } from 'global/sources/Icons';
import { INTERNAL, MASTER, MANAGER } from 'constants/user';
import { useStyles } from './style';
import CustomDrawer from './modules/CustomDrawer';

function Header({ noSearch, ...props }) {
  const classes = useStyles();

  const [drawer, setDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const { user, isMaster, isInternal, userService, isManager } = useContext(UserContext);
  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);
  const handleDrawer = () => setDrawer(!drawer);
  const showSearch = !noSearch && _.includes([INTERNAL, MASTER, MANAGER], user.userType);
  return (
    <AppBar
      elevation={2}
      className={classes.appbarRoot}
    >
      <Container maxWidth="xl" className={classes.headerContainer}>
        <Toolbar disableGutters className={classes.toolbarRoot}>
          {!showSearch && <div className={classes.grow} />}
          <Link to="/" className={clsx(classes.headerButton, !showSearch ? classes.translateCenter : 'leftPosition')}>
            <CircleLogo className={classes.smallLogo} />
            <Logo className={classes.logo} />
          </Link>
          <div className={classes.grow} />
          {showSearch && (
            <div className={clsx(classes.search, classes.translateCenter)}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Buscar..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'Buscar' }}
                onKeyDown={(e) => {
                  const { value } = e.target;
                  if ($_.deburrify(e.key) === 'enter' && value !== '') {
                    window.location = `/buscar/${value.trim()}`;
                  }
                }}
              />
            </div>
          )}
          {showSearch && <div className={classes.grow} />}
          <Button
            aria-haspopup="true"
            color="primary"
            className={clsx(classes.userBtn)}
            onClick={handleOpenMenu}
          >
            <Typography
              className={clsx(classes.userName, 'userName')}
            >
              {user.name}
            </Typography>
            <AccountCircle
              classes={{
                root: classes.userMenuIcon,
              }}
            />
            <ArrowDownIcon className="downIcon" />
          </Button>
          <IconButton
            onClick={handleDrawer}
            className={classes.btnDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-user"
            elevation={2}
            anchorEl={anchorEl}
            keepMounted
            open={openMenu}
            variant="menu"
            onClose={handleCloseMenu}
            classes={{
              paper: classes.menuPaper,
            }}
          >
            <List>
              {userService && !isMaster && !isManager && serviceMenu.map((item) => (
                <Link key={item.link} className={classes.menuLink} to={item.link}>
                  <ListItem>
                    <ListItemIcon classes={{ root: classes.listIcon }}>{item.icon}</ListItemIcon>
                    <ListItemText>{item.title}</ListItemText>
                  </ListItem>
                </Link>
              ))}
              {(isMaster || isManager) && masterMenu.map((item) => (
                <Link key={item.link} className={classes.menuLink} to={item.link}>
                  <ListItem>
                    <ListItemIcon classes={{ root: classes.listIcon }}>{item.icon}</ListItemIcon>
                    <ListItemText>{item.title}</ListItemText>
                  </ListItem>
                </Link>
              ))}
              {userService && <Divider className={classes.divider} />}
              {isInternal && menuGlobalInternal.map((item) => (
                <Link key={item.link} className={classes.menuLink} to={item.link}>
                  <ListItem>
                    <ListItemIcon classes={{ root: classes.listIcon }}>{item.icon}</ListItemIcon>
                    <ListItemText>{item.title}</ListItemText>
                  </ListItem>
                </Link>
              ))}
              {isInternal && <Divider className={classes.divider} />}
              {menuLinks.map((itemMenu) => {
                if (_.includes(itemMenu.userType, user.userType)) {
                  return (
                    <Link key={itemMenu.link} className={classes.menuLink} to={itemMenu.link}>
                      <ListItem>{itemMenu.title}</ListItem>
                    </Link>
                  );
                }
                return null;
              })}
              <Link
                onClick={(e) => {
                  props.logout();
                  e.preventDefault();
                }}
                to="/"
                className={classes.menuLink}
              >
                <ListItem>Sair</ListItem>
              </Link>
            </List>
          </Menu>
          <CustomDrawer
            anchor="right"
            open={drawer}
            onClose={handleDrawer}
            onOpen={handleDrawer}
          >
            <List>
              <Typography variant="h6" className={classes.userName} gutterBottom>{user.name}</Typography>
              <Divider />
              {userService && serviceMenu.map((item) => (
                <Link key={item.link} className={classes.menuLink} to={item.link}>
                  <ListItem>
                    <ListItemIcon classes={{ root: classes.listIcon }}>{item.icon}</ListItemIcon>
                    <ListItemText>{item.title}</ListItemText>
                  </ListItem>
                </Link>
              ))}
              {userService && <Divider className={classes.divider} />}
              {isInternal && menuGlobalInternal.map((item) => (
                <Link key={item.link} className={classes.menuLink} to={item.link}>
                  <ListItem>
                    <ListItemIcon classes={{ root: classes.listIcon }}>{item.icon}</ListItemIcon>
                    <ListItemText>{item.title}</ListItemText>
                  </ListItem>
                </Link>
              ))}
              {isInternal && <Divider className={classes.divider} />}
              {menuLinks.map((itemMenu) => {
                if (_.includes(itemMenu.userType, user.userType)) {
                  return (
                    <Link key={itemMenu.link} className={classes.menuLink} to={itemMenu.link}>
                      <ListItem>{itemMenu.title}</ListItem>
                    </Link>
                  );
                }
                return null;
              })}
              <Link
                onClick={(e) => {
                  props.logout();
                  e.preventDefault();
                }}
                to="/"
                className={classes.menuLink}
              >
                <ListItem>Sair</ListItem>
              </Link>
            </List>
          </CustomDrawer>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

Header.propTypes = {
  noSearch: PropTypes.bool,
};
Header.defaultProps = {
  noSearch: false,
};

export default connect(null, { logout })(Header);
