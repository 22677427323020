import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialogMoreInfo: {
    padding: `${theme.spacing(6, 3, 3, 3)} !important`,
    minHeight: '100vh',
  },
  subset: {
    paddingTop: theme.spacing(3),
  },
  gridInfo: {
    maxWidth: 200,
  },
  gridContainer: {
    paddingBottom: theme.spacing(1),
    position: 'relative',
  },
  beforeMdPadding: {
    [theme.breakpoints.down('md')]: {
      paddingRight: `${theme.spacing(5.5)}px !important`,
    },
  },
  fromMdPadding: {
    [theme.breakpoints.up('md')]: {
      paddingRight: `${theme.spacing(5.5)}px !important`,
    },
  },
  closeBtn: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 4,
  },
  moreverticonDialog: {
    top: theme.spacing(7),
    right: theme.spacing(1),
    position: 'absolute',
    zIndex: 4,
  },
  previousButton: {
    top: theme.spacing(3),
    left: theme.spacing(3),
    position: 'absolute',
    zIndex: 4,
  },
  link: {
    marginRight: theme.spacing(1),
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.main,
    },
  },
  smallPaper: {
    padding: theme.spacing(2),
    boxShadow: theme.shadows[2],
    minHeight: '100%',
    position: 'relative',
    '&.focused': {
      boxShadow: theme.shadows[6],
    },
    '&.pointer': {
      cursor: 'pointer',
    },
    '&.pointer:hover': {
      boxShadow: theme.shadows[4],
    },
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  transitions: {
    transition: theme.transitions.create('all'),
  },
  editBtn: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    transition: theme.transitions.create('all'),
    transform: 'rotate(0deg)',
    '&.editing': {
      transform: 'rotate(180deg)',
    },
  },
  maxWidthName: {
    paddingRight: 30,
    minHeight: theme.spacing(5),
  },
  pLine: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    whiteSpace: 'nowrap',
    lineHeight: 1.8,
  },
  icon: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
}));
