import React from 'react';
import { semanticDate } from 'global/functions';
import {
  Grid,
  Typography,
} from '@material-ui/core';

function InfosGrid({ infos, client, isProcess, classes, ...props }) {
  return (
    <Grid container spacing={2} className={classes.gridContainer}>
      {isProcess && client.date_initialised && (
        <Grid className={classes.gridInfo} item>
          <Typography color="primary" gutterBottom>Data de início</Typography>
          <Typography variant="subtitle2">{semanticDate(client.date_initialised, true)}</Typography>
        </Grid>
      )}
      <Grid className={classes.gridInfo} item>
        <Typography color="primary" gutterBottom>Última atualização</Typography>
        <Typography variant="subtitle2">{semanticDate(client.updated_at, true)}</Typography>
      </Grid>
      {isProcess && (
        <Grid className={classes.gridInfo} item>
          <Typography color="primary" gutterBottom>Assento</Typography>
          <Typography variant="subtitle2">{client.assento || 'Em busca'}</Typography>
        </Grid>
      )}
      {isProcess && infos.products && (
        <Grid className={classes.gridInfo} item>
          <Typography color="primary" gutterBottom>Tipo de processo</Typography>
          <Typography variant="subtitle2">{infos.products[0] && infos.products[0].title}</Typography>
        </Grid>
      )}
      {isProcess && client.step && (
        <Grid className={classes.gridInfo} item>
          <Typography color="primary" gutterBottom>Etapa atual</Typography>
          <Typography variant="subtitle2">{client.step.public_name}</Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default InfosGrid;
