/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Grid, Link } from '@material-ui/core';
import {
  number, object, string, func, oneOfType,
} from 'prop-types';
import { stopPropagation } from 'global/functions';
import { useStyles } from 'service/style';
import { PROCESS, SCOPE } from 'constants/service';

function GridDocs({
  currentClient, clientName, docType, setDocsShown, scopeId, xs, ...props
}) {
  const classes = useStyles(props);
  return (
    <Grid item xs={xs} className={classes.centeredGrid}>
      <Link
        className={classes.link}
        component="button"
        onClick={(e) => setDocsShown(currentClient, e, docType)}
      >
        {'Documentos'}
      </Link>
      <Link
        className={classes.link}
        href={`/contrato/${scopeId}`}
        aria-label={docType === SCOPE ? `Contrato de ${clientName}` : `Contrato relacionado ao processo de ${clientName}`}
        onClick={(e) => { window.open(`/contrato/${scopeId}`, '_blank'); stopPropagation(e); }}
        target="_blank"
      >
        {'Contrato'}
      </Link>
    </Grid>
  );
}

GridDocs.defaultProps = {
  xs: 1,
  docType: PROCESS,
};

GridDocs.propTypes = {
  xs: number,
  currentClient: object.isRequired,
  docType: string,
  setDocsShown: func.isRequired,
  scopeId: oneOfType([string, number]).isRequired,
  clientName: string.isRequired,
};

export default GridDocs;
