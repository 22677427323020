import React from 'react';
import { Typography } from '@material-ui/core';
import { createMarkup } from 'global/functions';
import { useStyles } from './style';

function ContractBody({ content }) {
  const classes = useStyles();
  if (content) {
    return (
      <div
        className={classes.contractBody}
        dangerouslySetInnerHTML={createMarkup(content)}
      />
    );
  }
  return (
    <Typography>
      {'Contrato não encontrado. Por favor, entre em contato com o seu vendedor.'}
    </Typography>
  );
}

export default ContractBody;
