import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  Typography,
  Button,
  Divider,
  TextField,
  Grid,
} from '@material-ui/core';
import { getLabel } from 'constants/newClient';
import * as definitions from 'store/definitions/customer';
import { validaCPF } from 'global/functions';
import { showNotification } from 'store/actions/notifications';
import { NOTIFICATION_TYPE_ERROR } from 'constants/notification';
import Address from './Address';
import Personal from './Personal';
import Contact from './Contact';
import { createPostObject } from './functions';
import cep from 'cep-promise';

function Form({
  form, setForm, classes, createCustomer, action, inputClientId, sentValues, externalUser, ...props
}) {
  const handleSelectCountry = (selectedCountry) => setForm({
    ...form, country: selectedCountry.alpha2Code,
  });

  const loadAddressByZipCode = () => {
    const { zipCode } = form;
    if (zipCode && zipCode.length === 9) {
      cep(zipCode)
        .then((res) => {
          setForm({
            ...form,
            zipCode: res.cep,
            address1: res.street,
            address4: res.neighborhood,
            city: res.city,
            state: res.state,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleSend = (e) => {
    e.preventDefault();
    if (validaCPF(form.cpf)) {
      createCustomer(createPostObject(form));
    } else {
      props.showNotification(NOTIFICATION_TYPE_ERROR, 'CPF incorreto. Por favor, insira um número de CPF válido.');
    }
  };

  const handleChangeForm = (field, value) => {
    setForm({ ...form, [field]: value });
  };

  useEffect(() => {
    //* AUTOCOMPLETE WORKAROUND
    const countryEl = document.getElementById('client-country');
    if (countryEl) countryEl.setAttribute('autocomplete', 'nope');
  }, []);

  const handleError = () => {
    var message = '';

    if (props.errorMessage != null) {
      var items = Object.keys(props.errorMessage);
      items.map((item) => message += `${props.errorMessage[item][0]}. `);
    }

    return message
  }

  useEffect(() => {
    handleError();
  }, [props.errorMessage]);

  const necessaryProps = { form, handleChangeForm };

  const addressProps = {
    ...necessaryProps,
    loadAddressByZipCode,
    getLabel,
    classes,
    handleSelectCountry,
  };
  const personalProps = {
    ...necessaryProps,
  };
  const contactProps = {
    ...necessaryProps,
  };

  switch (action) {
    case definitions.CREATE_CUSTOMER_FULFILLED:
      return (
        <Typography
          variant="h5"
          color="textSecondary"
          align="center"
          style={{ paddingTop: 24 }}
          paragraph
        >
          As informações foram enviadas com sucesso!
        </Typography>
      );
    default:
      return (
        <form onSubmit={handleSend}>
          <Typography variant="h5" color="textSecondary" paragraph>Preencha abaixo os dados da pessoa que ficará responsável pela contratação dos serviços.</Typography>
          <Paper elevation={3}>
            {inputClientId && (
              <Grid container spacing={4} style={{ marginTop: 0, marginBottom: 0 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    required
                    label="PT do Cliente"
                    value={form.clientId}
                    name="clientId"
                    onChange={(e) => handleChangeForm('clientId', e.target.value)}
                    helperText={!externalUser ? 'Por favor, confira se o PT é o mesmo que no PipeDrive' : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    required
                    label="Número do deal no Pipedrive"
                    value={form.internalId}
                    name="internalId"
                    onChange={(e) => handleChangeForm('internalId', e.target.value)}
                    helperText={!externalUser ? 'Este é o número do "deal" no PipeDrive' : ''}
                  />
                </Grid>
              </Grid>
            )}
            <Contact {...contactProps} />
            <Divider className={classes.divider} />
            <Personal {...personalProps} />
            <Divider className={classes.divider} />
            <Address {...addressProps} />
            <Button
              variant="contained"
              color="primary"
              className={classes.sendButton}
              type="submit"
              size="large"
            >
              Enviar
            </Button>
            {action === definitions.CREATE_CUSTOMER_REJECTED && (
              <Typography
                variant="h6"
                color="error"
                style={{ paddingTop: 24 }}
              >
                {handleError()}

              </Typography>
            )}
          </Paper>
        </form>
      );
  }
}

export default connect(null, { showNotification })(Form);
