import React, { useState, useEffect } from 'react';
import CopyElement from 'global/CopyElement';
import * as $_ from 'global/functions';
import clsx from 'clsx';
import {
  Call as PhoneIcon,
  Email as EmailIcon,
} from '@material-ui/icons';
import {
  Grid,
  Typography,
  Link,
} from '@material-ui/core';
import { SCOPE } from 'constants/service';
import { useStyles } from './style';
import { readableBRPhoneNumbers } from 'helpers';

function HeaderGrid({ isProcess, infos, type, handleCloseDialog, handleDocsShown, ...props }) {
  const classes = useStyles(props);
  const [infoss, setInfoss] = useState(infos);

  console.log(infoss);

  useEffect(() => setInfoss({ ...infos }), [infos]);

  let phoneNumber = '';
  if (infoss.customer && infoss.customer.phone) phoneNumber = readableBRPhoneNumbers(infoss.customer.phone);
  return (
    <Grid container spacing={2} className={classes.gridContainer}>
      <Grid item xs={12} md={8} className={classes.beforeMdPadding}>
        <Typography variant="subtitle2" color="textSecondary">
          {isProcess ? 'Processo de' : 'Cliente'}
        </Typography>
        <CopyElement placement="top-start" value={infoss.clientName}>
          <Typography variant="h5">
            {infoss.clientName}
          </Typography>
        </CopyElement>
        <CopyElement placement="top-start" value={infoss.processId}>
          <Typography variant="h6">
            {infoss.processId}
          </Typography>
        </CopyElement>
        {infoss.scopeId && (
          <CopyElement placement="top-start" value={infoss.scopeId}>
            <Typography variant="h6" color="textSecondary">
              {`ID da venda ${infoss.scopeId}`}
            </Typography>
          </CopyElement>
        )}
        {infoss.salesman && infoss.salesman.id
          ? (
            <Typography color="textSecondary">
              {'Vendedor responsável: '}
              {infoss.salesman.name ? (
                <CopyElement placement="top-start">
                  {infoss.salesman.name}
                </CopyElement>
              ) : null}
              {infoss.salesman.email ? (
                <CopyElement placement="top-start" value={infoss.salesman.email}>
                  {` (${infoss.salesman.email})`}
                </CopyElement>
              ) : null}
            </Typography>
          ) : null}
      </Grid>
      <Grid item xs={12} md={4} className={classes.fromMdPadding}>
        <Typography className={clsx(classes.pLine)}>
          <br />
          <EmailIcon className={classes.icon} color="disabled" fontSize="inherit" />
          <CopyElement>{infoss.customer.email}</CopyElement>
        </Typography>
        <Typography className={clsx(classes.pLine)}>
          <PhoneIcon className={classes.icon} color="disabled" fontSize="inherit" />
          <CopyElement>{phoneNumber}</CopyElement>
        </Typography>
        <Typography variant="body1">
          <Link
            className={classes.link}
            component="button"
            onClick={() => handleDocsShown()}
          >
            {'Documentos'}
          </Link>
          <Link
            className={classes.link}
            href={`/contrato/${infoss.scopeInternalId}`}
            aria-label={type === SCOPE ? `Contrato de ${infoss.clientName}` : `Contrato relacionado ao processo de ${infoss.clientName}`}
            onClick={(e) => { window.open(`/contrato/${infoss.scopeInternalId}`, '_blank'); $_.stopPropagation(e); }}
            target="_blank"
          >
            {'Contrato'}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default HeaderGrid;
