import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import uniqid from 'uniqid';
import { Dialog, DialogContent, Grid, Typography, DialogTitle, DialogActions, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import FilesPreview from 'global/modules/FilesPreview';
import FileBox from 'global/filebox';
import { deleteDocument, sendDocument } from 'store/actions/documents';
import { showNotification } from 'store/actions/notifications';
import { NOTIFICATION_TYPE_ERROR } from 'constants/notification';
import { MESSAGE_ERROR_COMMON_ERROR } from 'constants/common/messages';
import { changeStepProcessSendDocs } from 'store/actions/processes';
import { STEP_DOCSOK } from 'constants/process';
import { FULFILLED } from 'constants/session';

function Dialogs({ classes, context, setUpdate, update, actionStatus, ...props }) {
  const { currentClient, confirmEndStepDialog, handleConfirmEndStepDialog } = context;
  const [file, setFile] = useState([]);
  //  const [checked, setChecked] = useState(false);
  const checked = false;
  const [notify, setNotify] = useState(true);
  const handleRemoveDoc = () => setFile([]);

  const handleUploadDocEndstep = () => {
    if (
      (checked && currentClient.id)
      || (!checked && currentClient.applicant.id && file.length > 0)
    ) {
      props.changeStepProcessSendDocs(
        currentClient.id,
        STEP_DOCSOK,
        false,
        checked ? 0 : currentClient.applicant.id,
        checked ? 0 : file,
      );
    } else {
      props.showNotification(NOTIFICATION_TYPE_ERROR, MESSAGE_ERROR_COMMON_ERROR);
    }
  };

  const handleChangeNotify = () => setNotify(!notify);

  useEffect(() => {
    if (confirmEndStepDialog && actionStatus === FULFILLED) {
      handleConfirmEndStepDialog();
      setUpdate(uniqid());
    }
    // eslint-disable-next-line
  }, [actionStatus]);

  // const handleCheck = () => setChecked(!checked);

  return (
    <Dialog
      open={confirmEndStepDialog}
      onClose={handleConfirmEndStepDialog}
      scroll="body"
      aria-labelledby="dialog-endstep-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="dialog-endstep-title">
        {'Anexar e confirmar documentos'}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* }
          <Grid item xs={12}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={checked}
                  className={classes.inputCheck}
                  onChange={handleCheck}
                />
              )}
              label="Não existem documentos a serem anexados no momento"
            />
          </Grid>
              { */}
          {file.length > 0 && !checked && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  {'Arquivo anexado'}
                </Typography>
              </Grid>
              {file.map((annex) => (
                <FilesPreview
                  key={uniqid()}
                  doc={annex}
                  removeDoc={handleRemoveDoc}
                  expandable
                  fullWidth={file.length === 1}
                  sm={12 / file.length > 3 ? 12 / file.length : 3}
                />
              ))}
            </>
          )}
          {file.length === 0 && !checked && (
            <Grid item xs={12}>
              {`Por favor, anexe os documentos referentes ao processo de ${currentClient.applicant.name}`}
            </Grid>
          )}
          {!checked && (
            <Grid item xs={12}>
              {file.length > 0 && <Typography variant="h6" gutterBottom>Substituir arquivo</Typography>}
              <FileBox
                id="annex-pt-doc"
                file={file}
                setFile={setFile}
                multiple={false}
              />
            </Grid>
          )}
          {file.length > 0 || checked
            ? (
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {`Confirmar que os documentos de ${currentClient.applicant.name} estão completos?`}
                </Typography>
              </Grid>
            )
            : null}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox checked={notify} onChange={() => handleChangeNotify()} />
              }
              label="Notificar o cliente por e-mail"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirmEndStepDialog}
          variant="outlined"
          color="secondary"
        >
          {'Cancelar'}
        </Button>
        <Button
          onClick={handleUploadDocEndstep}
          disabled={file.length === 0 && !checked}
          variant="outlined"
          color="primary"
        >
          {'Confirmar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const actions = {
  deleteDocument,
  sendDocument,
  showNotification,
  changeStepProcessSendDocs,
};

const mapStateToProps = (state) => ({
  steps: state.steps.collection,
  actionStatus: state.processes.actionStatus,
});

export default connect(mapStateToProps, actions)(Dialogs);
