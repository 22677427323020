import React from 'react';
import {
  useDrop,
} from 'react-dnd';
import { itemTypes } from 'constants/batchpost';
import NoResults from 'global/modules/NoResults';
import { Paper, Grid, Typography } from '@material-ui/core';
import { useStyles } from 'service/style';

function ColumnProcess({
  style, children, head, showResults, fulfilled, ...props
}) {
  const classes = useStyles();
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [itemTypes.processinLot, itemTypes.lot],
    drop: (item, monitor) => {
      const result = monitor.getDropResult();
      return ({
        name: 'processes', process: item.process, lot: result ? result.lot : null, side: 'processes'
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });
  const styles = {
    height: fulfilled ? '100%' : 'unset',
    margin: -16,
    padding: 16,
    transform: 'translate3d(0, 0, 0)',
  };

  return (
    <div ref={drop} style={{ ...style, ...styles }}>
      {head}
      {!showResults && fulfilled && !(isOver && canDrop)
        ? (
          <NoResults message="Nenhum processo encontrado" />
        ) : null}
      {isOver && fulfilled && canDrop
        ? (
          <Paper
            square
            className={classes.body}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.paperAlert}>
                <Typography align="center">
                  {'Retirar do pacote'}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        ) : null}
      {showResults
        ? children
        : null}
    </div>
  );
}

export default ColumnProcess;
