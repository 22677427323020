export const infosCitizen = [
  {
    label: 'Tipo de certificado',
    name: 'certificate_type',
  },
  {
    label: 'Data de nascimento',
    name: 'birthdate',
  },
  {
    label: 'Local de nascimento',
    name: 'birthplace',
  },
  {
    label: 'Assento',
    name: 'assento',
  },
  {
    label: 'Concelho',
    name: 'concelho',
  },
  {
    label: 'Consulado',
    name: 'consulate',
  },
  {
    label: 'Número de assento',
    name: 'document_number',
  },
  {
    label: 'CRC',
    name: 'crc',
  },
  {
    label: 'Ano do assento',
    name: 'assento_year',
  },
  {
    label: 'Nome do pai',
    name: 'father_name',
  },
  {
    label: 'Nome da mãe',
    name: 'mother_name',
  },
];

export const infosApplicant = [
  {
    label: 'Nascimento',
    name: 'birthdate',
  },
  {
    label: 'Local de nascimento',
    name: 'birthplace',
  },
  {
    label: 'Gênero',
    name: 'gender',
  },
  {
    label: 'Assento',
    name: 'assento',
  },
];
