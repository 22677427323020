import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { ServiceContext } from 'contexts/service';
import { UserContext } from 'contexts/user';
import * as $_ from 'global/functions';
import {
  Container, Paper, Tabs, Tab,
} from '@material-ui/core';
import { menuItems as completedMenuItems, devMenuItems } from 'constants/service';
import { clearResponses as clear } from 'store/actions/processes';
import { clearResponses as clearL } from 'store/actions/batchpost';
import { useStylesMenu } from './style';

function NavTabs({
  noMargin, user, clearResponses, clearLots, ...props
}) {
  const minMenuItemWidth = 170;
  const serviceContext = useContext(ServiceContext);
  const userContext = useContext(UserContext);
  const { currentTab, setCurrentTab } = serviceContext;
  let menuItems = [...completedMenuItems];
  if (userContext.isMaster) menuItems = [...completedMenuItems, ...devMenuItems];
  const linkItems = menuItems.map((a) => $_.deburrify(a));

  const classesmenu = useStylesMenu({ ...props, minMenuItemWidth });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleMenu = (event, newValue) => {
    clearResponses();
    clearLots();
    setCurrentTab(newValue);
    window
      .history
      .pushState(
        null,
        menuItems[newValue],
        `${window.location.origin}/servico/${linkItems[newValue]}`,
      );
    event.preventDefault();
  };

  useEffect(() => {
    const changeWindowWidth = () => setWindowWidth(window.innerWidth);
    window
      .addEventListener('resize',() => changeWindowWidth);
    return () => {
      window
        .removeEventListener('resize', () => changeWindowWidth);
    }
  }, []);

  const overFlow = (menuItems.length * minMenuItemWidth) > windowWidth;

  return (
    <Container
      maxWidth="xl"
      className={classesmenu.container}
    >
      <Paper
        className={clsx(
          classesmenu.root,
          noMargin ? 'regular' : classesmenu.margin,
        )}
      >
        <Tabs
          value={currentTab}
          onChange={handleMenu}
          classes={{
            indicator: classesmenu.indicator,
          }}
          textColor="primary"
          scrollButtons="desktop"
          TabIndicatorProps={{ children: <div /> }}
          variant={overFlow ? 'scrollable' : 'fullWidth'}
        >
          {menuItems.map((a) => (
            <Tab
              key={uniqid()}
              component="a"
              onClick={(event) => {
                event.preventDefault();
              }}
              href={$_.deburrify(a)}
              className={classesmenu.tab}
              aria-label={a}
              label={a}
              classes={{
                fullWidth: classesmenu.tabRippleBg,
                wrapper: classesmenu.tabWrapper,
              }}
            />
          ))}
        </Tabs>
      </Paper>
    </Container>
  );
}

NavTabs.propTypes = {
  noMargin: PropTypes.bool,
  user: PropTypes.object.isRequired,
  clearResponses: PropTypes.func.isRequired,
  clearLots: PropTypes.func.isRequired,
};

NavTabs.defaultProps = {
  noMargin: false,
};

export default connect(null, { clearResponses: clear, clearLots: clearL })(NavTabs);
