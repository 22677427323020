export const prepareToSubmit = (form) => ({
  birthdate: form.birthdate,
  certificate_id: form.certificate_id,
  certificate_type: form.certificate_type,
  crc: form.crc,
  assento_year: form.assento_year,
  concelho: form.concelho,
  father_name: form.father_name,
  freguesia: form.freguesia,
  mother_name: form.mother_name,
  name: form.name,
  consulate: form.consulate,
  document_number: form.document_number,
  birthplace: form.birthplace,
  docs: form.docs,
});
