import React from 'react';
import { sortArrayOfObjectsByValue } from 'global/functions';
import { connect } from 'react-redux';
import { includes, toLower } from 'lodash';
import { SaleDashboardContext } from 'contexts/saleDashboard';
import { Dialog, DialogContent, DialogTitle, TextField, DialogActions, Button, Typography, Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fetchAll } from 'store/actions/users';
import { INTERNAL_SALES, INTERNAL_COORDINATOR } from 'constants/user';
import { FULFILLED } from 'constants/common';
import { LoadingComponent } from 'global/modules/Loading';
import { Share, Delete } from '@material-ui/icons';
import { shareSale, deleteShareSale } from 'store/actions/sales';
import Row from 'service/Envio/Historic/Row';

const ShareSaleDialog = ({ users, status, fetch, saleStatus, share, deleteShareSale }) => {
  const { dialogShareSale, handleDialogShareSale, multiSelect, shared } = React.useContext(SaleDashboardContext);
  const [salesUsers, setSalesUsers] = React.useState([]);
  const [posting, setPosting] = React.useState(false);
  const [user, setUser] = React.useState({ label: '', id: null });

  const handleShare = () => {
    if (user.id) {
      multiSelect.forEach((id, index) => {
        share(id, user.id, index === (multiSelect.length - 1));
      });
      setPosting(true);
    }
  };

  const handleRemoveShare = (user, sale) => {
    deleteShareSale(sale, user);
    setPosting(true)
    handleDialogShareSale();
  }

  React.useEffect(() => {
    fetch(null, '', `SALES,SALES_COORDINATOR`, 100);
  }, [fetch]);

  React.useEffect(() => {
    if (!user) {
      setUser({ label: '', id: null });
    }
  }, [user]);

  React.useEffect(() => {
    setPosting((p) => {
      if (p && saleStatus === FULFILLED) {
        if (dialogShareSale) handleDialogShareSale();
        return false;
      }
      return p;
    });
    //eslint-disable-next-line
  }, [saleStatus]);

  React.useEffect(() => {
    if (users) {
      const usersList = users.data ? users.data : [];
      setSalesUsers([
        ...sortArrayOfObjectsByValue(
          usersList.filter((u) => (
            (u.internal_type === INTERNAL_SALES || u.internal_type === INTERNAL_COORDINATOR)
            // && !includes(toLower(u.name), 'teste')
          )),
          'name',
          true
        ).map((u) => ({ ...u, label: `${u.name} (${u.email})` }))
      ]);
    }
  }, [users]);

  return (
    <Dialog
      open={dialogShareSale}
      onClose={handleDialogShareSale}
      scroll="body"
      fullWidth
      maxWidth="md"
    >
      { shared.length > 0 ? (
        <div>
          <DialogTitle>
            {`Remover compartilhamento${shared.length > 1 ? 's' : ''}`}
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1">Clique no nome do usuário para remover o compartilhamento da venda</Typography>
            <Divider />
            {shared.map(shareItem => (
                <Button 
                  key={shareItem.name} 
                  variant="outlined" 
                  startIcon={<Delete />}
                  onClick={(e) => handleRemoveShare(shareItem.user_id, shareItem.sale_scope_id)}
                >
                    {shareItem.name}
                  </Button>
              )
            )}
          </DialogContent>
        </div>
      ) : null}
      
      <Divider variant="middle" />
      <DialogTitle>
        {`Compartilhar venda${multiSelect.length > 1 ? 's' : ''}`}
      </DialogTitle>
      <DialogContent>
        {status === FULFILLED
          ? (
            <Autocomplete
              id="share-sale"
              options={salesUsers}
              clearOnEscape
              autoHighlight
              value={user}
              onChange={(event, newValue) => {
                setUser(newValue);
              }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} fullWidth label="Compartilhar venda com " variant="outlined" />}
            />
          ) : (
            <LoadingComponent />
          )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" disabled={posting} onClick={() => handleDialogShareSale()} variant="outlined">
          {'Cancelar'}
        </Button>
        <Button color="primary" onClick={handleShare} disabled={posting || (user && user.label === '')} variant="outlined">
          {'Compartilhar '}
          <Share style={{ marginLeft: 8 }} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  users: state.listUsers.users,
  status: state.listUsers.fetchStatus,
  saleStatus: state.sales.batchStatus,
});

export default connect(mapStateToProps, { fetch: fetchAll, share: shareSale, deleteShareSale })(ShareSaleDialog);
