import { amber, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core';
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_INFO,
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_WARNING,
  NOTIFICATION_TYPE_BLACK,
} from 'constants/notification';

const styles = makeStyles(theme => ({
  default: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  [NOTIFICATION_TYPE_SUCCESS]: {
    backgroundColor: green[600],
  },
  [NOTIFICATION_TYPE_ERROR]: {
    backgroundColor: theme.palette.error.dark,
  },
  [NOTIFICATION_TYPE_INFO]: {
    backgroundColor: theme.palette.primary.main,
  },
  [NOTIFICATION_TYPE_WARNING]: {
    color: theme.palette.text.primary,
    backgroundColor: amber[200],
  },
  [NOTIFICATION_TYPE_BLACK]: {
    color: '#fff',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default styles;
