import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FULFILLED } from 'constants/session';
import SaleFormStepThree from 'sales/form/StepThree';
import { Container, Grid, Divider, Typography, Paper } from '@material-ui/core';
import NoResults from 'global/modules/NoResults';
import { connect } from 'react-redux';
import { fetchSaleScopeByClientd, setAlignment as setThis } from 'store/actions/sales';
import { LoadingComponent } from 'global/modules/Loading';
import SaleCost from 'sales/form/StepFive/SaleCost';
import CustomerInfo from 'sales/form/StepFive/CustomerInfo';
import Products from 'sales/form/StepFive/Products';
import Comments from 'sales/form/StepFive/Comments';
import { showConfirmation } from 'store/actions/confirmations';
import { showNotification } from 'store/actions/notifications';
import { NOTIFICATION_TYPE_ERROR } from 'constants/notification';
import { MESSAGE_ERROR_CLIENT_NOT_FOUND } from 'constants/common/messages';
import Countdown from 'global/modules/Countdown';
import { STEP_CHECKING } from 'constants/process';
import SingleHeader from './SingleHeader';
import CitizenCard from './modules/CitizenCard';

function Single({
  id, fetch, status, scope, push, setAlignment, confirmation, confirm, notification,
}) {
  const [phases, setPhases] = useState({});
  const [done, setDone] = useState(false);
  const [portugueses, setPortugueses] = useState([]);
  const [update, setUpdate] = useState('initial');

  const handleConfirm = () => {
    if (scope.customer) {
      const confirmed = () => {
        setAlignment(scope.id);
      };

      confirmation(
        {
          title: 'Confirmar venda',
          message: `Deseja confirmar a venda do cliente ${scope.customer.name} (${scope.client_id})?`,
        },
        {
          confirm: 'Confirmar',
          cancel: 'Cancelar',
        },
        {
          func: confirmed,
        },
      );
    } else notification(NOTIFICATION_TYPE_ERROR, MESSAGE_ERROR_CLIENT_NOT_FOUND);
  };

  useEffect(() => {
    fetch(id);
  }, [id, fetch, update]);

  useEffect(() => {
    if (Object.keys(scope).length > 0) {
      if (scope.processes && scope.processes[0] && scope.processes[0].step.name === STEP_CHECKING) {
        setDone(false);
      } else {
        setDone(true);
      }
      const buildPhases = {};
      const { processes } = scope;
      processes.forEach((process) => {
        if (buildPhases[process.phase]) {
          buildPhases[process.phase] = [...buildPhases[process.phase], process];
        } else buildPhases[process.phase] = [process];
      });
      setPhases(buildPhases);
    }
  }, [scope]);

  useEffect(() => {
    if (scope.peoples) {
      setPortugueses(scope.peoples.filter((a) => !a.applicant));
    }
  }, [scope]);

  useEffect(() => {
    if (confirm === FULFILLED && !done) {
      setDone(true);
    }
  }, [confirm, done]);

  return (
    <>
      <Container maxWidth="xl">
        <SingleHeader push={push} scope={scope} handleConfirm={handleConfirm} done={done} />
      </Container>
      {done && (
        <Container
          maxWidth="xl"
          style={{
            padding: '24px 0',
          }}
        >
          <Typography
            variant="h5"
            color="textSecondary"
            align="center"
            paragraph
          >
            {'A venda já foi confirmada!'}
          </Typography>
          <Countdown pushFunction={push} onEnd="/verificacao" />
        </Container>
      )}
      <Divider style={{ marginBottom: 32 }} />
      {status === FULFILLED && Object.keys(scope).length > 0 && !done && (
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <CustomerInfo customer={scope.customer} />
              <SaleCost payments={scope.payments} scope={scope} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper elevation={3}>
                <Typography variant="h5" paragraph>
                  {`Portugu${portugueses.length > 1 ? 'eses' : 'ês'}`}
                </Typography>
                <Grid container spacing={4}>
                  {portugueses && portugueses.map((citizen) => (
                    <CitizenCard key={`cidadao${citizen.id}`} citizen={citizen} setUpdate={setUpdate} />
                  ))}
                </Grid>
              </Paper>
              <Products scope={scope} phases={phases} />
              {scope.comment && (
                <Comments mode="CLOSED" />
              )}
            </Grid>
          </Grid>
        </Container>
      )}
      {status === FULFILLED && Object.keys(phases).length > 0 && !done && (
        <>
          <SaleFormStepThree phases={phases} />
          <Divider style={{ marginBottom: 32 }} />
        </>
      )}
      {status === FULFILLED && Object.keys(scope).length === 0 && !done && (
        <NoResults />
      )}
      {status !== FULFILLED && !done && (
        <LoadingComponent margin={0} />
      )}
    </>
  );
}

Single.propTypes = {
  id: PropTypes.string.isRequired,
  fetch: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  scope: PropTypes.object,
  push: PropTypes.func.isRequired,
  setAlignment: PropTypes.func.isRequired,
  confirmation: PropTypes.func.isRequired,
  notification: PropTypes.func.isRequired,
  confirm: PropTypes.string.isRequired,
};

Single.defaultProps = {
  scope: {},
};

const mapStateToProps = (state) => ({
  scope: state.sales.scope,
  status: state.sales.scopeStatus,
  confirm: state.sales.confirm,
});

const actions = {
  fetch: fetchSaleScopeByClientd,
  setAlignment: setThis,
  confirmation: showConfirmation,
  notification: showNotification,
};

export default connect(mapStateToProps, actions)(Single);
