/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-lines */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import uniqid from 'uniqid';
import {
  Grid, TextField, MenuItem, Typography, FormControlLabel, Checkbox, makeStyles, FormControl, FormHelperText,
} from '@material-ui/core';
import { processForm, relations, PROCESS_FORM_MODE_EDIT, sameRelation } from 'constants/sales';
import FooterForm from 'sales/form/StepTwo/FooterForm';
import { showNotification } from 'store/actions/notifications';
import { createProcess, removeProcess, updateProcess, updatePerson, removeDocument } from 'store/actions/sales';
import { showConfirmation } from 'store/actions/confirmations';
import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_WARNING } from 'constants/notification';
import * as imports from 'objects/imports';
import { Skeleton } from '@material-ui/lab';
import { prepareToSubmit, validateForm, prepareToUpdateProcess, prepareToUpdate, prepareToUpdatePerson } from './functions';
import SaleFormStepTwoDoc from './Docs';
import { sortBy } from 'helpers';
import { MESSAGE_ALERT_CANT_REMOVE_PROCESS } from 'constants/common/messages';
import { getFormObj } from './actions';
import MoreSales from './MoreSales';
import ProductPrice from './ProductPrice';
import FilesPreview from 'global/modules/FilesPreview';
import _ from 'lodash';
import Filebox from 'global/filebox';
import { ContactSupportOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  inputCheck: {
    color: theme.palette.grey[700],
  },
}));

function SaleFormStepTwoForm({
  mode, process, people, ...props
}) {
  const classes = useStyles(props);
  const formObj = getFormObj(process, people);
  const [form, setForm] = useState(formObj);
  const [hasError, setHasError] = useState(false);
  const [previousPerson, setPreviousPerson] = useState('new');
  const hostsList = props.peoples && process
    ? props.peoples.filter((a) => process.applicant.id !== a.id)
    : props.peoples;
  const personList = props.peoples;
  const [docsToAdd, setDocsToAdd] = React.useState([]);
  const [errors, setErrors] = React.useState([]);

  const handleSelectProduct = (e, selected) => {
    const select = [{ id: selected.props.value, apostilate: false, use_old_price: false }];
    setForm({ ...form, products: select, product: [selected.props.value], amounts: {}, extraProducts: [] });
  };

  const handleChange = (name, value) => {
    let newForm = form;
    if (name === 'hosts' && form.applicantId === parseInt(value)) {
      newForm = { ...newForm, parent_type: sameRelation, hosts: value };
    } else {
      newForm = { ...newForm, [name]: value };
    }
    setForm({ ...newForm });
  }

  const handleChangePreviousPerson = (value) => {
    setPreviousPerson(value);
    if (value !== 'new') {
      const thisPerson = props.peoples && props.peoples.find(a => a.id === value);
      setForm({
        ...form,
        name: thisPerson.name,
        birthdate: thisPerson.birthdate,
        gender: thisPerson.gender,
        applicantId: value,
      });
    } else setForm(formObj);
  };

  const handleApostilate = (index, e) => {
    const { checked } = e.target;
    let { products } = form;
    if (index === 'all') {
      products = products.map((a) => {
        a.apostilate = checked;
        return a;
      });
    } else {
      products[index].apostilate = checked;
    }
    setForm({ ...form, products });
  };

  const handleChangePremiumService = (e) => setForm({...form, premium_service: e.target.checked});

  const handleSubmit = (e) => {
    setHasError(false);
    e.preventDefault();
    if (!validateForm(form)) {
      setHasError(true);
      props.showNotification(
        NOTIFICATION_TYPE_ERROR,
        'Por favor, preencha o formulário',
      );
      return;
    }
    let phase = 0;
    if (hostsList.find((a) => a.id === parseInt(form.hosts, 10)).applicant) {
      const findApplicantProcess = props.scope
        .processes
        .filter((a) => a.applicant_id === parseInt(form.hosts, 10) && a.products[0].category === 0);
      if (findApplicantProcess.length > 0) {
        phase = parseInt(findApplicantProcess[0].phase, 10) + 1;
      }
    }
    props.createProcess({ data: form.applicantId !== 'new' ? prepareToUpdate({ ...form, phase }) : prepareToSubmit({ ...form, phase }), clientId: props.scope.client_id, scopeId: props.scope.id });
    if (form.applicantId !== 'new') {
      props.updatePerson({
        personId: form.applicantId,
        data: prepareToUpdatePerson(form),
      });
    }

    setForm(processForm);
    setPreviousPerson('new');
  };

  const handleClear = (e) => {
    setForm(processForm);
    e.preventDefault();
  };

  const handleUpdateProcess = (e) => {
    console.log(form);
    setHasError(false);
    e.preventDefault();
    if (!validateForm(form)) {
      setHasError(true);
      props.showNotification(
        NOTIFICATION_TYPE_ERROR,
        'Por favor, preencha o formulário',
      );
      return;
    }
    const updateBoth = (payload) => {
      const clientId = props.scope.client_id;
      const scopeId = props.scope.id;
      const processId = process.id;
      const personId = people.id;
      props.updateProcess({
        clientId,
        processId,
        data: prepareToUpdateProcess(form),
        scopeId,
      });
      props.updatePerson({
        clientId,
        personId,
        data: prepareToUpdatePerson(form),
        scopeId,
      });
    }
    props.showConfirmation(
      {
        title: 'Editar processo',
        message: `Editar informações sobre o processo de ${form.name}?`,
      },
      {
        confirm: 'Editar',
        cancel: 'Cancelar',
      },
      {
        func: updateBoth,
        payload: {
          processId: process.id,
          personId: process.applicant_id,
          data: prepareToSubmit(form),
          clientId: props.scope.client_id,
          scopeId: props.scope.id,
        },
      },
    );
  };

  const handleRemoveDoc = (doc) => {
    console.log(doc)
    const docs = form.documents.filter((a) => !_.isEqual(a, doc));

    if (doc.id) {
        props.removeDocument({docId: doc.id})
    }

    setForm({ ...form, documents: docs });
  };

  const handleRemoveProcess = (e) => {
    e.preventDefault();
    const checkBlock = props.scope.processes.filter((a) => a.hosts[0].id === process.applicant_id && a.host_relations !== sameRelation);
    if (checkBlock && checkBlock.length > 0) {
      props.showNotification(NOTIFICATION_TYPE_WARNING, MESSAGE_ALERT_CANT_REMOVE_PROCESS);
    } else {
      props.showConfirmation(
        {
          title: 'Remover processo',
          message: `Remover informações sobre o processo de ${form.name}?`,
        },
        {
          confirm: 'Remover',
          cancel: 'Cancelar',
        },
        {
          func: props.removeProcess,
          payload: {
            processId: process.id,
            clientId: props.scope.client_id,
            scopeId: props.scope.id,
          },
        },
      );
    }
  };
  const filteredProducts = props.products ? props.products.filter((p) => !p.can_attached && p.deleted_at === null) : [];
  const sameOne = `(${form.gender === 'FEMALE' ? 'a' : 'o'} própri${form.gender === 'FEMALE' ? 'a' : 'o'})`;
  const selectedProduct = props.products ? props.products.filter((p) => p.id === form.product[0]) : [];
  return (
    <form onSubmit={handleSubmit} onReset={handleClear} style={{ width: '100%' }}>
      <Grid container spacing={2}>
        {personList && personList.length > 0 && mode !== PROCESS_FORM_MODE_EDIT && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              select
              variant="outlined"
              name="applicantId"
              label="Requerentes já cadastrados"
              value={form.applicantId}
              onChange={e => handleChangePreviousPerson(e.target.value)}
              error={hasError && form.name === ''}
            >
              <MenuItem value="new">Novo(a) requerente</MenuItem>
              {personList.map(a => (
                <MenuItem key={uniqid()} value={a.id}>{a.name}</MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
        {previousPerson === 'new' && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              name="name"
              label="Nome"
              disabled={previousPerson !== 'new'}
              value={form.name}
              onChange={e => handleChange('name', e.target.value)}
              error={hasError && form.name === ''}
            />
          </Grid>
        )}
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            variant="outlined"
            name="birthdate"
            label="Data de nascimento"
            value={form.birthdate}
            onChange={e => handleChange('birthdate', e.target.value)}
            error={hasError && form.birthdate === null}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            select
            fullWidth
            variant="outlined"
            name="gender"
            label="Gênero"
            value={form.gender || ''}
            SelectProps={{
              native: true,
            }}
            onChange={e => handleChange('gender', e.target.value)}
            error={hasError && form.gender === ''}
          >
            <option value="">{' '}</option>
            {imports.genders.map((gender) => (
              <option key={`gender${gender.value}`} value={gender.value}>
                {gender.label}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            select
            variant="outlined"
            name="hosts"
            label="Portugês"
            value={form.hosts}
            SelectProps={{
              native: true,
            }}
            onChange={e => handleChange('hosts', e.target.value)}
            error={hasError && form.hosts === ''}
          >
            <option value="">{' '}</option>
            {hostsList.map(person => (
              <option key={person.id} value={person.id}>{`${person.name} ${form.applicantId === person.id ? sameOne : ''}`}</option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            select
            variant="outlined"
            name="parent_type"
            label="Relação com o português"
            value={form.applicantId === parseInt(form.hosts) ? sameRelation : form.parent_type}
            SelectProps={{
              native: true,
            }}
            disabled={form.applicantId === parseInt(form.hosts)}
            onChange={e => handleChange('parent_type', e.target.value)}
            error={hasError && form.parent_type === ''}
          >
            <option value="">{' '}</option>
            {form.applicantId !== parseInt(form.hosts) && relations.map((relation) => {
              const r = form.gender === 'FEMALE' ? relation[1] : relation[0];
              return (
                <option key={`gender${relation[0]}`} value={r}>{r}</option>
              );
            })}
            {form.applicantId === parseInt(form.hosts) && (
              <option value={sameRelation}>{sameOne}</option>
            )}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          {filteredProducts && filteredProducts.length > 0
            ? (
              <TextField
                fullWidth
                select
                variant="outlined"
                name="product"
                label="Processo"
                value={form.product}
                onChange={handleSelectProduct}
                error={hasError && form.product.length === 0}
              >
                <MenuItem value="" style={{ display: 'none' }}>Selecione</MenuItem>
                {filteredProducts && sortBy(filteredProducts, 'title').sort().map((product) => (
                  <MenuItem key={uniqid()} value={product.id}>{product.title}</MenuItem>
                ))}
              </TextField>
            ) : (
              <Skeleton
                width="100%"
                height="100%"
                style={{ margin: 0 }}
              />
            )}
        </Grid>
        {form.products && form.products.length > 0 && form.product[0] !== '' && (
          <ProductPrice products={props.products} form={form} setForm={setForm} />
        )}
        {form.products && form.products.length > 0 && form.product[0] !== '' && (
          <MoreSales products={props.products} form={form} setForm={setForm} />
        )}
        {form.products && form.products.length > 0 && form.product[0] !== '' && (
          <Grid item xs={12}>
            <Typography variant="h5" color="textSecondary">
              {'Documentação necessária'}
            </Typography>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={form.products[0].apostilate}
                  className={classes.inputCheck}
                  onChange={(e) => handleApostilate('all', e)}
                />
              )}
              label="O cliente vai apostilar"
            />
          </Grid>
        )}
        {((form.products && form.products.length > 0 && form.product[0] !== '' && form.products[0].is_courtesy) ||
        (selectedProduct && selectedProduct.length > 0 && selectedProduct[0].is_courtesy)) && (
          <Grid item xs={12}>
            <Typography variant="h5" color="textSecondary">
              {'Inclusão de cortesia'}
            </Typography>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={form.is_courtesy}
                  className={classes.inputCheck}
                  onChange={(e) => handleChange('is_courtesy', e.target.checked)}
                />
              )}
              label="Este processo vai ser cortesia?"
            />
          </Grid>
        )}

        {form.products && form.products.length > 0 && form.product[0] !== '' && form.products.map((product, index) => (
          <SaleFormStepTwoDoc
            key={uniqid()}
            product={product}
            index={index}
            onApostilate={handleApostilate}
          />
        ))}
        <Grid item xs={12} md={5}>
        {/* <FormControl>
          <FormControlLabel
            control={<Checkbox  checked={form.premium_service} onChange={handleChangePremiumService} name="customer_delivery" />}
            label="Ida Cartório"
          />
            <FormHelperText>
              Marque essa opção se o cartório autenticará e reconhecerá firma na casa do cliente. Válido apenas para RJ.
            </FormHelperText>
          </FormControl>
          <Typography color="textPrimary">
            Ao selecionar a ida ao cartório, será adicionado nas taxas um valor e <b>R$ 160,00</b>, por processos. Existindo mais de um
            processo na venda, esse valor será adicionado a partir do segundo processo.
          </Typography> */}
          {form.products && form.products.length > 0 && form.products[0].id === 21 && (
            <Grid item xs={12}>
              <Typography variant="h5" color="textSecondary">Anexar Cópia do RG ou Passaporte: (Obrigatório para Convolação)</Typography>
              <Filebox
                id="annex-pt-doc"
                file={docsToAdd}
                setFile={(file) => setForm({ ...form, documents: file })}
                errors={errors}
                setErrors={setErrors}
                baseFileAmount={form.documents.length}
              />
              {(form.documents && form.documents.length > 0) && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="h5">Documentos anexados</Typography>
                  </Grid>
                  {form.documents && form.documents.map((doc) => (
                    <FilesPreview key={uniqid()} doc={doc} removeDoc={handleRemoveDoc} inScope />
                  ))}
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <FooterForm mode={mode} onDelete={handleRemoveProcess} onUpdate={handleUpdateProcess} />
    </form>
  );
}

const mapStateToProps = (state) => ({
  products: state.product.allProducts,
  peoples: state.sales.scope.peoples,
  scope: state.sales.scope,
});

const actions = {
  showNotification,
  createProcess,
  removeProcess,
  updateProcess,
  updatePerson,
  showConfirmation,
  removeDocument,
};

export default connect(mapStateToProps, actions)(SaleFormStepTwoForm);
