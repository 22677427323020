import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { apiBaseUrl } from 'api';
import { Menu, MenuItem, Typography, ListItemIcon, Link as LinkMaterial } from '@material-ui/core';
import {
  AttachmentOutlined as AttachmentIconOutlined,
  CloudDownloadOutlined as CloudDownloadIconOutlined,
  CloudUploadOutlined as CloudUploadOutlinedIcon,
  EditOutlined as EditIconOutlined,
  VisibilityOutlined as VisibilityIconOutlined,
  SendOutlined,
  Edit,
} from '@material-ui/icons/';
import {
  PAYMENT_CREDIT_CARD,
} from 'constants/sales';
import { SaleDashboardContext } from 'contexts/saleDashboard';
import { UserContext } from 'contexts/user';
import { useStyles } from '../style';

function OpenMenus() {
  const classes = useStyles(false);
  const dashContext = useContext(SaleDashboardContext);
  const { userCoordinator } = useContext(UserContext);
  const { menuEl, currentClient } = dashContext;

  const paymentsLength = currentClient.payments
    ? currentClient.payments.filter((a) => a.payment_method !== PAYMENT_CREDIT_CARD).length : 0;

  return (
    <div>
      <Menu
        id="scopeMenu"
        anchorEl={menuEl.scope}
        keepMounted
        open={Boolean(menuEl.scope)}
        onClose={
          () => dashContext.openMenu(false, 0)
        }
        elevation={2}
      >
        <Link
          className={classes.link}
          to={`/nova-venda/${currentClient.id}`}
          target="_blank"
          onClick={
            () => dashContext.openMenu(false, 0)
          }
        >
          <MenuItem>
            <ListItemIcon className={classes.popMenuIcon}>
              {currentClient.salesStep < 1 ? <EditIconOutlined /> : <VisibilityIconOutlined />}
            </ListItemIcon>
            <Typography color="textPrimary">{`${currentClient.salesStep < 1 ? 'Editar' : 'Ver'} escopo`}</Typography>
          </MenuItem>
        </Link>
      </Menu>
      <Menu
        id="contractMenu"
        anchorEl={menuEl.contract}
        keepMounted
        open={Boolean(menuEl.contract)}
        onClose={
          () => dashContext.openMenu(false, 1)
        }
        elevation={2}
      >
        <Link
          className={classes.link}
          to={`/contrato/${currentClient.id}`}
          target="_blank"
          onClick={
            () => dashContext.openMenu(false, 1)
          }
        >
          <MenuItem>
            <ListItemIcon className={classes.popMenuIcon}>
              <VisibilityIconOutlined />
            </ListItemIcon>
            <Typography color="textPrimary">Ver contrato</Typography>
          </MenuItem>
        </Link>
        {currentClient.contract_path && (
          <MenuItem onClick={() => dashContext.handleSendContractEmail(currentClient)}>
            <ListItemIcon className={classes.popMenuIcon}>
              <SendOutlined />
            </ListItemIcon>
            <Typography color="textPrimary">
              {'Reenviar e-mail de contrato'}
            </Typography>
          </MenuItem>
        )}
        {!userCoordinator && !currentClient.id
          ? null
          : (
            <MenuItem
              onClick={() => {
                dashContext.handleDialogEditContract();
                dashContext.openMenu(false, 1);
              }}
            >
              <ListItemIcon className={classes.popMenuIcon}>
                <Edit />
              </ListItemIcon>
              <Typography color="textPrimary">
                {'Editar contrato'}
              </Typography>
            </MenuItem>
          )}
        {currentClient.contract_path && (
          <LinkMaterial
            className={classes.link}
            href={`${apiBaseUrl}/contracts/${currentClient.contract_path}`}
            target="_blank"
            onClick={
              () => dashContext.openMenu(false, 1)
            }
          >
            <MenuItem>
              <ListItemIcon className={classes.popMenuIcon}>
                <CloudDownloadIconOutlined />
              </ListItemIcon>
              <Typography>
                {'Baixar contrato para impressão'}
              </Typography>
            </MenuItem>
          </LinkMaterial>

        )}
        {currentClient.signed_contract_path && (
          <LinkMaterial
            className={classes.link}
            href={`${apiBaseUrl}/contracts/${currentClient.signed_contract_path}`}
            target="_blank"
            onClick={
              () => dashContext.openMenu(false, 1)
            }
          >
            <MenuItem>
              <ListItemIcon className={classes.popMenuIcon}>
                <CloudDownloadIconOutlined />
              </ListItemIcon>
              <Typography>
                {'Baixar contrato assinado para impressão'}
              </Typography>
            </MenuItem>
          </LinkMaterial>

        )}
        <MenuItem
          onClick={() => {
            dashContext.handleDialogSignedContract(currentClient);
            dashContext.openMenu(false, 1);
          }}
        >
          <ListItemIcon className={classes.popMenuIcon}><CloudUploadOutlinedIcon /></ListItemIcon>
          <Typography>{`${currentClient.signed_contract_path ? 'Reenviar' : 'Enviar'} contrato assinado`}</Typography>
        </MenuItem>
      </Menu>
      <Menu
        id="paymentMenu"
        anchorEl={menuEl.payment}
        keepMounted
        open={Boolean(menuEl.payment)}
        onClose={
          () => dashContext.openMenu(false, 2)
        }
        elevation={2}
      >
        {paymentsLength > 0
          ? (
            <MenuItem
              onClick={() => {
                dashContext.openMenu(false, 2);
                dashContext.handleDialogPayment(currentClient);
              }}
            >
              <ListItemIcon className={classes.popMenuIcon}>
                <AttachmentIconOutlined />
              </ListItemIcon>
              <Typography>Anexar comprovante</Typography>
            </MenuItem>
          ) : (
            <Typography
              color="textSecondary"
              style={{
                padding: '0 8px',
                userSelect: 'none',
                outline: 'none',
              }}
            >
              {'Aguarando o pagamento'}
            </Typography>
          )}
      </Menu>
    </div>
  );
}

export default OpenMenus;
