import { fade, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  search: {
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    color: fade(theme.palette.text.primary, 0.3),
    transition: theme.transitions.create(['color'], { duration: '100ms' }),
    '&:hover, &:focus': {
      color: theme.palette.text.primary,
    },
    marginRight: 0,
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  richText: {
    '& p, & [data-block=true]:not(h2):not(h6)': {
      ...theme.typography.body1,
    },
    '& h6, & h2': {
      ...theme.typography.h6,
    },
    '& strong': {
      fontWeight: 'bolder',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(2, 1, 2, 7),
    width: '100%',
    lineHeight: '3rem',
    fontSize: '1.5rem',
  },
  categoryButton: {
    marginBottom: theme.spacing(1),
    '&.selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover, &:focus': {
        backgroundColor: fade(theme.palette.primary.main, .7),
      },
    },
  },
  tagButton: {
    display: 'contents',
  },
  expansionPanel: {
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 2),
    }
  },
  tagName: {
    marginRight: theme.spacing(1),
  },
  panelContent: {
    flexDirection: 'column',
  },
  panelInfos: {
    marginBottom: theme.spacing(2),
  },
  categoryGrid: {
    padding: theme.spacing(.5),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    }
  },
  categoryContainer: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    width: 'calc(100% + ' + theme.spacing(4) + 'px)',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
}));