import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { SaleDashboardContext } from 'contexts/saleDashboard';
import { fetchMoreAccompaniment, fetchAllAccompaniment } from 'store/actions/sales';
import { Grid, Paper, Typography } from '@material-ui/core';
import ActionsContext from 'contexts/actions';
import TabHeader from 'sales/dashboard/components/MasterTabHeader';
import TabRowAttendance from 'sales/dashboard/components/TabRowAttendance';
import { LoadingComponent } from 'global/modules/Loading';
import NoResults from 'global/modules/NoResults';
import { REQUESTING, FULFILLED, DEFAULT } from 'constants/common';
import { useStyles } from './style';
import { setSalesStep } from '../functions';
import { getCancelToken, cancel } from 'api';
import LoadMore from './modules/LoadMore';

function MasterTabAttendance({
  tab,
  tabKey,
  attendancesReq,
  currentPage,
  update,
  allSalesReq,
  status,
  fetchAll,
  fetchMoreStatus,
  fetchMore,
  ...props
}) {
  const classes = useStyles(false);
  const { reload } = useContext(ActionsContext);
  const { setCurrentClient, currentClient, q, sortBy, sortOrder } = useContext(SaleDashboardContext);
  const [attendances, setAttendances] = useState([...attendancesReq]);
  const [allSales, setAllSales] = useState([...allSalesReq]);
  const [requesting, setRequesting] = useState(false);
  const cancelSource = React.useRef(null);

  const handleFetchMore = () => {
    fetchMore(cancelSource.current.token, currentPage + 1, sortBy, sortOrder, q);
  };

  useEffect(() => {
    if (status === FULFILLED && requesting) {
      setRequesting(false);
    }
    setAttendances([...attendancesReq]);
    setAllSales([...allSalesReq]);
  }, [status, allSalesReq, attendancesReq, requesting]);

  const attendancesWithSteps = attendances.map((a) => setSalesStep(a));

  useEffect(() => {
    cancelSource.current = getCancelToken().source();
    if (!requesting) {
      setRequesting(true);
      fetchAll(cancelSource.current.token, currentPage, sortBy, sortOrder, q);
    }
    return () => cancel(cancelSource.current);
    // eslint-disable-next-line
  }, [fetchAll, update, reload]);

  useEffect(() => {
    if (!requesting && status !== REQUESTING && status !== DEFAULT) {
      setRequesting(true);
      fetchAll(cancelSource.current.token, currentPage, sortBy, sortOrder, q);
    }
    // eslint-disable-next-line
  }, [sortBy, sortOrder, q]);

  useEffect(() => {
    if (status === FULFILLED) {
      const maintainClient = allSales.find((a) => a.id === currentClient.id);
      setCurrentClient(maintainClient || currentClient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, allSales]);

  return (
    <Grid>
      <TabHeader
        title={tab.title}
        tabKey={tabKey}
      />
      {attendancesWithSteps
        && status === FULFILLED
        && attendancesWithSteps.length === 0
        ? <NoResults message="Nenhuma venda encontrada" />
        : (
          <Paper className={clsx(classes.header, 'selectable')}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Cliente</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Informações</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} className={classes.middleGridPadding}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2">Venda</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" component="p" align="center">Contrato</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" component="p" align="right">Pagamento</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} className={classes.lastChildPadding}>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Typography variant="subtitle2">Serviços</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        )}
      {attendancesWithSteps
        && attendancesWithSteps.length > 0
        && attendancesWithSteps.map((item, i) => (
          <TabRowAttendance
            key={`attendance${item.id}`}
            index={i}
            item={item}
          />
        ))}
      {status === REQUESTING && fetchMoreStatus !== REQUESTING
        && <LoadingComponent />}
      {attendancesWithSteps && attendancesWithSteps.length > 0 && status !== DEFAULT && (
        <LoadMore action={handleFetchMore} />
      )}
    </Grid>
  );
}

MasterTabAttendance.propTypes = {
  tab: PropTypes.object.isRequired,
  tabKey: PropTypes.number.isRequired,
  attendancesReq: PropTypes.array.isRequired,
  update: PropTypes.string.isRequired,
  allSalesReq: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  allSalesReq: state.sales.allSales,
  attendancesReq: state.sales.collection,
  status: state.sales.status,
  fetchMoreStatus: state.sales.fetchMoreStatus,
  totalPages: state.sales.totalPages,
  currentPage: state.sales.currentPage,
});

const actions = {
  fetchAll: fetchAllAccompaniment,
  fetchMore: fetchMoreAccompaniment,
};

export default connect(mapStateToProps, actions)(MasterTabAttendance);
