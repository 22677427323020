import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  grid: {
    minHeight: 170,
    padding: theme.spacing(2),
  },
  removeButton: {
    position: 'absolute',
    top: -22.5,
    right: -22.5,
    width: 45,
    height: 45,
    zIndex: 2,
    '&:hover': {
      color: theme.palette.error.dark,
    },
  },
  imgPreview: {
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    height: 120,
    marginBottom: theme.spacing(-0.5),
    maxHeight: 120,
  },
  miniHolderContainer: {
    height: '100%',
  },
  fileMiniHolder: {
    padding: theme.spacing(2, 1),
    height: '100%',
    maxHeight: 150,
    position: 'relative',
    '&.not-sent': {
      backgroundColor: '#FAFAFA',
    },
    '&.expandable': {
      cursor: 'pointer',
      transition: theme.transitions.create('all'),
      userSelect: 'none',
    },
    '&.expandable:hover': {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[6],
    },
    '&.expandable:active': {
      boxShadow: 'none',
    },
  },
  miniHolderItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  miniHolderInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
}));
