import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import uniqid from 'uniqid';
import { Typography, Grid } from '@material-ui/core';
import { FILL_LATER_TEXT } from 'constants/sales';
import { fetchFreguesiasByConselhos } from 'store/actions/sales';

function PeopleHeader({ people, expanded, certificates, ...props }) {
  const getCertificateName = () => {
    const certificate = _.first(_.filter(certificates, ['id', people.certificate_id]));
    if (typeof certificate === 'undefined') return FILL_LATER_TEXT;
    return certificate.name;
  };

  useEffect(() => {
    if (expanded) {
      if (people.concelho_id != null) {
        props.fetchFreguesiasByConselhos(people.concelho_id);
      }
    }
  }, [people]);

  if (expanded) return (<Typography variant="h5">Editar dados de {people.name}</Typography>);
  if (!expanded) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" color="textSecondary">
            {people.name}
          </Typography>
        </Grid>
        {people.birthdate && (
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              Data de nascimento: <strong>{people.birthdate}</strong>
            </Typography>
          </Grid>
        )}
        {people.certificate_id && (
          <Grid item xs={6}>
            <Typography variant="subtitle1">Tipo do certificado: <strong>{getCertificateName()}</strong></Typography>
          </Grid>
        )}
        {people.mother_name && (
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              Nome da mãe: <strong>{people.mother_name}</strong>
            </Typography>
          </Grid>
        )}
        {people.father_name && (
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              Nome do pai: <strong>{people.father_name}</strong>
            </Typography>
          </Grid>
        )}
        {people.concelho && (
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              Conselhos: <strong>{people.city != null ? people.city.concelho.name : 'A confirmar'}</strong>
            </Typography>
          </Grid>
        )}
        {people.city && (
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              Freguesia: <strong>{people.city != null ? people.city.name : 'A confirmar'}</strong>
            </Typography>
          </Grid>
        )}
        {people.document_number && (
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              Número de assento: <strong>{people.document_number}</strong>
            </Typography>
          </Grid>
        )}
        {people.crc && (
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              CRC: <strong>{people.crc}</strong>
            </Typography>
          </Grid>
        )}
        {people.assento_year && (
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              Ano do assento: <strong>{people.assento_year}</strong>
            </Typography>
          </Grid>
        )}
        {people.birthplace && (
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              Naturalidade: <strong>{people.birthplace}</strong>
            </Typography>
          </Grid>
        )}
        {people.documents && people.documents.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography variant="h5">Documentos anexados</Typography>
            </Grid>
            {people.documents.map(doc => (
              <Grid key={uniqid()} item>
                {doc.file_name}
              </Grid>
            ))}
          </>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  certificates: state.certificates.collection,
});

export default connect(mapStateToProps, { fetchFreguesiasByConselhos })(PeopleHeader);
