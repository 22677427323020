export const calcPrices = (processes) => {
  let hiredServices = {};
  processes.forEach((process) => {
    if (process.products[0]) {
      const product = process.products[0];
      const exists = Object.keys(hiredServices).indexOf(product ? product.title : '') >= 0;
      let price = 0;
      product.prices.forEach(cost => {
        if (cost.name !== 'Honorários') price += cost.price;
      });
      hiredServices[product.title] = { title: product.title, price: exists ? hiredServices[product.title].price + price : price, times: exists ? hiredServices[product.title].times + 1 : 1 };
    }
  });
  return hiredServices;
}