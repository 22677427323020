import * as definitions from 'store/definitions/externalAuth';
import {
  REJECTED,
  PENDING,
  FULFILLED,
  NO_EXTERNAL_AUTH,
} from 'constants/session';

function ExternalAuth(state = {
  auth: NO_EXTERNAL_AUTH,
}, action) {
  const { payload } = action;
  switch (action.type) {
    case definitions.EXTERNAL_AUTH:
    case definitions.EXTERNAL_AUTH_PENDING:
      return {
        ...state,
        auth: PENDING,
        payload,
      };

    case definitions.EXTERNAL_AUTH_FULFILLED:
      return {
        ...state,
        auth: FULFILLED,
        payload,
      };

    case definitions.EXTERNAL_AUTH_REJECTED:
      return {
        ...state,
        auth: REJECTED,
        payload,
      };

    default:
      return state;
  }
}

export default ExternalAuth;
