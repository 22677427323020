import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import * as definitions from 'store/definitions/product';
import * as selectors from 'store/selectors/product';
import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from 'constants/notification';
import * as notificationDefinitions from 'store/definitions/notifications';
import * as messages from 'constants/common/messages';
import * as API from 'api';
import { PRODUCT_PATH, PRODUCT_SINGLE_PATH, PRICE_PATH, REQUIREMENT_PATH } from 'api/paths';

export function* productSearch(action) {
  const {
    searchValue, categories,
  } = action.payload;
  const payload = yield select(
    (state) => selectors.filterProducts(state)(searchValue, categories)
  );
  yield put({ type: definitions.PRODUCT_SEARCH_RESULT, payload });
}

/* PRODUCTS */

function* fetchAll() {
  try {
    const payload = yield call(API.fetch, PRODUCT_PATH, {});
    yield put({ type: definitions.FETCH_ALL_PRODUCTS_FULFILLED, payload: payload.products.map(a => ({ ...a, id: parseInt(a.id, 10) })) });
  } catch (error) {
    yield put({ type: definitions.FETCH_ALL_PRODUCTS_REJECTED, payload: { error } });
  }
}

function* postProduct(action) {
  try {
    const { data } = action.payload;
    const payload = yield call(API.create, PRODUCT_SINGLE_PATH, { ...data });
    yield put({
      type: definitions.CREATE_PRODUCT_FULFILLED,
      payload,
    });
  } catch (error) {
    yield put({
      type: definitions.CREATE_PRODUCT_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_ERROR,
        message: messages.MESSAGE_ERROR_COMMON_ERROR,
      },
    });

  }
}

function* patchProduct(action) {
  try {
    const { productId, data } = action.payload;
    const payload = yield call(API.update, PRODUCT_SINGLE_PATH, productId, { ...data });
    yield put({
      type: definitions.UPDATE_PRODUCT_FULFILLED,
      payload,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_COMMON_UPDATE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.UPDATE_PRODUCT_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_ERROR,
        message: messages.MESSAGE_ERROR_COMMON_ERROR,
      },
    });

  }
}

function* deleteProduct(action) {
  try {
    const { productId } = action.payload;
    const payload = yield call(API.remove, PRODUCT_SINGLE_PATH, productId);
    yield put({
      type: definitions.REMOVE_PRODUCT_FULFILLED,
      payload,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_REMOVE_PRODUCT,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.REMOVE_PRODUCT_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_ERROR,
        message: messages.MESSAGE_ERROR_COMMON_ERROR,
      },
    });

  }
}

/* PRICES */

function* postPriceToProduct(action) {
  try {
    const { productId, data } = action.payload;
    const payload = yield call(API.create, PRICE_PATH, { ...data, product_id: productId });
    yield put({
      type: definitions.ADD_PRICE_TO_PRODUCT_FULFILLED,
      payload,
    });
  } catch (error) {
    yield put({
      type: definitions.ADD_PRICE_TO_PRODUCT_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_ERROR,
        message: messages.MESSAGE_ERROR_COMMON_ERROR,
      },
    });

  }
}

function* patchPriceBatch(action) {
  try {
    const { priceId, data, last } = action.payload;
    const payload = yield call(API.update, PRICE_PATH, priceId, { ...data });
    yield put({
      type: definitions.BATCH_UPDATE_PRICE_FULFILLED,
      payload,
    });
    if (last) {
      yield put({
        type: notificationDefinitions.SHOW,
        payload: {
          variant: NOTIFICATION_TYPE_SUCCESS,
          message: messages.MESSAGE_SUCCESS_COMMON_UPDATE,
        },
      });
    }
  } catch (error) {
    yield put({
      type: definitions.BATCH_UPDATE_PRICE_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_ERROR,
        message: messages.MESSAGE_ERROR_COMMON_ERROR,
      },
    });
  }
}

function* patchPrice(action) {
  try {
    const { priceId, data } = action.payload;
    const payload = yield call(API.update, PRICE_PATH, priceId, { ...data });
    yield put({
      type: definitions.UPDATE_PRICE_FULFILLED,
      payload,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_COMMON_UPDATE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.UPDATE_PRICE_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_ERROR,
        message: messages.MESSAGE_ERROR_COMMON_ERROR,
      },
    });
  }
}

function* deletePrice(action) {
  try {
    const { priceId } = action.payload;
    const payload = yield call(API.remove, PRICE_PATH, priceId);
    yield put({
      type: definitions.REMOVE_PRICE_FULFILLED,
      payload,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_REMOVE_PRICE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.REMOVE_PRICE_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_ERROR,
        message: messages.MESSAGE_ERROR_COMMON_ERROR,
      },
    });

  }
}

/* REQUIREMENTS */

function* postRequirementToProduct(action) {
  try {
    const { productId, data } = action.payload;
    const payload = yield call(API.create, REQUIREMENT_PATH, { ...data, product_id: productId });
    yield put({
      type: definitions.ADD_REQUIREMENT_TO_PRODUCT_FULFILLED,
      payload,
    });
  } catch (error) {
    yield put({
      type: definitions.ADD_REQUIREMENT_TO_PRODUCT_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_ERROR,
        message: messages.MESSAGE_ERROR_COMMON_ERROR,
      },
    });

  }
}

function* patchRequirement(action) {
  try {
    const { requirementId, data } = action.payload;
    const payload = yield call(API.update, REQUIREMENT_PATH, requirementId, { ...data });
    yield put({
      type: definitions.UPDATE_REQUIREMENT_FULFILLED,
      payload,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_COMMON_UPDATE,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.UPDATE_REQUIREMENT_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_ERROR,
        message: messages.MESSAGE_ERROR_COMMON_ERROR,
      },
    });

  }
}

function* deleteRequirement(action) {
  try {
    const { requirementId } = action.payload;
    const payload = yield call(API.remove, REQUIREMENT_PATH, requirementId);
    yield put({
      type: definitions.REMOVE_REQUIREMENT_FULFILLED,
      payload,
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_SUCCESS,
        message: messages.MESSAGE_SUCCESS_REMOVE_REQUIREMENT,
      },
    });
  } catch (error) {
    yield put({
      type: definitions.REMOVE_REQUIREMENT_REJECTED,
      payload: { error },
    });
    yield put({
      type: notificationDefinitions.SHOW,
      payload: {
        variant: NOTIFICATION_TYPE_ERROR,
        message: messages.MESSAGE_ERROR_COMMON_ERROR,
      },
    });

  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(definitions.PRODUCT_SEARCH, productSearch),
    takeLatest(definitions.FETCH_ALL_PRODUCTS, fetchAll),
    takeLatest(definitions.CREATE_PRODUCT, postProduct),
    takeLatest(definitions.UPDATE_PRODUCT, patchProduct),
    takeLatest(definitions.REMOVE_PRODUCT, deleteProduct),
    takeLatest(definitions.ADD_PRICE_TO_PRODUCT, postPriceToProduct),
    takeLatest(definitions.UPDATE_PRICE, patchPrice),
    takeLatest(definitions.BATCH_UPDATE_PRICE, patchPriceBatch),
    takeLatest(definitions.REMOVE_PRICE, deletePrice),
    takeLatest(definitions.ADD_REQUIREMENT_TO_PRODUCT, postRequirementToProduct),
    takeLatest(definitions.UPDATE_REQUIREMENT, patchRequirement),
    takeLatest(definitions.REMOVE_REQUIREMENT, deleteRequirement),
  ]);
}
