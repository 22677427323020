import React, { useState } from 'react';
import clsx from 'clsx';
import { Grid, Paper, IconButton, Typography } from '@material-ui/core';
import Edit from 'global/modules/CitizenEdit';
import { Edit as EditIcon, Close } from '@material-ui/icons';
import { infosCitizen } from 'constants/citizens';
import Visualization from 'global/modules/MoreInfo/CitizenInfoRowVisualization';
import { useStyles } from 'global/modules/MoreInfo/style';

function CitizenCard({ citizen, setUpdate }) {
  const [editing, setEditing] = useState(false);
  const classes = useStyles();
  const handleEdit = () => setEditing((s) => !s);
  return (
    <Grid item xs={12} lg={!editing ? 6 : 12} className={classes.transitions}>
      <Paper className={classes.smallPaper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.maxWidthName} variant="h6">
              {editing ? 'Alterar informações' : citizen.name}
            </Typography>
            <IconButton onClick={handleEdit} className={clsx(classes.editBtn, editing ? 'editing' : 'regular')}>
              {editing ? <Close /> : <EditIcon />}
            </IconButton>
            {!editing && infosCitizen.map((info) => <Visualization key={`visualization${citizen.id}${info.name}`} citizen={citizen} info={info} />)}
            {editing && (
              <Edit
                citizen={citizen}
                infos={infosCitizen}
                classes={classes}
                setEditing={setEditing}
                editing={editing}
                setUpdate={setUpdate}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default CitizenCard;
