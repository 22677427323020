import axios from 'axios';
import qs from 'qs';
import { SESSION_TOKEN_STORAGE_KEY } from 'constants/session';

export const apiBaseUrl = process.env.REACT_APP_API_URL;
const client_id = process.env.REACT_APP_AUTH_CLIENT_ID;
const client_secret = `${process.env.REACT_APP_AUTH_CLIENT_SECRET}`;
const external_id = process.env.REACT_APP_AUTH_CUSTOMER_CLIENT_ID;
const external_secret = process.env.REACT_APP_AUTH_CUSTOMER_CLIENT_SECRET;
export const url = `${apiBaseUrl}`;

export const getCancelToken = () => axios.CancelToken;

export const cancel = (cancelSource, reason = 'Requisição cancelada pelo usuário') => cancelSource.cancel(reason);

export const login = (pathService, record) => (
  axios
    .post(`${url}/${pathService}`, {
      grant_type: 'password',
      client_id,
      client_secret,
      ...record
    })
    .then((res) => res.data)
);

export const externalLogin = (pathService) => (
  axios
    .post(`${url}/${pathService}`, {
      grant_type: 'client_credentials',
      client_id:
      external_id,
      client_secret:
      external_secret,
    })
    .then((res) => res.data)
);

export const fetchUrl = (sUrl) => (
  axios
    .get(sUrl)
    .then((res) => res.data)
);

export const fetch = (pathService, filter, cancelToken) => (
  axios
    .get(`${url}/${pathService}?${qs.stringify(filter)}`, { cancelToken })
    .then((res) => res.data)
);

export const fetchPaged = (pathService, filter) => (
  axios
    .get(`${url}/${pathService}?${qs.stringify(filter)}`)
    .then((res) => res.data)
);

export const fetchById = (pathService, id) => (
  axios
    .get(`${url}/${pathService}/${id}`)
    .then((res) => res.data)
);

export const create = (pathService, record) => (
  axios
    .post(`${url}/${pathService}`, record)
    .then((res) => res.data)
);

export const update = (pathService, id, record, query) => (
  axios
    .patch(`${url}/${pathService}/${id}${query ? `?${qs.stringify(query)}` : ''}`, record)
    .then((res) => res.data)
);

export const updateAction = (pathService, id, action, record, query) => (
  axios
    .patch(`${url}/${pathService}/${id}/${action}${query ? `?${qs.stringify(query)}` : ''}`, record)
    .then((res) => res.data)
);

export const remove = (pathService, id, record) => {
  return (
    axios
      .delete(`${url}/${pathService}${id ? `/${id}` : ''}`, { data: { ...record } } )
      .then((res) => res.data)
  );
}

export const insertToken = () => {
  const sessionStored = localStorage.getItem(SESSION_TOKEN_STORAGE_KEY);
  if (sessionStored === null) return;
  axios.interceptors.request.use((config) => (
    Object.assign(config, {
      headers: {
        ...config.headers,
        'Authorization': `Bearer ${sessionStored}`,
      },
    })
  ));
};
