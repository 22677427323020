import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { updateProfile } from 'store/actions/session';
import {
  Grid, Paper, Typography, TextField, Button,
} from '@material-ui/core';
import * as imports from 'objects/imports';
import AutosuggestField from 'global/fields/AutosuggestField';
import { UserContext } from 'contexts/user';
import ZipCodeField from 'global/fields/ZipCodeField';
import cep from 'cep-promise';
import { addressFormFields, autoAddressFields } from 'constants/session/userFormFields';
import { prepareToSendAddress } from './functions';
import { useStyles } from './style';

function AddressForm({ sizing, ...props }) {
  const classes = useStyles();

  const { user } = useContext(UserContext);
  const { address } = user;
  const initialForm = {
    ...addressFormFields,
    ...address,
  };
  const [form, setForm] = useState(initialForm);

  const handleSelectCountry = (selectedCountry) => setForm({
    ...form, country: selectedCountry.alpha2Code,
  });

  const handleChangeForm = (key, value) => setForm({ ...form, [key]: value });

  const loadData = () => {
    setForm(initialForm);
  };

  const loadAddressByZipCode = () => {
    const { zipCode } = form;
    if (zipCode && zipCode.length === 9) {
      cep(zipCode)
        .then((res) => {
          setForm({
            ...form,
            zipcode: res.cep,
            address1: res.street,
            address4: res.neighborhood,
            city: res.city,
            state: res.state,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const getLabel = (label) => {
    switch (label) {
      case 'address1':
        return 'Logradouro';
      case 'address4':
        return 'Bairro';
      case 'address2':
        return 'Número';
      case 'address3':
        return 'Complemento';
      case 'city':
        return 'Cidade';
      case 'state':
        return 'Estado';
      default:
        return '';
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.updateProfile(user.id, prepareToSendAddress(form));
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [user]);

  return (
    <Grid item {...sizing}>
      <Paper
        elevation={1}
        className={classes.paper}
      >
        <Typography
          variant="h6"
          gutterBottom
          className={classes.header}
        >
          Endereço
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={form.country === 'BR' ? 6 : 12} className={classes.formRow}>
              <AutosuggestField
                onSelect={handleSelectCountry}
                suggestList={imports.countries}
                label="País"
                value={form.country}
                loadKey="alpha2Code"
                InputLabelProps={{
                  shrink: Boolean(form.country),
                }}
                inputProps={{
                  classes,
                  autoComplete: 'off',
                  id: 'client-country',
                  name: 'country',
                  variant: 'outlined',
                  fullWidth: true,
                  required: true,
                }}
                theme={{
                  container: classes.container,
                  suggestionsContainerOpen: classes.suggestionsContainerOpen,
                  suggestionsList: classes.suggestionsList,
                  suggestion: classes.suggestion,
                }}
              />
            </Grid>
            {form.country === 'BR' && (
              <Grid item xs={6} className={classes.formRow}>
                <ZipCodeField
                  value={form.zipCode}
                  label="Cep"
                  variant="outlined"
                  fullWidth
                  onBlur={() => loadAddressByZipCode()}
                  onChange={e => handleChangeForm('zipCode', e.target.value)}
                  InputLabelProps={{
                    shrink: Boolean(form.zipCode),
                  }}
                />
              </Grid>
            )}

            {autoAddressFields.map((field) => (
              <Grid item xs={6} className={classes.formRow} key={field}>
                <TextField
                  id={`client-${field}`}
                  name={field}
                  label={getLabel(field)}
                  variant="outlined"
                  fullWidth
                  required
                  value={form[field]}
                  onChange={e => handleChangeForm([field], e.target.value)}
                  aria-describedby={`client-${field}`}
                  InputLabelProps={{
                    shrink: Boolean(form[field]),
                  }}
                />
              </Grid>
            ))}
            <Grid item xs={12} className={clsx(classes.formRow, 'btnHolder')}>
              <Typography align="right">
                <Button
                  variant={isEqual(form, initialForm) ? 'outlined' : 'contained'}
                  color="primary"
                  type="submit"
                  disabled={isEqual(form, initialForm)}
                >
                  Salvar alterações
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
}

export default connect(null, { updateProfile })(AddressForm)
