import React, { useEffect, useContext } from 'react';
import { SalesContext } from 'contexts/sales';
import { connect } from 'react-redux';
import uniqid from 'uniqid';
import {
  Container,
  Typography,
  Grid,
} from '@material-ui/core';
import { updateSaleScopeById } from 'store/actions/sales';
import { ordinalInPortuguese } from 'global/functions';
import PhaseRow from './modules/PhaseRow';

function SaleFormStepThree({ phases, ...props }) {
  const salesContext = useContext(SalesContext);
  const allPhases = phases || salesContext.phases;

  useEffect(() => {
    salesContext.handleStepOk(salesContext.currentStep, true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const buildPhases = {};
    const { processes } = props.scope;
    processes.forEach((process) => {
      if (buildPhases[process.phase]) {
        buildPhases[process.phase] = [...buildPhases[process.phase], process];
      } else buildPhases[process.phase] = [process];
    });
    salesContext.setPhases(buildPhases);
    // eslint-disable-next-line
  }, [props.scope.processes]);

  return (
    <Container maxWidth="xl">
      <Typography variant="h5" paragraph>Divisão dos processos por fase</Typography>
      {Object.keys(allPhases).map((phase, index) => (
        <Grid container spacing={2} key={uniqid()}>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ marginTop: index === 0 ? 0 : 30 }}>
              {Object.keys(allPhases).length === 1 ? 'Fase única' : `${ordinalInPortuguese(index + 1)} fase`}
            </Typography>
          </Grid>
          {allPhases[phase].map((process) => (
            <Grid key={uniqid()} item xs={12}>
              <PhaseRow
                process={process}
                phase={index + 1}
                unique={allPhases[phase].length === 1}
                totalPhases={Object.keys(allPhases).length}
              />
            </Grid>
          ))}
        </Grid>
      ))}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  scope: state.sales.scope,
});

export default connect(mapStateToProps, { updateSaleScopeById })(SaleFormStepThree);
