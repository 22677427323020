import React, { useState } from 'react';
import * as imports from '../../objects/imports';
import {
  Card,
  Button,
  Divider,
  DialogTitle,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  makeStyles,
  CardContent,
} from '@material-ui/core';
import AddUser from '../modules/AddUser';
import IconButton from '@material-ui/core/IconButton';
import CloseButton from '@material-ui/icons/Clear';
import Comments from 'global/Comments';
import * as DUMMIES from '../../objects/dummies';


const useStyles = makeStyles(theme => ({
  newUser: {
    borderWidth: '2px',
    borderStyle: 'dashed',
    opacity: '.7',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    transition: theme.transitions.create(['all'], { duration: '180ms' }),
    minHeight: '160px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '& i': {
      fontSize: '3rem',
    },
    '& h6': {
      maxHeight: 0,
      overflow: 'hidden',
      transition: theme.transitions.create(['height', 'max-height'], { duration: '180ms' })
    },
    '&:hover': {
      opacity: '1',
      backgroundColor: '#FFFFFF',
      borderWidth: '2px',
      borderStyle: 'dashed',
      borderColor: '#b0aeab',
      boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
      color: theme.palette.primary.main,
      '& h6': {
        maxHeight: theme.spacing(4),
      },
    }
  },
  userInfo: {
    paddingRight: theme.spacing(6),
  }
}));

const stoppropagation = (e) => {
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
}

function CheckListDisplay({ index, doc, checkthis }) {
  let title = doc.title;
  if (checkthis) {
    let checked = !(doc.phase === "0");
    return (
      <Typography color="textSecondary" classes={{ root: checked ? 'check checked' : 'check' }} variant="subtitle2" gutterBottom>
        {title}
      </Typography>
    )
  }
  else {
    return (
      <Typography variant="subtitle2" gutterBottom>
        {title}
      </Typography>
    )
  }
}

function CurrentStepInfos({ current, i, step }) {

  let contents = [];
  contents = [...contents,
  <Typography variant="h5" key={`h5${i}${step.title}`} color="textPrimary" gutterBottom>{step.title}</Typography>
    ,
  <Typography variant="body1" color="textSecondary" key={`body${i}${step.title}`} paragraph>{step.cardDescription}</Typography>,
  <Divider key={`carddivider${i}`} />
  ]

  /***  DOCUMENTAÇÃO E ALINHAMENTO ***/
  if (current.stage.filter(a => { return a === '2' || a === '1' }).length > 0) {
    contents = [...contents, <Typography key={`tit0${i}${step.title}`} variant="h6" gutterBottom>Documentos necessários</Typography>]

    /***  DOCUMENTOS DE RESPONSABILIDADE DO CLIENTE ***/
    let docs = current.userDocs.filter(a => { return a.clientSide && a.phase === '0'; });
    if (docs.length) {
      contents = [...contents, <Typography key={`tit1${i}${step.title}`} variant="subtitle1">Você envia:</Typography>];
      docs.map((doc, index) => {
        contents = [...contents,
        <CheckListDisplay key={`docali${index}`} index={index} doc={doc} checkthis />
        ]
        return null
      })
      contents = [...contents, <Typography key={`prgph1${i}`} paragraph />]
    }

    /***  DOCUMENTOS DE RESPONSABILIDADE INTERNA ***/
    docs = current.userDocs.filter(a => { return !a.clientSide && a.phase === '0'; });
    if (docs.length) {
      contents = [...contents,
      <Typography key={`tit2${i}${step.title}`} variant="subtitle1">Nós providenciaremos:</Typography>
      ]
      docs.map((doc, index) => {
        contents = [...contents,
        <CheckListDisplay key={`docalip${index}`} index={index} doc={doc} checkthis />
        ]
        return null
      })
      contents = [...contents, <Typography key={`prgph2${i}`} paragraph />]
    }
    /*** DOCUMENTOS JÁ RECEBIDOS NA EMPRESA ***/
    docs = current.userDocs.filter(a => { return a.phase === '1'; });
    if (docs.length) {
      contents = [...contents,
      <Typography key={`tit3${i}${step.title}`} variant="subtitle1">Documentos recebidos:</Typography>
      ]
      docs.map((doc, index) => {
        contents = [...contents,
        <CheckListDisplay key={`docalid${index}`} index={index} doc={doc} checkthis />
        ]
        return null;
      })
    }
  }

  /***  APOSTILAMENTO ***/
  else if (current.stage.filter(a => { return a === '3' }).length > 0) {
    if (DUMMIES.apostilamento.length > 0) {
      contents = [...contents, <Typography key={`tit0${i}${step.title}`} variant="h6" gutterBottom>Documentos enviados para o apostilamento</Typography>]
      DUMMIES.apostilamento.map((doc, index) => {
        contents = [...contents,
        <CheckListDisplay key={`apo${index}`} index={index} doc={doc} checkthis />
        ]
        return null
      })
      contents = [...contents, <Typography key={`prgph3${i}`} paragraph />]
    }
  }

  /*** CONFIRMAÇÃO ***/
  else if (current.stage.filter(a => { return a === '4' }).length > 0) {
    if (DUMMIES.confirmacao.length > 0) {
      contents = [...contents, <Typography key={`tit0${i}${step.title}`} variant="h6" gutterBottom>Documentos sendo confirmados para o envio</Typography>]
      DUMMIES.confirmacao.map((doc, index) => {
        contents = [...contents,
        <CheckListDisplay key={`conf${index}`} index={index} doc={doc} />
        ]
        return null
      })
      contents = [...contents, <Typography key={`prgph4${i}`} paragraph />]
    }
  }

  /*** ENVIO ***/
  else if (current.stage.filter(a => { return a === '5' }).length > 0) {
    if (DUMMIES.confirmacao.length > 0) {
      /***** DUMMY  ***/
      let date = new Date(99131231238);
      /**********/
      contents = [...contents,
      <Typography key={`tit0${i}${step.title}`} variant="h6" gutterBottom>Processo enviado para Portugal</Typography>
      ]
      contents = [...contents,
      <Typography key={`tit2${i}${step.title}`} color="textSecondary" variant="subtitle2" gutterBottom>Enviado em <strong>{date.toLocaleDateString()}</strong></Typography>,
      <Typography key={`tit3${i}${step.title}`} color="textSecondary" variant="subtitle2" gutterBottom>Prazo <strong>15 dias</strong></Typography>,
      <Typography key={`tit1${i}${step.title}`} color="textSecondary" variant="subtitle2" gutterBottom>Número de rastreio <a href="https://www.dhl.com" onClick={stoppropagation}>234234345</a></Typography>
      ]
      contents = [...contents, <Typography key={`prgph5${i}`} paragraph />]
    }
  }
  /*** ACOMPANHAMENTO ***/
  else if (current.stage.filter(a => { return a === '6' }).length > 0) {
    if (DUMMIES.confirmacao.length > 0) {
      /***** DUMMY  ***/
      let date = new Date(109131231238);
      /**********/
      contents = [...contents,
      <Typography key={`tit0${i}${step.title}`} variant="h6" gutterBottom>Processo em curso na conservatória de Braga</Typography>
      ]
      contents = [...contents,
      <Typography key={`tit2${i}${step.title}`} color="textSecondary" variant="subtitle2">Deu entrada em <strong>{date.toLocaleDateString()}</strong></Typography>
      ]
      contents = [...contents, <Typography key={`prgph6${i}`} paragraph />]
    }
  }
  /*** CONCLUSÃO ***/
  else if (current.stage.filter(a => { return a === '7' }).length > 0) {
    if (DUMMIES.confirmacao.length > 0) {
      contents = [...contents,
      <Typography key={`tit0${i}${step.title}`} variant="h6" gutterBottom>Parabéns!</Typography>
      ]
      contents = [...contents,
      <Typography key={`tit2${i}${step.title}`} color="textSecondary" variant="subtitle2">Seu número de assento é <strong>{`235596`}</strong></Typography>
        ,
      <Divider key={`divider2${i}${step.title}`} />
        ,
      <Button key={`btn1{i}${step.title}`} size="small" onClick={stoppropagation}>Avalie seu processo conosco</Button>
        ,
      <Button key={`btn2${i}${step.title}`} size="small" onClick={stoppropagation}>Nos avalie no Google</Button>
      ]
      contents = [...contents, <Typography key={`prgph7${i}`} paragraph />]
    }
  }
  return <>{contents}</>
}

function FluidCard({ children, ...props }) {
  return (
    <Card classes={{ root: `fluid link card` }} square {...props}>
      <CardContent>
        {children}
      </CardContent>
    </Card>
  )
}

function NewUser(props) {


  const classes = useStyles();
  return (
    <Grid item xs={12} md={3}>
      <Card square className={classes.newUser}>
        <i className="fas fa-plus" />
        <Typography variant="subtitle1" color="inherit">Começar um novo processo</Typography>
      </Card>
    </Grid>
  )
}


function CitizenInfo({ citizen, current }) {
  return (
    <>
      <Typography variant="h6" gutterBottom>Informações sobre {citizen.name}</Typography>
      <Typography variant="subtitle2" color="textSecondary" gutterBottom>Data de nascimento: <strong>{citizen.birthDate}</strong></Typography>
      <Typography variant="subtitle2" color="textSecondary" gutterBottom>Local de nascimento: <strong>{citizen.bornIn ? citizen.bornIn : 'Não disponível'}</strong></Typography>
      <Typography variant="subtitle2" color="textSecondary" gutterBottom>Número de assento: <strong>{citizen.documentNumber ? citizen.documentNumber : 'Em busca'}</strong></Typography>
    </>
  )
}

function HiredServices({ services }) {
  return (
    <>
      {services.map((service, index) => {
        return (
          <Typography key={`hiredservice${index}`} variant="subtitle2">
            {`${imports.processTypes.find(a => { return a.id === service; }).title}${index !== services.length - 1 ? '' : ''}`}
          </Typography>
        )
      })}
    </>
  )
}

function UserCard({ user, index, applicants, smallCard }) {

  const classes = useStyles();

  const [shown, setShown] = useState(false);

  const citizen = applicants.filter(a => {
    return a.id === user.citizenId
  })[0]

  const handleClose = (e) => {
    e.preventDefault()
    setShown(false)
    stoppropagation(e);
  }

  const handleOpen = (e) => {
    e.preventDefault()
    setShown(true)
  }

  const closeBtnContent = <IconButton key={`closebtn`} onClick={handleClose} style={{
    display: 'block',
    width: '2em',
    height: '2em',
    lineHeight: '1',
    margin: '0 0 0 auto',
  }}>
    <CloseButton style={{
      width: '100%',
      height: '100%'
    }} />
  </IconButton>

  const currentStep = imports.userSteps[user.stage - 1]

  return (
    <Grid item xs={12} md={6} key={`gridDialog`}>
      <FluidCard onClick={handleOpen}>
        <div className="header">
          <AddUser key={`adduser${index}`} user={user} index={index} />
          <Typography variant="h4" color="textPrimary" className={classes.userInfo}>{user.name}</Typography>
          <Typography variant="h6" color="textPrimary" className={classes.userInfo} gutterBottom>{`${user.citizenRelationship} de ${citizen.name}`}</Typography>
          <Typography variant="h6" color="textSecondary" className={classes.userInfo}>PT{user.id}</Typography>
          <HiredServices services={user.hiredServices} />
        </div>

        <div className="content">
          {!smallCard ?
            <div className="description">
              <CurrentStepInfos key={`currentinfo${index}`} current={user} index={index} step={currentStep} />
            </div>
            : null}
        </div>
        <Dialog
          classes={{ paper: 'dialogPaper' }}
          open={shown}
          onClose={handleClose}
          scroll={`body`}
          fullScreen={false}
        >
          <DialogTitle>
            <Grid spacing={0} container>
              <Grid xs={10} item>
                <Typography
                  variant="h4"
                  color="textPrimary"
                  style={{ display: "inline-block" }}
                  className="title"
                >
                  {user.name}
                </Typography>
                <Typography
                  variant="h6"
                  color="textPrimary"
                  gutterBottom
                >
                  {`${user.citizenRelationship} de ${citizen.name}`}
                </Typography>
                <Typography
                  variant="h6"
                  color="textSecondary"
                >
                  PT{user.id}
                </Typography>
                <HiredServices services={user.hiredServices} />
              </Grid>
              <Grid xs={2} item>
                {closeBtnContent}
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            {
              !smallCard ?
                <>
                  <CurrentStepInfos key={`currentinfo${index}`} current={user} index={index} step={currentStep} />
                  <Divider />
                  <CitizenInfo key={`citizenInfo${index}`} index={index} current={user} citizen={citizen} />
                  <Divider />
                  <Typography key={`historico${index}`} variant="h6" gutterBottom>Histórico do processo</Typography>
                  <Comments key={`comments${index}`} />
                </>
                :
                <Typography>Aguardando o processo de &#123;nome&#125; ser finalizado para iniciar</Typography>
            }
          </DialogContent>
        </Dialog>
      </FluidCard>
    </Grid>
  )
}

function UserCardList(props) {
  const applicants = DUMMIES.user.body.applicant; //imports.user props.user.applicant;
  let users = applicants.filter((item) => {
    return item.phase === props.filterPhase;
  });
  if (users.length > 0) {
    return (
      <>
        {users.map(function (user, index) {
          return (
            <UserCard key={`card${index}`} user={user} applicants={applicants} index={index} smallCard={props.smallCard} />
          )
        })}
        {props.newuser ? <NewUser key={`newuser${props.user.id}`} /> : null}
      </>
    )
  }
  else return null;
}

export default UserCardList;
