import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import * as PropTypes from 'prop-types';
// import 'moment/locale/pt-br';
// import moment from 'moment';
import { LEGAL_AGE } from 'constants/common';
import { returnValidDate } from 'helpers';

function DatePickerField({
  format,
  label,
  value,
  onChanged,
  error,
  cancelLabel,
  okLabel,
  variant,
  inputVariant,
  DialogProps,
  legalAge,
  ...others
}) {
  const [date, setDate] = useState(null);

  useEffect(() => {
    /* TIMEZONE JAVASCRIPT ERROR FIX */
    const realDate = new Date(value).setUTCHours(12);
    /* -- */
    setDate(value ? (new Date(realDate)) : null);
  }, [value]);

  const handleChangeDate = (newDate) => {
    setDate(newDate);
    onChanged(newDate);
  };

  const today = new Date();
  const yearsAgo = today.setFullYear(today.getFullYear() - LEGAL_AGE);
  const maxDate = legalAge ? returnValidDate(yearsAgo) : '2100-01-01';

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
      <KeyboardDatePicker
        variant={variant}
        cancelLabel={cancelLabel}
        okLabel={okLabel}
        format={format}
        label={label}
        inputVariant={inputVariant}
        value={date}
        onChange={handleChangeDate}
        inputProps={{
          error: error.toString(),
        }}
        todayLabel="Hoje"
        invalidLabel="inválido"
        maxDate={maxDate}
        maxDateMessage="Data maior que a data máxima"
        minDateMessage="Data menor que a data mínima"
        invalidDateMessage="Data inválida"
        KeyboardButtonProps={{
          'aria-label': 'Selecione uma data',
        }}
        DialogProps={DialogProps}
        {...others}
      />
    </MuiPickersUtilsProvider>
  );
}

DatePickerField.propTypes = {
  value: PropTypes.any,
  format: PropTypes.string,
  label: PropTypes.string,
  onChanged: PropTypes.func.isRequired,
  error: PropTypes.bool,
  cancelLabel: PropTypes.string,
  okLabel: PropTypes.string,
  variant: PropTypes.string,
  inputVariant: PropTypes.string,
  legalAge: PropTypes.bool,
  DialogProps: PropTypes.object,
};

DatePickerField.defaultProps = {
  value: null,
  format: 'dd/MM/yyyy',
  label: 'Data',
  error: false,
  cancelLabel: 'Cancelar',
  okLabel: 'Confirmar',
  variant: 'dialog',
  inputVariant: 'outlined',
  legalAge: false,
  DialogProps: {
    scroll: 'body',
  },
};

export default DatePickerField;
