import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Grid, TextField, MenuItem, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
// import DatePickerField from 'global/fields/DatePickerField';
// import TownHallField from 'sales/form/StepOne/TownHallField';
import FillLaterField from 'sales/form/StepOne/FillLaterField';
import { FILL_LATER_TEXT } from 'constants/sales';
import { uniqueId } from 'lodash';
import { fetchFreguesiasByConselhos } from 'store/actions/sales';
import AutosuggestField from 'global/fields/AutosuggestField';
import { useStyles } from 'global/account/style.js';

function NewPeopleDefaultForm({ form, onUpdateField, hasError, ...props }) {

  const classes = useStyles();
  const [concelhoId, setConcelhoId] = useState(null);

  const handleSelectConselho = (obj) => {
    if (obj == 0) {
      onUpdateField('concelho', 0)
      return;
    }

    props.fetchFreguesias(obj.value);

    setConcelhoId(obj.value);

    var selectedConcelho = _.first(_.filter(props.conselhos, ['id', obj.value]));
    onUpdateField('concelho', selectedConcelho);
    onUpdateField('concelho_id', obj.value);
  }

  const handleSelectFreguesia = (id) => {
    if (id == 0) {
      onUpdateField('freguesia', 0);
      return;
    }

    console.log(concelhoId)

    var data = {};
    var freguesia = _.first(_.filter(props.freguesias,['id', id]));
    var concelho = _.first(_.filter(props.conselhos,['id', freguesia.id]));
    data = freguesia;
    data.concelho = concelho;
    onUpdateField('freguesia', freguesia)
    onUpdateField('freguesia_id', id)
  }

  const mapConcelhos = () => {
    var result = props.conselhos.map(e => {
      var obj = {}
      obj.value = e.id;
      obj.label = e.name;
      return obj;
    });
    return result;
  }

  return (
    <>
      <Grid item xs={4}>
      <AutosuggestField
        onSelect={handleSelectConselho}
        suggestList={mapConcelhos()}
        labelsuggestList="Selecione"
        label="Concelho"
        value={String(concelhoId != null ? concelhoId : '')}
        loadKey="alpha2Code"
        notTranslate={true}
        InputLabelProps={{
          shrink: Boolean(concelhoId),
        }}
        inputProps={{
          classes,
          autoComplete: 'off',
          id: 'concelho_id',
          name: 'concelho_id',
          variant: 'outlined',
          fullWidth: true,
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
      />
      <Typography variant="caption">
        * Caso o assento do português tenha sido concluído pelo consulado,
        inserir em "Concelho" o estado de nascimento do português, e
        NÃO o estado do consulado.
      </Typography>
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          variant="outlined"
          name="freguesia"
          select
          label="Freguesia de nascimento"
          value={form.freguesia_id}
          onChange={(e) => handleSelectFreguesia(e.target.value)}
          error={hasError && form.freguesia == ''}
        >
          <MenuItem key={uniqueId()} value={0}>A confirmar</MenuItem>
          {props.freguesias.map((freguseia) => (
            <MenuItem key={uniqueId()} value={freguseia.id}>{freguseia.name} - {freguseia.code}</MenuItem>
          ))}
        </TextField>
        <Typography variant="caption">
          * Caso o assento do português tenha sido concluído pelo consulado,
          inserir em "Freguesia" a cidade de nascimento do português, e
          NÃO a cidade do consulado.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          variant="outlined"
          name="mother_name"
          label="Nome da mãe"
          value={form.mother_name}
          onChange={(e) => onUpdateField('mother_name', e.target.value)}
          error={hasError && form.mother_name == ''}
        />
        <FillLaterField
          checked={form.mother_name === FILL_LATER_TEXT}
          onCheck={(e) => onUpdateField('mother_name', e ? FILL_LATER_TEXT : '')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          variant="outlined"
          name="father_name"
          label="Nome do pai"
          value={form.father_name}
          onChange={(e) => onUpdateField('father_name', e.target.value)}
          error={hasError && form.father_name == ''}
        />
        <FillLaterField
          checked={form.father_name == FILL_LATER_TEXT}
          onCheck={(e) => onUpdateField('father_name', e ? FILL_LATER_TEXT : '')}
        />
      </Grid>
    </>

  );
}

const mapStateToProps = (state) => ({
  conselhos: state.sales.conselhos,
  freguesias: state.sales.freguesias
});

const actions = {
  fetchFreguesias: fetchFreguesiasByConselhos
}

export default connect(mapStateToProps, actions)(NewPeopleDefaultForm);
