import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    transition: theme.transitions.create(['all']),
  },
  inputRoot: {
    transition: theme.transitions.create(['all']),
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    margin: '2px auto 48px',
    '&.single': {
      marginBottom: 0,
    },
    [theme.breakpoints.up('sm')]: {
      width: '60%',
      minWidth: 400,
      maxWidth: 800,
    },
  },
  columnLessImportant: {
    display: 'none !important',
    [theme.breakpoints.up('sm')]: {
      display: 'flex !important',
    },
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  pageTitle: {
    lineHeight: `${theme.spacing(5)}px`,
  },
  smallPaper: {
    width: theme.spacing(35),
    display: 'inline-block',
    padding: theme.spacing(2),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    boxShadow: theme.shadows[4],
    '&:hover': {
      boxShadow: theme.shadows[1],
    },
  },
  header: {
    padding: theme.spacing(2, 3),
    marginBottom: theme.spacing(1),
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'relative',
    '&.selected': {
      paddingBottom: 0,
    },
  },
  body: {
    userSelect: 'none',
    padding: theme.spacing(2, 3),
    margin: theme.spacing(1.5, 0, 2, 0),
    position: 'relative',
    minHeight: theme.spacing(8),
    transition: theme.transitions.create(['box-shadow', 'min-height'], { duration: '100ms' }),
    boxShadow: theme.shadows[6],
    '&.pointer:hover': {
      boxShadow: theme.shadows[0],
    },
    '&.pointer': {
      cursor: 'pointer',
    },
  },
  centeredGrid: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  pLine: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    whiteSpace: 'nowrap',
    lineHeight: 1.8,
  },
  icon: {
    marginRight: theme.spacing(.5),
    marginTop: theme.spacing(.5),
  },
  dialogMoreInfo: {
    padding: `${theme.spacing(6, 3, 3, 3)} !important`,
  },
  closeBtn: {
    position: 'absolute',
    top: theme.spacing(-3),
    right: 0,
  },
  fromMdPadding: {
    [theme.breakpoints.up('md')]: {
      paddingRight: `${theme.spacing(5.5)}px !important`,
    },
  },
  beforeMdPadding: {
    [theme.breakpoints.down('md')]: {
      paddingRight: `${theme.spacing(5.5)}px !important`,
    },
  },
  link: {
    marginRight: theme.spacing(1),
  },
  gridContainer: {
    paddingBottom: theme.spacing(1),
    position: 'relative',
  },
}));
