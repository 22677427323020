import React, { useState } from 'react';
import { connect } from 'react-redux';
import FileDrop from 'react-file-drop';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography, LinearProgress } from '@material-ui/core';
import {
  CloudUploadOutlined as CloudUploadOutlinedIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
} from '@material-ui/icons';
import * as $_ from 'global/functions';
import { showNotification } from 'store/actions/notifications';
import { NOTIFICATION_TYPE_BLACK } from 'constants/notification';
import { useStyles } from './style';

function FileBox({
  id,
  errors,
  setErrors,
  name,
  setFile,
  file,
  acceptedTypes,
  maxSize,
  maxFiles,
  baseFileAmount,
  multiple,
  FileDropProps,
  InputProps,
  showNotification,
  ...other
}) {
  const classes = useStyles(other);

  const [loadingCard, setLoadingCard] = useState(false);

  const handleUploadFile = async (files) => {
    if(!files) return;
    const limitFiles = maxFiles - baseFileAmount;
    if (multiple && files.length > limitFiles) {
      showNotification(NOTIFICATION_TYPE_BLACK, `Por favor, selecione até ${limitFiles} arquivos. ${maxFiles > limitFiles ? `(Limite total de ${maxFiles} arquivos)` : ''}`);
      return;
    }
    const filesToUpload = multiple ? files : [files[0]];
    if (filesToUpload.length > 0) {
      let addFiles = [];
      let fileToUpload = {};
      for (let i = 0; i < filesToUpload.length; i++) {
        fileToUpload = filesToUpload[i];
        if (acceptedTypes.indexOf(fileToUpload.type.split('/')[1]) === -1) {
          showNotification(NOTIFICATION_TYPE_BLACK, 'O formato do arquivo é inválido.');
          return;
        }
        if (fileToUpload.size > maxSize) {
          showNotification(NOTIFICATION_TYPE_BLACK, `O arquivo é grande demais, por favor escolha um arquivo com menos de ${$_.humanFileSize(fileToUpload)}.`);
          return;
        }
        setLoadingCard(true);
        const src = await $_.readFileAsync(fileToUpload);
        const addFile = {
          src,
          title: fileToUpload.name,
          size: fileToUpload.size,
          type: fileToUpload.type,
        };
        const indexOf = errors.indexOf(name);
        if (indexOf >= 0) {
          const newErrors = errors;
          newErrors.splice(indexOf, 1);
          setErrors(newErrors);
        }
        addFiles = [...addFiles, addFile];
      }
      setFile(addFiles);
      setLoadingCard(false);
    }
  };

  return (
    <div
      id={id}
      className={clsx(classes.fileBox,
        errors.indexOf(name) >= 0 ? 'error' : 'regular')}
      {...other}
    >
      <FileDrop
        onDrop={(files) => handleUploadFile(files)}
        {...FileDropProps}
      >
        <Typography align="center">
          <CloudUploadOutlinedIcon className="hover" fontSize="large" />
        </Typography>
        <Typography
          variant="h6"
          align="center"
        >
          {'Arraste e solte'}
        </Typography>
        <Typography
          align="center"
          color="textSecondary"
          gutterBottom
        >
          {'ou '}
          <Typography
            color="primary"
            component="span"
          >
            {'clique aqui'}
          </Typography>
          {' para selecionar o arquivo'}
        </Typography>
        {!(file.length > 0)
          ? loadingCard && (
            <LinearProgress className={classes.linearProgress} color="primary" />
          ) : file.map((thisFile) => (
            <Typography key={`Upload${thisFile.title}`} className={classes.paragraphWrapper}>
              <strong className={clsx(classes.smallMargin, 'right')}>
                {`${$_.limitChars(thisFile.title, 40)} `}
              </strong>
              {` ${$_.humanFileSize(thisFile.size)}`}
              <CheckCircleOutlinedIcon className={classes.smallMargin} color="primary" />
            </Typography>
          ))}
      </FileDrop>
      <input
        type="file"
        className="dummyInput"
        id="file-upload"
        multiple={multiple}
        name={name}
        title={`Selecionar arquivo${multiple ? 's' : ''}`}
        onChange={(event) => handleUploadFile(event.target.files)}
        accept={`.${acceptedTypes.join(',.')}`}
        {...InputProps}
      />
    </div>
  );
}

FileBox.propTypes = {
  id: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  setErrors: PropTypes.func,
  name: PropTypes.string,
  multiple: PropTypes.bool,
  setFile: PropTypes.func.isRequired,
  file: PropTypes.arrayOf(PropTypes.object).isRequired,
  acceptedTypes: PropTypes.arrayOf(PropTypes.string),
  maxSize: PropTypes.number,
  maxFiles: PropTypes.number,
  baseFileAmount: PropTypes.number,
};

FileBox.defaultProps = {
  id: 'react-file-drop',
  errors: [],
  setErrors: () => [],
  name: 'file',
  multiple: true,
  acceptedTypes: ['jpeg', 'jpg', 'tiff', 'bmp', 'png', 'pdf'],
  maxSize: 15728640,
  maxFiles: 10,
  baseFileAmount: 0,
};

export default connect(null, { showNotification })(FileBox);
