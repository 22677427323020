import React from 'react';
import clsx from 'clsx';
import { Grid, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import { getDocInfo } from './functions';
import { convertCurrency } from 'helpers';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  grid: {
    width: '50%',
    display: 'inline-block',
    minWidth: 150,
    maxWidth: 200,
  },
  notApplicable: {
    textDecoration: 'line-through',
  },
}));

const ProductPrice = ({ form, setForm, products, ...props }) => {
  const classes = useStyles(props);
  const thisProduct = form.products[0] ? getDocInfo(products, form.products[0]) : {};
  let productCost = form.products[0]
    ? thisProduct.prices.reduce((a, b) => a + (parseInt(form.products[0].use_old_price ? b.old_price : b.price) || 0), 0)
    : 0;
  if (form.products[0] && form.products[0].apostilate && !form.is_courtesy) {
    const property = thisProduct.prices.find((p) => p.name.toLowerCase() === 'apostilamento');
    const valueToSubtract = property.price || 0;
    productCost = productCost - parseInt(valueToSubtract);
  }
  if (form.is_courtesy) {
    productCost = 0;
  }
  const canHaveOldPrice = Boolean(getDocInfo(products, { id: thisProduct.id }).prices.find((a) => a.old_price));
  const handleChangeOldPrice = (value) => setForm((o) => {
    let products = form.products;
    products[0].use_old_price = value;
    return ({
      ...o,
      products,
    })
  }
  );
  return (
    <Grid item xs={12}>
      <Typography variant="h5" color="textSecondary" paragraph>
        {'Valor parcial da venda'}
      </Typography>
      {form.products[0] && thisProduct
        ? thisProduct.prices.map((price) => {
          const notApplicable = form.products[0] && form.products[0].apostilate && price.name.toLowerCase() === 'apostilamento';
          return (
            <Typography key={`price${price.id}`}>
              <Typography
                component="span"
                className={clsx(classes.grid, (notApplicable || form.is_courtesy) ? classes.notApplicable : 'regular')}
              >
                {`${price.name}: `}
              </Typography>
              <Typography variant="subtitle2" className={(notApplicable || form.is_courtesy) ? classes.notApplicable : 'regular'} component="span">
                {convertCurrency(form.products[0].use_old_price ? price.old_price : price.price)}
              </Typography>
            </Typography>
          );
        })
        : null}
      <Typography>
        <Typography component="span" className={classes.grid}>
          {'Total: '}
        </Typography>
        <Typography color="primary" variant="subtitle2" component="span">
          {convertCurrency(productCost)}
        </Typography>
      </Typography>
      {canHaveOldPrice && (
        <Typography>
          <FormControlLabel
            control={(
              <Checkbox
                checked={form.products[0].use_old_price}
                onChange={(e) => handleChangeOldPrice(e.target.checked)}
              />
            )}
            label="Usar preço antigo"
          />
        </Typography>
      )}
    </Grid>
  )
};

export default ProductPrice;
