import React from 'react';
import uniqid from 'uniqid';
import { connect } from 'react-redux';
import { makeStyles, Typography, Grid, Divider } from '@material-ui/core';
import { capitalize } from 'global/functions';
import { getDocInfo } from './functions';

const useStyles = makeStyles(theme => ({
  docsItemGrid: {
    padding: theme.spacing(1),
  },
  docSpecifications: {
    paddingRight: theme.spacing(3),
    '& span': {
      fontWeight: 600,
    }
  },
  divider: {
    backgroundColor: theme.palette.grey[400],
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  eachDoc: {
    marginTop: theme.spacing(1),
  },
}));

function SaleFormStepTwoDoc(props, onApostilate) {
  const classes = useStyles();
  
  const docInfo = getDocInfo(props.products, props.product);

  return (
    <Grid item xs={12} className={classes.docsItemGrid}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" color="textSecondary" gutterBottom>
            <strong>{docInfo && docInfo.title}</strong>
          </Typography>
        </Grid>
        {docInfo && docInfo.requirements.map((document) => {
          if (!document.provided) {
            return (
                <Grid key={uniqid()} className={classes.eachDoc} item xs={12}>
                  <Typography variant="subtitle2">
                    <strong>{capitalize(document.name)}</strong>
                  </Typography>

                  {Boolean(document.format) && (
                    <Typography
                      className={classes.docSpecifications}
                    >
                      <Typography component="span" color="textSecondary" variant="subtitle1">
                        {'Formato: '}
                      </Typography>
                      {capitalize(document.format)}
                    </Typography>
                  )}
                  {Boolean(document.exception) && (
                    <Typography
                      className={classes.docSpecifications}
                    >
                      <Typography
                        component="span"
                        color="textSecondary"
                        variant="subtitle1"
                      >
                        {'Exceção: '}
                      </Typography>
                      {capitalize(document.exception)}
                    </Typography>
                  )}
                  {Boolean(document.specification) && (
                    <Typography
                      className={classes.docSpecifications}
                    >
                      <Typography
                        component="span"
                        color="textSecondary"
                        variant="subtitle1"
                      >
                        {'Especificação: '}
                      </Typography>
                      {capitalize(document.specification)}
                    </Typography>
                  )}
                  {Boolean(document.variation) && (
                    <Typography
                      className={classes.docSpecifications}
                    >
                      <Typography
                        component="span"
                        color="textSecondary"
                        variant="subtitle1"
                      >
                        {'Documentos possíveis: '}
                      </Typography>
                      {typeof document.variation === 'string' ? document.variation : document.variation.join(', ')}
                    </Typography>
                  )}
                  <strong>
                    {/* {Boolean(item.clientHague[thisprocessType.title]) ? `Cliente vai apostilar` : `Precisa ser apostilado`} */}
                  </strong>
                </Grid>
            );
          }
          return null;
        })}
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => ({
  products: state.product.allProducts,
});

export default connect(mapStateToProps)(SaleFormStepTwoDoc);
